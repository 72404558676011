define('ohs/routes/edit-quiz-name', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _breadcrumbMixin.default, {
    titleToken: function titleToken(model) {
      return model.quiz.get('quizName');
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', false);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      return Ember.RSVP.hash({
        quiz: this.store.peekRecord('quiz', params.quiz_id),
        returnToDetail: params.return_detail
      });
    },


    afterModel: function afterModel(model) {
      if (model.returnToDetail == '1') this._super(model, 'Edit Quiz Name', [{ 'param': model.quiz.get('id') }, { 'param': model.returnToDetail }]);
    },

    actions: {
      saveQuiz: function saveQuiz() {
        document.activeElement.blur();
        var quiz = this.currentModel.quiz;
        //trim quiz name
        quiz.set('quizName', quiz.get('quizName').trim());
        var quizName = quiz.get('quizName');
        if (Ember.isBlank(quizName)) {
          this.set("currentModel.validationErrors", "The quiz name can\'t be blank.");
          return false;
        }
        var quizId = quiz.get('id');
        var passageId = quiz.get('passages.firstObject.id');
        var self = this;
        if (!quizId) {
          quizId = 0;
        }
        var sesObj = this.get('session');
        var userId = sesObj.session.content.authenticated["userId"];
        return Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/quiz/hasDuplicateQuizName?quizId=' + quizId + '&quizName=' + encodeURIComponent(quizName) + '&userId=' + encodeURIComponent(userId)).done(function (response) {
          self.set("currentModel.validationErrors", '');
          quiz.save().then(function () {
            if (self.currentModel.returnToDetail == '1') {
              self.updateBreadCrumbs();
              self.transitionTo('teacher.quiz-detail', quiz.get('id'));
            } else {
              if (passageId > 0) {
                self.transitionTo('passage-quiz-review', passageId, quiz.get('id'));
              } else {
                self.transitionTo('review-1', quiz.get('id'));
              }
            }
          }, function () {
            //alert("Your quiz could not be saved. Please check your internet connection and try again.");
            self.set("currentModel.showAlert", true);
          });
        }).fail(function (response) {
          self.set("currentModel.validationErrors", response.responseText);
        });
      },

      cancel: function cancel() {
        document.activeElement.blur();
        var quiz = this.currentModel.quiz;
        var passageId = quiz.get('passages.firstObject.id');
        quiz.rollbackAttributes();
        this.set("currentModel.validationErrors", '');
        if (this.currentModel.returnToDetail == '1') {
          this.transitionTo('teacher.quiz-detail', quiz.get('id'));
        } else {
          if (passageId > 0) {
            this.transitionTo('passage-quiz-review', passageId, quiz.get('id'));
          } else {
            this.transitionTo('review-1', quiz.get('id'));
          }
        }
      },

      //Hide alert box
      hideAlert: function hideAlert() {
        this.set("currentModel.showAlert", false);
      }

    },

    //Common Methods
    updateBreadCrumbs: function updateBreadCrumbs() {
      var quiz = this.currentModel.quiz;
      var breadcrumbs = this.get('session').get('breadcrumbs');
      if (breadcrumbs.length > 1) {
        var bc = breadcrumbs[breadcrumbs.length - 2];
        if (bc) {
          Ember.set(bc, 'text', quiz.get('quizName'));
        }
      }
    }
  });
});
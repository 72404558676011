define('ohs/mixins/edinsight-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    edinsight: Ember.inject.service('edinsight-service'),

    afterModel: function afterModel(model, transition) {
      var _this = this;

      var isRosterSync = transition.queryParams['isRosterSync'] == 'true';
      var token = transition.queryParams['UserToken'];
      var providerId = transition.queryParams['providerId'];

      if (isRosterSync) {
        Ember.run.schedule('afterRender', function () {
          _this.get('edinsight').pullRoster(token, providerId).then(function (response) {
            _this.set('currentModel.showConfirm', true);
            _this.set('currentModel.confirmMessage', 'Success! Your students and courses have been pulled in from EdInsight!');
            _this.set('currentModel.syncing', false);
          }, function (response) {
            _this.set('currentModel.showAlert', true);
            _this.set('currentModel.syncing', false);
            _this.set('currentModel.alertMessage', 'There was an error pulling your Roster from EdInsight.');
          });
        });

        model.syncing = true;
        return model;
      } else {
        return model;
      }
    }

  });
});
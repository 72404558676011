define('ohs/components/x-meta', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'section',
    store: Ember.inject.service(),
    session: Ember.inject.service('session'),
    quizName: null,
    //classNames: ['container' ,'meta-wrapper']
    classNameBindings: ['dynamicClasses'],
    dynamicClasses: Ember.computed('noContainer', function () {
      var classes = '';

      if (this.get('noContainer')) {
        classes = 'meta-wrapper';
      } else {
        classes = 'container meta-wrapper';
      }

      return classes;
    }),

    // var q=this.get('quiz'),

    actions: {
      editAction: function editAction() {
        if (this.get('is_through_passage')) {
          this.set('quizName', this.get('quiz.quizName'));
          var self = this;
          self.toggle();
        } else {
          this.sendAction('editAction');
        }
      },
      saveQuizname: function saveQuizname() {
        //trim quiz name
        this.set('page_title', this.get('page_title').trim());
        var quizName = this.get('page_title');
        if (Ember.isBlank(quizName)) {
          this.set("validationErrors", "The quiz name can\'t be blank.");
          return false;
        }
        var quizId = 0;
        var sesObj = this.get('session');
        var userId = sesObj.session.content.authenticated["userId"];
        var self = this;
        //DuplicateQuizName checking
        Ember.$.getJSON(this.get('store').adapterFor('application').get('host') + '/quiz/hasDuplicateQuizName?quizId=' + quizId + '&quizName=' + encodeURIComponent(quizName) + '&userId=' + encodeURIComponent(userId)).done(function (response) {
          self.set("validationErrors", '');
          self.toggle();
        }).fail(function (response) {
          self.set("validationErrors", response.responseText);
        });
      },

      cancelAction: function cancelAction() {
        this.set("validationErrors", '');
        this.set('page_title', this.get('quizName'));
        var self = this;
        self.toggle();
      },

      printAction: function printAction() {
        this.sendAction('printAction');
      },

      buttonAction: function buttonAction() {
        this.sendAction('buttonAction');
      },

      item2Action: function item2Action() {
        this.sendAction('item_2_action');
      },

      item3Action: function item3Action() {
        this.sendAction('item_3_action');
      },

      item4Action: function item4Action() {
        this.sendAction('item_4_action');
      }
    },
    toggle: function toggle() {
      var self = this;
      self.toggleProperty('editing_quiz_name');
    }
  });
});
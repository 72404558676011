define('ohs/routes/students', ['exports', 'ohs/mixins/authorized-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authorizedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authorizedRouteMixin.default, _breadcrumbMixin.default, {
    authorizedRoles: ['Tutor', 'Teacher'],
    titleToken: "My Groups",

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },
    google: Ember.inject.service('google-integration'),
    model: function model() {
      var _get = get(this, 'token'),
          userId = _get.userId,
          roles = _get.roles;

      var user = null;

      if (roles.includes('Tutor')) {
        user = this.store.findRecord('tutor', userId);
      }

      if (roles.includes('Teacher')) {
        user = this.store.findRecord('teacher', userId);
      }

      return Ember.RSVP.hash({
        teacher: user,
        teacherGroups: this.store.findAll('studentGroup'),
        sortOptions: [{
          text: "Date created",
          id: 1
        }, {
          text: "Group name",
          id: 2
        }, {
          text: "Group Desc",
          id: 3
        }],

        searchBox: '',
        currentSort: 1,
        filteredGroups: Ember.computed('teacherGroups.@each.id', 'searchBox', 'currentSort', function () {
          var groups = this.teacherGroups.filter(function (group, index, array) {
            if (this.searchBox) {
              var keywordSearch = true;
              var splitKeywords = this.searchBox.toLowerCase().split(" ");
              splitKeywords.forEach(function (k) {
                if (keywordSearch) {
                  keywordSearch = group.get('studentGroupName').toLowerCase().includes(k) || group.get('studentGroupDesc') && group.get('studentGroupDesc').toLowerCase().includes(k) || get(group, 'students').toArray().some(function (student) {
                    return get(student, 'studentDisplayName').toLowerCase().includes(k);
                  });
                }
              });
              return keywordSearch;
            } else {
              return true;
            }
          }, this);
          if (this.currentSort == 2) {
            return groups.sortBy('studentGroupName');
          } else if (this.currentSort == 3) {
            return groups.sortBy('studentGroupDesc');
          } else {
            return groups.sortBy('createdOn').reverse();
          }
        })
      });
    },

    afterModel: function afterModel(curModel) {
      this._super(curModel, 'Student Groups', false, true);
    },

    actions: {
      sortBy: function sortBy(selectedValue) {
        this.set('currentModel.currentSort', selectedValue);
      },

      goToQuizzes: function goToQuizzes() {
        this.transitionTo('teacher-quizzes');
      },

      createGroup: function createGroup() {
        this.transitionTo('student-group-create');
      },

      drillToGroup: function drillToGroup(id) {
        this.transitionTo('student-group', id);
      },

      loading: function loading(transition, originRoute) {
        if (originRoute != this) return true;
      },

      createStudent: function createStudent() {
        this.transitionTo('new-user', 'Student', 0);
      },


      pullFromClassroom: function pullFromClassroom() {
        var _this = this;

        this.toggleProperty('currentModel.syncing');
        var userId = this.get('session').session.content.authenticated["userId"];
        this.get('google').pullClassroom(userId, false).then(function () {
          _this.toggleProperty('currentModel.showConfirm');
          _this.set('currentModel.confirmMessage', 'Success! Your students and courses have been pulled in from Classroom!');
          _this.toggleProperty('currentModel.syncing');
        }, function (error) {
          _this.toggleProperty('currentModel.showAlert');
          _this.set('currentModel.alertMessage', error);
          _this.toggleProperty('currentModel.syncing');
        });
      },

      hideConfirm: function hideConfirm() {
        this.set('currentModel.showConfirm', false);
        this.refresh();
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
        this.set('currentModel.alertMessage', '');
      }

    },

    joinRoster: function joinRoster() {
      this.refresh();
    }
  });
});
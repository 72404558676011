define('ohs/components/answer-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['student-stats'],
    secondPartAnswers: Ember.computed('student.studentSecondPartAnswers.@each.id', 'secondPartAnswerOptions.@each.id', function () {
      var studentAnswers = Ember.get(this, 'student.studentSecondPartAnswers').filterBy('questionId', Ember.get(this, 'currentQuestionId')).map(function (a) {
        return Ember.get(a, 'secondPartAnswerId');
      }) || [];

      return (Ember.get(this, 'secondPartAnswerOptions') || []).map(function (ao) {
        return {
          letter: Ember.get(ao, 'answerLetter'),
          isCorrect: Ember.get(ao, 'isCorrect'),
          isAnswered: studentAnswers.includes(parseInt(Ember.get(ao, 'id')))
        };
      });
    }),

    firstPartAnswers: Ember.computed('student.studentAnswers.@each.answerId', 'answerOptions.@each.id', function () {
      var currentAnswer = Ember.get(this, 'student.studentAnswers').findBy('questionId', Ember.get(this, 'currentQuestionId'));

      return (Ember.get(this, 'answerOptions') || []).map(function (ao) {
        return {
          letter: Ember.get(ao, 'answerLetter'),
          isCorrect: Ember.get(ao, 'isCorrect'),
          isAnswered: currentAnswer && Ember.get(currentAnswer, 'answerId') == Ember.get(ao, 'id')
        };
      });
    }),

    isFirstPartUnAnswered: Ember.computed('firstPartAnswers.@each.isAnswered', 'firstPartAnswers.[]', function () {
      return !Ember.get(this, 'firstPartAnswers').toArray().some(function (a) {
        return Ember.get(a, 'isAnswered');
      });
    }),

    isSecondPartUnAnswered: Ember.computed('secondPartAnswers.@each.isAnswered', 'secondPartAnswers.[]', function () {
      return !Ember.get(this, 'secondPartAnswers').toArray().some(function (a) {
        return Ember.get(a, 'isAnswered');
      });
    })
  });
});
define('ohs/mirage/serializers/application', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Serializer.extend({
    serialize: function serialize(object, request) {
      // This is how to call super, as Mirage borrows [Backbone's implementation of extend](http://backbonejs.org/#Model-extend)
      var original = _emberCliMirage.Serializer.prototype.serialize.apply(this, arguments);

      // Add metadata, sort parts of the response, etc.
      var propertyName = Object.getOwnPropertyNames(original);
      var json = original[propertyName[0]];

      return json;
    }
  });
});
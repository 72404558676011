define('ohs/components/color-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    selectedOption: Ember.computed('value', 'options.@each.text', function () {
      return this.get('options').findBy('id', this.get('value'));
    }),

    otherOptions: Ember.computed('value', 'options.@each.text', function () {
      var self = this;
      return this.get('options').filter(function (item) {
        return item.id != self.get('value');
      });
    }),

    actions: {
      expand: function expand() {
        this.set('isExpanded', true);
        setTimeout(function () {
          $('.other-options').slideDown();
        }, 100);
      },

      select: function select(id) {
        this.sendAction('setPropertyAction', id);
        this.set('isExpanded', false);
      }
    }
  });
});
define('ohs/routes/teacher-results-1', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/breadcrumb-mixin', 'ohs/mixins/passage-modal-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _breadcrumbMixin, _passageModalMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _breadcrumbMixin.default, _passageModalMixin.default, {
    titleToken: function titleToken(model) {
      return model.administration.get('quiz.quizName');
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      var commentLength = 0;
      var textLimit = 0;
      return Ember.RSVP.hash({
        showNextStep: params.show_next_step,
        studentGroups: this.store.query('studentGroup', { administrationId: params.administration_id, userId: userId }),
        administration: this.store.findRecord('administration', params.administration_id),
        studentTestAdministrations: this.store.query('student-test-administration', { administrationId: params.administration_id }),
        testQuestions: this.store.query('test-question', { administrationId: params.administration_id }),
        orderedQuestions: Ember.computed('administration.quiz.testQuestions.@each.id', function () {
          return this.administration.get('quiz').get('testQuestions').sortBy('questionOrder');
        }),
        passage: Ember.computed('administration', function () {
          return this.administration.get('quiz').get('passages.firstObject');
        }),

        groupFilter: '',

        studentsWithResults: Ember.computed('administration.studentTestAdministrations.@each.id', 'sortBy', 'groupFilter', function () {
          var results = this.administration.get('studentTestAdministrations').filter(function (r, index, array) {
            var inGroup = false;
            var studentId = r.get('student.id');
            if (this.groupFilter) {
              var studArray = this.studentGroups.findBy('id', this.groupFilter).get('students');
              studArray.forEach(function (item, index, enumerable) {
                var studId = item.get('id');
                if (studentId == studId) {
                  inGroup = true;
                  return;
                }
              });
            } else {
              inGroup = true;
            }
            return inGroup;
          }, this);

          switch (parseInt(Ember.get(this, 'sortBy'))) {
            case 1:
              return results.filter(function (r, index, array) {
                return r.get('numAnswered') > 0;
              }).sortBy('student.fullNameReversed');
            case 2:
              return results.filter(function (r, index, array) {
                return r.get('numAnswered') > 0;
              }).sortBy('percentCorrect').reverse();
            case 3:
              return results.filter(function (r, index, array) {
                return r.get('numAnswered') > 0;
              }).sortBy('team.id');
            default:
              return results;
          }
        }),

        questionIssue: '',
        questionIssueComments: '',

        validIssue: Ember.computed('questionIssue', 'commentLength', 'textLimit', function () {
          if (this.questionIssue) {
            if (this.questionIssue != "Other") {
              if (this.questionIssueComments && this.textLimit) {
                return this.questionIssueComments.length <= this.textLimit;
              }
              return true;
            } else {
              if (this.questionIssueComments && this.textLimit) {
                if (this.questionIssueComments.length > 0 && this.questionIssueComments.length <= this.textLimit) {
                  return true;
                } else {
                  return false;
                }
              }
              return false;
            }
          }
          return false;
        }),

        tellUsMore: Ember.computed('questionIssue', function () {
          if (this.questionIssue == "Other") {
            return "Tell us more.";
          } else {
            return "Tell us more (optional).";
          }
        }),

        sortBy: 1,
        options: [{
          text: "Student name",
          id: 1
        }, {
          text: "Score",
          id: 2
        }, { text: 'Team', id: 3 }],
        isProcessing: false,
        isPrinting: false
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, model.administration.get('quiz.quizName') + ' (' + model.administration.get('finishedDateOnly') + ')', [{ 'param': model.administration.get('id') }, { 'param': model.showNextStep }]);

      model.administration.get('quiz').set('testQuestions', model.administration.get('quiz').get('testQuestions').sortBy('questionOrder'));
      model.administration.set('studentTestAdministrations', model.administration.get('studentTestAdministrations').sortBy('studentNameReversed'));

      var self = this;
      Ember.run.later(function () {
        self.set('currentModel.isPrinting', false);
        $('html, body').animate({
          scrollTop: $('.page-body').offset().top
        }, 800);
      }, 500);

      //Check to see if there is a child administration
      var hostAddress = this.get('store').adapterFor('application').get('host');
      var self = this;
      Ember.$.getJSON(hostAddress + '/administrations/hasChildAdministration?administrationId=' + model.administration.get('id')).done(function (response) {
        if (response) self.setHasChild(response);
      });
    },

    setHasChild: function setHasChild(response) {
      this.set('currentModel.hasChild', response);
    },


    deactivate: function deactivate() {
      $('li:contains("Quizzes")').find('li:contains("Results")').children().first().removeClass('active');
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Quizzes")').find('li:contains("Results")').children().first().addClass('active');
      }, 100);
    },

    actions: {

      setCharacterLimitAction: function setCharacterLimitAction(commentCharacterLength, characterLimit, comment) {
        this.set('currentModel.textLimit', characterLimit);
        this.set('currentModel.commentLength', commentCharacterLength);
        this.set('currentModel.questionIssueComments', comment);
      },

      filterStudentGroup: function filterStudentGroup(option) {
        this.set('currentModel.groupFilter', option);
      },
      //Hide alert box
      hideAlert: function hideAlert() {
        this.set("currentModel.showAlert", false);
        this.set("currentModel.alertMessage", "");
      },

      showIssue: function showIssue(questionId) {
        this.set('currentModel.showIssue', true);
        this.set('currentModel.currentQuestionId', questionId);
      },

      reportIssue: function reportIssue() {

        this.set('currentModel.issueMessage', null);
        var self = this;
        var adapter = this.get('store').adapterFor('application');

        var url = adapter.get('host') + '/accounts/reportQuestion?questionId=' + this.get('currentModel.currentQuestionId') + '&issue=' + this.get('currentModel.questionIssue') + '&comment=' + this.get('currentModel.questionIssueComments');
        if (this.get('currentModel.questionIssueComments').trim() == '') {
          url = adapter.get('host') + '/accounts/reportQuestion?questionId=' + this.get('currentModel.currentQuestionId') + '&issue=' + this.get('currentModel.questionIssue');
        }
        adapter.ajax(url, 'POST').then(function () {
          self.set('currentModel.showConfirm', true);
          self.set('currentModel.confirmationMessage', 'Your report has been received and is currently being reviewed. If we have any follow up questions, someone will be in touch!');
        }, function () {
          self.set('currentModel.showAlert', true);
          self.set("currentModel.alertMessage", "There was an error sending your report. Please check your internet connection and try again.");
        });
        self.set('currentModel.questionIssue', null);
        self.set('currentModel.questionIssueComments', '');
      },

      //Hide alert box
      hideConfirm: function hideConfirm() {
        this.set("currentModel.showConfirm", false);
      },

      //Hide alert box
      hideDeleteConfirm: function hideDeleteConfirm() {
        this.set("currentModel.showDeleteConfirm", false);
        this.refresh();
      },

      hideConfirmAndRedirect: function hideConfirmAndRedirect() {
        this.transitionTo('teacher-quizzes');
      },


      setQuestionIssue: function setQuestionIssue(value) {
        this.set('currentModel.questionIssue', value);
      },

      setQuestionIssueComment: function setQuestionIssueComment(value) {
        this.set('currentModel.questionIssueComments', value);
      },

      validateIssue: function validateIssue() {
        if (!this.get('currentModel.questionIssue')) {
          this.set('currentModel.issueMessage', 'Please tell us what is wrong with this resource.');
          return false;
        } else if (this.get('currentModel.questionIssue') == "Other" && !this.get('currentModel.questionIssueComments').trim()) {
          this.set('currentModel.issueMessage', 'Please add some additional information about what is wrong with this resource.');
          return false;
        } else if (this.get('currentModel.questionIssueComments.length') > this.get('currentModel.textLimit')) {
          this.set('currentModel.issueMessage', 'Comment exceeds the character limit.');
          return false;
        } else {
          return true;
        }
      },

      sortBy: function sortBy(value) {
        this.set('currentModel.sortBy', value);
      },

      goToResources: function goToResources() {
        this.transitionTo('teacher-resources-2');
      },

      goToSentResources: function goToSentResources() {
        this.transitionTo('teacher-sent-resources');
      },

      generateCsv: function generateCsv() {
        var csvContent = "";
        var link = document.createElement("a");
        var fileName = this.get('currentModel.administration.quiz.quizName') + '.csv';
        this.set("currentModel.isProcessing", true);

        //IE 10+
        if (navigator != null && navigator.msSaveBlob) {
          csvContent = this.generateCsvContent();
          var blob = new Blob([csvContent], {
            "type": "text/csv;charset=utf8;"
          });
          navigator.msSaveBlob(blob, fileName);

          document.body.appendChild(link);
          link.click();
          this.set("currentModel.isProcessing", false);
        }
        //In other browsers check for download attribute support
        else if (link.download !== undefined) {
            csvContent = "data:text/csv;charset=utf-8," + this.generateCsvContent();
            var encoded = encodeURI(csvContent);
            link.setAttribute("href", encoded);
            link.setAttribute("download", fileName);

            document.body.appendChild(link);
            link.click();
            this.set("currentModel.isProcessing", false);
          } else {
            //needs to implement server side export
            var administrationId = this.get('currentModel.administration.id');
            var baseUrl = this.store.adapterFor('application').get('host');

            var sesObj = this.get('session');
            var userId = sesObj.session.content.authenticated["userId"];
            var sortBy = this.get('currentModel.sortBy');
            this.set('currentModel.isPrinting', true);
            var self = this;

            return Ember.$.getJSON(baseUrl + '/administrations/GenerateAdministrationResultCsv?administrationId=' + administrationId + '&userId=' + encodeURIComponent(userId) + '&sortOrder=' + encodeURIComponent(sortBy)).done(function (response) {
              var xhr = new XMLHttpRequest();
              xhr.open('GET', baseUrl + '/quiz/DownloadQuizQuestions?fileName=' + encodeURIComponent(response), true);
              xhr.responseType = "blob";
              xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                  var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
                  if (isSafari) {
                    var blob = new Blob([xhr.response], { type: "application/octet-stream" });
                  } else {
                    var blob = new Blob([xhr.response], { type: "application/pdf" });
                  }
                  saveAs(blob, response);
                }
              };
              xhr.send(null);

              self.set("currentModel.isProcessing", false);
              self.set('currentModel.isPrinting', false);
            }).fail(function (response) {
              self.set("currentModel.isProcessing", false);
              self.set("currentModel.error", true);
              self.set('currentModel.isPrinting', false);
            });
          }
      },
      hideErrorMessage: function hideErrorMessage() {
        this.set('currentModel.error', false);
      },
      cancel: function cancel() {
        this.set('currentModel.issueMessage', '');
        this.set('currentModel.questionIssueComments', '');
        this.set('currentModel.questionIssue', null);
      },
      clear: function clear() {
        this.set('currentModel.issueMessage', '');
        this.set('currentModel.questionIssueComments', '');
        this.set('currentModel.questionIssue', null);
      },
      confirmDeleteStudentTest: function confirmDeleteStudentTest(studentTestAdministration) {
        this.set('currentModel.selectedStudentTestAdministration', studentTestAdministration);
        this.set('currentModel.showModal', true);
        this.set('currentModel.modalMessage', 'Are you sure you want to delete this student test result?');
      },
      removeStudentTest: function removeStudentTest() {
        this.set('currentModel.showModal', false);
        this.set('currentModel.modalMessage', null);
        var self = this;
        var studentTestAdministration = this.get('currentModel.selectedStudentTestAdministration');
        studentTestAdministration.destroyRecord().then(function (msg) {
          self.set("currentModel.showDeleteConfirm", true);
          self.set("currentModel.confirmationMessage", 'Success!');
        }, function (error) {
          //  If status is 404,administration is also deleted
          if (error && error.errors && error.errors[0].status == '404') {
            self.set("currentModel.showAdministrationDeleteConfirm", true);
            self.set("currentModel.confirmationMessage", 'Success! Since this was the only result in the session, the session has also been deleted.');
          } else {
            self.set("currentModel.showAlert", true);
            self.set('currentModel.alertMessage', "Student test result could not be deleted. Please check your Internet connection and try again.");
          }
        });
      }
    },

    //Common Methods
    generateCsvContent: function generateCsvContent() {
      return this.get('currentModel.studentsWithResults').reduce(function (csv, item) {
        return csv.concat('"' + Ember.get(item, 'studentNameReversed') + '", ' + Ember.get(item, 'numPointsEarned') + ', ' + Ember.get(item, 'numPointsAvailable') + ', ' + Ember.get(item, 'percentCorrect') + ' \n');
      }, "Student Name, Number Correct, Points Possible, Percent Correct \n");
    }
  });
});
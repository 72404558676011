define('ohs/components/game-mode-banner-in-progress', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        quizName: '',
        accessCode: '',

        teamA: null,
        teamB: null,

        reverseAnimation: Ember.observer('reset', function () {
            var _this = this;

            Ember.run.once(function () {
                if (Ember.get(_this, 'reset')) {
                    Ember.get(_this, 'tlMoveRover').to(Ember.get(_this, 'roverWrapper'), 1.25, { marginLeft: '0%', ease: Power2.easeIn }).resume();
                    _this.sendAction('resetComplete');
                }
            });
        }),

        playAnimation: Ember.observer('winner', function () {
            var _this2 = this;

            Ember.run.once(function () {
                var winner = Ember.get(_this2, 'winner');
                if (winner == 'winner-left') {
                    Ember.get(_this2, 'tlMoveRover').to(Ember.get(_this2, 'roverWrapper'), 2.5, { marginLeft: '-10%', ease: Power2.easeIn }).resume();
                    _this2.sendAction('animationComplete');
                } else if (winner == 'winner-right') {
                    Ember.get(_this2, 'tlMoveRover').to(Ember.get(_this2, 'roverWrapper'), 2.5, { marginLeft: '10%', ease: Power2.easeInOut }).resume();
                    _this2.sendAction('animationComplete');
                }
            });
        }),

        didInsertElement: function didInsertElement() {
            //setup 
            Ember.set(this, 'rRight', this.$().find("#rover-right"));
            Ember.set(this, 'rLeft', this.$().find("#rover-left"));
            Ember.set(this, 'rWheelLeft', this.$().find("#rover-left .wheel"));
            Ember.set(this, 'rWheelRight', this.$().find("#rover-right .wheel"));
            Ember.set(this, 'rLeftBody', this.$().find("#rover-left .rover-body"));
            Ember.set(this, 'rRightBody', this.$().find("#rover-right .rover-body"));
            Ember.set(this, 'rNeckLeft', this.$().find("#rover-left .neck"));
            Ember.set(this, 'rNeckRight', this.$().find("#rover-right .neck"));
            Ember.set(this, 'rLeftHead', this.$().find("#rover-left .rover-head"));
            Ember.set(this, 'rRightHead', this.$().find("#rover-right .rover-head"));
            Ember.set(this, 'roverWrapper', this.$().find(".rover-wrapper"));
            Ember.set(this, 'star', this.$().find(".star"));
            Ember.set(this, 'shootingStarRight', this.$().find(".shooting-star-right"));
            Ember.set(this, 'shootingStarLeft', this.$().find(".shooting-star-left"));
            Ember.set(this, 'tlRoverWheels', new TimelineMax({ delay: 0.5, repeat: -1 }));
            Ember.set(this, 'tlBodyMove', new TimelineMax({ delay: 0.5 }));
            Ember.set(this, 'tlLeftWinning', new TimelineMax({ paused: true }));
            Ember.set(this, 'tlRightWinning', new TimelineMax({ paused: true }));
            Ember.set(this, 'tlStars', new TimelineLite());
            Ember.set(this, 'tlShootingStars', new TimelineMax({ repeat: -1, repeatDelay: 2 }));
            Ember.set(this, 'tlreset', new TimelineMax({ paused: true }));
            Ember.set(this, 'tlMoveRover', new TimelineMax({ paused: true }));
            //movement
            //spin the wheels
            Ember.get(this, 'tlRoverWheels').to(Ember.get(this, 'rWheelRight'), 1, { rotation: 360, delay: 0, transformOrigin: "50% 50%", ease: Linear.easeNone }, "roverStart").to(Ember.get(this, 'rWheelLeft'), 1, { rotation: -360, delay: 0, transformOrigin: "50% 50%", ease: Linear.easeNone }, "roverStart");

            Ember.get(this, 'tlBodyMove').to(Ember.get(this, 'rLeftHead'), 0.5, { x: -10, delay: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "roverStart").to(Ember.get(this, 'rNeckLeft'), 0.5, { rotation: -20, delay: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "roverStart").to(Ember.get(this, 'rRightHead'), 0.5, { x: 10, delay: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "roverStart").to(Ember.get(this, 'rNeckRight'), 0.5, { rotation: 20, delay: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "roverStart").to(Ember.get(this, 'rLeftBody'), 0.5, { x: -10, delay: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "roverStart").to(Ember.get(this, 'rRightBody'), 0.5, { x: 10, delay: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "roverStart");

            Ember.get(this, 'tlRightWinning').to(Ember.get(this, 'roverWrapper'), 2.5, { marginLeft: "-10%", ease: Power2.easeInOut });

            Ember.get(this, 'tlLeftWinning').to(Ember.get(this, 'roverWrapper'), 2.5, { marginLeft: "10%", ease: Power2.easeInOut });
        },


        actions: {
            right: function right() {
                Ember.get(this, 'tlMoveRover').clear().to(Ember.get(this, 'roverWrapper'), 2.5, { marginLeft: '10%', ease: Power2.easeInOut }).resume();
            },
            left: function left() {
                Ember.get(this, 'tlMoveRover').clear().to(Ember.get(this, 'roverWrapper'), 2.5, { marginLeft: '-10%', ease: Power2.easeIn }).resume();
            },
            reset: function reset() {
                Ember.get(this, 'tlMoveRover').clear().to(Ember.get(this, 'roverWrapper'), 1.25, { marginLeft: '0%', ease: Power2.easeIn }).resume();
            }
        }
    });
});
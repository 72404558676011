define('ohs/controllers/teacher-results-list', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  //helper function
  function ids() {
    return this.map(function (i) {
      return Ember.get(i, 'id');
    });
  }

  exports.default = Ember.Controller.extend({
    ////injections 
    session: Ember.inject.service('session'),

    ////properties
    showLastYearsResults: false,
    selectedSortId: 1,
    selectedSubjectId: '',
    numberShown: 10,
    sortByOptions: [{ id: 1, text: 'Active Sessions' }, { id: 2, text: 'Date Given' }, { id: 3, text: 'Name' }, { id: 4, text: 'Standard' }],

    ////mutilined computeds 
    //options for subject drop down
    subjectOptions: Ember.computed('administrationsBySchoolYear.@each.quiz', function () {
      return Ember.get(this, 'administrationsBySchoolYear').reduce(function (subjects, administration) {
        if (ids.call(subjects).includes(Ember.get(administration, 'quiz.subject.id'))) return subjects;

        return subjects.concat(Ember.get(administration, 'quiz.subject'));
      }, []);
    }),

    //current school year
    currentSchoolYear: Ember.computed('model.schoolYears.{[],@each.isCurrent}', function () {
      return Ember.get(this, 'model.schoolYears').findBy('isCurrent');
    }),

    lastSchoolYear: Ember.computed('currentSchoolYear', function () {
      var currentSchoolYearSort = Ember.get(this, 'currentSchoolYear.sort');
      return Ember.get(this, 'model.schoolYears').findBy('sort', currentSchoolYearSort - 1);
    }),

    //the selected subject 
    selectedSubject: Ember.computed('selectedSubjectId', function () {
      var selectedSubjectId = Ember.get(this, 'selectedSubjectId');
      if (selectedSubjectId) return Ember.get(this, 'store').peekRecord('subject', Ember.get(this, 'selectedSubjectId'));

      return null;
    }),

    onlyCurrentSchoolYearResults: Ember.computed('currentSchoolYear', 'userAdministrations.[]', function () {
      var start = (0, _moment.default)(Ember.get(this, 'currentSchoolYear.start'));
      return !Ember.get(this, 'userAdministrations').toArray().some(function (administration) {
        var date = (0, _moment.default)(Ember.get(administration, 'startTime'));
        return date.isBefore(start);
      });
    }),

    //this users administrations
    userAdministrations: Ember.computed('model.administrations.[]', function () {
      var userId = Ember.get(this, 'session.session.content.authenticated.userId');
      return Ember.get(this, 'model.administrations').filterBy('quiz.createUserId', userId).filterBy('parentAdministrationId', undefined);
    }),

    //the users administrations filtered by the current school year, unless they ask to see the previous year as well
    administrationsBySchoolYear: Ember.computed('userAdministrations.[]', 'currentSchoolYear', 'showLastYearsResults', function () {
      var showPrevious = Ember.get(this, 'showLastYearsResults');
      var start = (0, _moment.default)(showPrevious ? Ember.get(this, 'lastSchoolYear.start') : Ember.get(this, 'currentSchoolYear.start'));
      var end = (0, _moment.default)(Ember.get(this, 'currentSchoolYear.end'));

      return Ember.get(this, 'userAdministrations').reduce(function (filtered, administration) {
        var date = (0, _moment.default)(Ember.get(administration, 'startTime'));
        if (date.isBefore(end) && date.isAfter(start)) filtered.push(administration);

        return filtered;
      }, []);
    }),

    filteredAdministrations: Ember.computed('administrationsBySchoolYear.[]', 'selectedSubject', function () {
      var subject = Ember.get(this, 'selectedSubject');
      var administrations = Ember.get(this, 'administrationsBySchoolYear');

      if (subject) administrations = administrations.filterBy('quiz.subject.id', Ember.get(subject, 'id'));

      return administrations;
    }),

    sortedAdministrations: Ember.computed('filteredAdministrations.[]', 'selectedSortId', function () {
      var administrations = Ember.get(this, 'filteredAdministrations');
      switch (parseInt(Ember.get(this, 'selectedSortId'))) {
        case 1:
          return administrations.sortBy('isComplete');
          break;
        case 2:
          return administrations.sortBy('startTime').reverse();
          break;
        case 3:
          return administrations.sortBy('quiz.quizName');
          break;
        case 4:
          return administrations.sortBy('quiz.standardCodeDisplay');
          break;
      }
    })
  });
});
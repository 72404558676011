define('ohs/components/user-profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    validationMsg: null,
    store: Ember.inject.service(),
    tick: 1,
    syncPassword: 1,
    forcePasswordChange: true,

    startEdit: Ember.observer('isEdit', function () {
      if (this.get('isEdit')) {
        this.setFieldsForEdit();
      }
    }),

    setFieldsForEdit: function setFieldsForEdit() {
      var role = this.get('user').get('userRole');
      if (role === "Teacher" || role === "Tutor") {
        if (this.get('user').get('stateId') == 39) {
          this.set('standardTypeSelected', true);
        } else {
          this.set('standardTypeSelected', false);
          this.set('standardTypeId', 2011);
        }
      } else {
        this.set('standardTypeId', null);
      }
      this.set('forcePasswordChange', this.get('user.forcePasswordChange'));

      this.set('user.firstNameEdit', this.get('user.firstName'));
      this.set('user.lastNameEdit', this.get('user.lastName'));
      this.set('user.emailEdit', this.get('user.email'));
      this.set('user.userNameEdit', this.get('user.userName'));
      this.set('user.studentIdEdit', this.get('user.studentId'));

      var username = this.get('user.userName');
      if (username.indexOf('.') > 0) this.set('user.currentSuffix', username.substring(username.indexOf('.')));
    },

    actions: {
      editUser: function editUser() {
        this.set('isEdit', true);
      },

      saveUser: function saveUser() {
        //make sure we have a validation array on the user object
        if (!this.get('user.invalidAttributes')) {
          this.set('user.invalidAttributes', new Array());
        }
        if (this.get('user.invalidAttributes.length') == 0) {
          this.set('isProcessing', true);
          var self = this;
          document.activeElement.blur();

          //update user
          var userId = this.get('user.id');
          var initialFirstName = this.get('user.firstName');
          var initialLastName = this.get('user.lastName');
          var initialEmail = this.get('user.email');
          var initialUsername = this.get('user.userName');
          var initialStudentId = this.get('user.studentId');

          var user = this.get('user');
          user.set('firstName', this.get('user.firstNameEdit'));
          user.set('lastName', this.get('user.lastNameEdit'));
          user.set('email', this.get('user.emailEdit'));
          user.set('userName', this.get('user.userNameEdit'));
          user.set('studentId', this.get('user.studentIdEdit'));

          //For teachers and tutors email address is the username
          if (user.get('userRole') === 'Teacher' || user.get('userRole') === 'Tutor') {
            user.set('userName', user.get('email'));
          }

          //increment the tick so that validation is done for each field
          this.set('tick', this.get('tick') + 1);

          //set forcePasswordChange
          if (!user.get('lastLoginFormatted')) {
            user.set('forcePasswordChange', this.get('forcePasswordChange'));
          } else {
            user.set('forcePasswordChange', 0);
          }

          //delay for 2 seconds to allow validations to happen
          setTimeout(function () {
            if (self.get('user.invalidAttributes.length') > 0) {
              //user needs to fix validation errorMessage
              self.set('isProcessing', false);
            } else {
              //save the user
              if (!self.get('self-edit')) {
                user.set('isThroughAccountAdmin', true);
                if (self.get('lastVisitedRoute') == 'all-students') {
                  user.set('hasTeacherStudentLink', true);
                }
                var account = self.get('store').peekAll('account');
                var accountId = account.objectAt(0).id;
                user.set('accountId', accountId);
                user.set('avatar', 'Green/aliens_final_green-01.svg');
                user.set('errorId', self.get('errorId'));
                //  Added temprorly to fix the error while creating a user.
                user.set('surveyReferralTypeId', 7);
              }
              user.save().then(function (u) {
                self.set('isProcessing', false);
                self.set('showConfirm', true);

                if (self.get('isNew')) {
                  self.set('confirmMessage', 'The user has been created!');
                } else {
                  if (user.get('userRole') == 'Student') {
                    self.set('confirmMessage', 'Your information has been updated!');
                  } else {
                    if (initialEmail.toLowerCase() == u.get('email').toLowerCase()) {
                      self.set('confirmMessage', 'Your information has been updated!');
                    } else {
                      self.set('confirmMessage', 'Your information has been updated! We just sent an email to your new email address. Before you can log in with your new email address, you will have to click the verification link in that email');
                    }
                  }

                  self.set('isEdit', false);
                }
              }, function () {
                self.set('isProcessing', false);
                if (self.get('isNew')) {
                  self.set('alertMessage', 'There was a problem creating the user. Please check your internet connection and try again.');
                } else {
                  self.set('alertMessage', 'There was a problem updating your information. Please check your internet connection and try again.');
                }
              });
            }
          }, 1000);
        }
      },

      cancelUser: function cancelUser() {
        if (this.get('isNew')) {
          // var route;
          // if(this.get('user.userRole')=='Student'){
          //   route='manage-students';
          // }
          // else if(this.get('user.userRole')=='Teacher'){
          //   route='manage-teachers';
          // }

          this.sendAction('transitionAction', this.get('lastVisitedRoute'));
        } else {
          var googleEmail = this.get('user.googleEmail');
          this.get('user').rollbackAttributes();
          this.set('user.googleEmail', googleEmail);
          this.set('validationMsg', null);
          this.set('isEdit', false);
        }
      },

      hideConfirm: function hideConfirm() {
        this.set('showConfirm', false);
        if (this.get('isNew')) {
          this.sendAction('transitionAction', this.get('lastVisitedRoute'));
        } else {
          //  This is for update user (student) header section by reloading user model in the application route.
          if (this.get('user').get('userRole') == 'Student' || this.get('isFromAccountCreation')) {
            this.sendAction('hideAction');
          }
        }
      },

      setGradeLevel: function setGradeLevel(selectedValue) {
        this.set('user.gradeLevelId', selectedValue);
      },
      setPrimarySubject: function setPrimarySubject(selectedValue) {
        this.set('user.primarySubjectId', selectedValue);
      },

      setState: function setState(selectedValue) {
        var role = this.get('user.userRole');
        this.set('user.stateId', selectedValue);
        if (role === "Teacher" || role === "Tutor") {
          if (selectedValue == 39) {
            this.set('standardTypeSelected', true);
          } else {
            this.set('standardTypeSelected', false);
            this.get('user').set('standardTypeId', 2011);
          }
        } else {
          this.get('user').set('standardTypeId', null);
        }
      },

      setStandardType: function setStandardType(selectedValue) {
        this.set('user.standardTypeId', selectedValue);
      },

      setHonorific: function setHonorific(selectedValue) {
        this.set('user.honorific', selectedValue);
      },
      setForcePasswordChange: function setForcePasswordChange(selectedValue) {
        this.toggleProperty('forcePasswordChange', selectedValue);
      }
    }
  });
});
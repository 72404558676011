define("ohs/components/sign-in", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showPassword: false,
    username: "",
    password: "",
    isProcessing: false,
    isSlowConnection: false,
    timeout: null,
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    authenticator: 'authenticator:custom',

    onSignIn: function onSignIn(googleUser) {
      var profile = googleUser.getBasicProfile();
      console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
      console.log('Name: ' + profile.getName());
      console.log('Image URL: ' + profile.getImageUrl());
      console.log('Email: ' + profile.getEmail());
    },

    actions: {

      pickTeacher: function pickTeacher() {
        this.get('router').transitionTo('sign-up', ["Teacher"]);
      },
      pickStudent: function pickStudent() {
        this.get('router').transitionTo('sign-up', ["Student"]);
      },
      pickSubscription: function pickSubscription() {
        this.get('router').transitionTo('purchase');
      },
      authenticate: function authenticate() {
        var _this = this;

        document.activeElement.blur();
        this.set('validationErrors', '');
        this.set('successMessages', '');

        var credentials = this.getProperties('username', 'password');
        if (credentials.username.trim() && credentials.password) {
          this.set('isProcessing', true);
          this.get('session').authenticate('authenticator:custom', credentials).catch(function (message) {
            if (message) {
              var obj = JSON.parse(message);

              if (obj && obj.error == "account_disabled") {
                _this.sendAction('disableAction', obj.error_uri);
              } else {
                _this.set('validationErrors', obj.error_description);
              }
            } else {
              _this.set('validationErrors', 'Error occurred. Please try again.');
            }
            _this.set('isProcessing', false);
          });
        } else if (!credentials.username.trim() && !credentials.password) {
          this.set('validationErrors', "The username and password can't be blank");
          this.set('isProcessing', false);
        } else if (!credentials.password) {
          this.set('validationErrors', "The password can't be blank.");
          this.set('isProcessing', false);
        } else if (!credentials.username.trim()) {
          this.set('validationErrors', "The username can't be blank.");
          this.set('isProcessing', false);
        } else {
          this.set('validationErrors', 'There is an unspecified error regarding credentials.');
          this.set('isProcessing', false);
        }
      },

      resendVerificationEmail: function resendVerificationEmail() {
        this.set('validationErrors', '');
        this.set('successMessages', '');

        var self = this;

        Ember.$.getJSON(self.get('store').adapterFor('application').get('host') + '/ResendVerificationEmail?userName=' + self.username + ' &pwd=' + encodeURIComponent(self.password)).done(function (response) {
          if (response.id) {
            self.set('successMessages', 'Your verification email has been re-sent to the registered email');
          } else {
            self.set('validationErrors', response);
          }
        }).fail(function (response) {
          self.set('validationErrors', 'Verification email resend failed!');
        });
      },

      googleLogin: function googleLogin() {
        document.activeElement.blur();
        this.set('validationErrors', '');
        this.set('successMessages', '');
        var self = this;
        if (gapi.auth2 && gapi.auth2.getAuthInstance()) {
          gapi.auth2.getAuthInstance().signIn().then(function (googleUser) {
            self.set('isProcessing', true);
            var credentials = { 'idtoken': googleUser.getAuthResponse().id_token };
            self.get('session').authenticate('authenticator:custom', credentials).catch(function (message) {
              if (message) {
                var obj = JSON.parse(message);

                if (obj && obj.error == "account_disabled") {
                  self.sendAction('disableAction', obj.error_uri);
                } else if (obj && obj.message) {
                  self.set('validationErrors', obj.message);
                } else {
                  self.set('validationErrors', obj.error_description);
                }
              } else {
                self.set('validationErrors', 'Error occurred. Please try again.');
              }
              self.set('isProcessing', false);
            });
          });
        } else {
          this.set('validationErrors', 'Something went wrong. Please refresh the page and try again or log in with your Kandoolu credentials.');
        }
      },

      schoologyLogin: function schoologyLogin() {
        var self = this;
        //get a request token
        Ember.$.getJSON(this.get('store').adapterFor('application').get('host') + '/getSchoologyRequestTokens').done(function (response) {
          if (response.oauth_token) {
            document.cookie = "SRT=" + response.oauth_token;
            document.cookie = "SRTS=" + response.oauth_token_secret;
            window.location = "https://www.schoology.com/oauth/authorize?return_url=" + window.location.href.replace('/login', '/schoology-login') + "&oauth_token=" + response.oauth_token;
          } else {
            alert('fail');
          }
        }).fail(function (response) {
          var obj = JSON.parse(response);
          if (obj && obj.error == "account_disabled") {
            self.sendAction('disabledAction', obj.error_uri);
          }
        });
      }

    }

  });
});
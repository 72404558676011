define('ohs/initializers/on-hand-schools', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    _emberInflector.default.inflector.uncountable('eligible content');
  }

  exports.default = {
    name: 'on-hand-schools',
    initialize: initialize
  };
});
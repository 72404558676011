define('ohs/routes/start-quiz-2', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/tip-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _tipMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _tipMixin.default, {
        signalR: Ember.inject.service('signal-r'),

        titleToken: function titleToken(model) {
            return model.quiz.get('quizName') + ' In Progress';
        },

        model: function model(params) {
            var store = this.store;
            var sesObj = this.get('session');
            var self = this;
            var userId = sesObj.session.content.authenticated["userId"];
            return Ember.RSVP.hash({
                quiz: this.store.peekRecord('quiz', params.quiz_id),
                testTypeId: params.test_type_id,
                duration: null,
                durationType: null,
                validationErrors: '',
                teacher: this.store.find('teacher', userId),
                //teacherLinkedStudents: store.findAll('student'),//get all teacher linked students
                orderedStudents: Ember.computed('teacher.students.@each.id', function () {
                    return this.teacher.get('students').sortBy('fullNameReversed');
                }),
                selectedStudents: null,
                studentGroups: store.queryRecord('teacher', { userId: userId }),
                orderedGroups: Ember.computed('teacher.studentGroups.@each.id', function () {
                    return this.teacher.get('studentGroups').sortBy('studentGroupName');
                }),
                selectedStudentGroups: [],
                studentIds: [], //to hold student ids of student test administration
                isInviteAllSelected: false,
                endDate: null,
                endTime: '12:00',
                endAMPM: 'AM',
                minDate: new Date(),
                startDate: null,
                startTime: '12:00',
                startAMPM: 'AM'
                // endQuizTime: Ember.computed('model.endTime',function(){
                //   return self.get('endTime');
                // })
            });
        },
        afterModel: function afterModel(model) {
            var tip = this.store.peekRecord('tip', 2);
            if (tip) {
                model.showTip = true;
                model.tipText = tip.get('tipText');
            }
        },


        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            // Implement your custom setup after
            this.controllerFor('application').set('showSecondaryNav', true);
            this.controllerFor('application').set('teacher', true);
        },

        actions: {
            joinRoster: function joinRoster() {
                this.refresh();
            },


            setQuizCloseTime: function setQuizCloseTime(selectedValue) {
                Ember.set(this.currentModel, 'durationType', selectedValue);
            },

            goBack: function goBack() {
                this.set('currentModel.buttonsDisabled', true);
                this.transitionTo('start-quiz-1', this.get('currentModel.quiz.id'));
            },

            printQuestions: function printQuestions() {
                var self = this;

                //  Added by Jibin for feature 3098	(Kandoolu - Remove 'Print Quiz' option from users on a Free Trial) on 02/23/2017
                var account = self.get('store').peekAll('account');
                var isFreeTrial = account.objectAt(0).get('isFreeTrial');
                if (isFreeTrial && isFreeTrial == true) {
                    self.set('currentModel.showAlert', true);
                    self.set('currentModel.alertMessage', "Printing quizzes is disabled during your free trial. To enable this feature, please upgrade to a full subscription!");
                    return;
                }
                //  feature 3098 end

                self.set("currentModel.isProcessing", true);
                self.set('currentModel.isPrinting', true);

                var quizId = this.get('currentModel.quiz.id');
                var baseUrl = this.store.adapterFor('application').get('host');

                var sesObj = this.get('session');
                var userId = sesObj.session.content.authenticated["userId"];
                return Ember.$.getJSON(baseUrl + '/quiz/GenerateQuizQuestionsPdf?quizId=' + quizId + '&userId=' + encodeURIComponent(userId)).done(function (response) {
                    //self.refresh();
                    self.set("currentModel.isProcessing", false);

                    var xhr = new XMLHttpRequest();
                    xhr.open('GET', baseUrl + '/quiz/DownloadQuizQuestions?fileName=' + encodeURIComponent(response), true);
                    xhr.responseType = "blob";
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState == 4) {
                            var blob = new Blob([xhr.response], { type: "application/pdf" });
                            //
                            // var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
                            // if(isSafari){
                            //   var blob = new Blob([xhr.response], {type: "application/pdf"});
                            // }
                            // else{
                            //   var blob = new Blob([xhr.response], {type: "application/pdf"});
                            // }
                            saveAs(blob, response);
                        }
                    };
                    xhr.send(null);

                    self.set('currentModel.isPrinting', false);
                }).fail(function (response) {
                    self.set("currentModel.isProcessing", false);
                    self.set("currentModel.error", true);
                    self.set('currentModel.isPrinting', false);
                });
            },


            inviteStudents: function inviteStudents() {
                var self = this;
                var store = this.store;
                var hostAddress = self.get('store').adapterFor('application').get('host');
                self.set('currentModel.buttonsDisabled', true);
                var selectedStudentGroups = this.currentModel.selectedStudentGroups;
                var selectedStudents = this.currentModel.selectedStudents;
                var teacherLinkedStudents = this.get('currentModel.teacher.students');

                var startDateTime = null;
                var endDateTime = null;

                //Set quiz start time format
                if (this.get('currentModel.startDate')) {

                    startDateTime = this.convertTo24HourFormat(this.get('currentModel.startDate'), this.get('currentModel.startTime'), this.get('currentModel.startAMPM'));
                }

                //Set quiz end time format
                if (this.get('currentModel.endDate')) {
                    endDateTime = this.convertTo24HourFormat(this.get('currentModel.endDate'), this.get('currentModel.endTime'), this.get('currentModel.endAMPM'));
                }

                var currentDateTime = new Date();
                //If no start time is selected, the quiz will start immediately.
                //The IsOpen flag for the administration should be set to 1 right away so as to bypass the teacher quiz room
                var quizIsOpen = false;
                if (this.get('currentModel.testTypeId') == 1) {
                    if (!startDateTime) {
                        quizIsOpen = true;
                        startDateTime = currentDateTime;
                    } else {
                        if (startDateTime <= currentDateTime) {
                            self.set('currentModel.validationErrors', 'The start time needs to be in the future.');
                            self.set('currentModel.buttonsDisabled', false);
                            return true;
                        }
                    }
                }

                if (endDateTime) {
                    if (endDateTime <= currentDateTime) {
                        self.set('currentModel.validationErrors', 'The end time needs to be in the future.');
                        self.set('currentModel.buttonsDisabled', false);
                        return true;
                    }
                    if (startDateTime) {
                        if (endDateTime <= startDateTime) {
                            self.set('currentModel.validationErrors', 'The end time needs to be after the start time.');
                            self.set('currentModel.buttonsDisabled', false);
                            return true;
                        }
                    }
                }

                var admin = this.store.createRecord('administration', {
                    isOpen: quizIsOpen,
                    isComplete: false,
                    currentQuestionIndex: 0,
                    testTypeId: this.currentModel.testTypeId,
                    quiz: this.currentModel.quiz,
                    startTime: startDateTime,
                    endTime: endDateTime
                });

                this.set('currentModel.isProcessing', true);
                self.set('currentModel.validationErrors', '');

                if (this.currentModel.testTypeId == 1) // If the quiz is student controlled
                    {
                        admin.save().then(function () {
                            selectedStudentGroups.forEach(function (item, index, enumerable) {
                                Ember.$.getJSON(hostAddress + '/studentGroups/updateLastUsed?groupId=' + item.id).done(function (response) {
                                    if (response) var students = item.get('students');
                                    self.createStudentTestAdministration(store, self, students, admin.id, item.get('studentGroupName'));
                                }).fail(function (error) {
                                    self.controllerFor('application').set('errorStatusCode', error.status);
                                    self.transitionTo('error');
                                });
                            });

                            //manually added students
                            if (selectedStudents) {
                                self.createStudentTestAdministration(store, self, selectedStudents, admin.id, 'Invited manually');
                            }
                            //teacher linked students
                            if (self.get('currentModel.isInviteAllSelected')) {
                                self.createStudentTestAdministration(store, self, teacherLinkedStudents, admin.id, 'All Students');
                            }

                            //If administration is open, directly go to results page
                            if (admin.get('isOpen')) {
                                self.transitionTo('teacher-self-guided-results', admin.id);
                            } else {
                                self.transitionTo('teacher-quiz-room', admin.id);
                            }
                        }, function () {
                            admin.rollbackAttributes();
                            self.set("currentModel.showAlert", true);
                            self.set("currentModel.alertMessage", "Your quiz could not be started. Please check your internet connection and try again.");
                            self.set('currentModel.buttonsDisabled', false);
                            //hide loading panel
                            self.set('currentModel.isProcessing', false);
                        });
                        //}
                        // else if (!this.get('duration')) {
                        //   self.set('currentModel.validationErrors','Enter quiz closes in duration');
                        //   }
                        // else if (!this.get('durationType')) {
                        //   self.set('currentModel.validationErrors','Select a duration type');
                        //   }
                    } else {
                    // If the quiz is teacher controlled

                    admin.save().then(function () {
                        //student groups
                        selectedStudentGroups.forEach(function (item, index, enumerable) {
                            Ember.$.getJSON(hostAddress + '/studentGroups/updateLastUsed?groupId=' + item.id).done(function (response) {
                                if (response) var students = item.get('students');
                                self.createStudentTestAdministration(store, self, students, admin.id, item.get('studentGroupName'));
                            }).fail(function (error) {
                                self.controllerFor('application').set('errorStatusCode', error.status);
                                self.transitionTo('error');
                            });
                        });

                        //manually added students
                        if (selectedStudents) {
                            self.createStudentTestAdministration(store, self, selectedStudents, admin.id, 'Invited manually');
                        }

                        //teacher linked students
                        if (self.get('currentModel.isInviteAllSelected')) {
                            self.createStudentTestAdministration(store, self, teacherLinkedStudents, admin.id, 'All Students');
                        }

                        //transition to proper route
                        if (get(self, 'currentModel.quiz.subject.id') == 2) self.transitionTo('teacher.quiz.teacher-led.lobby', admin.id);else self.transitionTo('teacher-quiz-room', admin.id);
                    }, function () {
                        admin.rollbackAttributes();
                        self.set("currentModel.showAlert", true);
                        self.set("currentModel.alertMessage", "Your quiz could not be started. Please check your internet connection and try again.");
                        self.set('currentModel.buttonsDisabled', false);
                        //hide loading panel
                        self.set('currentModel.isProcessing', false);
                    });
                }
            },

            //Hide alert box
            hideAlert: function hideAlert() {
                this.set("currentModel.showAlert", false);
                this.set("currentModel.alertMessage", "");
            },

            //Add/Remove selected student group from selectedStudentGroups array
            setSelectedStudentGroup: function setSelectedStudentGroup(selectedValue) {
                var studentGroupId = selectedValue;
                if (studentGroupId) {
                    var studentGroup = this.store.peekRecord('student-group', parseInt(studentGroupId));
                    //If student group already exist in array remove it otherwise add it
                    if (this.currentModel.selectedStudentGroups.contains(studentGroup)) {
                        this.currentModel.selectedStudentGroups.removeObject(studentGroup);
                    } else {
                        this.currentModel.selectedStudentGroups.pushObject(studentGroup);
                    }
                }
            },
            setInviteAllStudents: function setInviteAllStudents(selectedValue) {
                this.toggleProperty('currentModel.isInviteAllSelected');
            },


            loading: function loading(transition, originRoute) {
                if (originRoute != this) return true;
            },

            setEndTime: function setEndTime(time) {
                this.set('currentModel.endTime', time);
            },

            closeTip: function closeTip() {
                this.destroyTip(2);
                this.set('currentModel.showTip', false);
            },

            showTip: function showTip() {
                var self = this;
                var uid = this.modelFor('application').user.id;
                var userTip = this.store.createRecord('userTip', {
                    userId: uid,
                    tipId: 2
                });
                userTip.save().then(function () {
                    var tip = self.store.peekRecord('tip', 2);

                    if (tip) {
                        self.set('currentModel.showTip', true);
                        self.set('currentModel.tipText', tip.get('tipText'));
                    }
                });
            }

        },
        //Methods

        getEndTime: function getEndTime(duration, durationType) {
            var currentDateTime = new Date();
            duration = parseInt(duration);

            if (durationType == 'minutes') {
                currentDateTime = currentDateTime.setMinutes(currentDateTime.getMinutes() + duration);
            } else if (durationType == 'hours') {
                currentDateTime = currentDateTime.setHours(currentDateTime.getHours() + duration);
            } else if (durationType == 'days') {
                currentDateTime = currentDateTime.setDate(currentDateTime.getDate() + duration);
            } else if (durationType == 'weeks') {
                currentDateTime = currentDateTime.setDate(currentDateTime.getDate() + duration * 7);
            } else if (durationType == 'months') {
                currentDateTime = currentDateTime.setMonth(currentDateTime.getMonth() + duration);
            }
            return new Date(currentDateTime);
        },
        createStudentTestAdministration: function createStudentTestAdministration(store, self, students, adminId, invitedThrough) {
            //Iterate through students in student group
            students.forEach(function (item, index, enumerable) {
                //Check whether the test administration is created or not for the current student
                if (!self.currentModel.studentIds.contains(item.id)) {
                    self.currentModel.studentIds.pushObject(item.id);
                    //create student test administration
                    var sta = store.createRecord('studentTestAdministration', { administrationId: adminId, userId: item.id, invitedThrough: invitedThrough });
                    sta.save().then(null, function () {
                        sta.rollbackAttributes();
                        self.set("currentModel.showAlert", true);
                        self.set("currentModel.alertMessage", "Student could not be added to the quiz. Please check your internet connection and try again.");
                    });
                }
            });
        },
        convertTo24HourFormat: function convertTo24HourFormat(date, time, formatAMPM) {
            var splittedTime = time.split(':');
            var hour = parseInt(splittedTime[0]);
            var minute = parseInt(splittedTime[1]);
            if (formatAMPM == "PM" && hour < 12) hour = hour + 12;
            if (formatAMPM == "AM" && hour == 12) hour = hour - 12;
            if (hour < 10) hour = "0" + hour;
            var dateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, minute, "00");
            return dateTime;
        }
    });
});
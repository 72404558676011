define("ohs/helpers/image-helper", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.imageHelper = imageHelper;
  function imageHelper(params /*, hash*/) {
    var html = params[0];
    if (html) {
      html = html.replace(/\/Media\/Question/gi, "http://tlmcontentmanager.kandoolu.com/Media/Question");
      html = html.replace(/\/generic_wiris\/integration/gi, "http://tlmcontentmanager.kandoolu.com/generic_wiris/integration");
      html = html.replace(/tlmcontentmanager.azurewebsites.nethttp:\/\//gi, "");
      html = html.replace(/\/QuestionAudio/gi, "http://tlmcontentmanager.kandoolu.com/QuestionAudio");
      html = spanWrapper(html);
      html = fixSVG(html);
      return Ember.String.htmlSafe(html);
    }
  }

  /*
   * I fixed this by better using regex (4/1/2019). However, if this becomes problematic again just use regular dom manipulation.
   *  1. Generate a div with param as the innerHTML.
   *  2. Find every img tag -> div.querySelectorAll("img")
   *  3. For every img tag, generate a span and wrap the image tag
   *  4. Replace the image in the div with the new span + image tag.
   */

  /**
   * Wraps all image tags in an html string with a <span style="display: inline-block"></span>
   * @param {*} param
   */
  function spanWrapper(param) {
    var matches = param.match(/<img([\w\W]+?)>/g) || [];

    for (var x = 0; x < matches.length; x++) {
      // image tag
      var image = matches[x];
      var startPos = param.indexOf(image);
      var endPos = startPos + image.length;
      var beforeImage = param.slice(0, startPos);
      var afterImage = param.slice(endPos);

      // reconstruct the string by wrapping the image tag with a span
      param = beforeImage + "<span style=\"display: inline-block\">" + image + "</span>" + afterImage;
    }

    return param;
  }

  function fixSVG(html) {
    html = html.replace(/#SVG/gi, window.location.pathname + "#SVG");
    return html;
  }

  exports.default = Ember.Helper.helper(imageHelper);
});
define('ohs/routes/student-resources', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/student-authorize-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _studentAuthorizeMixin, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _studentAuthorizeMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Resources For You',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('student', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var _this = this;

      return this.store.findAll('resource-assigned').then(function (ra) {
        return Ember.RSVP.hash({
          assignedResources: ra,
          studentRatings: _this.store.findAll('resource-student-rating'),
          studentTrophies: _this.modelFor('application').studentTrophies,
          student: _this.modelFor('application').user,
          selectedFilter: 1,
          selectedSort: '2',
          finalSearch: '',
          searchBox: '',
          perPage: 10,
          numShown: 10,
          hasMoreItems: Ember.computed('assignedResources.@each.id', 'numShown', function () {
            if (this.assignedResources.get('length') > this.numShown) {
              return true;
            } else {
              return false;
            }
          }),

          filteredAssignedResources: Ember.computed('assignedResources.@each.id', 'selectedFilter', 'selectedSort', 'finalSearch', 'searchBox', 'numShown', function () {

            var results = this.assignedResources.filter(function (assignedResource, index, array) {
              // trim extra space from the resource name
              assignedResource.set('resource.resourceName', assignedResource.get('resource.resourceName').trim());
              var typeFilter = true;
              switch (this.selectedFilter) {
                case '2':
                  typeFilter = assignedResource.get('viewed') == false;
                  break;
                case '3':
                  typeFilter = assignedResource.get('viewed') == true;
                  break;
                default:
                  typeFilter = true;
              }

              var keywordSearch = true;
              if (this.searchBox.length > 0) {
                keywordSearch = assignedResource.get('resource.resourceName').toLowerCase().indexOf(this.searchBox.toLowerCase()) >= 0 || assignedResource.get('resource.description').toLowerCase().indexOf(this.searchBox.toLowerCase()) >= 0;
              }
              return typeFilter && keywordSearch;
            }, this);

            switch (this.selectedSort) {
              case '1':
                results = results.sortBy('viewedForSorting', 'assignedDate').reverse();
                break;
              case '2':
                results = results.sortBy('assignedDate').reverse();
                break;
              case '3':
                results = results.sortBy('viewedDate').reverse();
                break;
              case '4':
                results = results.sortBy('resource.resourceName');
                break;
              case '5':
                results = results.sortBy('user.lastName');
                break;
              default:
                results = results.sortBy('viewed');
            }

            return results.slice(0, this.numShown);
          }),

          filterOpts: [{
            id: 1,
            text: "All"
          }, {
            id: 2,
            text: "New Only"
          }, {
            id: 3,
            text: "Visited Only"
          }],
          sortingOpts: [{
            id: 2,
            text: "Received Date"
          }, {
            id: 3,
            text: "Viewed Date"
          }, {
            id: 4,
            text: "Resource Name"
          }, {
            id: 5,
            text: "Sender"
          }]
        });
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Resources', false, true);
    },

    actions: {
      hideKeyboard: function hideKeyboard() {
        document.activeElement.blur();
      },

      createTrophy: function createTrophy() {
        var _this2 = this;

        this.store.findRecord('trophy', 15).then(function (trophy) {
          var trophy = _this2.store.createRecord('student-trophy', {
            student: _this2.get('currentModel.student'),
            trophy: trophy,
            dateEarned: new Date(),
            studentViewed: false
          });
          trophy.save();
        });
      },

      setFilter: function setFilter(value) {
        this.set('currentModel.selectedFilter', value);
        if (value == 2) {
          this.set('currentModel.sortingOpts', [{
            id: 2,
            text: "Received Date"
          }, {
            id: 4,
            text: "Resource Name"
          }, {
            id: 5,
            text: "Sender"
          }]);
          if (this.get('currentModel.selectedSort') == 3) {
            this.set('currentModel.selectedSort', 2);
          }
        } else {
          this.set('currentModel.sortingOpts', [{
            id: 2,
            text: "Received Date"
          }, {
            id: 3,
            text: "Viewed Date"
          }, {
            id: 4,
            text: "Resource Name"
          }, {
            id: 5,
            text: "Sender"
          }]);
        }
      },

      setSort: function setSort(value) {
        this.set('currentModel.selectedSort', value);
      }
    }
  });
});
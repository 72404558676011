define('ohs/routes/change-password', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset'], function (exports, _authenticatedRouteMixin, _reset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, {
    titleToken: 'Change Password',
    lastVisitedRoute: null,
    forcePasswordChange: false,
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);

      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
        }
      }
    },

    beforeModel: function beforeModel() {
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (!roles) {
          this.transitionTo('home');
        }
      }

      //Find the previous route and redirect to it after completing the task
      var previousRoutes = this.router.router.currentHandlerInfos;
      var previousRoute = previousRoutes && previousRoutes.pop();
      if (previousRoute) {
        this.set('lastVisitedRoute', previousRoute.name);
      }
    },

    model: function model(params) {
      return Ember.RSVP.hash({
        changePassword: this.store.createRecord('change-password', { userId: params.userId }),
        showForcePasswordChange: params.force_password_change,
        forcePasswordChange: false,
        tick: 1,
        syncPassword: 1
      });
    },


    actions: {
      changePassword: function changePassword() {
        document.activeElement.blur();
        var self = this;
        if (!self.get('currentModel.changePassword.invalidAttributes')) {
          self.set('currentModel.changePassword.invalidAttributes', new Array());
        }
        if (self.get('currentModel.changePassword.invalidAttributes.length') == 0) {
          self.set('currentModel.isProcessing', true);

          //increment the tick so that validation is done for each field
          self.set('currentModel.tick', self.get('currentModel.tick') + 1);

          setTimeout(function () {
            if (self.get('currentModel.changePassword.invalidAttributes.length') > 0) {
              //user needs to fix validation errorMessage
              self.set('currentModel.isProcessing', false);
            } else {
              var host = self.get('store').adapterFor('application').get('host');

              var _Ember$getProperties = Ember.getProperties(self.currentModel.changePassword, ['currentPassword', 'newPassword', 'confirmNewPassword', 'userId']),
                  currentPassword = _Ember$getProperties.currentPassword,
                  newPassword = _Ember$getProperties.newPassword,
                  confirmNewPassword = _Ember$getProperties.confirmNewPassword,
                  userId = _Ember$getProperties.userId;

              Ember.$.ajax({
                type: 'POST',
                url: host + '/changePasswords',
                data: {
                  currentPassword: currentPassword,
                  newPassword: newPassword,
                  confirmNewPassword: confirmNewPassword,
                  userId: userId
                }
              }).then(function () {
                //set the ForcePasswordChange flag to true or false in aspnetuser table
                if (self.get('currentModel.forcePasswordChange') != null) {
                  var student = self.store.peekRecord('student', self.get('currentModel.changePassword.userId'));
                  if (student) {
                    student.set('forcePasswordChange', self.get('currentModel.forcePasswordChange'));
                    student.save();
                    self.set('currentModel.showConfirm', true);
                    self.set('currentModel.isProcessing', false);
                  } else {
                    self.set('currentModel.showConfirm', true);
                    self.set('currentModel.isProcessing', false);
                  }
                }
              }, function (response) {
                self.set("currentModel.showAlert", true);
                self.set('currentModel.isProcessing', false);
                if (response.responseJSON.modelState && response.responseJSON.modelState[""]) {
                  //must say I'm not proud of this line of code.
                  self.set("currentModel.validationMsg", response.responseJSON.modelState[""][0] === 'Incorrect password.' ? 'Your current password is incorrect.' : response.responseJSON.modelState[""][0]);
                } else {
                  self.set("currentModel.validationMsg", "Failed to change password. Please check your internet connection and try again.");
                }
              });
            }
          }, 500);
        }
      },


      hideConfirm: function hideConfirm() {
        if (this.get('lastVisitedRoute') == 'user-profile') {
          this.transitionTo('user-profile');
        } else if (this.get('lastVisitedRoute')) {
          this.transitionTo(this.get('lastVisitedRoute'), this.get('currentModel.changePassword.userId'));
        } else {
          this.transitionTo('user-profile');
        }
      },

      cancel: function cancel() {
        if (this.get('lastVisitedRoute') == 'user-profile') {
          this.transitionTo('user-profile');
        } else if (this.get('lastVisitedRoute')) {
          this.transitionTo(this.get('lastVisitedRoute'), this.get('currentModel.changePassword.userId'));
        } else {
          this.transitionTo('user-profile');
        }
      },
      setForcePasswordChange: function setForcePasswordChange(selectedValue) {
        //this.set('currentModel.selectedForcePasswordChange',this.get('currentModel.forcePasswordChange'));
        this.toggleProperty('currentModel.forcePasswordChange');
      }
    }
  });
});
define('ohs/models/standard', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    standardCode: _emberData.default.attr('string'),
    standardDesc: _emberData.default.attr('string'),
    //quiz: DS.belongsTo('quiz')
    gradeLevelId: _emberData.default.attr('number'),
    standardTypeId: _emberData.default.attr('number'),
    standardLevelId: _emberData.default.attr('number'),
    parentStandardId: _emberData.default.attr('number'),
    subjectId: _emberData.default.attr('number'),
    standardOrderId: _emberData.default.attr('number'),

    text: Ember.computed('standardCode', 'standardDesc', function () {
      if (this.get('standardCode')) {
        return this.get('standardCode') + " - " + this.get('standardDesc');
      } else {
        return this.get('standardDesc');
      }
    }),

    numQuestionsTested: _emberData.default.attr('number')

    //question: DS.belongsTo('question'),
    //quiz: DS.belongsTo('quiz')
  });
});
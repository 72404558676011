define('ohs/routes/schoology-sign-in', ['exports', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/authenticated-route-mixin'], function (exports, _reset, _noNavigation, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _reset.default, _noNavigation.default, {

    beforeModel: function beforeModel() {},

    model: function model(params) {
      return Ember.RSVP.hash({
        showLoad: true,
        user: this.store.createRecord('user', { userRole: params.role }),
        stateOptions: this.store.findAll('state'),
        standardTypeOptions: this.store.findAll('standard-type'),
        studentGrades: this.store.query('grade', { 'gradeType': 'StudentProfle' }),
        teacherGrades: this.store.query('grade', { 'gradeType': 'AllGrades' }),
        isSchoologySignUp: true,
        typeChosen: true,
        role: params.role
      });
    },
    afterModel: function afterModel(model) {
      var self = this;
      //get cookies
      var pairs = document.cookie.split(";");
      var cookies = {};
      for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].trim().split("=");
        cookies[pair[0]] = unescape(pair[1]);
      }

      if (cookies.SRT && cookies.SRTS) {
        var adapter = this.store.adapterFor('application');
        Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/getSchoologyAccessTokens?SRT=' + cookies.SRT + '&SRTS=' + cookies.SRTS).done(function (response) {
          //clear out cookies
          var cookie_date = new Date();
          cookie_date.setTime(cookie_date.getTime() - 1);
          document.cookie = 'SRT=; Path=/; Expires=' + cookie_date.toGMTString();
          document.cookie = 'SRTS=; Path=/; Expires=' + cookie_date.toGMTString();
          if (response.oauth_token) {
            var at = response.oauth_token;
            var accessSecret = response.oauth_token_secret;
            document.cookie = "SRT=" + response.oauth_token;
            document.cookie = "SRTS=" + response.oauth_token_secret;
            var url = adapter.get('host') + '/isSchoologyIdAlreadyLinked?at=' + at + '&accessSecret=' + accessSecret;
            adapter.ajax(url, 'GET').then(function (response) {
              self.set('currentModel.user.honorific', response.name_title);
              self.set('currentModel.user.firstName', response.name_first);
              self.set('currentModel.user.lastName', response.name_last);
              self.set('currentModel.user.userName', response.username);
              self.set('currentModel.user.email', response.primary_email);
              if (!response.primary_email) {
                self.set('currentModel.user.emailRequired', true);
              } else {
                if (self.get('currentModel.user.userRole') == "Student") {
                  self.set('currentModel.user.isFreeTrial', false);
                  self.set('currentModel.showLoad', false);
                } else {
                  self.setFreeTrialFlag(self.get('currentModel.user.email'));
                }
              }
              self.set('currentModel.user.schoologyId', response.uid);
              self.set('currentModel.user.schoologyAccess', at + '|||' + accessSecret);
              if (response.username) {
                self.set('currentModel.user.schoologyUsername', response.username);
              } else if (response.primary_email) {
                self.set('currentModel.user.schoologyUsername', response.primary_email);
              } else {
                self.set('currentModel.user.schoologyUsername', response.name_first + ' ' + response.name_last);
              }
            }, function (response) {
              self.set('currentModel.showAlert', true);
              self.set('currentModel.alertText', response.errors[0].detail);
            });
          } else {
            self.set('currentModel.showAlert', true);
            self.set('currentModel.alertText', 'Error occurred. Please try again.');
          }
        }).fail(function (response) {
          self.set('currentModel.showAlert', true);
          self.set('currentModel.alertText', 'Something went wrong. Please refresh the page and try again.');
          //clear out cookies
          var cookie_date = new Date();
          cookie_date.setTime(cookie_date.getTime() - 1);
          document.cookie = 'SRT=; Path=/; Expires=' + cookie_date.toGMTString();
          document.cookie = 'SRTS=; Path=/; Expires=' + cookie_date.toGMTString();
        });
      } else {
        this.transitionTo('user-profile');
      }
    },


    actions: {

      closeAlert: function closeAlert() {
        var self = this;
        self.transitionTo('sign-up');
      },

      registerSuccess: function registerSuccess() {
        this.transitionTo('sign-up-success');
      }
    },

    setFreeTrialFlag: function setFreeTrialFlag(email) {
      if (email) {
        var self = this;
        Ember.$.getJSON(this.get('store').adapterFor('application').get('host') + '/ValidateTeacherEmail?email=' + encodeURIComponent(email)).done(function (response) {
          if (response) {
            if (!self.get('currentModel.user.stateId')) self.set('currentModel.user.stateId', response.stateId);
            if (!self.get('currentModel.user.standardTypeId')) self.set('currentModel.user.standardTypeId', response.standardTypeId);
            self.set('currentModel.user.isFreeTrial', false);

            self.set('currentModel.showLoad', false);
          } else {
            self.set('currentModel.user.isFreeTrial', true);
            self.set('currentModel.showLoad', false);
          }
        }).fail(function (error) {
          self.set('currentModel.showAlert', true);
          self.set('currentModel.alertText', 'This email address is already in use');
        });
      } else {
        self.set('currentModel.user.isFreeTrial', true);
        self.set('currentModel.showLoad', false);
      }
    }
  });
});
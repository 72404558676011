define('ohs/components/text-counter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    characterCount: '0',
    characterLimit: '500',
    textCount: false,
    overLimit: false,
    classNameBindings: ['overLimitClass'],

    paste: function paste(event) {
      event.preventDefault();
      var comment;
      if (event.originalEvent.clipboardData) {
        comment = (event.originalEvent || event).clipboardData.getData('text/plain');
      } else if (window.clipboardData) {
        comment = window.clipboardData.getData('Text');
      }

      var characterLimit = this.get('characterLimit');
      var existingComment = this.$('.comment-area').val();
      comment = existingComment + comment;
      var commentCharacterLength = comment.length;

      if (commentCharacterLength > characterLimit) {
        this.set('overLimit', true);
      } else {
        this.set('overLimit', false);
      }
      this.$('.comment-area').val(comment);
      this.set('characterCount', this.$('.comment-area').val().length);
      this.sendAction('characterLimitAction', commentCharacterLength, characterLimit, this.$('.comment-area').val());
    },

    overLimitClass: Ember.computed('overLimit', function () {
      return this.get('overLimit') ? 'over-limit' : '';
    }),

    actions: {
      characterCountUpdate: function characterCountUpdate() {
        var comment = this.$('.comment-area').val();
        var commentCharacterLength = comment.length;
        var characterLimit = this.get('characterLimit');

        if (commentCharacterLength > characterLimit) {
          this.set('overLimit', true);
        } else {
          this.set('overLimit', false);
        }

        this.set('characterCount', this.$('.comment-area').val().length);

        var scrollPos = $('.modal').scrollTop();
        this.$('.comment-area').css({ height: "1px" });
        var offset = 0;
        if (this.$('.comment-area')[0].scrollHeight > 75) offset = 10;
        this.$('.comment-area').css({ height: offset + this.$('.comment-area')[0].scrollHeight + "px" });
        $('.modal').scrollTop(scrollPos);
        //if (this.get('input_value'))
        //this.set('input_value', this.$('.comment-area').val());
        //this.sendAction('ratingAction', this.$('.comment-area').val());
        this.sendAction('characterLimitAction', commentCharacterLength, characterLimit, this.$('.comment-area').val());
      }
    }
  });
});
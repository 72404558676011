define('ohs/mirage/scenarios/default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (server) {

    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
    */

    // server.createList('post', 10);

    server.loadFixtures('studentMissions');
    server.loadFixtures('galaxies');
    server.loadFixtures('missions');
  };
});
define('ohs/controllers/student/mission-control/spaceships', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        spaceshipSorting: Object.freeze(['isActive:desc']),
        sortedSpaceships: Ember.computed.sort('model.spaceships', 'spaceshipSorting'),

        actions: {
            select: function select(spaceship) {
                var spaceshipId = Ember.get(spaceship, 'id');
                var user = Ember.get(this, 'model.student');

                // update the user
                Ember.set(user, 'selectedSpaceshipId', spaceshipId);

                user.save();
            }
        }
    });
});
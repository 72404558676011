define('ohs/routes/create-quiz-1', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/teacher-authorize-mixin'], function (exports, _authenticatedRouteMixin, _reset, _noNavigation, _teacherAuthorizeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _noNavigation.default, _teacherAuthorizeMixin.default, {
    titleToken: 'Create a New Quiz',
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      var quizId = transition.handlerInfos[1].params.quiz_id;
      if (quizId == "newQuiz") {
        this.store.unloadAll('standard');
      }
    },

    model: function model(params) {
      var createQuiz1 = this.modelFor('create-quiz-1');
      var store = this.store;

      if (createQuiz1 == null || params.quiz_id == "newQuiz") {
        var sesObj = this.get('session');
        var userId = sesObj.session.content.authenticated["userId"];
        var self = this;
        return this.store.findRecord('teacher', userId).then(function (teacher) {
          return Ember.RSVP.hash({
            grades: [],
            account: self.store.queryRecord('account', { userId: userId }),
            standards: self.store.findAll('standard'),
            quiz: self.store.createRecord('quiz', {
              gradeLevelId: teacher.get('gradeLevelId') || 3,
              subjectId: 1,
              createUserId: userId,
              subject: self.store.peekRecord('subject', 1),
              standards: []
            }),
            validationErrors: '',
            expandedChildIndex: 0,

            standardType: sesObj.get('standardType'),

            fillteredSubjects: Ember.computed('account.subjects', function () {
              var sub = this.account.get('subjects');
              return sub;
            }),
            fillteredSubjectsByGrade: Ember.computed('account.subjects.@each.gradeLevels', function () {
              return this.account.get('subjects').filter(function (item, index, self) {
                if (item.get("gradeLevels.length") > 0) {
                  return true;
                }
              });
            }),

            filGrades: Ember.computed('quiz.subjectId', function () {
              var curSubject = self.store.peekRecord('subject', this.quiz.get('subjectId'));
              var grades = curSubject.get('gradeLevels');
              grades.forEach(function (item) {
                item.set('text', item.get('description'));
              });
              return grades.sortBy('gradeLevelOrder');
            }),

            categoriesByGrade: Ember.computed('quiz.gradeLevelId', function () {
              return this.get('standards').filterBy('gradeLevelId', this.get('gradeLevelId'));
            }),

            filteredCategories: Ember.computed('quiz.gradeLevelId', 'quiz.subjectId', 'standards.@each.id', function () {
              var stan = this.standards;
              var gradeLevelId = this.quiz.get('gradeLevelId');
              var subjectId = this.quiz.get('subjectId');
              stan = stan.filter(function (item) {
                return item.get('subjectId') == subjectId && item.get('gradeLevelId') == gradeLevelId && item.get('standardLevelId') == 1 && item.get('subjectId') != 2;
              }).sortBy('standardOrderId');
              return stan;
            }),

            filteredAssessmentAnchor: Ember.computed('quiz.reportingCategory', 'standards.@each.id', function () {
              var assessmentAnchor = this.standards;
              var standardId = this.quiz.get('reportingCategory');
              assessmentAnchor = assessmentAnchor.filterBy('parentStandardId', standardId).sortBy('standardOrderId');
              return assessmentAnchor;
            }),

            filteredEligiblecontent: Ember.computed('quiz.assessmentAnchors.@each.id', function () {
              //var eligiblecontent = this.standards;
              var anchors = this.quiz.get('assessmentAnchors');
              //eligiblecontent = eligiblecontent.filterBy('parentStandardId',parseInt(standardId)).sortBy('standardOrderId');
              var eligiblecontent = this.standards.filter(function (item) {
                var returnValue = false;
                anchors.forEach(function (anchor) {
                  if (item.get('parentStandardId') == anchor.get('id')) {
                    returnValue = true;
                  }
                });
                return returnValue;
              }).sortBy('standardOrderId');
              return eligiblecontent;
            }),

            startText: Ember.computed('quiz.gradeLevelId', 'quiz.subjectId', 'quiz.reportingCategory', function () {
              var gradeDesc = store.peekRecord('gradeLevel', this.quiz.get('gradeLevelId')).get('description');
              var subject = store.peekRecord('subject', this.quiz.get('subjectId')).get('text');
              if (this.quiz.get('reportingCategory')) {
                var stan = store.peekRecord('standard', this.quiz.get('reportingCategory')).get('text');
                return gradeDesc + ' ' + subject + ' covering ' + stan;
              } else if (this.quiz.get('subjectId') == 2) {
                return gradeDesc + ' ' + subject;
              } else {
                return '';
              }
            }),

            focusText: Ember.computed('quiz.assessmentAnchor', function () {
              var stan = store.peekRecord('standard', this.quiz.get('assessmentAnchor'));
              var anchor = '';
              if (stan) anchor = stan.get('text');
              return anchor;
            }),

            focus: Ember.computed('quiz.assessmentAnchor', function () {
              return store.peekRecord('standard', this.quiz.get('assessmentAnchor'));
            }),

            skillText: Ember.computed('quiz.eligibleContent.@each.id', function () {
              if (this.quiz.get('eligibleContent').length == 1) {
                var ec1 = this.quiz.get('eligibleContent').toArray()[0];
                if (ec1.get('text').length > 85) {
                  return ec1.get('text').substring(0, 85) + '...';
                } else {
                  return ec1.get('text');
                }
              } else if (this.quiz.get('eligibleContent').length > 1) {
                var string = "";
                for (var i = 0; i < this.quiz.get('eligibleContent').length; i++) {
                  string += this.quiz.get('eligibleContent').toArray()[i].get('standardCode');
                  if (i < this.quiz.get('eligibleContent').length - 1) {
                    string += ', ';
                  }
                }
                return string;
              }
            })

          });
        });
      } else {
        return createQuiz1;
      }
    },


    actions: {
      transitionToPassageQuiz: function transitionToPassageQuiz() {
        this.transitionTo('passage-quiz');
      },

      transition: function transition() {
        this.set('currentModel.scrollTo', true);
        this.transitionTo('create-quiz-4');
      }
    }
  });
});
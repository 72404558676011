define('ohs/models/latest-activity-teacher-resource', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    action: _emberData.default.attr('string'),
    date: _emberData.default.attr('date'),
    ratingScore: _emberData.default.attr('number'),
    comments: _emberData.default.attr('string'),

    actionString: Ember.computed('action', 'date', function () {
      if (this.get('date')) {
        return this.get('action') + ' ' + this.get('date').toLocaleDateString();
      } else {
        return this.get('action');
      }
    }),

    linkTo: Ember.computed('action', function () {
      if (this.get('action') == 'Bookmarked') {
        return 'teacher.resource-detail';
      } else if (this.get('action') == 'Reviewed') {
        return 'teacher.resource-detail';
      }
    }),

    itemId: Ember.computed(function () {
      return this.get('id').replace('Bookmarked', '').replace('Reviewed', '');
    }),

    ratingScoreCount: Ember.computed('ratingScore', function () {
      var range = [];
      for (var i = 0; i < parseInt(this.get('ratingScore')); ++i) {
        range.push(i);
      }
      return range;
    }),

    ratingCommentShort: Ember.computed('comments', function () {
      var text = this.get('comments');
      if (text.length > 30) {
        return text.substring(0, 27) + " ...";
      } else {
        return text;
      }
    })

  });
});
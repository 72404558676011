define('ohs/models/test-approved-resource', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    quiz: _emberData.default.belongsTo('quiz'),
    interestLevel: _emberData.default.belongsTo('interestLevel'),
    resource: _emberData.default.belongsTo('resource'),

    interestLevelId: Ember.computed.alias('interestLevel.id')
  });
});
define('ohs/components/mission-control/mission-details-heading', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['mission-detail-header'],

        model: function model() {
            /*
                Need data for the mission, it's planet, and the galaxy.
            */
        }
    });
});
define('ohs/components/tab-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    activeIndex: 0,
    classNames: ['tab-list'],
    children: null,

    childTabs: Ember.computed('children.[]', function () {
      return Ember.get(this, 'children').map(function (tab) {
        return {
          index: Ember.get(tab, 'myIndex'),
          label: Ember.get(tab, 'label')
        };
      });
    }),

    //this makes sure that children gets overrode 
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'children', []);
    },


    actions: {
      register: function register(child) {
        Ember.get(this, 'children').addObject(child);
      }
    }
  });
});
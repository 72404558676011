define('ohs/routes/sign-up-teacher', ['exports', 'ohs/mixins/reset', 'ohs/mixins/no-navigation'], function (exports, _reset, _noNavigation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _noNavigation.default, {
    titleToken: 'Create A New Account',

    model: function model(params) {
      var _this = this;

      var store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        //fields
        user: store.createRecord('teacher', { userRole: 'Teacher' }),
        displayEmail: '',

        //options
        standardTypes: this.store.findAll('standard-type'),
        subject: this.store.findAll('subject').then(function (subjects) {
          return subjects.filter(function (x) {
            return x.id == 1 || x.id == 5 || x.id == 2;
          });
        }),
        grades: this.store.query('grade', { 'gradeType': 'AllGrades' }),
        states: this.store.findAll('state'),
        filteredGrades: Ember.computed('user.primarySubjectId', function () {
          //if the user has selected a primarySubject then filter the grade levels based on it
          if (!!_this.get('currentModel.user.primarySubjectId')) {
            var currentSubject = _this.store.peekRecord('subject', _this.get('currentModel.user.primarySubjectId'));
            currentSubject.get('gradeLevels').forEach(function (x) {
              x.set('text', x.get('description'));
            });
            return currentSubject.get('gradeLevels').sortBy('gradeLevelOrder');
          }
          //else show them all the gradeLevel possiblities
          else {
              //display all the gradelevels from all the subjects in the drop down
              return _this.get('currentModel.subject').reduce(function (previous, current) {
                current.get('gradeLevels').forEach(function (gradeLevel) {
                  if (!previous.includes(gradeLevel)) {
                    gradeLevel.set('text', gradeLevel.get('description'));
                    previous.push(gradeLevel);
                  }
                });
                return previous;
              }, Ember.A([])).sortBy('gradeLevelOrder');
            }
        }),
        honorifics: [{ text: "Mrs", id: "Mrs" }, { text: "Mr", id: "Mr" }, { text: "Ms", id: "Ms" }, { text: "Miss", id: "Miss" }, { text: "Dr", id: "Dr" }],
        aboutUs: this.store.findAll('survey-referral')
      });
    },

    actions: {
      //transition to Purchase route
      purchaseAction: function purchaseAction() {
        this.transitionTo('purchase');
      },

      //registers a teacher
      teacherRegister: function teacherRegister() {
        var self = this;

        //setup user for save
        var user = this.get('currentModel.user');
        user.set('userName', user.get('email'));
        user.set('avatar', 'Green/aliens_final_green-01.svg');

        //saves the user
        this.send('register', function () {
          self.transitionTo('sign-up-success');
        });
      },

      //saves the user
      register: function register(callback) {
        var user = this.get('currentModel.user');

        user.save().then(function (u) {
          callback();
        });
      },

      cancel: function cancel() {
        this.transitionTo('login');
      },

      //centeralized method for validating fields on the screen
      //invalidAttributes *MUST* be part of currentModel
      //allows for a callback on success and failure, must provide caller
      validateFields: function validateFields(successCallback, failCallback, caller) {
        var self = this;

        if (!this.get('currentModel.invalidAttributes')) {
          this.set('currentModel.invalidAttributes', []);
        }

        if (this.get('currentModel.invalidAttributes.length') == 0) {
          caller.set('isProcessing', true);
          document.activeElement.blur();

          caller.set('tick', caller.get('tick') + 1);

          setTimeout(function () {
            if (self.get('currentModel.invalidAttributes.length') > 0) {
              failCallback();
            } else {
              successCallback();
            }
          }, 1000);
        }
      },

      setSubject: function setSubject(subject) {
        this.get('currentModel.user').set('primarySubjectId', subject);
      },

      setGradeLevel: function setGradeLevel(gradeLevelId) {
        this.get('currentModel.user').set('gradeLevelId', gradeLevelId);
      },

      resetUser: function resetUser() {

        var user = this.get('currentModel.user');
        user.set('firstName', null);
        user.set('lastName', null);
        user.set('email', null);
        user.set('username', null);
        user.set('password', null);
        user.set('confirmPassword', null);
        user.set('subject', null);
        user.set('gradeLevelId', null);
        user.set('stateId', null);
        user.set('standardTypeId', null);
        user.set('honorific', null);
        user.set('studentId', null);
        user.set('surveyReferralTypeId', null);
      }

    }

  });
});
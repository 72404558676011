define('ohs/controllers/teacher-quizzes', ['exports', 'moment', 'lodash'], function (exports, _moment, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    //helper function
    function ids() {
        return this.map(function (i) {
            return Ember.get(i, 'id');
        });
    }

    exports.default = Ember.Controller.extend({
        ////injections 
        session: Ember.inject.service('session'),

        ////properties
        selectedSubjectId: '',
        selectedSortId: 1,
        selectedSchoolYearId: null,
        numberShown: 10,
        sortByOptions: [{ id: 1, text: 'Active Sessions' }, { id: 2, text: 'Date Created' }, { id: 3, text: 'Name' }, { id: 4, text: 'Standard' }],

        ////multiline computeds
        selectedSchoolYear: Ember.computed('selectedSchoolYearId', 'model.schoolYears.[]', function () {
            //reset the selectedSubjectId
            Ember.set(this, 'selectedSubjectId', '');
            return Ember.get(this, 'model.schoolYears').findBy('id', Ember.get(this, 'selectedSchoolYearId'));
        }),

        //subject list for the subject drop down
        subjectOptions: Ember.computed('selectedSchoolYearQuizzes.@each.subject', function () {
            return Ember.get(this, 'selectedSchoolYearQuizzes').reduce(function (subjects, quiz) {
                if (ids.call(subjects).includes(Ember.get(quiz, 'subject.id'))) return subjects;

                return subjects.concat(Ember.get(quiz, 'subject'));
            }, []);
        }),

        //filtered the quizzes to only have quizzes owned by this user
        userQuizzes: Ember.computed('model.quizzes.[]', function () {
            var userId = Ember.get(this, 'session.session.content.authenticated.userId');
            return Ember.get(this, 'model.quizzes').filterBy('createUserId', userId).filterBy('id');
        }),

        //filter the quizzes to only be the selected school year
        selectedSchoolYearQuizzes: Ember.computed('userQuizzes.[]', 'selectedSchoolYear.id', function () {
            var quizzes = Ember.get(this, 'userQuizzes');
            var schoolYear = Ember.get(this, 'selectedSchoolYear');
            return this._filterQuizzesOnSchoolYear(quizzes, schoolYear);
        }),

        //filters the school year quizzes based on the fitlers selected
        filteredQuizzes: Ember.computed('selectedSchoolYearQuizzes.[]', 'selectedSubjectId', function () {
            var selectedSubjectId = Ember.get(this, 'selectedSubjectId');
            var quizzes = Ember.get(this, 'selectedSchoolYearQuizzes');

            //filter by subject 
            if (selectedSubjectId) quizzes = quizzes.filterBy('subject.id', selectedSubjectId);

            return quizzes;
        }),

        //sorts the filtered quizzes based on the sort criteria selected
        sortedQuizzes: Ember.computed('filteredQuizzes.[]', 'selectedSortId', function () {
            var quizzes = Ember.get(this, 'filteredQuizzes');
            switch (parseInt(Ember.get(this, 'selectedSortId'))) {
                case 1:
                    return quizzes.sortBy('hasInCompleteAdministration').reverse();
                    break;
                case 2:
                    return quizzes.sortBy('createdOn').reverse();
                    break;
                case 3:
                    return quizzes.sortBy('quizName');
                    break;
                case 4:
                    return quizzes.sortBy('standardCodeDisplay');
                    break;
            }
        }),

        // The school-year model that represents the current year
        currentSchoolYear: Ember.computed('model.schoolYears.{@each.isCurrent,[]}', function () {
            return Ember.get(this, 'model.schoolYears').find(function (sy) {
                return Ember.get(sy, 'isCurrent');
            });
        }),

        // The school-year model that represents the last year
        lastSchoolYear: Ember.computed('currentSchoolYear.sort', function () {
            var lastSort = Ember.get(this, 'currentSchoolYear.sort') - 1;
            return Ember.get(this, 'model.schoolYears').find(function (sy) {
                return Ember.get(sy, 'sort') === lastSort;
            });
        }),

        // Determines if there are quizzes for any other year besides the current
        onlyCurrentSchoolYearQuizzes: Ember.computed('currentSchoolYear', 'userQuizzes', function () {
            var quizzes = Ember.get(this, 'userQuizzes');
            var currentYearQuizzes = this._filterQuizzesOnSchoolYear(quizzes, Ember.get(this, 'currentSchoolYear'));

            return Ember.get(currentYearQuizzes, 'length') === Ember.get(quizzes, 'length');
        }),

        ////methods 
        _filterQuizzesOnSchoolYear: function _filterQuizzesOnSchoolYear(quizzes, schoolYear) {
            //find start and end dates 
            var start = (0, _moment.default)(Ember.get(schoolYear, 'start'));
            var end = (0, _moment.default)(Ember.get(schoolYear, 'end'));

            //reduce the quizzes based on if they are between the dates or not.
            return quizzes.reduce(function (filtered, quiz) {
                var date = (0, _moment.default)(Ember.get(quiz, 'lastGiven'));
                if (date.isBefore(end) && date.isAfter(start)) filtered.push(quiz);

                return filtered;
            }, []);
        }
    });
});
define('ohs/components/student-signup', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['signup-form'],
        //services
        store: Ember.inject.service(),

        //properties
        isProcessing: false,
        tick: 1,
        showFields: false,
        suggestions: null,

        //actions
        actions: {
            setGradeLevel: function setGradeLevel(value) {
                this.sendAction('setGradeLevel', value);
            },

            register: function register() {
                var self = this;

                this.sendAction('validate',
                //success
                function () {
                    self.set('isProcessing', false);
                    self.sendAction('register');
                },
                //fail
                function () {
                    //validation errors
                    self.set('isProcessing', false);
                },
                //caller
                this);
            },

            continueRegistration: function continueRegistration() {
                var user = this.get('model.user');
                //  Added temprorly to fix the error while creating a user.
                user.set('surveyReferralTypeId', 7);
                var host = this.get('store').adapterFor('application').get('host');
                var self = this;

                this.sendAction('validate',
                //success
                function () {
                    self.set('isProcessing', false);
                    self.set('showFields', true);

                    //find possible student matches
                    var data = {
                        'firstName': user.get('firstName'),
                        'lastName': user.get('lastName'),
                        'gradeLevelId': user.get('gradeLevelId')
                    };

                    if (user.get('email')) {
                        data["email"] = user.get('email');
                    }

                    Ember.$.ajax({
                        url: host + '/studentMatches',
                        data: data,
                        dataType: 'JSON',
                        type: 'Get'
                    }).then(function (response) {
                        self.set('suggestions', response);
                    }, function (xhr, status, error) {
                        console.log(xhr.responseText);
                    });
                },
                //fail
                function () {
                    self.set('isProcessing', false);
                },
                //caller
                this);
            },

            cancel: function cancel() {
                //reset user fields
                this.sendAction('resetUser');

                //reset linkingCode
                this.set('model.linkingCode', null);

                //send cancel
                this.sendAction('cancel');
            }

        }
    });
});
define('ohs/routes/parent/student-trophies', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/parent-authorize-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _parentAuthorizeMixin, _breadcrumbMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _parentAuthorizeMixin.default, _breadcrumbMixin.default, {
        titleToken: 'Trophies',

        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            // Implement your custom setup after
            this.controllerFor('application').set('showSecondaryNav', true);
            this.controllerFor('application').set('parent_many_children', true);
        },

        beforeModel: function beforeModel() {
            this._super.apply(this, arguments);
            this.store.unloadAll('student-trophy');
        },

        model: function model() {
            var sesObj = this.get('session');
            var currentStudentId = sesObj.get('currentStudent');
            return Ember.RSVP.hash({
                categories: this.store.query('trophy-category', { userId: currentStudentId }),

                orderedCategories: Ember.computed('categories.@each.id', function () {
                    return this.categories.sortBy('id');
                })
            });
        },

        afterModel: function afterModel(model) {
            this._super(model, 'Trophies', false, true);
        },

        activate: function activate() {
            var self = this;
            window.addEventListener("resize", function (e) {
                self.resizeCanvas();
            }, false);
        },

        resizeCanvas: function resizeCanvas() {
            $('.trophy-img').each(function () {
                $(this).css('height', $(this).width() * $(this).css('min-height').replace("px", ""));
            });
        }
    });
});
define('ohs/models/subscription', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    description: _emberData.default.attr('string'),
    cost: _emberData.default.attr('number'),
    length: _emberData.default.attr('number'),
    created: _emberData.default.attr('date'),
    lastModified: _emberData.default.attr('date'),
    accountTypeId: _emberData.default.attr('number'),
    isFreeTrial: _emberData.default.attr('boolean'),
    isGrantFunded: _emberData.default.attr('boolean'),
    isActive: _emberData.default.attr('boolean'),
    subjects: _emberData.default.hasMany('subject'),

    stripeAmount: Ember.computed('cost', function () {
      return this.get('cost') * 100;
    }),

    subjectsText: Ember.computed('subjects.[]', function () {
      return this.get('subjects').map(function (x) {
        return x.get('text');
      }).join(', ');
    }),

    text: Ember.computed('length', 'description', function () {
      //length is always in months
      var length = this.get('length');
      if (length == 1) {
        return this.get('description') + ' ( ' + length + ' month )';
      } else {
        return this.get('description') + ' ( ' + length + ' months )';
      }
    })
  });
});
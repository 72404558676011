define('ohs/models/passage-complexity-level', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        level: _emberData.default.attr('string'),
        order: _emberData.default.attr('number')
    });
});
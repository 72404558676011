define('ohs/components/form/labeled-input-select', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['x-input', 'x-input-select'],

        label: null
    });
});
define('ohs/routes/parent-students', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/parent-authorize-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _parentAuthorizeMixin, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _parentAuthorizeMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Students',
    signalR: Ember.inject.service('signal-r'),

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('parent_many_children', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      return this.store.findRecord('parent', userId, { reload: true });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Students', false, true);
      //Signal-R event subscription
      this.get('signalR').on('linkParentStudent', this, 'linkParentStudent');
    },

    actions: {
      loading: function loading(transition, originRoute) {
        if (originRoute != this) return true;
      },

      checkRemoveStudent: function checkRemoveStudent(student) {
        //alert(student.get('firstName'));
        this.set('currentModel.studentToRemove', student);
        this.set('currentModel.showConfirm', true);
      },

      removeStudent: function removeStudent() {
        this.set('currentModel.showConfirm', false);
        var self = this;
        var adapter = this.get('store').adapterFor('application');
        var idToRemove = this.get('currentModel.studentToRemove').get('id');
        adapter.ajax(adapter.get('host') + '/parents/removeStudent/' + idToRemove, 'DELETE').then(function () {
          self.set('currentModel.confirmRemove', true);
          self.get('currentModel.students').removeObject(self.get('currentModel.studentToRemove'));
          var sesObj = self.get('session');
          if (sesObj.get('currentStudent') == idToRemove) {
            sesObj.set('currentStudent', self.get('currentModel.students').get('firstObject').get('id'));
          }
        }, function () {
          self.set('currentModel.showAlert', true);
        });
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
      },

      hideConfirm: function hideConfirm() {
        this.set('currentModel.confirmRemove', false);
      }
    },

    linkParentStudent: function linkParentStudent(userId) {
      var sesObj = this.get('session');
      if (!sesObj.get('currentStudent')) {
        sesObj.set('currentStudent', userId);
      }
      this.refresh();
    }
  });
});
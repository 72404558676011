define('ohs/models/class-resource', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    webUrl: _emberData.default.attr('string'),
    fileUrl: _emberData.default.attr('string'),
    teacher: _emberData.default.belongsTo('user'),
    dateCreated: _emberData.default.attr('date'),
    classLibraryFolder: _emberData.default.belongsTo('classLibraryFolder'),
    copiedFrom: _emberData.default.attr('number'),
    cutFrom: _emberData.default.attr('number'),
    // classLibraryFolders: DS.hasMany('classLibraryFolder'),

    itemIcon: Ember.computed('fileUrl', 'webUrl', function () {

      var supported = ['docx', 'xlsx', 'pptx', 'txt', 'doc', 'xls', 'pdf', 'ppt', 'html', 'jpg', 'mp3', 'mp4', 'png', 'psd', 'svg', 'zip'];

      if (this.get('fileUrl')) {
        var ext = this.get('fileUrl').slice(this.get('fileUrl').lastIndexOf('.') + 1);
        var iconBase = 'icon-class-library-';

        if (supported.includes(ext)) {
          //maps x icons to regular icons
          switch (ext) {
            case 'docx':
              return iconBase + 'doc';

            case 'xlsx':
              return iconBase + 'xls';

            case 'pptx':
              return iconBase + 'ppt';

            case 'txt':
              return 'icon-class-library-file';
          }
          return '' + iconBase + ext;
        }
      } else if (this.get('webUrl')) {
        return 'icon-class-library-www';
      }

      return 'icon-class-library-file';
    }),

    fileIcon: Ember.computed('itemIcon', function () {
      return this.get('itemIcon');
    }),

    trimmedName: Ember.computed('name', function () {
      if (this.get('name')) {
        if (this.get('name').length > 18) {
          return this.get('name').substring(0, 13) + '...';
        } else {
          return this.get('name');
        }
      }
    })

  });
});
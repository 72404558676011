define('ohs/routes/view-class-resource', ['exports', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin', 'ohs/mixins/authenticated-route-mixin'], function (exports, _reset, _breadcrumbMixin, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: function titleToken(model) {
      return model.classResource.get('name');
    },

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          } else if (roles.indexOf("Parent") > 0) {
            this.controllerFor('application').set('parent_many_children', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
          this.controllerFor('application').set('parent_many_children', '');
        }
      }
    },

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      //determine if the class resource item has been deleted
      //if it has just direct them back to the top level of the class library

      if (Ember.get(Ember.get(this, 'store').peekRecord('class-resource', transition.params['view-class-resource'].class_resource_id), 'isDeleted')) {
        this.transitionTo('class-library', 0);
      }
    },
    model: function model(params) {
      //Find the previous route and redirect to it after completing the task
      var previousRoutes = this.router.router.currentHandlerInfos;
      var previousRoute = previousRoutes && previousRoutes.pop();

      if (previousRoute) {
        this.set('lastVisitedRoute', previousRoute.name);
        this.set('lastVisitedFolder', previousRoute.params.folder_id);
      }

      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      var classResourceId = params.class_resource_id;
      var curUser = null;
      var isStudent = false;

      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles.includes("Student")) {
          isStudent = true;
          curUser = this.store.findRecord('user', userId);
        }

        if (roles.includes("Teacher")) {
          curUser = this.store.findRecord('teacher', userId);
        }

        if (roles.includes("Tutor")) {
          curUser = this.store.findRecord('tutor', userId);
        }
      }

      if (isStudent) {
        return Ember.RSVP.hash({
          curUser: curUser,
          classResourceId: params.class_resource_id,
          classResource: this.store.findRecord('class-resource', classResourceId),
          showNavConfirmModal: false,
          classResourceStudentNotifies: this.store.peekAll('class-resource-student-notify'),

          isResourceOwner: Ember.computed('curUser.id', 'classResource.teacher.id', function () {
            if (this.curUser.get('id') == this.classResource.get('teacher.id')) {
              return true;
            }
          })
        });
      } else {
        return Ember.RSVP.hash({
          curUser: curUser,
          classResourceId: params.class_resource_id,
          classResource: this.store.findRecord('class-resource', classResourceId),
          showNavConfirmModal: false,

          // start
          studentGroups: this.store.findAll('studentGroup'),
          orderedGroups: Ember.computed('studentGroups.@each.id', function () {
            return this.studentGroups.sortBy('studentGroupName');
          }),
          orderedStudents: Ember.computed('curUser.students.@each.id', function () {
            return this.curUser.get('students').sortBy('fullNameReversed');
          }),
          selectedStudents: null,
          selectedStudentGroups: [],
          isInviteAllSelected: false,
          teacherLinkedStudents: this.store.findAll('student'), //get all teacher linked students
          studentMessage: '',
          studentIds: [], //to hold student ids of student test administration


          validStudent: Ember.computed('selectedStudentGroups.length', 'selectedStudents.length', 'isInviteAllSelected', function () {
            if (this.selectedStudentGroups && this.selectedStudentGroups.length > 0 || this.selectedStudents && this.selectedStudents.length > 0 || this.isInviteAllSelected) {
              Ember.set(this, 'studentMessage', '');
              return true;
            } else {
              return false;
            }
          }),

          //END


          isResourceOwner: Ember.computed('curUser.id', 'classResource.teacher.id', function () {
            if (this.curUser.get('id') == this.classResource.get('teacher.id')) {
              return true;
            }
          })
        });
      }
    },


    afterModel: function afterModel(model) {
      this._super(model, model.classResource.get('name'), model.classResourceId);

      if (model.classResourceStudentNotifies) {
        var notification = model.classResourceStudentNotifies.findBy('classResource.id', model.classResource.get('id'));
        if (notification) {
          notification.destroyRecord();
        }
      }
    },

    actions: {
      confirmVisitResource: function confirmVisitResource(webUrl) {
        var self = this;
        this.disableVisitResourceButton(true);
        var user = this.get('currentModel.curUser');
        if (user.get('hideNavigationWarning')) {
          self.disableVisitResourceButton(false);
          window.open(webUrl);
        } else {
          self.set('currentModel.navigateURL', webUrl);
          self.set('currentModel.showNavConfirmModal', true);
        }
      },

      //Hide alert box
      hideAlert: function hideAlert() {
        this.set("currentModel.showAlert", false);
      },

      notifyConfirm: function notifyConfirm() {
        this.set('currentModel.confirmNotify', false);
      },

      generatefile: function generatefile() {
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = this.currentModel.classResource.get('fileUrl');
        var fileExt = fileName.slice(fileName.lastIndexOf('.') + 1);

        var stream;
        var macosPlatforms = new Array('Macintosh', 'MacIntel', 'MacPPC', 'Mac68K');
        if (macosPlatforms.indexOf(window.navigator.platform) != -1) {
          stream = "application/Octet-Stream";
        } else {
          if (fileExt == 'doc') {
            stream = "application/msword";
          } else if (fileExt == 'docx') {
            stream = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          } else if (fileExt == 'xls') {
            stream = "application/vnd.ms-excel";
          } else if (fileExt == 'xlsx') {
            stream = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          } else if (fileExt == 'ppt') {
            stream = "application/vnd.ms-powerpoint";
          } else if (fileExt == 'pptx') {
            stream = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
          } else if (fileExt == 'pdf') {
            stream = "application/pdf";
          } else if (fileExt == 'txt') {
            stream = "text/plain";
          } else {
            stream = "application/Octet-Stream";
          }
        }
        var xhr = new XMLHttpRequest();
        xhr.open('GET', baseUrl + '/classResources/DownloadFile?fileName=' + encodeURIComponent(fileName), true);
        xhr.responseType = "blob";
        var self = this;
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            if (xhr.response.type == 'application/octet-stream') {
              var blob = new Blob([xhr.response], { type: stream });
              saveAs(blob, fileName);
            } else {
              self.set('currentModel.showAlert', true);
              self.set('currentModel.warningMessage', "File not found!");
            }
          }
        };
        xhr.send(null);
      },


      hideErrorMessage: function hideErrorMessage() {
        this.set('currentModel.error', false);
      },

      editResource: function editResource() {
        this.transitionTo('edit-class-resource', this.get('currentModel.classResourceId'));
      },

      cancelStudents: function cancelStudents() {
        this.set('currentModel.selectedStudents', '');
      },

      clearStudents: function clearStudents() {
        this.set('currentModel.selectedStudents', '');
      },

      hideConfirm: function hideConfirm() {
        var folderId = '0';
        if (this.get('lastVisitedFolder')) {
          folderId = this.get('lastVisitedFolder');
        }
        this.transitionTo('class-library', folderId);
      },

      checkDeleteResource: function checkDeleteResource() {
        this.set('currentModel.showModal', true);
      },

      deleteResource: function deleteResource() {
        this.set('currentModel.showModal', false);
        var r = this.get('currentModel.classResource');
        var self = this;
        r.destroyRecord().then(function () {
          self.set('currentModel.showConfirm', true);
          self.set('currentModel.confirmMessage', "This item has been deleted!");
        }, function () {
          self.set('currentModel.error', true);
        });
      },

      //Add/Remove selected student group from selectedStudentGroups array
      setSelectedStudentGroup: function setSelectedStudentGroup(selectedValue) {
        var studentGroupId = selectedValue;
        if (studentGroupId) {
          var studentGroup = this.store.peekRecord('student-group', parseInt(studentGroupId));
          //If student group already exist in array remove it otherwise add it
          if (this.currentModel.selectedStudentGroups.contains(studentGroup)) {
            this.currentModel.selectedStudentGroups.removeObject(studentGroup);
          } else {
            this.currentModel.selectedStudentGroups.pushObject(studentGroup);
          }
        }
      },
      sendClassResource: function sendClassResource() {
        var self = this;
        var store = this.store;
        var hostAddress = self.get('store').adapterFor('application').get('host');
        var selectedStudentGroups = this.currentModel.selectedStudentGroups;
        var selectedStudents = this.currentModel.selectedStudents;
        // var classResource = this.currentModel.classResource;

        var sesObj = this.get('session');
        var teacherId = sesObj.session.content.authenticated["userId"];
        var teacher = this.currentModel.curUser;
        var teacherLinkedStudents = this.currentModel.teacherLinkedStudents;

        if (teacher) {
          //Assign student groups
          selectedStudentGroups.forEach(function (item, index, enumerable) {
            Ember.$.getJSON(hostAddress + '/studentGroups/updateLastUsed?groupId=' + item.id).done(function (response) {
              if (response) var students = item.get('students');
              self.assignClassResourceToStudent(students);
            }).fail(function (error) {
              self.controllerFor('application').set('errorStatusCode', error.status);
              self.transitionTo('error');
            });
          });

          //Assign manually added students
          if (selectedStudents) {
            self.assignClassResourceToStudent(selectedStudents);
          }

          //teacher linked students
          var isInvitedAll = self.get('currentModel.isInviteAllSelected');
          if (isInvitedAll) {
            self.assignClassResourceToStudent(teacherLinkedStudents);
          }

          if (selectedStudentGroups && selectedStudentGroups.length > 0 || selectedStudents && selectedStudents.length > 0 || isInvitedAll) {
            var self = this;
            setTimeout(function () {
              if (!self.get('currentModel.showAlert')) {
                self.set('currentModel.confirmationMessage', 'The selected students have been notified!');
                self.set('currentModel.confirmNotify', true);

                self.set('currentModel.selectedStudents', null);
                self.set('currentModel.selectedStudentGroups', []);
                self.set('currentModel.isInviteAllSelected', false);
              }
            }, 1500);
          }
        } else {
          this.set('currentModel.studentMessage', 'Please select at least one student or group.');
          return false;
        }
      },
      setInviteAllStudents: function setInviteAllStudents(selectedValue) {
        this.toggleProperty('currentModel.isInviteAllSelected');
      },
      validateStudent: function validateStudent() {
        if (this.currentModel.selectedStudentGroups && this.currentModel.selectedStudentGroups.length > 0 || this.currentModel.selectedStudents && this.currentModel.selectedStudents.length > 0 || this.currentModel.isInvitedAll) {
          return true;
        } else {
          this.set('currentModel.studentMessage', 'Please select at least one student or group.');
          return false;
        }
      }
    },
    disableVisitResourceButton: function disableVisitResourceButton(isDisable) {
      $('.button-subtle').prop('disabled', isDisable);
    },
    assignClassResourceToStudent: function assignClassResourceToStudent(students) {
      var self = this;
      var store = this.store;
      var classResource = this.currentModel.classResource;
      //Iterate through students
      students.forEach(function (item, index, enumerable) {
        //Check whether resource assigned for the current student or not
        if (!self.currentModel.studentIds.contains(item.id)) {
          self.currentModel.studentIds.pushObject(item.id);

          var adapter = self.get('store').adapterFor('application');
          adapter.ajax(adapter.get('host') + '/classResourceStudentNotifies/isClassResourceAlreadySent/' + classResource.id + '/' + item.id, 'GET').then(function (result) {
            //check whether resource already sent to the student
            if (!result) {
              //create resource assigned
              var ra = store.createRecord('class-resource-student-notify', {
                classResource: classResource,
                student: item
              });
              ra.save().then(function () {}, function (error) {
                //duplicate entry to resource user link table returns a 409 status
                if (error && error.errors && error.errors.length > 0 && error.errors[0].status == '409') {
                  ra.rollbackAttributes();
                } else if (ra.isError) {
                  ra.rollbackAttributes();
                  self.set("currentModel.showAlert", true);
                  self.set("currentModel.warningMessage", "There was an notifying the students. Please check your internet connection and try again.");
                }
                self.set('currentModel.showConfirm', false);
              });
            } else {
              //update resource assigned to user
              self.store.queryRecord('class-resource-student-notify', { classResourceId: classResource.id, studentUserId: item.id }).then(function (classResourceStudentNotify) {
                classResourceStudentNotify.set('dateNotified', new Date());
                classResourceStudentNotify.save().then(function () {}, function (error) {
                  self.set("currentModel.showAlert", true);
                  self.set("currentModel.warningMessage", "There was an error notifying the students. Please check your internet connection and try again.");
                });
              });
            }
          }, function () {
            self.set("currentModel.showAlert", true);
            self.set("currentModel.warningMessage", "There was an error notifying the students. Please check your internet connection and try again.");
          });
        }
      });
    }
  });
});
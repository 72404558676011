define('ohs/models/student-answer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    studentTestId: _emberData.default.attr('number'),
    questionId: _emberData.default.attr('number'),
    answerId: _emberData.default.attr('number'),
    isCorrect: _emberData.default.attr('boolean'),
    //startTime: DS.attr('date'),
    //endTime: DS.attr('date'),
    //question: DS.belongsTo('question'),
    //answerOption: DS.belongsTo('answer-option'),
    studentTestAdministration: _emberData.default.belongsTo('student-test-administration')
  });
});
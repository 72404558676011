define('ohs/components/game-team', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        teamList: [],
        team: null,
        colors: [],
        classNames: ['team'],

        actions: {
            pickColor: function pickColor(color) {
                this.sendAction('pickColor', color);
            }
        }
    });
});
define('ohs/routes/teacher/quiz/game-mode/game-results', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            var store = this.store;
            var administration = Ember.get(this.modelFor('teacher.quiz'), 'administration');
            var administrationId = Ember.get(administration, 'id');

            return Ember.RSVP.hash({
                studentTestAdministrations: store.query('student-test-administration', { administrationId: administrationId }),
                administration: store.findRecord('administration', administrationId)
            });
        },


        actions: {
            seeResults: function seeResults() {
                //gotta love magic numbers! the '1' is show_next_step ( something for the breadcrumbs );
                this.transitionTo('teacher-results-1', Ember.get(this, 'currentModel.administration.id'), '1');
            }
        }
    });
});
define('ohs/components/class-library-item', ['exports', 'ember-context-menu'], function (exports, _emberContextMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberContextMenu.default, {
    tagName: 'li',
    classNames: ['class-library-item'],
    attributeBindings: ['draggable'],
    isDragging: false,
    session: Ember.inject.service(),
    contextMenu: Ember.inject.service(),
    maxNameLength: 40,

    draggable: Ember.computed('isStudent', function () {
      return !this.get('isStudent');
    }),

    _contextMenu: function _contextMenu(e) {
      // do anything before triggering the context-menu
    },


    contextItems: Ember.computed('type', function () {
      if (this.get('type') != 'folder' && !this.get('isStudent')) {
        var session = this.get('session');
        var self = this;
        return [{
          label: 'Copy',
          action: function action(selection, details, event) {
            var clipboardObj = { 'action': 'copy', 'type': details.type, 'id': details.id };
            session.set('libraryClipboard', clipboardObj);
            self.set('clipboardObj', clipboardObj);
          }
        }, {
          label: 'Cut',
          action: function action(selection, details, event) {
            var clipboardObj = { 'action': 'cut', 'type': details.type, 'id': details.id };
            session.set('libraryClipboard', clipboardObj);
            self.set('clipboardObj', clipboardObj);
          }
        }];
      }
    }),

    contextDetails: Ember.computed('item', function () {
      return { 'id': this.get('item.id'), 'type': this.get('type') };
    }),

    contextStyle: Ember.computed('type', 'isStudent', function () {
      if (this.get('type') != 'folder' && !this.get('isStudent')) {
        return 'custom-context';
      }
    }),

    didRender: function didRender() {
      if (this.get('type') != 'folder' && !this.get('isStudent')) {
        var id = "#item-wrapper" + this.get('type') + this.get('item.id');
        var canvas = $(id)[0];
        var self = this;
        var contextMenu = this.get('contextMenu');
        var longTouchTimer;
        canvas.addEventListener("touchstart", function (e) {
          if (contextMenu.get('isActive')) {
            e.stopPropagation();
            e.preventDefault();
          }
          var touchEvent = e.touches[0];
          longTouchTimer = setTimeout(function () {
            contextMenu.activate(touchEvent, self.get('contextItems'), null, self.get('contextDetails'));
          }, 1000);
        }, false);
        canvas.addEventListener("touchend", function (e) {
          if (contextMenu.get('isActive')) {
            e.stopPropagation();
            e.preventDefault();
          }
          clearTimeout(longTouchTimer);
        }, false);
        canvas.addEventListener("contextmenu", function (e) {
          e.preventDefault();
          contextMenu.activate(e, self.get('contextItems'), null, self.get('contextDetails'));
        }, false);
      }
    },


    dragOver: function dragOver() {
      if (this.get('isDragging')) {
        return true;
      }
      if (!this.get('canDragOver')) {
        this.set('draggedOver', 'item-over');
      }
      return this.get('canDragOver');
    },

    dragStart: function dragStart(event) {
      if (event.dataTransfer) {
        this.set('isDragging', true);
        var objString = this.get('type') + '|' + this.get('item.id');
        event.dataTransfer.setData('text/data', objString);
      }
    },

    drop: function drop(event) {
      this.set('draggedOver', '');
      this.set('isDragging', false);
      var objString = event.dataTransfer.getData('text/data') + '|' + this.get('item.id');
      this.sendAction('dropAction', objString);
      return false;
    },

    dragLeave: function dragLeave(event) {
      this.set('draggedOver', '');
      return false;
    },

    dragEnd: function dragEnd() {
      this.set('isDragging', false);
      this.set('draggedOver', '');
    },

    // startTouchDrag(e) {
    //   e.preventDefault();
    //
    // },

    canDragOver: Ember.computed('type', function () {
      if (this.get('type') == 'folder' && !this.get('isStudent')) {
        return false;
      } else {
        return true;
      }
    }),

    itemName: Ember.computed('item', function () {
      if (this.get('type') == "linkedResource") {
        var name = this.get('item.resource.resourceName');
        if (name) {
          if (name.length > this.get('maxNameLength')) {
            return name.substring(0, this.get('maxNameLength') - 5) + '...';
          } else {
            return name;
          }
        }
      } else {
        if (this.get('item.name').length > this.get('maxNameLength')) {
          return this.get('item.name').substring(0, this.get('maxNameLength') - 5) + '...';
        } else {
          return this.get('item.name');
        }
      }
    }),

    actions: {
      drill: function drill() {
        if (this.get('type') == "resource") {
          this.sendAction('drillResourceAction', this.get('item.id'));
        } else if (this.get('type') == "linkedResource") {
          this.sendAction('drillLinkedResourceAction', this.get('item.id'));
        } else {
          this.sendAction('clickAction', this.get('item.id'));
        }
      }

    }

  });
});
define('ohs/routes/teacher-results-list', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ember-infinity/mixins/route', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _route, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _route.default, _breadcrumbMixin.default, {
    ////properties 
    titleToken: 'Results',

    ////life-cycle hooks
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var store = get(this, 'store');
      return Ember.RSVP.hash({
        studentTestAdministrations: store.findAll('student-test-administration', { reload: true }),
        administrations: store.findAll('administration'),
        schoolYears: store.findAll('school-year')
      });
    },


    afterModel: function afterModel(model) {
      this._super(model, 'Quiz Results', false, true);
    },

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    ////actions 
    actions: {
      startCreateNewQuiz: function startCreateNewQuiz() {
        this.transitionTo('create-quiz-1', 'newQuiz');
      },

      goToQuizzes: function goToQuizzes() {
        this.transitionTo('teacher-quizzes');
      },

      drill: function drill(id) {
        this.transitionTo('teacher-results-1', id, '0');
      },

      showStudentsNoScores: function showStudentsNoScores(admin) {
        this.set('currentModel.currentAdmin', admin);
        this.set('currentModel.showScores', false);
        this.set('currentModel.showStudents', true);
      },

      showGroups: function showGroups(groups) {
        this.set('currentModel.curGroups', groups);
        this.set('currentModel.showGroups', true);
      },

      closeStudents: function closeStudents() {
        this.set('currentModel.showStudents', false);
      },

      closeGroups: function closeGroups() {
        this.set('currentModel.showGroups', false);
      },

      deleteSession: function deleteSession(administrationId) {
        var self = this;
        this.set("currentModel.isProcessing", true);
        var admin = this.get('currentModel.administrations').findBy('id', administrationId);
        admin.destroyRecord(admin).then(function () {
          self.set('currentModel.showSessionConfirm', true);
          self.set('currentModel.confirmMessage', "This session has been deleted!");
          self.set("currentModel.isProcessing", false);
        }, function () {
          self.set('currentModel.showAlert', true);
          self.set('currentModel.alertMessage', "This quiz could not be deleted. Please check your internet connection and try again.");
          self.set("currentModel.isProcessing", false);
        });
      },
      hideSessionConfirm: function hideSessionConfirm() {
        this.set('currentModel.showSessionConfirm', false);
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
      }
    }
  });
});
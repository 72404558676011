define('ohs/mixins/show-alert-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        showAlert: function showAlert(model, alertBool, alertMessageVar, alertMessage) {
            set(model, alertBool, true);
            set(model, alertMessageVar, alertMessage);
        }
    });
});
define('ohs/components/infinity-scroll', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    perPage: 10,
    tickNumber: 1,

    hasMoreChanged: Ember.observer('hasMoreItems', function () {
      // deal with the change
      if (this.get('hasMoreItems')) {
        this.tick();
      }
    }),

    didInsertElement: function didInsertElement() {
      this.tick();
    },

    tick: function tick() {
      if (this.get('hasMoreItems')) {
        var nextTick = Ember.run.later(this, function () {
          try {
            if (this.isScrolledIntoView($('#infinitySpinner'))) {
              this.set('numShown', this.get('numShown') + this.get('perPage'));
            }
            this.set('tickNumber', this.get('tickNumber') + 1);
            this.tick();
          } catch (e) {
            //do nothing
          }
        }, 1000);
        this.set('nextTick', nextTick);
      }
    },

    isScrolledIntoView: function isScrolledIntoView(elem) {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();

      var elemTop = $(elem).offset().top;
      var elemBottom = elemTop + $(elem).height();

      return elemBottom <= docViewBottom && elemTop >= docViewTop;
    }
  });
});
define('ohs/routes/student-account-details', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: function titleToken(model) {
      return model.student.get('fullName');
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      var account = this.store.peekAll('account');
      var accountId = account.objectAt(0).id;
      var studentId = params.user_id;
      var sesObj = this.get('session');

      return Ember.RSVP.hash({
        student: this.store.findRecord('student', studentId),
        // orderedUserInterests: Ember.computed('student.interestAreas.@each.id',function() {
        //   return this.student.get('interestAreas').sortBy('description');
        // }),
        grades: this.store.query('grade', { 'gradeType': 'StudentProfle' }),
        gradeLevel: Ember.computed('student.gradeLevelId', 'grades.@each.id', function () {
          var gradeId = this.student.get('gradeLevelId');
          if (gradeId) {
            var selectedGrade = this.grades.findBy('id', gradeId.toString());
            if (selectedGrade) {
              return selectedGrade.get('text');
            }
          } else {
            return "Not Provided";
          }
        }),
        // completedQuizzes: this.store.query('student-test-administration', {accountId: accountId, userId: studentId}),
        // recentResults: Ember.computed('completedQuizzes', function() {
        //   return this.completedQuizzes.sortBy('dateCompletedSort').reverse();
        // }),
        // resources: this.store.query('resource-assigned',{userId: studentId}),
        // orderedResources: Ember.computed('resources.@each.id',function() {
        //   return this.resources.filterBy('student.id',studentId).sortBy('assignedDate').reverse();
        // }),
        standardType: sesObj.get('standardType')
      });
    },


    afterModel: function afterModel(model) {
      this._super(model, model.student.get('fullName'), model.student.get('id'));
    },

    deactivate: function deactivate() {
      $('li:contains("Account Admin")').find('li:contains("Student Accounts")').children().first().removeClass('active');
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Account Admin")').find('li:contains("Student Accounts")').children().first().addClass('active');
      }, 100);
    },

    actions: {
      resetPassword: function resetPassword() {
        var userId = this.get('currentModel.student.id');
        this.transitionTo('reset-user-password', userId);
      },


      updateBreadCrumbs: function updateBreadCrumbs() {
        var breadcrumbs = this.get('session').get('breadcrumbs');
        var bc = breadcrumbs[breadcrumbs.length - 1];
        if (bc) {
          Ember.set(bc, 'text', this.currentModel.student.get('fullName'));
        }
      }
    }

  });
});
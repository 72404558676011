define('ohs/routes/teacher-quizzes', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/breadcrumb-mixin', 'ohs/mixins/tip-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _breadcrumbMixin, _tipMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _breadcrumbMixin.default, _tipMixin.default, {
    ////properties 
    titleToken: 'Quizzes',

    ////lifecycle hooks
    model: function model(params) {
      var store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        quizzes: store.findAll('quiz'),
        schoolYears: store.findAll('school-year')
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Quizzes', false, true);
      var tip = this.store.peekRecord('tip', 6);
      if (tip) {
        model.showTip = true;
      }
    },

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super.apply(this, arguments);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
      Ember.set(controller, 'selectedSchoolYearId', Ember.get(controller, 'currentSchoolYear.id'));
    },

    actions: {
      startCreateNewQuiz: function startCreateNewQuiz() {
        this.transitionTo('create-quiz-1', 'newQuiz');
      },

      closeTip: function closeTip() {
        this.destroyTip(6);
        this.set('currentModel.showTip', false);
      },

      goToNewQuiz: function goToNewQuiz() {
        this.destroyTip(6);
        this.transitionTo('create-quiz-1', 'newQuiz');
      },

      goToResourceSearch: function goToResourceSearch() {
        this.destroyTip(6);
        this.transitionTo('teacher.resource-search-start');
      },

      goToStudents: function goToStudents() {
        this.destroyTip(6);
        this.transitionTo('student-list');
      }
    }
  });
});
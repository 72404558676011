define('ohs/routes/upload-account-creation-template', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Upload Account Creation Templates',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          } else if (roles.indexOf("Parent") > 0) {
            this.controllerFor('application').set('parent_many_children', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
          this.controllerFor('application').set('parent_many_children', '');
        }
      }
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var account = this.store.peekAll('account');
      var accountId = account.objectAt(0).id;
      var userId = this.get('session').session.content.authenticated["userId"];

      return Ember.RSVP.hash({
        // teachers: this.store.query('teacher',{accountId : accountId}),
        teacherUserCreationError: this.store.query('userCreationError', { userRole: 'Teacher' }),
        studentUserCreationError: this.store.query('userCreationError', { userRole: 'Student' }),
        rosterCreationError: this.store.query('rosterCreationError', { userId: userId }),
        // orderedTeachers: Ember.computed('teacher','searchBox',function() {
        //   return this.teachers.filter(function(teach,index,array) {
        //     if (this.searchBox) {
        //       return teach.get('fullName').toLowerCase().includes(this.searchBox.toLowerCase()) || teach.get('userName').toLowerCase().includes(this.searchBox.toLowerCase());
        //     }
        //     else {
        //       return true;
        //     }
        //
        //   },this).sortBy('fullNameReversed');
        // }),
        loadFinished: true
        // searchBox: '',
      });
    },


    afterModel: function afterModel(model) {
      this._super(model, 'Account Uploads', false, true);
    },

    actions: {
      generateTeacherUserTemplate: function generateTeacherUserTemplate() {
        var self = this;
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = 'TeacherUserTemplate.csv';
        var xhr = new XMLHttpRequest();
        xhr.open('GET', baseUrl + '/teachers/DownloadCsvTemplate?fileName=' + encodeURIComponent(fileName), true);
        xhr.responseType = "blob";
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            var blobType = self.getBlobType();
            var blob = new Blob([xhr.response], { type: blobType });
            // var blob = new Blob([xhr.response], {type: "text/csv;base64"});
            saveAs(blob, fileName);
          }
        };
        xhr.send(null);
      },
      generateStudentUserTemplate: function generateStudentUserTemplate() {
        var self = this;
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = 'StudentUserTemplate.csv';
        var xhr = new XMLHttpRequest();
        xhr.open('GET', baseUrl + '/students/DownloadStudentUserTemplate?fileName=' + encodeURIComponent(fileName), true);
        xhr.responseType = "blob";
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            var blobType = self.getBlobType();
            var blob = new Blob([xhr.response], { type: blobType });
            //  var blob = new Blob([xhr.response], {type: "text/csv;base64"});
            saveAs(blob, fileName);
          }
        };
        xhr.send(null);
      },
      generateRosterTemplate: function generateRosterTemplate() {
        var self = this;
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = 'RosterTemplate.csv';
        var xhr = new XMLHttpRequest();
        xhr.open('GET', baseUrl + '/teachers/DownloadCsvTemplate?fileName=' + encodeURIComponent(fileName), true);
        xhr.responseType = "blob";
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            var blobType = self.getBlobType();
            var blob = new Blob([xhr.response], { type: blobType });
            // var blob = new Blob([xhr.response], {type: "text/csv;base64"});
            saveAs(blob, fileName);
          }
        };
        xhr.send(null);
      },
      uploadTeacherUsers: function uploadTeacherUsers() {
        var self = this;
        self.set("currentModel.loadFinished", false);
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = 'TeacherUserTemplate.csv';
        var file = $('[name="uploadTeacherUsersList"]').prop("files")[0];

        if (file) {
          // Csv file validation
          var validExts = new Array(".xls", ".csv");
          var fileExt = file.name.substring(file.name.lastIndexOf('.'));
          if (validExts.indexOf(fileExt) < 0) {
            self.set("currentModel.showAlert", true);
            self.set('currentModel.alertMessage', "Please upload a valid file!");
            self.set("currentModel.loadFinished", true);
            return;
          }
          var sesObj = this.get('session');
          var userId = sesObj.session.content.authenticated["userId"];

          var formData = new FormData();
          formData.append(fileName, file);

          var xhr = new XMLHttpRequest();
          xhr.open('POST', baseUrl + '/accounts/UploadTeacherUsers?userId=' + encodeURIComponent(userId), true);

          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
              self.set("currentModel.showConfirm", true);
              var response = xhr.response.split('"').join("");
              // Blank pop up was displayed after uploading the file in Firefox 56.0,to fix this'object' checking was added.
              if (typeof InstallTrigger != 'undefined' && (typeof InstallTrigger === 'undefined' ? 'undefined' : _typeof(InstallTrigger)) != 'object') {
                response = $(response).text();
              }
              self.set("currentModel.confirmMessage", response);
            }
          };

          xhr.send(formData);
        } else {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Please select a file to upload!");
          self.set("currentModel.loadFinished", true);
        }
      },
      uploadStudentUsers: function uploadStudentUsers() {
        var self = this;
        self.set("currentModel.loadFinished", false);
        $("#uploadStatus").empty();
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = 'StudentUserTemplate.csv';
        var file = $('[name="uploadStudentUsersList"]').prop("files")[0];

        if (file) {
          // Csv file validation
          var validExts = new Array(".xls", ".csv");
          var fileExt = file.name.substring(file.name.lastIndexOf('.'));
          if (validExts.indexOf(fileExt) < 0) {
            self.set("currentModel.showAlert", true);
            self.set('currentModel.alertMessage', "Please upload a valid file!");
            self.set("currentModel.loadFinished", true);
            return;
          }

          var sesObj = this.get('session');
          var userId = sesObj.session.content.authenticated["userId"];

          var formData = new FormData();
          formData.append(fileName, file);

          var xhr = new XMLHttpRequest();
          xhr.open('POST', baseUrl + '/accounts/UploadStudentUsers?userId=' + encodeURIComponent(userId), true);

          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
              self.set("currentModel.showConfirm", true);
              var response = xhr.response.split('"').join("");
              //Blank pop up was displayed after uploading the file in Firefox 56.0,to fix this'object' checking was added.
              if (typeof InstallTrigger != 'undefined' && (typeof InstallTrigger === 'undefined' ? 'undefined' : _typeof(InstallTrigger)) != 'object') {
                response = $(response).text();
              }

              self.set("currentModel.confirmMessage", response);
            }
          };

          xhr.send(formData);
        } else {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Please select a file to upload!");
          self.set("currentModel.loadFinished", true);
        }
      },
      uploadRoster: function uploadRoster() {
        var self = this;
        self.set("currentModel.loadFinished", false);
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = 'RosterTemplate.csv';
        var file = $('[name="uploadRosterList"]').prop("files")[0];

        if (file) {
          // Csv file validation
          var validExts = new Array(".xls", ".csv");
          var fileExt = file.name.substring(file.name.lastIndexOf('.'));
          if (validExts.indexOf(fileExt) < 0) {
            self.set("currentModel.showAlert", true);
            self.set('currentModel.alertMessage', "Please upload a valid file!");
            self.set("currentModel.loadFinished", true);
            return;
          }

          var account = this.store.peekAll('account');
          var accountId = account.objectAt(0).id;
          var userId = this.get('session').session.content.authenticated["userId"];

          var formData = new FormData();
          formData.append(fileName, file);

          var xhr = new XMLHttpRequest();
          xhr.open('POST', baseUrl + '/students/UploadRoster?userId=' + encodeURIComponent(userId) + '&accountId=' + encodeURIComponent(accountId), true);

          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
              self.set("currentModel.showConfirm", true);
              var response = xhr.response.split('"').join("");
              // Blank pop up was displayed after uploading the file in Firefox 56.0,to fix this'object' checking was added.
              if (typeof InstallTrigger != 'undefined' && (typeof InstallTrigger === 'undefined' ? 'undefined' : _typeof(InstallTrigger)) != 'object') {
                response = $(response).text();
              }
              self.set("currentModel.confirmMessage", response);
            }
          };

          xhr.send(formData);
        } else {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Please select a file to upload!");
          self.set("currentModel.loadFinished", true);
        }
      },
      updateErrorTeacherUser: function updateErrorTeacherUser(errorTeacherUser) {
        this.transitionTo('new-user', 'Teacher', errorTeacherUser.get('id'));
      },
      updateErrorStudentUser: function updateErrorStudentUser(errorStudentUser) {
        this.transitionTo('new-user', 'Student', errorStudentUser.get('id'));
      },
      updateErrorRoster: function updateErrorRoster(errorRoster) {
        this.transitionTo('roster-error-update', errorRoster.get('id'));
      },
      confirmRemoveErrorTeacherUser: function confirmRemoveErrorTeacherUser(errorTeacherUser) {
        this.set('currentModel.selectedErrorTeacherUser', errorTeacherUser);
        this.set('currentModel.showErrorTeacherUserConfirm', true);
        this.set('currentModel.modalMessage', 'Are you sure you want to delete this teacher from the error list?');
      },
      confirmRemoveErrorStudentUser: function confirmRemoveErrorStudentUser(errorStudentUser) {
        this.set('currentModel.selectedErrorStudentUser', errorStudentUser);
        this.set('currentModel.showErrorStudentUserConfirm', true);
        this.set('currentModel.modalMessage', 'Are you sure you want to delete this student from the error list?');
      },
      confirmRemoveErrorRoster: function confirmRemoveErrorRoster(errorRoster) {
        this.set('currentModel.selectedErrorRoster', errorRoster);
        this.set('currentModel.showErrorRosterConfirm', true);
        this.set('currentModel.modalMessage', 'Are you sure you want to delete this roster from the error list?');
      },
      removeErrorTeacherUser: function removeErrorTeacherUser() {
        var self = this;
        self.set('currentModel.showErrorTeacherUserConfirm', false);
        self.set('currentModel.modalMessage', null);
        self.set("currentModel.loadFinished", false);
        var errorTeacherUser = this.get('currentModel.selectedErrorTeacherUser');

        errorTeacherUser.destroyRecord().then(function () {
          self.set("currentModel.showConfirm", true);
          self.set("currentModel.confirmMessage", 'This teacher has been deleted from the error list!');
        }, function () {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "This teacher could not be deleted from the error list. Please check your Internet connection and try again.");
        });
      },
      removeErrorStudentUser: function removeErrorStudentUser() {
        var self = this;
        self.set('currentModel.showErrorStudentUserConfirm', false);
        self.set('currentModel.modalMessage', null);
        self.set("currentModel.loadFinished", false);
        var errorStudentUser = this.get('currentModel.selectedErrorStudentUser');

        errorStudentUser.destroyRecord().then(function () {
          self.set("currentModel.showConfirm", true);
          self.set("currentModel.confirmMessage", 'This student has been deleted from the error list!');
        }, function () {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Student could not be deleted from the error list. Please check your Internet connection and try again.");
        });
      },
      removeErrorRoster: function removeErrorRoster() {
        var self = this;
        self.set('currentModel.showErrorRosterConfirm', false);
        self.set('currentModel.modalMessage', null);
        self.set("currentModel.loadFinished", false);
        var errorRoster = this.get('currentModel.selectedErrorRoster');

        errorRoster.destroyRecord().then(function () {
          self.set("currentModel.showConfirm", true);
          self.set("currentModel.confirmMessage", 'This roster has been deleted from the error list!');
        }, function () {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Roster could not be deleted from the error list. Please check your Internet connection and try again.");
        });
      },

      hideConfirm: function hideConfirm() {
        var self = this;
        self.refresh();
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
        this.set('currentModel.alertMessage', '');
      },

      removeAllErrors: function removeAllErrors() {
        var self = this;
        var userId = this.get('session').session.content.authenticated["userId"];
        var baseUrl = this.store.adapterFor('application').get('host');
        Ember.$.getJSON(this.get('store').adapterFor('application').get('host') + '/accounts/DeleteAllAccountCreationErrors/' + encodeURIComponent(userId)).done(function (response) {
          self.set("currentModel.showConfirm", true);
          self.set("currentModel.confirmMessage", 'All errors has been deleted from the error list!');
        }).fail(function (response) {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Errors could not be deleted from the error list. Please check your Internet connection and try again.");
        });
      }

    },
    getBlobType: function getBlobType() {

      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      var isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        if (navigator.userAgent.match('CriOS')) {
          return "text/csv;base64";
        } else {
          return "application/octet-stream";
        }
      } else if (isMac) {
        var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
        if (isSafari) {
          return "application/octet-stream";
        } else {
          return "text/csv;base64";
        }
      } else {
        return "text/csv;base64";
      }
    }
  });
});
define('ohs/routes/login', ['exports', 'ohs/mixins/reset', 'ohs/mixins/no-navigation'], function (exports, _reset, _noNavigation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _noNavigation.default, {
    titleToken: 'Login',
    session: Ember.inject.service('session'),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (get(this, 'session.isAuthenticated')) return this.transitionTo('home');
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        isProcessing: false
      });
    },


    actions: {
      accountDisabled: function accountDisabled(userId) {
        this.transitionTo('account-disable', userId);
      }
    }

  });
});
define('ohs/routes/student-passage-quiz-7', ['exports', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/authenticated-route-mixin'], function (exports, _reset, _noNavigation, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _noNavigation.default, _authenticatedRouteMixin.default, {
    title: 'Quiz 1',

    model: function model(params) {
      var store = this.store;
      var currentQuizAdministration = null;
      var model = null;

      return this.store.query('student-test-administration', { isOpen: true }).then(function (admins) {
        currentQuizAdministration = admins.get('firstObject');

        return Ember.RSVP.hash({
          studentTestAdministration: currentQuizAdministration,
          teacher: store.findRecord('user', currentQuizAdministration.get('administration.quiz.createUserId')),
          passage: Ember.Object.create({
            instructions: "Read the following passage detailing how Harriet Tubman will break barriers by replacing Andrew Jackson as the face on the S20 bill and answer the questions about it.",
            title: "Tubman on the $20",
            author: "From Scholastic News, April 26, 2016",
            text: '<p>All the people currently featured on U.S. paper money are men. But that’s going to change. Harriet Tubman will be featured on a new $20 bill, the U.S. Department of the Treasury announced last week. Tubman was a former slave who helped hundreds of slaves escape to freedom.</p> ' + '<p>Tubman will become the first black American and first woman in more than a century to appear on the front of U.S. paper currency. The first woman on paper currency was Martha Washington in the late 1800s.</p> ' + '<h4>Currency Face-Off</h4> ' + '<p>The Treasury Department’s decision comes after controversy last summer over its plan for a new $10 bill. Last June, the Treasury’s leader, Jacob Lew, announced that a woman would be featured on a redesigned $10 bill, which currently features Alexander Hamilton..</p> ' + '<p>Lew asked for input about which woman to put on the $10 bill, but he didn’t get the feedback he expected.</p> ' + '<p>That’s partly because a group had just run an online poll to put a woman on the $20 bill, rather than the $10 bill (Tubman won the poll). Many Americans supported the campaign and were unhappy with Lew’s plan. They argued that a woman should be featured on the $20 bill instead of the ten, as the twenty is more widely used. Also, many people want to remove Andrew Jackson from the $20 bill because they believe he doesn’t deserve the honor.</p> ' + '<p>What’s more, many people think that Hamilton, a widely respected American leader, should not lose his place on the ten.</p> ' + '<h4>Filling the Bill</h4> ' + '<p>The Treasury Department’s final decision is a compromise. Tubman will be front and center on the twenty, and Jackson will move to the back of the bill. Hamilton will stay on the ten, but the new bill will also feature images of five other women’s rights leaders on the back.</p> '
          }),
          questions: Ember.A([Ember.Object.create({
            questionText: "Which of the following text structures was used in the passage?",
            orderedAnswers: Ember.A([Ember.Object.create({ letter: "A", text: "Problem-solution", value: 1 }), Ember.Object.create({ letter: "B", text: "Chronological", value: 2 })])
          }), Ember.Object.create({
            questionText: "Which of the following is <strong>not</strong> a key idea expressed in the text?",
            orderedAnswers: Ember.A([Ember.Object.create({ letter: "A", text: "Harriet Tubman should be featured on the twenty dollar bill because it is used more than any other denomination.", value: 5 }), Ember.Object.create({ letter: "B", text: "Women who had a lasting impact on women’s rights will be honored by having their images added to U.S. currency.", value: 6 }), Ember.Object.create({ letter: "C", text: "Harriet Tubman will be the first woman to ever be featured on paper currency in the United States.", value: 7 })])
          }), Ember.Object.create({
            questionText: "Which answer best describes the outcome of the Treasury Department’s final decision regarding the new currency??",
            secondQuestionText: "Which sentence from the passage supports the answer in Part One?",
            orderedAnswers: Ember.A([Ember.Object.create({ letter: "A", text: "The outcome was a give-and-take solution.", value: 9 }), Ember.Object.create({ letter: "B", text: "The outcome adopted Lew's original suggestions.", value: 10 }), Ember.Object.create({ letter: "C", text: "The outcome remained an unsolved controversy.", value: 11 })]),
            secondAnswers: Ember.A([Ember.Object.create({ letter: "A", text: "\"...[Lew] didn’t get the feedback he expected.\"", value: 13 }), Ember.Object.create({ letter: "B", text: "\"The Treasury Department’s final decision is a compromise.\"", value: 14 }), Ember.Object.create({ letter: "D", text: "\"...many people think that Hamilton ... should not lose his place on the ten.\"", value: 16 })]),
            secondAnswerAllowedCount: 1
          })]),

          displayBelow: false,
          message: "",
          messageClass: "",
          iconClass: "",
          currentColor: 'red',
          currentTool: 'draw',
          backgroundColor: 'transparent',
          initialPercent: 0,
          nowPercent: Ember.computed('currentQuestionCount', function () {
            return 100 / 3 * this.currentQuestionCount;
          }),
          currentQuestionCount: 0
        });
      });
    },

    actions: {
      increaseCounters: function increaseCounters() {
        var curCount = this.controller.get('model.currentQuestionCount');

        this.controller.set('model.currentQuestionCount', curCount + 1);
        this.controller.get('model.nowPercent');
        return true;
      },
      decreaseCounters: function decreaseCounters() {
        var curCount = this.controller.get('model.currentQuestionCount');

        this.controller.set('model.currentQuestionCount', curCount - 1);
        this.controller.get('model.nowPercent');
        return true;
      },
      submit: function submit() {
        this.transitionTo('home');
      }
    }
  });
});
define('ohs/routes/student/mission-control/mission-question', ['exports', 'ohs/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            this.controllerFor('application').set('showSecondaryNav', false);
            this.controllerFor('application').set('student', true);

            // reset the state
            // reset the scratchpad
            Ember.setProperties(controller, {
                state: 'AnswerQuestion',
                currentAnswer: null,
                scratchpadContent: null,
                currentColor: 'red',
                currentTool: 'draw',
                backgroundColor: 'transparent',
                showScratchpad: false
            });
        },


        model: function model(params) {
            var userId = Ember.get(this, 'token.userId');
            var store = Ember.get(this, 'store');

            return Ember.RSVP.hash({
                user: store.findRecord('student', userId),
                studentMission: store.findRecord('studentMission', params.student_mission_id)
            });
        },

        afterModel: function afterModel(model) {
            var studentMission = Ember.get(model, 'studentMission');
            var studentMissionId = Ember.get(studentMission, 'id');
            var completed = Ember.get(studentMission, 'isCompleted');

            // completed student missions have no place here - get out.
            if (completed) {
                return this.transitionTo('student.mission-control.mission-complete', studentMissionId);
            }
        }
    });
});
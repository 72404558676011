define('ohs/components/avatar-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    caseAdjustedAvatar: Ember.computed('avatar', function () {
      var a = this.get('avatar');
      return a.replace('_Green', '_green').replace('_Blue', '_blue').replace('_Orange', '_orange').replace('_Pink', '_pink').replace('_Yellow', '_yellow').replace('_Gray', '_gray');
    }),

    actions: {
      chooseAvatar: function chooseAvatar(avatar) {
        this.sendAction('chooseAction', this.get('caseAdjustedAvatar'));
      }
    }
  });
});
define('ohs/routes/student-group-edit', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, {
    titleToken: function titleToken(model) {
      return model.get('studentGroupName');
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      return this.store.findRecord('studentGroup', params.group_id, { reload: true });
    },


    deactivate: function deactivate() {
      $('li:contains("Students")').find('li:contains("My Groups")').children().first().removeClass('active');
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Students")').find('li:contains("My Groups")').children().first().addClass('active');
      }, 100);
    },

    actions: {
      saveGroup: function saveGroup() {
        var _this = this;

        document.activeElement.blur();
        this.get('currentModel').save().then(function (group) {
          _this.updateBreadCrumbs();
          _this.set("currentModel.isProcessing", false);
          _this.transitionTo('student-group', group.get('id'));
        }, function (fail) {
          _this.set("currentModel.isProcessing", false);
          _this.set("currentModel.validationErrors", response.responseText);
        });
      },

      cancel: function cancel() {
        document.activeElement.blur();
        var group = this.currentModel;
        group.rollbackAttributes();
        this.set("currentModel.validationErrors", '');
        this.transitionTo('student-group', group.get('id'));
      },

      //Hide alert box
      hideAlert: function hideAlert() {
        this.set("currentModel.showAlert", false);
      },

      willTransition: function willTransition(transition) {
        this.currentModel.rollbackAttributes();
      }
    },

    //Common Methods
    updateBreadCrumbs: function updateBreadCrumbs() {
      var studentGroup = this.currentModel;
      var breadcrumbs = this.get('session').get('breadcrumbs');
      if (breadcrumbs.length > 1) {
        var bc = breadcrumbs[breadcrumbs.length - 1];
        if (bc) {
          Ember.set(bc, 'text', studentGroup.get('studentGroupName'));
        }
      }
    }
  });
});
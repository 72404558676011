define('ohs/routes/manage-teacher-profile', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: function titleToken(model) {
      return model.teacher.get('fullName');
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      var _this = this;

      var account = this.store.peekAll('account');
      var accountId = account.objectAt(0).id;
      var teacherId = params.teacher_id;

      return this.store.queryRecord('teacher', { userId: teacherId }).then(function (t) {
        return Ember.RSVP.hash({
          teacher: t,
          students: _this.store.query('student', { teacherUserId: t.id }),
          orderedStudents: Ember.computed('students.@each.id', function () {
            return this.students.sortBy('lastName');
          }),
          accountId: accountId,
          quizzes: _this.store.query('quiz', { teacherUserId: t.id }),
          standards: _this.store.query('standard', { teacherUserId: t.id })
        });
      });
    },


    afterModel: function afterModel(model) {
      this._super(model, model.teacher.get('fullName'), model.teacher.get('id'));
    },

    deactivate: function deactivate() {
      $('li:contains("Account Admin")').find('li:contains("Manage Teachers")').children().first().removeClass('active');
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Account Admin")').find('li:contains("Manage Teachers")').children().first().addClass('active');
      }, 100);
    },

    actions: {
      resetPassword: function resetPassword() {
        var userId = this.get('currentModel.teacher.id');
        this.transitionTo('reset-user-password', userId);
      },


      drill: function drill(id) {
        this.transitionTo('student-profile', id);
      },

      addStudents: function addStudents() {
        this.transitionTo('student-account-list-add-remove', this.get('currentModel.teacher.id'), this.get('currentModel.accountId'));
      }
    }

  });
});
define('ohs/routes/teacher/quiz/game-mode/lobby', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            var store = this.get('store');

            return Ember.RSVP.hash({
                //controls views
                isTeacherView: true,
                allColors: store.peekAll('team-color'),
                colors: Ember.computed('administration.teamA.teamColor.id', 'administration.teamB.teamColor.id', function () {
                    var teamA = Ember.get(this, 'administration.teamA'),
                        teamB = Ember.get(this, 'administration.teamB');

                    if (teamA && teamB) {
                        var teams = [teamA, teamB];
                        var allColors = Ember.get(this, 'allColors').slice();
                        var usedColors = teams.reduce(function (previous, team) {
                            return previous.concat(team.get('teamColor'));
                        }, []);

                        return allColors.reduce(function (previous, color) {
                            if (usedColors.any(function (teamColor) {
                                return teamColor.get('id') == color.get('id');
                            })) {
                                return previous;
                            } else {
                                return previous.concat(color);
                            }
                        }, []);
                    }

                    return [];
                }),
                quiz: Ember.get(this.modelFor('teacher.quiz'), 'administration.quiz'),
                administration: Ember.get(this.modelFor('teacher.quiz'), 'administration'),

                joinedStudents: Ember.computed.filterBy('administration.studentTestAdministrations', 'currentlyInQuiz', true),

                teamAJoinedStudents: Ember.computed('joinedStudents.@each.team', 'administration.teamA.id', function () {
                    return Ember.get(this, 'joinedStudents').filterBy('team.id', Ember.get(this, 'administration.teamA.id'));
                }),

                teamBJoinedStudents: Ember.computed('joinedStudents.@each.team', 'administration.teamB.id', function () {
                    return Ember.get(this, 'joinedStudents').filterBy('team.id', Ember.get(this, 'administration.teamB.id'));
                })

            });
        },


        actions: {
            toggleView: function toggleView() {
                this.toggleProperty('currentModel.isTeacherView');
            },
            studentChangeTeam: function studentChangeTeam(student, currentTeam) {
                //gets the new team and the current students testAdministartion
                var administration = Ember.get(this, 'currentModel.administration'),
                    teamA = Ember.get(administration, 'teamA'),
                    teamB = Ember.get(administration, 'teamB'),
                    newTeam = Ember.get(teamA, 'id') == Ember.get(currentTeam, 'id') ? teamB : teamA,
                    studentTestAdministration = Ember.get(this, 'currentModel.joinedStudents').findBy('student.id', student.get('id')),
                    teamMembers = Ember.get(newTeam, 'studentTestAdministrations').toArray();

                teamMembers.unshift(studentTestAdministration);

                //sets the students new team, then removes them from their old team and adds them to the new one.
                Ember.set(studentTestAdministration, 'team', newTeam);
                Ember.set(studentTestAdministration, 'teamId', Ember.get(newTeam, 'id'));
                Ember.get(currentTeam, 'studentTestAdministrations').removeObject(studentTestAdministration);
                Ember.set(newTeam, 'studentTestAdministrations', teamMembers);

                //saves the studentTestAdministartion
                studentTestAdministration.save();
            },
            startQuiz: function startQuiz() {
                var _this = this;

                Ember.set(this, 'currentModel.administration.isOpen', true);
                Ember.set(this, 'currentModel.administration.startTime', new Date());
                Ember.get(this, 'currentModel.administration').save().then(function () {
                    //this.modelFor('teacher.quiz.game-mode').reload();
                    //transition to next route
                    _this.transitionTo('teacher.quiz.game-mode.in-progress.classroom-view', Ember.get(_this, 'currentModel.administration.id'));
                }, function () {
                    Ember.set(_this.modelFor('teacher.quiz'), 'showAlert', true);
                    Ember.set(_this.modelFor('teacher.quiz'), 'alertMessage', 'Your quiz could not be started. Please check your internet connection and try again.');
                });
            },
            teamAColorChange: function teamAColorChange(color) {
                Ember.set(this, 'currentModel.administration.teamA.teamColor', color);
                //call save
                Ember.get(this, 'store').peekRecord('team', Ember.get(this, 'currentModel.administration.teamA.id')).save();
            },
            teamBColorChange: function teamBColorChange(color) {
                Ember.set(this, 'currentModel.administration.teamB.teamColor', color);
                //call save
                Ember.get(this, 'store').peekRecord('team', Ember.get(this, 'currentModel.administration.teamB.id')).save();
            }
        }
    });
});
define('ohs/models/resource-user-review', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    resource: _emberData.default.belongsTo('resource'),
    user: _emberData.default.belongsTo('user'),
    ratingScore: _emberData.default.attr('number'),
    comments: _emberData.default.attr('string'),
    ratingDate: _emberData.default.attr('date'),
    anonymous: _emberData.default.attr('boolean'),

    ratingDateFormatted: Ember.computed('ratingDate', function () {
      if (this.get('ratingDate')) {
        var date = this.get('ratingDate').toLocaleDateString();
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (today.toLocaleDateString() == date) return 'Today';else if (yesterday.toLocaleDateString() == date) {
          return 'Yesterday';
        } else {
          return date;
        }
      }
    }),

    ratingCommentShort: Ember.computed('comments', function () {
      var text = this.get('comments');
      if (text.length > 250) {
        return text.substring(0, 247) + " ...";
      } else {
        return text;
      }
    })

  });
});
define('ohs/routes/teacher/mission-control/mission/search', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _breadcrumbMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_breadcrumbMixin.default, _authenticatedRouteMixin.default, {
        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            this.controllerFor('application').set('showSecondaryNav', true);

            var gradeFilters = Ember.get(controller, 'gradeFilters');

            Ember.set(controller, 'filterByGradeLevel', gradeFilters.objectAt(0));
            Ember.get(controller, 'filterPlanets').perform();
        },
        model: function model() {
            var store = Ember.get(this, 'store');

            return Ember.RSVP.hash({
                galaxies: store.findAll('mission-control/galaxy'),
                planets: store.findAll('mission-control/planet')
            });
        },
        afterModel: function afterModel(model) {
            this._super(model, 'Assign Mission', false, true);
        }
    });
});
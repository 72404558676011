define('ohs/routes/teacher-resources-2', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Recommended Resources',

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      var sesObj = this.get('session');
      var roles = sesObj.session.content.authenticated["roles"];
      if (roles.includes("Student")) {
        this.transitionTo('home');
      } else if (roles.includes("Teacher")) {
        //this.transitionTo('home');
      }
    },

    model: function model() {
      return Ember.RSVP.hash({
        recommended: this.store.findAll('resource-recommended'),
        studentGroups: this.store.findAll('student-group'),
        levelFilterOption: '1',
        keywordBox: '',
        keywordSearchPhrase: '',
        groupFilter: '',
        subjectFilter: '',
        showModal: false,
        warningMessage: '',
        subjectOpts: Ember.computed('recommended.@each.resource', function () {
          var subjects = [];
          var subjectIds = [];
          this.recommended.forEach(function (r) {
            if (subjectIds.indexOf(r.get('resource.subject.id')) < 0) {
              subjects.pushObject(r.get('resource.subject'));
              subjectIds.pushObject(r.get('resource.subject.id'));
            }
          });
          return subjects.sortBy('id');
        }),
        unfilteredResources: Ember.computed('recommended.@each.resourceStatus', function () {
          return this.recommended.filterBy('resourceStatus', 'New');
        }),

        newResources: Ember.computed('recommended.@each.resourceStatus', 'levelFilterOption', 'keywordBox', 'groupFilter', 'subjectFilter', function () {
          var filtered = this.recommended.filter(function (r, index, array) {
            var hasLevel = true;
            switch (this.levelFilterOption) {
              case '1':
                hasLevel = true;
                break;
              case '2':
                hasLevel = r.get('resource').get('interestLevels').findBy('id', '3');
                break;
              case '3':
                hasLevel = r.get('resource').get('interestLevels').findBy('id', '2');
                break;
              case '4':
                hasLevel = r.get('resource').get('interestLevels').findBy('id', '1');
                break;
              default:
                hasLevel = true;
                break;
            }

            var keywordSearch = true;
            if (this.keywordBox.length > 0) {
              var splitKeywords = this.keywordBox.toLowerCase().split(" ");
              splitKeywords.forEach(function (k) {
                if (keywordSearch) {
                  keywordSearch = r.get('resource').get('resourceName').toLowerCase().indexOf(k) >= 0 || r.get('resource').get('description').toLowerCase().indexOf(k) >= 0 || r.get('student').get('fullName').toLowerCase().indexOf(k) >= 0 || r.get('standard').get('text').toLowerCase().indexOf(k) >= 0;
                }
              });
            }

            var inGroup = true;
            if (this.groupFilter.length > 0) {
              inGroup = r.get('student').get('studentGroups').findBy('id', this.groupFilter);
            }
            var inSubject = true;
            if (this.subjectFilter.length > 0) {
              inSubject = r.get('resource').get('subject.id') == this.subjectFilter;
            }

            return r.get('resourceStatus') == 'New' && hasLevel && keywordSearch && inGroup && inSubject;
          }, this);

          return filtered.sortBy('student.fullNameReversed');
        }),

        newResourcesDistinctStudents: Ember.computed('recommended.@each.resourceStatus', 'levelFilterOption', 'keywordBox', 'groupFilter', 'subjectFilter', function () {
          var students = new Array();

          var filtered = this.recommended.filter(function (r, index, array) {
            var hasLevel = true;
            switch (this.levelFilterOption) {
              case '1':
                hasLevel = true;
                break;
              case '2':
                hasLevel = r.get('resource').get('interestLevels').findBy('id', '3');
                break;
              case '3':
                hasLevel = r.get('resource').get('interestLevels').findBy('id', '2');
                break;
              case '4':
                hasLevel = r.get('resource').get('interestLevels').findBy('id', '1');
                break;
              default:
                hasLevel = true;
                break;
            }

            var keywordSearch = true;
            if (this.keywordBox.length > 0) {
              var splitKeywords = this.keywordBox.toLowerCase().split(" ");
              splitKeywords.forEach(function (k) {
                if (keywordSearch) {
                  keywordSearch = r.get('resource').get('resourceName').toLowerCase().indexOf(k) >= 0 || r.get('resource').get('description').toLowerCase().indexOf(k) >= 0 || r.get('student').get('fullName').toLowerCase().indexOf(k) >= 0 || r.get('standard').get('text').toLowerCase().indexOf(k) >= 0;
                }
              });
            }

            var inGroup = true;
            if (this.groupFilter.length > 0) {
              inGroup = r.get('student').get('studentGroups').findBy('id', this.groupFilter);
            }
            var inSubject = true;
            if (this.subjectFilter.length > 0) {
              inSubject = r.get('resource').get('subject.id') == this.subjectFilter;
            }

            return r.get('resourceStatus') == 'New' && hasLevel && keywordSearch && inGroup && inSubject;
          }, this);

          filtered.sortBy('student.fullNameReversed').forEach(function (item) {
            if (students.length == 0 || students[students.length - 1].get('id') != item.get('student.id')) {
              students.pushObject(item.get('student'));
            }
          });
          return students;
        }),

        levelFilterOpts: [{
          id: 1,
          text: "All levels"
        }, {
          id: 2,
          text: "Above grade level"
        }, {
          id: 3,
          text: "At grade level"
        }, {
          id: 4,
          text: "Below grade level"
        }]
      });
    },


    afterModel: function afterModel(model) {
      var crumbs = this.get('session.breadcrumbs');
      if (crumbs.length > 0 && crumbs[crumbs.length - 1].route == 'teacher-results-1') {
        this._super(model, 'Recommended Resources', false, false);
      } else {
        this._super(model, 'Recommended Resources', false, true);
      }
    },

    actions: {
      hideKeyboard: function hideKeyboard() {
        document.activeElement.blur();
      },

      dismiss: function dismiss(object) {
        if (this.get('currentModel.newResources').filterBy('student.id', object.get('student.id')).get('length') == 2) {
          var student = this.get('currentModel.newResourcesDistinctStudents').findBy('id', object.get('student.id'));
          student.set('close-student', 'last-resource');
        }
        if (this.get('currentModel.newResources').filterBy('student.id', object.get('student.id')).get('length') == 1) {
          var student = this.get('currentModel.newResourcesDistinctStudents').findBy('id', object.get('student.id'));
          student.set('close-student', 'close-student');
        } else {
          object.set('animate', 'slide-up');
        }
        setTimeout(function () {
          object.set('resourceStatus', 'Dismissed');
          object.save();
        }, 1000);
      },

      send: function send(object) {

        var self = this;
        var adapter = this.get('store').adapterFor('application');
        //check whether resource already sent to the student
        adapter.ajax(adapter.get('host') + '/resourceAssigneds/isResourceAlreadySent/' + object.get('resource.id') + '/' + object.get('student.id') + '/' + object.get('teacher.id'), 'GET').then(function (result) {
          //not assigned(new record)
          if (!result) {
            var assigned = self.store.createRecord('resource-assigned', {
              resource: object.get('resource'),
              student: object.get('student'),
              viewed: false,
              assignedDate: new Date(),
              user: object.get('teacher'),
              viewedDate: null
            });

            object.set('wasSent', 'sent-button');
            setTimeout(function () {
              object.set('updateText', true);
              setTimeout(function () {
                assigned.save().then(function () {
                  if (self.get('currentModel.newResources').filterBy('student.id', object.get('student.id')).get('length') == 2) {
                    var student = self.get('currentModel.newResourcesDistinctStudents').findBy('id', object.get('student.id'));
                    student.set('close-student', 'last-resource');
                  } else if (self.get('currentModel.newResources').filterBy('student.id', object.get('student.id')).get('length') == 1) {
                    var student = self.get('currentModel.newResourcesDistinctStudents').findBy('id', object.get('student.id'));
                    student.set('close-student', 'close-student');
                  } else {
                    object.set('animate', 'slide-up');
                  }
                  setTimeout(function () {
                    object.set('resourceStatus', 'Sent');
                    object.save();
                  }, 1000);
                });
              }, 500);
            }, 500);
          }
          //already assigned
          else {
              self.store.queryRecord('resource-assigned', { resourceId: object.get('resource.id'), studentId: object.get('student.id'), sentByUserId: object.get('teacher.id') }).then(function (assignedResource) {
                assignedResource.set('assignedDate', new Date());
                assignedResource.set('viewed', false);
                assignedResource.set('viewedDate', null);

                object.set('wasSent', 'sent-button');
                setTimeout(function () {
                  object.set('updateText', true);
                  setTimeout(function () {
                    assignedResource.save().then(function () {
                      if (self.get('currentModel.newResources').filterBy('student.id', object.get('student.id')).get('length') == 2) {
                        var student = self.get('currentModel.newResourcesDistinctStudents').findBy('id', object.get('student.id'));
                        student.set('close-student', 'last-resource');
                      } else if (self.get('currentModel.newResources').filterBy('student.id', object.get('student.id')).get('length') == 1) {
                        var student = self.get('currentModel.newResourcesDistinctStudents').findBy('id', object.get('student.id'));
                        student.set('close-student', 'close-student');
                      } else {
                        object.set('animate', 'slide-up');
                      }
                      setTimeout(function () {
                        object.set('resourceStatus', 'Sent');
                        object.save();
                      }, 1000);
                    });
                  }, 500);
                }, 500);
              });
            }
          self.set('currentModel.showConfirm', true);
        }, function (error) {
          self.set("currentModel.showAlert", true);
          self.set("currentModel.warningMessage", "There was an error sending the resource. Please check your internet connection and try again.");
        });
      },

      confirmSendAll: function confirmSendAll() {
        this.set('currentModel.showModal', true);
      },

      confirmDismissAll: function confirmDismissAll() {
        this.set('currentModel.showDismissModal', true);
      },

      dismissAll: function dismissAll() {
        this.set('currentModel.showDismissModal', false);
        $('.recommended-resources-group').slideUp(800);
        $('.filter-container').fadeOut(800);
        var dismiss = this.get('currentModel.newResources');
        setTimeout(function () {
          dismiss.forEach(function (item, index) {
            item.set('resourceStatus', 'Dismissed');
            item.save();
          });
        }, 1000);
      },

      sendAll: function sendAll() {
        this.set('currentModel.showModal', false);
        var send = this.get('currentModel.newResources');
        var self = this;
        var parentDiv = $(".recommended-resources-group")[0];
        var adapter = this.get('store').adapterFor('application');

        $('.recommended-resources-group').slideUp(800);
        $('.filter-container').fadeOut(800);

        send.forEach(function (item, index) {
          //check whether resource already sent to the student
          adapter.ajax(adapter.get('host') + '/resourceAssigneds/isResourceAlreadySent/' + item.get('resource.id') + '/' + item.get('student.id') + '/' + item.get('teacher.id'), 'GET').then(function (result) {
            //resource not sent(new record)
            if (!result) {
              var assigned = self.store.createRecord('resource-assigned', {
                resource: item.get('resource'),
                student: item.get('student'),
                viewed: false,
                assignedDate: new Date(),
                user: item.get('teacher'),
                viewedDate: null
              });

              var divObject = $(parentDiv.children[index]);
              assigned.save().then(function () {
                divObject.addClass('send');
                item.set('resourceStatus', 'Sent');
                item.save();
              });
            } else {

              self.store.queryRecord('resource-assigned', { resourceId: item.get('resource.id'), studentId: item.get('student.id'), sentByUserId: item.get('teacher.id') }).then(function (assignedResource) {
                assignedResource.set('assignedDate', new Date());
                assignedResource.set('viewed', false);
                assignedResource.set('viewedDate', null);

                var divObject = $(parentDiv.children[index]);
                assignedResource.save().then(function () {
                  divObject.addClass('send');
                  item.set('resourceStatus', 'Sent');
                  item.save();
                });
              });
            }
          });
        });
      },

      orderNew: function orderNew(option) {
        this.set('currentModel.newSortOption', option);
      },

      filterNew: function filterNew(option) {
        this.set('currentModel.levelFilterOption', option);
      },

      keywordSearch: function keywordSearch() {
        this.set('currentModel.keywordSearchPhrase', this.get('currentModel.keywordBox'));
      },

      filterStudentGroup: function filterStudentGroup(option) {
        this.set('currentModel.groupFilter', option);
      },

      goToQuizzes: function goToQuizzes() {
        this.transitionTo('teacher-quizzes');
      },

      searchResources: function searchResources() {
        this.transitionTo('teacher.resource-search-start');
      },

      toggleFilter: function toggleFilter() {
        if (this.get('currentModel.filterExpanded')) {
          this.set('currentModel.filterExpanded', false);
        } else {
          this.set('currentModel.filterExpanded', true);
        }
      },
      filterSubject: function filterSubject(option) {
        this.set('currentModel.subjectFilter', option);
      },

      //Hide alert box
      hideAlert: function hideAlert() {
        this.set("currentModel.showAlert", false);
      }

    }
  });
});
define('ohs/routes/teacher/shared-quiz-detail', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/breadcrumb-mixin', 'ohs/mixins/passage-modal-mixin', 'ohs/mixins/resource-actions-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _breadcrumbMixin, _passageModalMixin, _resourceActionsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _actions;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _breadcrumbMixin.default, _passageModalMixin.default, _resourceActionsMixin.default, {
    titleToken: function titleToken(model) {
      return model.quiz.get('quizName');
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      return Ember.RSVP.hash({
        // quiz: this.store.find('quiz',params.test_id),
        quiz: this.store.queryRecord('quiz', { quizId: params.test_id, userId: userId }),
        curUser: this.store.findRecord('teacher', userId),

        orderedQuestions: Ember.computed('quiz.testQuestions.@each.id', 'quiz.testQuestions.@each.extraOrder', function () {
          return this.quiz.get('testQuestions').sortBy('questionOrder');
        }),
        // approvedResources: this.store.findAll('test-approved-resource'),
        // approvedResources: this.store.query('test-approved-resource',{quizId:params.test_id,userId:userId}),
        approvedResources: this.store.query('test-approved-resource', { testId: params.test_id }),

        numApprovedResources: Ember.computed('approvedResources.@each.id', function () {
          return this.approvedResources.filterBy('quiz.id', this.quiz.get('id')).get('length');
        }),

        approvedBelowResources: Ember.computed('approvedResources.@each.id', function () {
          var self = this;
          return this.approvedResources.filter(function (item) {
            return item.get('interestLevel.id') == 1 && item.get('quiz.id') == self.quiz.get('id');
          });
        }),

        approvedAtResources: Ember.computed('approvedResources.@each.id', function () {
          var self = this;
          return this.approvedResources.filter(function (item) {
            return item.get('interestLevel.id') == 2 && item.get('quiz.id') == self.quiz.get('id');
          });
        }),

        approvedAboveResources: Ember.computed('approvedResources.@each.id', function () {
          var self = this;
          return this.approvedResources.filter(function (item) {
            return item.get('interestLevel.id') == 3 && item.get('quiz.id') == self.quiz.get('id');
          });
        }),

        isProcessing: false
      });
    },

    deactivate: function deactivate() {
      $('li:contains("Quizzes")').find('li:contains("Global Quizzes")').children().first().removeClass('active');
      this.get('currentModel.quiz').unloadRecord();
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Quizzes")').find('li:contains("Global Quizzes")').children().first().addClass('active');
      }, 100);
    },

    afterModel: function afterModel(model) {
      this._super(model, model.quiz.get('quizName'), model.quiz.get('id'));

      model.quiz.set('questions', []);

      var self = this;
      if (!this.get('currentModel.isRefresh')) {
        Ember.run.later(function () {
          self.set('currentModel.isPrinting', false);
          $('html, body').animate({
            scrollTop: $('.page-body').offset().top
          }, 800);
        }, 500);
      }

      $(".question-edit-controls :button").attr("disabled", false);
    },

    actions: (_actions = {
      loading: function loading(transition, originRoute) {
        if (originRoute != this) return true;
      },

      approvedResources: function approvedResources() {
        this.transitionTo('quiz-approved-resources', this.currentModel.quiz.get('id'), '1');
      },
      refresh: function refresh() {
        this.set('currentModel.isRefresh', true);
        this.refresh();
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
      },

      hideConfirm: function hideConfirm() {
        this.set('currentModel.showConfirm', false);
        this.transitionTo('teacher-quizzes');
      },

      printQuestions: function printQuestions() {
        var self = this;
        var account = self.get('store').peekAll('account');
        var isFreeTrial = account.objectAt(0).get('isFreeTrial');
        if (isFreeTrial && isFreeTrial == true) {
          self.set('currentModel.showAlert', true);
          self.set('currentModel.alertMessage', "Printing quizzes is disabled during your free trial. To enable this feature, please upgrade to a full subscription!");
          return;
        }
        self.set("currentModel.isProcessing", true);
        self.set('currentModel.isPrinting', true);

        var quizId = this.get('currentModel.quiz.id');
        var baseUrl = this.store.adapterFor('application').get('host');

        var sesObj = this.get('session');
        var userId = sesObj.session.content.authenticated["userId"];
        return Ember.$.getJSON(baseUrl + '/quiz/GenerateSharedQuizQuestionsPdf?quizId=' + quizId).done(function (response) {
          self.set("currentModel.isProcessing", false);
          var xhr = new XMLHttpRequest();
          xhr.open('GET', baseUrl + '/quiz/DownloadQuizQuestions?fileName=' + encodeURIComponent(response), true);
          xhr.responseType = "blob";
          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
              var blob = new Blob([xhr.response], { type: "application/pdf" });
              saveAs(blob, response);
            }
          };
          xhr.send(null);

          self.set('currentModel.isPrinting', false);
        }).fail(function (response) {
          self.set("currentModel.isProcessing", false);
          self.set("currentModel.error", true);
          self.set('currentModel.isPrinting', false);
        });
      },
      hideErrorMessage: function hideErrorMessage() {
        this.set('currentModel.error', false);
      },


      scrollToQuestions: function scrollToQuestions() {
        $('html, body').animate({
          scrollTop: $('.editable-question').offset().top
        }, 800);
      },

      hideIssueConfirm: function hideIssueConfirm() {
        this.set('currentModel.showIssueConfirm', false);
      },
      reportIssue: function reportIssue(reportItem) {
        var self = this;
        var adapter = this.get('store').adapterFor('application');

        var url = adapter.get('host') + '/accounts/reportResource?resourceId=' + reportItem.resourceId + '&issue=' + reportItem.issue + '&comment=' + reportItem.comments;
        if (reportItem.comments == '') {
          url = adapter.get('host') + '/accounts/reportResource?resourceId=' + reportItem.resourceId + '&issue=' + reportItem.issue;
        }
        adapter.ajax(url, 'POST').then(function () {
          self.set('currentModel.showIssueConfirm', true);
          self.set('currentModel.issueConfirmationMessage', 'Your report has been received and is currently being reviewed. If we have any follow up questions, someone will be in touch!');
        }, function () {
          self.set('currentModel.showAlert', true);
          self.set("currentModel.alertMessage", "There was an error sending your report. Please check your internet connection and try again.");
        });
        reportItem.comments = '';
        reportItem.issue = null;
      },

      showNavAlert: function showNavAlert(url) {
        this.set('currentModel.showNavConfirmModal', true);
        this.set('currentModel.navigateURL', url);
      },

      addToMyQuizzes: function addToMyQuizzes() {
        var self = this;
        var testId = this.get('currentModel.quiz.id');
        Ember.$.getJSON(this.get('store').adapterFor('application').get('host') + '/quizzes/AddToMyQuizzes/' + encodeURIComponent(testId)).done(function (response) {
          self.set("currentModel.showConfirm", true);
          self.set("currentModel.confirmMessage", 'Successfully added !');
        }).fail(function (response) {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Quiz could not be shared. Please check your Internet connection and try again.");
        });
      },

      removeFromMyQuizzes: function removeFromMyQuizzes() {
        var self = this;
        var testId = this.get('currentModel.quiz.id');
        var baseUrl = this.store.adapterFor('application').get('host');
        Ember.$.getJSON(this.get('store').adapterFor('application').get('host') + '/quizzes/RemoveFromMyQuizzes/' + encodeURIComponent(testId)).done(function (response) {
          self.set("currentModel.showConfirm", true);
          self.set("currentModel.confirmMessage", 'Successfully removed !');
        }).fail(function (response) {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Quiz could not be shared. Please check your Internet connection and try again.");
        });
      }
    }, _defineProperty(_actions, 'hideAlert', function hideAlert() {
      this.set('currentModel.showAlert', false);
      this.set('currentModel.alertMessage', '');
    }), _defineProperty(_actions, 'hideConfirm', function hideConfirm() {
      this.set('currentModel.showConfirm', false);
      this.set('currentModel.confirmMessage', '');
    }), _actions)
  });
});
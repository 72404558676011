define('ohs/mirage/fixtures/missions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    id: 99,
    name: 'Mission to the Cardello Building',
    description: 'On this mission, you will make a few guesses and see how well you can guess the correct answer.',
    points: 300,
    order: 1,
    numQuestions: 3,
    planet: {
      id: 1,
      name: 'Planet Angolix',
      galaxy: {
        id: 1,
        name: 'Geomeda Galaxy'
      }
    },
    testQuestions: [{
      id: 101,
      questionOrder: 1,
      question: {
        id: 1,
        questionText: 'Answer this question',
        answerOptions: [{
          id: 1101,
          answerText: 'Answer A',
          isCorrect: false,
          cognitiveMistakeDesc: 'The student made the wrong choice',
          answerLetter: 'A',
          order: 1
        }, {
          id: 1102,
          answerText: 'Answer B',
          isCorrect: false,
          cognitiveMistakeDesc: 'The student made the wrong choice',
          answerLetter: 'B',
          order: 2
        }, {
          id: 1103,
          answerText: 'Answer C',
          isCorrect: true,
          answerLetter: 'C',
          order: 3
        }, {
          id: 1104,
          answerText: 'Answer D',
          isCorrect: false,
          cognitiveMistakeDesc: 'The student made the wrong choice',
          answerLetter: 'D',
          order: 4
        }]
      }
    }, {
      id: 102,
      questionOrder: 2,
      question: {
        id: 102,
        questionText: 'Answer this question',
        answerOptions: [{
          id: 1201,
          answerText: 'Answer A',
          isCorrect: false,
          cognitiveMistakeDesc: 'The student made the wrong choice',
          answerLetter: 'A',
          order: 1
        }, {
          id: 1202,
          answerText: 'Answer B',
          isCorrect: false,
          cognitiveMistakeDesc: 'The student made the wrong choice',
          answerLetter: 'B',
          order: 2
        }, {
          id: 1203,
          answerText: 'Answer C',
          isCorrect: true,
          answerLetter: 'C',
          order: 3
        }, {
          id: 1204,
          answerText: 'Answer D',
          isCorrect: false,
          cognitiveMistakeDesc: 'The student made the wrong choice',
          answerLetter: 'D',
          order: 4
        }]
      }
    }, {
      id: 103,
      questionOrder: 3,
      question: {
        id: 103,
        questionText: 'Answer this question',
        answerOptions: [{
          id: 1301,
          answerText: 'Answer A',
          isCorrect: false,
          cognitiveMistakeDesc: 'The student made the wrong choice',
          answerLetter: 'A',
          order: 1
        }, {
          id: 1302,
          answerText: 'Answer B',
          isCorrect: false,
          cognitiveMistakeDesc: 'The student made the wrong choice',
          answerLetter: 'B',
          order: 2
        }, {
          id: 1303,
          answerText: 'Answer C',
          isCorrect: true,
          answerLetter: 'C',
          order: 3
        }, {
          id: 1304,
          answerText: 'Answer D',
          isCorrect: false,
          cognitiveMistakeDesc: 'The student made the wrong choice',
          answerLetter: 'D',
          order: 4
        }]
      }
    }]
  }];
});
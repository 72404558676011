define('ohs/routes/student-group-create', ['exports', 'ohs/mixins/authorized-route-mixin', 'ohs/mixins/reset'], function (exports, _authorizedRouteMixin, _reset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authorizedRouteMixin.default, {
    authorizedRoles: ['Tutor', 'Teacher'],
    titleToken: 'Create Student Group',

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
    },

    beforeModel: function beforeModel() {

      if (this._super()) {
        var oldRecord = this.modelFor('student-group-create');
        if (oldRecord) this.store.unloadRecord(oldRecord);
      }
    },

    model: function model(params) {
      return this.store.createRecord('studentGroup');
    },


    deactivate: function deactivate() {
      $('li:contains("Students")').find('li:contains("My Groups")').children().first().removeClass('active');
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Students")').find('li:contains("My Groups")').children().first().addClass('active');
      }, 100);
    },

    actions: {
      saveGroup: function saveGroup() {
        document.activeElement.blur();
        var group = this.currentModel;
        var groupName = group.get('studentGroupName');
        var groupId = group.get('id');
        var sesObj = this.get('session');
        var userId = sesObj.session.content.authenticated["userId"];
        var self = this;
        self.set("currentModel.isProcessing", true);

        if (groupName && groupName.trim()) {
          if (!groupId) {
            groupId = 0;
          }
          return Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/studentGroups/hasDuplicateGroupName?groupId=' + groupId + '&groupName=' + encodeURIComponent(groupName) + '&userId=' + userId).done(function (response) {
            self.set("currentModel.validationErrors", '');
            group.save().then(function (g) {
              self.set("currentModel.isProcessing", false);
              self.transitionTo('student-group-add-remove', g.get('id'));
            }, function (error) {
              self.set("currentModel.isProcessing", false);
              self.set("currentModel.showAlert", true);
            });
          }).fail(function (response) {
            self.set("currentModel.isProcessing", false);
            self.set("currentModel.validationErrors", response.responseText);
          });
        } else {
          self.set("currentModel.validationErrors", "Group name can't be blank");
          self.set("currentModel.isProcessing", false);
        }
      },

      cancel: function cancel() {
        document.activeElement.blur();
        var group = this.currentModel;
        group.rollbackAttributes();
        this.set("currentModel.validationErrors", '');
        this.transitionTo('students');
      },

      //Hide alert box
      hideAlert: function hideAlert() {
        this.set("currentModel.showAlert", false);
      }
    }
  });
});
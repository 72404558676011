define('ohs/components/mission-control/student-mission-list-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: 'mission-item',

    missionStatus: Ember.computed('studentMissions.@each.id', 'mission.id', function () {
      var studentMission = this.get('studentMissions').findBy('mission.id', this.get('mission.id'));
      if (studentMission) {
        if (studentMission.get('numQuestions') == studentMission.get('numAnswered')) {
          return 'Complete';
        } else if (studentMission.get('numAnswered') == 0) {
          return 'NotStarted';
        } else {
          return 'InProgress';
        }
      } else {
        return 'NotStarted';
      }
    }),

    studentMission: Ember.computed('studentMissions.@each.id', 'mission.id', function () {
      return this.get('studentMissions').findBy('mission.id', this.get('mission.id'));
    }),

    actions: {
      viewResults: function viewResults() {
        this.sendAction('viewResultsAction', this.get('studentMission.id'));
      },
      startQuiz: function startQuiz() {
        this.sendAction('startQuizAction', this.get('mission.id'));
      },
      continueQuiz: function continueQuiz() {
        this.sendAction('continueQuizAction', this.get('studentMission.id'));
      }
    }
  });
});
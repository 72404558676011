define('ohs/controllers/application', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		signalr: Ember.inject.service('signal-r'),

		init: function init() {
			var signalr = Ember.get(this, 'signalr');
			var self = this;
			signalr.on('notificationChange', function () {
				self.send.apply(self, ['notificationChange'].concat(Array.prototype.slice.call(arguments)));
			});
		},


		copyrightYear: new Date().getFullYear(),
		navigation: null,
		session: Ember.inject.service('session'),
		menuOpen: false,
		needRefresh: true,
		colorPickers: [],
		currentRole: Ember.computed('session', function () {
			var sesObj = this.get('session');
			var roles = sesObj.session.content.authenticated["roles"];
			if (roles) {
				if (roles.includes("Teacher")) {
					return "Teacher";
				} else if (roles.includes("Student")) {
					return "Student";
				} else if (roles.indexOf("Tutor") > 0) {
					return "Tutor";
				}
			}
		}),

		registerNotifications: function registerNotifications(notifications) {
			this.set('topNav', notifications);
		},

		refreshModel: function refreshModel() {
			if (this.get('needRefresh')) {
				this.get('target').send('refreshModel');
				this.set('needRefresh', false);
			}
		},

		needsAction: Ember.computed('topNav.children.@each.isExpanded', function () {
			if (this.get('topNav') != null) {
				if (this.get('topNav').get('children').findBy('isExpanded', true)) {
					return true;
				} else {
					return false;
				}
			} else if (this.get('menuOpen') == true) {
				return true;
			} else {
				return false;
			}
		}),

		actions: {
			logout: function logout() {
				this.set('needRefresh', true);
				this.get('session').invalidate();
			},


			registerNavigation: function registerNavigation(navigation) {
				this.set('navigation', navigation);
			},

			closeNavigation: function closeNavigation(event) {
				if (this.get('navigation') && !this.get('navigation').isDestroyed) {
					this.get('navigation').send('close');
				}
				if (this.get('topNav') != null) {
					this.get('topNav').get('children').forEach(function (menu) {
						menu.send('hide');
					});
				}
			},

			stayOpen: function stayOpen() {}
		}
	});
});
define('ohs/routes/teacher/roster-approval', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/tip-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _tipMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reset.default, _teacherAuthorizeMixin.default, _authenticatedRouteMixin.default, _tipMixin.default, {
        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            // Implement your custom setup after
            this.controllerFor('application').set('showSecondaryNav', true);
            this.controllerFor('application').set('teacher', true);
        },

        model: function model() {
            return Ember.RSVP.hash({
                studentGroups: this.store.findAll('student-group-pending', { reload: true }),
                filteredGroups: Ember.computed('studentGroups.[]', function () {
                    var _this = this;

                    return this.studentGroups.reduce(function (previous, group) {
                        if (!previous.includes(group.get('groupType'), 0)) {
                            previous.pushObject(group.get('groupType'));
                            return previous;
                        } else {
                            return previous;
                        }
                    }, Ember.A([])).map(function (type) {
                        return {
                            groups: _this.studentGroups.filterBy('groupType', type),
                            name: type
                        };
                    });
                }),
                isLoading: false,

                tipNumber: 11,
                tipText: null,
                showTip: false

            });
        },

        actions: {
            closeTip: function closeTip() {
                var tipId = Ember.get(this, 'currentModel.tipNumber');
                this.destroyTip(tipId);
                this.toggleProperty('currentModel.showTip');
            },

            showTip: function showTip() {
                var _this2 = this;

                var userId = this.get('session').session.content.authenticated["userId"];
                var userTip = this.store.createRecord('userTip', {
                    userId: userId,
                    tipId: this.get('currentModel.tipNumber')
                });

                userTip.save().then(function (tip) {
                    if (tip) {
                        _this2.toggleProperty('currentModel.showTip');
                        _this2.set('currentModel.tipText', tip.get('tip.tipText'));
                    }
                });
            },

            toUserProfile: function toUserProfile() {
                this.transitionTo('user-profile');
            }
        }
    });
});
define('ohs/components/answer-box', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    answerOptions: [],
    secondPartAnswerOptions: [],

    chartBackgroundColors: Ember.computed('answerOptions', function () {
      var correctColor = '#a8cd4d';
      var incorrectColor = '#ed633b';

      return this.get('answerOptions').reduce(function (a, b) {
        return b.get('isCorrect') ? a.concat(correctColor) : a.concat(incorrectColor);
      }, []);
    }),

    chartLabels: Ember.computed('answerOptions', function () {
      return this.get('answerOptions').map(function (x) {
        return x.get('answerLetter');
      });
    }),

    chartData: Ember.computed('studentTestAdministrations.@each.unansweredQuestions', 'currentQuestionId', function () {
      var _this = this;

      var administrations = this.get('studentTestAdministrations');
      //this is to dynamically generating the starting values incase there are ever more than 4 options
      var startingCounts = this.get('answerOptions').reduce(function (a, b) {
        return a.concat(0);
      }, []);

      //first reduce gets the how many times each answer was picked
      var answerPickedPercents = administrations.reduce(function (a, b) {
        var counts = a;
        var orderedAnswers = _this.get('answerOptions');
        b.get('studentAnswers').filter(function (c) {
          return c.get('questionId') == _this.get('currentQuestionId');
        }).forEach(function (c) {
          var answerId = c.get('answerId');
          var index = orderedAnswers.map(function (x) {
            return parseInt(x.get('id'));
          }).indexOf(answerId);
          if (index != -1) {
            counts[index] += 1;
          }
        });
        return counts;
      }, startingCounts)
      //second reduce turns the counts into percents
      .reduce(function (a, b) {
        return b > 0 && administrations.length > 0 ? a.concat(Math.round(b / administrations.length * 100)) : a.concat(0);
      }, []);

      return {
        labels: this.get('chartLabels'),
        datasets: [{
          backgroundColor: this.get('chartBackgroundColors'),
          borderColor: 'rgb(255, 99, 132)',
          data: answerPickedPercents
        }]
      };
    }),

    chartOptions: {
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          },
          scaleLabel: {
            display: true,
            labelString: 'Answer Options'
          }
        }],
        yAxes: [{
          ticks: {
            max: 100,
            min: 0,
            stepSize: 25
          },
          scaleLabel: {
            display: true,
            labelString: '% of Students'
          }
        }]
      },
      tooltips: {
        //Makes the colored block not display
        displayColors: false,
        callbacks: {
          //Choice C: 3 Students
          title: function title(tooltipItem, data) {
            return 'Choice ' + tooltipItem[0].xLabel + ': ' + tooltipItem[0].yLabel + '%';
          },
          //return nothing for the label
          label: function label(tooltipItem, data) {
            return '';
          }
        }
      }
    },

    currentQuestionId: Ember.computed('answerOptions.@each.id', function () {
      return parseInt(this.get('answerOptions').toArray()[0].get('question').get('id'));
    }),

    numAnswered: Ember.computed('studentTestAdministrations.@each.unansweredQuestions', 'currentQuestionId', function () {
      var _this2 = this;

      /*
        Works backward from the number of students in the test
      */
      return Ember.get(this, 'studentTestAdministrations').reduce(function (current, sta) {
        if (Ember.get(sta, 'unansweredQuestions').findBy('id', _this2.get('currentQuestionId').toString())) current--;
        return current;
      }, Ember.get(this, 'studentTestAdministrations.length'));
    }),

    updateProperties: function updateProperties() {
      //this.propertyWillChange('percentCorrect');
      this.propertyDidChange('numAnswered');
      this.propertyDidChange('numCorrect');
      this.propertyDidChange('percentCorrect');
    },

    actions: {
      lockAnswers: function lockAnswers() {
        var self = this;
        //this.isProcessing(true);
        setTimeout(function () {
          self.isProcessing(true);
        }, 200);
        this.sendAction('lockAction');
        setTimeout(function () {
          self.isProcessing(false);
        }, 2500);
      },

      toggleShowChart: function toggleShowChart() {
        this.sendAction('toggleShowChart');
      }
    },

    //Methods
    // Enable or disable dic contents based on the status of a process.
    isProcessing: function isProcessing(status) {
      //re-enable link
      if ($(".totals a").length > 0) {
        $(".totals a").attr("disabled", status);
      }

      if ($(".quiz-stats-no-answers a").length > 0) {
        $(".quiz-stats-no-answers a").attr("disabled", status);
      }
    }
  });
});
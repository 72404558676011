define('ohs/components/x-tip-inline', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tag: 'div',
        displayed: false,
        tipText: '',

        actions: {
            close: function close() {
                this.sendAction('closeTip');
            }
        }
    });
});
define('ohs/routes/student-quiz-details', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Quiz Details',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (!transition.queryParams.student_id) {
        this.transitionTo('usage-tracker');
      }
    },
    model: function model(queryParams, params) {
      if (params.queryParams.from_date && params.queryParams.to_date) {
        var quizDetails = this.store.query('studentTestAdministration', { studentUserId: params.queryParams.student_id, fromDate: params.queryParams.from_date, toDate: params.queryParams.to_date });
      } else {
        var quizDetails = this.store.query('studentTestAdministration', { studentUserId: params.queryParams.student_id, filterType: params.queryParams.filter_type });
      }
      return Ember.RSVP.hash({
        // quizDetails: this.store.query('studentTestAdministration',{studentUserId: params.student_id}),
        quizDetails: quizDetails,
        user: this.store.findRecord('user', params.queryParams.student_id),
        quizDetailOptions: [{
          text: "Name",
          id: 1
        }, {
          text: "Subject",
          id: 2
        }, {
          text: "Date taken",
          id: 3
        }, {
          text: "Standard",
          id: 4
        }, {
          text: "Score",
          id: 5
        }],
        curSort: 1,
        curquizDetails: Ember.computed('quizDetails.@each.id', 'curSort', function () {
          var results;
          if (this.curSort == 1) {
            results = this.quizDetails.sortBy('administration.quiz.quizName');
          } else if (this.curSort == 2) {
            results = this.quizDetails.sortBy('administration.quiz.subject.text').reverse();
          } else if (this.curSort == 3) {
            results = this.quizDetails.sortBy('administration.startTime').reverse();
          } else if (this.curSort == 4) {
            results = this.quizDetails.sortBy('administration.quiz.standardCodeDisplay').reverse();
          } else if (this.curSort == 5) {
            results = this.quizDetails.sortBy('percentCorrect').reverse();
          }

          return results;
        })
      });
    },


    actions: {
      sortBy: function sortBy(selectedValue) {
        this.set('currentModel.curSort', selectedValue);
      }
    }
  });
});
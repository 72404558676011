define('ohs/models/passage', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    author: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    instructions: _emberData.default.attr('string'),
    text: _emberData.default.attr('string'),
    gradeLevelId: _emberData.default.attr('number'),
    questions: _emberData.default.hasMany('question', { async: false }),
    standards: _emberData.default.hasMany('standard', { inverse: null }),
    passageLength: _emberData.default.attr('string'),
    passageLengthId: _emberData.default.attr('number'),
    passageTextType: _emberData.default.belongsTo('passage-text-type'),
    passageComplexityLevel: _emberData.default.belongsTo('passage-complexity-level'),

    eligibleContentCount: Ember.computed('standards.[]]', function () {
      return this.get('standards.length');
    })
  });
});
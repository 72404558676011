define('ohs/routes/teacher/mission-control/leaderboard', ['exports', 'ohs/mixins/authorized-route-mixin'], function (exports, _authorizedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authorizedRouteMixin.default, {
        authorizedRoles: ['Tutor', 'Teacher'],

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('application').set('showSecondaryNav', true);
        },
        model: function model() {
            var store = Ember.get(this, 'store');

            return Ember.RSVP.hash({
                leaderboards: store.findAll('mission-leaderboard')
            });
        }
    });
});
define('ohs/routes/teacher/resource-detail', ['exports', 'ohs/mixins/reset', 'ohs/mixins/authorized-route-mixin', 'ohs/mixins/breadcrumb-mixin', 'ohs/mixins/tip-mixin'], function (exports, _reset, _authorizedRouteMixin, _breadcrumbMixin, _tipMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reset.default, _authorizedRouteMixin.default, _breadcrumbMixin.default, _tipMixin.default, {
        authorizedRoles: ['Tutor', 'Teacher'],

        titleToken: function titleToken(model) {
            return model.resource.get('resourceName');
        },

        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            // Implement your custom setup after
            this.controllerFor('application').set('showSecondaryNav', true);
        },

        model: function model(params) {
            var curUser = this.modelFor('teacher').user;
            var userId = get(this, 'token.userId');
            var self = this;
            var commentLength = 0;
            var textLimit = 0;

            return this.store.findRecord('resource', params.resource_id).then(function (r) {
                return Ember.RSVP.hash({
                    curUser: curUser,
                    resource: r,
                    studentGroups: self.store.findAll('studentGroup'),
                    orderedGroups: Ember.computed('studentGroups.@each.id', function () {
                        return this.studentGroups.sortBy('studentGroupName');
                    }),
                    orderedStudents: Ember.computed('curUser.students.@each.id', function () {
                        return this.curUser.get('students').sortBy('fullNameReversed');
                    }),

                    myRating: Ember.computed('resource.resourceUserReviews.@each.id', function () {
                        return this.resource.get('resourceUserReviews').find(function (item, index, enumerable) {
                            return item.get('user').get('id') == userId;
                        });
                    }),

                    bookmark: self.store.findAll('resourceBookmark').then(function (b) {
                        return b.find(function (item, index, enumerable) {
                            return item.get('resource').get('id') == params.resource_id;
                        });
                    }),

                    resourceRating: null,
                    isAnonymous: false,
                    resourceComments: '',
                    warningMessage: '',
                    confirmationMessage: '',
                    selectedStudents: null,
                    selectedStudentGroups: [],
                    studentIds: [], //to hold student ids of student test administration
                    resourceIssue: '',
                    resourceIssueComments: '',
                    isInviteAllSelected: false,
                    teacherLinkedStudents: self.store.findAll('student'), //get all teacher linked students
                    studentMessage: '',
                    issueMessage: '',
                    showNavConfirmModal: false,

                    validIssue: Ember.computed('resourceIssue', 'commentLength', 'textLimit', function () {
                        if (this.resourceIssue) {
                            if (this.resourceIssue != "Other") {
                                if (this.resourceIssueComments && this.textLimit) {
                                    return this.resourceIssueComments.length <= this.textLimit;
                                }
                                return true;
                            } else {
                                if (this.resourceIssueComments && this.textLimit) {
                                    if (this.resourceIssueComments.length > 0 && this.resourceIssueComments.length <= this.textLimit) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                                return false;
                            }
                        }
                        return false;
                    }),

                    tellUsMore: Ember.computed('resourceIssue', function () {
                        if (this.resourceIssue == "Other") {
                            return "Tell us more.";
                        } else {
                            return "Tell us more (optional).";
                        }
                    }),

                    validStudent: Ember.computed('selectedStudentGroups.length', 'selectedStudents.length', 'isInviteAllSelected', function () {
                        if (this.selectedStudentGroups && this.selectedStudentGroups.length > 0 || this.selectedStudents && this.selectedStudents.length > 0 || this.isInviteAllSelected) {
                            Ember.set(this, 'studentMessage', '');
                            return true;
                        } else {
                            return false;
                        }
                    })

                });
            }, function (error) {
                console.log(error);
            });
        },

        scheduleTip: function scheduleTip(tip) {
            var self = this;
            setTimeout(function () {
                self.set('currentModel.showTip', true);
                self.set('currentModel.tipText', tip.get('tipText'));
            }, 5000);
        },
        afterModel: function afterModel(model) {
            //breadcrumbs
            this._super(model, model.resource.get('resourceName'), model.resource.get('id'));

            var tip = this.store.peekRecord('tip', 1);
            if (tip) {
                var myRating = model.resource.get('resourceUserReviews').findBy('user.id', model.curUser.get('id'));
                if (!myRating) {
                    this.scheduleTip(tip);
                }
            }
        },


        actions: {
            //signalr action. Invoked from the controller
            joinRoster: function joinRoster() {
                this.refresh();
            },


            setCharacterLimitAction: function setCharacterLimitAction(commentCharacterLength, characterLimit, comment) {
                this.set('currentModel.textLimit', characterLimit);
                this.set('currentModel.commentLength', commentCharacterLength);
                this.set('currentModel.resourceIssueComments', comment);
            },
            rateResource: function rateResource() {
                if (this.currentModel.commentLength > this.currentModel.textLimit) {
                    this.set("currentModel.warningMessage", "Comment exceeds the character limit.");
                    this.set("currentModel.showAlert", true);
                    return;
                }
                if (this.currentModel.resourceRating) {
                    //save the rating
                    var curModel = this.currentModel;
                    var self = this;
                    var rating = this.store.createRecord('resource-user-review', {
                        resource: curModel.resource,
                        user: curModel.curUser,
                        ratingScore: curModel.resourceRating,
                        comments: curModel.resourceComments,
                        ratingDate: new Date(),
                        anonymous: curModel.isAnonymous
                    });
                    rating.save().then(function () {
                        self.set('currentModel.resourceRating', null);
                        self.set('currentModel.resourceComments', '');
                        self.set('currentModel.isAnonymous', false);
                    }, function (error) {
                        if (error && error.errors && error.errors.length > 0 && error.errors[0].status == '409') {
                            rating.rollbackAttributes();
                            self.set("currentModel.warningMessage", "The resource has already been rated by the user");
                            self.set('currentModel.resourceRating', null);
                            self.set('currentModel.resourceComments', '');
                        } else if (ra.isError) {
                            rating.rollbackAttributes();
                            self.set("currentModel.warningMessage", "There was an error sending the resource. Please check your internet connection and try again.");
                        }
                        self.set("currentModel.showAlert", true);
                    });
                } else {
                    this.set('currentModel.warningMessage', "You haven't selected a rating for this resource. Please select a rating and try again.");
                    this.set("currentModel.showAlert", true);
                }
            },

            checkRemoveRating: function checkRemoveRating() {
                this.set('currentModel.showModal', true);
                this.set('currentModel.modalMessage', 'Are you sure you want to delete this rating?');
            },


            removeRating: function removeRating() {
                this.set('currentModel.showModal', false);
                this.set('currentModel.modalMessage', null);

                var self = this;
                var rating = this.get('currentModel.myRating');
                rating.destroyRecord().then(function () {}, function () {
                    self.set('currentModel.warningMessage', "Your rating could not be deleted. Please check your Internet connection and try again.");
                    self.set("currentModel.showAlert", true);
                });
            },

            clearRating: function clearRating() {
                this.set('currentModel.resourceRating', null);
                this.set('currentModel.resourceComments', '');
                this.set('currentModel.commentLength', 0);
            },

            setRating: function setRating(value) {
                this.set('currentModel.resourceRating', value);
            },

            setAnonymous: function setAnonymous(value) {
                this.set('currentModel.isAnonymous', value);
            },

            setComment: function setComment(value) {
                this.set('currentModel.resourceComments', value);
            },

            //Hide alert box
            hideAlert: function hideAlert() {
                this.set("currentModel.showAlert", false);
            },

            //Hide alert box
            hideConfirm: function hideConfirm() {
                this.set("currentModel.showConfirm", false);
            },
            cancelStudents: function cancelStudents() {
                this.set('currentModel.selectedStudents', '');
            },
            clearStudents: function clearStudents() {
                this.set('currentModel.selectedStudents', '');
            },
            createBookmark: function createBookmark() {
                var curModel = this.currentModel;
                var self = this;
                if (!curModel.bookmark) {
                    var b = this.store.createRecord('resource-bookmark', {
                        resource: curModel.resource,
                        user: curModel.curUser,
                        bookmarkedDate: new Date()
                    });
                    b.save().then(function () {
                        self.refresh();
                    }, function () {
                        self.set('currentModel.warningMessage', "This resource could not be bookmarked. Please check your Internet connection and try again.");
                        self.set("currentModel.showAlert", true);
                    });
                }
            },

            deleteBookmark: function deleteBookmark() {
                var curModel = this.currentModel;
                var self = this;
                if (curModel.bookmark) {
                    var b = curModel.bookmark;
                    b.destroyRecord().then(function () {
                        self.refresh();
                    }, function () {
                        self.set('currentModel.warningMessage', "This bookmark could not be deleted. Please check your Internet connection and try again.");
                        self.set("currentModel.showAlert", true);
                    });
                }
            },

            //Add/Remove selected student group from selectedStudentGroups array
            setSelectedStudentGroup: function setSelectedStudentGroup(selectedValue) {
                var studentGroupId = selectedValue;
                if (studentGroupId) {
                    var studentGroup = this.store.peekRecord('student-group', parseInt(studentGroupId));
                    //If student group already exist in array remove it otherwise add it
                    if (this.currentModel.selectedStudentGroups.contains(studentGroup)) {
                        this.currentModel.selectedStudentGroups.removeObject(studentGroup);
                    } else {
                        this.currentModel.selectedStudentGroups.pushObject(studentGroup);
                    }
                }
            },
            sendResource: function sendResource() {
                var self = this;
                var store = this.store;
                var hostAddress = self.get('store').adapterFor('application').get('host');
                var selectedStudentGroups = this.currentModel.selectedStudentGroups;
                var selectedStudents = this.currentModel.selectedStudents;
                var resource = this.currentModel.resource;

                var sesObj = this.get('session');
                var teacherId = sesObj.session.content.authenticated["userId"];
                var teacher = this.currentModel.curUser;
                var teacherLinkedStudents = this.currentModel.teacherLinkedStudents;

                if (teacher) {
                    //Assign student groups
                    selectedStudentGroups.forEach(function (item, index, enumerable) {
                        Ember.$.getJSON(hostAddress + '/studentGroups/updateLastUsed?groupId=' + item.id).done(function (response) {
                            if (response) var students = item.get('students');
                            self.assignResourceToStudent(store, self, students, teacher, resource);
                        }).fail(function (error) {
                            self.controllerFor('application').set('errorStatusCode', error.status);
                            self.transitionTo('error');
                        });
                    });

                    //Assign manually added students
                    if (selectedStudents) {
                        self.assignResourceToStudent(store, self, selectedStudents, teacher, resource);
                    }

                    //teacher linked students
                    var isInvitedAll = self.get('currentModel.isInviteAllSelected');
                    if (isInvitedAll) {
                        self.assignResourceToStudent(store, self, teacherLinkedStudents, teacher, resource);
                    }

                    if (selectedStudentGroups && selectedStudentGroups.length > 0 || selectedStudents && selectedStudents.length > 0 || isInvitedAll) {
                        var self = this;
                        setTimeout(function () {
                            if (!self.get('currentModel.showAlert')) {
                                self.set('currentModel.confirmationMessage', 'Resources sent successfully!');
                                self.set('currentModel.showConfirm', true);

                                self.set('currentModel.selectedStudents', null);
                                self.set('currentModel.selectedStudentGroups', []);
                                self.set('currentModel.isInviteAllSelected', false);
                            }
                        }, 1500);
                    }
                } else {
                    this.set('currentModel.studentMessage', 'Please select at least one student or group.');
                    return false;
                }
            },


            showFullDescription: function showFullDescription() {
                this.set('currentModel.showFullDescription', true);
            },

            hideFullDescription: function hideFullDescription() {
                this.set('currentModel.showFullDescription', false);
            },

            reportIssue: function reportIssue() {
                this.set('currentModel.issueMessage', null);
                var self = this;
                var adapter = this.get('store').adapterFor('application');

                var url = adapter.get('host') + '/accounts/reportResource?resourceId=' + this.get('currentModel.resource').get('id') + '&issue=' + this.get('currentModel.resourceIssue') + '&comment=' + this.get('currentModel.resourceIssueComments');
                if (this.get('currentModel.resourceIssueComments').trim() == '') {
                    url = adapter.get('host') + '/accounts/reportResource?resourceId=' + this.get('currentModel.resource').get('id') + '&issue=' + this.get('currentModel.resourceIssue');
                }

                adapter.ajax(url, 'POST').then(function () {
                    self.set('currentModel.showConfirm', true);
                    self.set('currentModel.confirmationMessage', 'Your report has been received and is currently being reviewed. If we have any follow up questions, someone will be in touch!');
                }, function () {
                    self.set('currentModel.showAlert', true);
                    self.set("currentModel.warningMessage", "There was an error sending your report. Please check your internet connection and try again.");
                });
                this.set('currentModel.resourceIssueComments', '');
                this.set('currentModel.resourceIssue', null);
            },

            setResourceIssue: function setResourceIssue(value) {
                this.set('currentModel.resourceIssue', value);
            },

            setResourceIssueComment: function setResourceIssueComment(value) {
                this.set('currentModel.resourceIssueComments', value);
            },

            validateIssue: function validateIssue() {

                if (!this.get('currentModel.resourceIssue')) {
                    this.set('currentModel.issueMessage', 'Please tell us what is wrong with this resource.');
                    return false;
                } else if (this.get('currentModel.resourceIssue') == "Other" && !this.get('currentModel.resourceIssueComments').trim()) {
                    this.set('currentModel.issueMessage', 'Please add some additional information about what is wrong with this resource.');
                    return false;
                } else if (this.get('currentModel.resourceIssueComments.length') > this.get('currentModel.textLimit')) {
                    this.set('currentModel.issueMessage', 'Comment exceeds the character limit.');
                    return false;
                } else {
                    return true;
                }
            },

            setInviteAllStudents: function setInviteAllStudents(selectedValue) {
                this.toggleProperty('currentModel.isInviteAllSelected');
            },
            validateStudent: function validateStudent() {
                if (this.currentModel.selectedStudentGroups && this.currentModel.selectedStudentGroups.length > 0 || this.currentModel.selectedStudents && this.currentModel.selectedStudents.length > 0 || this.currentModel.isInvitedAll) {
                    return true;
                } else {
                    this.set('currentModel.studentMessage', 'Please select at least one student or group.');
                    return false;
                }
            },


            loading: function loading(transition, originRoute) {
                if (originRoute != this) return true;
            },

            confirmVisitResource: function confirmVisitResource(webUrl) {
                //var user = this.get('currentModel.curUser');
                var self = this;
                this.disableVisitResourceButton(true);
                // var user = this.get('store').findRecord('user',this.get('currentModel.curUser.id'),{reload : true}).then((user)=>{
                var user = this.get('currentModel.curUser');
                if (user.get('hideNavigationWarning')) {
                    self.disableVisitResourceButton(false);
                    window.open(webUrl);
                } else {
                    self.set('currentModel.navigateURL', webUrl);
                    self.set('currentModel.showNavConfirmModal', true);
                }
                // })
            },
            cancel: function cancel() {
                this.set('currentModel.issueMessage', '');
                this.set('currentModel.resourceIssueComments', '');
                this.set('currentModel.resourceIssue', null);
            },
            clear: function clear() {
                this.set('currentModel.issueMessage', '');
                this.set('currentModel.resourceIssueComments', '');
                this.set('currentModel.resourceIssue', null);
            },


            showFlashWarning: function showFlashWarning() {
                this.set('currentModel.showFlashWarning', true);
            },

            closeFlash: function closeFlash() {
                this.set('currentModel.showFlashWarning', false);
            },

            closeTip: function closeTip(doNotShowAgain) {
                if (doNotShowAgain) {
                    this.destroyTip(1);
                }
                this.set('currentModel.showTip', false);
            },

            addResourceToClassLibrary: function addResourceToClassLibrary() {
                this.transitionTo('add-resource-classlibrary', this.get('currentModel.resource.id'));
            },
            viewResourceInClassLibrary: function viewResourceInClassLibrary() {
                this.transitionTo('view-class-linked-resource', this.get('currentModel.resource.classLinkedResources.firstObject.id'));
            }
        },

        //Methods
        assignResourceToStudent: function assignResourceToStudent(store, self, students, teacher, resource) {
            //Iterate through students
            students.forEach(function (item, index, enumerable) {
                //Check whether resource assigned for the current student or not
                if (!self.currentModel.studentIds.contains(item.id)) {
                    self.currentModel.studentIds.pushObject(item.id);

                    var adapter = self.get('store').adapterFor('application');
                    adapter.ajax(adapter.get('host') + '/resourceAssigneds/isResourceAlreadySent/' + resource.id + '/' + item.id + '/' + teacher.id, 'GET').then(function (result) {
                        //check whether resource already sent to the student
                        if (!result) {
                            //create resource assigned
                            var ra = store.createRecord('resource-assigned', {
                                resource: resource,
                                student: item,
                                user: teacher });
                            ra.save().then(function () {}, function (error) {
                                //duplicate entry to resource user link table returns a 409 status
                                if (error && error.errors && error.errors.length > 0 && error.errors[0].status == '409') {
                                    ra.rollbackAttributes();
                                } else if (ra.isError) {
                                    ra.rollbackAttributes();
                                    self.set("currentModel.showAlert", true);
                                    self.set("currentModel.warningMessage", "There was an error sending the resource. Please check your internet connection and try again.");
                                }
                                self.set('currentModel.showConfirm', false);
                            });
                        } else {
                            //update resource assigned to user
                            self.store.queryRecord('resource-assigned', { resourceId: object.get('resource.id'), studentId: object.get('student.id'), sentByUserId: object.get('teacher.id') }).then(function (assignedResource) {
                                assignedResource.set('assignedDate', new Date());
                                assignedResource.set('viewed', false);
                                assignedResource.set('viewedDate', null);

                                assignedResource.save().then(function () {}, function (error) {
                                    self.set("currentModel.showAlert", true);
                                    self.set("currentModel.warningMessage", "There was an error sending the resource. Please check your internet connection and try again.");
                                });
                            });
                        }
                    }, function () {
                        self.set("currentModel.showAlert", true);
                        self.set("currentModel.warningMessage", "There was an error sending the resource. Please check your internet connection and try again.");
                    });
                }
            });
        },
        disableVisitResourceButton: function disableVisitResourceButton(isDisable) {
            $('.button-subtle').prop('disabled', isDisable);
        }
    });
});
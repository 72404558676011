define('ohs/components/x-breadcrumbs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['breadcrumb-wrapper'],
    session: Ember.inject.service('session'),
    breadcrumbs: null,

    linkedBreadcrumbs: Ember.computed('breadcrumbs.@each.text', function () {
      if (this.get('breadcrumbs')) {
        var numCrumbs = this.get('breadcrumbs').get('length');
        if (numCrumbs > 4) {
          return this.get('breadcrumbs').slice(numCrumbs - 4, numCrumbs - 1);
        } else {
          return this.get('breadcrumbs').slice(0, numCrumbs - 1);
        }
      }
    }),

    curCrumb: Ember.computed('breadcrumbs.@each.text', function () {
      if (this.get('breadcrumbs')) {
        var numCrumbs = this.get('breadcrumbs').get('length');
        return this.get('breadcrumbs')[numCrumbs - 1];
      }
    }),

    didRender: function didRender() {
      this.set('breadcrumbs', this.get('session').get('breadcrumbs'));
    },

    actions: {}

  });
});
define('ohs/routes/student/quiz', ['exports', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/signalr-student-route'], function (exports, _reset, _noNavigation, _authenticatedRouteMixin, _signalrStudentRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _noNavigation.default, _reset.default, _signalrStudentRoute.default, {
        signalr: Ember.inject.service('signal-r'),

        setupController: function setupController(controller, model) {
            this._super.apply(this, arguments);
            //controller.set('administration', model.administration);
        },
        model: function model(params) {
            var store = Ember.get(this, 'store');
            //let studentTestAdministration = get(this, 'store').peekRecord('student-test-administration', get(params, 'student_test_administration_id'));
            return Ember.RSVP.hash({
                viewMode: 'passage',
                viewModeString: Ember.computed('viewMode', function () {
                    return Ember.get(this, 'otherViewMode').capitalize();
                }),

                otherViewMode: Ember.computed('viewMode', function () {
                    return Ember.get(this, "viewMode") == 'passage' ? 'questions' : 'passage';
                }),

                isTransitionApproved: false,
                studentTestAdministration: Ember.get(this, 'store').findRecord('student-test-administration', Ember.get(params, 'student_test_administration_id')),
                teacher: Ember.computed('studentTestAdministration.id', function () {
                    return store.peekRecord('teacher', Ember.get(this, 'studentTestAdministration.administration.quiz.createUserId'));
                }),

                administration: Ember.computed.alias('studentTestAdministration.administration'),

                quiz: store.queryRecord('quiz', { studentTestAdministrationId: Ember.get(params, 'student_test_administration_id') }),
                testQuestions: store.query('test-question', { studentTestId: Ember.get(params, 'student_test_administration_id') }),
                passage: Ember.computed.alias('quiz.passages.firstObject'),

                selectedFirstPartAnswer: Ember.computed('studentTestAdministration.studentAnswers.[]', 'studentTestAdministration.studentAnswers.@each.id', 'administration.testQuestion.id', function () {
                    var questionId = parseInt(Ember.get(this, 'administration.testQuestion.question.id'));
                    return Ember.get(this, 'studentTestAdministration.studentAnswers').findBy('questionId', questionId);
                }),

                selectedSecondPartAnswers: Ember.computed('studentTestAdministration.studentSecondPartAnswers.[]', 'studentTestAdministration.studentSecondPartAnswers.@each.id', 'administration.testQuestion.id', function () {
                    var questionId = parseInt(Ember.get(this, 'administration.testQuestion.question.id'));
                    return Ember.get(this, 'studentTestAdministration.studentSecondPartAnswers').filterBy('questionId', questionId) || [];
                }),

                percentComplete: Ember.computed('administration.testQuestion.id', function () {
                    var total = Ember.get(this, 'administration.quiz.numQuestions');
                    var current = Ember.get(this, 'administration.currentQuestionIndex') + 1;
                    return Math.round(current / total * 100);
                }),

                //scratchpad options
                currentColor: 'red',
                currentTool: 'draw',
                backgroundColor: 'white'
            });
        },
        afterModel: function afterModel(model) {
            if (Ember.get(model, 'studentTestAdministration.cancelled')) {
                Ember.set(model, "alertMessage", "Your teacher has cancelled this quiz!");
                Ember.set(model, 'showCancelAlert', true);
            } else if (!Ember.get(model, 'studentTestAdministration.currentlyInQuiz')) {
                Ember.set(model, 'studentTestAdministration.currentlyInQuiz', true);
                Ember.get(model, 'studentTestAdministration').save();
            }

            var administration = Ember.get(model, 'studentTestAdministration.administration');
            Ember.set(this, 'signalr.administrationId', Ember.get(administration, 'id'));
            Ember.get(this, 'signalr').joinAdministration(Ember.get(administration, 'id'));
        },


        actions: {
            //signal-r actions
            cancelQuiz: function cancelQuiz() {
                this._super.apply(this, arguments);
                Ember.get(this, 'signalr').leaveAdministration(Ember.get(this, 'currentModel.studentTestAdministration.administration.id'));
                Ember.set(this, 'currentModel.studentTestAdministration.cancelled', true);
                Ember.set(this, "currentModel.alertMessage", "Your teacher has cancelled this quiz!");
                Ember.set(this, 'currentModel.showCancelAlert', true);
            },
            endQuiz: function endQuiz(administrationId) {
                this._super.apply(this, arguments);
                Ember.get(this, 'signalr').leaveAdministration(administrationId);
                this.transitionTo('student-results', Ember.get(this, 'currentModel.studentTestAdministration.id'), '1');
            },
            convertToSelfGuidedQuiz: function convertToSelfGuidedQuiz(studentTestId, testQuestionId) {
                this._super.apply(this, arguments);
                switch (Ember.get(this, 'currentModel.administration.testTypeId')) {
                    case 2:
                        this.transitionTo('student-passage-quiz', studentTestId);break;
                    default:
                        this.transitionTo('student-self-guided-question', studentTestId, testQuestionId);break;
                }
            },
            changeQuestion: function changeQuestion(questionIndex, answersLocked, administrationId) {
                this._super.apply(this, arguments);
                if (administrationId = Ember.get(this, 'currentModel.administration.id')) {
                    Ember.set(this, 'currentModel.studentTestAdministration.administration.answersLocked', answersLocked);

                    if (Ember.get(this, 'currentModel.studentTestAdministration.administration.currentQuestionIndex') != questionIndex) {
                        var canvas = $('#myCanvas')[0];
                        if (canvas) {
                            Ember.set(this, 'currentModel.studentTestAdministration.administration.testQuestion.scratchpadContent', canvas.toDataURL());
                        }
                        Ember.set(this, 'currentModel.showScratchpad', false);
                    }

                    if (!Ember.get(this, 'currentModel.administration.isOpen')) {
                        Ember.set(this, 'currentModel.administration.isOpen', true);
                        //switch is here for when we move the other test types to this style of nesting.
                        switch (Ember.get(this, 'currentModel.administration.testTypeId')) {
                            case 3:
                                this.transitionTo('student.quiz.game-mode.in-progress', Ember.get(this, 'currentModel.studentTestAdministration.id'));break;
                            case 2:
                                this.transitionTo('student.quiz.teacher-led.ela.in-progress', Ember.get(this, 'currentModel.studentTestAdministration.id'));break;
                        }
                    } else {
                        Ember.set(this, 'currentModel.administration.currentQuestionIndex', questionIndex);
                    }
                }
            },

            //end signal-r actions

            retryTransition: function retryTransition() {
                Ember.set(this, 'currentModel.confirmTransition', false);
                Ember.set(this, 'currentModel.isTransitionApproved', true);
                Ember.get(this, 'currentModel.transition').retry();
            },
            goToQuizzes: function goToQuizzes() {
                this.transitionTo('student-quizzes');
            },
            showScratchpad: function showScratchpad() {
                this.toggleProperty('currentModel.showScratchpad');
            }
        }
    });
});
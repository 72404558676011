define('ohs/components/result-overview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    questionBreakdown: Ember.computed('administration.studentTestAdministrations.@each.percentCorrect', function () {
      var testQuestions = this.get('administration').get('quiz').get('testQuestions');
      var numStudents = this.get('studentsWithResults').get('length');
      var admin = this.get('administration');
      var returnArray = [];
      testQuestions.forEach(function (item, index) {
        var questionNumber = item.get('questionOrder');
        var pctCorrect = 0;
        if (numStudents == 0) {
          pctCorrect = 0;
        } else {
          var questionId = item.get('question').get('id');
          var numPoints = 1;
          if (item.get('question.secondPartQuestion') && item.get('question.secondPartQuestion.numCorrectAnswers') > 0) {
            numPoints += item.get('question.secondPartQuestion.numCorrectAnswers');
          }
          var numCorrect = 0;
          admin.get('studentTestAdministrations').forEach(function (item1, index1) {
            var studentAnswer = item1.get('studentAnswers').findBy('questionId', parseInt(questionId));
            if (studentAnswer && studentAnswer.get('isCorrect')) {
              numCorrect++;
              if (item.get('question.secondPartQuestion') && item.get('question.secondPartQuestion.numCorrectAnswers') > 0) {
                var studentSecondPartAnswers = item1.get('studentSecondPartAnswers').filterBy('questionId', parseInt(questionId));
                if (studentSecondPartAnswers && studentSecondPartAnswers.length <= item.get('question.secondPartQuestion.numCorrectAnswers')) {
                  for (var i = 0; i < studentSecondPartAnswers.length; i++) {
                    if (studentSecondPartAnswers.objectAt(i).get('isCorrect')) numCorrect++;
                  }
                }
              }
            }
          });
          var pctCorrect = Math.round(numCorrect / (numStudents * numPoints) * 100);
        }
        var object = { "questionNumber": questionNumber, "pctCorrect": pctCorrect };
        returnArray.pushObject(object);
      });
      return returnArray;
    }),

    chartOptions: {
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          },
          scaleLabel: {
            display: true,
            labelString: 'Questions'
          }
        }],
        yAxes: [{
          ticks: {
            max: 100,
            min: 0,
            stepSize: 10
          },
          scaleLabel: {
            display: true,
            labelString: '% Correct'
          }
        }]
      },
      tooltips: {
        //Makes the colored block not display
        displayColors: false,
        callbacks: {
          //Question C: 3 Students 
          title: function title(tooltipItem, data) {
            return 'Question ' + tooltipItem[0].xLabel + ': ' + tooltipItem[0].yLabel + '%';
          },
          //return nothing for the label
          label: function label(tooltipItem, data) {
            return '';
          }
        }
      }
    },

    chartData: Ember.computed('questionBreakdown', function () {
      var questions = this.get('questionBreakdown').sortBy('questionNumber');
      var labels = [];
      var datasets = [];
      var dataArray = [];
      questions.forEach(function (item, index) {
        labels.pushObject(item["questionNumber"]);
        dataArray.pushObject(item["pctCorrect"]);
      });

      return {
        labels: labels,
        datasets: [{
          backgroundColor: 'rgba(137, 220, 220, 0.7)',
          borderColor: 'rgba(137, 220, 220, 0.7)',
          data: dataArray
        }]
      };
    }),

    strongestScore: Ember.computed('questionBreakdown', function () {
      if (this.get('questionBreakdown').get('length') > 0) {
        var questions = this.get('questionBreakdown').sortBy('pctCorrect');
        return questions.get('lastObject')['pctCorrect'];
      }
    }),

    strongestQuestions: Ember.computed('questionBreakdown', function () {
      if (this.get('questionBreakdown').get('length') > 0) {
        var questions = this.get('questionBreakdown').sortBy('questionNumber').sortBy('pctCorrect');
        var questionNumbers = questions.get('lastObject')['questionNumber'];
        var label = "Question ";

        var l = questions.length - 1;
        var done = false;
        if (l < 1) done = true;
        while (!done && l > 0) {
          if (questions[l]['pctCorrect'] == questions[l - 1]['pctCorrect']) {
            label = "Questions ";
            questionNumbers = questions[l - 1]['questionNumber'] + ", " + questionNumbers;
            l--;
          } else {
            done = true;
          }
        }

        return label + questionNumbers;
      }
    }),

    weakestScore: Ember.computed('questionBreakdown', function () {
      if (this.get('questionBreakdown').get('length') > 0) {
        var questions = this.get('questionBreakdown').sortBy('pctCorrect');
        return questions.get('firstObject')['pctCorrect'];
      }
    }),

    weakestQuestions: Ember.computed('questionBreakdown', function () {
      if (this.get('questionBreakdown').get('length') > 0) {
        var questions = this.get('questionBreakdown').sortBy('questionNumber').sortBy('pctCorrect');
        var questionNumbers = questions.get('firstObject')['questionNumber'];
        var label = "Question ";

        var l = 0;
        var done = false;
        if (questions.length < 2) done = true;
        while (!done && l < questions.length - 1) {
          if (questions[l]['pctCorrect'] == questions[l + 1]['pctCorrect']) {
            label = "Questions ";
            questionNumbers += ", " + questions[l + 1]['questionNumber'];
            l++;
          } else {
            done = true;
          }
        }

        return label + questionNumbers;
      }
    }),

    strongestScoreLabel: Ember.computed('strongestQuestions', function () {
      if (this.get('strongestQuestions')) {
        if (this.get('strongestQuestions').indexOf(',') > 0) return "Strongest Scores";else {
          return "Strongest Score";
        }
      }
    }),

    weakestScoreLabel: Ember.computed('weakestQuestions', function () {
      if (this.get('weakestQuestions')) {
        if (this.get('weakestQuestions').indexOf(',') > 0) return "Weakest Scores";else {
          return "Weakest Score";
        }
      }
    })
  });
});
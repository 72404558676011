define('ohs/routes/student-quiz-room', ['exports', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/signalr-student-route'], function (exports, _reset, _noNavigation, _authenticatedRouteMixin, _signalrStudentRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reset.default, _noNavigation.default, _authenticatedRouteMixin.default, _signalrStudentRoute.default, {
        signalR: Ember.inject.service('signal-r'),

        titleToken: function titleToken(model) {
            return 'Quiz Room for ' + model.studentTestAdministration.get('administration').get('quiz.quizName');
        },

        setupController: function setupController(controller, model) {
            this._super.apply(this, arguments);
            //Set administration id to sigal-r service property
            this.get('signalR').administrationId = model.studentTestAdministration.get('administration').get('id');
            //join administration
            this.get('signalR').joinAdministration(model.studentTestAdministration.get('administration').get('id'));
        },
        model: function model(params) {
            var store = this.store;
            return store.queryRecord('quiz', { studentTestAdministrationId: params.student_test_id }).then(function (quiz) {
                return Ember.RSVP.hash({
                    tickNumber: 0,
                    message: "",
                    messageClass: "",
                    iconClass: "",
                    alreadyOpened: false,

                    studentTestAdministration: store.findRecord('studentTestAdministration', params.student_test_id),
                    testQuestions: store.query('testQuestion', { studentTestId: params.student_test_id }),
                    teacher: store.findRecord('user', get(quiz, 'createUserId'))
                });
            });
        },


        afterModel: function afterModel(model) {
            if (model.studentTestAdministration.get('cancelled')) {
                model.loadFinished = false;
                model.showCancelAlert = true;
                model.alertMessage = "Your teacher has cancelled this quiz!";
                return;
            }

            model.studentTestAdministration.set('currentlyInQuiz', true);
            model.studentTestAdministration.save();

            var testTypeId = model.studentTestAdministration.get('administration').get('testTypeId');
            if (model.studentTestAdministration.get('administration').get('isOpen')) {
                if (testTypeId == 1) {
                    var firstUnanswered = null;

                    var testQuestions = model.studentTestAdministration.get('administration').get('quiz').get('testQuestions').sortBy('questionOrder');
                    var studentAnswers = model.studentTestAdministration.get('studentAnswers');
                    var foundFirst = false;
                    testQuestions.forEach(function (q) {
                        if (!foundFirst) {
                            var sa = studentAnswers.findBy('questionId', parseInt(q.get('question.id')));
                            if (!sa) {
                                firstUnanswered = q.get('id');
                                foundFirst = true;
                            }
                        }
                    });

                    if (firstUnanswered) {
                        // this.transitionTo('student-self-guided-question',model.studentTestAdministration.get('id'),firstUnanswered);
                        if (model.studentTestAdministration.get('administration').get('quiz').get('subject.id') == 2) {
                            this.transitionTo('student-passage-quiz', model.studentTestAdministration.get('id'));
                        } else {
                            this.transitionTo('student-self-guided-question', model.studentTestAdministration.get('id'), firstUnanswered);
                        }
                    } else if (testTypeId == 3) {
                        this.transitionTo('student.quiz.game-mode.lobby');
                    } else {
                        if (model.studentTestAdministration.get('administration').get('quiz').get('subject.id') == 2) {
                            this.transitionTo('student-passage-quiz', model.studentTestAdministration.get('id'));
                        } else {
                            this.transitionTo('student-review-quiz', model.studentTestAdministration.get('id'));
                        }
                    }
                } else if (testTypeId == 2) {
                    this.transitionTo('student-question', model.studentTestAdministration.get('id'));
                } else if (testTypeId == 3) {
                    this.transitionTo('student.quiz.game-mode.lobby', model.studentTestAdministration.get('id'));
                }
            } else if (testTypeId == 3) {
                this.transitionTo('student.quiz.game-mode.lobby', model.studentTestAdministration.get('id'));
            }

            var currentModel = model;
            this.tick();
        },

        tick: function tick() {
            var nextTick = Ember.run.later(this, function () {
                try {
                    this.set('currentModel.tickNumber', this.get('currentModel.tickNumber') + 1);
                    this.tick();
                } catch (e) {
                    //do nothing
                }
            }, 1000);
            this.set('nextTick', nextTick);
        },

        actions: {
            changeQuestion: function changeQuestion(questionIndex, answersLocked, administrationId) {
                this._super.apply(this, arguments);
                //var sta = store.find('studentTestAdministration',studentTestId);
                if (this.get('currentModel') && this.get('currentModel').studentTestAdministration && administrationId == this.get('currentModel').studentTestAdministration.get('administration').get('id')) {
                    if (this.get('currentModel') && !this.get('currentModel.alreadyOpened')) {
                        this.set('currentModel.alreadyOpened', true);
                        var testTypeId = this.get('currentModel').studentTestAdministration.get('administration').get('testTypeId');
                        if (testTypeId == 1) {
                            // this.transitionTo('student-self-guided-question',this.get('currentModel').studentTestAdministration.get('id'),this.get('currentModel').studentTestAdministration.get('administration').get('quiz').get('testQuestions').sortBy('questionOrder').get('firstObject').get('id'));
                            if (this.get('currentModel.studentTestAdministration').get('administration').get('quiz').get('subject.id') == 2) {
                                this.transitionTo('student-passage-quiz', this.get('currentModel').studentTestAdministration.get('id'));
                            } else {
                                this.transitionTo('student-self-guided-question', this.get('currentModel').studentTestAdministration.get('id'));
                            }
                        } else if (testTypeId == 2) {
                            this.transitionTo('student-question', this.get('currentModel').studentTestAdministration.get('id'));
                        }
                    }
                }
            },
            cancelQuiz: function cancelQuiz(administrationId) {
                this._super.apply(this, arguments);
                if (this.get('currentModel') && this.get('currentModel').studentTestAdministration && administrationId == this.get('currentModel').studentTestAdministration.get('administration').get('id')) {
                    if (this.get('currentModel') && !this.get('currentModel.alreadyOpened')) {
                        this.set('currentModel.studentTestAdministration.cancelled', true);
                    }
                }
            },
            signalRConnectionAlert: function signalRConnectionAlert(message, messageClass, iconClass) {
                this._super.apply(this, arguments);
                Ember.set(this.get('currentModel'), 'message', message);
                Ember.set(this.get('currentModel'), 'messageClass', messageClass);
                Ember.set(this.get('currentModel'), 'iconClass', iconClass);
            },


            loading: function loading(transition, originRoute) {
                if (originRoute != this) return true;
            },

            gotoQuizzes: function gotoQuizzes() {
                //Leave administration
                this.get('signalR').leaveAdministration(this.get('currentModel.studentTestAdministration.administration.id'));
                this.transitionTo('student-quizzes');
            }
        }
    });
});
define('ohs/components/mission-control/planet-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        attributeBindings: ['hidden'],
        actions: {
            close: function close() {
                this.closeModal();
            }
        }
    });
});
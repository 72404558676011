define('ohs/adapters/rest-adapter', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'ohs/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var RESTAdapter = _emberData.default.RESTAdapter;
    exports.default = RESTAdapter.extend(_dataAdapterMixin.default, {
        host: _environment.default.apiUrl,
        namespace: '/api',
        authorizer: 'authorizer:custom'
    });
});
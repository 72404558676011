define('ohs/components/choose-answer-second-part', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['question', 'current'],
        store: Ember.inject.service(),
        testQuestion: null,
        firstPartDisabled: Ember.computed('answersLocked', function () {
            return Ember.get(this, 'answersLocked');
        }),

        secondPartIsDisabled: Ember.computed('selectedFirstPartAnswer', 'answersLocked', function () {
            return !Ember.get(this, 'selectedFirstPartAnswer') || Ember.get(this, 'answersLocked');
        }),

        question: Ember.computed('testQuestion.id', function () {
            return Ember.get(this, 'testQuestion.question');
        }),

        selectedSecondPartAnswerIds: Ember.computed('selectedSecondPartAnswers.[]', 'selectedSecondPartAnswers.@each.id', function () {
            var _this = this;

            return Ember.get(this, 'selectedSecondPartAnswers').map(function (sspa) {
                return Ember.get(_this, 'store').peekRecord('second-part-answer-option', Ember.get(sspa, 'secondPartAnswerId'));
            });
        }),

        actions: {
            selectAnswer: function selectAnswer(answerId) {
                this.sendAction('selectAnswer', answerId);
            },
            selectSecondAnswer: function selectSecondAnswer(questionType, answerId) {
                if (questionType.trim().toLowerCase() == 'checkbox') this.sendAction('selectSecondAnswerCheckbox', answerId);else this.sendAction('selectSecondAnswer', answerId);
            }
        }
    });
});
define('ohs/models/test-question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    questionOrder: _emberData.default.attr('number'),
    quiz: _emberData.default.belongsTo('quiz'),
    question: _emberData.default.belongsTo('question'),

    questionId: Ember.computed('question', function () {
      return this.get('question').get('id');
    })

  });
});
define('ohs/routes/pattern-library/modules/index', ['exports', 'ohs/mixins/reset', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/no-navigation'], function (exports, _reset, _authenticatedRouteMixin, _noNavigation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _noNavigation.default, _authenticatedRouteMixin.default, {
    model: function model() {
      return true;
    }
  });
});
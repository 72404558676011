define('ohs/routes/schoology-login', ['exports', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/authenticated-route-mixin'], function (exports, _reset, _noNavigation, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _reset.default, _noNavigation.default, {
    beforeModel: function beforeModel() {
      var self = this;
      //get cookies
      var pairs = document.cookie.split(";");
      var cookies = {};
      for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].trim().split("=");
        cookies[pair[0]] = unescape(pair[1]);
      }

      if (cookies.SRT && cookies.SRTS) {
        var self = this;
        Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/getSchoologyAccessTokens?SRT=' + cookies.SRT + '&SRTS=' + cookies.SRTS).done(function (response) {
          if (response.oauth_token) {
            //log in with schoology
            var credentials = { 'schoologyAccessToken': response.oauth_token, 'schoologyAccessSecret': response.oauth_token_secret };
            self.get('session').authenticate('authenticator:custom', credentials).catch(function (message) {
              if (message) {
                var obj = JSON.parse(message);

                //clear out cookies
                var cookie_date = new Date();
                cookie_date.setTime(cookie_date.getTime() - 1);
                document.cookie = 'SRT=; Path=/; Expires=' + cookie_date.toGMTString();
                document.cookie = 'SRTS=; Path=/; Expires=' + cookie_date.toGMTString();

                if (obj && obj.error == "account_disabled") {
                  self.transitionTo('account-disable', obj.error_uri);
                } else if (obj && obj.message) {
                  self.set('currentModel.alertText', obj.message);
                  self.set('currentModel.showLoad', false);
                  self.set('currentModel.showAlert', true);
                } else {
                  self.set('currentModel.alertText', obj.error_description);
                  self.set('currentModel.showLoad', false);
                  self.set('currentModel.showAlert', true);
                }
              } else {
                self.set('currentModel.alertText', 'Error occurred. Please try again.');
                self.set('currentModel.showLoad', false);
                self.set('currentModel.showAlert', true);
              }
            });
          } else {
            self.set('currentModel.alertText', 'Error occurred. Please try again.');
            self.set('currentModel.showLoad', false);
            self.set('currentModel.showAlert', true);
          }
        }).fail(function (response) {
          self.set('currentModel.alertText', 'Error occurred. Please try again.');
          self.set('currentModel.showLoad', false);
          self.set('currentModel.showAlert', true);
        });
      } else {
        this.transitionTo('login');
      }
    },

    model: function model() {
      return Ember.RSVP.hash({
        showAlert: false,
        alertText: "",
        showLoad: true
      });
    },


    actions: {
      closeAlert: function closeAlert() {
        this.transitionTo('login');
      }
    }
  });
});
define('ohs/routes/student-parent-list', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/student-authorize-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _studentAuthorizeMixin, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _studentAuthorizeMixin.default, _breadcrumbMixin.default, {
    titleToken: 'My Parents/Guardians',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('student', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];

      return Ember.RSVP.hash({
        //parents: this.store.query("parent",{studentId : userId}),
        student: this.store.queryRecord('student', { studentId: userId }),
        studentUserId: sesObj.session.content.authenticated["userId"],
        parentUserId: '',
        parentName: '',
        code: '',
        showModal: '',
        successMessages: '',
        validationErrors: '',
        isProcessing: false
      });
    },

    actions: {
      joinNow: function joinNow() {
        document.activeElement.blur();
        var self = this;
        self.set("currentModel.validationErrors", '');
        self.set("currentModel.successMessages", '');
        self.set("currentModel.showModal", false);
        self.set("currentModel.isProcessing", true);

        if (self.currentModel.code.trim()) {
          return Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/parents?code=' + self.currentModel.code.trim()).done(function (response) {
            if (response[0]) {
              self.set("currentModel.showModal", true);
              self.set("currentModel.parentUserId", response[0].id);
              self.set("currentModel.parentName", response[0].firstName + " " + response[0].lastName);
              self.set("currentModel.isProcessing", false);
            } else {
              self.set("currentModel.validationErrors", 'The parent code you entered could not be found.');
              self.set("currentModel.isProcessing", false);
            }
          }).fail(function (response) {
            self.set("currentModel.validationErrors", response.responseText);
            self.set("currentModel.isProcessing", false);
          });
        } else {
          self.set("currentModel.validationErrors", 'Parent code can\'t be blank.');
          self.set("currentModel.isProcessing", false);
        }
      },

      afterModel: function afterModel(model) {
        this._super(model, 'Parents/Guardians', false, true);
      },

      join: function join(parentUserId) {
        document.activeElement.blur();
        var self = this;
        self.set("currentModel.validationErrors", '');
        self.set("currentModel.successMessages", '');
        self.set("currentModel.showModal", false);

        return Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/parents?parentUserId=' + parentUserId + '&studentUserId=' + self.get("currentModel.studentUserId")).done(function (response) {
          if (response == true) {
            self.set("currentModel.successMessages", 'You have successfully linked to ' + self.get("currentModel.parentName") + '.');
            self.refresh();
          } else {
            self.set("currentModel.validationErrors", 'You have already linked to ' + self.get("currentModel.parentName") + '.');
            self.set("currentModel.isProcessing", false);
          }
        }).fail(function (response) {
          self.set("currentModel.validationErrors", response.responseText);
          self.set("currentModel.isProcessing", false);
        });
      },

      loading: function loading(transition, originRoute) {
        if (originRoute != this) return true;
      }

    }

  });
});
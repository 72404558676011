define('ohs/components/teacher-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'li',
    classNames: ['selectable-student-item']

  });
});
define('ohs/routes/student/quiz/teacher-led/lobby', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        afterModel: function afterModel(model) {
            if (get(model, 'administration.isComplete')) {
                this.transitionTo('student-results', get(model, 'studentTestAdministration.id'), '1');
            } else if (get(model, 'administration.isOpen') && get(model, 'administration.quiz.subject.id') == 2) {
                this.transitionTo('student.quiz.teacher-led.ela.in-progress', get(model, 'studentTestAdministration.id'));
            }
        }
    });
});
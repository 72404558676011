define('ohs/controllers/teacher/continue-quiz/pick-type', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        //// computeds
        enabledTestTypes: Ember.computed.filterBy('model.testTypes', 'isEnabled'),

        filteredTestTypes: Ember.computed.filter('enabledTestTypes', function (tt) {
            //test-types 1=self guided, 2=teacher-led, 3=game-mode
            // we don't allow game-mode tests to be a "continue" type
            return Ember.get(tt, 'id') !== '3';
        }),

        actions: {
            pickType: function pickType(type) {
                var administration = Ember.get(this, 'model.administration');
                var testTypeId = Ember.get(type, 'id');

                Ember.set(administration, 'testTypeId', testTypeId);

                // after setting the type, pick some students
                this.transitionToRoute('teacher.continue-quiz.pick-students');
            }
        }
    });
});
define('ohs/components/x-tab', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    didInsertElement: function didInsertElement() {
      //registers the child to the parent 
      Ember.get(this, 'parentView').send('register', this);
    }
  });
});
define('ohs/routes/edit-resource-classlibrary', ['exports', 'ohs/mixins/authorized-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authorizedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authorizedRouteMixin.default, _breadcrumbMixin.default, {
    authorizedRoles: ['Tutor', 'Teacher'],
    titleToken: 'classLinkedResources',

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      var _get = this.get('token'),
          userId = _get.userId;

      return Ember.RSVP.hash({
        teacher: this.store.findRecord('user', userId),
        classLinkedResource: this.store.findRecord('class-linked-resource', params.class_linked_resource_id)
        // classLibraryFolders: this.store.findAll('class-library-folder'),
        // orderedclassLibraryFolders: Ember.computed('classLibraryFolders.@each.id',function() {
        //   return this.classLibraryFolders.sortBy('name');
        // }),
        // selectedclassLibraryFolders:[],
      });
    },


    // afterModel(model){
    // if(model){
    //   model.classLinkedResource.get('classLibraryFolders').forEach(function(item,index) {
    //     model.selectedclassLibraryFolders.pushObject(item);
    //   });
    // }
    //  },

    actions: {
      // setSelectedclassLibraryFolder(selectedValue) {
      //     var classLibraryFolderId = selectedValue;
      //     if (classLibraryFolderId) {
      //       let classLibraryFolder = this.store.peekRecord('class-library-folder',parseInt(classLibraryFolderId));
      //       //If class Library Folder already exist in array remove it otherwise add it
      //       if(this.currentModel.selectedclassLibraryFolders.contains(classLibraryFolder)){
      //         this.currentModel.selectedclassLibraryFolders.removeObject(classLibraryFolder);
      //     }
      //     else {
      //         this.currentModel.selectedclassLibraryFolders.pushObject(classLibraryFolder);
      //     }
      //   }
      // },
      cancel: function cancel() {
        this.get('currentModel.classLinkedResource').rollbackAttributes();
        this.transitionTo('view-class-linked-resource', this.get('currentModel.classLinkedResource.id'));
      },
      //decide whether or not should the transition occur.
      willTransition: function willTransition(transition) {
        this.get('currentModel.classLinkedResource').rollbackAttributes();
      },


      hideAlert: function hideAlert() {
        var self = this;
        self.set('currentModel.alertMessage', '');
        self.set('currentModel.showAlert', false);
      },
      hideConfirm: function hideConfirm() {
        this.transitionTo('view-class-linked-resource', this.get('currentModel.classLinkedResource.id'));
      },
      save: function save() {
        var self = this;
        var classLinkedResource = self.get('currentModel.classLinkedResource');
        if (!classLinkedResource.get('invalidAttributes')) {
          classLinkedResource.set('invalidAttributes', new Array());
        }
        if (classLinkedResource.get('invalidAttributes.length') == 0) {
          self.set('currentModel.isProcessing', true);
          document.activeElement.blur();

          //increment the tick so that validation is done for each field
          self.set('currentModel.tick', self.get('currentModel.tick') + 1);

          //delay for 2 seconds to allow validations to happen
          setTimeout(function () {
            if (classLinkedResource.get('invalidAttributes.length') > 0) {
              //user needs to fix validation errorMessage
              self.set('currentModel.isProcessing', false);
            } else {
              //var self = this;
              //  classLinkedResource.set('classLibraryFolders', self.get('currentModel.selectedclassLibraryFolders'));
              //save the user
              classLinkedResource.set('teacher', self.get('currentModel.teacher'));
              //  classLinkedResource.set('resource',self.get('currentModel.resource'));
              //  classLinkedResource.set('dateCreated',new Date());
              classLinkedResource.save().then(function (u) {
                //self.uploadClassResource(u.id);
                self.set('currentModel.confirmMessage', 'Your information has been updated successfully!');
                self.set('currentModel.isProcessing', false);
              }, function (error) {
                self.set('currentModel.isProcessing', false);
                self.set('currentModel.showAlert', true);
                if (error) {
                  self.set('currentModel.alertMessage', error.message);
                } else {
                  self.set('currentModel.alertMessage', 'There was a problem creating the user. Please check your internet connection and try again.');
                }
              });
            }
          }, 1000);
        }
      }
    }
  });
});
define('ohs/models/mission-control/planet', ['exports', 'ember-data', 'ember-data/relationships', 'lodash'], function (exports, _emberData, _relationships, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        Model = _emberData.default.Model,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        image: attr('string'),
        name: attr('string'),
        title: attr('string'),
        description: attr('string'),

        ////relationships
        facts: hasMany('mission-control/fact'),
        galaxy: (0, _relationships.belongsTo)('mission-control/galaxy'),
        standards: hasMany('mission-control/standard'),
        missions: hasMany('mission-control/mission'),
        // subject: hasMany('common/subject'),
        gradeLevel: (0, _relationships.belongsTo)('grade-level'),
        alien: (0, _relationships.belongsTo)('mission-control/alien'),

        // computeds
        gradeLevelId: Ember.computed.alias('gradeLevel.id'),

        missionsSort: Object.freeze(['order']),
        sortedMissions: Ember.computed.sort('missions', 'missionsSort'),

        points: Ember.computed('missions.@each.points', function () {
            var missions = Ember.get(this, 'missions').toArray();

            return _lodash.default.sumBy(missions, function (mission) {
                return Ember.get(mission, 'points');
            });
        })

    });
});
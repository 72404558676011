define('ohs/routes/verify-email', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Verify Mail',
    session: Ember.inject.service(),
    model: function model(params) {

      return Ember.RSVP.hash({
        code: params.code,
        userId: params.userId,
        password: params.pwd,
        userName: '',
        validationErrors: '',
        isSuccess: ''
      });
    },


    afterModel: function afterModel(curModel) {
      var self = this;
      var sesObj = this.get('session');

      Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/Users/?code=' + encodeURIComponent(curModel.code) + '&userId=' + encodeURIComponent(curModel.userId)).done(function (response) {
        self.set("currentModel.isSuccess", response.emailConfirmed);
        self.set("currentModel.userName", response.userName);
        sesObj.session.content.authenticated["userName"] = response.userName;
        self.set("currentModel.errorMessages", '');
      }).fail(function (response) {
        self.set("currentModel.isSuccess", 'false');
        self.set("currentModel.errorMessages", response.responseText);
      });
    },

    actions: {
      authenticate: function authenticate() {
        var self = this;

        Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/Decrypt?valueToDecrypt=' + encodeURI(self.currentModel.password)).done(function (response) {
          var credentials = { username: self.currentModel.userName, password: response };
          self.get('session').authenticate('authenticator:custom', credentials).catch(function (message) {
            var obj = JSON.parse(message);
            self.set('currentModel.validationErrors', obj.error_description);
          });
        }).fail(function (response) {
          self.set("currentModel.validationErrors", response.responseText);
        });
      }
    }

  });
});
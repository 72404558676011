define('ohs/controllers/student-passage-quiz', ['exports', 'ohs/mixins/signalr-controller'], function (exports, _signalrController) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_signalrController.default, {
        scratchpad: Ember.Object.extend({
            content: null,
            backgroundColor: 'transparent',
            currentColor: 'red',
            currentTool: 'draw'
        })
    });
});
define('ohs/routes/student-groups-student', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Student Groups',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      return Ember.RSVP.hash({
        teacherGroups: this.store.findAll('studentGroup'),
        student: this.store.findRecord('student', params.student_id),
        orderedGroups: Ember.computed('teacherGroups', function () {
          return this.teacherGroups.sortBy('studentGroupName');
        })
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Add or Remove Groups', model.student.get('id'));
    },

    deactivate: function deactivate() {
      $('li:contains("Students")').find('li:contains("All Students")').children().first().removeClass('active');
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Students")').find('li:contains("All Students")').children().first().addClass('active');
      }, 100);
    },

    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }

  });
});
define('ohs/routes/student/mission-control/spaceships', ['exports', 'ohs/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('application').set('showSecondaryNav', true);
            this.controllerFor('application').set('student', true);
        },
        model: function model() {
            var store = Ember.get(this, 'store');
            var userId = Ember.get(this, 'token.userId');

            return Ember.RSVP.hash({
                student: store.findRecord('student', userId),
                spaceships: store.query('mission-control/student-spaceship', { studentId: userId })
            });
        }
    });
});
define('ohs/routes/teacher/quiz/game-mode/in-progress', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        signalR: Ember.inject.service('signal-r'),
        model: function model() {
            return Ember.RSVP.hash({
                //report an issue junk
                questionIssue: '',
                questionIssueComments: '',
                //not going to lie, this is a  striaght copy and paste from 'teacher-class-view'
                //we need to find a way to centralize the report an issue
                validIssue: Ember.computed('questionIssue', 'questionIssueComments', function () {
                    return Ember.get(this, 'questionIssue') && Ember.get(this, 'questionIssue') != "Other" || Ember.get(this, 'questionIssue') == "Other" && Ember.get(this, 'questionIssueComments').trim();
                }),

                //end report an issue

                isTeacherView: false,
                //used to determin if the animation already played for this round
                didAnimationPlay: false,
                //determines if the animation should reset or not
                reset: false,
                playAnimation: false,

                //holds values for 'winner-left' or 'winner-right' tells the 'game-mode-banner-in-progress' component which side won the round
                winner: Ember.computed('studentsWithAnswers', 'teamAPercentCorrect', 'teamBPercentCorret', 'playAnimation', function () {
                    if ((Ember.get(this, 'studentsWithAnswers.length') == Ember.get(this, 'joinedStudents.length') || Ember.get(this, 'playAnimation')) && Ember.get(this, 'teamAPercentCorrect') > Ember.get(this, 'teamBPercentCorrect')) {
                        return 'winner-left';
                    } else if ((Ember.get(this, 'studentsWithAnswers.length') == Ember.get(this, 'joinedStudents.length') || Ember.get(this, 'playAnimation')) && Ember.get(this, 'teamAPercentCorrect') < Ember.get(this, 'teamBPercentCorrect')) {
                        return 'winner-right';
                    } else if ((Ember.get(this, 'studentsWithAnswers.length') == Ember.get(this, 'joinedStudents.length') || Ember.get(this, 'playAnimation')) && Ember.get(this, 'teamAPercentCorrect') == Ember.get(this, 'teamBPercentCorrect')) {
                        return 'draw';
                    }
                }),

                //gets all the studentTestAdministrations that have answered a question
                studentsWithAnswers: Ember.computed('joinedStudents.@each.numAnswered', 'administration.testQuestion.id', function () {
                    var _this = this;

                    console.log(Ember.get(this, 'joinedStudents') + ' are in the quiz');
                    var studentsWithAnswers = Ember.get(this, 'joinedStudents').reduce(function (list, sta) {
                        if (Ember.get(sta, 'studentAnswers').findBy('questionId', parseInt(Ember.get(_this, 'administration.testQuestion.questionId')))) {
                            return list.concat(sta);
                        }

                        return list;
                    }, []);
                    console.log(studentsWithAnswers.length + ' students with answers');
                    return studentsWithAnswers;
                }),

                teamANumCorrect: Ember.computed('administration.teamA', 'administration.studentTestAdministrations.@each.numAnswered', 'administration.testQuestion.question.id', function () {
                    var teamA = Ember.get(this, 'administration.teamA');
                    var questionId = parseInt(Ember.get(this, 'administration.testQuestion.question.id'));
                    if (teamA) {
                        return (Ember.get(teamA, 'studentTestAdministrations') || []).reduce(function (numCorrect, sta) {
                            var answer = Ember.get(sta, 'studentAnswers').findBy('questionId', questionId);

                            if (answer && Ember.get(answer, 'isCorrect')) {
                                numCorrect++;
                            }

                            return numCorrect;
                        }, 0);
                    }

                    return 0;
                }),

                teamATotalPossible: Ember.computed('studentsWithAnswers.[]', 'administration.teamA.id', function () {
                    return (Ember.get(this, 'studentsWithAnswers').filterBy('team.id', Ember.get(this, 'administration.teamA.id')) || []).get('length');
                }),

                teamBTotalPossible: Ember.computed('studentsWithAnswers.[]', 'administration.teamB.id', function () {
                    return (Ember.get(this, 'studentsWithAnswers').filterBy('team.id', Ember.get(this, 'administration.teamB.id')) || []).get('length');
                }),

                teamBNumCorrect: Ember.computed('administration.teamB', 'administration.studentTestAdministrations.@each.numAnswered', 'administration.testQuestion.question.id', function () {
                    var teamB = Ember.get(this, 'administration.teamB');
                    var questionId = parseInt(Ember.get(this, 'administration.testQuestion.question.id'));
                    if (teamB) {
                        return (Ember.get(teamB, 'studentTestAdministrations') || []).reduce(function (numCorrect, sta) {
                            var answer = Ember.get(sta, 'studentAnswers').findBy('questionId', questionId);

                            if (answer && Ember.get(answer, 'isCorrect')) {
                                numCorrect++;
                            }

                            return numCorrect;
                        }, 0);
                    }

                    return 0;
                }),

                teamAPercentCorrect: Ember.computed('teamATotalPossible', 'teamANumCorrect', function () {
                    return Math.round(Ember.get(this, 'teamANumCorrect') / Ember.get(this, 'teamATotalPossible') * 100) || 0;
                }),

                teamBPercentCorrect: Ember.computed('teamBTotalPossible', 'teamBNumCorrect', function () {
                    return Math.round(Ember.get(this, 'teamBNumCorrect') / Ember.get(this, 'teamBTotalPossible') * 100) || 0;
                }),

                quiz: Ember.get(this.modelFor('teacher.quiz'), 'administration.quiz'),
                administration: Ember.get(this.modelFor('teacher.quiz'), 'administration'),

                orderedAnswers: Ember.computed('administration.testQuestion.question.answerOptions.@each.id', function () {
                    return Ember.get(this, 'administration.testQuestion.question.answerOptions').sortBy('id');
                }),

                joinedStudents: Ember.computed('administration.studentTestAdministrations.@each.currentlyInQuiz', function () {
                    return Ember.get(this, 'administration.studentTestAdministrations').filterBy('currentlyInQuiz', true).sortBy('student.fullName');
                }),

                currentQuestionNumber: Ember.computed('administration.currentQuestionIndex', function () {
                    return Ember.get(this, 'administration.currentQuestionIndex') + 1;
                }),

                currentProgress: Ember.computed('administration.currentQuestionIndex', 'quiz.testQuestions.[]', function () {
                    return 'Question ' + (Ember.get(this, 'administration.currentQuestionIndex') + 1) + ' of ' + Ember.get(this, 'quiz.testQuestions.length');
                })
            });
        },


        actions: {
            willTransition: function willTransition(transition) {
                switch (Ember.get(transition, 'targetName')) {
                    case 'teacher-results-1':
                    case 'teacher-quizzes':
                    case 'teacher-self-guided-results':
                    case 'error':
                    case 'teacher.quiz.game-mode.in-progress':
                    case 'teacher.quiz.game-mode.teacher-view':
                    case 'teacher.quiz.game-mode.in-progress.classroom-view':
                    case 'teacher.quiz.game-mode.in-progress.teacher-view':
                    case 'teacher.quiz.game-mode.game-results':
                        return true;
                    default:
                        if (Ember.get(this, 'currentModel.isTransitionApproved')) {
                            Ember.set(this, 'currentModel.isTransititonApproved', false);
                            return true;
                        }

                        Ember.set(this, 'currentModel.transition', transition);
                        Ember.set(this, 'currentModel.confirmTransition', true);
                        transition.abort();
                        break;
                }
            },
            retryTransition: function retryTransition() {
                Ember.set(this, 'currentModel.confirmTransition', false);
                Ember.set(this, 'currentModel.isTransitionApproved', true);
                Ember.get(this, 'currentModel.transition').retry();
            },
            reportIssue: function reportIssue(question, issue, comments) {
                var _this2 = this;

                var baseUrl = Ember.get(Ember.get(this, 'store').adapterFor('application'), 'host');
                var questionId = Ember.get(question, 'id');
                var url = baseUrl + '/accounts/reportQuestion?questionId=' + questionId + '&issue=' + issue;

                if (!comments.trim() == '') {
                    url = url.concat('&comment=' + comments);
                }

                Ember.$.post(url).then(function () {
                    Ember.set(_this2.modelFor('teacher.quiz'), 'showConfirm', true);
                    Ember.set(_this2.modelFor('teacher.quiz'), 'confirmationMessage', 'Your report has been received and is currently being reviewed. If we have any follow up questions, someone will be in touch!');
                }, function () {
                    Ember.set(_this2.modelFor('teacher.quiz'), 'showAlert', true);
                    Ember.set(_this2.modelFor('teacher.quiz'), "alertMessage", "There was an error sending your report. Please check your internet connection and try again.");
                });
                Ember.set(this, 'currentModel.questionIssue', null);
                Ember.set(this, 'currentModel.questionIssueComments', '');
            },
            validateIssue: function validateIssue(issue, comments) {
                if (!issue) {
                    Ember.set(this, 'currentModel.issueMessage', 'Please tell us what is wrong with this resource.');
                    return false;
                }

                if (issue.trim().toLowerCase() == 'other' && !comments.trim()) {
                    Ember.set(this, 'currentModel.issueMessage', 'Please add some additional information about what is wrong with this resource.');
                    return false;
                }

                Ember.set(this, 'currentModel.issueMessage', '');
                return true;
            },
            endQuiz: function endQuiz() {
                var _this3 = this;

                /*
                    I'm like 99% sure we can replace this with just adding a computed property
                    to the administration that returns a bool for if there are student answers.
                    I'm doing it this way just because the teacher-class-view route does it like this. Should revisit!
                */
                var delay = 0;
                if (!Ember.get(this, 'currentModel.didAnimationPlay')) {
                    Ember.set(this, 'currentModel.playAnimation', true);
                    if (Ember.get(this, 'currentModel.winner') != 'draw') delay = 3000;
                }

                Ember.run.later(function () {
                    var user = Ember.get(_this3.modelFor('application'), 'user');
                    var administration = Ember.get(_this3, 'currentModel.administration');
                    var baseUrl = Ember.get(Ember.get(_this3, 'store').adapterFor('application'), 'host');
                    Ember.set(_this3, 'currentModel.isProcessing', true);
                    Ember.$.getJSON(baseUrl + '/administrations/hasAnyStudentAnswers?administrationId=' + Ember.get(administration, 'id') + '&userId=' + encodeURIComponent(Ember.get(user, 'id'))).then(function (hasAnswers) {
                        if (hasAnswers) {
                            Ember.set(administration, 'isOpen', false);
                            Ember.set(administration, 'isComplete', true);
                            Ember.set(administration, 'endTime', new Date());

                            administration.save().then(function (savedAdministration) {
                                //magic "t"?
                                //im assuming it stands for "teacher", but we all know what assuming does.
                                Ember.get(_this3, 'signalR').leaveAdministration('t' + Ember.get(administration, 'id'));
                                _this3.transitionTo('teacher.quiz.game-mode.game-results', Ember.get(administration, 'id'));
                            }, function () {
                                administration.rollbackAttributes();
                                Ember.set(_this3.modelFor('teacher.quiz'), 'showAlert', true);
                                Ember.set(_this3.modelFor('teacher.quiz'), 'alertMessage', 'Could not end the quiz. Please check your internet connection and try again.');
                                Ember.set(_this3, 'currentModel.isProcessing', false);
                            });
                        }
                        //missing results, we should delete the administration
                        else {
                                Ember.set(_this3.modelFor('teacher.quiz'), 'showDeleteAdministrationAlert', true);
                                _this3.toggleProperty('currentModel.isProcessing');
                            }
                    }, function () {
                        _this3.toggleProperty('currentModel.isProcessing');
                    });
                }, delay);
            },
            nextQuestion: function nextQuestion() {
                var _this4 = this;

                this.toggleProperty('currentModel.isProcessing');
                var delay = 0;

                //if the animation did not play then we need to force it to happen
                //the delay of 3000 is to allow the animation to play before triggering the reset
                if (!Ember.get(this, 'currentModel.didAnimationPlay')) {
                    Ember.set(this, 'currentModel.playAnimation', true);
                    if (Ember.get(this, 'currentModel.winner') != 'draw') delay = 3000;
                }

                Ember.run.later(function () {
                    _this4.incrementProperty('currentModel.administration.currentQuestionIndex');
                    Ember.get(_this4, 'currentModel.administration').save().then(function () {}, function () {
                        Ember.get(_this4, 'currentModel.administration').rollbackAttributes();
                        Ember.set(_this4.modelFor('teacher.quiz'), 'showAlert', true);
                        Ember.set(_this4.modelFor('teacher.quiz'), 'currentModel.alertMessage', "Could not navigate to next question. Please check your internet connection and try again.");
                    });
                    Ember.set(_this4, 'currentModel.showChart', false);
                    Ember.set(_this4, 'currentModel.reset', true);
                    Ember.set(_this4, 'currentModel.playAnimation', false);
                    Ember.set(_this4, 'currentModel.didAnimationPlay', false);
                    _this4.toggleProperty('currentModel.isProcessing');
                }, delay);
            },
            previousQuestion: function previousQuestion() {
                var _this5 = this;

                this.decrementProperty('currentModel.administration.currentQuestionIndex');
                this.toggleProperty('currentModel.isProcessing');
                Ember.get(this, 'currentModel.administration').save().then(function () {}, function () {
                    Ember.get(_this5, 'currentModel.administration').rollbackAttributes();
                    Ember.set(_this5.modelFor('teacher.quiz'), 'showAlert', true);
                    Ember.set(_this5.modelFor('teacher.quiz'), 'currentModel.alertMessage', "Could not navigate to previous question. Please check your internet connection and try again.");
                });
                this.toggleProperty('reset');
                this.toggleProperty('currentModel.isProcessing');
            },
            lockAnswers: function lockAnswers() {
                var _this6 = this;

                Ember.set(this, 'currentModel.isProcessing', true);
                var admin = Ember.get(this, 'currentModel.administration');
                this.toggleProperty('currentModel.administration.answersLocked');

                admin.save().then(function () {
                    Ember.set(_this6, 'currentModel.isProcessing', false);
                }, function (error) {
                    admin.rollbackAttributes();
                    Ember.set(_this6, 'currentModel.isProcessing', false);
                    Ember.set(_this6.modelFor('teacher.quiz'), "showAlert", true);
                    var message = Ember.get(admin, 'answersLocked') ? "Failed to unlock the answers. Please check your internet connection and try again." : "Failed to lock the answers. Please check your internet connection and try again.";
                    Ember.set(_this6.modelFor('teacher.quiz'), "alertMessage", message);
                });
            },
            toggleView: function toggleView() {
                if (Ember.get(this, 'currentModel.isTeacherView')) {
                    this.toggleProperty('currentModel.isTeacherView');
                    this.transitionTo('teacher.quiz.game-mode.in-progress.classroom-view', Ember.get(this, 'currentModel.administration.id'));
                } else {
                    this.toggleProperty('currentModel.isTeacherView');
                    this.transitionTo('teacher.quiz.game-mode.in-progress.teacher-view', Ember.get(this, 'currentModel.administration.id'));
                }
            }
        }
    });
});
define('ohs/mixins/reset', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		activate: function activate() {
			this._super.apply(this, arguments);
			window.scrollTo(0, 0);
		}
	});
});
define('ohs/models/student', ['exports', 'ohs/models/user', 'ember-data'], function (exports, _user, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _user.default.extend({
    selectedSpaceshipId: _emberData.default.attr('number'),
    missionPointsEarned: _emberData.default.attr('number'),
    lastViewed: _emberData.default.attr('date'),

    teachers: _emberData.default.hasMany('teacher'),
    tutors: _emberData.default.hasMany('tutor'),
    studentGroups: _emberData.default.hasMany('student-group'),

    fullNameReversed: Ember.computed('firstName', 'lastName', function () {
      return this.get('lastName') + ", " + this.get('firstName');
    }),

    UsernameForTitle: Ember.computed('userName', function () {
      if (this.get('userName').length > 8) {
        return this.get('userName');
      } else {
        return "";
      }
    }),
    studentDisplayName: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + " " + this.get('lastName').substring(0, 1) + ".";
    }),

    recommendedResources: _emberData.default.hasMany('resource-recommended'),
    //resourceAssigned: DS.hasMany('resource-assigned'),
    resourceStudentRatings: _emberData.default.hasMany('resource-student-rating'),

    parents: _emberData.default.hasMany('parent'),
    passwordEncrypt: _emberData.default.attr('string'),

    learningStyles: _emberData.default.hasMany('learning-style'),
    interestAreas: _emberData.default.hasMany('interest-area'),
    lastLoginFormatted: Ember.computed('lastLogin', function () {
      if (this.get('lastLogin')) {
        var date = this.get('lastLogin').toLocaleDateString();
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (today.toLocaleDateString() == date) return 'Today';else if (yesterday.toLocaleDateString() == date) {
          return 'Yesterday';
        } else {
          return date;
        }
      }
    })
  });
});
define('ohs/routes/teacher-quiz-room', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/signalr-teacher-route'], function (exports, _authenticatedRouteMixin, _reset, _noNavigation, _teacherAuthorizeMixin, _signalrTeacherRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, Ember.Evented, _noNavigation.default, _teacherAuthorizeMixin.default, _signalrTeacherRoute.default, {
        signalR: Ember.inject.service('signal-r'),

        titleToken: function titleToken(model) {
            return 'Quiz Room for ' + model.administration.get('quiz.quizName');
        },

        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            // Implement your custom setup after
            this.controllerFor('application').set('showSecondaryNav', false);
            this.controllerFor('application').set('teacher', true);

            //Set administration id to sigal-r service property
            this.get('signalR').administrationId = "t" + model.administration.get('id');
            //join administration
            this.get('signalR').joinAdministration("t" + model.administration.get('id'));
        },

        model: function model(params) {
            var store = this.store;

            return Ember.RSVP.hash({
                message: "",
                messageClass: "",
                iconClass: "",
                tickNumber: 0,
                testQuestions: store.query('test-question', { administrationId: params.administration_id }),
                studentTestAdminstrations: store.query('student-test-administration', { administrationId: params.administration_id }),
                administration: store.find('administration', params.administration_id),
                joinedStudents: Ember.computed('administration.studentTestAdministrations.@each.currentlyInQuiz', function () {
                    return this.administration.get('studentTestAdministrations').filterBy('currentlyInQuiz', true).sortBy('student.fullName');
                })
            });
        },


        afterModel: function afterModel(model) {

            if (model.administration.get('cancelled')) {
                model.showEndTimeAlert = true;
                model.alertMessage = "Unable to start this quiz because the end time is already over!";
            }

            if (model.administration.get('isComplete')) {
                this.transitionTo('teacher-results-1', model.administration.get('id'), '1');
            }

            if (model.administration.get('isOpen')) {
                if (model.administration.get('testTypeId') == 1) {
                    this.transitionTo('teacher-self-guided-results', model.administration.get('id'));
                    return;
                } else {
                    this.transitionTo('teacher-class-view', model.administration.get('id'));
                    return;
                }
            }
        },

        tick: function tick() {
            var nextTick = Ember.run.later(this, function () {
                try {
                    this.set('currentModel.tickNumber', this.get('currentModel.tickNumber') + 1);
                    this.tick();
                } catch (e) {
                    //do nothing
                }
            }, 1000);
            this.set('nextTick', nextTick);
        },


        actions: {
            //signalr actions
            changeQuestion: function changeQuestion(questionIndex, answersLocked, administrationId) {
                this._super.apply(this, arguments);
                if (administrationId == this.currentModel.administration.id && this.get('currentModel.administration.testTypeId') == 1) {
                    this.transitionTo('teacher-self-guided-results', this.currentModel.administration.id);
                }
            },
            signalRConnectionAlert: function signalRConnectionAlert(message, messageClass, iconClass) {
                this._super.apply(this, arguments);
                Ember.set(this.get('currentModel'), 'message', message);
                Ember.set(this.get('currentModel'), 'messageClass', messageClass);
                Ember.set(this.get('currentModel'), 'iconClass', iconClass);
            },

            //end signalr actions

            startQuiz: function startQuiz() {
                var admin = this.currentModel.administration;
                var self = this;
                if (admin.get('endTime') && admin.get('endTime') < new Date()) {
                    admin.set('cancelled', true);
                    self.set("currentModel.showEndTimeAlert", true);
                    self.set("currentModel.alertMessage", 'Unable to start this quiz because the end time is already over!');
                    return;
                }
                admin.set('isOpen', true);
                admin.set('startTime', new Date());
                admin.save().then(function () {
                    if (admin.get('testTypeId') == 1) {
                        self.transitionTo('teacher-self-guided-results', admin.id);
                    } else {
                        self.transitionTo('teacher-class-view', admin.id);
                    }
                }, function (error) {
                    admin.rollbackAttributes();
                    if (error && error.errors && error.errors.length > 0 && error.errors[0].detail) {
                        admin.set('cancelled', true);
                        self.set("currentModel.showEndTimeAlert", true);
                        self.set("currentModel.alertMessage", error.errors[0].detail);
                    } else {
                        self.set("currentModel.showAlert", true);
                        self.set("currentModel.alertMessage", 'Your quiz could not be started. Please check your internet connection and try again.');
                    }
                });
            },

            //Hide alert box
            hideAlert: function hideAlert() {
                this.set("currentModel.showAlert", false);
            },

            endTimeOver: function endTimeOver() {
                var self = this;
                //hide alert popup
                self.set("currentModel.showEndTimeAlert", false);

                // Delete administration
                var admin = self.currentModel.administration;
                admin.set('cancelled', false);
                admin.destroyRecord().then(function (a) {
                    //Leave Administration
                    self.get('signalR').leaveAdministration(admin.id);
                    //go to teacher dashboard
                    self.transitionTo('teacher-quizzes');
                }, function () {
                    admin.rollbackAttributes();
                    self.set("currentModel.showAlert", true);
                    self.set("currentModel.alertMessage", "Please check your internet connection and try again.");
                });
            },

            //if the user clicks the back button go back to quiz-details
            willTransition: function willTransition(transition) {
                if (transition.targetName == "start-quiz-1" || transition.targetName == "start-quiz-2" || transition.targetName == "continue-quiz-1" || transition.targetName == "continue-quiz-2") {
                    this.transitionTo('teacher.quiz-detail', this.get('currentModel.administration.quiz.id'));
                } else {
                    return true;
                }
            },


            editStartTime: function editStartTime() {
                this.set('currentModel.administration.editStart', true);
            },

            updateStartTime: function updateStartTime() {
                var startDateTime = null;
                var endDateTime = null;
                var self = this;

                if (this.get('currentModel.administration.startDateNew')) {
                    startDateTime = this.convertTo24HourFormat(new Date(this.get('currentModel.administration.startDateNew')), this.get('currentModel.administration.startTimeNew'), this.get('currentModel.administration.startAMPMNew'));
                } else {
                    self.set('currentModel.validationErrors', 'You must set a start time. If you want to start the quiz right now, click the button below.');
                    self.set('currentModel.buttonsDisabled', false);
                    return true;
                }

                //Set quiz end time format
                if (this.get('currentModel.administration.endDateNew')) {
                    endDateTime = this.convertTo24HourFormat(new Date(this.get('currentModel.administration.endDateNew')), this.get('currentModel.administration.endTimeNew'), this.get('currentModel.administration.endAMPMNew'));
                }

                var currentDateTime = new Date();
                if (startDateTime && startDateTime <= currentDateTime) {
                    self.set('currentModel.validationErrors', 'The start time needs to be in the future.');
                    self.set('currentModel.buttonsDisabled', false);
                    return true;
                }

                if (endDateTime) {
                    if (endDateTime <= currentDateTime) {
                        self.set('currentModel.validationErrors', 'The end time needs to be in the future.');
                        self.set('currentModel.buttonsDisabled', false);
                        return true;
                    }
                    if (startDateTime) {
                        if (endDateTime <= startDateTime) {
                            self.set('currentModel.validationErrors', 'The end time needs to be after the start time.');
                            self.set('currentModel.buttonsDisabled', false);
                            return true;
                        }
                    }
                }

                var admin = this.get('currentModel.administration');
                admin.set('startTime', startDateTime);
                admin.set('endTime', endDateTime);
                admin.save().then(function () {
                    self.set('currentModel.validationErrors', '');
                    self.set('currentModel.administration.editStart', false);
                });
            }
        },

        convertTo24HourFormat: function convertTo24HourFormat(date, time, formatAMPM) {
            var splittedTime = time.split(':');
            var hour = parseInt(splittedTime[0]);
            var minute = parseInt(splittedTime[1]);
            if (formatAMPM == "PM" && hour < 12) hour = hour + 12;
            if (formatAMPM == "AM" && hour == 12) hour = hour - 12;
            if (hour < 10) hour = "0" + hour;
            var dateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, minute, "00");
            return dateTime;
        }
    });
});
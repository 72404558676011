define("ohs/controllers/pattern-library/modules/filters", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    typeFilterOpts: [{
      id: 1,
      text: "All types"
    }, {
      id: 2,
      text: "Website"
    }, {
      id: 3,
      text: "App"
    }, {
      id: 4,
      text: "Video"
    }],
    levelFilterOpts: [{
      id: 1,
      text: "All levels"
    }, {
      id: 2,
      text: "Above grade level"
    }, {
      id: 3,
      text: "At grade level"
    }, {
      id: 4,
      text: "Below grade level"
    }],
    sortingOpts: [{
      id: 1,
      text: "Rating"
    }, {
      id: 2,
      text: "Standard"
    }, {
      id: 3,
      text: "Resource name"
    }]
  });
});
define('ohs/components/x-resource', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'article',
    //classNames: ['result', 'resource'],
    teacher: false,
    showClasses: true,
    user: null,
    store: Ember.inject.service(),

    resourceIssue: '',
    resourceIssueComments: '',
    showNavConfirmModal: false,
    textLimit: 1000,
    commentLength: 0,

    // validIssue: Ember.computed('resourceIssue','resourceIssueComments',function() {
    //   return (this.resourceIssue && this.resourceIssue != "Other" && this.resourceIssueComments.length<=this.textLimit)
    //     || (this.resourceIssue == "Other" && this.resourceIssueComments.trim() && this.resourceIssueComments.length<=this.textLimit);
    // }),
    validIssue: Ember.computed('resourceIssue', 'commentLength', 'textLimit', function () {
      if (this.resourceIssue) {
        if (this.resourceIssue != "Other") {
          if (this.resourceIssueComments && this.textLimit) {
            return this.resourceIssueComments.length <= this.textLimit;
          }
          return true;
        } else {
          if (this.resourceIssueComments && this.textLimit) {
            if (this.resourceIssueComments.length > 0 && this.resourceIssueComments.length <= this.textLimit) {
              return true;
            } else {
              return false;
            }
          }
          return false;
        }
      }
      return false;
    }),

    tellUsMore: Ember.computed('resourceIssue', function () {
      if (this.resourceIssue == "Other") {
        return "Tell us more.";
      } else {
        return "Tell us more (optional).";
      }
    }),

    classNameBindings: ['dynamicClasses'],

    dynamicClasses: Ember.computed('showClasses', function () {
      var classes = '';

      if (this.get('showClasses')) {
        classes = 'result resource';
      }

      return classes;
    }),

    bookmark: Ember.computed('userBookmarks.@each.id', function () {
      var resourceId = this.get('r').get('id');
      return this.get('userBookmarks').find(function (item, index, enumerable) {
        return item.get('resource').get('id') == resourceId;
      });
    }),

    studentRating: Ember.computed('r', function () {
      if (this.get('studentRatings')) {
        var rating = this.get('studentRatings').findBy('resource.id', this.get('r').get('id'));
        return rating;
      }
    }),

    hasSecondLookTrophy: Ember.computed('trophies.@each.id', function () {
      if (this.get('trophies')) {
        //return this.get('trophies').findBy('trophy.id',15) != null;
        return this.get('trophies').find(function (item, index, enumerable) {
          return item.get('trophy').get('id') == '15';
        });
      } else {
        return false;
      }
    }),

    actions: {
      createBookmark: function createBookmark() {
        this.sendAction('createAction', this.get('r').get('id'));
      },

      deleteBookmark: function deleteBookmark() {
        this.sendAction('deleteAction', this.get('r').get('id'));
      },

      resourceViewed: function resourceViewed(object) {
        this.disableGoButton(true);
        var self = this;
        // var user = this.get('store').findRecord('user',this.user.get('id'),{reload : true}).then((user)=>{
        var user = this.get('user');
        if (user.get('hideNavigationWarning')) {
          self.disableGoButton(false);
          window.open(this.get('r.webUrl'));
          //Award trophy if this was previously viewed
          if (object.get('viewed')) {
            //check to see if the trophy is already awarded
            if (!self.get('hasSecondLookTrophy')) {
              self.sendAction('createTrophyAction');
            }
          }
          object.set('viewed', true);
          object.set('viewedDate', new Date());
          object.save().then(function () {
            self.disableGoButton(false);
          });
        } else {
          self.set('showNavConfirmModal', true);
        }
        // }
        // ,()=>{
        //   self.disableGoButton(false);
        // });
      },

      resourceHelpful: function resourceHelpful(object) {
        var rating = this.get('store').createRecord('resource-student-rating', {
          resource: object.get('resource'),
          student: object.get('student'),
          ratingDate: new Date(),
          wasHelpful: true
        });
        rating.save();
      },

      setCharacterLimitAction: function setCharacterLimitAction(commentCharacterLength, characterLimit, comment) {
        this.set('textLimit', characterLimit);
        this.set('commentLength', commentCharacterLength);
        this.set('resourceIssueComments', comment);
      },
      reportIssue: function reportIssue() {

        this.set('issueMessage', null);
        var self = this;
        var adapter = this.get('store').adapterFor('application');

        var url = adapter.get('host') + '/accounts/reportResource?resourceId=' + this.get('r').get('id') + '&issue=' + this.get('resourceIssue') + '&comment=' + this.get('resourceIssueComments');
        if (this.get('resourceIssueComments').trim() == '') {
          url = adapter.get('host') + '/accounts/reportResource?resourceId=' + this.get('r').get('id') + '&issue=' + this.get('resourceIssue');
        }
        adapter.ajax(url, 'POST').then(function () {
          self.set('showConfirm', true);
          self.set('confirmationMessage', 'Your report has been received and is currently being reviewed. If we have any follow up questions, someone will be in touch!');
        }, function () {
          self.set('showAlert', true);
          self.set("alertMessage", "There was an error sending your report. Please check your internet connection and try again.");
        });
        this.set('resourceIssue', null);
        this.set('resourceIssueComments', '');
      },

      //Hide alert box
      hideConfirm: function hideConfirm() {
        this.set("showConfirm", false);
      },

      setResourceIssue: function setResourceIssue(value) {
        this.set('resourceIssue', value);
      },

      setResourceIssueComment: function setResourceIssueComment(value) {
        this.set('resourceIssueComments', value);
      },

      validateIssue: function validateIssue() {
        if (!this.get('resourceIssue')) {
          this.set('issueMessage', 'Please tell us what is wrong with this resource.');
          return false;
        } else if (this.get('resourceIssue') == "Other" && !this.get('resourceIssueComments').trim()) {
          this.set('issueMessage', 'Please add some additional information about what is wrong with this resource.');
          return false;
        } else if (this.get('resourceIssueComments.length') > this.get('textLimit')) {
          this.set('issueMessage', 'Comment exceeds the character limit.');
          return false;
        } else {
          return true;
        }
      },

      //Hide alert box
      hideAlert: function hideAlert() {
        this.set("showAlert", false);
        this.set("alertMessage", '');
      },

      cancel: function cancel() {
        this.set('issueMessage', '');
        this.set('resourceIssueComments', '');
        this.set('resourceIssue', null);
      },
      clear: function clear() {
        this.set('issueMessage', '');
        this.set('resourceIssueComments', '');
        this.set('resourceIssue', null);
      },

      showFlashWarning: function showFlashWarning() {
        this.set('showFlashWarning', true);
      },

      closeFlash: function closeFlash() {
        this.set('showFlashWarning', false);
      }
    },

    disableGoButton: function disableGoButton(isDisable) {
      $('.external').prop('disabled', isDisable);
    }
  });
});
define('ohs/components/x-utility-navigation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['aria-hidden', 'role', 'aria-labeledby'],
    classNames: ['utility-navigation-wrapper'],
    classNameBindings: ['isOpen'],
    role: 'dialog',
    'aria-labeledby': 'utility-nav-content',
    isOpen: false,
    isHidden: true,

    'aria-hidden': function () {
      return !this.get('isOpen') + '';
    }.property('isOpen'),

    actions: {
      openModal: function openModal() {
        this.set('isOpen', !this.get('isOpen'));
      },

      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    }

  });
});
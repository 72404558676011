define('ohs/mirage/fixtures/galaxies', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    id: 1,
    name: 'Geomeda Galaxy',
    title: 'Geometry',
    planets: [{
      id: 1,
      name: 'Planet Angolix',
      title: 'What\'s Your Angle?',
      description: 'All about geometry',
      image: 'planet-5.svg',
      points: 400,
      gradeLevel: {
        id: '3',
        description: '3rd Grade',
        code: '03',
        gradeLevelOrder: '3'
      },
      missions: [{
        id: 1,
        name: 'Mission to Angles Canyon',
        points: 100,
        order: 1,
        numQuestions: 10
      }, {
        id: 2,
        name: 'Obtuse, Acute, Right Mission',
        points: 100,
        order: 2,
        numQuestions: 10
      }, {
        id: 3,
        name: 'All the Degrees Mission',
        points: 100,
        order: 3,
        numQuestions: 10
      }, {
        id: 4,
        name: 'If it\'s Not Wrong it\'s Right, Mission',
        points: 100,
        order: 4,
        numQuestions: 10
      }, {
        id: 4,
        name: 'Mission to the Cardello Building',
        points: 300,
        order: 5,
        numQuestions: 3
      }]
    }, {
      id: 2,
      name: 'Planet Shapus',
      title: 'Ship Shapus',
      description: 'Nullam quis risus eget urna mollis ornare vel eu leo. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
      image: 'planet-6.svg',
      points: 800,
      gradeLevel: {
        id: '4',
        description: '4th Grade',
        code: '04',
        gradeLevelOrder: '4'
      },
      missions: [{
        id: 5,
        name: 'Mission to Angles Canyon',
        points: 250,
        order: 1,
        numQuestions: 10
      }]
    }]
  }, {
    id: 2,
    name: 'Numbers Galaxy',
    title: 'The Number System',
    planets: [{
      id: 3,
      name: 'Planet Integer',
      title: 'What\'s Your Number?',
      description: 'All about numbers',
      image: 'planet-8.svg',
      points: 900,
      gradeLevel: {
        id: '3',
        description: '3rd Grade',
        code: '03',
        gradeLevelOrder: '3'
      },
      missions: [{
        id: 6,
        name: 'Mission to Angles Canyon',
        points: 900,
        order: 1,
        numQuestions: 10
      }]
    }]
  }];
});
define('ohs/routes/teacher-self-guided-results', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/breadcrumb-mixin', 'ohs/mixins/passage-modal-mixin', 'ohs/mixins/signalr-teacher-route'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _breadcrumbMixin, _passageModalMixin, _signalrTeacherRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _breadcrumbMixin.default, _passageModalMixin.default, _signalrTeacherRoute.default, {
        signalR: Ember.inject.service('signal-r'),

        titleToken: function titleToken(model) {
            return model.administration.get('quiz.quizName');
        },

        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super.apply(this, arguments);
            // Implement your custom setup after
            this.controllerFor('application').set('showSecondaryNav', true);
            this.controllerFor('application').set('teacher', true);

            this.get('signalR').administrationId = "t" + model.administration.get('id');
            this.get('signalR').joinAdministration("t" + model.administration.get('id'));
        },

        model: function model(params) {
            var _this = this;

            var commentLength = 0;
            var textLimit = 0;
            var store = get(this, 'store');

            return Ember.RSVP.hash({
                message: "",
                messageClass: "",
                iconClass: "",
                hasNoStudentAnswers: "",
                hasIncompleteAdministration: "",
                hasOneIncompleteAdministration: "",
                minDate: new Date(),
                questionIssue: '',
                questionIssueComments: '',

                administration: this.store.find('administration', params.administration_id),
                studentTestAdministrations: store.query('student-test-administration', { administrationId: params.administration_id }),
                testQuestions: store.query('test-question', { administrationId: params.administration_id }),

                testQuestionSort: ['questionOrder'],

                orderedQuestions: Ember.computed.sort('testQuestions', 'testQuestionSort'),
                passage: Ember.computed.alias('administration.quiz.passages.firstObject'),

                orderedAdministrations: Ember.computed('administration.studentTestAdministrations.@each.id', function () {
                    return this.administration.get('studentTestAdministrations').sortBy('student.fullNameReversed');
                }),

                completedAdministrations: Ember.computed('administration.studentTestAdministrations.[]', function () {
                    return this.administration.get('studentTestAdministrations').filter(function (sta, index, array) {
                        return sta.get('isFinished');
                    });
                }),

                incompleteAdministrations: Ember.computed('administration.studentTestAdministrations.[]', function () {
                    return this.administration.get('studentTestAdministrations').filter(function (sta, index, array) {
                        return sta.get('isFinished') == false;
                    });
                }),

                classAverage: Ember.computed('completedAdministrations.@each.percentCorrect', function () {
                    if (_this.get('currentModel.completedAdministrations').length > 0) {
                        return Math.round(_this.get('currentModel.completedAdministrations').reduce(function (a, b) {
                            return a + b.get('percentCorrect');
                        }, 0) / _this.get('currentModel.completedAdministrations').get('length'));
                    } else {
                        return 0;
                    }
                }),

                mostMissed: Ember.computed('completedAdministrations.[]', function () {
                    var mostMissedQuestionIds = [];
                    var mostMissedQuestionCount = -1;

                    if (_this.get('currentModel.completedAdministrations') && _this.get('currentModel.completedAdministrations').length > 0) {
                        _this.get('currentModel.completedAdministrations').reduce(function (a, b) {
                            var missed = a;
                            b.get('studentAnswers').forEach(function (c) {
                                if (!c.get('isCorrect')) {
                                    if (missed.has(c.get('questionId'))) {
                                        missed.set(c.get('questionId'), missed.get(c.get('questionId')) + 1);
                                    } else {
                                        missed.set(c.get('questionId'), 1);
                                    }
                                }
                            });
                            return missed;
                        }, new Map()).forEach(function (value, key) {
                            if (value > mostMissedQuestionCount) {
                                mostMissedQuestionIds = [key];
                                mostMissedQuestionCount = value;
                            } else if (value == mostMissedQuestionCount) {
                                mostMissedQuestionIds.push(key);
                            }
                        });
                    } else {
                        return 'N/A';
                    }

                    //this indicates no one missed a question
                    if (mostMissedQuestionIds.length == 0) {
                        return 'Perfect!';
                    }

                    //create the string
                    if (mostMissedQuestionIds.length > 1) {
                        var questionNumbers = mostMissedQuestionIds.map(function (x) {
                            var ques = _this.get('currentModel.orderedQuestions').find(function (question) {
                                return question.get('question.id') == parseInt(x);
                            });
                            if (ques) {
                                ques = ques.get('questionOrder');
                            }
                            return ques;
                        }).join(', ');

                        return 'Questions: ' + questionNumbers;
                    } else {
                        var questionNumber = _this.get('currentModel.orderedQuestions').find(function (question) {
                            return question.get('question.id') == mostMissedQuestionIds[0];
                        }).get('questionOrder');
                        return 'Question: ' + questionNumber;
                    }
                }),

                mostMissedLabel: Ember.computed('mostMissed', function () {
                    if (_this.get('currentModel.mostMissed').includes('Questions')) {
                        return 'Most Missed Questions';
                    } else {
                        return 'Most Missed Question';
                    }
                }),

                validIssue: Ember.computed('questionIssue', 'commentLength', 'textLimit', function () {
                    if (this.questionIssue) {
                        if (this.questionIssue != "Other") {
                            if (this.questionIssueComments && this.textLimit) {
                                return this.questionIssueComments.length <= this.textLimit;
                            }
                            return true;
                        } else {
                            if (this.questionIssueComments && this.textLimit) {
                                if (this.questionIssueComments.length > 0 && this.questionIssueComments.length <= this.textLimit) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                            return false;
                        }
                    }
                    return false;
                }),

                tellUsMore: Ember.computed('questionIssue', function () {
                    if (this.questionIssue == "Other") {
                        return "Tell us more.";
                    } else {
                        return "Tell us more (optional).";
                    }
                })

            });
        },


        afterModel: function afterModel(model, transition) {
            this._super(model, model.administration.get('quiz.quizName') + ' (In Progress)', model.administration.get('id'));
            var administration = get(model, 'administration');

            if (get(administration, 'isComplete')) {
                this.transitionTo('teacher-results-1', get(administration, 'id'), '1');
            }
        },

        actions: {
            //signalr actions
            signalRConnectionAlert: function signalRConnectionAlert(message, messageClass, iconClass) {
                Ember.set(this.get('currentModel'), 'message', message);
                Ember.set(this.get('currentModel'), 'messageClass', messageClass);
                Ember.set(this.get('currentModel'), 'iconClass', iconClass);
            },
            endAdministration: function endAdministration(administrationId) {
                //Leave administration
                this.get('signalR').leaveAdministration("t" + administrationId);
                if (this.get('currentModel.administration.parentAdministrationId')) {
                    this.transitionTo('teacher-results-1', this.get('currentModel.administration.parentAdministrationId'), '1');
                } else {
                    this.transitionTo('teacher-results-1', administrationId, '1');
                }
            },
            cancelQuiz: function cancelQuiz(administrationId) {
                //Leave Administration
                this.get('signalR').leaveAdministration("t" + administrationId);
                this.set("currentModel.showCancelAlert", true);
                this.set("currentModel.alertMessage", "This quiz has been cancelled!");
            },

            //end signalr actions

            setCharacterLimitAction: function setCharacterLimitAction(commentCharacterLength, characterLimit, comment) {
                this.set('currentModel.textLimit', characterLimit);
                this.set('currentModel.commentLength', commentCharacterLength);
                this.set('currentModel.questionIssueComments', comment);
            },

            endQuiz: function endQuiz() {
                var admin = this.currentModel.administration;
                var self = this;
                var sesObj = this.get('session');
                var userId = sesObj.session.content.authenticated["userId"];
                var noOfStudents = this.currentModel.administration.get('studentTestAdministrations').sortBy('student.fullNameReversed').length;
                Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/administrations/hasAnyStudentAnswers?administrationId=' + admin.id + '&userId=' + encodeURIComponent(userId)).then(function (hasAnyStudentAnswers) {
                    if (hasAnyStudentAnswers) {
                        //check for any unfinished student tests
                        Ember.$.getJSON(self.store.adapterFor('application').get('host') + '/administrations/hasAnyIncompleteStudentAdministration?administrationId=' + admin.id + '&userId=' + encodeURIComponent(userId)).then(function (hasAnyIncompleteAdministration) {
                            if (!hasAnyIncompleteAdministration) {
                                self.closeAdministration();
                            } else if (noOfStudents == 1) {
                                self.set('currentModel.hasOneIncompleteAdministration', true);
                            } else {
                                //No students joined the quiz
                                self.set('currentModel.hasIncompleteAdministration', true);
                            }
                        }, function (error) {
                            if (error) {
                                self.set("currentModel.showCancelAlert", true);
                                self.set("currentModel.alertMessage", error.responseText);
                            } else {
                                self.set("currentModel.showAlert", true);
                                self.set("currentModel.alertMessage", "Failed to end the quiz. Please check your internet connection and try again.");
                            }
                        });
                    } else {
                        //No students joined the quiz
                        self.set('currentModel.hasNoStudentAnswers', true);
                    }
                }, function (error) {
                    if (error) {
                        self.set("currentModel.showCancelAlert", true);
                        self.set("currentModel.alertMessage", error.responseText);
                    } else {
                        self.set("currentModel.showAlert", true);
                        self.set("currentModel.alertMessage", "Failed to end the quiz. Please check your internet connection and try again.");
                    }
                });
            },

            deleteQuiz: function deleteQuiz(administrationId) {
                //hidden modal popup
                this.set('currentModel.hasNoStudentAnswers', false);
                //Delete administration
                var admin = this.currentModel.administration;
                var self = this;
                admin.destroyRecord().then(function (a) {
                    //Leave Administration
                    self.get('signalR').leaveAdministration(administrationId);
                    //go to teacher dashboard
                    //self.transitionTo('teacher-quizzes');
                }, function (error) {
                    admin.rollbackAttributes();
                    self.set("currentModel.showAlert", true);
                    if (error) {
                        self.set("currentModel.alertMessage", error.responseText);
                    } else {
                        self.set("currentModel.alertMessage", "Failed to end the quiz. Please check your internet connection and try again.");
                    }
                });
            },

            //Hide alert box
            hideAlert: function hideAlert() {
                this.set("currentModel.showAlert", false);
                this.set("currentModel.alertMessage", "");
            },

            hideConfirmAndRedirect: function hideConfirmAndRedirect() {
                this.transitionTo('teacher-quizzes');
            },


            showIssue: function showIssue(questionId) {
                this.set('currentModel.showIssue', true);
                this.set('currentModel.currentQuestionId', questionId);
            },

            reportIssue: function reportIssue() {

                this.set('currentModel.issueMessage', null);
                var self = this;
                var adapter = this.get('store').adapterFor('application');

                var url = adapter.get('host') + '/accounts/reportQuestion?questionId=' + this.get('currentModel.currentQuestionId') + '&issue=' + this.get('currentModel.questionIssue') + '&comment=' + this.get('currentModel.questionIssueComments');
                if (this.get('currentModel.questionIssueComments').trim() == '') {
                    url = adapter.get('host') + '/accounts/reportQuestion?questionId=' + this.get('currentModel.currentQuestionId') + '&issue=' + this.get('currentModel.questionIssue');
                }
                adapter.ajax(url, 'POST').then(function () {
                    self.set('currentModel.showConfirm', true);
                    self.set('currentModel.confirmationMessage', 'Your report has been received and is currently being reviewed. If we have any follow up questions, someone will be in touch!');
                }, function () {
                    self.set('currentModel.showAlert', true);
                    self.set("currentModel.alertMessage", "There was an error sending your report. Please check your internet connection and try again.");
                });

                this.set('currentModel.questionIssueComments', '');
                this.set('currentModel.questionIssue', null);
            },

            //Hide alert box
            hideConfirm: function hideConfirm() {
                this.set("currentModel.showConfirm", false);
            },
            //Hide alert box
            hideDeleteConfirm: function hideDeleteConfirm() {
                this.set("currentModel.showDeleteConfirm", false);
                this.refresh();
            },

            setQuestionIssue: function setQuestionIssue(value) {
                this.set('currentModel.questionIssue', value);
            },

            setQuestionIssueComment: function setQuestionIssueComment(value) {
                this.set('currentModel.questionIssueComments', value);
            },

            validateIssue: function validateIssue() {
                if (!this.get('currentModel.questionIssue')) {
                    this.set('currentModel.issueMessage', 'Please tell us what is wrong with this resource.');
                    return false;
                } else if (this.get('currentModel.questionIssue') == "Other" && !this.get('currentModel.questionIssueComments').trim()) {
                    this.set('currentModel.issueMessage', 'Please add some additional information about what is wrong with this resource.');
                    return false;
                } else if (this.get('currentModel.questionIssueComments.length') > this.get('currentModel.textLimit')) {
                    this.set('currentModel.issueMessage', 'Comment exceeds the character limit.');
                    return false;
                } else {
                    return true;
                }
            },

            cancel: function cancel() {
                this.set('currentModel.issueMessage', '');
                this.set('currentModel.questionIssueComments', '');
                this.set('currentModel.questionIssue', null);
            },
            clear: function clear() {
                this.set('currentModel.issueMessage', '');
                this.set('currentModel.questionIssueComments', '');
                this.set('currentModel.questionIssue', null);
            },
            closeAdministration: function closeAdministration() {
                this.closeAdministration();
            },


            //if the user clicks the back button go back to quiz-details
            willTransition: function willTransition(transition) {
                if (transition.targetName == "start-quiz-1" || transition.targetName == "start-quiz-2" || transition.targetName == "continue-quiz-1" || transition.targetName == "continue-quiz-2") {
                    this.transitionTo('teacher.quiz-detail', this.get('currentModel.administration.quiz.id'));
                } else {
                    return true;
                }
            },


            editEndTime: function editEndTime() {
                this.set('currentModel.administration.editEnd', true);
            },

            updateEndTime: function updateEndTime() {
                var endDateTime = null;
                var self = this;

                //Set quiz end time format
                if (this.get('currentModel.administration.endDateNew')) {
                    endDateTime = this.convertTo24HourFormat(new Date(this.get('currentModel.administration.endDateNew')), this.get('currentModel.administration.endTimeNew'), this.get('currentModel.administration.endAMPMNew'));
                }

                var currentDateTime = new Date();

                if (endDateTime) {
                    if (endDateTime <= currentDateTime) {
                        self.set('currentModel.validationErrors', 'The end time needs to be in the future.');
                        self.set('currentModel.buttonsDisabled', false);
                        return true;
                    }
                }

                var admin = this.get('currentModel.administration');
                admin.set('endTime', endDateTime);
                admin.save().then(function () {
                    self.set('currentModel.validationErrors', '');
                    self.set('currentModel.administration.editEnd', false);
                });
            },

            //Hide alert box and transition To student-results route
            gotoQuizzes: function gotoQuizzes() {
                this.transitionTo('teacher-quizzes');
            },

            ConfirmDeleteStudentAction: function ConfirmDeleteStudentAction(studentTestAdministration) {
                this.set('currentModel.selectedStudentTestAdministration', studentTestAdministration);
                this.set('currentModel.showModal', true);
                this.set('currentModel.modalMessage', 'Are you sure you want to delete this student test result?');
            },
            removeStudentTest: function removeStudentTest() {
                this.set('currentModel.showModal', false);
                this.set('currentModel.modalMessage', null);
                var self = this;
                var studentTestAdministration = this.get('currentModel.selectedStudentTestAdministration');
                studentTestAdministration.destroyRecord().then(function (msg) {
                    self.set("currentModel.showDeleteConfirm", true);
                    self.set("currentModel.confirmationMessage", 'Success!');
                }, function (error) {
                    //  If status is 404,administration is also deleted
                    if (error && error.errors && error.errors[0].status == '404') {
                        self.set("currentModel.showAdministrationDeleteConfirm", true);
                        self.set("currentModel.confirmationMessage", 'Success! Since this was the only result in the session, the session has also been deleted.');
                    } else {
                        self.set("currentModel.showAlert", true);
                        self.set('currentModel.alertMessage', "Student test result could not be deleted. Please check your Internet connection and try again.");
                    }
                });
            }
        },

        convertTo24HourFormat: function convertTo24HourFormat(date, time, formatAMPM) {
            var splittedTime = time.split(':');
            var hour = parseInt(splittedTime[0]);
            var minute = parseInt(splittedTime[1]);
            if (formatAMPM == "PM" && hour < 12) hour = hour + 12;
            if (formatAMPM == "AM" && hour == 12) hour = hour - 12;
            if (hour < 10) hour = "0" + hour;
            var dateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, minute, "00");
            return dateTime;
        },
        closeAdministration: function closeAdministration() {
            //close the modal
            this.set('currentModel.hasOneIncompleteAdministration', false);
            this.set('currentModel.hasIncompleteAdministration', false);

            var admin = this.currentModel.administration;
            var self = this;
            //end the quiz
            admin.set('isOpen', false);
            admin.set('isComplete', true);
            admin.set('endTime', new Date());

            admin.save().then(function (a) {
                //Leave administration
                self.get('signalR').leaveAdministration(admin.id);

                if (admin.get('parentAdministrationId')) {
                    self.transitionTo('teacher-results-1', admin.get('parentAdministrationId'), '1');
                } else {
                    self.transitionTo('teacher-results-1', a.id, '1');
                }
            }, function (error) {
                admin.rollbackAttributes();
                if (error && error.errors && error.errors.length > 0 && error.errors[0].detail) {
                    self.set("currentModel.showAlert", true);
                    self.set("currentModel.alertMessage", error.errors[0].detail);
                } else {
                    self.set("currentModel.showAlert", true);
                    self.set("currentModel.alertMessage", "Could not end the quiz. Please check your internet connection and try again.");
                }
            });
        }
    });
});
define('ohs/routes/account-admin/dashboard', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Account Admin',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          } else if (roles.indexOf("Parent") > 0) {
            this.controllerFor('application').set('parent_many_children', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
          this.controllerFor('application').set('parent_many_children', '');
        }
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },
    model: function model() {
      var account = this.store.peekAll('account');
      var accountId = account.objectAt(0).id;
      return Ember.RSVP.hash({
        accountEmailPatterns: this.store.query('accountEmailPattern', { accountId: accountId }),
        loadFinished: true,
        listAllEmailPattern: true,
        newEmailPattern: false,
        account: account
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Account Admin', false, true);
    },
    actions: {
      confirmRemoveEmailPattern: function confirmRemoveEmailPattern(accountEmailPattern) {
        this.set('currentModel.selectedEmailPattern', accountEmailPattern);
        this.set('currentModel.showModal', true);
        this.set('currentModel.modalMessage', 'Are you sure you want to delete this email pattern?');
      },
      removeEmailPattern: function removeEmailPattern() {
        this.set('currentModel.showModal', false);
        this.set('currentModel.modalMessage', null);
        var self = this;
        var emailPattern = this.get('currentModel.selectedEmailPattern');
        emailPattern.destroyRecord().then(function () {
          self.set("currentModel.showConfirm", true);
          self.set("currentModel.confirmMessage", 'Email pattern has been deleted from your account!');
        }, function () {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Email pattern could not be deleted. Please check your Internet connection and try again.");
        });
      },

      hideConfirm: function hideConfirm() {
        var self = this;
        self.refresh();
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
        this.set('currentModel.alertMessage', '');
      },

      saveEmailPattern: function saveEmailPattern() {
        var self = this;
        if (self.validate()) {
          var account = self.store.peekAll('account');
          var accountId = account.objectAt(0).id;
          var email = self.store.peekAll('accountEmailPattern');
          var emailPattern = self.get('currentModel.accountEmailPatterns.emailPattern');
          var emailpatternexist = email.filterBy('emailPattern', emailPattern).length;
          if (emailpatternexist <= 0) {
            if (emailPattern.match(/^((([^<>()\[\]\\.,;:\s@"]+)?(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
              var emailPattern = self.store.createRecord('accountEmailPattern', {
                emailPattern: emailPattern,
                accountId: accountId
              });
              emailPattern.save().then(function () {
                self.set("currentModel.showConfirm", true);
                self.set("currentModel.confirmMessage", 'Email pattern has been successfully inserted !');
                self.toggle();
              }, function () {
                self.set("currentModel.showAlert", true);
                self.set('currentModel.alertMessage', "Email pattern could not be inserted. Please check your Internet connection and try again.");
              });
            } else {
              self.set('currentModel.validationErrors', 'Email pattern is not valid');
              return false;
            }
          } else {
            self.set('currentModel.validationErrors', 'This email pattern is already in use');
            return false;
          }
        }
      },

      toggleAction: function toggleAction() {
        var self = this;
        self.toggle();
      }
    },

    toggle: function toggle() {
      var self = this;
      self.toggleProperty('currentModel.listAllEmailPattern');
      self.toggleProperty('currentModel.newEmailPattern');
      self.set('currentModel.accountEmailPatterns.emailPattern', null);
      self.set('currentModel.validationErrors', null);
    },
    validate: function validate() {
      var self = this;
      var emailPattern = self.currentModel.accountEmailPatterns.emailPattern;
      if (emailPattern && emailPattern.trim()) {
        return true;
      } else {
        self.set('currentModel.validationErrors', 'You must enter an email pattern');
        return false;
      }
    }
  });
});
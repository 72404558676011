define('ohs/routes/teacher/quiz-search-start', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Global Quizzes',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      this.store.unloadAll('standard');
    },

    model: function model() {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      var self = this;

      return this.store.findRecord('teacher', userId).then(function (teacher) {
        var gradeLevel = 3;
        if (teacher.get('gradeLevelId')) {
          gradeLevel = teacher.get('gradeLevelId');
        }
        return Ember.RSVP.hash({
          grades: self.store.query('grade', { 'gradeType': 'SearchResource' }),
          // subjects: self.store.findAll('subject'),
          account: self.store.queryRecord('account', { userId: userId }),
          standards: self.store.findAll('standard'),

          selectedGrade: gradeLevel,
          selectedSubject: 1,
          keywordSearch: '',
          selectedHasResourceAttached: 1,

          standardType: sesObj.get('standardType'),

          selectedReportingCategory: "",
          selectedAssessmentAnchor: "",
          selectedEligibleContent: [],
          eligibleContentQueryString: "",

          fillteredSubjects: Ember.computed('account.subjects', function () {
            var sub = this.account.get('subjects');
            return sub;
          }),

          filGrades: Ember.computed('selectedSubject', function () {
            var curSubject = self.store.peekRecord('subject', this.selectedSubject);
            var grades = curSubject.get('gradeLevels');
            grades.forEach(function (item) {
              item.set('text', item.get('description'));
            });
            if (!curSubject.get('gradeLevels').findBy('id', this.selectedGrade.toString())) {
              Ember.set(this, 'selectedGrade', 3);
            }
            return grades.sortBy('gradeLevelOrder');
          }),

          reportingCategories: Ember.computed('selectedGrade', 'selectedSubject', 'standards.@each.id', function () {
            var stan = this.standards;
            var gradeLevelId = this.selectedGrade;
            var subjectId = this.selectedSubject;
            stan = stan.filterBy('standardLevelId', 1).filterBy('gradeLevelId', gradeLevelId).filterBy('subjectId', subjectId).sortBy('standardOrderId');
            return stan;
          }),
          assessmentAnchors: Ember.computed('selectedReportingCategory', 'standards.@each.id', function () {
            var stan = this.standards;
            var rc = this.selectedReportingCategory;
            stan = stan.filterBy('parentStandardId', rc).sortBy('standardOrderId');
            return stan;
          }),
          eligibleContent: Ember.computed('selectedAssessmentAnchor', 'standards.@each.id', function () {
            var stan = this.standards;
            var anchor = this.selectedAssessmentAnchor;
            stan = stan.filterBy('parentStandardId', anchor).sortBy('standardOrderId');
            return stan;
          }),

          hasResourceAttached: [{
            text: "All Quizzes",
            id: 1
          }, {
            text: "Quizzes with Resources",
            id: 2
          }, {
            text: "Quizzes Without Resources",
            id: 3
          }]

        });
      });
    },
    afterModel: function afterModel(model) {
      this._super(model, 'Global Quizzes', false, true);
    },
    actions: {
      setGrade: function setGrade(value) {
        this.set('currentModel.selectedGrade', parseInt(value));
      },
      setSubject: function setSubject(value) {
        this.set('currentModel.selectedSubject', parseInt(value));
      },
      setHasResourceAttached: function setHasResourceAttached(value) {
        this.set('currentModel.selectedHasResourceAttached', parseInt(value));
      },
      search: function search() {
        document.activeElement.blur();

        if (this.get('currentModel.minQuestionCountSearch') && !this.get('currentModel.minQuestionCountSearch').match(/^\d+$/)) {
          this.set('currentModel.invalidAttributes', 'Invalid minimum no: of questions');
          return false;
        }
        if (this.get('currentModel.maxQuestionCountSearch') && !this.get('currentModel.maxQuestionCountSearch').match(/^\d+$/)) {
          this.set('currentModel.invalidAttributes', 'Invalid maximum no: of questions');
          return false;
        }
        this.transitionTo('teacher.quiz-search-results');
      }
    }
  });
});
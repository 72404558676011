define('ohs/models/student-group-pending', ['exports', 'ember-data', 'ohs/models/student-group'], function (exports, _emberData, _studentGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _studentGroup.default.extend({

    //this is to tell how the group was created.
    groupType: Ember.computed('edInsightCourseId', 'schoologyCourseId', 'googleCourseId', function () {
      if (this.get('edInsightCourseId')) {
        return 'EdInsight';
      } else if (this.get('schoologyCourseId')) {
        return 'Schoology';
      } else if (this.get('googleCourseId')) {
        return 'Google';
      } else {
        return 'Kandoolu';
      }
    })
  });
});
define('ohs/components/roster-course', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        group: null,
        studentGroupObjects: Ember.computed('group.students.[]', function () {
            var _this = this;

            if (this.get('group.students.length') > 0) {
                return this.get('group.students').map(function (student) {
                    return {
                        student: student,
                        groupId: _this.get('group.id')
                    };
                });
            }
        }),

        currentApprovals: Ember.computed('approvals.[]', 'approvals@each.groupId', 'approvals@each.student', function () {
            var _this2 = this;

            if (this.get('approvals')) {
                return this.get('approvals').filter(function (approval) {
                    return approval.groupId == _this2.get('group.id');
                });
            } else {
                return [];
            }
        }),

        isApproved: Ember.computed('currentApprovals.[]', function () {
            if (this.get('currentApprovals') && this.get('currentApprovals.length') != this.get('group.students.length')) {
                return false;
            } else {
                return true;
            }
        }),

        actions: {
            toggleGroupApproval: function toggleGroupApproval() {
                if (this.get('isApproved')) {
                    this.sendAction('approveGroup', this.get('group.id'));
                } else {
                    this.sendAction('disapproveGroup', this.get('group.id'));
                }
            },

            toggleStudentApproval: function toggleStudentApproval(groupId, student) {
                this.sendAction('toggleStudentApproval', groupId, student);
            }
        }
    });
});
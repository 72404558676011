define('ohs/components/highlightable-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['student-passage-quiz'],
    currentSelection: null,
    answerSelected: Ember.computed('currentQuestionIndex', function () {
      var index = this.get('currentQuestionIndex');
      var answer = this.get('questions').objectAt(index).get('answer');
      return answer != null && answer != undefined;
    }),
    notes: [],
    noteCount: 0,
    noteTextForNote: function noteTextForNote(noteId) {
      var text = '';
      var note = null;

      if (noteId !== null && noteId > 0) {
        note = this.get('notes').objectAt(noteId - 1);

        if (note) {
          text = note['noteText'];
        }
      }

      return text;
    },
    noteText: '',
    passage: "",
    showNoteModal: false,
    showNoteEditModal: false,

    didRender: function didRender() {
      Ember.run.later(function () {
        var originalRelativeOffset = $('.primary-content').offset();
        var self = this;

        function positionHighlightingControls() {
          var selection = window.getSelection();
          var startNode = selection.anchorNode;

          var verticalOffset = { top: 0, left: 0 };
          var height = $('.highlight-control-group button')[0].offsetHeight;
          var width = $('.highlight-control-group')[0].offsetWidth;
          var boundingRect = null;

          if (startNode.getBoundingClientRect) {
            boundingRect = startNode.getBoundingClientRect();
          } else {
            var range = document.createRange();
            range.selectNode(startNode);
            boundingRect = range.getBoundingClientRect();
          }

          verticalOffset['left'] = window.scrollX + boundingRect.left - originalRelativeOffset['left'];
          verticalOffset['top'] = window.scrollY + boundingRect.top - originalRelativeOffset['top'] - height - '8';

          // if there isn't enough room to display the controls without breaking between buttons,
          if (document.documentElement.clientWidth < verticalOffset['left'] + width) {

            // move the controls left one whole width,
            // or to the edge of the screen, whichever is more rightmost
            if (verticalOffset['left'] - width < 0) {
              verticalOffset['left'] = 8;
            } else {
              verticalOffset['left'] -= width;
            }
          }

          var inlinePositionStyle = 'top: ' + verticalOffset['top'] + 'px; left: ' + verticalOffset['left'] + 'px;';
          $('.highlight-control-group')[0].setAttribute('style', inlinePositionStyle);
        }

        function selectedTextHandler() {
          $(document).bind('textselect', function (event, string, element) {
            var parentOf = $.contains($('.passage-text')[0], element);
            var equalTo = $('.passage-text').is(element);
            var controls = $('.passage-text .highlight-control-group');

            if ((parentOf || equalTo) && string.length > 0 && string != "\n") {
              positionHighlightingControls();
              controls.addClass('is-visible');
            } else {
              controls.removeClass('is-visible');
            }
          });
        }

        selectedTextHandler();
      }, 400);
    },

    selectedText: function selectedText() {
      var selection = window.getSelection();
      return selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    },

    buildReplacementNodes: function buildReplacementNodes(text, startOffset, endOffset, noteId) {
      var nodes = [];
      var before = null;

      if (startOffset > 0) {
        before = document.createTextNode(text.substring(0, startOffset));
      }

      var toReplace = text.substring(startOffset, endOffset);

      if (endOffset < text.length) {
        nodes.pushObject(document.createTextNode(text.substring(endOffset, text.length)));
      }

      var replacement = document.createElement('span');

      replacement.setAttribute('class', "highlighted-text");
      replacement.textContent = toReplace;
      replacement.setAttribute('data-note-id', noteId);

      nodes.pushObject(replacement);

      if (before && before.textContent) {
        nodes.pushObject(before);
      }

      return nodes;
    },
    nodeContainsSelection: function nodeContainsSelection(node, selection) {
      var toCompare = document.createRange();
      toCompare.selectNodeContents(node);

      var selectionStartedAfterNode = selection.compareBoundaryPoints(Range.START_TO_START, toCompare) >= 0;

      // the node is (partially) "contained" in the selection if
      // its start is before the selection start
      // and its end is after the selection start.
      if (selectionStartedAfterNode) {
        return selection.compareBoundaryPoints(Range.END_TO_START, toCompare) < 1;
      }
      // or if its start is after the selection start,
      // but before the end of the selection
      else {
          return selection.compareBoundaryPoints(Range.START_TO_END, toCompare) >= 0;
        }
    },
    buildActionMarkup: function buildActionMarkup(noteId) {
      var requiresEdit = this.get('notes').objectAt(noteId - 1)['noteText'];

      var actionWrapper = document.createElement('div');
      actionWrapper.setAttribute('class', 'note-action-wrapper');
      actionWrapper.setAttribute('data-note-id', noteId);

      if (requiresEdit) {
        var editLink = document.createElement('a');
        editLink.setAttribute('class', "note-info-link");
        editLink.setAttribute('data-note-id', noteId);

        var editText = document.createElement('span');
        editText.setAttribute('class', 'visually-hidden');
        editText.textContent = 'Edit Note';

        var noteIcon = document.createElement('span');
        noteIcon.setAttribute('class', 'icon-note');

        editLink.appendChild(editText);
        editLink.appendChild(noteIcon);
        actionWrapper.appendChild(editLink);
      } else {
        var deleteIcon = document.createElement('span');
        deleteIcon.setAttribute('class', 'icon-highlight-close');

        var deleteLink = document.createElement('a');
        deleteLink.setAttribute('class', "delete-note-link");
        deleteLink.setAttribute('data-note-id', noteId);

        var deleteText = document.createElement('span');
        deleteText.setAttribute('class', 'visually-hidden');
        deleteText.textContent = 'Delete highlighting';

        deleteLink.appendChild(deleteText);
        deleteLink.appendChild(deleteIcon);
        actionWrapper.appendChild(deleteLink);
      }

      return actionWrapper;
    },
    highlightRecursively: function highlightRecursively(current, selection, noteId) {
      var self = this;
      var finished = false;
      var highlighting = self.nodeContainsSelection(current, selection);

      var currentStartOffset = 0;
      var currentEndOffset = current.textContent.length;

      //check for offset boundaries, ensure not to highlight more than we need
      if (current.contains(selection.startContainer) || current.isSameNode(selection.startContainer)) {
        currentStartOffset = selection.startOffset;
      }

      if (current.contains(selection.endContainer) || current.isSameNode(selection.endContainer)) {
        currentEndOffset = selection.endOffset;
      }

      // base case
      if (highlighting && current.nodeName == '#text') {
        var replacementNodes = this.buildReplacementNodes(current.textContent, currentStartOffset, currentEndOffset, noteId);
        var parent = current.parentNode;

        // this result will change once we start modifying parent node
        finished = current.isSameNode(selection.endContainer);

        parent.replaceChild(replacementNodes[0], current);

        if (replacementNodes.length > 1) {
          var insertedHighlight = parent.insertBefore(replacementNodes[1], replacementNodes[0]);

          if (replacementNodes.length > 2) {
            parent.insertBefore(replacementNodes[2], insertedHighlight);
          }
        }

        // insert the note information link after the note
        if (finished) {
          var noteActionMarkup = self.buildActionMarkup(noteId);
          var afterHighlightedText = null;
          var lastHighlightedNode = null;

          // have to figure out where the highlighting ends
          for (var i = 0; i < replacementNodes.length; i++) {
            if (replacementNodes[i].classList && replacementNodes[i].classList.contains('highlighted-text')) {
              lastHighlightedNode = replacementNodes[i];
            }
          }

          afterHighlightedText = lastHighlightedNode.nextSibling;

          parent.insertBefore(noteActionMarkup, afterHighlightedText);
        }
      } else {
        current.childNodes.forEach(function (child, i) {
          if (self.nodeContainsSelection(child, selection) && (child.classList == undefined || !child.classList.contains('highlighted-text'))) {
            self.highlightRecursively(child, selection, noteId);
          }
        });
      }
    },
    bindNoteActions: function bindNoteActions() {
      var editHandler = this.handleNoteEditClicks.bind(this);
      var deleteHandler = this.handleNoteDeleteClicks.bind(this);
      var controlHandler = this.handleHighlightClicks.bind(this);

      Ember.run.later(function () {
        $('.note-info-link').off('click', editHandler);
        $('.note-info-link').on('click', editHandler);

        $('.delete-note-link').off('click', deleteHandler);
        $('.delete-note-link').on('click', deleteHandler);

        $('.highlighted-text').off('click', controlHandler);
        $('.highlighted-text').on('click', controlHandler);
      });
    },
    handleNoteEditClicks: function handleNoteEditClicks(event) {
      this.send('editNote', event.currentTarget.getAttribute('data-note-id'));
    },
    handleNoteDeleteClicks: function handleNoteDeleteClicks(event) {
      this.send('deleteNote', event.currentTarget.getAttribute('data-note-id'));
    },
    handleHighlightClicks: function handleHighlightClicks(event) {
      var noteId = event.currentTarget.getAttribute('data-note-id');
      $('.delete-note-link[data-note-id]').removeClass('is-visible');
      $('.delete-note-link[data-note-id="' + noteId + '"]').toggleClass('is-visible');
    },
    actions: {
      closeNote: function closeNote() {
        this.set('showNoteModal', false);
        this.set('noteText', '');
        this.set('currentSelection', '');
      },

      openNoteModal: function openNoteModal() {
        this.set('noteText', '');
        this.set('showNoteModal', true);
        this.set('currentSelection', this.selectedText());
      },
      highlight: function highlight() {
        var selection = this.selectedText();

        if (selection.commonAncestorContainer) {
          var noteCount = this.get('noteCount') + 1;

          this.get('notes').pushObject({ selection: selection, noteText: null });
          this.set('noteCount', noteCount);

          this.highlightRecursively(selection.commonAncestorContainer, selection, noteCount);
          this.bindNoteActions();
        }

        this.set('showNoteModal', false);
        $('.highlight-control-group').removeClass('is-visible');
      },
      createNote: function createNote() {
        var selection = this.get('currentSelection');
        var noteCount = this.get('noteCount') + 1;

        this.get('notes').pushObject({ selection: selection, noteText: this.get('noteText') });
        this.set('noteCount', noteCount);

        if (selection.commonAncestorContainer) {
          this.highlightRecursively(selection.commonAncestorContainer, selection, noteCount);
          this.bindNoteActions();
        }

        this.set('showNoteModal', false);
        this.set('noteText', '');
        $('.highlight-control-group').removeClass('is-visible');
      },
      editNote: function editNote(noteId) {
        this.set('currentNoteId', noteId);
        this.set('noteText', this.noteTextForNote(noteId));
        this.set('showNoteEditModal', true);
      },
      deleteNote: function deleteNote(noteId) {
        // leave a blank note where this one was
        var notes = this.get('notes');
        notes[noteId - 1] = null;
        this.set('notes', notes);

        Ember.run.later(function () {
          var spanToRemove = $('span[data-note-id="' + noteId + '"]');
          var actionToRemove = $('.note-action-wrapper[data-note-id="' + noteId + '"]');

          actionToRemove.remove();

          $.each(spanToRemove, function (index) {
            var node = $(this);

            // have to replace highlighted text with normal text
            node.before(node.text());
            node.remove();
          });
        });

        this.set('currentNoteId', null);
        this.set('showNoteEditModal', false);
        this.set('noteText', '');
      },
      saveNote: function saveNote(noteId) {
        var note = this.get('notes').objectAt(noteId - 1);

        Ember.set(note, 'noteText', this.get('noteText'));

        this.set('currentNoteId', null);
        this.set('showNoteEditModal', false);
        this.set('noteText', '');
      }
    }
  });
});
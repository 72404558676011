define('ohs/components/chart-answer-choices-part-two', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        answerOptions: Ember.computed('parentView.administration.testQuestion.id', function () {
            var administration = Ember.get(this, 'parentView.administration');
            return Ember.get(administration, 'testQuestion.question.secondPartQuestion.secondPartAnswerOptions');
        }),

        answerChoicePickedPercents: Ember.computed('parentView.studentTestAdministrations.@each.unansweredQuestions', 'parentView.administration.testQuestion.id', function () {
            var _this = this;

            var choiceCounts = Ember.get(this, 'answerOptions').reduce(function (a, b) {
                return a.concat(0);
            }, []),
                answerOptionIds = Ember.get(this, 'answerOptions').map(function (x) {
                return parseInt(Ember.get(x, 'id'));
            }),
                studentCount = Ember.get(this, 'parentView.studentTestAdministrations.length');
            return Ember.get(this, 'parentView.studentTestAdministrations')
            //gets all the student answers from the STAs for the current question
            .reduce(function (sas, sta) {
                return sas.concat(Ember.get(sta, 'studentSecondPartAnswers').filterBy('questionId', parseInt(Ember.get(_this, 'parentView.administration.testQuestion.question.id'))));
            }, [])
            //calculate how many times each answer was picked
            .reduce(function (counts, sa) {
                var index = answerOptionIds.indexOf(Ember.get(sa, 'secondPartAnswerId'));
                if (index != -1) counts[index] += 1;
                return counts;
            }, choiceCounts)
            //gets the percentages
            .map(function (count) {
                return count > 0 ? Math.round(count / studentCount * 100) : 0;
            });
        }),

        data: Ember.computed('answerChoicePickedPercents', 'answerOptions.[]', 'answerOptions.@each.id', function () {
            return {
                labels: Ember.get(this, 'labels'),
                datasets: [{
                    backgroundColor: Ember.get(this, 'backgroundColors'),
                    borderColor: 'rgb(255, 99, 132)',
                    data: Ember.get(this, 'answerChoicePickedPercents')
                }]
            };
        }),

        labels: Ember.computed('answerOptions.[]', 'answerOptions.@each.id', function () {
            return (Ember.get(this, 'answerOptions') || []).map(function (ao) {
                return Ember.get(ao, 'answerLetter');
            });
        }),

        backgroundColors: Ember.computed('answerOptions.[]', 'answerOptions.@each.id', function () {
            var _this2 = this;

            return (Ember.get(this, 'answerOptions') || []).map(function (ao) {
                return Ember.get(ao, 'isCorrect') ? Ember.get(_this2, 'parentView.correctColor') : Ember.get(_this2, 'parentView.incorrectColor');
            });
        })
    });
});
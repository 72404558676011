define('ohs/routes/passage-quiz', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/teacher-authorize-mixin'], function (exports, _authenticatedRouteMixin, _reset, _noNavigation, _teacherAuthorizeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _noNavigation.default, _teacherAuthorizeMixin.default, {
    titleToken: function titleToken(model) {
      return model.createQuiz1.quiz.get('quizName');
    },

    model: function model(params, transistion) {
      var store = this.store;
      //ensures they did the first filtering part
      var createQuiz1 = this.modelFor('create-quiz-1');
      if (createQuiz1) {
        //if we are coming from passage-quiz-detail there is no need to reload the model
        if (transistion.pivotHandler.controller.currentPath == 'passage-quiz-detail') {
          var model = this.modelFor('passage-quiz');
          Ember.set(model, 'selectedLength', '');
          Ember.set(model, 'selectedTextType', null);
          return model;
        }

        return Ember.RSVP.hash({
          createQuiz1: this.modelFor('create-quiz-1'),
          selectedLength: '',
          selectedTextType: null,
          isThroughPassage: true,
          editingQuizName: false,
          passages: store.query('passage', { 'gradeLevelId': createQuiz1.quiz.get('gradeLevelId'), 'subjectId': createQuiz1.quiz.get('subjectId') }),
          gradeLevelDesc: Ember.computed('createQuiz1.quiz.gradeLevelId', function () {
            var grade = store.peekRecord('grade-level', this.createQuiz1.quiz.get('gradeLevelId'));
            return grade.get('description');
          }),
          subjectName: Ember.computed('createQuiz1.quiz.subjectId', function () {
            var subject = store.peekRecord('subject', this.createQuiz1.quiz.get('subjectId'));
            return subject.get('text');
          }),

          textTypes: Ember.computed('passages.@each.passageTextType', function () {
            var textTypeIds = this.passages.reduce(function (previous, next) {
              if (!previous.includes(next.get('passageTextType.id'))) {
                return previous.concat(next.get('passageTextType.id'));
              }
              return previous;
            }, Ember.A([]));

            return store.peekAll('passage-text-type').filter(function (obj) {
              return textTypeIds.includes(obj.get('id'));
            });
          }),

          complexities: Ember.computed('passages.[]', function () {
            return Ember.get(this, 'passages').reduce(function (previous, next) {
              if (!previous.some(function (cl) {
                return Ember.get(cl, 'id') == Ember.get(next, 'passageComplexityLevel.id');
              })) previous.pushObject(Ember.get(next, 'passageComplexityLevel'));
              return previous;
            }, []).map(function (complexity) {
              return {
                id: Ember.get(complexity, 'id'),
                text: Ember.get(complexity, 'level')
              };
            });
          }),

          curPasages: Ember.computed('passages@each.id', 'selectedComplexity', 'selectedLength', 'selectedTextType', function () {
            var filtered = this.passages.filter(function (p, index, array) {
              var hasLength = true;
              switch (this.selectedLength) {
                case '1':
                  hasLength = true;
                  break;
                case '2':
                  hasLength = p.get('passageLength') == 'Short';
                  break;
                case '3':
                  hasLength = p.get('passageLength') == 'Medium';
                  break;
                case '4':
                  hasLength = p.get('passageLength') == 'Long';
                  break;
                default:
                  hasLength = true;
                  break;
              }

              var hasTextType = this.selectedTextType ? p.get('passageTextType.id') == Ember.get(this, 'selectedTextType') : true;
              var hasComplexity = Ember.get(this, 'selectedComplexity') ? Ember.get(p, 'passageComplexityLevel.id') == Ember.get(this, 'selectedComplexity') : true;
              return hasComplexity && hasLength && hasTextType;
            }, this);
            return filtered.reverse();
          })
        });
      } else {
        this.transitionTo('create-quiz-1', 'New');
      }
    },
    actions: {
      goBack: function goBack() {
        this.transitionTo('create-quiz-1', 'existingQuiz');
      },

      transitionToPassage: function transitionToPassage(id) {
        this.transitionTo('passage-quiz-detail', id);
      },

      filterComplexities: function filterComplexities(value) {
        Ember.set(this, 'currentModel.selectedComplexity', value);
      },

      filterByLength: function filterByLength(selectedValue) {
        this.set('currentModel.selectedLength', selectedValue);
      },

      filterByTextType: function filterByTextType(selectedTextType) {
        this.set('currentModel.selectedTextType', selectedTextType);
      }
    }
  });
});
define('ohs/mixins/signalr-controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        signalr: Ember.inject.service('signal-r'),

        init: function init() {
            this._super.apply(this, arguments);
            var signalr = this.get('signalr'),
                self = this;

            signalr.on('notificationChange', function () {
                self.send.apply(self, ['notificationChange'].concat(Array.prototype.slice.call(arguments)));
            });
            signalr.on('studentFinished', function () {
                self.send('studentFinished');
            });
            signalr.on('studentJoined', function () {
                self.send.apply(self, ['joinStudent'].concat(Array.prototype.slice.call(arguments)));
            });
            signalr.on('studentLeft', function () {
                self.send.apply(self, ['studentLeft'].concat(Array.prototype.slice.call(arguments)));
            });
            signalr.on('refreshModel', function () {
                self.send('refresh');
            });
            signalr.on('changeQuestion', function () {
                self.send.apply(self, ['changeQuestion'].concat(Array.prototype.slice.call(arguments)));
            });
            signalr.on('signalRConnectionAlert', function () {
                self.send.apply(self, ['signalRConnectionAlert'].concat(Array.prototype.slice.call(arguments)));
            });
            signalr.on('updateAnswer', function () {
                self.send.apply(self, ['updateAnswer'].concat(Array.prototype.slice.call(arguments)));
            });
            signalr.on('deleteSecondPartAnswer', function () {
                self.send.apply(self, ['deleteSecondPartAnswer'].concat(Array.prototype.slice.call(arguments)));
            });
            signalr.on('addAnswer', function () {
                self.send.apply(self, ['addAnswer'].concat(Array.prototype.slice.call(arguments)));
            });
            signalr.on('endAdministration', function () {
                self.send.apply(self, ['endAdministration'].concat(Array.prototype.slice.call(arguments)));
            });
            signalr.on('joinRoster', function () {
                self.send('joinRoster');
            });
            signalr.on('cancelQuiz', function () {
                self.send.apply(self, ['cancelQuiz'].concat(Array.prototype.slice.call(arguments)));
            });
            signalr.on('endQuiz', function () {
                self.send.apply(self, ['endQuiz'].concat(Array.prototype.slice.call(arguments)));
            });
            signalr.on('convertToSelfGuidedQuiz', function () {
                self.send.apply(self, ['convertToSelfGuidedQuiz'].concat(Array.prototype.slice.call(arguments)));
            });
            signalr.on('changeTeam', function () {
                self.send('changeTeam');
            });
            signalr.on('removeStudentFromAdministration', function () {
                self.send.apply(self, ['removeStudentFromAdministration'].concat(Array.prototype.slice.call(arguments)));
            });
        }
    });
});
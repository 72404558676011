define('ohs/services/signal-r', ['exports', 'ohs/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {

    administrationId: 0,
    signalRHub: null,
    currentlyConnected: false,

    init: function init() {
      var _this = this;

      $.connection.hub.url = _environment.default.apiUrl + '/signalr';
      this.set('signalRHub', $.connection.studentTestHub);
      this.set('self', this);

      var self = this;

      //Handle connection issues
      this.get('signalRHub').connection.reconnecting(function () {
        self.trigger('signalRConnectionAlert', "Your connection to the server has been lost. Attempting to reconnect...", "warning-text", "icon icon-alert");
      });

      this.get('signalRHub').connection.reconnected(function () {
        self.trigger('signalRConnectionAlert', "Connection Restored!", "success-text", "icon icon-correct");
        setTimeout(function () {
          //self.refresh();
          self.trigger('refreshModel');
        }, 2000);
      });

      //Reconnect to SignalR hub if the connection is lost
      this.get('signalRHub').connection.disconnected(function () {
        setTimeout(function () {
          $.connection.hub.start({
            withCredentials: false
          }).done(function () {
            //Do stuff here if we need to perform actions when connecting to the hub
            self.get('signalRHub').server.joinAdministration(self.get('administrationId'));
            self.trigger('signalRConnectionAlert', "Connection Restored!", "success-text", "icon icon-correct");
            setTimeout(function () {
              //self.refresh();
              self.trigger('refreshModel');
            }, 2000);
          }).fail(function () {
            self.trigger('signalRConnectionAlert', "Could not connect to the server. Please make sure you are connected to the internet.", "warning-text", "icon icon-alert");
          });
        }, 5000); // Restart connection after 5 seconds.
      });

      //client methods
      //Method invoked from server when a student joins the quiz
      this.get('signalRHub').client.studentJoined = function (studentTestId) {
        self.trigger('studentJoined', studentTestId);
      };

      this.get('signalRHub').client.deleteSecondPartAnswer = function (secondPartAnswerId) {
        self.trigger('deleteSecondPartAnswer', secondPartAnswerId);
      };

      //Method invoked from server when a student leaves the quiz
      this.get('signalRHub').client.studentLeft = function (studentTestId) {
        self.trigger('studentLeft', studentTestId);
      };

      //Method invoked from server when a student finished the quiz
      this.get('signalRHub').client.studentFinished = function (studentTestId) {
        self.trigger('studentFinished', studentTestId);
      };

      //Method invoked from server when a new answer is added
      this.get('signalRHub').client.addAnswer = function (studentAnswer) {
        self.trigger('addAnswer', studentAnswer);
      };

      //Method invoked from server when an answer is updated
      this.get('signalRHub').client.updateAnswer = function (studentAnswer) {
        self.trigger('updateAnswer', studentAnswer);
      };

      //Method invoked from server when the teacher changes questions
      this.get('signalRHub').client.changeQuestion = function (questionIndex, answersLocked, administrationId) {
        self.trigger('changeQuestion', questionIndex, answersLocked, administrationId);
      };

      //Method invoked from server when the teacher ends the quiz
      this.get('signalRHub').client.endQuiz = function (administrationId) {
        self.trigger('endQuiz', administrationId);
      };

      //Method invoked from server when the teacher ends the quiz with no answers
      this.get('signalRHub').client.cancelQuiz = function (administrationId) {
        self.trigger('cancelQuiz', administrationId);
      };

      //Method invoked from server when the job scheduler ends the quiz automatically based on end time for a student controlled test
      this.get('signalRHub').client.endAdministration = function (administrationId) {
        self.trigger('endAdministration', administrationId);
      };

      //Method invoked from server when a new notification is available or an existing notification is obsolete
      this.get('signalRHub').client.notificationChange = function (userId) {
        self.trigger('notificationChange', userId);
      };

      this.get('signalRHub').client.newNotification = function () {
        return _this.trigger('newNotification');
      };

      //Method invoked from server when a new student joined to a teacher roster
      this.get('signalRHub').client.joinRoster = function (userId) {
        self.trigger('joinRoster', userId);
      };

      //Method invoked from server when a new student linked to parent
      this.get('signalRHub').client.linkParentStudent = function (userId) {
        self.trigger('linkParentStudent', userId);
      };

      //Method invoked from server when a teacher controlled quiz is converted to self-guided quiz
      this.get('signalRHub').client.convertToSelfGuidedQuiz = function (studentTestId, testQuestionId) {
        self.trigger('convertToSelfGuidedQuiz', studentTestId, testQuestionId);
      };

      //Method invoked from server when a page refresh needed at the client side
      this.get('signalRHub').client.refreshModel = function () {
        self.trigger('refreshModel');
      };

      //Method invoked from server when the teacher removes student from administration
      this.get('signalRHub').client.removeStudentFromAdministration = function (administrationId) {
        self.trigger('removeStudentFromAdministration', administrationId);
      };

      this.get('signalRHub').client.changeTeam = function () {
        self.trigger('changeTeam');
      };

      //Start the SignalR connection
      return $.connection.hub.start({
        withCredentials: false
      }).done(function () {

        //alert('SignalR Service Started');
        self.currentlyConnected = true;
      }).fail(function () {
        //alert('SignalR Service Failed to Start');
        self.trigger('signalRConnectionAlert', "Could not connect to the server. Please check your internet connection and try again.", "warning-text", "icon icon-alert");
      });
    },


    //server methods
    joinAdministration: function joinAdministration(administrationId) {
      //Make sure we have a good connection before joining the hub
      if (this.currentlyConnected) {
        this.get('signalRHub').server.joinAdministration(administrationId);
      } else {
        var self = this;
        $.connection.hub.start({
          withCredentials: false
        }).done(function () {

          //alert('SignalR Service Started');
          self.currentlyConnected = true;
          self.get('signalRHub').server.joinAdministration(administrationId);
        }).fail(function () {
          //alert('SignalR Service Failed to Start');
          self.trigger('signalRConnectionAlert', "Could not connect to the server. Please check your internet connection and try again.", "warning-text", "icon icon-alert");
        });
      }
    },
    leaveAdministration: function leaveAdministration(administrationId) {
      this.get('signalRHub').server.leaveAdministration(administrationId);
    },
    joinUserHub: function joinUserHub(userId) {
      //Make sure we have a good connection before joining the hub
      if (this.currentlyConnected) {
        this.get('signalRHub').server.joinUserHub(userId);
        //alert('Joining Hub for Administration ' + administrationId);
      } else {
        var self = this;
        $.connection.hub.start({
          withCredentials: false
        }).done(function () {

          //alert('SignalR Service Started');
          self.currentlyConnected = true;
          self.get('signalRHub').server.joinUserHub(userId);
        }).fail(function () {
          //alert('SignalR Service Failed to Start');
          self.trigger('signalRConnectionAlert', "Could not connect to the server. Please check your internet connection and try again.", "warning-text", "icon icon-alert");
        });
      }
    },
    leaveUserHub: function leaveUserHub(userId) {
      this.get('signalRHub').server.leaveUserHub(userId);
    }
  });
});
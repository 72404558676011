define('ohs/routes/passage-quiz-questions', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/passage-modal-mixin'], function (exports, _authenticatedRouteMixin, _reset, _noNavigation, _teacherAuthorizeMixin, _passageModalMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _noNavigation.default, _teacherAuthorizeMixin.default, _passageModalMixin.default, {
    titleToken: function titleToken(model) {
      return model.passagequiz.createQuiz1.quiz.get('quizName');
    },

    model: function model(params) {
      var store = this.store;
      var passagequiz = this.modelFor('passage-quiz');
      if (passagequiz) {
        return Ember.RSVP.hash({
          passagequiz: this.modelFor('passage-quiz'),
          curPassageId: params.passage_id,
          passage: store.peekRecord('passage', params.passage_id),
          questions: this.store.query('question', { 'passageId': params.passage_id.toString() }),
          isThroughPassage: true,
          editingQuizName: false
        });
      } else {
        this.transitionTo('create-quiz-1', 'New');
      }
    },

    actions: {
      editQuiz: function editQuiz() {
        this.transitionTo('create-quiz-1', 'existingQuiz');
      },
      backToPassages: function backToPassages() {
        this.transitionTo('passage-quiz');
      },
      reviewQuiz: function reviewQuiz(quizId) {
        this.transitionTo('passage-quiz-review', this.currentModel.curPassageId, quizId);
      }
    }
  });
});
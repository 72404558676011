define('ohs/routes/sign-up-tutor', ['exports', 'ohs/mixins/no-navigation', 'ohs/mixins/reset'], function (exports, _noNavigation, _reset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reset.default, _noNavigation.default, {
        titleToken: 'Create A New Account',

        model: function model() {
            return Ember.RSVP.hash({
                // this is here to make the sign-up-success route work
                user: Ember.computed.alias('tutor'),
                userRole: Ember.computed.alias('tutor.userRole'),
                tutor: this.store.createRecord('tutor', {
                    userRole: 'Tutor',
                    avatar: 'Green/aliens_final_green-01.svg'
                }),
                standardTypes: this.store.findAll('standard-type'),
                subjects: this.store.findAll('subject'),
                grades: this.store.query('grade', { gradeType: 'AllGrades' }),
                states: this.store.findAll('state'),
                referrals: this.store.findAll('survey-referral'),
                honorifics: [{ text: "Mrs", id: "Mrs" }, { text: "Mr", id: "Mr" }, { text: "Ms", id: "Ms" }, { text: "Miss", id: "Miss" }, { text: "Dr", id: "Dr" }]
            });
        }
    });
});
define('ohs/routes/start-quiz-1', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, {
    titleToken: function titleToken(model) {
      return "Start " + model.get('quizName');
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      return this.store.find('quiz', params.quiz_id);
    },


    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    actions: {
      teacherLed: function teacherLed() {
        var quiz = this.currentModel;
        this.transitionTo('start-quiz-2', quiz.id, 2);
      },

      selfGuided: function selfGuided() {
        var quiz = this.currentModel;
        this.transitionTo('start-quiz-2', quiz.id, 1);
      },

      printQuestions: function printQuestions() {
        var self = this;

        //  Added by Jibin for feature 3098	(Kandoolu - Remove 'Print Quiz' option from users on a Free Trial) on 02/23/2017
        var account = self.get('store').peekAll('account');
        var isFreeTrial = account.objectAt(0).get('isFreeTrial');
        if (isFreeTrial && isFreeTrial == true) {
          self.set('currentModel.showAlert', true);
          self.set('currentModel.alertMessage', "Printing quizzes is disabled during your free trial. To enable this feature, please upgrade to a full subscription!");
          return;
        }
        //  feature 3098 end

        self.set("currentModel.isProcessing", true);
        self.set('currentModel.isPrinting', true);

        var quizId = this.get('currentModel.id');
        var baseUrl = this.store.adapterFor('application').get('host');

        var sesObj = this.get('session');
        var userId = sesObj.session.content.authenticated["userId"];
        return Ember.$.getJSON(baseUrl + '/quiz/GenerateQuizQuestionsPdf?quizId=' + quizId + '&userId=' + encodeURIComponent(userId)).done(function (response) {
          //self.refresh();
          self.set("currentModel.isProcessing", false);

          var xhr = new XMLHttpRequest();
          xhr.open('GET', baseUrl + '/quiz/DownloadQuizQuestions?fileName=' + encodeURIComponent(response), true);
          xhr.responseType = "blob";
          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
              var blob = new Blob([xhr.response], { type: "application/pdf" });
              //
              // var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
              // if(isSafari){
              //   var blob = new Blob([xhr.response], {type: "application/pdf"});
              // }
              // else{
              //   var blob = new Blob([xhr.response], {type: "application/pdf"});
              // }
              saveAs(blob, response);
            }
          };
          xhr.send(null);

          self.set('currentModel.isPrinting', false);
        }).fail(function (response) {
          self.set("currentModel.isProcessing", false);
          self.set("currentModel.error", true);
          self.set('currentModel.isPrinting', false);
        });
      }
    }
  });
});
define('ohs/models/class-linked-resource', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    resource: _emberData.default.belongsTo('resource'),
    description: _emberData.default.attr('string'),
    teacher: _emberData.default.belongsTo('user'),
    dateCreated: _emberData.default.attr('date'),
    // classLibraryFolders: DS.hasMany('classLibraryFolder'),
    classLibraryFolder: _emberData.default.belongsTo('classLibraryFolder'),

    itemIcon: Ember.computed(function () {
      return 'icon-class-library-www';
    }),

    trimmedName: Ember.computed('resource.resourceName', function () {
      if (this.get('resource') && this.get('resource.resourceName')) {
        if (this.get('resource.resourceName').length > 18) {
          return this.get('resource.resourceName').substring(0, 13) + '...';
        } else {
          return this.get('resource.resourceName');
        }
      }
    })
  });
});
define('ohs/router', ['exports', 'ohs/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('sign-up');
    this.route('student-dashboard');
    this.route('teacher-dashboard');
    this.route('student-question', { path: 'student-question/:student_test_id' });
    this.route('student-passage-quiz', { path: 'student-passage-quiz/:student_test_id', queryParams: ['question_id'] });
    this.route('student-passage-quiz-7');
    this.route('site-map');
    this.route('create-quiz-1', { path: 'create-quiz-1/:quiz_id' });
    this.route('create-quiz-4');
    this.route('passage-quiz');
    this.route('passage-quiz-detail', { path: 'passage-quiz-detail/:passage_id' });
    this.route('passage-quiz-questions', { path: 'passage-quiz-questions/:passage_id' });
    this.route('passage-quiz-review', { path: 'passage-quiz-review/:passage_id/:quiz_id' });
    this.route('review-1', { path: 'review-1/:quiz_id' });
    this.route('start-quiz-0', { path: 'start-quiz-0/:quiz_id' });
    this.route('start-quiz-1', { path: 'start-quiz-1/:quiz_id' });
    this.route('start-quiz-2', { path: 'start-quiz-2/:quiz_id/:test_type_id' });
    this.route('teacher-resources-1');
    this.route('teacher-resources-2');
    this.route('teacher-self-guided-results', { path: 'teacher-self-guided-results/:administration_id' });
    this.route('teacher-results-1', { path: 'teacher-results-1/:administration_id/:show_next_step' });
    this.route('teacher-results-2', { path: 'teacher-results-2/:student_test_id' });
    this.route('student-resources');
    this.route('student-results', { path: 'student-results/:student_test_id/:show_next_step' });
    this.route('teacher-quiz-room', { path: 'teacher-quiz-room/:administration_id' });
    this.route('teacher-results-list');
    this.route('home');
    this.route('student-quiz-room', { path: 'student-quiz-room/:student_test_id' });
    this.route('sign-up-success');
    this.route('edit-quiz-name', { path: 'edit-quiz-name/:quiz_id/:return_detail' });
    this.route('edit-quiz-questions', { path: 'edit-quiz-questions/:quiz_id/:return_detail' });
    this.route('teacher-quiz-question', { path: 'teacher-quiz-question/:administration_id' });
    this.route('student-self-guided-question', { path: 'student-question/:student_test_id/:question_id' });
    this.route('students');
    this.route('student-list');
    this.route('student-group', { path: 'student-group/:group_id' });
    this.route('student-group-add-remove', { path: 'student-group-add-remove/:group_id' });
    this.route('student-group-edit', { path: 'student-group-edit/:group_id' });
    this.route('student-group-create');
    this.route('student-profile', { path: 'student-profile/:student_id' });
    this.route('student-quizzes');
    this.route('teacher-quizzes');
    this.route('verify-email', { path: 'verify-email/:code/:userId/:pwd' });
    this.route('student-trophies');
    this.route('student-groups-student', { path: 'student-groups-student/:student_id' });
    this.route('forgot-password');
    this.route('reset-password', { path: 'reset-password/:resetToken/:userId' });
    this.route('forgot-user-name');

    this.route('parent', function () {
      this.route('resource-detail', { path: 'resource-detail/:resource_id' });
      this.route('resource-search-start');
      this.route('search-standard');
      this.route('search-results');
      this.route('parent-dashboard');
      this.route('loading');
      this.route('student-quiz-results');
      this.route('resource-bookmarks');
      this.route('student-resources');
      this.route('student-trophies');
    });

    this.route('teacher', function () {
      this.route('resource-search-start');
      this.route('resource-detail', { path: 'resource-detail/:resource_id' });
      this.route('search-standard');
      this.route('search-results');
      this.route('trophies');
      this.route('resource-bookmarks');
      this.route('resource-ratings');
      this.route('quiz-detail', { path: 'quiz-detail/:test_id' });
      this.route('loading');
      this.route('standard-coverage');
      this.route('roster-approval');
      this.route('quiz-search-start');
      this.route('quiz-search-results');
      this.route('shared-quiz-detail', { path: 'shared-quiz-detail/:test_id' });
      this.route('teacher-dashboard');

      this.route('quiz', { path: 'quiz/:administration_id' }, function () {
        this.route('game-mode', function () {
          this.route('lobby');
          this.route('in-progress', function () {
            this.route('teacher-view');
            this.route('classroom-view');
          });
          this.route('game-results');
        });
        this.route('teacher-led', function () {
          this.route('ela', function () {
            this.route('teacher-view');
            this.route('classroom-view');
          });
          this.route('lobby');
        });
      });
      this.route('tug-of-war-lobby');

      this.route('start-quiz', { path: 'start-quiz/:quiz_id' }, function () {
        this.route('pick-type');
        this.route('pick-students');
        this.route('pick-teams');
      });
      this.route('mission-control', function () {
        this.route('leaderboard');

        this.route('mission', function () {
          this.route('details', { path: ':mission_id/details' });
          this.route('search');
          this.route('sent');
          this.route('results', { path: 'results/:student_mission_id' });
        });
      });
      this.route('continue-quiz', { path: 'continue-quiz/:quiz_id/:administration_id' }, function () {
        this.route('pick-type');
        this.route('pick-students');
      });
    });

    this.route('pattern-library', function () {
      this.route('index');
      this.route('type');
      this.route('grid');
      this.route('iconography');
      this.route('buttons');
      this.route('reset');
      this.route('trophies');
      this.route('forms');
      this.route('modules', function () {
        this.route('index');
        this.route('page-titles');
        this.route('notifications');
        this.route('filters');
        this.route('modals');
        this.route('results-list');
        this.route('tabs');
      });
    });

    this.route('loading');
    this.route('student-teacher-list');
    this.route('join-quiz-without-login');
    this.route('error');
    this.route('student-result-list');
    this.route('user-profile');
    this.route('change-password', { path: 'change-password/:userId/:force_password_change' });
    this.route('teacher-class-view', { path: 'teacher-class-view/:administration_id' });
    this.route('teacher-sent-resources');
    this.route('student-parent-list');
    this.route('parent-students');
    this.route('change-avatar');
    this.route('link-teacher-without-login');
    this.route('quiz-approved-resources', { path: 'quiz-approved-resources/:test_id/:return_detail' });
    this.route('create-quiz-5', { path: 'create-quiz-5/:test_id' });
    this.route('force-password-change');
    this.route('student-review-quiz', { path: 'student-review-quiz/:student_test_id' });
    this.route('manage-students');
    this.route('account-admin', function () {
      this.route('teacher', { path: 'teacher/:user_id' }, function () {
        this.route('quiz-detail', { path: 'quiz-detail/:quiz_id' });
      });
      this.route('dashboard');
    });
    this.route('manage-student-profile', { path: 'manage-student-profile/:student_id' });
    this.route('manage-teachers');
    this.route('new-user', { path: 'new-user/:role/:error_id' });
    this.route('manage-teacher-profile', { path: 'manage-teacher-profile/:teacher_id' });
    this.route('reset-user-password', { path: 'reset-password/:userId' });
    this.route('account-disable', { path: 'account-disable/:userId' });
    this.route('student-account-list-add-remove', { path: 'student-account-list-add-remove/:teacher_id/:accountId' });
    this.route('roster-error-update', { path: 'roster-error-update/:roster_error_Id' });

    this.route('all-students');
    this.route('grammar-question');
    this.route('grammar-question-full');
    this.route('grammar-question-with-image');
    this.route('class-library', { path: 'class-library/:folder_id' });
    this.route('add-class-resource-folder', { path: 'add-class-resource-folder/:folder_id' });
    this.route('add-class-resource', { path: 'add-class-resource/:folder_id' });
    this.route('schoology-login');
    this.route('schoology-link-user');
    this.route('edit-class-resource', { path: 'edit-class-resource/:class_resource_id' });
    this.route('schoology-sign-in', { path: 'schoology-sign-in/:role' });
    this.route('view-class-resource', { path: 'view-class-resource/:class_resource_id' });
    this.route('add-resource-classlibrary', { path: 'add-resource-classlibrary/:resource_id' });
    this.route('edit-resource-classlibrary', { path: 'edit-resource-classlibrary/:class_linked_resource_id' });
    this.route('admin-dashboard');
    this.route('teacher-leaderboard');
    this.route('view-class-linked-resource', { path: 'view-class-linked-resource/:class_linked_resource_id' });
    this.route('edit-class-resource-folder', { path: 'edit-class-resource-folder/:folder_id' });
    this.route('edit-passage-quiz-questions', { path: 'edit-passage-quiz-questions/:quiz_id/:passage_id/:return_detail' });
    this.route('purchase');
    this.route('purchase-success');

    this.route('quick-stats');
    this.route('usage-tracker');
    this.route('linked-student-list', { path: 'linked-student-list/:teacher_id' });
    this.route('login-details', { path: 'login-details', queryParams: ['user_id', 'from_date', 'to_date', 'filter_type'] });
    this.route('teacher-quiz-details', { path: 'teacher-quiz-details/:teacher_id' });
    this.route('resource-send-details', { path: 'resource-send-details', queryParams: ['teacher_id', 'from_date', 'to_date', 'filter_type'] });
    this.route('student-quiz-details', { path: 'student-quiz-details', queryParams: ['student_id', 'from_date', 'to_date', 'filter_type'] });
    this.route('student-resource-details', { path: 'student-resource-details', queryParams: ['student_id', 'from_date', 'to_date', 'filter_type'] });
    this.route('teacher-accounts');
    this.route('teacher-account-details', { path: 'teacher-account-details/:user_id' });
    this.route('student-accounts');
    this.route('student-account-details', { path: 'student-account-details/:user_id' });
    this.route('download-account-creation-template');
    this.route('upload-account-creation-template');
    this.route('standard-coverage-report');
    this.route('student-earned-trophies', { path: 'student-earned-trophies', queryParams: ['user_id', 'from_date', 'to_date', 'filter_type'] });
    this.route('welcome');
    this.route('student', function () {
      this.route('quiz', { path: 'quiz/:student_test_administration_id' }, function () {
        this.route('game-mode', function () {
          this.route('lobby');
          this.route('in-progress');
        });
        this.route('teacher-led', function () {
          this.route('ela', function () {
            this.route('in-progress');
          });
          this.route('lobby');
        });
      });
      this.route('mission-control', function () {
        this.route('spaceships');

        this.route('missions', function () {
          this.route('search');
        });
        this.route('mission-results-student', { path: 'mission-results-student/:student_mission_id' });
        this.route('start-mission', { path: 'start-mission/:student_mission_id' });
        this.route('mission-question', { path: 'mission-question/:student_mission_id' });
        this.route('mission-complete', { path: 'mission-complete/:student_mission_id' });
      });
    });
    this.route('force-roster-join');
    this.route('sign-up-student');
    this.route('sign-up-teacher');
    this.route('tutor', function () {});
    this.route('sign-up-tutor');
  });

  exports.default = Router;
});
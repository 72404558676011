define('ohs/components/progress-meter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['dynamicClasses'],

    dynamicClasses: Ember.computed('inProgress', function () {
      if (this.get('inProgress')) {
        return ['progress-meter-wrapper self-guided-result'];
      } else {
        return ['progress-meter-wrapper'];
      }
    }),

    initialStyle: Ember.computed('percentage-initial', function () {
      var width = this.get('percentage-initial');
      return new Ember.Handlebars.SafeString("width: " + width + "%;");
    }),

    currentStyle: Ember.computed('percentage-now', function () {
      var width = this.get('percentage-now');
      return new Ember.Handlebars.SafeString("left: 0%; width: " + width + "%;");
    })

  });
});
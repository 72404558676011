define('ohs/models/mission-control/assessment-item', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var hasMany = _emberData.default.hasMany;
    exports.default = _emberData.default.Model.extend({
        assessmentItemQuestions: hasMany('mission-control/assessment-item-question'),

        assessmentItemQuesitonsSorting: Object.freeze(['order']),
        sortedAssessmentItemQuestions: Ember.computed.sort('assessmentItemQuestions', 'assessmentItemQuesitonsSorting'),

        /* NOTE: Currently mission-control does not plan to support 2 part questions.
            This is to by pass the two part functionality that the content-portal offers already.
            When we plan to implement two part questions this will need removed. 
        */
        question: Ember.computed('sortedAssessmentItemQuestions.[]', function () {
            var questions = Ember.get(this, 'sortedAssessmentItemQuestions');

            return questions.get('length') > 0 ? questions.objectAt(0).get('question') : null;
        })
    });
});
define('ohs/components/mission-control/student-mission-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ol',
    classNames: 'mission-item-list',

    filteredMissions: Ember.computed('planet.missions.@each.id', 'missionFilter', function () {
      var missionFilter = this.get('missionFilter');
      if (missionFilter == 1) {
        return this.get('planet.missions');
      } else if (missionFilter == 2) {
        return this.get('planet.missions').filter(function (mission) {
          var studentMission = this.get('studentMissions').findBy('mission.id', mission.get('id'));
          if (studentMission) {
            if (studentMission.get('numQuestions') == studentMission.get('numAnswered')) {
              return null;
            } else if (studentMission.get('numAnswered') == 0) {
              return mission;
            } else {
              return null;
            }
          } else {
            return mission;
          }
        }, this);
      } else {
        return this.get('planet.missions').filter(function (mission) {
          var studentMission = this.get('studentMissions').findBy('mission.id', mission.get('id'));
          if (studentMission) {
            if (studentMission.get('numQuestions') == studentMission.get('numAnswered')) {
              return mission;
            } else if (studentMission.get('numAnswered') == 0) {
              return null;
            } else {
              return mission;
            }
          } else {
            return null;
          }
        }, this);
      }
    }),

    actions: {
      viewResults: function viewResults(studentMissionId) {
        this.sendAction('viewResultsAction', studentMissionId);
      },
      startQuiz: function startQuiz(missionId) {
        this.sendAction('startQuizAction', missionId);
      },
      continueQuiz: function continueQuiz(studentMissionId) {
        this.sendAction('continueQuizAction', studentMissionId);
      }
    }
  });
});
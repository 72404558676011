define('ohs/models/administration', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    testCode: _emberData.default.attr('string'), //Used for student test login
    isOpen: _emberData.default.attr('boolean', { defaultValue: false }), //If the administration is open or not
    isComplete: _emberData.default.attr('boolean', { defaultValue: false }), //If the administration is complete or not
    currentQuestionIndex: _emberData.default.attr('number'), //Current question index of an ongoing administration in a test
    testTypeId: _emberData.default.attr('number'), //Whether the test is teacher led or student guided
    startTime: _emberData.default.attr('date'),
    endTime: _emberData.default.attr('date'),
    duration: _emberData.default.attr('number'),
    durationType: _emberData.default.attr('string'),
    teams: _emberData.default.hasMany('team'),
    quiz: _emberData.default.belongsTo('quiz'),
    studentTestAdministrations: _emberData.default.hasMany('student-test-administration', { async: false }),
    answersLocked: _emberData.default.attr('boolean'),
    parentAdministrationId: _emberData.default.attr('number'),

    percentCorrect: _emberData.default.attr('number'),

    averagePercentCorrect: Ember.computed('percentCorrect', function () {
      return Math.round(Ember.get(this, 'percentCorrect'));
    }),

    /** Current question that the administration is on */
    testQuestion: Ember.computed('quiz.testQuestions.@each.id', 'currentQuestionIndex', function () {
      var currentQuestionIndex = Ember.get(this, 'currentQuestionIndex') || 0;
      return (Ember.get(this, 'quiz.testQuestions') || []).toArray().sortBy('questionOrder')[currentQuestionIndex];
    }),

    //TODO: REFACTOR. Use moment anywhere this is referenced
    createdDate: Ember.computed('startTime', function () {
      var startTime = this.get('startTime');
      if (startTime) {
        var date = this.get('startTime').toLocaleDateString();
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (today.toLocaleDateString() == date) return 'Created Today';else if (yesterday.toLocaleDateString() == date) {
          return 'Created Yesterday';
        } else {
          return 'Created ' + date;
        }
      } else {
        return 'Not yet started';
      }
    }),

    /** Current active question number */
    currentQuestionNumber: Ember.computed('currentQuestionIndex', function () {
      return Ember.get(this, 'currentQuestionIndex') + 1;
    }),

    //TODO: REFACTOR. Use moment anywhere this is referenced
    startedDate: Ember.computed('startTime', function () {
      var startTime = this.get('startTime');
      var curDate = new Date();
      if (startTime && startTime < curDate) {
        var date = this.get('startTime').toLocaleDateString();
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (today.toLocaleDateString() == date) return 'Started Today';else if (yesterday.toLocaleDateString() == date) {
          return 'Started Yesterday';
        } else {
          return 'Started ' + date;
        }
      } else {
        return 'Not yet started';
      }
    }),

    //TODO: REFACTOR. Remove this? If startTime is null we have issues. 
    startedDateForOrdering: Ember.computed('startTime', function () {
      var startTime = this.get('startTime');
      if (startTime) {
        return startTime;
      } else {
        return new Date();
      }
    }),

    //TODO: REFACTOR. Use moment anywhere this is referenced
    createdDateOnly: Ember.computed('startTime', function () {
      var startTime = this.get('startTime');
      if (startTime) {
        var date = this.get('startTime').toLocaleDateString();
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (today.toLocaleDateString() == date) return 'Today';else if (yesterday.toLocaleDateString() == date) {
          return 'Yesterday';
        } else {
          return date;
        }
      } else {
        return 'Not Started';
      }
    }),

    //TODO: REFACTOR. Use moment anywhere this is referenced
    finishedDateOnly: Ember.computed('startTime', function () {
      var endTime = this.get('endTime');
      if (endTime) {
        var date = this.get('endTime').toLocaleDateString();
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (today.toLocaleDateString() == date) return 'Today';else if (yesterday.toLocaleDateString() == date) {
          return 'Yesterday';
        } else {
          return date;
        }
      } else {
        return 'Still Open';
      }
    }),

    //TODO: REFACTOR. We really shouldn't be hard coding this. 
    //If we have to then it should be somewhere centalized, not here.
    testTypeDisplay: Ember.computed('testTypeId', function () {
      var testTypeId = this.get('testTypeId');
      if (testTypeId == 1) return "Self-guided";else if (testTypeId == 2) return "Teacher-led";else if (testTypeId == 3) return "Game-mode";
    }),

    //TODO: REFACTOR. Use moment anywhere this is referenced
    dateGiven: Ember.computed('startTime', function () {
      var startTime = this.get('startTime');
      var curDate = new Date();
      if (startTime && startTime < curDate) {
        var date = startTime.toLocaleDateString();
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (today.toLocaleDateString() == date) return 'Given Today';else if (yesterday.toLocaleDateString() == date) {
          return 'Given Yesterday';
        } else return 'Given ' + date;
      } else {
        return 'Not yet started';
      }
    }),

    //This used to be used. We no longer support future dating administrations. 
    //TODO: Figure out if this is still needed, or has plans to be needed.
    completeBy: Ember.computed('endTime', function () {
      if (this.get('futureStartDate')) {
        var timeText = this.get('startTime').toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
        var date = this.get('startTime').toLocaleDateString();
        var today = new Date();
        var tomorrow = new Date();
        var dateText;
        tomorrow.setDate(tomorrow.getDate() + 1);
        if (today.toLocaleDateString() == date) dateText = 'Today';else if (tomorrow.toLocaleDateString() == date) {
          dateText = 'Tomorrow';
        } else {
          dateText = date;
        }
        if (dateText == 'Today' || dateText == 'Tomorrow') {
          return 'Starts at ' + timeText + ' ' + dateText;
        } else {
          return 'Starts at ' + timeText + ' on ' + dateText;
        }
      } else if (this.get('futureEndDate')) {
        var timeText = this.get('endTime').toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
        var date = this.get('endTime').toLocaleDateString();
        var today = new Date();
        var tomorrow = new Date();
        var dateText;
        tomorrow.setDate(tomorrow.getDate() + 1);
        if (today.toLocaleDateString() == date) dateText = 'Today';else if (tomorrow.toLocaleDateString() == date) {
          dateText = 'Tomorrow';
        } else {
          dateText = date;
        }
        if (dateText == 'Today' || dateText == 'Tomorrow') {
          return 'Complete by ' + timeText + ' ' + dateText;
        } else {
          return 'Complete by ' + timeText + ' on ' + dateText;
        }
      }

      switch (Ember.get(this, 'testTypeId')) {
        case 1:
          return "Self-guided";
        case 2:
          return "Teacher-led";
        case 3:
          return "Game-mode";
      }
    }),

    /** List of students with at least one answered question */
    studentsWithResults: Ember.computed('studentTestAdministrations.@each.id', function () {
      return this.get('studentTestAdministrations').filter(function (sta, index, array) {
        return sta.get('numAnswered') > 0;
      });
    }),

    //Server-Side Operation
    totalPointsAvailable: Ember.computed('quiz.numQuestions', function () {
      var pointCount = 0;
      var numStudents = this.get('studentsWithResults').get('length');
      if (numStudents == 0) {
        return 0;
      }
      this.get('quiz.testQuestions').forEach(function (item, index) {
        pointCount += item.get('question.numPoints');
      });
      return pointCount * numStudents;
    }),

    //Server-Side Operation
    numFinished: Ember.computed('studentTestAdministrations.@each.isFinished', function () {
      return this.get('studentTestAdministrations').filterBy('isFinished', true).get('length');
    }),

    //Server-Side Operation
    numIncomplete: Ember.computed('studentTestAdministrations.@each.isFinished', function () {
      return this.get('studentTestAdministrations').filterBy('isFinished', false).get('length');
    }),

    //TODO: REFACTOR. This works but we should really find another way of doing this. Since this is setting a property on another model through
    //a computed property, it seems very hacky.
    questionBreakdown: Ember.computed('studentTestAdministrations.@each.percentCorrect', function () {
      var testQuestions = this.get('quiz').get('testQuestions');
      var numStudents = this.get('studentsWithResults').get('length');
      var admin = this;
      var returnArray = [];
      testQuestions.forEach(function (item, index) {
        var questionNumber = item.get('questionOrder');
        var pctCorrect = 0;
        if (numStudents == 0) {
          pctCorrect = 0;
        } else {
          var questionId = item.get('question').get('id');
          var numPoints = 1;
          if (item.get('question.secondPartQuestion') && item.get('question.secondPartQuestion.numCorrectAnswers') > 0) {
            numPoints += item.get('question.secondPartQuestion.numCorrectAnswers');
          }
          var numCorrect = 0;
          admin.get('studentTestAdministrations').forEach(function (item1, index1) {
            var studentAnswer = item1.get('studentAnswers').findBy('questionId', parseInt(questionId));
            if (studentAnswer && studentAnswer.get('isCorrect')) {
              numCorrect++;
              if (item.get('question.secondPartQuestion') && item.get('question.secondPartQuestion.numCorrectAnswers') > 0) {
                var studentSecondPartAnswers = item1.get('studentSecondPartAnswers').filterBy('questionId', parseInt(questionId));
                if (studentSecondPartAnswers && studentSecondPartAnswers.length <= item.get('question.secondPartQuestion.numCorrectAnswers')) {
                  for (var i = 0; i < studentSecondPartAnswers.length; i++) {
                    if (studentSecondPartAnswers.objectAt(i).get('isCorrect')) numCorrect++;
                  }
                }
              }
            }
          });
          var pctCorrect = Math.round(numCorrect / (numStudents * numPoints) * 100);
        }
        var object = { "questionNumber": questionNumber, "pctCorrect": pctCorrect };
        returnArray.pushObject(object);
      });
      return returnArray;
    }),

    strongestScore: Ember.computed('questionBreakdown', function () {
      if (this.get('questionBreakdown').get('length') > 0) {
        var questions = this.get('questionBreakdown').sortBy('pctCorrect');
        return questions.get('lastObject')['pctCorrect'];
      }
    }),

    strongestQuestions: Ember.computed('questionBreakdown', function () {
      if (this.get('questionBreakdown').get('length') > 0) {
        var questions = this.get('questionBreakdown').sortBy('questionNumber').sortBy('pctCorrect');
        var questionNumbers = questions.get('lastObject')['questionNumber'];
        var label = "Question ";

        var l = questions.length - 1;
        var done = false;
        if (l < 1) done = true;
        while (!done && l > 0) {
          if (questions[l]['pctCorrect'] == questions[l - 1]['pctCorrect']) {
            label = "Questions ";
            questionNumbers = questions[l - 1]['questionNumber'] + ", " + questionNumbers;
            l--;
          } else {
            done = true;
          }
        }

        return label + questionNumbers;
      }
    }),

    weakestScore: Ember.computed('questionBreakdown', function () {
      if (this.get('questionBreakdown').get('length') > 0) {
        var questions = this.get('questionBreakdown').sortBy('pctCorrect');
        return questions.get('firstObject')['pctCorrect'];
      }
    }),

    weakestQuestions: Ember.computed('questionBreakdown', function () {
      if (this.get('questionBreakdown').get('length') > 0) {
        var questions = this.get('questionBreakdown').sortBy('questionNumber').sortBy('pctCorrect');
        var questionNumbers = questions.get('firstObject')['questionNumber'];
        var label = "Question ";

        var l = 0;
        var done = false;
        if (questions.length < 2) done = true;
        while (!done && l < questions.length - 1) {
          if (questions[l]['pctCorrect'] == questions[l + 1]['pctCorrect']) {
            label = "Questions ";
            questionNumbers += ", " + questions[l + 1]['questionNumber'];
            l++;
          } else {
            done = true;
          }
        }

        return label + questionNumbers;
      }
    }),

    strongestScoreLabel: Ember.computed('strongestQuestions', function () {
      if (this.get('strongestQuestions')) {
        if (this.get('strongestQuestions').indexOf(',') > 0) return "Strongest Scores";else {
          return "Strongest Score";
        }
      }
    }),

    weakestScoreLabel: Ember.computed('weakestQuestions', function () {
      if (this.get('weakestQuestions')) {
        if (this.get('weakestQuestions').indexOf(',') > 0) return "Weakest Scores";else {
          return "Weakest Score";
        }
      }
    }),

    teamSortProperties: ['id'],
    teamsSorted: Ember.computed.sort('teams', 'teamSortProperties'),

    teamA: Ember.computed.alias('teamsSorted.firstObject'),

    teamB: Ember.computed.alias('teamsSorted.lastObject'),

    currentQuestionPointsEarned: Ember.computed('studentTestAdministrations.@each.numPointsEarnedCurrent', 'studentTestAdministrations.[]', 'testQuestion.id', function () {
      return Ember.get(this, 'studentTestAdministrations').reduce(function (pointsEarned, sta) {
        return pointsEarned + Ember.get(sta, 'numPointsEarnedCurrent');
      }, 0);
    }),

    currentQuesitonPercentCorrect: Ember.computed('currentQuestionPointsEarned', function () {
      var pointsEarned = Ember.get(this, 'currentQuestionPointsEarned') || 0,
          pointsPossible = Ember.get(this, 'testQuestion.question.numPoints') * Ember.get(this, 'studentTestAdministrations').filterBy('currentlyInQuiz').get('length') || 0;

      return pointsPossible != 0 && pointsEarned != 0 ? Math.round(pointsEarned / pointsPossible * 100) : 0;
    })
  });
});
define('ohs/mixins/teacher-authorize-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    beforeModel: function beforeModel() {
      var sesObj = this.get('session');
      if (!sesObj) return;

      var roles = sesObj.session.content.authenticated.roles || [];
      if (roles.includes("Teacher")) {
        return true;
      } else {
        this.controllerFor('application').set('errorStatusCode', 403);
        this.controllerFor('application').set('errorDetailMessage', 'You don\'t have access to this request');
        this.transitionTo('error');
        return false;
      }
    }

  });
});
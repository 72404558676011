define('ohs/routes/teacher', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),

        model: function model() {
            var roles = Ember.get(this, 'session.session.content.authenticated.roles');
            var model = '';

            if (roles.includes('Tutor')) {
                model = 'tutor';
            } else if (roles.includes('Teacher')) {
                model = 'teacher';
            }

            return Ember.RSVP.hash({
                user: this.store.find(model, this.modelFor('application').userId),
                studentGroups: this.store.findAll('student-group')
            });
        }
    });
});
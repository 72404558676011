define('ohs/models/parent', ['exports', 'ohs/models/user', 'ember-data'], function (exports, _user, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _user.default.extend({
    code: _emberData.default.attr('string'),
    students: _emberData.default.hasMany('student'),
    resourceBookmarks: _emberData.default.hasMany('resource-bookmark')
  });
});
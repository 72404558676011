define('ohs/routes/student-question', ['exports', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/signalr-student-route', 'ohs/mixins/tip-mixin'], function (exports, _reset, _noNavigation, _authenticatedRouteMixin, _signalrStudentRoute, _tipMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reset.default, _noNavigation.default, _authenticatedRouteMixin.default, _signalrStudentRoute.default, _tipMixin.default, {
        signalR: Ember.inject.service('signal-r'),

        setupController: function setupController(controller, model) {
            this._super.apply(this, arguments);
            this.get('signalR').administrationId = Ember.get(model, 'studentTestAdministration.administration.id');
            this.get('signalR').joinAdministration(Ember.get(model, 'studentTestAdministration.administration.id'));
        },


        titleToken: function titleToken(model) {
            return model.studentTestAdministration.get('administration.quiz.quizName');
        },

        beforeModel: function beforeModel(transition) {
            this._super.apply(this, arguments);
            var studentTestId = transition.handlerInfos[1].params.student_test_id;
            var hostAddress = this.get('store').adapterFor('application').get('host');
            var self = this;

            var sta = this.store.peekRecord('studentTestAdministration', studentTestId);

            if (sta && sta.get('administration').get('testTypeId') == 1) {
                //If Student-Controlled
                self.transitionTo('student-quiz-room', studentTestId);
            } else if (sta && sta.get('cancelled')) {
                //do nothing
            } else {
                //get the administration id and join signalR
                Ember.$.getJSON(hostAddress + '/studentTestAdministrations/getAdministrationId/' + studentTestId).fail(function (error) {
                    self.controllerFor('application').set('errorStatusCode', error.status);
                    self.transitionTo('error');
                });

                //check administration already closed or not
                Ember.$.getJSON(hostAddress + '/administrations/IsAdministrationCompleted?studentTestId=' + studentTestId).done(function (response) {
                    if (response)
                        //administration closed - redirect to result
                        self.transitionTo('student-results', studentTestId, '1');
                }).fail(function (error) {
                    self.controllerFor('application').set('errorStatusCode', error.status);
                    self.transitionTo('error');
                });
            }
        },
        model: function model(params) {
            var _this = this;

            var store = Ember.get(this, 'store');
            return store.queryRecord('quiz', { studentTestAdministrationId: params.student_test_id }).then(function (quiz) {
                return Ember.RSVP.hash({
                    studentTestAdministration: _this.store.findRecord('studentTestAdministration', params.student_test_id),
                    teacher: store.findRecord('user', Ember.get(quiz, 'createUserId')),
                    testQuestions: store.query('testQuestion', { studentTestId: params.student_test_id }),

                    testQuestionSorting: ['questionOrder'],
                    sortedTestQuestions: Ember.computed.sort('testQuestions', 'testQuestionSorting'),
                    currentQuestionIndex: Ember.computed.alias('studentTestAdministration.administration.currentQuestionIndex'),

                    testQuestion: Ember.computed('currentQuestionIndex', function () {
                        return Ember.get(this, 'sortedTestQuestions').objectAt(Ember.get(this, 'currentQuestionIndex'));
                    }),

                    percentInitial: Ember.computed('testQuestion.questionOrder', 'studentTestAdministration.administration.quiz.testQuestions.length', function () {
                        var increment = 100 / this.studentTestAdministration.get('administration').get('quiz').get('testQuestions').get('length');
                        return (this.studentTestAdministration.get('administration').get('currentQuestionIndex') + 1) * increment - increment;
                    }),

                    percentCurrent: Ember.computed('testQuestion.questionOrder', 'studentTestAdministration.administration.quiz.testQuestions.length', function () {
                        var increment = 100 / this.studentTestAdministration.get('administration').get('quiz').get('testQuestions').get('length');
                        return (this.studentTestAdministration.get('administration').get('currentQuestionIndex') + 1) * increment;
                    }),

                    displayBelow: Ember.computed('studentTestAdministration.administration.quiz.testQuestions.@each.question', 'loadFinished', function () {
                        var pageWidth = $('.page-content').width();
                        if (pageWidth > 700) {
                            var availableWidth;
                            if (pageWidth > 960) {
                                availableWidth = pageWidth * 0.94875 / 3 - 10;
                            } else {
                                availableWidth = pageWidth * 0.965 * (5 / 12) - 10;
                            }
                            var tq = this.studentTestAdministration.get('administration').get('quiz').get('testQuestions').sortBy('questionOrder').toArray()[this.studentTestAdministration.get('administration').get('currentQuestionIndex')];
                            if (tq && tq.get('question.minimumAnswerWidth') && tq.get('question.minimumAnswerWidth') > availableWidth) {

                                return 'display-below';
                            }
                        }
                    }),

                    message: "",
                    messageClass: "",
                    iconClass: "",
                    transitionUrl: "",
                    redirectUrl: "",
                    currentColor: 'red',
                    currentTool: 'draw',
                    backgroundColor: 'transparent',

                    answersDisabled: Ember.computed('studentTestAdministration.administration.answersLocked', function () {
                        return this.studentTestAdministration.get('administration').get('answersLocked');
                    })
                });
            });
        },


        afterModel: function afterModel(model) {
            if (model.studentTestAdministration.get('cancelled')) {
                model.loadFinished = false;
                model.showCancelAlert = true;
                model.alertMessage = "Your teacher has cancelled this quiz!";
            } else {
                model.studentTestAdministration.set('currentlyInQuiz', true);
                model.studentTestAdministration.save();

                var tip = this.store.peekRecord('tip', 8);
                if (tip) {
                    model.showTip = true;
                    model.tipText = tip.get('tipText');
                }

                if (model.studentTestAdministration.get('administration').get('isComplete')) {
                    this.transitionTo('student-results', model.studentTestAdministration.get('id'), '1');
                }

                //Check if this is a touch device
                if (this.isTouchDevice()) {
                    Ember.run.later(function () {
                        $('.closed-toolbox').addClass('fixed');
                    }, 800);
                }

                var self = this;
                Ember.run.later(function () {
                    self.set('currentModel.loadFinished', true);
                    $('html, body').animate({
                        scrollTop: $('.page-header').offset().top
                    }, 800);
                }, 500);
            }
        },

        isTouchDevice: function isTouchDevice() {
            return 'ontouchstart' in window // works on most browsers
            || navigator.maxTouchPoints; // works on IE10/11 and Surface
        },

        actions: {
            //signalr actions
            changeQuestion: function changeQuestion(questionIndex, answersLocked) {
                this._super.apply(this, arguments);
                if (this.get('currentModel.studentTestAdministration.administration.currentQuestionIndex') != questionIndex) {
                    var canvas = $('#myCanvas')[0];
                    if (canvas) {
                        this.set('currentModel.testQuestion.scratchpadContent', canvas.toDataURL());
                    }
                    this.set('currentModel.showScratchpad', false);
                    this.set('currentModel.loadFinished', false);
                    this.get('currentModel').studentTestAdministration.get('administration').set('currentQuestionIndex', questionIndex);
                    this.set('currentModel.answersDisabled', answersLocked);
                    this.set('currentModel.studentTestAdministration.administration.answersLocked', answersLocked);

                    var self = this;
                    setTimeout(function () {
                        self.set('currentModel.loadFinished', true);
                    }, 500);
                } else {
                    this.set('currentModel.answersDisabled', answersLocked);
                    this.set('currentModel.studentTestAdministration.administration.answersLocked', answersLocked);
                }
            },
            endQuiz: function endQuiz(administrationId) {
                this._super.apply(this, arguments);
                this.set('currentModel.loadFinished', false);
                //Leave administration
                this.get('signalR').leaveAdministration(administrationId);

                this.transitionTo('student-results', this.get('currentModel').studentTestAdministration.get('id'), '1');
            },
            cancelQuiz: function cancelQuiz(administrationId) {
                this._super.apply(this, arguments);
                this.set('currentModel.studentTestAdministration.cancelled', true);
            },
            signalRConnectionAlert: function signalRConnectionAlert(message, messageClass, iconClass) {
                this._super.apply(this, arguments);
                Ember.set(this.get('currentModel'), 'message', message);
                Ember.set(this.get('currentModel'), 'messageClass', messageClass);
                Ember.set(this.get('currentModel'), 'iconClass', iconClass);
            },

            //end signalr actions

            loading: function loading(transition, originRoute) {
                if (originRoute != this) return true;
            },

            removeFocus: function removeFocus() {
                setTimeout(function () {
                    alert('it happened');
                }, 3000);
            },

            selectAnswer: function selectAnswer(answerChoiceId, studentAnswerId) {
                this.set('currentModel.answersDisabled', true);
                var self = this;
                var chosenAnswer = this.get('currentModel.testQuestion').get('question').get('answerOptions').findBy('id', answerChoiceId.toString());
                if (!studentAnswerId) {
                    var answer = this.store.createRecord('studentAnswer', {
                        answerId: answerChoiceId,
                        questionId: parseInt(this.get('currentModel.testQuestion').get('question').get('id')),
                        studentTestId: parseInt(this.currentModel.studentTestAdministration.get('id')),
                        isCorrect: chosenAnswer.get('isCorrect'),
                        studentTestAdministration: this.currentModel.studentTestAdministration
                    });
                    var currentModel = this.currentModel;
                    answer.save().then(function (response) {
                        currentModel.studentTestAdministration.get('studentAnswers').pushObject(answer);
                        self.set('currentModel.answersDisabled', self.get('currentModel.studentTestAdministration.administration.answersLocked'));
                    }, function (response) {
                        answer.rollbackAttributes();
                        if (response && response.errors && response.errors.length > 0) {
                            self.set('currentModel.answersDisabled', true);
                            self.set("currentModel.answersLockedMessage", response.errors[0].detail);
                        } else {
                            self.set("currentModel.showAlert", true);
                            self.set('currentModel.answersDisabled', self.get('currentModel.studentTestAdministration.administration.answersLocked'));
                        }
                    });
                } else {
                    var _answer = this.store.peekRecord('studentAnswer', studentAnswerId);
                    _answer.set('answerId', answerChoiceId);
                    _answer.set('isCorrect', chosenAnswer.get('isCorrect'));
                    _answer.save().then(function (response) {
                        self.set('currentModel.answersDisabled', self.get('currentModel.studentTestAdministration.administration.answersLocked'));
                    }, function (response) {
                        _answer.rollbackAttributes();
                        if (response && response.errors && response.errors.length > 0) {
                            self.set('currentModel.answersDisabled', true);
                            self.set("currentModel.answersLockedMessage", response.errors[0].detail);
                        } else {
                            self.set("currentModel.showAlert", true);
                            self.set('currentModel.answersDisabled', self.get('currentModel.studentTestAdministration.administration.answersLocked'));
                        }
                    });
                }
            },

            //Hide alert box
            hideAlert: function hideAlert() {
                this.set("currentModel.showAlert", false);
            },

            gotoQuizzes: function gotoQuizzes() {
                //Leave administration
                this.get('signalR').leaveAdministration(this.get('currentModel.studentTestAdministration.administration.id'));
                this.transitionTo('student-quizzes');
            },

            //Hide Answers Locked alert box
            hideAnswersLockedAlert: function hideAnswersLockedAlert() {
                this.set("currentModel.answersLockedMessage", '');
            },

            //decide whether or not the transition should occur.
            willTransition: function willTransition(transition) {
                if (transition.targetName == "student-question" || transition.targetName == "student-results" || this.get('currentModel.studentTestAdministration.cancelled') || transition.targetName == "student-self-guided-question") {
                    return true;
                }

                //redirect to error page when an error occur in this page like network failure
                if (transition.targetName == "error") {
                    return true;
                }

                //redirectUrl is used to hold the current url to check whether its called from redirect action method or not
                var redirectUrl = this.get('currentModel.redirectUrl');
                if (redirectUrl && redirectUrl.targetName == transition.targetName) {
                    return true;
                } else {
                    this.set('currentModel.transitionUrl', transition);
                    this.set('currentModel.confirmTransition', true);
                    transition.abort();
                }
            },
            redirect: function redirect() {
                //Leave administration
                this.get('signalR').leaveAdministration(this.get('currentModel.studentTestAdministration.administration.id'));

                this.set('currentModel.confirmTransition', false);
                this.set('currentModel.redirectUrl', this.get('currentModel.transitionUrl'));
                this.transitionTo(this.get('currentModel.transitionUrl.targetName'));
            },


            showScratchpad: function showScratchpad() {
                this.set('currentModel.showScratchpad', true);
            },

            closeScratchpad: function closeScratchpad(dataUrl) {
                this.set('currentModel.testQuestion.scratchpadContent', dataUrl);
                this.set('currentModel.showScratchpad', false);
            },

            closeTip: function closeTip() {
                this.destroyTip(8);
                this.set('currentModel.showTip', false);
            }
        }
    });
});
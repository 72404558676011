define('ohs/serializers/mission-control/assessment-item', ['exports', 'ohs/serializers/mission-control', 'ember-data'], function (exports, _missionControl, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
    exports.default = _missionControl.default.extend(EmbeddedRecordsMixin, {
        attrs: {
            assessmentItemQuestions: { embedded: 'always' }
        }
    });
});
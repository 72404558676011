define('ohs/routes/student/mission-control/start-mission', ['exports', 'ohs/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      var userId = Ember.get(this, 'token.userId');
      return Ember.RSVP.hash({
        user: this.store.findRecord('student', userId),
        studentMission: this.store.findRecord('studentMission', params.student_mission_id),
        spaceships: this.store.query('mission-control/student-spaceship', { studentId: userId })
      });
    },
    afterModel: function afterModel(model) {
      var studentMissionId = Ember.get(model, 'studentMission.id');
      var isCompleted = Ember.get(model, 'studentMission.isCompleted');

      // already completed the quiz, kick them to the results page
      if (isCompleted) {
        this.transitionTo('student.mission-control.mission-results-student', studentMissionId);
      }
    },
    setupController: function setupController() {
      this._super.apply(this, arguments);
      this.controllerFor('application').set('showSecondaryNav', false);
      this.controllerFor('application').set('student', true);
    }
  });
});
define('ohs/routes/teacher/quiz', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/signalr-teacher-route'], function (exports, _authenticatedRouteMixin, _signalrTeacherRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _signalrTeacherRoute.default, {
        //services
        signalR: Ember.inject.service('signal-r'),

        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            // Implement your custom setup after
            this.controllerFor('application').set('showSecondaryNav', false);
            this.controllerFor('application').set('teacher', true);
        },

        model: function model(params) {
            var store = this.get('store');

            return Ember.RSVP.hash({
                isTransitionApproved: false,
                isTeacherView: false,
                showDeleteAdministrationAlert: false,
                showAlert: false,
                alertMessage: '',

                administration: store.findRecord('administration', params.administration_id),
                studentTestAdministrations: store.query('student-test-administration', { administrationId: params.administration_id }),
                testQuestions: store.query('test-question', { administrationId: params.administration_id }),

                joinedStudents: Ember.computed('studentTestAdministrations.[]', 'studentTestAdministrations.@each.currentlyInQuiz', function () {
                    return Ember.get(this, 'administration.studentTestAdministrations').filterBy('currentlyInQuiz', true);
                }),

                percentComplete: Ember.computed('administration.testQuestion.id', function () {
                    var total = Ember.get(this, 'administration.quiz.numQuestions');
                    var current = Ember.get(this, 'administration.currentQuestionIndex') + 1;
                    return Math.round(current / total * 100);
                }),

                percentInitial: Ember.computed('administration.testQuestion.id', function () {
                    var total = Ember.get(this, 'administration.quiz.numQuestions');
                    var current = Ember.get(this, 'administration.currentQuestionIndex');
                    return Math.round(current / total * 100);
                }),

                //report an issue stuff
                validIssue: Ember.computed('questionIssue', 'questionIssueComments', function () {
                    return Ember.get(this, 'questionIssue') && Ember.get(this, 'questionIssue') != "Other" || Ember.get(this, 'questionIssue') == "Other" && Ember.get(this, 'questionIssueComments').trim();
                }),

                //scratchpad options
                currentColor: 'red',
                currentTool: 'draw',
                backgroundColor: 'transparent'
            });
        },
        afterModel: function afterModel(model) {
            //Set adminstration id to singal-r service property
            this.get('signalR').administrationId = "t" + model.administration.get('id');
            //join administration
            this.get('signalR').joinAdministration("t" + model.administration.get('id'));
        },


        actions: {
            //signal-r actions
            joinStudent: function joinStudent(studentTestAdministrationId) {
                this.store.findRecord('student-test-administration', studentTestAdministrationId);
            },
            changeQuestion: function changeQuestion(questionIndex, answersLocked, administrationId) {
                this._super.apply(this, arguments);
                var model = Ember.get(this, 'currentModel');
                if (administrationId == Ember.get(model, 'administration.id')) {
                    var canvas = $('#myCanvas')[0];
                    if (canvas) {
                        Ember.set(model, 'administration.testQuestion.scratchpadContent', canvas.toDataURL());
                    }
                    Ember.set(model, 'showScratchpad', false);
                    if (!Ember.get(model, 'administration.isOpen')) {
                        Ember.set(model, 'administration.isOpen', true);
                        //switch is here for when we move the other test types to this style of nesting.
                        switch (Ember.get(model, 'administration.testTypeId')) {
                            case 3:
                                this.transitionTo('teacher.quiz.game-mode.in-progress.classroom-view', Ember.get(model, 'administration.id'));break;
                        }
                    } else {
                        Ember.set(model, 'administration.currentQuestionIndex', questionIndex);
                    }
                }
            },
            signalRConnectionAlert: function signalRConnectionAlert() {
                this._super.apply(this, arguments);
                this.set('currentModel.message', message);
                this.set('currentModel.messageClass', messageClass);
                this.set('currentModel.iconClass', iconClass);
            },

            //end signal-r actions

            retryTransition: function retryTransition() {
                //get(this, 'signalR').leaveAdministration("t" + get(this, 'currentModel.administration.id'));
                Ember.set(this, 'currentModel.confirmTransition', false);
                Ember.set(this, 'currentModel.isTransitionApproved', true);
                Ember.get(this, 'currentModel.transition').retry();
            },
            reportIssue: function reportIssue(question, issue, comments) {
                var _this = this;

                var baseUrl = Ember.get(Ember.get(this, 'store').adapterFor('application'), 'host');
                var questionId = Ember.get(question, 'id');
                var url = baseUrl + '/accounts/reportQuestion?questionId=' + questionId + '&issue=' + issue;

                if (!comments.trim() == '') {
                    url = url.concat('&comment=' + comments);
                }

                Ember.$.post(url).then(function () {
                    Ember.set(_this.modelFor('teacher.quiz'), 'showConfirm', true);
                    Ember.set(_this.modelFor('teacher.quiz'), 'confirmationMessage', 'Your report has been received and is currently being reviewed. If we have any follow up questions, someone will be in touch!');
                }, function () {
                    Ember.set(_this.modelFor('teacher.quiz'), 'showAlert', true);
                    Ember.set(_this.modelFor('teacher.quiz'), "alertMessage", "There was an error sending your report. Please check your internet connection and try again.");
                });
                Ember.set(this, 'currentModel.questionIssue', null);
                Ember.set(this, 'currentModel.questionIssueComments', '');
            },
            validateIssue: function validateIssue(issue, comments) {
                if (!issue) {
                    Ember.set(this, 'currentModel.issueMessage', 'Please tell us what is wrong with this resource.');
                    return false;
                }

                if (issue.trim().toLowerCase() == 'other' && !comments.trim()) {
                    Ember.set(this, 'currentModel.issueMessage', 'Please add some additional information about what is wrong with this resource.');
                    return false;
                }

                Ember.set(this, 'currentModel.issueMessage', '');
                return true;
            },
            showScratchpad: function showScratchpad() {
                this.toggleProperty('currentModel.showScratchpad');
            },
            confirmConvert: function confirmConvert() {
                this.toggleProperty('currentModel.showChangeInTestTypeDialog');
            },
            convertToSelfGuided: function convertToSelfGuided() {
                var _this2 = this;

                this.toggleProperty('currentModel.showChangeInTestTypeDialog');
                Ember.set(this, 'currentModel.isProcessing', false);

                //convert to self-guided
                var administration = Ember.get(this, 'currentModel.administration');
                Ember.set(administration, 'testTypeId', 1);
                Ember.set(administration, 'answersLocked', false);
                administration.save().then(function (result) {
                    Ember.set(_this2, 'currentModel.isProcessing', false);
                    _this2.transitionTo('teacher-self-guided-results', Ember.get(administration, 'id'));
                }, function (error) {
                    administration.rollbackAttributes();
                    Ember.set(_this2, 'currentModel.isProcessing', false);
                    Ember.set(_this2.modelFor('teacher.quiz'), 'showAlert', true);
                    Ember.set(_this2.modelFor('teacher.quiz'), 'alertMessage', 'Failed to convert to self guided quiz. Please check your internet connection and try again.');
                });
            },
            deleteQuiz: function deleteQuiz() {
                var _this3 = this;

                var administration = Ember.get(this, 'currentModel.administration');
                this.toggleProperty('currentModel.showDeleteAdministrationAlert');

                administration.destroyRecord().then(function () {
                    Ember.get(_this3, 'signalR').leaveAdministration('t' + Ember.get(administration, 'id'));
                    _this3.transitionTo('teacher-quizzes');
                }, function () {
                    administration.rollbackAttributes();
                    _this3.toggleProperty('currentModel.showAlert');
                    Ember.set(_this3, 'currentModel.alertMessage', 'Failed to end the quiz. Please check your internet connection and try again.');
                });
            },
            endQuiz: function endQuiz() {
                var _this4 = this;

                var user = Ember.get(this.modelFor('application'), 'user');
                var administration = Ember.get(this, 'currentModel.administration');
                var baseUrl = Ember.get(Ember.get(this, 'store').adapterFor('application'), 'host');
                Ember.set(this, 'currentModel.isProcessing', true);
                Ember.$.getJSON(baseUrl + '/administrations/hasAnyStudentAnswers?administrationId=' + Ember.get(administration, 'id') + '&userId=' + encodeURIComponent(Ember.get(user, 'id'))).then(function (hasAnswers) {
                    if (hasAnswers) {
                        Ember.set(administration, 'isOpen', false);
                        Ember.set(administration, 'isComplete', true);
                        Ember.set(administration, 'endTime', new Date());

                        administration.save().then(function (savedAdministration) {
                            //magic "t"?
                            //im assuming it stands for "teacher", but we all know what assuming does.
                            Ember.get(_this4, 'signalR').leaveAdministration('t' + Ember.get(administration, 'id'));
                            _this4.transitionTo('teacher-results-1', Ember.get(administration, 'id'), '1');
                        }, function () {
                            administration.rollbackAttributes();
                            Ember.set(_this4, 'currentModel.showAlert', true);
                            Ember.set(_this4, 'currentModel.alertMessage', 'Could not end the quiz. Please check your internet connection and try again.');
                            Ember.set(_this4, 'currentModel.isProcessing', false);
                        });
                    }
                    //missing results, we should delete the administration
                    else {
                            Ember.set(_this4, 'currentModel.showDeleteAdministrationAlert', true);
                            _this4.toggleProperty('currentModel.isProcessing');
                        }
                }, function () {
                    _this4.toggleProperty('currentModel.isProcessing');
                });
            }
        }

    });
});
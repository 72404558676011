define('ohs/authorizers/custom', ['exports', 'ember-simple-auth/authorizers/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        session: Ember.inject.service(),
        authorize: function authorize(sesObj, requestOptions) {
            //var accessToken = this.get('session.content.secure.token');
            //var sesObj = this.get('session');
            var accessToken = sesObj.token;
            if (this.get('session.isAuthenticated') && !Ember.isEmpty(accessToken)) {
                requestOptions('Authorization', 'Bearer ' + accessToken);
            }
        }
    });
});
define('ohs/routes/roster-error-update', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Roster Error Update',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          } else if (roles.indexOf("Parent") > 0) {
            this.controllerFor('application').set('parent_many_children', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
          this.controllerFor('application').set('parent_many_children', '');
        }
      }
    },

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      //Find the previous route and redirect to it after completing the task
      var previousRoutes = this.router.router.currentHandlerInfos;
      var previousRoute = previousRoutes && previousRoutes.pop();
      if (previousRoute) {
        this.set('lastVisitedRoute', previousRoute.name);
      }

      if (transition.handlerInfos[1].params.roster_error_Id > 0) {
        var errorRoster = this.store.peekRecord('rosterCreationError', transition.handlerInfos[1].params.roster_error_Id);
        if (!errorRoster) {
          if (this.get('lastVisitedRoute')) {
            this.transitionTo(this.get('lastVisitedRoute'));
          } else {
            this.transitionTo('upload-account-creation-template');
          }
        }
      }
    },

    model: function model(params) {
      if (params) {
        return Ember.RSVP.hash({
          rosterCreationError: this.store.peekRecord('rosterCreationError', params.roster_error_Id)
        });
      }
    },


    actions: {

      saveRoster: function saveRoster() {
        var self = this;
        if (self.validate()) {
          var errorRoster = this.store.peekRecord('rosterCreationError', self.currentModel.rosterCreationError.id);

          var account = this.store.peekAll('account');
          var accountId = account.objectAt(0).id;

          return Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/students/insertRoster?teacherEmail=' + self.currentModel.rosterCreationError.get('teacherEmail').trim() + '&studentUsername=' + self.currentModel.rosterCreationError.get('studentUsername').trim() + '&accountId=' + accountId + '&rosterCreationErrorId=' + self.currentModel.rosterCreationError.id).done(function (response) {
            if (response) {
              self.set("currentModel.showAlert", true);
              self.set("currentModel.alertMessage", response);
            }
          }).fail(function (response) {
            self.set("currentModel.validationErrors", response.responseText);
          });
        }
      },

      cancelRoster: function cancelRoster() {
        var self = this;
        self.currentModel.rosterCreationError.rollbackAttributes();
        if (this.get('lastVisitedRoute')) {
          this.transitionTo(this.get('lastVisitedRoute'));
        } else {
          this.transitionTo('upload-account-creation-template');
        }
      }

    },

    validate: function validate() {
      var self = this;
      var teacherEmail = self.currentModel.rosterCreationError.get('teacherEmail').trim();
      var studentId = self.currentModel.rosterCreationError.get('studentUsername').trim();

      // teacherEmail validation
      if (teacherEmail) {
        if (teacherEmail.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {

          // studentId validation
          if (studentId) {
            return true;
          } else {
            self.set('currentModel.validationErrors', 'You must enter student Id');
            return false;
          }
        } else {
          self.set('currentModel.validationErrors', 'Email address is not valid');
          return false;
        }
      } else {
        self.set('currentModel.validationErrors', 'You must enter an email address');
        return false;
      }
    }
  });
});
define('ohs/routes/purchase', ['exports', 'ohs/mixins/no-navigation'], function (exports, _noNavigation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_noNavigation.default, {
    titleToken: function titleToken() {
      return 'Purchase Kandoolu';
    },

    model: function model() {
      var self = this;

      return Ember.RSVP.hash({
        subscriptions: this.store.query('subscription', { AccountTypeId: 1 }),
        selectedSubscription: null,
        subscriptionEndDates: null,
        autoRenew: false,
        showStripe: false,
        email: '',
        showAlert: false,
        alertMessage: '',
        tick: 1,
        isLoading: false
      });
    },

    actions: {
      showAlertAction: function showAlertAction(message) {
        this.set('currentModel.alertMessage', message);
        this.set('currentModel.showAlert', true);
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
        this.set('currentModel.alertMessage', null);
      },

      showModalAction: function showModalAction() {
        this.set('currentModel.showModal', true);
      },

      proceed: function proceed() {
        this.transitionTo('sign-up', 'Teacher');
      },

      cancel: function cancel() {
        this.transitionTo('login');
      },

      purchaseFail: function purchaseFail(response) {
        var obj = JSON.parse(response);

        this.set('currentModel.isLoading', null);
        this.set('currentModel.alertMessage', obj.message);
        this.set('currentModel.showAlert', true);
      },

      purchaseSuccess: function purchaseSuccess(response) {
        this.set('currentModel.subscriptionEndDates', response);
        this.transitionTo('purchase-success');
      },

      SetSelectedSubscription: function SetSelectedSubscription(value) {
        var selected = this.store.peekRecord('subscription', value);
        this.set('currentModel.selectedSubscription', selected);
      }
    }
  });
});
define('ohs/models/notification', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = _emberData.default.Model.extend({
        created: attr('date'),
        updated: attr('date'),
        dismissed: attr('date'),
        modelId: attr('string'),
        modelName: attr('string'),
        notificationType: attr('string'),

        actor: belongsTo('user'),
        notifier: belongsTo('user')
    });
});
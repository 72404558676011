define('ohs/models/student-second-part-answer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    studentTestId: _emberData.default.attr('number'),
    questionId: _emberData.default.attr('number'),
    secondPartQuestionId: _emberData.default.attr('number'),
    secondPartAnswerId: _emberData.default.attr('number'),
    isCorrect: _emberData.default.attr('boolean'),
    studentTestAdministration: _emberData.default.belongsTo('student-test-administration')
  });
});
define('ohs/components/passage-quiz', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'section',
    classNames: ['quiz passage-picker'],
    complexities: [],
    lengths: [{
      text: 'Any Length',
      id: 1
    }, {
      text: 'Short',
      id: 2
    }, {
      text: 'Medium',
      id: 3
    }, {
      text: 'Long',
      id: 4
    }],

    actions: {
      selectPassage: function selectPassage(id) {
        var passage = this.get('passages').filter(function (p, i) {
          return p.id == id;
        })[0];

        this.get('passages').forEach(function (passage) {
          passage.set('selected', false);
        });

        passage.set('selected', true);
        this.sendAction('pickPassage', id);
      }
    }
  });
});
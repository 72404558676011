define('ohs/routes/teacher/quiz/teacher-led/lobby', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        actions: {
            startQuiz: function startQuiz() {
                var _this = this;

                var testTypeId = Ember.get(this, 'currentModel.administration.testTypeId');
                Ember.set(this, 'currentModel.administration.isOpen', true);
                Ember.set(this, 'currentModel.administration.startTime', new Date());
                Ember.get(this, 'currentModel.administration').save().then(function () {
                    //transition to next route
                    if (testTypeId == 2) _this.transitionTo('teacher.quiz.teacher-led.ela.classroom-view', Ember.get(_this, 'currentModel.administration.id'));else _this.transitionTo('teacher-class-view', Ember.get(_this, 'currentModel.administration.id'));
                }, function () {
                    Ember.set(_this.modelFor('teacher.quiz'), 'showAlert', true);
                    Ember.set(_this.modelFor('teacher.quiz'), 'alertMessage', 'Your quiz could not be started. Please check your internet connection and try again.');
                });
            }
        }
    });
});
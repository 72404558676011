define('ohs/models/student-test-administration', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    administrationId: attr('number'),
    userId: attr('string'),
    isFinished: attr('boolean', { defaultValue: false }),
    administration: belongsTo('administration'),
    student: belongsTo('student'),
    studentAnswers: hasMany('student-answer'),
    studentSecondPartAnswers: hasMany('student-second-part-answer'),
    team: belongsTo('team'),
    teamId: attr('number'),
    dateFinished: attr('date'),
    currentlyInQuiz: attr('boolean', { defaultValue: false }),
    invitedThrough: attr('string'),

    percentCorrect: attr('number'),
    numberPointsAvailable: attr('number'),
    numberPointsEarned: attr('number'),

    numPointsEarned: Ember.computed.alias('numberPointsEarned'),
    numPointsAvailable: Ember.computed.alias('numberPointsAvailable'),

    //computed fields
    unansweredQuestions: Ember.computed('studentAnswers.[]', 'studentSecondPartAnswers.[]', 'numAnswered', 'studentAnswers.@each.id', 'studentSecondPartAnswers.@each.id', 'studentAnswers.@each.answerId', 'studentSecondPartAnswers.@each.answerId', function () {
      var _this = this;

      return this.get('administration.quiz.testQuestions').reduce(function (unanswered, testQuestion) {
        var question = testQuestion.get('question');
        if (_this.get('studentAnswers').filter(function (x) {
          return x.get('questionId') == question.get('id');
        }) == 0) {
          unanswered = unanswered.concat(question);
        }

        //secondPart questions
        //a second part question is considered unanswered if there are less selected answers than there are correct choices
        if (question.get('secondPartQuestion.id')) {
          var allowedSecondPart = question.get('secondPartQuestion.secondPartAnswerOptions').filter(function (x) {
            return x.get('isCorrect');
          });
          if (_this.get('studentSecondPartAnswers').filter(function (x) {
            return x && x.get('secondPartQuestionId') == question.get('secondPartQuestion.id');
          }).length < allowedSecondPart.length) {
            unanswered = unanswered.concat(question);
          }
        }

        return unanswered;
      }, Ember.A([]));
    }),

    //Server-Side Operation
    numAnswered: Ember.computed.alias('studentAnswers.length'),

    //Server-Side Operation
    numCorrect: Ember.computed('studentAnswers.@each.answerId', function () {
      return this.get('studentAnswers').filterBy('isCorrect', true).get('length');
    }),

    //Server-Side Operation
    numSecondPartAnswered: Ember.computed('studentSecondPartAnswers.@each.secondPartAnswerId', function () {
      return this.get('studentSecondPartAnswers').get('length');
    }),

    //Server-Side Operation
    numSecondPartCorrect: Ember.computed('studentSecondPartAnswers.@each.secondPartAnswerId', function () {
      return this.get('studentSecondPartAnswers').filterBy('isCorrect', true).get('length');
    }),

    //Server-Side Operation
    numPointsEarnedCurrent: Ember.computed('administration.testQuestion.id', 'studentAnswers.@each.answerId', 'studentSecondPartAnswers.@each.secondPartAnswerId', function () {
      var question = Ember.get(this, 'administration.testQuestion.question'),
          studentSecondPartAnswers = Ember.get(this, 'studentSecondPartAnswers').filterBy('questionId', parseInt(Ember.get(question, 'id'))),
          studentFirstPartAnswer = Ember.get(this, 'studentAnswers').findBy('questionId', parseInt(Ember.get(question, 'id'))),
          allowedNumberSecondPartAnswers = Ember.get(question, 'secondPartQuestion.numCorrectAnswers'),
          numCorrect = 0;

      //is first part correct?
      if (studentFirstPartAnswer && Ember.get(studentFirstPartAnswer, 'isCorrect')) {
        numCorrect++;

        //did the student select <= how many correct secondPartAnswers there are?
        //if there where no second part answers, we will just +=0
        if (studentSecondPartAnswers && studentSecondPartAnswers.length <= allowedNumberSecondPartAnswers) {
          numCorrect += studentSecondPartAnswers.filterBy('isCorrect').get('length');
        }
      }

      return numCorrect;
    }),

    //Server-Side Operation
    percentCorrectInProgress: Ember.computed('studentAnswers.@each.answerId', 'isFinished', function () {
      if (this.get('isFinished')) {
        return this.get('percentCorrect');
      } else {
        var numQuestions = this.get('numAnswered');
        if (this.get('studentSecondPartAnswers.length') && this.get('studentSecondPartAnswers.length') > 0) {
          var numCorrect = 0;
          for (var i = 0; i < this.get('studentAnswers.length'); i++) {
            var questionId = this.get('studentAnswers').objectAt(i).get('questionId');
            var isCorrect = this.get('studentAnswers').objectAt(i).get('isCorrect');
            if (isCorrect) {
              numCorrect++;
            }
            var secCorrectAnswerlength = this.get('studentSecondPartAnswers').filterBy('questionId', questionId).filterBy('isCorrect', true).get('length');
            var studentSecAnswerLength = this.get('studentSecondPartAnswers').filterBy('questionId', questionId).get('length');
            var secAnswerlength = this.get('administration.quiz.testQuestions').findBy('question.id', questionId.toString()).get('question.secondPartQuestion.numCorrectAnswers');
            if (secAnswerlength && secAnswerlength > 0) {
              if (studentSecAnswerLength <= secAnswerlength && isCorrect) {
                numCorrect += secCorrectAnswerlength;
                numQuestions += secAnswerlength;
              } else {
                numQuestions += secAnswerlength;
              }
            }
          }
          return Math.round(numCorrect / numQuestions * 100);
        } else {
          return Math.round(this.get('numCorrect') / this.get('numAnswered') * 100);
        }
      }
    }),

    //Shouldn't be here 
    //TODO: REFACTOR. This is handled by the student relationship, anything that uses this property should reference that directly.
    studentNameReversed: Ember.computed('student.fullNameReversed', function () {
      return this.get('student').get('fullNameReversed');
    }),

    //Shouldn't be here 
    //TODO: REFACTOR. This is handled by the student relationship, anything that uses this property should reference that directly.
    studentFullName: Ember.computed('student.fullNameReversed', function () {
      return this.get('student').get('firstName') + " " + this.get('student').get('lastName');
    }),

    //Use moment. 
    //TODO: REFACTOR. Remove this implement moment where it is used instead
    dateCompleted: Ember.computed('administration.endTime', function () {
      var date;
      if (this.get('administration').get('endTime')) {
        date = this.get('administration').get('endTime').toLocaleDateString();
      } else if (this.get('administration').get('startTime')) {
        date = this.get('administration').get('startTime').toLocaleDateString();
      } else {
        return '';
      }
      var today = new Date();
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (today.toLocaleDateString() == date) return 'Completed Today';else if (yesterday.toLocaleDateString() == date) {
        return 'Completed Yesterday';
      } else return 'Completed ' + date;
    }),

    //Use moment.
    //TODO: REFACTOR. Remove this, implement moment where it is used instead
    dateCompletedDateOnly: Ember.computed('administration.endTime', function () {
      var date;
      if (this.get('administration').get('endTime')) {
        date = this.get('administration').get('endTime').toLocaleDateString();
      } else if (this.get('administration').get('startTime')) {
        date = this.get('administration').get('startTime').toLocaleDateString();
      } else {
        return '';
      }
      var today = new Date();
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (today.toLocaleDateString() == date) return 'Today';else if (yesterday.toLocaleDateString() == date) {
        return 'Yesterday';
      } else return date;
    }),

    //TODO: REFACTOR. Probably unneeded.
    dateCompletedSort: Ember.computed('administration.endTime', function () {
      var date;
      if (this.get('administration').get('endTime')) {
        date = this.get('administration').get('endTime');
      } else {
        date = this.get('administration').get('startTime');
      }
      return date;
    }),

    //TODO: REFACTOR. This is just controlling the score level, this should be contained in either a mixin, helper, component, etc... Somewhere that it can be reused.
    scoreLevel: Ember.computed('percentCorrect', function () {
      if (this.get('percentCorrect')) {
        var p = this.get('percentCorrect');
        if (p < 60) {
          return 'Below Grade Level';
        } else if (p < 100) {
          return 'At Grade Level';
        } else {
          return 'Above Grade Level';
        }
      } else {
        return 'Below Grade Level';
      }
    }),

    //TODO: REFACTOR. This is just controlling an icon. We should have an "icon" component for this. Plus the above suggestion would help fix this.
    scoreLevelIcon: Ember.computed('percentCorrect', function () {
      if (this.get('percentCorrect')) {
        var p = this.get('percentCorrect');
        if (p < 60) {
          return 'icon icon-grade-below';
        } else if (p < 100) {
          return 'icon icon-grade-at';
        } else {
          return 'icon icon-grade-above';
        }
      } else {
        return 'icon icon-grade-below';
      }
    })

  });
});
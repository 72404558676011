define('ohs/components/game-mode-results', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['game-mode-results-wrapper'],
        didAnimationPlay: false,
        didElementInsert: false,

        triggerAnimation: Ember.observer('winner', function () {
            var _this = this;

            Ember.run.once(function () {
                _this.playAnimation();
            });
        }),

        //criteria: can only play once 
        //          has to be after didInsertElement 
        //          winner has to be 'winner-left' or 'winner-right'
        playAnimation: function playAnimation() {
            var canPlay = !Ember.get(this, 'didAnimationPlay') && Ember.get(this, 'didElementInsert');
            if (canPlay) {
                switch (Ember.get(this, 'winner')) {
                    case 'winner-left':
                        Ember.get(this, 'tlLeftWins').restart();
                        this.toggleProperty('didAnimationPlay');
                        break;
                    case 'winner-right':
                        Ember.get(this, 'tlRightWins').restart();
                        this.toggleProperty('didAnimationPlay');
                        break;
                }
            }
        },


        winner: Ember.computed('teamLeftScore', 'teamRightScore', function () {
            var leftScore = Ember.get(this, 'teamLeftScore') || 0;
            var rightScore = Ember.get(this, 'teamRightScore') || 0;

            if (leftScore == rightScore) {
                //its a draw
                return 'draw';
            } else if (leftScore > rightScore) {
                //left wins -> trigger animation
                return 'winner-left';
            } else if (leftScore < rightScore) {
                //right wins -> trigger animation
                return 'winner-right';
            }
        }),

        winningTeamColor: Ember.computed('winner', function () {
            switch (Ember.get(this, 'winner')) {
                case 'winner-left':
                    return Ember.get(this, 'teamLeftColor');
                    break;

                case 'winner-right':
                    return Ember.get(this, 'teamRightColor');
                    break;

                default:
                    return '';
                    break;
            }
        }),

        winningTeamName: Ember.computed('winner', function () {
            switch (Ember.get(this, 'winner')) {
                case 'winner-left':
                    return Ember.get(this, 'teamLeftName');
                    break;

                case 'winner-right':
                    return Ember.get(this, 'teamRightName');
                    break;

                default:
                    return 'We have a tie!';
                    break;
            }
        }),

        didInsertElement: function didInsertElement() {
            Ember.set(this, 'rLeft', this.$("#rover-left"));
            Ember.set(this, 'rRight', this.$("#rover-right"));
            Ember.set(this, 'rWheelsLeft', this.$("#rover-left .wheel"));
            Ember.set(this, 'rWheelsRight', this.$("#rover-right .wheel"));
            Ember.set(this, 'rEyeLeft', this.$("#rover-left .eye"));
            Ember.set(this, 'rEyeRight', this.$("#rover-right .eye"));
            Ember.set(this, 'rLeftBody', this.$("#rover-left .body"));
            Ember.set(this, 'rRightBody', this.$("#rover-right .body"));
            Ember.set(this, 'rLeftNeck', this.$("#rover-left .neck"));
            Ember.set(this, 'rRightNeck', this.$("#rover-right .neck"));
            Ember.set(this, 'rLeftHead', this.$("#rover-left .head"));
            Ember.set(this, 'rRightHead', this.$("#rover-right .head"));
            Ember.set(this, 'finishLineElements', this.$(".finish-line-left, .finish-line-right, .rope-wrapper, .rover .team-name"));
            Ember.set(this, 'winnerTagRight', this.$(".rover-right-wrapper .winner"));
            Ember.set(this, 'winnerTagLeft', this.$(".rover-left-wrapper .winner"));
            Ember.set(this, 'winnerBox', this.$(".team-stats-wrapper .winner"));
            Ember.set(this, 'teamStats', this.$(".team-stats"));
            Ember.set(this, 'star', this.$(".star"));
            Ember.set(this, 'shootingStarRight', this.$(".shooting-star-right"));
            Ember.set(this, 'shootingStarLeft', this.$(".shooting-star-left"));
            Ember.set(this, 'roverWrapper', this.$(".rover-wrapper"));
            Ember.set(this, 'tlBodyMove', new TimelineMax());
            Ember.set(this, 'tlLeftWins', new TimelineMax({ paused: true }));
            Ember.set(this, 'tlRightWins', new TimelineMax({ paused: true }));
            Ember.set(this, 'tlStars', new TimelineLite());
            Ember.set(this, 'tlShootingStars', new TimelineMax({ repeat: -1, repeatDelay: 2 }));

            Ember.get(this, 'tlBodyMove').set(Ember.get(this, 'roverWrapper'), { marginLeft: "0", ease: Power2.easeInOut }).set(Ember.get(this, 'winnerTagRight'), { autoAlpha: 0 }).set(Ember.get(this, 'winnerTagLeft'), { autoAlpha: 0 }).to(Ember.get(this, 'rLeftHead'), 0.25, { x: -10, transformOrigin: "50% 50%", ease: Power2.easeIn }, "roverStart").to(Ember.get(this, 'rLeftNeck'), 0.25, { rotation: -20, transformOrigin: "50% 50%", ease: Power2.easeIn }, "roverStart").to(Ember.get(this, 'rRightHead'), 0.25, { x: 10, transformOrigin: "50% 50%", ease: Power2.easeIn }, "roverStart").to(Ember.get(this, 'rRightNeck'), 0.25, { rotation: 20, transformOrigin: "50% 50%", ease: Power2.easeIn }, "roverStart").to(Ember.get(this, 'rLeftBody'), 0.25, { x: -10, transformOrigin: "50% 50%", ease: Power2.easeIn }, "roverStart").to(Ember.get(this, 'rRightBody'), 0.25, { x: 10, transformOrigin: "50% 50%", ease: Power2.easeIn }, "roverStart");

            Ember.get(this, 'tlShootingStars').set(Ember.get(this, 'shootingStarRight'), { x: 0, y: 400 }, "shootingStarStart").set(Ember.get(this, 'shootingStarLeft'), { x: -100, y: 400 }, "shootingStarStart").to(Ember.get(this, 'shootingStarRight'), 1.25, { x: 600, y: -200, delay: 2 }).to(Ember.get(this, 'shootingStarLeft'), 1.25, { x: 500, y: -200, delay: 4 }).set(Ember.get(this, 'shootingStarLeft'), { x: -400, y: 400 }).to(Ember.get(this, 'shootingStarLeft'), 1.25, { x: 200, y: -200, delay: 1 }).set(Ember.get(this, 'shootingStarRight'), { x: -200, y: 400 }).to(Ember.get(this, 'shootingStarRight'), 1.25, { x: 400, y: -200, delay: 4 });

            /// Left Rover Wins -------------------------
            Ember.get(this, 'tlLeftWins')
            /// Move Rovers
            .set(Ember.get(this, 'roverWrapper'), { marginLeft: "0", ease: Power2.easeInOut }).to(Ember.get(this, 'rWheelsRight'), 1, { rotation: 360, repeat: 1, transformOrigin: "50% 50%", ease: Linear.easeNone }, 0).to(Ember.get(this, 'rWheelsLeft'), 1, { rotation: -360, repeat: 1, transformOrigin: "50% 50%", ease: Linear.easeNone }, 0).to(Ember.get(this, 'roverWrapper'), 2, { marginLeft: "-15%", ease: Power2.easeInOut }, 0)
            /// Reset heads from pulling
            .add("headReset", "-=0.5").to(Ember.get(this, 'rLeftHead'), 0.25, { x: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "headReset").to(Ember.get(this, 'rLeftNeck'), 0.25, { rotation: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "headReset").to(Ember.get(this, 'rRightHead'), 0.25, { x: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "headReset").to(Ember.get(this, 'rRightNeck'), 0.25, { rotation: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "headReset").to(Ember.get(this, 'rLeftBody'), 0.25, { x: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "headReset").to(Ember.get(this, 'rRightBody'), 0.25, { x: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "headReset").set(Ember.get(this, 'rWheelsRight'), { rotation: 0 }).set(Ember.get(this, 'rWheelsLeft'), { rotation: 0 })
            /// Rovers React
            .add("winnerDeclared-=0.5").to(Ember.get(this, 'winnerTagLeft'), 0.5, { autoAlpha: 1, y: -10 }, "winnerDeclared").to(Ember.get(this, 'winnerBox'), 0.25, { scale: 1.05 }, "winnerDeclared").to(Ember.get(this, 'winnerBox'), 0.25, { scale: 1 }, "winnerDeclared+=0.25").to(Ember.get(this, 'rRightHead'), 0.25, { rotation: -7, transformOrigin: "50% 50%", ease: Power2.easeIn }, "winnerDeclared").to(Ember.get(this, 'rEyeRight'), 0.25, { x: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "winnerDeclared").to(Ember.get(this, 'rRightHead'), 0.5, { y: 10, x: -5, transformOrigin: "50% 50%", ease: Power2.easeIn }, "winnerDeclared").to(Ember.get(this, 'finishLineElements'), 0.25, { alphaSet: 0 })
            // HeadMove Bounce
            .to(Ember.get(this, 'rLeftHead'), 0.25, { y: -12 }, "winnerDeclared").to(Ember.get(this, 'rLeftHead'), 2, { y: -5, ease: Bounce.easeOut }, "winnerDeclared+=0.25").to(Ember.get(this, 'rLeftHead'), 0.25, { rotation: -12 }, "winnerDeclared")
            // Neck Bounce
            .to(Ember.get(this, 'rLeftNeck'), 0.25, { y: -6 }, "winnerDeclared").to(Ember.get(this, 'rLeftNeck'), 2, { y: 0, ease: Bounce.easeOut }, "winnerDeclared+=0.25");

            /// Right Rover Wins -------------------------
            Ember.get(this, 'tlRightWins')
            /// Move Rovers
            .set(Ember.get(this, 'roverWrapper'), { marginLeft: "0", ease: Power2.easeInOut }).to(Ember.get(this, 'rWheelsRight'), 1, { rotation: 360, repeat: 1, transformOrigin: "50% 50%", ease: Linear.easeNone }, 0).to(Ember.get(this, 'rWheelsLeft'), 1, { rotation: -360, repeat: 1, transformOrigin: "50% 50%", ease: Linear.easeNone }, 0).to(Ember.get(this, 'roverWrapper'), 2, { marginLeft: "15%", ease: Power2.easeInOut }, 0)
            /// Reset heads from pulling
            .add("headReset", "-=0.5").to(Ember.get(this, 'rRightHead'), 0.25, { x: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "headReset").to(Ember.get(this, 'rRightNeck'), 0.25, { rotation: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "headReset").to(Ember.get(this, 'rLeftHead'), 0.25, { x: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "headReset").to(Ember.get(this, 'rLeftNeck'), 0.25, { rotation: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "headReset").to(Ember.get(this, 'rRightBody'), 0.25, { x: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "headReset").to(Ember.get(this, 'rLeftBody'), 0.25, { x: 0, transformOrigin: "50% 50%", ease: Power2.easeIn }, "headReset").set(Ember.get(this, 'rWheelsRight'), { rotation: 0 }).set(Ember.get(this, 'rWheelsLeft'), { rotation: 0 })
            /// Rovers React
            .add("winnerDeclared-=0.5").to(Ember.get(this, 'winnerTagRight'), 0.5, { autoAlpha: 1, y: -10 }, "winnerDeclared").to(Ember.get(this, 'winnerBox'), 0.25, { scale: 1.05 }, "winnerDeclared").to(Ember.get(this, 'winnerBox'), 0.25, { scale: 1 }, "winnerDeclared+=0.25").to(Ember.get(this, 'rLeftHead'), 0.25, { rotation: 7, transformOrigin: "50% 50%", ease: Power2.easeIn }, "winnerDeclared").to(Ember.get(this, 'rEyeLeft'), 0.25, { y: 2, transformOrigin: "50% 50%", ease: Power2.easeIn }, "winnerDeclared").to(Ember.get(this, 'rLeftHead'), 0.5, { y: 10, x: 5, transformOrigin: "50% 50%", ease: Power2.easeIn }, "winnerDeclared").to(Ember.get(this, 'finishLineElements'), 0.25, { alphaSet: 0 })
            // HeadMove Bounce
            .to(Ember.get(this, 'rRightHead'), 0.25, { y: -12 }, "winnerDeclared").to(Ember.get(this, 'rRightHead'), 2, { y: -5, ease: Bounce.easeOut }, "winnerDeclared+=0.25").to(Ember.get(this, 'rRightHead'), 0.25, { rotation: 12 }, "winnerDeclared")
            // Neck Bounce
            .to(Ember.get(this, 'rRightNeck'), 0.25, { y: -6 }, "winnerDeclared").to(Ember.get(this, 'rRightNeck'), 2, { y: 0, ease: Bounce.easeOut }, "winnerDeclared+=0.25");

            Ember.get(this, 'tlStars').staggerFromTo(Ember.get(this, 'star'), 0.3, { scale: 0, autoAlpha: 0 }, { scale: 1, autoAlpha: 1, transformOrigin: "50% 50%", ease: Back.easeInOut, delay: 1 }, 0.05).staggerFromTo(Ember.get(this, 'star'), 2, { opacity: 1, scale: 1 }, { opacity: 0.25, scale: 1.4, repeat: -1, transformOrigin: "50% 50%", yoyo: true, ease: Back.easeInOut }, 0.5);
            //// Count Numbers Up
            // this.$('.count').each(function () {
            //     $(this).prop('Counter',0).animate({
            //             Counter: $(this).text()
            //     }, {
            //             duration: 2000,
            //             //easing: 'easeOutSine',
            //             step: function (now) {
            //                     $(this).text(Math.ceil(now));
            //             }
            //     });
            // });

            this.toggleProperty('didElementInsert');
            //try to trigger the animation to play.
            //should be the last part of the didInsertElement block
            this.playAnimation();
        }
    });
});
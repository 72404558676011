define('ohs/components/passage-questions', ['exports', 'ohs/mixins/tip-mixin'], function (exports, _tipMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_tipMixin.default, {
    tagName: 'section',
    classNames: ['quiz question-picker'],
    acceptedQuestions: [],
    children: [],
    currentQuestionIndex: 0,
    finished: false,
    initial: true,
    cardDismissed: false,
    justAccepted: false,
    showAlert: null,
    showSkillIntro: false,
    goingForward: false,
    arrowDisabled: false,
    tick: 0,
    skillIntroStatus: Ember.computed('showSkillIntro', function () {
      return this.get('showSkillIntro') ? 'skill-title-visible' : '';
    }),
    alertMessage: null,
    store: Ember.inject.service(),

    questionIssue: '',
    questionIssueComments: '',
    textLimit: 1000,
    commentLength: 0,

    skillInitial: 'skill-initial',
    validIssue: Ember.computed('questionIssue', 'commentLength', 'textLimit', function () {
      if (this.questionIssue) {
        if (this.questionIssue != "Other") {
          if (this.questionIssueComments && this.textLimit) {
            return this.questionIssueComments.length <= this.textLimit;
          }
          return true;
        } else {
          if (this.questionIssueComments && this.textLimit) {
            if (this.questionIssueComments.length > 0 && this.questionIssueComments.length <= this.textLimit) {
              return true;
            } else {
              return false;
            }
          }
          return false;
        }
      }
      return false;
    }),

    tellUsMore: Ember.computed('questionIssue', function () {
      if (this.questionIssue == "Other") {
        return "Tell us more.";
      } else {
        return "Tell us more (optional).";
      }
    }),

    acceptedCount: Ember.computed('acceptedQuestions.length', function () {
      return this.get('acceptedQuestions').get('length');
    }),

    currentQuestionCount: Ember.computed('currentQuestionIndex', function () {
      return this.get('currentQuestionIndex') + 1;
    }),

    finishDisabled: Ember.computed('acceptedQuestions.length', function () {
      return this.get('acceptedCount') === 0;
    }),

    isFinished: Ember.computed('finished', function () {
      return this.get('finished') ? 'finished' : '';
    }),

    initialStatus: Ember.computed('initial', function () {
      var init = this.get('initial');
      if (init == true && !this.get('cardDismissed')) {
        this.set('cardDismissed', true);
        return 'initial';
      } else {
        this.set('cardDismissed', true);
        return 'inactive';
      }
    }),

    curQ: Ember.computed('currentQuestionIndex', function () {
      return this.currentQuestion();
    }),

    hasNext: Ember.computed('currentQuestionIndex', 'finished', function () {
      var index = this.get('currentQuestionIndex');
      var hasNext = this.get('orderedQuestions').get('length') - 1 != index;
      hasNext = hasNext && !this.get('finished');
      return hasNext ? 'has-next' : '';
    }),

    hasPrevious: Ember.computed('currentQuestionIndex', 'showSkillIntro', function () {
      var index = this.get('currentQuestionIndex');
      return index > 0 || !this.get('currentlyOnInitial') ? 'has-previous' : '';
    }),

    acceptedStatus: Ember.computed('currentQuestionIndex', 'acceptedQuestions.length', function () {
      var question = this.currentQuestion();
      if (this.get('acceptedQuestions').indexOf(question) >= 0) {
        return 'accepted';
      }
    }),

    justAcceptedStatus: Ember.computed('justAccepted', function () {
      var question = this.currentQuestion();
      if (this.justAccepted) {
        return 'just-accepted';
      }
    }),

    acceptDisabled: Ember.computed('currentQuestionIndex', 'acceptedQuestions.length', function () {
      var question = this.currentQuestion();
      //disable accept if this question is in the accepted array
      return this.get('acceptedQuestions').indexOf(question) >= 0;
    }),

    removeDisabled: Ember.computed('currentQuestionIndex', 'acceptedQuestions.length', function () {
      var question = this.currentQuestion();
      //disable remove if this question isn't in the accepted array
      return this.get('acceptedQuestions').indexOf(question) < 0;
    }),

    currentEligibleContent: Ember.computed('currentQuestionIndex', 'showSkillIntro', 'currentlyOnInitial', 'tick', function () {
      if (this.get('showSkillIntro') && this.get('goingForward')) {
        var ec = this.get('orderedQuestions').toArray()[this.get('currentQuestionIndex') + 1].get('standards.firstObject');
        return ec;
      } else {
        var _ec = this.currentQuestion().get('standards.firstObject');
        return _ec;
      }
    }),

    hasNextStandard: Ember.computed('currentEligibleContent', function () {
      var s = this.get('currentEligibleContent');
      var si = this.get('standardInfo').find(function (item, i) {
        return item.standard.get('standardCode') == s.get('standardCode');
      });
      var indexOfNextStandard = si.startIndex + si.numQuestions - 1;
      if (indexOfNextStandard + 2 > this.get('orderedQuestions').get('length')) {
        return 'hidden';
      } else {
        return '';
      }
    }),

    hasPrevStandard: Ember.computed('currentEligibleContent', function () {
      var s = this.get('currentEligibleContent');
      var si = this.get('standardInfo').find(function (item, i) {
        return item.standard.get('standardCode') == s.get('standardCode');
      });
      if (si.previousStandard) {
        return '';
      } else {
        return 'hidden';
      }
    }),

    standardInfo: Ember.computed('questions.@each.id', function () {
      var standardInfo = [];
      var loopIndex = 0;
      var curStandard = null;
      var questions = this.get('orderedQuestions');
      questions.forEach(function (q) {
        var qs = q.get('standards.firstObject');
        if (qs != curStandard) {
          var si = { 'standard': qs, 'startIndex': loopIndex, 'numQuestions': 1, 'previousStandard': curStandard };
          standardInfo.pushObject(si);
          curStandard = qs;
        } else {
          var si = standardInfo.findBy('standard', qs);
          si.numQuestions++;
        }
        loopIndex++;
      });
      return standardInfo;
    }),

    currentStandardStartAndEnd: Ember.computed('currentEligibleContent', function () {
      var s = this.get('currentEligibleContent');
      var si = this.get('standardInfo').findBy('standard', s);
      var firstQuestionNumber = si.startIndex + 1;
      var lastQuestionNumber = si.startIndex + si.numQuestions;
      return { 'first': firstQuestionNumber, 'last': lastQuestionNumber };
    }),

    orderedQuestions: Ember.computed('questions.@each.id', function () {
      return this.get('questions').sortBy('standards.firstObject.id');
    }),

    // init() {
    //
    // },

    currentQuestion: function currentQuestion() {
      var index = this.get('currentQuestionIndex');
      return this.get('orderedQuestions').toArray()[index];
    },

    getChildComponent: function getChildComponent(action) {
      var questions = this.get('children');
      var position = this.get('currentQuestionIndex');

      if (questions.length > position) {
        return questions[position];
      } else {
        return null;
      }
    },

    registerChild: function registerChild(child) {
      this.get('children').pushObject(child);
      //if this question is already in the quiz, add this question to the accepted array
      var childQuestionId = child.get('question').get('id');
      if (this.get('quiz')) {
        if (this.get('quiz').get('testQuestions').get('length') > 0) {
          var testQuestions = this.get('quiz').get('testQuestions').toArray();
          for (var i = 0; i < testQuestions.length; i++) {
            var tq = testQuestions[i];
            if (tq.get('question').get('id') == childQuestionId) {
              var question = child.get('question');
              this.get('acceptedQuestions').pushObject(question);
              child.markAccepted(true);
              this.get('quiz').get('questions').pushObject(question);
            }
          }
        } else {
          var testQuestions = this.get('quiz').get('questions').toArray();
          for (var i = 0; i < testQuestions.length; i++) {
            var tq = testQuestions[i];
            if (tq.get('id') == childQuestionId) {
              var question = child.get('question');
              this.get('acceptedQuestions').pushObject(question);
              child.markAccepted(true);
            }
          }
        }
      }
    },

    didInsertElement: function didInsertElement() {
      if (!this.get('initial')) {
        this.set('showMeta', true);
        if (this.get('standardInfo.length') > 1) {
          this.set('showSkillIntro', true);
          // original question to unmark as current
          var currentQuestion = this.getChildComponent();
          currentQuestion.markCurrent(false);
          this.set('currentlyOnInitial', true);
          this.set('skillInitial', true);
        } else {
          this.set('showSkillIntro', false);
          // original question to unmark as current
          var _currentQuestion = this.getChildComponent();
          _currentQuestion.markCurrent(true);
          this.set('currentlyOnInitial', false);
          this.set('skillInitial', false);
        }
      } else {
        var current = this.getChildComponent();
        current.markCurrent(true);
      }
    },

    willDestroyElement: function willDestroyElement() {
      var questions = this.get('children');
      questions.length = 0;
      this.get('acceptedQuestions').length = 0;
    },

    actions: {
      nextAnchor: function nextAnchor() {
        this.set('goingForward', true);
        var s = this.get('currentEligibleContent');
        var si = this.get('standardInfo').findBy('standard', s);
        var indexOfNextStandard = si.startIndex + si.numQuestions - 1;
        if (si.numQuestions == 1) {
          this.set('tick', this.get('tick') + 1);
        }
        if (indexOfNextStandard + 2 > this.get('orderedQuestions').get('length')) {
          return;
        } else {
          // original question to unmark as current
          var currentQuestion = this.getChildComponent();
          currentQuestion.markCurrent(false);
          this.set('currentQuestionIndex', indexOfNextStandard);
          this.set('showSkillIntro', true);
          this.set('currentlyOnInitial', false);
        }
      },

      previousAnchor: function previousAnchor() {
        var s = this.get('currentEligibleContent');
        var si = this.get('standardInfo').findBy('standard', s);
        if (si.previousStandard) {
          var ps = this.get('standardInfo').findBy('standard', si.previousStandard);
          if (ps.numQuestions == 1) {
            this.set('tick', this.get('tick') + 1);
          }
          var indexOfPrevStandard = ps.startIndex;
          var currentQuestion = this.getChildComponent();
          currentQuestion.markCurrent(false);
          this.set('currentQuestionIndex', indexOfPrevStandard);
          this.set('showSkillIntro', true);
          if (indexOfPrevStandard == 0) {
            this.set('currentlyOnInitial', true);
          }
        }
        this.set('goingForward', false);
      },

      changeClass: function changeClass() {
        var _this = this;

        this.set('initial', false);
        this.destroyTip(5);

        setTimeout(function () {
          _this.set('showMeta', true);
        }, 1250);
        if (this.get('standardInfo.length') > 1) {
          this.set('showSkillIntro', true);
          this.set('currentlyOnInitial', true);

          // original question to unmark as current
          var currentQuestion = this.getChildComponent();
          currentQuestion.markCurrent(false);

          setTimeout(function () {
            _this.set('skillInitial', '');
          }, 3000);
        }
      },
      acceptQuestion: function acceptQuestion() {
        var question = this.currentQuestion();

        if (this.get('acceptedQuestions').indexOf(question) < 0) {
          this.get('acceptedQuestions').pushObject(question);
          this.getChildComponent().markAccepted(true);
          //push this question into the Quiz

          this.get('quiz').get('questions').pushObject(question);
          this.set('justAccepted', true);
        }
      },

      removeQuestion: function removeQuestion() {
        var question = this.currentQuestion();
        this.get('acceptedQuestions').removeObject(question);
        this.getChildComponent().markAccepted(false);
        //remove this question from the Quiz
        this.get('quiz').get('questions').removeObject(question);
        this.set('justAccepted', false);
      },

      nextQuestion: function nextQuestion() {
        var skipIndexUpdate = false;
        if (this.get('showSkillIntro') && !this.get('goingForward')) {
          skipIndexUpdate = true;
        }
        this.set('goingForward', true);
        this.set('justAccepted', false);
        var index = this.get('currentQuestionIndex');

        if (this.get('standardInfo.length') > 1) {
          var showSkillIntro = this.get('standardInfo').findBy('startIndex', this.get('currentQuestionIndex') + 1) && !this.get('showSkillIntro');

          // we have to keep track so we can undo this once it is shown
          this.set('showSkillIntro', showSkillIntro);
        }

        if (index >= this.get('orderedQuestions').get('length')) {
          return;
        }

        // original question to unmark as current
        var currentQuestion = this.getChildComponent();
        currentQuestion.markCurrent(false);

        // we skip actually moving to the next card when a skill introduction card is shown
        // (in between eligible content from multiple assessment anchors)
        if (this.get('showSkillIntro')) {
          return;
        }
        // position update, if there are questions left
        else if (index + 1 < this.get('orderedQuestions').get('length')) {
            if (this.get('currentlyOnInitial')) {
              this.set('currentlyOnInitial', false);
            } else {
              if (!skipIndexUpdate) this.set('currentQuestionIndex', index + 1);
            }

            //next question marked as current
            currentQuestion = this.getChildComponent();
            currentQuestion.markCurrent(true);
          }
          //otherwise we're at the end
          else {
              this.set('finished', true);
            }
      },

      prevQuestion: function prevQuestion() {
        var skipIndexUpdate = false;
        if (this.get('showSkillIntro') && this.get('goingForward')) {
          skipIndexUpdate = true;
        }
        this.set('goingForward', false);
        this.set('justAccepted', false);
        var index = this.get('currentQuestionIndex');
        if (index == 0) {
          if (!this.get('showSkillIntro')) {
            //this.set('showSkillIntro',true);
            this.set('currentlyOnInitial', true);
          } else {
            this.set('showSkillIntro', false);
            this.set('currentlyOnInitial', false);
            var _currentQuestion2 = this.getChildComponent();
            _currentQuestion2.markCurrent(true);
            return;
          }
        }

        var currentQuestion = this.getChildComponent();

        if (this.get('standardInfo.length') > 1) {
          var showSkillIntro = this.get('standardInfo').findBy('startIndex', this.get('currentQuestionIndex')) && !this.get('showSkillIntro');

          // we have to keep track so we can undo this once it is shown
          this.set('showSkillIntro', showSkillIntro);
        }

        // mark the last question current
        // if we're on the bottom of the stack
        if (this.get('finished')) {
          currentQuestion.markCurrent(true);
          this.set('finished', false);
        } else if (this.get('showSkillIntro')) {
          currentQuestion.markCurrent(false);
          return;
        } else {
          // original question unmarked as current
          currentQuestion.markCurrent(false);

          // position update
          if (!skipIndexUpdate) this.set('currentQuestionIndex', index - 1);

          //Previous question marked as current
          currentQuestion = this.getChildComponent();
          currentQuestion.markCurrent(true);
        }
      },
      saveQuiz: function saveQuiz() {
        var component = this;
        component.set('finishDisabled', true);
        // To disable Next/Previous buttons
        component.set('arrowDisabled', true);
        var passageId = component.get('passage.id');
        var passage = component.get('passage');
        component.get('quiz').set('passageId', passageId);
        // component.get('quiz').set('passage',passage);
        component.get('quiz').save().then(function (quiz) {
          component.sendAction('saveAction', quiz.get('id'));
          component.set('finishDisabled', false);
        }, function (quiz) {
          component.set('finishDisabled', false);
          component.set('showAlert', true);
          component.set('alertMessage', "You already have a quiz with this name. Please use a new quiz name.");
        });
        // component.sendAction('saveAction', this.get('quiz.id'));
        // component.set('finishDisabled',false);
      },

      refresh: function refresh() {
        this.set('currentQuestionIndex', 0);

        if (this.get('standardInfo.length') > 1) {
          this.set('showSkillIntro', true);
          this.set('currentlyOnInitial', true);
        } else {
          //first question marked as current
          var currentQuestion = this.getChildComponent();
          currentQuestion.markCurrent(true);
        }

        this.set('finished', false);
      },

      //Hide alert box
      hideAlert: function hideAlert() {
        this.set("showAlert", false);
        this.set("alertMessage", "");
      },

      showIssue: function showIssue() {
        this.set('showIssue', true);
      },

      setCharacterLimitAction: function setCharacterLimitAction(commentCharacterLength, characterLimit) {
        this.set('textLimit', characterLimit);
        this.set('commentLength', commentCharacterLength);
      },

      reportIssue: function reportIssue() {

        this.set('issueMessage', null);
        var self = this;
        var adapter = this.get('store').adapterFor('application');

        var url = adapter.get('host') + '/accounts/reportQuestion?questionId=' + this.currentQuestion().get('id') + '&issue=' + this.get('questionIssue') + '&comment=' + this.get('questionIssueComments');
        if (this.get('questionIssueComments').trim() == '') {
          url = adapter.get('host') + '/accounts/reportQuestion?questionId=' + this.currentQuestion().get('id') + '&issue=' + this.get('questionIssue');
        }
        adapter.ajax(url, 'POST').then(function () {
          self.set('showConfirm', true);
          self.set('confirmationMessage', 'Your report has been received and is currently being reviewed. If we have any follow up questions, someone will be in touch!');
        }, function () {
          self.set('showAlert', true);
          self.set("alertMessage", "There was an error sending your report. Please check your internet connection and try again.");
        });

        this.set('questionIssueComments', '');
        this.set('questionIssue', null);
      },
      //Hide alert box
      hideConfirm: function hideConfirm() {
        this.set("showConfirm", false);
      },

      setQuestionIssue: function setQuestionIssue(value) {
        this.set('questionIssue', value);
      },

      setQuestionIssueComment: function setQuestionIssueComment(value) {
        this.set('questionIssueComments', value);
      },

      validateIssue: function validateIssue() {
        if (!this.get('questionIssue')) {
          this.set('issueMessage', 'Please tell us what is wrong with this resource.');
          return false;
        } else if (this.get('questionIssue') == "Other" && !this.get('questionIssueComments').trim()) {
          this.set('issueMessage', 'Please add some additional information about what is wrong with this resource.');
          return false;
        } else if (this.get('questionIssueComments.length') > this.get('textLimit')) {
          this.set('issueMessage', 'Comment exceeds the character limit.');
          return false;
        } else {
          return true;
        }
      },

      cancel: function cancel() {
        this.set('issueMessage', '');
        this.set('questionIssueComments', '');
        this.set('questionIssue', null);
      },
      clear: function clear() {
        this.set('issueMessage', '');
        this.set('questionIssueComments', '');
        this.set('questionIssue', null);
      },


      showPreviousUsed: function showPreviousUsed() {
        this.set('showPrevousQuizzes', true);
      },

      closeModal: function closeModal() {
        this.set('showPrevousQuizzes', false);
      }
    }
  });
});
define('ohs/components/trophy-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['aria-hidden', 'role', 'aria-labeledby'],
    role: 'dialog',
    enabled: false,
    'aria-labeledby': 'modal-content',
    students: false,

    studentsShowing: function () {
      return this.get('students') ? 'students-showing' : '';
    }.property('students'),

    'aria-hidden': function () {
      return !this.get('enabled') + '';
    }.property('enabled'),

    hideScroll: Ember.observer('enabled', function () {
      var _this = this;

      Ember.run.once(this, function () {
        _this.get('enabled') ? $('body').addClass('modal-active') : $('body').removeClass('modal-active');
      });
    }),

    enabledClasses: function () {
      return this.get('enabled') ? 'is-open' : 'is-hidden';
    }.property('enabled'),

    studentTrophy: Ember.computed('t.studentTrophies.@each.id', function () {
      if (this.get('t.studentTrophies').get('length') == 1) {
        return this.get('t.studentTrophies').get('firstObject');
      }
    }),

    didRender: function didRender() {
      if (!this.get('enabled')) {
        this.$().find('.trophy-img').css('height', this.$().find('.trophy-img').width() * this.$().find('.trophy-img').css('min-height').replace("px", ""));
      }
    },

    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('enabled');
        if (this.get('student') && this.get('studentTrophy') && !this.get('studentTrophy').get('studentViewed')) {
          var st = this.get('studentTrophy');
          st.set('studentViewed', true);
          st.save();
        }
      },

      showStudents: function showStudents() {
        this.toggleProperty('students');
      }
    }
  });
});
define('ohs/routes/login-details', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Login Details',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (!transition.queryParams.user_id) {
        this.transitionTo('usage-tracker');
      }
    },
    model: function model(queryParams, params) {
      if (params.queryParams.from_date && params.queryParams.to_date) {
        var loginDetails = this.store.query('user-tracker', { userId: params.queryParams.user_id, fromDate: params.queryParams.from_date, toDate: params.queryParams.to_date });
      } else {
        var loginDetails = this.store.query('user-tracker', { userId: params.queryParams.user_id, filterType: params.queryParams.filter_type });
      }
      return Ember.RSVP.hash({
        loginDetails: loginDetails,
        user: this.store.findRecord('user', params.queryParams.user_id)
      });
    }
  });
});
define('ohs/services/ajax', ['exports', 'ember-ajax/services/ajax', 'ember-ajax/errors', 'ohs/config/environment'], function (exports, _ajax, _errors, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ajax.default.extend({
        session: Ember.inject.service(),
        host: _environment.default.apiUrl,
        namespace: '/api',
        authorizer: 'authorizer:custom',

        headers: Ember.computed('session.authToken', function () {
            var authorizer = this.get('authorizer');
            var headers = {};
            this.get('session').authorize(authorizer, function (headerName, headerValue) {
                headers[headerName] = headerValue;
            });
            return headers;
        }),

        request: function request() {
            var _this = this;

            return this._super.apply(this, arguments).catch(function (error) {
                if ((0, _errors.isUnauthorizedError)(error)) {
                    _this.get('session').invalidate();
                }

                throw error;
            });
        }
    });
});
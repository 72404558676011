define('ohs/authenticators/custom', ['exports', 'ember-simple-auth/authenticators/base', 'ohs/config/environment'], function (exports, _base, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        ////properties 
        tokenEndpoint: _environment.default.apiUrl + '/token',
        googleLoginEndpoint: _environment.default.apiUrl + '/api/googleSignIn',
        schoologyLoginEndpoint: _environment.default.apiUrl + '/api/schoologySignIn',

        ////methods
        restore: function restore(data) {
            return new Ember.RSVP.Promise(function (resolve, reject) {
                return Ember.isEmpty(data.token) ? reject() : resolve(data);
            });
        },
        authenticate: function authenticate(options) {
            var _this = this;

            //username and password login
            if (options.username) return new Ember.RSVP.Promise(function (resolve, reject) {
                _this._createRequest({
                    username: options.username,
                    password: options.password,
                    grant_type: "password"
                }, get(_this, 'tokenEndpoint')).then(function (response) {
                    return resolve(_this._createSessionObject(response));
                }, function (xhr) {
                    return reject(xhr.responseText);
                });
            });

            //google login
            if (options.idtoken) return new Ember.RSVP.Promise(function (resolve, reject) {
                _this._createRequest({
                    idtoken: options.idtoken,
                    grant_type: "password"
                }, get(_this, 'googleLoginEndpoint')).then(function (response) {
                    return resolve(_this._createSessionObject(response));
                }, function (xhr) {
                    return reject(xhr.responseText);
                });
            });

            //schoology login
            if (options.schoologyAccessToken) return new Ember.RSVP.Promise(function (resolve, reject) {
                _this._createRequest({
                    accessToken: options.schoologyAccessToken,
                    accessSecret: options.schoologyAccessSecret,
                    grant_type: "password"
                }, get(_this, 'schoologyLoginEndpoint')).then(function (response) {
                    //clear cookies
                    var cookie_date = new Date();
                    cookie_date.setTime(cookie_date.getTime() - 1);
                    document.cookie = "SRT=; expires=" + cookie_date.toGMTString();
                    document.cookie = "SRTS=; expires=" + cookie_date.toGMTString();

                    resolve(_this._createSessionObject(response));
                }, function (xhr) {
                    return reject(xhr.responseText);
                });
            });
        },


        invalidate: function invalidate() {
            return Ember.RSVP.resolve();
        },

        ////private methods 

        /**
         * Creates a POST call to the authentication endpoint provided
         * @param {*} data 
         * @param {*} endpoint 
         */
        _createRequest: function _createRequest(data, endpoint) {
            return Ember.$.ajax({
                type: 'POST',
                url: endpoint,
                data: data
            });
        },


        /**
         * Creates a object that can be used as the session object from the response object provided
         * @param {*} response Response object from the authentication endpoint
         */
        _createSessionObject: function _createSessionObject(response) {
            return {
                token: response.access_token,
                roles: JSON.parse(response.roles),
                userId: response.userId,
                userName: response.userName,
                displayName: response.displayName,
                forcePasswordChange: (response.forcePasswordChange || 'false').toLowerCase() === 'true',
                hasRoster: (response.hasRoster || 'true').toLowerCase() === 'true'
            };
        }
    });
});
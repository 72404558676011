define('ohs/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'ohs/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.apiUrl + '/api',
    signalRHubUrl: _environment.default.apiUrl + '/signalr',
    authorizer: 'authorizer:custom',
    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 400 && payload.modelState) {
        return new _emberData.default.InvalidError([payload.modelState]);
      }
      return this._super.apply(this, arguments);
    }
  });
});
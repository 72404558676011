define('ohs/mixins/authorized-route-mixin', ['exports', 'ohs/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, {

        //// hooks
        beforeModel: function beforeModel() {
            this._super.apply(this, arguments);

            var roles = Ember.get(this, 'token.roles');
            var authorizedRoles = Ember.get(this, 'authorizedRoles');

            var authorized = roles.some(function (role) {
                return authorizedRoles.some(function (r) {
                    return r === role;
                });
            });

            if (!authorized) {
                this.controllerFor('application').setProperties({
                    errorStatusCode: 403,
                    errorDetailMessage: "You don't have access to this request"
                });

                return this.transitionTo('error');
            }
        }
    });
});
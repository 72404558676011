define('ohs/components/student-review-question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    index: 0,
    classNames: ['question'],
    classNameBindings: ['dynamicClasses'],
    store: Ember.inject.service(),

    orderedAnswers: Ember.computed('question.question.answerOptions.@each.id', function () {
      return this.get('question.question.answerOptions').sortBy('id');
    }),
    orderedSecondPartAnswers: Ember.computed('question.question.secondPartQuestion.secondPartAnswerOptions.@each.id', function () {
      return this.get('question.question.secondPartQuestion.secondPartAnswerOptions').sortBy('id');
    }),
    dynamicClasses: Ember.computed('position', function () {
      var names = [];
      var index = this.get('index');
      var length = this.get('length');

      if (index === 0) {
        names.push('is-first');
      }

      if (index === length - 1) {
        names.push('is-last');
      }

      return names.join(' ');
    }),

    updateElementClasses: function updateElementClasses(self) {
      var children = self.parent().children();
      children.first().addClass('is-first');
      children.last().addClass('is-last');
    },

    actions: {}

  });
});
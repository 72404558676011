define('ohs/components/pick-a-time', ['exports', 'ember-cli-pickadate/components/pick-a-time'], function (exports, _pickATime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _pickATime.default;
    }
  });
});
define('ohs/components/student-recommended-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    //resources: null,

    studentResources: Ember.computed('resources.@each.resourceStatus', function () {
      if (this.get('resources')) {
        return this.get('resources').filterBy('student.id', this.get('student.id'));
      }
    }),

    studentSent: Ember.computed('sentResources.@each.assignedDate', function () {
      if (this.get('sentResources')) {
        return this.get('sentResources').filterBy('student.id', this.get('student.id')).sortBy('assignedDate').reverse();
      }
    }),

    actions: {

      send: function send(object) {
        var self = this;
        self.isProcessing(true);
        this.sendAction('onSendAction', object);
        setTimeout(function () {
          self.isProcessing(false);
        }, 2000);
      },

      dismiss: function dismiss(object) {
        this.sendAction('dismissAction', object);
      },

      resend: function resend(object) {
        this.sendAction('onSendAction', object);
      }

    },

    //Methods

    // Enable or disable buttons based on the status of a process.
    isProcessing: function isProcessing(status) {
      //Enable/Disable all the buttons in the div with 'button-group :button' class
      // $(".button-group :button").attr("disabled", status);
    }
  });
});
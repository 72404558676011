define('ohs/models/second-part-question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    questionId: _emberData.default.attr('number'),
    questionText: _emberData.default.attr('string'),
    dokLevelId: _emberData.default.attr('number'),
    hasTemplate: _emberData.default.attr('boolean'),
    difficultyIndexId: _emberData.default.attr('number'),
    minimumAnswerWidth: _emberData.default.attr('number'),
    audioUrl: _emberData.default.attr('string'),
    secondAnswerType: _emberData.default.attr('string'),
    secondAnswerAllowedCount: _emberData.default.attr('number'),
    secondPartAnswerOptions: _emberData.default.hasMany('secondPartAnswerOptions'),

    numCorrectAnswers: Ember.computed('secondPartAnswerOptions.@each.id', function () {
      return this.get('secondPartAnswerOptions').filterBy('isCorrect', true).get('length');
    }),
    orderedSecondPartAnswerOptions: Ember.computed('secondPartAnswerOptions.@each.id', 'answerOptions.[]', function () {
      return get(this, 'secondPartAnswerOptions').sortBy('id');
    })
  });
});
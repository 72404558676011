define('ohs/routes/teacher-resources-1', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Resources',

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      var self = this;

      return this.store.findRecord('teacher', userId).then(function (teacher) {
        var gradeLevel = 3;
        if (teacher.get('gradeLevelId')) {
          gradeLevel = teacher.get('gradeLevelId');
        }
        return Ember.RSVP.hash({
          resources: self.store.query("resource", { gradeLevelId: gradeLevel, subjectId: 1 }),
          bookmarks: self.store.findAll('resourceBookmark'),
          curUser: teacher,
          reviews: self.store.findAll('resource-user-review'),

          featuredResources: Ember.computed('resources.@each.id', function () {
            return this.resources.filter(function (item) {
              return item.get('resourceType') != 'Teacher Resources';
            }).sortBy('averageRating').reverse().slice(0, 3);
          }),

          featuredBookmarks: Ember.computed('bookmarks.@each.id', function () {
            return this.bookmarks.sortBy('bookmarkedDate').reverse().slice(0, 3);
          }),

          myRatings: Ember.computed('reviews.@each.id', function () {
            return this.reviews.filter(function (rating, index, array) {
              if (rating.get('user')) {
                return rating.get('user').get('id') == this.curUser.get('id');
              } else {
                return false;
              }
            }, this).sortBy('ratingDate').reverse().slice(0, 3);
          }),

          recommended: self.store.findAll('resource-recommended'),

          newRecommendedCount: Ember.computed('recommended.@each.resourceStatus', function () {
            var uniqueStudents = [];
            this.recommended.forEach(function (item) {
              //Added resourceStatus=='New' condition to filter resources that are not Sent or Dismiss
              if (!uniqueStudents.isAny('student.id', item.get('student').get('id')) && item.get('resourceStatus') == 'New') {
                uniqueStudents.pushObject(item);
              }
            });
            return uniqueStudents.length;
          }),
          selectedRatingId: null

        });
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Resources', false, true);
    },

    actions: {
      createBookmark: function createBookmark(resourceId) {
        var curModel = this.currentModel;
        var self = this;
        var curResource = curModel.resources.findBy('id', resourceId);
        var bookmarkForCurResource = curModel.bookmarks.find(function (item, index, enumerable) {
          return item.get('resource').get('id') == resourceId;
        });
        if (curResource && !bookmarkForCurResource) {
          var b = this.store.createRecord('resource-bookmark', {
            resource: curResource,
            user: curModel.curUser,
            bookmarkedDate: new Date()
          });
          b.save().then(function () {
            //self.refresh();
          }, function () {
            self.set('currentModel.warningMessage', "This resource could not be bookmarked. Please check your Internet connection and try again.");
            self.set("currentModel.showAlert", true);
          });
        }
      },

      deleteBookmark: function deleteBookmark(resourceId) {
        var curModel = this.currentModel;
        var self = this;
        var bookmarkForCurResource = curModel.bookmarks.find(function (item, index, enumerable) {
          return item.get('resource').get('id') == resourceId;
        });
        if (bookmarkForCurResource) {
          bookmarkForCurResource.destroyRecord().then(function () {
            //self.refresh();
          }, function () {
            self.set('currentModel.warningMessage', "This bookmark could not be deleted. Please check your Internet connection and try again.");
            self.set("currentModel.showAlert", true);
          });
        }
      },

      confirmRemoveRating: function confirmRemoveRating(value) {
        this.set('currentModel.selectedRatingId', value);
        this.set('currentModel.showModal', true);
        this.set('currentModel.modalMessage', 'Are you sure you want to delete this rating?');
      },


      removeRating: function removeRating() {
        var self = this;
        this.set('currentModel.showModal', false);
        this.set('currentModel.modalMessage', null);

        var rating = this.currentModel.reviews.findBy('id', this.get('currentModel.selectedRatingId'));
        rating.destroyRecord().then(function () {}, function () {
          self.set('currentModel.warningMessage', "Your rating could not be deleted. Please check your Internet connection and try again.");
          self.set("currentModel.showAlert", true);
        });
      },

      searchResources: function searchResources() {
        this.transitionTo('teacher.resource-search-start');
      },

      drill: function drill(id) {
        this.transitionTo('teacher.resource-detail', id);
      }

    }

  });
});
define("ohs/components/date-picker", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    timeChoices: [{
      id: 1,
      text: "12:00"
    }, {
      id: 2,
      text: "12:15"
    }, {
      id: 3,
      text: "12:30"
    }, {
      id: 4,
      text: "12:45"
    }, {
      id: 5,
      text: "1:00"
    }, {
      id: 6,
      text: "1:15"
    }, {
      id: 7,
      text: "1:30"
    }, {
      id: 8,
      text: "1:45"
    }, {
      id: 9,
      text: "2:00"
    }, {
      id: 10,
      text: "2:15"
    }, {
      id: 11,
      text: "2:30"
    }, {
      id: 12,
      text: "2:45"
    }, {
      id: 13,
      text: "3:00"
    }, {
      id: 14,
      text: "3:15"
    }, {
      id: 15,
      text: "3:30"
    }, {
      id: 16,
      text: "3:45"
    }, {
      id: 17,
      text: "4:00"
    }, {
      id: 18,
      text: "4:15"
    }, {
      id: 19,
      text: "4:30"
    }, {
      id: 20,
      text: "4:45"
    }, {
      id: 21,
      text: "5:00"
    }, {
      id: 22,
      text: "5:15"
    }, {
      id: 23,
      text: "5:30"
    }, {
      id: 24,
      text: "5:45"
    }, {
      id: 25,
      text: "6:00"
    }, {
      id: 26,
      text: "6:15"
    }, {
      id: 27,
      text: "6:30"
    }, {
      id: 28,
      text: "6:45"
    }, {
      id: 29,
      text: "7:00"
    }, {
      id: 30,
      text: "7:15"
    }, {
      id: 31,
      text: "7:30"
    }, {
      id: 32,
      text: "7:45"
    }, {
      id: 33,
      text: "8:00"
    }, {
      id: 34,
      text: "8:15"
    }, {
      id: 35,
      text: "8:30"
    }, {
      id: 36,
      text: "8:45"
    }, {
      id: 37,
      text: "9:00"
    }, {
      id: 38,
      text: "9:15"
    }, {
      id: 39,
      text: "9:30"
    }, {
      id: 40,
      text: "9:45"
    }, {
      id: 41,
      text: "10:00"
    }, {
      id: 42,
      text: "10:15"
    }, {
      id: 43,
      text: "10:30"
    }, {
      id: 44,
      text: "10:45"
    }, {
      id: 45,
      text: "11:00"
    }, {
      id: 46,
      text: "11:15"
    }, {
      id: 47,
      text: "11:30"
    }, {
      id: 48,
      text: "11:45"
    }],

    ampmChoices: [{
      id: 1,
      text: "AM"
    }, {
      id: 2,
      text: "PM"
    }],

    ampm: 'AM',
    selectedTime: '12:00',
    isTimeRequired: true,

    didRender: function didRender() {
      if (this.get('time')) {
        if (this.get('timeChoices').findBy('text', this.get('time'))) {
          this.$().find('.time-wrapper').find('.select-input').val(this.get('timeChoices').findBy('text', this.get('time')).id);
          this.$().find('.ampm-wrapper').find('.select-input').val(this.get('ampmChoices').findBy('text', this.get('ampm')).id);
        }
      } else {
        this.set('time', '12:00');
        this.set('ampm', 'AM');
      }
    },

    actions: {
      setTime: function setTime(time) {
        this.set('time', this.get('timeChoices').findBy('id', parseInt(time)).text);
      },

      setDate: function setDate(date) {
        this.set('date', date);
      },

      setAMPM: function setAMPM(ampm) {
        this.set('ampm', this.get('ampmChoices').findBy('id', parseInt(ampm)).text);
      },

      openCalendar: function openCalendar(id) {
        var datePicker = "input[name='" + this.get('name') + "']";
        $(datePicker).click();
      }

    }
  });
});
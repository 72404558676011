define('ohs/components/avatar/x-helmet', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        colors: {
            helmet: '#B2B4B8',
            outline: '#6B44CC',
            face: '#9CD3EF',
            glare: '#D2F2FF',
            attributes: '#152761'
        }
    });
});
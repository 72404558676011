define('ohs/routes/teacher-quiz-details', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Quiz Details',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        // quizDetails: this.store.query('quiz',{teacherId: params.teacher_id,createdOrGiven:params.return_detail}),
        quizDetails: this.store.query('quiz', { teacherId: params.teacher_id }),
        user: this.store.findRecord('user', params.teacher_id),
        quizDetailOptions: [{
          text: "Title",
          id: 1
        }, {
          text: "Subject",
          id: 2
        }, {
          text: "Standard",
          id: 3
        }, {
          text: "Date Created",
          id: 4
        }, {
          text: "Times Given",
          id: 5
        }, {
          text: "Score",
          id: 6
        }],
        curSort: 1,
        curQuizDetails: Ember.computed('quizDetails.@each.id', 'curSort', function () {
          var results;
          if (this.curSort == 1) {
            results = this.quizDetails.sortBy('quizName');
          } else if (this.curSort == 2) {
            results = this.quizDetails.sortBy('subject.text').reverse();
          } else if (this.curSort == 3) {
            results = this.quizDetails.sortBy('standardCodeDisplay').reverse();
          } else if (this.curSort == 4) {
            results = this.quizDetails.sortBy('createdOn').reverse();
          } else if (this.curSort == 5) {
            results = this.quizDetails.sortBy('timesGiven').reverse();
          } else if (this.curSort == 6) {
            results = this.quizDetails.sortBy('avgScore').reverse();
          }
          return results;
        })
      });
    },


    actions: {
      sortBy: function sortBy(selectedValue) {
        this.set('currentModel.curSort', selectedValue);
      }
    }
  });
});
define('ohs/mixins/validate-form-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        validate: function validate(model) {
            return new Ember.RSVP.Promise(function (resolve, reject) {
                if (!model.invalidAttributes) {
                    model.invalidAttributes = new Array();
                }

                if (model.invalidAttributes.get('length') == 0) {
                    Ember.set(model, 'tick', model.tick + 1);
                    Ember.run.later(function () {
                        if (model.invalidAttributes.get('length') > 0) {
                            //errors present 
                            reject();
                        } else {
                            resolve();
                        }
                    }, 1000);
                }
            });
        }
    });
});
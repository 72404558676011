define('ohs/components/game-mode-rover-waiting', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            Ember.set(this, 'wheel', this.$().find('.wheel'));
            Ember.set(this, 'wheelFront', this.$().find(".wheel.front"));
            Ember.set(this, 'wheelMid', this.$().find(".wheel.mid"));
            Ember.set(this, 'wheelBack', this.$().find(".wheel.back"));
            Ember.set(this, 'neck', this.$().find(".neck"));
            Ember.set(this, 'bodyBar', this.$().find(".body-bar"));
            Ember.set(this, 'star', this.$().find(".star"));
            Ember.set(this, 'head', this.$().find(".rover-head"));
            Ember.set(this, 'body', this.$().find(".rover-body"));
            Ember.set(this, 'pupil', this.$().find(".pupil"));
            Ember.set(this, 'dust', this.$().find(".dust"));
            Ember.set(this, 'tlRoverBounce', new TimelineMax({ repeat: -1 }));
            Ember.set(this, 'tlWheelsRotate', new TimelineMax({ repeat: -1 }));
            Ember.set(this, 'tlStars', new TimelineLite());
            Ember.set(this, 'tlRoverEnter', new TimelineLite());

            //Rotate Wheels
            Ember.get(this, 'tlWheelsRotate').to(Ember.get(this, 'wheel'), 1, { rotation: 360, delay: 0, transformOrigin: "50% 50%", ease: Linear.easeNone });

            //Wheels and Body Bounce
            Ember.get(this, 'tlRoverBounce').to(Ember.get(this, 'wheelFront'), 0.15, { y: -5, delay: 3 }).to(Ember.get(this, 'wheelFront'), 0.7, { y: 0, ease: Bounce.easeOut }, "roverBodyStart").to(Ember.get(this, 'wheelMid'), 0.15, { y: -5 }, "-=0.7").to(Ember.get(this, 'wheelMid'), 0.7, { y: 0, ease: Bounce.easeOut }, "-=0.5").to(Ember.get(this, 'wheelBack'), 0.15, { y: -5 }, "-=0.7").to(Ember.get(this, 'wheelBack'), 0.7, { y: 0, ease: Bounce.easeOut }, "-=0.5")
            // Body Bounce
            .to(Ember.get(this, 'body'), 0.25, { y: -12 }, "roverBodyStart").to(Ember.get(this, 'body'), 2, { y: 0, ease: Bounce.easeOut }, "roverBodyStart+=0.25")
            // Body Bounce
            .to(Ember.get(this, 'head'), 0.25, { y: -12 }, "roverBodyStart+=0.1").to(Ember.get(this, 'head'), 2, { y: 0, ease: Bounce.easeOut }, "roverBodyStart+=0.35")
            // Neck Bounce
            .to(Ember.get(this, 'neck'), 0.25, { y: -6 }, "roverBodyStart+=0.1").to(Ember.get(this, 'neck'), 2, { y: 0, ease: Bounce.easeOut }, "roverBodyStart+=0.35")
            // Body Bar
            .to(Ember.get(this, 'bodyBar'), 0.25, { y: -3 }, "roverBodyStart+=0.1").to(Ember.get(this, 'bodyBar'), 2, { y: 0, ease: Bounce.easeOut }, "roverBodyStart+=0.35");

            // Stars 
            Ember.get(this, 'tlStars').staggerFromTo(Ember.get(this, 'star'), 0.3, { scale: 0, autoAlpha: 0 }, { scale: 1, autoAlpha: 1, transformOrigin: "50% 50%", ease: Back.easeInOut, delay: 1 }, 0.05).staggerFromTo(Ember.get(this, 'star'), 2, { opacity: 1, scale: 1 }, { opacity: 0.25, scale: 1.4, repeat: -1, transformOrigin: "50% 50%", yoyo: true, ease: Back.easeInOut }, 0.5);
            // Rover Body
            Ember.get(this, 'tlRoverEnter').fromTo(Ember.get(this, 'body'), 2, { y: -12 }, { y: 0, ease: Bounce.easeOut }).fromTo(Ember.get(this, 'head'), 2, { y: -12 }, { y: 0, ease: Bounce.easeOut }, "-=2").fromTo(Ember.get(this, 'wheel'), 2, { y: -12 }, { y: 0, ease: Bounce.easeOut }, "-=2");
        }
    });
});
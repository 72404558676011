define('ohs/routes/student/quiz/teacher-led/ela/in-progress', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        activate: function activate() {
            Ember.run.schedule('afterRender', this, function () {
                function backToTopHandler() {
                    var startTop = $('#back-to-top').offset().top;

                    $(window).on('scroll', function (e) {
                        var newTop = $(window).scrollTop();

                        if (newTop > startTop) {
                            $('#back-to-top').addClass('is-visible');
                        } else {
                            $('#back-to-top').removeClass('is-visible');
                        }
                    });
                }

                function questionMobileNavFixedHandler() {
                    var startTop = $('.view-mode-selection').offset().top + 150;
                    $(window).on('scroll', function (e) {
                        var newTop = $(window).scrollTop();
                        if (newTop > startTop) {
                            $('.view-mode-selection').addClass('is-fixed');
                        } else {
                            $('.view-mode-selection').removeClass('is-fixed');
                        }
                    });
                }

                backToTopHandler();
                questionMobileNavFixedHandler();
            });
        },


        actions: {
            toggleViewMode: function toggleViewMode() {
                var mode = this.get("viewMode");

                // if(mode == 'passage'){
                //   this.set('previousPosition', this.$(window).scrollTop())
                // }
                // else {
                //   this.$('html, body').animate({scrollTop: this.get('previousPosition')});
                // }

                this.set('currentModel.viewMode', this.get('currentModel.otherViewMode'));
            }
        }
    });
});
define('ohs/components/eligible-contents', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['eligible-contents'],
    allowToggling: true,
    alwaysOpen: false,
    children: [],
    session: Ember.inject.service('session'),

    // only show the toggler for many contents
    toggleable: Ember.computed('contents.length', function () {
      return this.get('contents').get('length') > 3;
    }),

    // shown defaults to the opposite of toggleable
    shown: Ember.computed('contents.length', function () {
      return !this.get("toggleable");
    }),

    registerChild: function registerChild(child) {
      this.get('children').pushObject(child);
    },

    standardLabelText: Ember.computed(function () {
      var standardType = this.get('session').get('standardType');
      return standardType.get('level3Label');
    }),

    standardLabelAltText: Ember.computed(function () {
      var standardType = this.get('session').get('standardType');
      return standardType.get('level3LabelAltText');
    }),

    actions: {
      toggleContents: function toggleContents() {
        if (this.get('allowToggling') && this.get('toggleable')) {
          this.toggleProperty('shown');
        }
      },

      hideOthers: function hideOthers(shown) {
        var others = this.get('children').filter(function (val) {
          return val.get('elementId') != shown.get('elementId');
        });

        others.forEach(function (item) {
          item.hide();
        });
      }
    }
  });
});
define('ohs/components/simple-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    closeModal: 'closeModal',

    classNames: ['modal'],

    click: function click(evt) {
      var targetClassName = evt.target.className || '';
      if (targetClassName.match('cancel-modal')) {
        this.sendAction('closeModal');
      }
    }
  });
});
define('ohs/routes/student-group', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _teacherAuthorizeMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _breadcrumbMixin.default, {
    titleToken: function titleToken(model) {
      return model.studentGroup.get('studentGroupName');
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel(transition) {
      var self = this;
      var groupId = transition.handlerInfos[1].params.group_id;
      var hostAddress = self.get('store').adapterFor('application').get('host');

      Ember.$.getJSON(hostAddress + '/studentGroups/updateLastUsed?groupId=' + groupId).done(function (response) {
        if (response)
          // Updated "LastUsed" column  in the "studentgroup" table.
          return;
      }).fail(function (error) {
        self.controllerFor('application').set('errorStatusCode', error.status);
        self.transitionTo('error');
      });
    },

    model: function model(params) {
      return Ember.RSVP.hash({
        students: this.store.query('student', { groupId: params.group_id }),
        studentGroup: this.store.findRecord('studentGroup', params.group_id, { reload: true }),
        searchBox: '',
        showUsernamesPasswords: false,
        orderedStudents: Ember.computed('students.@each.id', function () {
          return this.students.sortBy('lastName');
        }),
        filteredStudents: Ember.computed('students.@each.id', 'searchBox', function () {
          return this.students.filter(function (stu, index, array) {
            if (this.searchBox) {
              return stu.get('fullName').toLowerCase().includes(this.searchBox.toLowerCase()) || stu.get('userName').toLowerCase().includes(this.searchBox.toLowerCase());
            } else {
              return true;
            }
          }, this).sortBy('lastName');
        })
      });
    },


    afterModel: function afterModel(model) {
      this._super(model, model.studentGroup.get('studentGroupName'), model.studentGroup.get('id'));
    },

    deactivate: function deactivate() {
      $('li:contains("Students")').find('li:contains("My Groups")').children().first().removeClass('active');
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Students")').find('li:contains("My Groups")').children().first().addClass('active');
      }, 100);
    },

    actions: {
      checkDeleteGroup: function checkDeleteGroup() {
        this.set('currentModel.showModal', true);
      },

      deleteGroup: function deleteGroup() {
        this.set('currentModel.showModal', false);
        var self = this;
        var group = this.get('currentModel.studentGroup');
        group.destroyRecord().then(function () {
          self.set('currentModel.showConfirm', true);
        }, function () {
          self.set('currentModel.showAlert', true);
          self.set('currentModel.alertMessage', 'Your group could not be deleted. Please check your internet connection and try again.');
        });
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
        this.set('currentModel.alertMessage', '');
      },

      hideConfirm: function hideConfirm() {
        this.set('currentModel.showConfirm', false);
        this.transitionTo('students');
      },

      addStudents: function addStudents() {
        this.transitionTo('student-group-add-remove', this.get('currentModel.studentGroup').get('id'));
      },

      drill: function drill(id) {
        this.transitionTo('student-profile', id);
      },

      edit: function edit() {
        this.transitionTo('student-group-edit', this.get('currentModel.studentGroup.id'));
      },

      setShowUsernames: function setShowUsernames() {
        var currentlyShown = this.get('currentModel.showUsernamesPasswords');
        this.set('currentModel.showUsernamesPasswords', !currentlyShown);
      },

      printStudents: function printStudents() {
        if (this.get('currentModel.studentGroup.numStudents') && this.get('currentModel.studentGroup.numStudents') > 0) {
          var self = this;
          self.set('currentModel.isPrinting', true);

          var groupId = this.get('currentModel.studentGroup.id');
          var groupName = this.get('currentModel.studentGroup.studentGroupName');
          var baseUrl = this.store.adapterFor('application').get('host');

          var sesObj = this.get('session');
          var userId = sesObj.session.content.authenticated["userId"];

          return Ember.$.getJSON(baseUrl + '/studentGroups/GenerateStudentsInGroupPdf/' + groupId + '/' + encodeURIComponent(userId)).done(function (response) {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', baseUrl + '/quiz/DownloadQuizQuestions?fileName=' + encodeURIComponent(response), true);
            xhr.responseType = "blob";
            xhr.onreadystatechange = function () {
              if (xhr.readyState == 4) {
                var blob = new Blob([xhr.response], { type: "application/pdf" });
                // var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
                // if(isSafari){
                //   var blob = new Blob([xhr.response], {type: "application/octet-stream"});
                // }
                // else{
                //   var blob = new Blob([xhr.response], {type: "application/pdf"});
                // }
                saveAs(blob, response);
              }
            };
            xhr.send(null);

            self.set('currentModel.isPrinting', false);
          }).fail(function (response) {
            self.set("currentModel.error", true);
            self.set('currentModel.isPrinting', false);
          });
        } else {
          this.set('currentModel.showAlert', true);
          this.set('currentModel.alertMessage', 'This group doesn\'t have any students to print!');
        }
      },
      printParentLetters: function printParentLetters() {
        var _this = this;

        if (this.get('currentModel.studentGroup.students') && this.get('currentModel.studentGroup.numStudents') > 0) {
          this.set('currentModel.isPrinting', true);
          var host = this.store.adapterFor('application').get('host');
          var userId = this.get('session').session.content.authenticated["userId"];

          return Ember.$.getJSON(host + '/students/GenerateTeacherLinkedStudentParentLetter?groupid=' + this.get('currentModel.studentGroup.id')).done(function (response) {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', host + '/quiz/DownloadQuizQuestions?fileName=' + encodeURIComponent(response), true);
            xhr.responseType = "blob";
            xhr.onreadystatechange = function () {
              if (xhr.readyState == 4) {
                var blob = new Blob([xhr.response], { type: "application/pdf" });
                saveAs(blob, response);
              }
            };
            xhr.send(null);
            _this.set('currentModel.isPrinting', false);
          });
        } else {
          this.set('currentModel.showAlert', true);
          this.set('currentModel.alertMessage', 'There is no student on your roster to print!');
        }
      }
    }

  });
});
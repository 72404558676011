define('ohs/controllers/parent/resource-detail', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isitBookmarked: false,

    isBookmarked: function () {
      var bookmarked = this.get('isitBookmarked');
      return bookmarked ? 'bookmarked' : '';
    }.property('isitBookmarked'),

    actions: {
      openModal: function openModal(target) {
        var modal = this.get('comp-' + target);
        modal.send('toggleModal');
      },
      bookmarkIt: function bookmarkIt() {
        this.get('isitBookmarked').toggleProperty('isitBookmarked');
      }
    }
  });
});
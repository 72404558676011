define('ohs/models/roster-creation-error', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    teacherEmail: _emberData.default.attr('string'),
    studentUsername: _emberData.default.attr('string'),
    errorDate: _emberData.default.attr('date'),
    errorMessage: _emberData.default.attr('string')
  });
});
define('ohs/components/x-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['aria-hidden', 'role', 'aria-labeledby'],
    role: 'dialog',
    enabled: false,
    isValid: true,
    'aria-labeledby': 'modal-content',
    cancelText: 'Cancel',
    secondaryAction: null,
    secondaryText: null,
    input_value: null,
    names: [],
    validationMessage: '',

    'aria-hidden': function () {
      return !this.get('enabled') + '';
    }.property('enabled'),

    enabledClasses: function () {
      return this.get('enabled') ? 'is-open' : 'is-hidden';
    }.property('enabled'),

    showNames: function () {
      console.log('firing computed value updater');
      return this.get('names').length > 0;
    }.property('names.length'),

    actions: {
      toggleModal: function toggleModal() {
        this.set('validationMessage', null);
        this.toggleProperty('enabled');
        this.sendAction('clearAction');
      },

      cancel: function cancel() {
        this.set('validationMessage', null);
        this.toggleProperty('enabled');
        this.sendAction('cancelAction');
      },
      sendSecondaryAction: function sendSecondaryAction() {
        this.sendAction('secondaryAction');
      },
      acceptClicked: function acceptClicked() {
        if (this.attrs.accept) {
          this.attrs.accept();
        } else {
          if (this.get('isValid')) {
            this.sendAction('acceptClicked');
            this.send('toggleModal');
          } else {
            this.sendAction('validateAction');
          }
        }
      }
    }
  });
});
define('ohs/routes/force-password-change', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset'], function (exports, _authenticatedRouteMixin, _reset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, {

    titleToken: 'Change Password',

    session: Ember.inject.service('session'),

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', false);
    },

    model: function model(params) {
      var self = this;
      return Ember.RSVP.hash({
        userId: self.get('session').session.content.authenticated["userId"],
        newPassword: '',
        confirmPassword: '',
        userName: '',
        validationErrors: '',
        successMessages: '',
        tick: 1,
        syncPassword: 1
      });
    },


    actions: {
      changePassword: function changePassword() {
        document.activeElement.blur();

        if (!this.get('currentModel.invalidAttributes')) {
          this.set('currentModel.invalidAttributes', new Array());
        }
        var self = this;

        if (this.get('currentModel.invalidAttributes.length') == 0) {
          this.set('currentModel.isProcessing', true);

          //increment the tick so that validation is done for each field
          this.set('currentModel.tick', this.get('currentModel.tick') + 1);

          setTimeout(function () {
            if (self.get('currentModel.invalidAttributes.length') > 0) {
              //user needs to fix validation errorMessage
              self.set('currentModel.isProcessing', false);
            } else {
              var baseUrl = self.store.adapterFor('application').get('host');
              Ember.$.post(baseUrl + '/SetPassword/?newPassword=' + encodeURIComponent(self.currentModel.newPassword)).done(function (response) {
                var user = self.store.peekRecord('user', self.currentModel.userId);
                if (user) {
                  user.set('forcePasswordChange', false);
                }
                self.set("currentModel.userName", response.UserName);
                self.set("currentModel.validationErrors", '');
                self.set('currentModel.showConfirm', true);
              }).fail(function (response) {
                self.set("currentModel.successMessages", '');
                self.set("currentModel.validationErrors", response.responseText);
              });
            }
          }, 250);
        }
      },

      hideConfirm: function hideConfirm() {
        this.set('currentModel.showConfirm', false);
        Ember.set(this, 'session.session.content.authenticated.forcePasswordChange', false);
        this.transitionTo('home');
      }

    }

  });
});
define('ohs/routes/manage-students', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Manage Students',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          } else if (roles.indexOf("Parent") > 0) {
            this.controllerFor('application').set('parent_many_children', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
          this.controllerFor('application').set('parent_many_children', '');
        }
      }
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var account = this.store.peekAll('account');
      var accountId = account.objectAt(0).id;
      return Ember.RSVP.hash({
        students: this.store.query('student', { accountId: accountId }),
        userCreationError: this.store.query('userCreationError', { userRole: 'Student' }),
        orderedStudents: Ember.computed('students', 'searchBox', function () {
          return this.students.filter(function (stu, index, array) {
            if (this.searchBox) {
              return stu.get('fullName').toLowerCase().includes(this.searchBox.toLowerCase()) || stu.get('userName').toLowerCase().includes(this.searchBox.toLowerCase());
            } else {
              return true;
            }
          }, this).sortBy('fullNameReversed');
        }),
        loadFinished: true,
        searchBox: ''
      });
    },


    afterModel: function afterModel(model) {
      this._super(model, 'Manage Students', false, true);
    },

    actions: {
      generateStudentUserTemplate: function generateStudentUserTemplate() {
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = 'StudentUserTemplate.csv';

        var xhr = new XMLHttpRequest();
        xhr.open('GET', baseUrl + '/students/DownloadStudentUserTemplate?fileName=' + encodeURIComponent(fileName), true);
        xhr.responseType = "blob";
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            var blob = new Blob([xhr.response], { type: "text/csv;base64" });

            // var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
            // if(isSafari){
            //   var blob = new Blob([xhr.response], {type: "application/octet-stream"});
            // }
            // else{
            //   var blob = new Blob([xhr.response], {type: "application/pdf"});
            // }
            saveAs(blob, fileName);
          }
        };
        xhr.send(null);
      },
      uploadStudentUsers: function uploadStudentUsers() {
        var self = this;
        self.set("currentModel.loadFinished", false);
        $("#uploadStatus").empty();
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = 'StudentUserTemplate.csv';
        var file = $('[name="uploadStudentUsersList"]').prop("files")[0];

        if (file) {
          // Csv file validation
          // var validExts = new Array(".xlsx", ".xls", ".csv");
          var validExts = new Array(".xls", ".csv");
          var fileExt = file.name.substring(file.name.lastIndexOf('.'));
          if (validExts.indexOf(fileExt) < 0) {
            self.set("currentModel.showAlert", true);
            self.set('currentModel.alertMessage', "Please upload a valid file!");
            self.set("currentModel.loadFinished", true);
            return;
          }

          var sesObj = this.get('session');
          var userId = sesObj.session.content.authenticated["userId"];

          var formData = new FormData();
          formData.append(fileName, file);

          var xhr = new XMLHttpRequest();
          xhr.open('POST', baseUrl + '/accounts/UploadStudentUsers?userId=' + encodeURIComponent(userId), true);

          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
              self.set("currentModel.showConfirm", true);

              var response = xhr.response.split('"').join("");
              if (typeof InstallTrigger !== 'undefined') {
                response = $(response).text();
              }

              self.set("currentModel.confirmMessage", response);
            }
          };

          xhr.send(formData);
        } else {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Please select a file to upload!");
          self.set("currentModel.loadFinished", true);
        }
      },
      updateErrorUser: function updateErrorUser(errorUser) {
        this.transitionTo('new-user', 'Student', errorUser.get('id'));
      },
      confirmRemoveErrorUser: function confirmRemoveErrorUser(errorUser) {
        this.set('currentModel.selectedErrorUser', errorUser);
        this.set('currentModel.showErrorUserConfirm', true);
        this.set('currentModel.modalMessage', 'Are you sure you want to dismiss this error? The student account will not be created.');
      },
      removeErrorUser: function removeErrorUser() {
        var self = this;
        self.set('currentModel.showErrorUserConfirm', false);
        self.set('currentModel.modalMessage', null);
        self.set("currentModel.loadFinished", false);
        var errorUser = this.get('currentModel.selectedErrorUser');

        errorUser.destroyRecord().then(function () {
          self.set("currentModel.showConfirm", true);
          self.set("currentModel.confirmMessage", 'Success!');
        }, function () {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Student could not be deleted from the error list. Please check your Internet connection and try again.");
        });
      },
      drill: function drill(id) {
        this.transitionTo('manage-student-profile', id);
      },
      confirmRemoveStudent: function confirmRemoveStudent(student) {
        this.set('currentModel.selectedStudent', student);
        this.set('currentModel.showModal', true);
        this.set('currentModel.modalMessage', 'Are you sure you want to delete this student from account?');
      },
      removeStudent: function removeStudent() {
        this.set('currentModel.showModal', false);
        this.set('currentModel.modalMessage', null);

        var self = this;
        var student = this.get('currentModel.selectedStudent');
        student.destroyRecord().then(function () {
          //self.refresh();
          self.set("currentModel.showConfirm", true);
          self.set("currentModel.confirmMessage", 'Student has been deleted from your account!');
        }, function () {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Student could not be deleted. Please check your Internet connection and try again.");
        });
      },


      hideConfirm: function hideConfirm() {
        var self = this;
        self.refresh();
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
        this.set('currentModel.alertMessage', '');
      },

      createStudent: function createStudent() {
        this.transitionTo('new-user', 'Student', 0);
      },


      showUploadSection: function showUploadSection() {
        if (this.get('currentModel.showUploadSection')) {
          this.set('currentModel.showUploadSection', false);
        } else {
          this.set('currentModel.showUploadSection', true);
        }
      }

    }
  });
});
define('ohs/mixins/tip-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        store: Ember.inject.service(),

        destroyTip: function destroyTip(id) {
            var _this = this;

            return new Ember.RSVP.Promise(function (resolve, reject) {
                var store = Ember.get(_this, 'store');
                var tip = store.peekRecord('tip', id);

                // can't destroy the tip if it doesn't exist
                if (!tip) {
                    resolve();
                    return;
                }

                // destroy the tip then unload the tip
                // HACK: this is a workaround for an ember issue. 
                tip.destroyRecord().then(function () {
                    store.unloadRecord(tip);
                    resolve();
                }, reject);
            });
        }

    });
});
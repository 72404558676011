define('ohs/components/x-navigation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['aria-hidden', 'role', 'aria-labeledby'],
    classNames: ['navigation-wrapper'],
    classNameBindings: ['isOpenUtility'],
    role: 'dialog',
    'aria-labeledby': 'utility-nav-content',
    isOpenUtility: false,
    session: Ember.inject.service('session'),

    userRole: Ember.computed('session', function () {
      var roles = this.get('curSes').session.content.authenticated["roles"];
      if (roles.includes("Teacher")) {
        return "Teacher";
      } else if (roles.includes("Student")) {
        return "Student";
      } else if (roles.indexOf("Parent") > 0) {
        return "Parent";
      }
    }),

    displayName: Ember.computed('session', function () {
      return this.get('curSes').session.content.authenticated["displayName"];
    }),

    'aria-hidden': function () {
      return !this.get('isOpenUtility');
    }.property('isOpenUtility'),

    actions: {
      openUtility: function openUtility() {
        this.set('isOpenUtility', !this.get('isOpenUtility'));
      },

      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    }

  });
});
define('ohs/routes/tutor', ['exports', 'ohs/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        model: function model() {
            var store = Ember.get(this, 'store');
            var userId = Ember.get(this, 'token.userId');

            return Ember.RSVP.hash({
                tutor: store.findRecord('tutor', userId)
            });
        }
    });
});
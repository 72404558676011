define('ohs/components/search-standard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    assessmentClass: '',
    eligibleClass: '',
    counter: 250,
    more_checkbox_1: false,
    more_checkbox_2: false,

    watchmore_checkbox_1: function () {
      var checked = !this.get('more_checkbox_1');
      var counter = this.get('counter');
      this.set('counter', checked ? counter + 50 : counter - 50);
    }.observes('more_checkbox_1'),

    watchmore_checkbox_2: function () {
      var checked = !this.get('more_checkbox_2');
      var counter = this.get('counter');
      this.set('counter', checked ? counter + 50 : counter - 50);
    }.observes('more_checkbox_2'),

    level1LabelText: Ember.computed('model.standardType.level1Label', 'model.selectedGrade', function () {
      if (this.get('model.selectedGrade') == 1 || this.get('model.selectedGrade') == 2 || this.get('model.selectedGrade') == 23) {
        return this.get('model.standardType.level1LabelAltText');
      } else {
        return this.get('model.standardType.level1Label');
      }
    }),

    level2LabelText: Ember.computed('model.standardType.level2Label', 'model.selectedGrade', function () {
      if (this.get('model.selectedGrade') == 1 || this.get('model.selectedGrade') == 2 || this.get('model.selectedGrade') == 23) {
        return this.get('model.standardType.level2LabelAltText');
      } else {
        return this.get('model.standardType.level2Label');
      }
    }),

    level3LabelText: Ember.computed('model.standardType.level3Label', 'model.selectedGrade', function () {
      if (this.get('model.selectedGrade') == 1 || this.get('model.selectedGrade') == 2 || this.get('model.selectedGrade') == 23) {
        return this.get('model.standardType.level3LabelAltText');
      } else {
        return this.get('model.standardType.level3Label');
      }
    }),

    //// computeds
    reportingCategories: Ember.computed('model.selectedGrade', 'model.selectedSubject', 'model.standards.@each.id', function () {
      var stan = Ember.get(this, 'model.standards');
      var gradeLevelId = Ember.get(this, 'model.selectedGrade');
      var subjectId = Ember.get(this, 'model.selectedSubject');
      stan = stan.filterBy('standardLevelId', 1).filterBy('gradeLevelId', gradeLevelId).filterBy('subjectId', subjectId).sortBy('standardOrderId');
      return stan;
    }),

    assessmentAnchors: Ember.computed('model.selectedReportingCategory', 'model.standards.@each.id', function () {
      var stan = Ember.get(this, 'model.standards');
      var rc = Ember.get(this, 'model.selectedReportingCategory');
      stan = stan.filterBy('parentStandardId', rc).sortBy('standardOrderId');
      return stan;
    }),

    eligibleContent: Ember.computed('model.selectedAssessmentAnchor', 'model.standards.@each.id', function () {
      var stan = Ember.get(this, 'model.standards');
      var anchor = Ember.get(this, 'model.selectedAssessmentAnchor');
      stan = stan.filterBy('parentStandardId', anchor).sortBy('standardOrderId');
      return stan;
    }),

    actions: {
      openAnchors: function openAnchors() {
        this.set('assessmentClass', 'active');
        var counter = this.get('counter');
        this.set('counter', counter - 50);

        Ember.run.later(this, function () {
          var top = this.$('.assess-anchor').offset().top;
          Ember.$('html, body').animate({
            scrollTop: top
          });
        }, 400);
      },

      openEligible: function openEligible() {
        this.set('eligibleClass', 'active');
        var counter = this.get('counter');
        this.set('counter', counter - 50);

        Ember.run.later(this, function () {
          var top = this.$('.eligible-content').offset().top;
          Ember.$('html, body').animate({
            scrollTop: top
          });
        }, 400);
      },

      setGradeLevel: function setGradeLevel(selectedValue) {
        this.set('model.selectedEligibleContent', []);
        this.set('model.eligibleContentQueryString', '');
        this.set('model.selectedAssessmentAnchor', '');
        this.set('model.selectedReportingCategory', '');
        this.set('model.selectedGrade', parseInt(selectedValue));
        this.set('assessmentClass', '');
        this.set('eligibleClass', '');
      },

      setSubject: function setSubject(selectedValue) {
        this.set('model.selectedEligibleContent', []);
        this.set('model.eligibleContentQueryString', '');
        this.set('model.selectedAssessmentAnchor', '');
        this.set('model.selectedReportingCategory', '');
        this.set('model.selectedSubject', parseInt(selectedValue));
        this.set('assessmentClass', '');
        this.set('eligibleClass', '');
      },

      setReportingCategory: function setReportingCategory(selectedValue) {
        this.set('model.selectedEligibleContent', []);
        this.set('model.eligibleContentQueryString', '');
        this.set('model.selectedAssessmentAnchor', '');
        this.set('model.selectedReportingCategory', parseInt(selectedValue));
        this.set('eligibleClass', '');
        if (selectedValue == "") {
          this.set('assessmentClass', '');
        } else {
          this.set('assessmentClass', 'active');
        }
      },

      setAssessmentAnchor: function setAssessmentAnchor(selectedValue) {
        this.set('model.selectedEligibleContent', []);
        this.set('model.eligibleContentQueryString', '');
        this.set('model.selectedAssessmentAnchor', parseInt(selectedValue));
        if (selectedValue == "") {
          this.set('eligibleClass', '');
        } else {
          this.set('eligibleClass', 'active');
        }
      },

      setEligibleContent: function setEligibleContent(selectedValue) {

        var eligibleContentId = selectedValue;
        if (eligibleContentId) {
          var ecs = this.get('model.selectedEligibleContent'),
              list = ecs.toArray();

          var removeStandard;

          list.forEach(function (standard) {
            if (standard == eligibleContentId) {
              removeStandard = standard;
            }
          });

          if (removeStandard) {
            ecs.removeObject(removeStandard);
          } else {
            var selectedStandard = eligibleContentId;
            ecs.pushObject(selectedStandard);
          }

          //set the eligible content query string
          var qs = '';
          if (ecs) {
            for (var i = 0; i < ecs.length; i++) {
              qs += ecs[i] + ",";
            }
            if (qs.length > 0) {
              qs = qs.substr(0, qs.length - 1);
            }
          }
          this.set('model.eligibleContentQueryString', qs);
        }
      },


      setTarget: function setTarget(value) {
        this.sendAction('setTargetAction', value);
      }

    }
  });
});
define('ohs/mixins/signalr-teacher-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        actions: {
            joinStudent: function joinStudent() {
                console.log('one joined');
                this.refresh();
            },
            studentLeft: function studentLeft() {
                console.log('one left');
                this.refresh();
            },
            studentFinished: function studentFinished() {
                console.log('student finished');
                this.refresh();
            },
            refreshModel: function refreshModel() {
                console.log('refresh');
                this.refresh();
            },
            changeQuestion: function changeQuestion(questionIndex, answersLocked, administrationId) {
                console.log('change question');
            },
            signalRConnectionAlert: function signalRConnectionAlert(message, messageClass, iconClass) {
                console.log('connection alert');
            },
            updateAnswer: function updateAnswer(studentAnswer) {
                console.log('update answer');
                this.get('store').find('studentAnswer', studentAnswer.id);
            },
            deleteSecondPartAnswer: function deleteSecondPartAnswer(secondPartAnswerId) {
                console.log('delete second part answer');
                var record = get(this, 'store').peekRecord('student-second-part-answer', secondPartAnswerId.toString());
                //TODO: REFACTOR
                //this is a super shitty work around for an open Ember bug. Issue: #5111
                record.get('_internalModel').transitionTo('deleted.saved');
                record.unloadRecord();
            },
            addAnswer: function addAnswer(studentAnswer) {
                console.log('add answer');
                get(this, 'store').find('studentTestAdministration', get(studentAnswer, 'studentTestId'));
            },
            endAdministration: function endAdministration(administrationId) {
                console.log('end administration');
            },
            joinRoster: function joinRoster() {
                console.log('student joined roster');
                this.refresh();
            }
        }
    });
});
define('ohs/routes/purchase-success', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        store: Ember.inject.service(),

        titleToken: 'Purchase Success',

        model: function model(params) {
            //gets the list of current subjects for account 
            var purchasedSubscription = this.modelFor('purchase');

            if (purchasedSubscription) {
                //this class holds the endDate and the name of the subject
                var EndDate = function EndDate(endDate, subject) {
                    _classCallCheck(this, EndDate);

                    this.endDate = endDate;
                    this.subject = subject;
                };

                //maps the returned subjects to the new class
                //used to format the date
                var subjectEndDates = purchasedSubscription.subscriptionEndDates.map(function (a) {
                    return new EndDate(new Date(a.endDate).toLocaleDateString("en-US"), a.text);
                });

                //return the model
                return Ember.RSVP.hash({
                    subscriptionEndDates: subjectEndDates
                });
            } else {
                this.transitionTo('purchase');
            }
        }

    });
});
define('ohs/components/navigation-wrapper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'navigation-wrapper',
    children: [],
    registerChild: function registerChild(child) {
      this.get('children').pushObject(child);
    },

    didInsertElement: function didInsertElement() {
      this.get('parent').registerNotifications(this);
    },

    actions: {
      hideOthers: function hideOthers(shown) {
        var others = this.get('children').filter(function (val) {
          return val.get('elementId') != shown.get('elementId');
        });

        others.forEach(function (item) {
          item.hide();
        });
      },

      transitionAction: function transitionAction(route) {
        this.sendAction('transitionAction', route);
      }

    }
  });
});
define('ohs/components/x-navigation-confirm-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['aria-hidden', 'role', 'aria-labeledby'],
    classNames: ['modal'],
    classNameBindings: ['isOpen', 'isHidden'],
    role: 'dialog',
    'aria-labeledby': 'modal-content',
    isOpen: true,
    isHidden: false,
    url: null,
    user: null,
    store: Ember.inject.service(),

    'aria-hidden': function () {
      return !this.get('isOpen') + '';
    }.property('isOpen'),

    actions: {
      openModal: function openModal() {
        this.set('isOpen', true);
        this.set('isHidden', false);
      },

      closeModal: function closeModal() {
        this.set('isOpen', false);
        this.set('isHidden', true);

        this.user.rollbackAttributes();
        this.disableGoButton(false);
      },

      visitResource: function visitResource(url) {
        this.set('isOpen', false);
        this.set('isHidden', true);
        var self = this;

        this.user.save().then(function (result) {
          self.disableGoButton(false);
        }, function () {
          user.rollbackAttributes();
          self.disableGoButton(false);
        });

        window.open(url);

        if (this.get('student')) {
          var object = this.get('assignedObject');

          //Award trophy if this was previously viewed
          if (object.get('viewed')) {
            //check to see if the trophy is already awarded
            if (!this.get('hasSecondLookTrophy')) {
              this.sendAction('createTrophyAction');
            }
          }
          object.set('viewed', true);
          object.set('viewedDate', new Date());
          object.save();
        }
      },

      modifySetting: function modifySetting() {
        this.user.toggleProperty('hideNavigationWarning');
      }
    },

    disableGoButton: function disableGoButton(isDisable) {
      $('.external').prop('disabled', isDisable);
      $('.button-subtle').prop('disabled', isDisable);
    }
  });
});
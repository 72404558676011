define('ohs/models/school-year', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;
    exports.default = _emberData.default.Model.extend({
        start: attr('date'),
        end: attr('date'),
        display: attr('string'),
        isCurrent: attr('boolean'),
        sort: attr('number')
    });
});
define('ohs/models/mission-leaderboard', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = _emberData.default.Model.extend({
        rank: attr('number'),
        missionsCompleted: attr('number'),
        pointsEarned: attr('number'),

        student: belongsTo('student')
    });
});
define('ohs/components/selectable-student', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['selectable-student-item'],
    classNameBindings: ['itemClass'],
    isSelectable: false,
    isCheckable: false,

    isApproved: Ember.computed('approvedStudents.[]', 'groupId', function () {
      var _this = this;

      if (this.get('approvedStudents')) {
        return this.get('approvedStudents').some(function (approval) {
          return approval.groupId == _this.get('groupId') && approval.student.get('id') == _this.get('student.id');
        });
      } else {
        return false;
      }
    }),

    actionButtonText: Ember.computed('studentGroup.students.[]', function () {
      if (this.get('studentGroup').get('students').includes(this.get('student'))) {
        return "Remove";
      } else {
        return "+ Add";
      }
    }),

    itemClass: Ember.computed('studentGroup.students.[]', 'isApproved', function () {
      var group = this.get('studentGroup');
      if (group && group.get('students').includes(this.get('student')) || this.get('isApproved')) {
        return ' in-group';
      } else {
        return '';
      }
    }),

    acceptDisabled: Ember.computed('studentGroup.students.[]', function () {
      if (this.get('studentGroup').get('students').includes(this.get('student'))) {
        return true;
      } else {
        return false;
      }
    }),

    removeDisabled: Ember.computed('studentGroup.students.[]', function () {
      if (this.get('studentGroup').get('students').includes(this.get('student'))) {
        return false;
      } else {
        return true;
      }
    }),

    actions: {
      toggleStudent: function toggleStudent() {
        var self = this;
        self.isProcessing(true);
        if (self.get('studentGroup').get('students').includes(self.get('student'))) {
          self.get('studentGroup').get('students').removeObject(self.get('student'));
        } else {
          self.get('studentGroup').get('students').pushObject(self.get('student'));
        }
        self.get('studentGroup').save().then(function (response) {
          self.isProcessing(false);
        });
      },

      toggleApproval: function toggleApproval() {
        this.sendAction('toggleApproval', this.get('groupId'), this.get('student'));
      },

      linkAction: function linkAction() {
        this.sendAction('linkAction', this.get('student').get('id'));
      }
    },

    //Methods

    //For Enable/Disable all the buttons in the div with 'group-actions' class
    isProcessing: function isProcessing(status) {
      if (status == true) {
        //Placing a dynamic grid 'over' on the top of these button’s div tag
        $(".group-actions").append('<div id="over" class="disable"></div>');

        //Disable 'Finished' link button while doing Add/Remove student group.
        //But there is no way to disable a link.
        //As a work around, add an onclick fuction and return false
        $(".button-subtle").attr("disabled", "disabled").on("click", function () {
          return false;
        });
      } else {
        //Removes the dynamic grid 'over' from the top of the button’s div tag only after the API interaction is finished
        $(".group-actions").find("#over").remove();

        //re-enable link
        $(".button-subtle").removeAttr("disabled").off("click");
      }
    }
  });
});
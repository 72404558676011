define('ohs/routes/student-earned-trophies', ['exports', 'ohs/mixins/reset', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _reset, _authenticatedRouteMixin, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Trophies',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('student', true);
      this.controllerFor('application').set('teacher', true);
    },
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (!transition.queryParams.user_id) {
        this.transitionTo('usage-tracker');
      }
    },
    model: function model(queryParams, params) {
      if (params.queryParams.from_date && params.queryParams.to_date) {
        var categories = this.store.query('trophy-category', { userId: params.queryParams.user_id, fromDate: params.queryParams.from_date, toDate: params.queryParams.to_date, reload: true });
      } else {
        var categories = this.store.query('trophy-category', { userId: params.queryParams.user_id, filterType: params.queryParams.filter_type, reload: true });
      }
      return categories.then(function (u) {
        return Ember.RSVP.hash({
          categories: u,
          orderedCategories: Ember.computed('categories.@each.id', function () {
            return this.categories.sortBy('id');
          })
        });
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Trophies', false, true);
    },

    activate: function activate() {
      var self = this;
      window.addEventListener("resize", function (e) {
        self.resizeCanvas();
      }, false);
    },

    resizeCanvas: function resizeCanvas() {
      $('.trophy-img').each(function () {
        $(this).css('height', $(this).width() * $(this).css('min-height').replace("px", ""));
      });
    }
  });
});
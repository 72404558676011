define('ohs/routes/user-profile', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/edinsight-mixin', 'ohs/config/environment'], function (exports, _authenticatedRouteMixin, _reset, _edinsightMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_edinsightMixin.default, _reset.default, _authenticatedRouteMixin.default, {
    titleToken: 'My Account',
    //edinsight: Ember.inject.service('edinsight-service'),
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          } else if (roles.includes("Tutor")) {
            this.controllerFor('application').set('tutor', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
          this.controllerFor('application').set('parent_many_children', '');
        }
      }
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (!roles) {
          this.transitionTo('home');
        }
      }
    },

    google: Ember.inject.service('google-integration'),
    edinsight: Ember.inject.service('edinsight-service'),

    model: function model() {
      var edinsight = this.get('edinsight');
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      var roles = sesObj.session.content.authenticated["roles"];
      var store = Ember.get(this, 'store');

      if (roles) {
        if (roles.includes("Student")) {
          return Ember.RSVP.hash({
            user: store.findRecord('student', userId),
            isEmailRequired: false,
            grades: store.query('grade', { 'gradeType': 'StudentProfle' }),
            //this is only to mimic the teachers side
            filteredGrades: Ember.computed.alias('grades'),

            interestAreas: store.findAll('interest-area'),
            orderedInterests: Ember.computed('interestAreas.@each.description', function () {
              return this.interestAreas.sortBy('description');
            }),
            orderedUserInterests: Ember.computed('user.interestAreas.@each.description', function () {
              return this.user.get('interestAreas').sortBy('description');
            }),
            learningStyles: store.findAll('learning-style'),
            stateOptions: store.findAll('state'),
            state: Ember.computed('user.stateId', 'stateOptions.@each.id', function () {
              var stateId = this.user.get('stateId');
              if (stateId) return this.stateOptions.findBy('id', stateId.toString());
            }),
            gradeLevel: Ember.computed('user.gradeLevelId', 'grades.@each.id', function () {
              var gradeId = this.user.get('gradeLevelId');
              if (gradeId) {
                var selectedGrade = this.grades.findBy('id', gradeId.toString());
                if (selectedGrade) {
                  return selectedGrade.get('text');
                }
              } else {
                return "Not Provided";
              }
            })
          });
        } else {
          return Ember.RSVP.hash({
            user: store.findRecord('user', userId),
            subjects: store.findAll('subject'),
            account: store.queryRecord('account', { userId: userId }),
            isEmailRequired: true,
            ownedOnly: true,
            removeSchoology: false,
            grades: store.query('grade', { 'gradeType': 'TeacherProfle' }),
            stateOptions: store.findAll('state'),
            standardTypeOptions: store.findAll('standard-type'),
            edinsightDistrictName: Ember.computed('user.edinsightProviderId', function () {
              var providerId = this.user.get('edinsightProviderId');
              var promise = edinsight.getDistrictByProviderId(providerId).then(function (district) {
                return district.name;
              }, function () {
                return 'EdInsight';
              });
              return DS.PromiseObject.create({ promise: promise });
            }),
            state: Ember.computed('user.stateId', 'stateOptions.@each.id', function () {
              return this.stateOptions.findBy('id', this.user.get('stateId').toString());
            }),
            standardType: Ember.computed('user.standardTypeId', 'standardTypeOptions.@each.id', function () {
              return this.standardTypeOptions.findBy('id', this.user.get('standardTypeId').toString());
            }),

            filteredGrades: Ember.computed('grades.[]', 'user.primarySubjectId', function () {
              var subjectId = Ember.get(this, 'user.primarySubjectId');

              if (!subjectId) return Ember.get(this, 'grades');

              var selectedSubject = store.peekRecord('subject', subjectId);
              return Ember.get(selectedSubject, 'gradeLevels').map(function (gl) {
                return {
                  id: Ember.get(gl, 'id'),
                  text: Ember.get(gl, 'description')
                };
              });
            }),

            primarySubject: Ember.computed('user.primarySubjectId', 'account.subjects.@each.id', function () {
              var subjectId = this.user.get('primarySubjectId');
              if (subjectId) {
                var selectedSubject = this.account.get('subjects').findBy('id', subjectId.toString());
                if (selectedSubject) {
                  return selectedSubject.get('text');
                }
              } else {
                return "Not Provided";
              }
            }),

            gradeLevel: Ember.computed('user.gradeLevelId', 'grades.@each.id', function () {
              var gradeId = this.user.get('gradeLevelId');
              if (gradeId) {
                var selectedGrade = this.grades.findBy('id', gradeId.toString());
                if (selectedGrade) {
                  return selectedGrade.get('text');
                }
              } else {
                return "Not Provided";
              }
            }),
            honorificOptions: [{
              text: "Mrs",
              id: "Mrs"
            }, {
              text: "Mr",
              id: "Mr"
            }, {
              text: "Ms",
              id: "Ms"
            }, {
              text: "Miss",
              id: "Miss"
            }, {
              text: "Dr",
              id: "Dr"
            }]
          });
        }
      }
    },


    actions: {
      //edinsight actions
      unlinkEdInsight: function unlinkEdInsight(user) {
        var _this = this;

        this.toggleProperty('currentModel.syncing');
        this.get('edinsight').unlink(user).then(function () {
          _this.toggleProperty('currentModel.syncing');
          _this.toggleProperty('currentModel.showConfirm');
          _this.set('currentModel.confirmMessage', 'Success! Your account has been unlinked');
        }, function () {
          _this.toggleProperty('currentModel.syncing');
          _this.toggleProperty('currentModel.showAlert');
          _this.set('currentModel.alertMessage', 'There was an error unlinking yoru account!');
        });
      },

      linkEdInsight: function linkEdInsight(user) {
        var _this2 = this;

        this.toggleProperty('currentModel.showEdInsight');
        this.toggleProperty('currentModel.syncing');
        var districtUrl = this.get('currentModel.edinsightUrl');
        //checks for url
        if (districtUrl) {
          //get district from the url
          this.get('edinsight').getDistrictByURL(districtUrl).then(function (district) {
            //does the link
            _this2.get('edinsight').link(district.providerId, _this2.get('currentModel.user')).then(function () {
              _this2.toggleProperty('currentModel.syncing');
              _this2.toggleProperty('currentModel.showConfirm');
              _this2.set('currentModel.confirmMessage', 'Success! You have been linked to ' + district.name);
            }, function () {
              _this2.toggleProperty('currentModel.syncing');
              _this2.toggleProperty('currentModel.showAlert');
              _this2.set('currentModel.alertMessage', 'There was an error linking your account to EdInsight!');
            });
          }).catch(function (err) {
            _this2.toggleProperty('currentModel.syncing');
            _this2.toggleProperty('currentModel.showAlert');
            _this2.set('currentModel.alertMessage', 'We could not find a district with that URL!');
          });
        } else {
          this.toggleProperty('currentModel.syncing');
          this.toggleProperty('currentModel.showAlert');
          this.set('currentModel.alertMessage', 'No Url was entered!');
        }
      },

      pullFromEdInsight: function pullFromEdInsight() {
        var providerId = this.get('currentModel.user.edinsightProviderId');
        var edinsight = this.get('edinsight');
        edinsight.getDistrictByProviderId(providerId).then(function (district) {
          edinsight.getUserToken(district);
        });
      },

      //end edinsight

      changePassword: function changePassword() {
        //this.transitionTo('change-password');
        var sesObj = this.get('session');
        var userId = sesObj.session.content.authenticated["userId"];
        this.transitionTo('change-password', userId, '0');
      },


      edit: function edit() {
        this.transitionTo('edit-user-profile');
      },

      transitionAction: function transitionAction() {
        //do nothing
      },

      reloadApplicationUserModel: function reloadApplicationUserModel() {
        this.modelFor('application').user.reload();
      },

      changeAvatar: function changeAvatar() {
        this.transitionTo('change-avatar');
      },

      editInterest: function editInterest() {
        var interestAreas = this.get('currentModel.user.interestAreas');
        interestAreas.forEach(function (item) {
          item.set('selected', 'selected');
        });
        this.set('currentModel.isEditInterests', true);
      },

      cancelInterest: function cancelInterest() {
        this.get('currentModel.user').rollbackAttributes();
        this.get('currentModel.interestAreas').forEach(function (item) {
          item.set('selected', '');
        });
        var interestAreas = this.get('currentModel.user.interestAreas');
        interestAreas.forEach(function (item) {
          item.set('selected', 'selected');
        });

        this.set('currentModel.isEditInterests', false);
      },

      editLearning: function editLearning() {
        var learningStyles = this.get('currentModel.user.learningStyles');
        learningStyles.forEach(function (item) {
          item.set('selected', 'selected');
        });
        this.set('currentModel.isEditLearning', true);
      },

      cancelLearning: function cancelLearning() {
        this.get('currentModel.user').rollbackAttributes();
        this.get('currentModel.learningStyles').forEach(function (item) {
          item.set('selected', '');
        });
        var learningStyles = this.get('currentModel.user.learningStyles');
        learningStyles.forEach(function (item) {
          item.set('selected', 'selected');
        });
        this.set('currentModel.isEditLearning', false);
      },

      saveLearning: function saveLearning() {
        var self = this;
        this.set('currentModel.isProcessing', true);

        var studentLearning = this.get('currentModel.user.learningStyles');

        this.get('currentModel.learningStyles').forEach(function (item) {
          if (item.get('selected') == 'selected') {
            if (!studentLearning.findBy('id', item.get('id'))) {
              studentLearning.pushObject(item);
            }
          } else {
            if (studentLearning.findBy('id', item.get('id'))) {
              studentLearning.removeObject(item);
            }
          }
        });

        this.get('currentModel.user').save().then(function () {
          self.set('currentModel.showConfirm', true);
          self.set('currentModel.confirmMessage', 'Your information has been updated successfully!');
          self.set('currentModel.isProcessing', false);
          self.set('currentModel.isEditLearning', false);
        }, function () {
          self.set('currentModel.showAlert', true);
          self.set('currentModel.alertMessage', 'Your information could not be saved. Please check your internet connection and try again');
          self.set('currentModel.isProcessing', false);
        });
      },

      saveInterest: function saveInterest() {
        var self = this;
        this.set('currentModel.isProcessing', true);
        var studentInterests = this.get('currentModel.user.interestAreas');

        this.get('currentModel.interestAreas').forEach(function (item) {
          if (item.get('selected') == 'selected') {
            if (!studentInterests.findBy('id', item.get('id'))) {
              studentInterests.pushObject(item);
            }
          } else {
            if (studentInterests.findBy('id', item.get('id'))) {
              studentInterests.removeObject(item);
            }
          }
        });

        this.get('currentModel.user').save().then(function () {
          self.set('currentModel.showConfirm', true);
          self.set('currentModel.confirmMessage', 'Your information has been updated successfully!');
          self.set('currentModel.isProcessing', false);
          self.set('currentModel.isEditLearning', false);
        }, function () {
          self.set('currentModel.showAlert', true);
          self.set('currentModel.alertMessage', 'Your information could not be saved. Please check your internet connection and try again');
          self.set('currentModel.isProcessing', false);
        });
      },

      editGeneral: function editGeneral() {
        this.set('currentModel.user.firstNameEdit', this.get('currentModel.user.firstName'));
        this.set('currentModel.user.lastNameEdit', this.get('currentModel.user.lastName'));
        this.set('currentModel.user.emailEdit', this.get('currentModel.user.email'));
        var username = this.get('currentModel.user.userName');
        var role = this.get('currentModel.user').get('userRole');

        if (username.indexOf('.') > 0) this.set('currentModel.user.currentSuffix', username.substring(username.indexOf('.')));

        if (role == "Teacher" || role == "Tutor") {
          if (this.get('currentModel.user').get('stateId') == 39) {
            this.set('currentModel.standardTypeSelected', true);
          } else {
            this.set('currentModel.standardTypeSelected', false);
            this.set('currentModel.standardTypeId', 2011);
          }
        } else {
          this.set('currentModel.standardTypeId', null);
        }
        this.set('currentModel.isEditGeneral', true);
      },

      cancelGeneral: function cancelGeneral() {
        this.get('currentModel.user').rollbackAttributes();
        this.set('currentModel.validationMsg', null);
        this.set('currentModel.isEditGeneral', false);
      },

      setHonorific: function setHonorific(selectedValue) {
        this.set('currentModel.user.honorific', selectedValue);
      },

      setGradeLevel: function setGradeLevel(selectedValue) {
        this.set('currentModel.user.gradeLevelId', selectedValue);
      },

      setState: function setState(selectedValue) {
        var role = this.get('currentModel.user').get('userRole');
        this.set('currentModel.user.stateId', selectedValue);
        if (role == "Teacher" || role == "Tutor") {
          if (selectedValue == 39) {
            this.set('currentModel.standardTypeSelected', true);
          } else {
            this.set('currentModel.standardTypeSelected', false);
            this.get('currentModel.user').set('standardTypeId', 2011);
          }
        } else {
          this.get('currentModel.user').set('standardTypeId', null);
        }
      },

      setStandardType: function setStandardType(selectedValue) {
        this.set('currentModel.user.standardTypeId', selectedValue);
      },

      hideConfirm: function hideConfirm() {
        this.set('currentModel.showConfirm', false);
        this.set('currentModel.isEditGeneral', false);
        this.set('currentModel.isEditLearning', false);
        this.set('currentModel.isEditInterests', false);
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
        //this.set('currentModel.isEditGeneral',false);
      },

      toggleLearningStyle: function toggleLearningStyle(style) {
        if (style.get('selected') == 'selected') {
          style.set('selected', '');
        } else {
          style.set('selected', 'selected');
        }
      },

      toggleInterestArea: function toggleInterestArea(area) {
        if (area.get('selected') == 'selected') {
          area.set('selected', '');
        } else {
          area.set('selected', 'selected');
        }
      },

      linkToGoogle: function linkToGoogle() {
        var _this3 = this;

        this.get('google').linkToGoogle(this.get('currentModel.user.id')).then(function (googleUser) {
          _this3.toggleProperty('currentModel.showConfirm');
          _this3.set('currentModel.confirmMessage', 'Your accounts have been linked! Now you can log in to Kandoolu using your Google credentials!');
          _this3.set('currentModel.user.googleEmail', googleUser.getBasicProfile().getEmail());
        }, function (response) {
          _this3.toggleProperty('currentModel.showAlert');
          _this3.set("currentModel.alertMessage", response.responseText);
        });
      },

      unlinkGoogle: function unlinkGoogle() {
        var _this4 = this;

        this.get('google').unlinkGoogle(this.get('currentModel.user.id')).then(function () {
          _this4.set('currentModel.showConfirm', true);
          _this4.set('currentModel.confirmMessage', 'Success! Your Kandoolu account is no longer linked to your Google account!');
          _this4.set('currentModel.user.googleEmail', null);
        }, function (response) {
          _this4.set('currentModel.showAlert', true);
          _this4.set("currentModel.alertMessage", response.responseText);
        });
      },

      pullFromClassroom: function pullFromClassroom() {
        var _this5 = this;

        this.toggleProperty('currentModel.syncing');
        var userId = this.get('currentModel.user.id');
        this.get('google').pullClassroom(userId, this.get('currentModel.ownedOnly')).then(function () {
          _this5.toggleProperty('currentModel.showConfirm');
          _this5.set('currentModel.confirmMessage', 'Success! Your students and courses have been pulled in from Classroom!');
          _this5.toggleProperty('currentModel.syncing');
        }, function (error) {
          _this5.toggleProperty('currentModel.showAlert');
          _this5.set('currentModel.alertMessage', error);
          _this5.toggleProperty('currentModel.syncing');
        });
      },

      setOwnedOnly: function setOwnedOnly() {
        this.toggleProperty('currentModel.ownedOnly');
      },

      setRemoveStudentsSchoology: function setRemoveStudentsSchoology() {
        if (this.get('currentModel.removeSchoology')) {
          this.set('currentModel.removeSchoology', false);
        } else {
          this.set('currentModel.removeSchoology', true);
        }
      },

      unlinkSchoology: function unlinkSchoology() {
        var adapter = this.store.adapterFor('application');
        var self = this;
        var url = adapter.get('host') + '/unlinkSchoology?userId=' + this.get('currentModel.user.id');
        adapter.ajax(url, 'POST').then(function () {
          self.set('currentModel.showConfirm', true);
          self.set('currentModel.confirmMessage', 'Success! Your Kandoolu account is no longer linked to your Schoology account!');
          self.set('currentModel.user.schoologyUsername', null);
        }, function (response) {
          self.set('currentModel.showAlert', true);
          self.set("currentModel.alertMessage", response.errors[0].detail);
        });
      },

      linkToSchoology: function linkToSchoology() {
        var self = this;
        //get a request token
        Ember.$.getJSON(this.get('store').adapterFor('application').get('host') + '/getSchoologyRequestTokens').done(function (response) {
          if (response.oauth_token) {
            document.cookie = "SRT=" + response.oauth_token;
            document.cookie = "SRTS=" + response.oauth_token_secret;
            window.location = "https://www.schoology.com/oauth/authorize?return_url=" + window.location.href.replace('/user-profile', '/schoology-link-user') + "&oauth_token=" + response.oauth_token;
          } else {
            alert('fail');
          }
        }).fail(function (response) {
          alert('fail');
        });
      },

      pullFromSchoology: function pullFromSchoology() {
        var _this6 = this;

        this.set('currentModel.syncing', true);
        var baseUrl = this.store.adapterFor('application').get('host');

        Ember.$.post(baseUrl + '/pullFromSchoology?userId=' + this.get('currentModel.user.id') + '&removeStudents=' + this.get('currentModel.removeSchoology')).then(function () {
          _this6.toggleProperty('currentModel.showConfirm');
          _this6.set('currentModel.confirmMessage', 'Success! Your students and courses have been pulled in from Schoology!');
          _this6.toggleProperty('currentModel.syncing');
        }, function () {
          _this6.toggleProperty('currentModel.showAlert');
          _this6.set('currentModel.alertMessage', 'Something went wrong and we could not complete the sync! If this problem persists, please contact support@kandoolu.com.');
          _this6.toggleProperty('currentModel.syncing');
        });
      }

    }
  });
});
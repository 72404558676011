define('ohs/routes/schoology-link-user', ['exports', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/authenticated-route-mixin'], function (exports, _reset, _noNavigation, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _reset.default, _noNavigation.default, {
    beforeModel: function beforeModel() {
      var self = this;
      //get cookies
      var pairs = document.cookie.split(";");
      var cookies = {};
      for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].trim().split("=");
        cookies[pair[0]] = unescape(pair[1]);
      }

      if (cookies.SRT && cookies.SRTS) {
        var self = this;
        var adapter = this.store.adapterFor('application');
        Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/getSchoologyAccessTokens?SRT=' + cookies.SRT + '&SRTS=' + cookies.SRTS).done(function (response) {
          //clear out cookies
          var cookie_date = new Date();
          cookie_date.setTime(cookie_date.getTime() - 1);
          document.cookie = "SRT=; expires=" + cookie_date.toGMTString();
          document.cookie = "SRTS=; expires=" + cookie_date.toGMTString();
          if (response.oauth_token) {
            var url = adapter.get('host') + '/linkToSchoology?at=' + response.oauth_token + '&accessSecret=' + response.oauth_token_secret;
            adapter.ajax(url, 'POST').then(function (response) {
              self.set('currentModel.showConfirm', true);
              self.set('currentModel.confirmMessage', 'Your accounts have been linked! Now you can log in to Kandoolu using your Schoology credentials!');
            }, function (response) {
              self.set('currentModel.showAlert', true);
              self.set("currentModel.alertText", response.errors[0].detail);
            });
          } else {
            self.set('currentModel.alertText', 'Error occurred. Please try again.');
            self.set('currentModel.showLoad', false);
            self.set('currentModel.showAlert', true);
          }
        }).fail(function (response) {
          self.set('currentModel.alertText', 'Error occurred. Please try again.');
          self.set('currentModel.showLoad', false);
          self.set('currentModel.showAlert', true);
          //clear out cookies
          var cookie_date = new Date();
          cookie_date.setTime(cookie_date.getTime() - 1);
          document.cookie = "SRT=; expires=" + cookie_date.toGMTString();
          document.cookie = "SRTS=; expires=" + cookie_date.toGMTString();
        });
      } else {
        this.transitionTo('user-profile');
      }
    },

    model: function model() {
      return Ember.RSVP.hash({
        showAlert: false,
        alertText: "",
        showLoad: true
      });
    },


    actions: {
      closeAlert: function closeAlert() {
        this.transitionTo('user-profile');
      }
    }
  });
});
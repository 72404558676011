define('ohs/routes/parent/search-standard', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/parent-authorize-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _parentAuthorizeMixin, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _parentAuthorizeMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Search Standard',
    signalR: Ember.inject.service('signal-r'),

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('parent_many_children', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var searchParams = this.modelFor('parent.resource-search-start');

      if (searchParams) {
        return searchParams;
      } else {
        this.transitionTo('parent.resource-search-start');
      }
    },


    afterModel: function afterModel(model) {
      this._super(model, 'Search by Standard');
      //Signal-R event subscription
      this.get('signalR').on('linkParentStudent', this, 'linkParentStudent');
    },

    actions: {
      submit: function submit() {
        document.activeElement.blur();
        this.transitionTo('parent.search-results');
      }
    },

    linkParentStudent: function linkParentStudent(userId) {
      var sesObj = this.get('session');
      if (!sesObj.get('currentStudent')) {
        sesObj.set('currentStudent', userId);
      }
      this.refresh();
    }
  });
});
define('ohs/routes/view-class-linked-resource', ['exports', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin', 'ohs/mixins/authenticated-route-mixin'], function (exports, _reset, _breadcrumbMixin, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: function titleToken(model) {
      return model.classLinkedResource.get('resource.resourceName');
    },

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          } else if (roles.indexOf("Parent") > 0) {
            this.controllerFor('application').set('parent_many_children', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
          this.controllerFor('application').set('parent_many_children', '');
        }
      }
    },

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (Ember.get(Ember.get(this, 'store').peekRecord('class-linked-resource', transition.params['view-class-linked-resource'].class_linked_resource_id), 'currentState').isDeleted) {
        this.transitionTo('class-library', 0);
      }
    },

    model: function model(params) {
      //Find the previous route and redirect to it after completing the task
      var previousRoutes = this.router.router.currentHandlerInfos;
      var previousRoute = previousRoutes && previousRoutes.pop();

      if (previousRoute) {
        this.set('lastVisitedRoute', previousRoute.name);
        this.set('lastVisitedFolder', previousRoute.params.folder_id);
      }
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      var curUser = null;

      var isStudent = false;
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles.includes("Student")) {
          isStudent = true;
          curUser = this.store.findRecord('user', userId);
        }

        if (roles.includes("Teacher")) {
          curUser = this.store.findRecord('teacher', userId);
        }

        if (roles.includes("Tutor")) {
          curUser = this.store.findRecord('tutor', userId);
        }
      }

      if (isStudent) {
        return Ember.RSVP.hash({
          curUser: curUser,
          classLinkedResourceId: params.class_linked_resource_id,
          classLinkedResource: this.store.findRecord('class-linked-resource', params.class_linked_resource_id),
          showNavConfirmModal: false,
          classLinkedResourceStudentNotifies: this.store.peekAll('class-linked-resource-student-notify'),

          isResourceOwner: Ember.computed('curUser.id', 'classLinkedResource.teacher.id', function () {
            if (this.curUser.get('id') == this.classLinkedResource.get('teacher.id')) {
              return true;
            }
          })
        });
      } else {
        return Ember.RSVP.hash({
          curUser: curUser,
          classLinkedResourceId: params.class_linked_resource_id,
          classLinkedResource: this.store.findRecord('class-linked-resource', params.class_linked_resource_id),
          showNavConfirmModal: false,
          // start
          studentGroups: this.store.findAll('studentGroup'),
          orderedGroups: Ember.computed('studentGroups.@each.id', function () {
            return this.studentGroups.sortBy('studentGroupName');
          }),
          orderedStudents: Ember.computed('curUser.students.@each.id', function () {
            return this.curUser.get('students').sortBy('fullNameReversed');
          }),
          selectedStudents: null,
          selectedStudentGroups: [],
          isInviteAllSelected: false,
          teacherLinkedStudents: this.store.findAll('student'), //get all teacher linked students
          studentMessage: '',
          studentIds: [], //to hold student ids of student test administration


          validStudent: Ember.computed('selectedStudentGroups.length', 'selectedStudents.length', 'isInviteAllSelected', function () {
            if (this.selectedStudentGroups && this.selectedStudentGroups.length > 0 || this.selectedStudents && this.selectedStudents.length > 0 || this.isInviteAllSelected) {
              Ember.set(this, 'studentMessage', '');
              return true;
            } else {
              return false;
            }
          }),

          //END

          isResourceOwner: Ember.computed('curUser.id', 'classLinkedResource.teacher.id', function () {
            if (this.curUser.get('id') == this.classLinkedResource.get('teacher.id')) {
              return true;
            }
          })
        });
      }
    },


    afterModel: function afterModel(model) {
      this._super(model, model.classLinkedResource.get('resource.resourceName'), model.classLinkedResourceId);

      if (model.classLinkedResourceStudentNotifies) {
        var notification = model.classLinkedResourceStudentNotifies.findBy('classLinkedResource.id', model.classLinkedResource.get('id'));
        if (notification) {
          notification.destroyRecord();
        }
      }
    },

    actions: {
      resourceDetails: function resourceDetails() {
        this.transitionTo('teacher.resource-detail', this.get('currentModel.classLinkedResource.resource.id'));
      },

      confirmVisitResource: function confirmVisitResource(webUrl) {
        var self = this;
        this.disableVisitResourceButton(true);
        var user = this.get('currentModel.curUser');
        if (user.get('hideNavigationWarning')) {
          self.disableVisitResourceButton(false);
          window.open(webUrl);
        } else {
          self.set('currentModel.navigateURL', webUrl);
          self.set('currentModel.showNavConfirmModal', true);
        }
      },

      //Hide alert box
      hideAlert: function hideAlert() {
        this.set("currentModel.showAlert", false);
      },
      hideErrorMessage: function hideErrorMessage() {
        this.set('currentModel.error', false);
      },
      notifyConfirm: function notifyConfirm() {
        this.set('currentModel.confirmNotify', false);
      },

      editResource: function editResource() {
        this.transitionTo('edit-resource-classlibrary', this.get('currentModel.classLinkedResourceId'));
      },

      hideConfirm: function hideConfirm() {
        var folderId = '0';
        if (this.get('lastVisitedFolder')) {
          folderId = this.get('lastVisitedFolder');
        }
        this.transitionTo('class-library', folderId);
      },

      checkDeleteResource: function checkDeleteResource() {
        this.set('currentModel.showModal', true);
      },

      cancelStudents: function cancelStudents() {
        this.set('currentModel.selectedStudents', '');
      },
      clearStudents: function clearStudents() {
        this.set('currentModel.selectedStudents', '');
      },

      deleteResource: function deleteResource() {
        this.set('currentModel.showModal', false);
        var r = this.get('currentModel.classLinkedResource');
        var self = this;
        r.destroyRecord().then(function () {
          self.set('currentModel.showConfirm', true);
          self.set('currentModel.confirmMessage', "This item has been deleted!");
        }, function () {
          self.set('currentModel.error', true);
        });
      },

      //Add/Remove selected student group from selectedStudentGroups array
      setSelectedStudentGroup: function setSelectedStudentGroup(selectedValue) {
        var studentGroupId = selectedValue;
        if (studentGroupId) {
          var studentGroup = this.store.peekRecord('student-group', parseInt(studentGroupId));
          //If student group already exist in array remove it otherwise add it
          if (this.currentModel.selectedStudentGroups.contains(studentGroup)) {
            this.currentModel.selectedStudentGroups.removeObject(studentGroup);
          } else {
            this.currentModel.selectedStudentGroups.pushObject(studentGroup);
          }
        }
      },
      sendClassLinkedResource: function sendClassLinkedResource() {
        var self = this;
        var store = this.store;
        var hostAddress = self.get('store').adapterFor('application').get('host');
        var selectedStudentGroups = this.currentModel.selectedStudentGroups;
        var selectedStudents = this.currentModel.selectedStudents;
        // var classLinkedResourceresource = this.currentModel.classLinkedResource;

        var sesObj = this.get('session');
        var teacherId = sesObj.session.content.authenticated["userId"];
        var teacher = this.currentModel.curUser;
        var teacherLinkedStudents = this.currentModel.teacherLinkedStudents;

        if (teacher) {
          //Assign student groups
          selectedStudentGroups.forEach(function (item, index, enumerable) {
            Ember.$.getJSON(hostAddress + '/studentGroups/updateLastUsed?groupId=' + item.id).done(function (response) {
              if (response) var students = item.get('students');
              self.assignResourceToStudent(students);
            }).fail(function (error) {
              self.controllerFor('application').set('errorStatusCode', error.status);
              self.transitionTo('error');
            });
          });

          //Assign manually added students
          if (selectedStudents) {
            self.assignResourceToStudent(selectedStudents);
          }

          //teacher linked students
          var isInvitedAll = self.get('currentModel.isInviteAllSelected');
          if (isInvitedAll) {
            self.assignResourceToStudent(teacherLinkedStudents);
          }

          if (selectedStudentGroups && selectedStudentGroups.length > 0 || selectedStudents && selectedStudents.length > 0 || isInvitedAll) {
            var self = this;
            setTimeout(function () {
              if (!self.get('currentModel.showAlert')) {
                self.set('currentModel.confirmationMessage', 'The selected students have been notified!');
                self.set('currentModel.confirmNotify', true);

                self.set('currentModel.selectedStudents', null);
                self.set('currentModel.selectedStudentGroups', []);
                self.set('currentModel.isInviteAllSelected', false);
              }
            }, 1500);
          }
        } else {
          this.set('currentModel.studentMessage', 'Please select at least one student or group.');
          return false;
        }
      },
      setInviteAllStudents: function setInviteAllStudents(selectedValue) {
        this.toggleProperty('currentModel.isInviteAllSelected');
      },
      validateStudent: function validateStudent() {
        if (this.currentModel.selectedStudentGroups && this.currentModel.selectedStudentGroups.length > 0 || this.currentModel.selectedStudents && this.currentModel.selectedStudents.length > 0 || this.currentModel.isInvitedAll) {
          return true;
        } else {
          this.set('currentModel.studentMessage', 'Please select at least one student or group.');
          return false;
        }
      }
    },

    disableVisitResourceButton: function disableVisitResourceButton(isDisable) {
      $('.button-subtle').prop('disabled', isDisable);
    },
    assignResourceToStudent: function assignResourceToStudent(students) {
      var self = this;
      var store = this.store;
      var classLinkedResource = this.currentModel.classLinkedResource;

      //Iterate through students
      students.forEach(function (item, index, enumerable) {
        //Check whether resource assigned for the current student or not
        if (!self.currentModel.studentIds.contains(item.id)) {
          self.currentModel.studentIds.pushObject(item.id);

          var adapter = self.get('store').adapterFor('application');
          adapter.ajax(adapter.get('host') + '/classLinkedResourceStudentNotifies/isLinkedResourceAlreadySent/' + classLinkedResource.id + '/' + item.id, 'GET').then(function (result) {
            //check whether resource already sent to the student
            if (!result) {
              //create resource assigned
              var ra = store.createRecord('class-linked-resource-student-notify', {
                classLinkedResource: classLinkedResource,
                student: item
              });
              ra.save().then(function () {}, function (error) {
                //duplicate entry to resource user link table returns a 409 status
                if (error && error.errors && error.errors.length > 0 && error.errors[0].status == '409') {
                  ra.rollbackAttributes();
                } else if (ra.isError) {
                  ra.rollbackAttributes();
                  self.set("currentModel.showAlert", true);
                  self.set("currentModel.warningMessage", "There was an error notifying the students. Please check your internet connection and try again.");
                }
                self.set('currentModel.showConfirm', false);
              });
            } else {
              //update resource assigned to user
              self.store.queryRecord('class-linked-resource-student-notify', { classLinkedresourceId: classLinkedResource.id, studentUserId: item.id }).then(function (classLinkedResourceStudentNotify) {
                classLinkedResourceStudentNotify.set('dateNotified', new Date());
                classLinkedResourceStudentNotify.save().then(function () {}, function (error) {
                  self.set("currentModel.showAlert", true);
                  self.set("currentModel.warningMessage", "There was an error notifying the students. Please check your internet connection and try again.");
                });
              });
            }
          }, function () {
            self.set("currentModel.showAlert", true);
            self.set("currentModel.warningMessage", "There was an error notifying the students. Please check your internet connection and try again.");
          });
        }
      });
    }
  });
});
define('ohs/routes/student-result-list', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/student-authorize-mixin', 'ember-infinity/mixins/route', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _studentAuthorizeMixin, _route, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _studentAuthorizeMixin.default, _route.default, _breadcrumbMixin.default, {
    titleToken: 'My Previous Results',

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('student', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var user = this.modelFor('application').user;
      var store = Ember.get(this, 'store');

      return Ember.RSVP.hash({
        quizCode: "",
        errorMessage: "",
        administrationId: "",
        numberShown: 3,
        selectedSubjectId: null,
        student: user,

        administrations: store.findAll('administration'),
        studentTestAdministrations: store.query('studentTestAdministration', { isOpen: false }),
        subjects: store.peekAll('subject'),
        spaceWalkTrophy: this.store.findRecord('trophy', 3),
        studentTrophies: this.modelFor('application').studentTrophies,

        usedSubjects: Ember.computed('subjects.[]', 'studentTestAdmiinstrations.[]', function () {
          return Ember.get(this, 'studentTestAdministrations').reduce(function (current, sta) {
            return !current.some(function (sub) {
              return Ember.get(sub, 'id') == Ember.get(sta, 'administration.quiz.subject.id');
            }) ? current.concat(Ember.get(sta, 'administration.quiz.subject')) : current;
          }, []);
        }),

        filteredStudentTestAdministrations: Ember.computed('selectedSubjectId', 'studentTestAdministrations.[]', function () {
          var stas = Ember.get(this, 'studentTestAdministrations');
          if (Ember.get(this, 'selectedSubjectId')) {
            stas = stas.filterBy('administration.quiz.subject.id', Ember.get(this, 'selectedSubjectId'));
          }
          return stas;
        })
      });
    },


    afterModel: function afterModel(model) {
      this._super(model, 'Quiz Results', false, true);
    },

    actions: {
      drill: function drill(id) {
        //Award the Space Walk trophy if it hasn't been earned yet
        if (this.currentModel.studentTrophies.findBy('trophy.id', '3') == null) {
          var trophy = this.store.createRecord('student-trophy', {
            student: this.get('currentModel.student'),
            trophy: this.get('currentModel.spaceWalkTrophy'),
            dateEarned: new Date(),
            studentViewed: false
          });
          trophy.save();
        }

        this.transitionTo('student-results', id, '0');
      }
    }

  });
});
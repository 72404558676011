define('ohs/components/x-thumb', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    rating: null,

    isUpActive: Ember.computed('rating', 'rating.wasHelpful', function () {
      if (this.get('rating')) {
        if (this.get('rating').get('wasHelpful') == true) {
          return true;
        }
      } else {
        return false;
      }
    }),

    isDownActive: Ember.computed('rating', 'rating.wasHelpful', function () {
      if (this.get('rating')) {
        if (this.get('rating').get('wasHelpful') == false) {
          return true;
        }
      } else {
        return false;
      }
    }),

    classNames: ['x-thumb'],
    store: Ember.inject.service(),
    actions: {

      toggleThumbUp: function toggleThumbUp() {
        //if there is already a rating
        if (this.get('rating')) {
          var rating = this.get('rating');
          var self = this;
          //if the rating is already yes, then delete this rating
          if (rating.get('wasHelpful')) {
            rating.destroyRecord().then(function () {
              self.set('rating', null);
            });
          }
          //otherwise, update the rating to be yes
          else {
              rating.set('wasHelpful', true);
              rating.save().then(function (r) {
                self.set('rating', r);
              });
            }
        }
        //otherwise, create a new record
        else {
            var rating = this.get('rating');
            rating = this.get('store').createRecord('resource-student-rating', {
              resource: this.get('assignedObject').get('resource'),
              student: this.get('assignedObject').get('student'),
              ratingDate: new Date(),
              wasHelpful: true
            });
            var self = this;
            rating.save().then(function (r) {
              self.set('rating', r);
            });
          }
      },

      toggleThumbDown: function toggleThumbDown() {
        //if there is already a rating
        if (this.get('rating')) {
          var rating = this.get('rating');
          var self = this;
          //if the rating is already no, then delete this rating
          if (rating.get('wasHelpful') == false) {
            rating.destroyRecord().then(function () {
              self.set('rating', null);
            });
          }
          //otherwise, update the rating to be yes
          else {
              rating.set('wasHelpful', false);
              rating.save().then(function (r) {
                self.set('rating', r);
              });
            }
        }
        //otherwise, create a new record
        else {
            var rating = this.get('rating');
            rating = this.get('store').createRecord('resource-student-rating', {
              resource: this.get('assignedObject').get('resource'),
              student: this.get('assignedObject').get('student'),
              ratingDate: new Date(),
              wasHelpful: false
            });
            var self = this;
            rating.save().then(function (r) {
              self.set('rating', r);
            });
          }
      }

    }
  });
});
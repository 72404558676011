define('ohs/components/labeled-input-checkbox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    store: Ember.inject.service(),
    type: 'checkbox',
    classNames: ['x-input x-input-checkbox'],
    attributeBindings: ['type', 'htmlChecked:checked', 'value', 'name', 'disabled'],

    change: function change() {
      var selectedValue = this.get('value');
      this.sendAction('setPropertyAction', selectedValue);
    }

  });
});
define('ohs/components/x-scratchpad', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['aria-hidden', 'role', 'aria-labeledby'],
    classNames: ['modal scratchpad'],
    classNameBindings: ['isOpen', 'isHidden'],
    role: 'dialog',
    'aria-labeledby': 'modal-content',
    isOpen: true,
    isHidden: false,
    backgroundColor: 'transparent',

    flag: false,
    dot_flag: false,
    prevX: null,
    prevY: null,
    currX: null,
    currY: null,
    currentColor: 'red',
    currentLineWidth: 2,

    showWhite: Ember.computed('backgroundColor', function () {
      if (this.get('backgroundColor') == 'white') {
        return 'invisible';
      }
    }),

    showTransparent: Ember.computed('backgroundColor', function () {
      if (this.get('backgroundColor') == 'transparent') {
        return 'invisible';
      }
    }),

    showEmptyPen: Ember.computed('currentTool', function () {
      if (this.get('currentTool') == 'draw') {
        return 'invisible';
      }
    }),

    showRedPen: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'draw' || this.get('currentColor') != 'red') {
        return 'invisible';
      }
    }),

    showBlackPen: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'draw' || this.get('currentColor') != 'black') {
        return 'invisible';
      }
    }),

    showBluePen: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'draw' || this.get('currentColor') != 'blue') {
        return 'invisible';
      }
    }),

    showCyanPen: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'draw' || this.get('currentColor') != 'cyan') {
        return 'invisible';
      }
    }),

    showPinkPen: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'draw' || this.get('currentColor') != 'pink') {
        return 'invisible';
      }
    }),

    showYellowPen: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'draw' || this.get('currentColor') != 'yellow') {
        return 'invisible';
      }
    }),

    showEmptyEraser: Ember.computed('currentTool', function () {
      if (this.get('currentTool') == 'erase') {
        return 'invisible';
      }
    }),

    showFilledEraser: Ember.computed('currentTool', function () {
      if (this.get('currentTool') != 'erase') {
        return 'invisible';
      }
    }),

    showEmptyLetters: Ember.computed('currentTool', function () {
      if (this.get('currentTool') == 'text') {
        return 'invisible';
      }
    }),

    showRedLetters: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'text' || this.get('currentColor') != 'red') {
        return 'invisible';
      }
    }),

    showBlackLetters: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'text' || this.get('currentColor') != 'black') {
        return 'invisible';
      }
    }),

    showBlueLetters: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'text' || this.get('currentColor') != 'blue') {
        return 'invisible';
      }
    }),

    showCyanLetters: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'text' || this.get('currentColor') != 'cyan') {
        return 'invisible';
      }
    }),

    showPinkLetters: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'text' || this.get('currentColor') != 'pink') {
        return 'invisible';
      }
    }),

    showYellowLetters: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'text' || this.get('currentColor') != 'yellow') {
        return 'invisible';
      }
    }),

    showShapeIcon: Ember.computed('currentTool', function () {
      if (this.get('currentTool') == 'line' || this.get('currentTool') == 'circle' || this.get('currentTool') == 'rectangle' || this.get('currentTool') == 'triangle') {
        return 'invisible';
      }
    }),

    showRedLine: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'line' || this.get('currentColor') != 'red') {
        return 'invisible';
      }
    }),

    showBlackLine: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'line' || this.get('currentColor') != 'black') {
        return 'invisible';
      }
    }),

    showBlueLine: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'line' || this.get('currentColor') != 'blue') {
        return 'invisible';
      }
    }),

    showPinkLine: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'line' || this.get('currentColor') != 'pink') {
        return 'invisible';
      }
    }),

    showCyanLine: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'line' || this.get('currentColor') != 'cyan') {
        return 'invisible';
      }
    }),

    showYellowLine: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'line' || this.get('currentColor') != 'yellow') {
        return 'invisible';
      }
    }),

    showRedCircle: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'circle' || this.get('currentColor') != 'red') {
        return 'invisible';
      }
    }),

    showBlackCircle: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'circle' || this.get('currentColor') != 'black') {
        return 'invisible';
      }
    }),

    showBlueCircle: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'circle' || this.get('currentColor') != 'blue') {
        return 'invisible';
      }
    }),

    showPinkCircle: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'circle' || this.get('currentColor') != 'pink') {
        return 'invisible';
      }
    }),

    showCyanCircle: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'circle' || this.get('currentColor') != 'cyan') {
        return 'invisible';
      }
    }),

    showYellowCircle: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'circle' || this.get('currentColor') != 'yellow') {
        return 'invisible';
      }
    }),

    showRedSquare: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'rectangle' || this.get('currentColor') != 'red') {
        return 'invisible';
      }
    }),

    showBlackSquare: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'rectangle' || this.get('currentColor') != 'black') {
        return 'invisible';
      }
    }),

    showBlueSquare: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'rectangle' || this.get('currentColor') != 'blue') {
        return 'invisible';
      }
    }),

    showPinkSquare: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'rectangle' || this.get('currentColor') != 'pink') {
        return 'invisible';
      }
    }),

    showCyanSquare: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'rectangle' || this.get('currentColor') != 'cyan') {
        return 'invisible';
      }
    }),

    showYellowSquare: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'rectangle' || this.get('currentColor') != 'yellow') {
        return 'invisible';
      }
    }),

    showRedTriangle: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'triangle' || this.get('currentColor') != 'red') {
        return 'invisible';
      }
    }),

    showBlackTriangle: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'triangle' || this.get('currentColor') != 'black') {
        return 'invisible';
      }
    }),

    showBlueTriangle: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'triangle' || this.get('currentColor') != 'blue') {
        return 'invisible';
      }
    }),

    showPinkTriangle: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'triangle' || this.get('currentColor') != 'pink') {
        return 'invisible';
      }
    }),

    showCyanTriangle: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'triangle' || this.get('currentColor') != 'cyan') {
        return 'invisible';
      }
    }),

    showYellowTriangle: Ember.computed('currentTool', 'currentColor', function () {
      if (this.get('currentTool') != 'triangle' || this.get('currentColor') != 'yellow') {
        return 'invisible';
      }
    }),

    strokeColor: Ember.computed('currentColor', function () {
      if (this.get('currentColor') == 'red') {
        return '#ff0000';
      }
      if (this.get('currentColor') == 'black') {
        return '#000000';
      }
      if (this.get('currentColor') == 'blue') {
        return '#435685';
      }
      if (this.get('currentColor') == 'cyan') {
        return '#6cced3';
      }
      if (this.get('currentColor') == 'pink') {
        return '#ed1e79';
      }
      if (this.get('currentColor') == 'yellow') {
        return '#f7931e';
      }
    }),

    currentTool: 'draw',

    'aria-hidden': function () {
      return !this.get('isOpen') + '';
    }.property('isOpen'),

    scrollClass: Ember.computed('isTouch', function () {
      if (!this.get('isTouch')) {
        return 'hide-object';
      }
    }),

    didRender: function didRender() {
      this._super.apply(this, arguments);
      var self = this;
      $(function () {
        $("textarea#textareaTest").keyup(function (e) {
          if (e.keyCode == 13) {
            var y = self.get('currY');
            var x = self.get('currX');
            //get the value of the textarea then destroy it and the save button
            var text = $('textarea#textareaTest').val();
            $('textarea#textareaTest').val("");
            $('#textAreaPopUp').css('display', 'none');

            var canvas = $('#myCanvas')[0];
            var ctx = canvas.getContext("2d");
            ctx.fillStyle = self.get('strokeColor');
            ctx.font = "18px ff-enzo";
            ctx.beginPath();
            ctx.fillText(text, x, y);
            ctx.closePath();
            e.preventDefault();
          }
        });
      });

      $(function () {
        $(document).keyup(function (e) {
          if (e.keyCode == 27) {
            var canvas = $('#myCanvas')[0];
            if (canvas.toDataURL()) {
              self.sendAction('proceed', canvas.toDataURL());
            }
            e.preventDefault();
          }
        });
      });

      //set the min-height of the modal so that it always takes up the full screen
      $('.scratchpad').css('min-height', window.innerHeight);
      var h = parseInt($('.main-content').css('height').replace("px", "")) + parseInt($('.site-header').css('height').replace("px", ""));
      $('.scratchpad').css('height', h);

      if ($('.icon-scratchpad-toolbox-extended').css('width')) {
        $('.icon-scratchpad-toolbox-extended').css('height', $('.icon-scratchpad-toolbox-extended').css('width').replace("px", "") / 7);
      }

      $('.divider').css('margin-top', $('.icon-scratchpad-toolbox-extended').css('height').replace("px", "") / 4);
      // $('.scratchpad-tool').css('height',$('.icon-scratchpad-toolbox-extended').css('height').replace("px",""));

      //set the position of the body to relative so that the scratchpad will take up the entire body
      $('body').css("position", "relative");

      //set the height and width of the canvas
      var canvas = $('#myCanvas')[0];
      var drawCanvas = $('#drawCanvas')[0];
      var ctx = canvas.getContext("2d");
      canvas.setAttribute('width', $(canvas).css('width'));
      canvas.setAttribute('height', $(canvas).css('height'));
      drawCanvas.setAttribute('width', $(drawCanvas).css('width'));
      drawCanvas.setAttribute('height', $(drawCanvas).css('height'));

      if (this.get('scratchpadContent')) {
        var img = new Image();
        img.src = this.get('scratchpadContent');
        img.onload = function () {
          ctx.drawImage(img, 0, 0);
        };
      }

      canvas.addEventListener("mousemove", function (e) {
        self.findxy('move', e);
      }, false);
      canvas.addEventListener("mousedown", function (e) {
        self.findxy('down', e);
      }, false);
      canvas.addEventListener("mouseup", function (e) {
        self.findxy('up', e);
      }, false);
      canvas.addEventListener("touchmove", function (e) {
        //e.preventDefault();
        self.findxyTouch('move', e);
      }, false);
      canvas.addEventListener("touchstart", function (e) {
        //e.preventDefault();
        self.findxyTouch('down', e);
      }, false);
      canvas.addEventListener("touchend", function (e) {
        //e.preventDefault();
        self.findxyTouch('up', e);
      }, false);
      canvas.addEventListener("mouseout", function (e) {
        self.findxy('out', e);
      }, false);

      drawCanvas.addEventListener("mousemove", function (e) {
        self.findxy('move', e);
      }, false);
      drawCanvas.addEventListener("mousedown", function (e) {
        self.findxy('down', e);
      }, false);
      drawCanvas.addEventListener("mouseup", function (e) {
        self.findxy('up', e);
      }, false);
      drawCanvas.addEventListener("touchmove", function (e) {
        //e.preventDefault();
        self.findxyTouch('move', e);
      }, false);
      drawCanvas.addEventListener("touchstart", function (e) {
        //e.preventDefault();
        self.findxyTouch('down', e);
      }, false);
      drawCanvas.addEventListener("touchend", function (e) {
        //e.preventDefault();
        self.findxyTouch('up', e);
      }, false);
      drawCanvas.addEventListener("mouseout", function (e) {
        self.findxy('out', e);
      }, false);

      window.addEventListener("resize", function (e) {
        self.resizeCanvas();
      }, false);
    },
    resizeCanvas: function resizeCanvas() {
      var canvas = $('#myCanvas')[0];
      var drawCanvas = $('#drawCanvas')[0];
      if (canvas) {
        var ctx = canvas.getContext('2d');
        var img = new Image();
        img.src = canvas.toDataURL();
        canvas.setAttribute('width', $(canvas).css('width'));
        canvas.setAttribute('height', $(canvas).css('height'));
        img.onload = function () {
          ctx.drawImage(img, 0, 0);
        };
        if (drawCanvas) {
          drawCanvas.setAttribute('width', $(drawCanvas).css('width'));
          drawCanvas.setAttribute('height', $(drawCanvas).css('height'));
        }
      }

      if ($('.icon-scratchpad-toolbox-extended').css('width')) {
        $('.icon-scratchpad-toolbox-extended').css('height', $('.icon-scratchpad-toolbox-extended').css('width').replace("px", "") / 7);
      }
      if ($('.icon-scratchpad-toolbox-extended').css('height')) {
        $('.divider').css('margin-top', $('.icon-scratchpad-toolbox-extended').css('height').replace("px", "") / 4);
      }
      // $('.scratchpad-tool').css('height',$('.icon-scratchpad-toolbox-extended').css('height').replace("px",""));
    },
    draw: function draw() {
      var canvas = $('#myCanvas')[0];
      var ctx = canvas.getContext("2d");
      ctx.beginPath();
      ctx.moveTo(this.get('prevX'), this.get('prevY'));
      ctx.lineTo(this.get('currX'), this.get('currY'));
      ctx.strokeStyle = this.get('strokeColor');
      ctx.lineWidth = this.get('currentLineWidth');
      ctx.stroke();
      ctx.closePath();
    },
    erase: function erase() {
      var canvas = $('#myCanvas')[0];
      var ctx = canvas.getContext("2d");
      ctx.beginPath();
      ctx.moveTo(this.get('prevX'), this.get('prevY'));
      ctx.lineTo(this.get('currX'), this.get('currY'));
      ctx.strokeStyle = this.get('currentColor');
      ctx.lineWidth = this.get('currentLineWidth');
      ctx.clearRect(this.get('currX'), this.get('currY'), 36, 36);
      ctx.closePath();
    },
    drawLine: function drawLine() {
      var canvas = $('#myCanvas')[0];
      var ctx = canvas.getContext("2d");
      var drawCanvas = $('#drawCanvas')[0];
      ctx.beginPath();
      ctx.moveTo(this.get('startX'), this.get('startY'));
      ctx.lineTo(this.get('currX'), this.get('currY'));
      ctx.strokeStyle = this.get('strokeColor');
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.closePath();
      drawCanvas.width = drawCanvas.width;
    },
    previewLine: function previewLine() {
      var canvas = $('#drawCanvas')[0];
      var ctx = canvas.getContext("2d");
      canvas.width = canvas.width;
      ctx.beginPath();
      ctx.moveTo(this.get('startX'), this.get('startY'));
      ctx.lineTo(this.get('currX'), this.get('currY'));
      ctx.strokeStyle = this.get('strokeColor');
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.closePath();
    },
    drawRectangle: function drawRectangle() {
      var canvas = $('#myCanvas')[0];
      var ctx = canvas.getContext("2d");
      var drawCanvas = $('#drawCanvas')[0];
      ctx.beginPath();
      ctx.moveTo(this.get('startX'), this.get('startY'));
      ctx.lineTo(this.get('currX'), this.get('startY'));
      ctx.moveTo(this.get('currX'), this.get('startY'));
      ctx.lineTo(this.get('currX'), this.get('currY'));
      ctx.moveTo(this.get('currX'), this.get('currY'));
      ctx.lineTo(this.get('startX'), this.get('currY'));
      ctx.moveTo(this.get('startX'), this.get('currY'));
      ctx.lineTo(this.get('startX'), this.get('startY'));
      ctx.strokeStyle = this.get('strokeColor');
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.closePath();
      drawCanvas.width = drawCanvas.width;
    },
    previewRectangle: function previewRectangle() {
      var canvas = $('#drawCanvas')[0];
      var ctx = canvas.getContext("2d");
      canvas.width = canvas.width;
      ctx.beginPath();
      ctx.moveTo(this.get('startX'), this.get('startY'));
      ctx.lineTo(this.get('currX'), this.get('startY'));
      ctx.moveTo(this.get('currX'), this.get('startY'));
      ctx.lineTo(this.get('currX'), this.get('currY'));
      ctx.moveTo(this.get('currX'), this.get('currY'));
      ctx.lineTo(this.get('startX'), this.get('currY'));
      ctx.moveTo(this.get('startX'), this.get('currY'));
      ctx.lineTo(this.get('startX'), this.get('startY'));
      ctx.strokeStyle = this.get('strokeColor');
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.closePath();
    },
    drawCircle: function drawCircle() {
      var canvas = $('#myCanvas')[0];
      var ctx = canvas.getContext("2d");
      var drawCanvas = $('#drawCanvas')[0];
      ctx.beginPath();
      var a = Math.abs(this.get('currX') - this.get('startX')) * Math.abs(this.get('currX') - this.get('startX'));
      var b = Math.abs(this.get('currY') - this.get('startY')) * Math.abs(this.get('currY') - this.get('startY'));
      var diameter = Math.sqrt(a + b);
      var centerX = Math.abs(this.get('currX') - this.get('startX')) / 2;
      if (this.get('startX') > this.get('currX')) {
        centerX = this.get('startX') - centerX;
      } else {
        centerX = this.get('startX') + centerX;
      }
      var centerY = Math.abs(this.get('currY') - this.get('startY')) / 2;
      if (this.get('startY') > this.get('currY')) {
        centerY = this.get('startY') - centerY;
      } else {
        centerY = this.get('startY') + centerY;
      }
      ctx.arc(centerX, centerY, diameter / 2, 0, 2 * Math.PI);
      ctx.strokeStyle = this.get('strokeColor');
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.closePath();
      drawCanvas.width = drawCanvas.width;
    },
    previewCircle: function previewCircle() {
      var canvas = $('#drawCanvas')[0];
      var ctx = canvas.getContext("2d");
      canvas.width = canvas.width;
      ctx.beginPath();
      var a = Math.abs(this.get('currX') - this.get('startX')) * Math.abs(this.get('currX') - this.get('startX'));
      var b = Math.abs(this.get('currY') - this.get('startY')) * Math.abs(this.get('currY') - this.get('startY'));
      var diameter = Math.sqrt(a + b);
      var centerX = Math.abs(this.get('currX') - this.get('startX')) / 2;
      if (this.get('startX') > this.get('currX')) {
        centerX = this.get('startX') - centerX;
      } else {
        centerX = this.get('startX') + centerX;
      }
      var centerY = Math.abs(this.get('currY') - this.get('startY')) / 2;
      if (this.get('startY') > this.get('currY')) {
        centerY = this.get('startY') - centerY;
      } else {
        centerY = this.get('startY') + centerY;
      }
      ctx.arc(centerX, centerY, diameter / 2, 0, 2 * Math.PI);
      ctx.strokeStyle = this.get('strokeColor');
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.closePath();
    },
    drawTriangle: function drawTriangle() {
      var canvas = $('#myCanvas')[0];
      var ctx = canvas.getContext("2d");
      var drawCanvas = $('#drawCanvas')[0];
      ctx.beginPath();
      var centerX = Math.abs(this.get('currX') - this.get('startX')) / 2;
      if (this.get('startX') > this.get('currX')) {
        centerX = this.get('startX') - centerX;
      } else {
        centerX = this.get('startX') + centerX;
      }
      ctx.moveTo(this.get('startX'), this.get('startY'));
      ctx.lineTo(this.get('currX'), this.get('startY'));
      ctx.moveTo(this.get('currX'), this.get('startY'));
      ctx.lineTo(centerX, this.get('currY'));
      ctx.moveTo(centerX, this.get('currY'));
      ctx.lineTo(this.get('startX'), this.get('startY'));
      ctx.strokeStyle = this.get('strokeColor');
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.closePath();
      drawCanvas.width = drawCanvas.width;
    },
    previewTriangle: function previewTriangle() {
      var canvas = $('#drawCanvas')[0];
      var ctx = canvas.getContext("2d");
      canvas.width = canvas.width;
      ctx.beginPath();
      var centerX = Math.abs(this.get('currX') - this.get('startX')) / 2;
      if (this.get('startX') > this.get('currX')) {
        centerX = this.get('startX') - centerX;
      } else {
        centerX = this.get('startX') + centerX;
      }
      ctx.moveTo(this.get('startX'), this.get('startY'));
      ctx.lineTo(this.get('currX'), this.get('startY'));
      ctx.moveTo(this.get('currX'), this.get('startY'));
      ctx.lineTo(centerX, this.get('currY'));
      ctx.moveTo(centerX, this.get('currY'));
      ctx.lineTo(this.get('startX'), this.get('startY'));
      ctx.strokeStyle = this.get('strokeColor');
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.closePath();
    },
    findxy: function findxy(res, e) {
      var canvas = $('#myCanvas')[0];
      var ctx = canvas.getContext("2d");
      if (res == 'down') {
        this.set('prevX', this.get('currX'));
        this.set('prevY', this.get('currY'));
        this.set('currX', e.clientX - (canvas.offsetLeft + canvas.parentElement.offsetLeft));
        this.set('currY', e.clientY - (canvas.offsetTop + canvas.parentElement.offsetTop) + $(document).scrollTop());

        if (this.get('currentTool') == 'draw') {
          this.set('flag', true);
          this.set('dot_flag', true);
          if (this.get('dot_flag')) {
            ctx.beginPath();
            ctx.fillStyle = this.get('strokeColor');
            ctx.fillRect(this.get('currX'), this.get('currY'), 2, 2);
            ctx.closePath();
            this.set('dot_flag', false);
          }
        } else if (this.get('currentTool') == 'erase') {
          this.set('flag', true);
          this.set('dot_flag', true);
          if (this.get('dot_flag')) {
            ctx.beginPath();
            ctx.fillStyle = this.get('currentColor');
            ctx.clearRect(this.get('currX'), this.get('currY'), 14, 14);
            ctx.closePath();
            this.set('dot_flag', false);
          }
        } else if (this.get('currentTool') == 'text') {
          $('#textAreaPopUp').css('top', this.get('currY') + canvas.offsetTop + "px");
          $('#textAreaPopUp').css('left', this.get('currX') + canvas.offsetLeft + "px");
          $('#textAreaPopUp').css('display', 'block');
          $('textarea#textareaTest').css('color', this.get('strokeColor'));
          $('textarea#textareaTest').val($('textarea#textareaTest').val().trim());
          setTimeout(function () {
            $('textarea#textareaTest').focus();
          }, 100);
        } else if (this.get('currentTool') == 'line' || this.get('currentTool') == 'rectangle' || this.get('currentTool') == 'circle' || this.get('currentTool') == 'triangle') {
          this.set('isDrawing', true);
          this.set('startX', this.get('currX'));
          this.set('startY', this.get('currY'));
        }
      }
      if (res == 'up' || res == "out") {
        this.set('flag', false);
        if (this.get('currentTool') == "line" && res == 'up') {
          this.set('isDrawing', false);
          this.drawLine();
        } else if (this.get('currentTool') == "rectangle" && res == 'up') {
          this.set('isDrawing', false);
          this.drawRectangle();
        } else if (this.get('currentTool') == "circle" && res == 'up') {
          this.set('isDrawing', false);
          this.drawCircle();
        } else if (this.get('currentTool') == "triangle" && res == 'up') {
          this.set('isDrawing', false);
          this.drawTriangle();
        }
      }
      if (res == 'move') {
        if (this.get('flag')) {
          this.set('prevX', this.get('currX'));
          this.set('prevY', this.get('currY'));
          this.set('currX', e.clientX - (canvas.offsetLeft + canvas.parentElement.offsetLeft));
          this.set('currY', e.clientY - (canvas.offsetTop + canvas.parentElement.offsetTop) + $(document).scrollTop());
          if (this.get('currentTool') == 'draw') {
            this.draw();
          } else if (this.get('currentTool') == 'erase') {
            this.erase();
          }
        } else if (this.get('currentTool') == 'line') {
          if (this.get('isDrawing')) {
            this.set('prevX', this.get('currX'));
            this.set('prevY', this.get('currY'));
            this.set('currX', e.clientX - (canvas.offsetLeft + canvas.parentElement.offsetLeft));
            this.set('currY', e.clientY - (canvas.offsetTop + canvas.parentElement.offsetTop) + $(document).scrollTop());
            this.previewLine();
          }
        } else if (this.get('currentTool') == 'rectangle') {
          if (this.get('isDrawing')) {
            this.set('prevX', this.get('currX'));
            this.set('prevY', this.get('currY'));
            this.set('currX', e.clientX - (canvas.offsetLeft + canvas.parentElement.offsetLeft));
            this.set('currY', e.clientY - (canvas.offsetTop + canvas.parentElement.offsetTop) + $(document).scrollTop());
            this.previewRectangle();
          }
        } else if (this.get('currentTool') == 'circle') {
          if (this.get('isDrawing')) {
            this.set('prevX', this.get('currX'));
            this.set('prevY', this.get('currY'));
            this.set('currX', e.clientX - (canvas.offsetLeft + canvas.parentElement.offsetLeft));
            this.set('currY', e.clientY - (canvas.offsetTop + canvas.parentElement.offsetTop) + $(document).scrollTop());
            this.previewCircle();
          }
        } else if (this.get('currentTool') == 'triangle') {
          if (this.get('isDrawing')) {
            this.set('prevX', this.get('currX'));
            this.set('prevY', this.get('currY'));
            this.set('currX', e.clientX - (canvas.offsetLeft + canvas.parentElement.offsetLeft));
            this.set('currY', e.clientY - (canvas.offsetTop + canvas.parentElement.offsetTop) + $(document).scrollTop());
            this.previewTriangle();
          }
        }
      }
    },
    findxyTouch: function findxyTouch(res, e) {
      e.preventDefault();
      var canvas = $('#myCanvas')[0];
      var ctx = canvas.getContext("2d");
      var touchEvent = e.touches[0];
      if (res == 'down') {
        this.set('prevX', this.get('currX'));
        this.set('prevY', this.get('currY'));
        this.set('currX', touchEvent.clientX - (canvas.offsetLeft + canvas.parentElement.offsetLeft));
        this.set('currY', touchEvent.clientY - (canvas.offsetTop + canvas.parentElement.offsetTop) + $(document).scrollTop());

        if (this.get('currentTool') == 'draw') {
          this.set('flag', true);
          this.set('dot_flag', true);
          if (this.get('dot_flag')) {
            ctx.beginPath();
            ctx.fillStyle = this.get('strokeColor');
            ctx.fillRect(this.get('currX'), this.get('currY'), 2, 2);
            ctx.closePath();
            this.set('dot_flag', false);
          }
        } else if (this.get('currentTool') == 'erase') {
          this.set('flag', true);
          this.set('dot_flag', true);
          if (this.get('dot_flag')) {
            ctx.beginPath();
            ctx.fillStyle = this.get('currentColor');
            ctx.clearRect(this.get('currX'), this.get('currY'), 14, 14);
            ctx.closePath();
            this.set('dot_flag', false);
          }
        } else if (this.get('currentTool') == 'text') {
          $('#textAreaPopUp').css('top', this.get('currY') + "px");
          $('#textAreaPopUp').css('left', this.get('currX') + "px");
          $('#textAreaPopUp').css('display', 'block');
          $('textarea#textareaTest').val($('textarea#textareaTest').val().trim());
          setTimeout(function () {
            $('textarea#textareaTest').focus();
          }, 100);
        } else if (this.get('currentTool') == 'line' || this.get('currentTool') == 'rectangle' || this.get('currentTool') == 'circle' || this.get('currentTool') == 'triangle') {
          this.set('isDrawing', true);
          this.set('startX', this.get('currX'));
          this.set('startY', this.get('currY'));
        }
      }
      if (res == 'up' || res == "out") {
        this.set('flag', false);
        if (this.get('currentTool') == "line" && res == 'up') {
          this.set('isDrawing', false);
          this.drawLine();
        } else if (this.get('currentTool') == "rectangle" && res == 'up') {
          this.set('isDrawing', false);
          this.drawRectangle();
        } else if (this.get('currentTool') == "circle" && res == 'up') {
          this.set('isDrawing', false);
          this.drawCircle();
        } else if (this.get('currentTool') == "triangle" && res == 'up') {
          this.set('isDrawing', false);
          this.drawTriangle();
        }
      }
      if (res == 'move') {
        if (this.get('flag')) {
          this.set('prevX', this.get('currX'));
          this.set('prevY', this.get('currY'));
          this.set('currX', touchEvent.clientX - (canvas.offsetLeft + canvas.parentElement.offsetLeft));
          this.set('currY', touchEvent.clientY - (canvas.offsetTop + canvas.parentElement.offsetTop) + $(document).scrollTop());
          if (this.get('currentTool') == 'draw') {
            this.draw();
          } else if (this.get('currentTool') == 'erase') {
            this.erase();
          }
        } else if (this.get('currentTool') == 'line') {
          if (this.get('isDrawing')) {
            this.set('prevX', this.get('currX'));
            this.set('prevY', this.get('currY'));
            this.set('currX', touchEvent.clientX - (canvas.offsetLeft + canvas.parentElement.offsetLeft));
            this.set('currY', touchEvent.clientY - (canvas.offsetTop + canvas.parentElement.offsetTop) + $(document).scrollTop());
            this.previewLine();
          }
        } else if (this.get('currentTool') == 'rectangle') {
          if (this.get('isDrawing')) {
            this.set('prevX', this.get('currX'));
            this.set('prevY', this.get('currY'));
            this.set('currX', touchEvent.clientX - (canvas.offsetLeft + canvas.parentElement.offsetLeft));
            this.set('currY', touchEvent.clientY - (canvas.offsetTop + canvas.parentElement.offsetTop) + $(document).scrollTop());
            this.previewRectangle();
          }
        } else if (this.get('currentTool') == 'circle') {
          if (this.get('isDrawing')) {
            this.set('prevX', this.get('currX'));
            this.set('prevY', this.get('currY'));
            this.set('currX', touchEvent.clientX - (canvas.offsetLeft + canvas.parentElement.offsetLeft));
            this.set('currY', touchEvent.clientY - (canvas.offsetTop + canvas.parentElement.offsetTop) + $(document).scrollTop());
            this.previewCircle();
          }
        } else if (this.get('currentTool') == 'triangle') {
          if (this.get('isDrawing')) {
            this.set('prevX', this.get('currX'));
            this.set('prevY', this.get('currY'));
            this.set('currX', touchEvent.clientX - (canvas.offsetLeft + canvas.parentElement.offsetLeft));
            this.set('currY', touchEvent.clientY - (canvas.offsetTop + canvas.parentElement.offsetTop) + $(document).scrollTop());
            this.previewTriangle();
          }
        }
      }
    },


    actions: {
      openModal: function openModal() {
        this.set('isOpen', true);
        this.set('isHidden', false);
      },

      closeModal: function closeModal() {
        this.set('isOpen', false);
        this.set('isHidden', true);
      },

      proceed: function proceed() {
        var canvas = $('#myCanvas')[0];
        this.sendAction('proceed', canvas.toDataURL());
      },

      changeTool: function changeTool(tool) {
        $('textarea#textareaTest').val("");
        $('#textAreaPopUp').css('display', 'none');
        var canvas = $('#myCanvas')[0];
        if (tool == 'clear') {
          this.set('scratchpadContent', null);
          canvas.width = canvas.width;
          var self = this;
          setTimeout(function () {
            $("label[for='" + self.get('currentTool') + "']").focus();
          }, 100);
        } else {
          this.set('currentTool', tool);
          this.set('scratchpadContent', canvas.toDataURL());
        }
        if (tool == 'draw') {
          this.set('currentLineWidth', 2);
        } else if (tool == 'erase') {
          this.set('currentLineWidth', 14);
        }
        this.set('showShapes', false);
      },

      changeBackground: function changeBackground(color) {
        var canvas = $('#myCanvas')[0];
        this.set('scratchpadContent', canvas.toDataURL());
        //this.set('backgroundColor',color);
        if (this.get('backgroundColor') == 'white') {
          this.set('backgroundColor', 'transparent');
        } else {
          this.set('backgroundColor', 'white');
        }
      },

      changeColor: function changeColor(color) {
        var canvas = $('#myCanvas')[0];
        this.set('scratchpadContent', canvas.toDataURL());
        this.set('showColors', false);
        this.set('currentColor', color);
      },

      showColors: function showColors() {
        var canvas = $('#myCanvas')[0];
        this.set('scratchpadContent', canvas.toDataURL());
        this.set('showColors', true);
      },

      showShapes: function showShapes() {
        var canvas = $('#myCanvas')[0];
        this.set('scratchpadContent', canvas.toDataURL());
        this.set('showShapes', true);
      },

      saveTextFromArea: function saveTextFromArea() {
        var y = this.get('currY');
        var x = this.get('currX');
        //get the value of the textarea then destroy it and the save button
        var text = $('textarea#textareaTest').val();
        $('textarea#textareaTest').val("");
        $('#textAreaPopUp').css('display', 'none');

        var canvas = $('#myCanvas')[0];
        var ctx = canvas.getContext("2d");
        ctx.fillStyle = this.get('strokeColor');
        ctx.font = "18px ff-enzo";
        ctx.beginPath();
        ctx.fillText(text, x, y);
        ctx.closePath();
      },

      toggleToolbox: function toggleToolbox() {
        var canvas = $('#myCanvas')[0];
        this.set('scratchpadContent', canvas.toDataURL());
        if (this.get('showTools')) {
          this.set('showTools', false);
        } else {
          this.set('showTools', true);
        }
      }
    }
  });
});
define('ohs/models/student-mission', ['exports', 'ember-data', 'lodash'], function (exports, _emberData, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    totalQuestions: attr('number'),
    totalAttemptedQuestions: attr('number'),
    pointsEarned: attr('number'),
    created: attr('date'),
    lastUpdated: attr('date'),
    completed: attr('date'),
    started: attr('date'),
    isPointEligible: attr('boolean'),

    student: belongsTo('student'),
    mission: belongsTo('mission-control/mission'),

    userSentMissions: hasMany('user-sent-mission'),
    // studentAnswers: hasMany('student-answer'),

    isStarted: Ember.computed.bool('started'),
    isCompleted: Ember.computed.bool('completed'),

    progress: Ember.computed('started', 'totalAttemptedQuestions', 'totalQuestions', function () {
      var started = Ember.get(this, 'isStarted');
      var answered = Ember.get(this, 'totalAttemptedQuestions');
      var total = Ember.get(this, 'totalQuestions');
      var pointsEarned = Ember.get(this, 'pointsEarned');
      var completed = Ember.get(this, 'isCompleted');
      var pointEligible = Ember.get(this, 'isPointEligible');

      if (!started) {
        return 'Not Started';
      }

      if (completed && pointEligible) {
        return 'Complete! ' + this.formatNumber(pointsEarned, 0) + ' Points Earned';
      }

      if (completed) {
        return 'Complete!';
      }

      return answered + ' of ' + total + ' Complete';
    }),

    sentBy: Ember.computed('userSentMissions.@each.id', function () {
      var userSentMissions = Ember.get(this, 'userSentMissions').toArray();
      var sentBy = 'Sent by';
      var teacherList = _lodash.default.uniqBy(_lodash.default.flatMap(userSentMissions, function (mission) {
        return Ember.get(mission, 'sentBy');
      }), function (teacher) {
        return Ember.get(teacher, 'id');
      }).map(function (teacher) {
        return Ember.get(teacher, 'teacherDisplayName');
      });

      return sentBy + ' ' + teacherList.join(', ');
    }),

    formatNumber: function formatNumber(n, c, d, t) {
      var c = isNaN(c = Math.abs(c)) ? 2 : c,
          d = d == undefined ? "." : d,
          t = t == undefined ? "," : t,
          s = n < 0 ? "-" : "",
          i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
          j = (j = i.length) > 3 ? j % 3 : 0;

      return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    }
  });
});
define('ohs/models/user-sent-mission', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = _emberData.default.Model.extend({
        sent: attr('date'),

        sentBy: belongsTo('teacher'),
        studentMission: belongsTo('student-mission'),

        sentDateOnly: Ember.computed('sent', function () {
            var sent = Ember.get(this, 'sent');

            return (0, _moment.default)(sent).format('M/D/YYYY');
        })
    });
});
define('ohs/routes/class-library', ['exports', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin', 'ohs/mixins/authenticated-route-mixin', 'lodash'], function (exports, _reset, _breadcrumbMixin, _authenticatedRouteMixin, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {

    titleToken: function titleToken(model) {
      return 'Class Library';
    },
    contextMenu: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          } else if (roles.indexOf("Parent") > 0) {
            this.controllerFor('application').set('parent_many_children', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
          this.controllerFor('application').set('parent_many_children', '');
        }
      }
    },

    model: function model(params) {
      //Find the previous route and redirect to it after completing the task
      var previousRoutes = this.router.router.currentHandlerInfos;
      var previousRoute = previousRoutes && previousRoutes.pop();

      if (previousRoute) {
        this.set('lastVisitedRoute', previousRoute.name);
        this.set('lastVisitedFolder', previousRoute.params.folder_id);
      }
      var sesObj = this.get('session');
      var isStudent = false;
      var clipboardObj = null;
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            isStudent = true;
          }
        }
        clipboardObj = sesObj.get('libraryClipboard');
      }

      var cr = this.store.peekAll('class-resource');
      var showTeacher = false;
      var teacher;

      // if the folder_id is an int -> this means we are in a folder
      // if the folder_id is a string -> this means that the folder_id is actually the teachers userId
      if (parseInt(params.folder_id).toString() != params.folder_id.toString()) {
        showTeacher = true;
        var userId = sesObj.session.content.authenticated["userId"];

        teacher = this.store.findRecord('student', userId, { reload: true }).then(function (student) {
          return _lodash.default.find(
          // we concat these because both teachers and tutors have access to the class library
          _lodash.default.concat(student.get('teachers').toArray(), student.get('tutors').toArray()), function (user) {
            return user.get('id') === params.folder_id;
          });
        });
      }

      if (cr.get('length') > 1 && parseInt(params.folder_id).toString() == params.folder_id.toString()) {
        //we're using 1 to guard against the case that they reload on a class library item
        return Ember.RSVP.hash({
          classLibraryFolders: this.store.peekAll('class-library-folder'),
          classResources: this.store.peekAll('class-resource'),
          classLinkedResources: this.store.peekAll('class-linked-resource'),
          curFolderId: params.folder_id,
          tick: 1,
          showTeacher: showTeacher,
          isStudent: isStudent,
          teacher: teacher,
          clipboardObj: clipboardObj,
          folderArray: new Array(),
          //  Filter class library folders based on the folder id.
          filteredClassLibraryFolders: Ember.computed('classLibraryFolders.@each.id', 'classLibraryFolders.@each.parentFolder', function () {
            if (parseInt(params.folder_id).toString() == params.folder_id.toString()) {
              if (params.folder_id > 0) {
                return this.classLibraryFolders.filterBy('parentFolder.id', params.folder_id).sortBy('name');
              } else {
                return this.classLibraryFolders.filter(function (item) {
                  if (!item.get('parentFolder.id')) {
                    return true;
                  }
                }).sortBy('name');
              }
            } else {
              return this.classLibraryFolders.filter(function (item) {
                if (!item.get('parentFolder.id') && item.get('teacher.id') == params.folder_id) {
                  return true;
                }
              }).sortBy('name');
            }
          }),

          curFolderName: Ember.computed('classLibraryFolders.@each.id', function () {
            if (parseInt(params.folder_id).toString() == params.folder_id.toString()) {
              if (params.folder_id > 0) {
                return this.classLibraryFolders.findBy('id', params.folder_id).get('name');
              } else {
                return '';
              }
            }
          }),

          showBorder: Ember.computed('curFolderId', function () {
            if (parseInt(params.folder_id).toString() == params.folder_id.toString()) {
              if (this.curFolderId > 0) {
                return 'showBorder';
              }
            }
          }),

          filteredItems: Ember.computed('classResources.@each.id', 'classLinkedResources.@each.id', 'classResources.@each.classLibraryFolder', 'classLinkedResources.@each.classLibraryFolder', function () {
            var classResources;
            var linkedResources;
            var items = new Array();
            if (parseInt(params.folder_id).toString() == params.folder_id.toString()) {
              if (params.folder_id > 0) {
                classResources = this.classResources.filter(function (resources) {
                  if (resources.get('classLibraryFolder')) {
                    return resources.get('classLibraryFolder.id') == params.folder_id;
                  }
                });
                linkedResources = this.classLinkedResources.filter(function (linkedResources) {
                  if (linkedResources.get('classLibraryFolder')) {
                    return linkedResources.get('classLibraryFolder.id') == params.folder_id;
                  }
                });
              } else {
                classResources = this.classResources.filter(function (resources) {
                  if (!resources.get('classLibraryFolder.id')) {
                    return true;
                  }
                });
                linkedResources = this.classLinkedResources.filter(function (linkedResources) {
                  if (!linkedResources.get('classLibraryFolder.id')) {
                    return true;
                  }
                });
              }
            } else {
              classResources = this.classResources.filter(function (resources) {
                if (!resources.get('classLibraryFolder.id') && resources.get('teacher.id') == params.folder_id) {
                  return true;
                }
              });
              linkedResources = this.classLinkedResources.filter(function (linkedResources) {
                if (!linkedResources.get('classLibraryFolder.id') && linkedResources.get('teacher.id') == params.folder_id) {
                  return true;
                }
              });
            }
            classResources.forEach(function (i) {
              var item = { "type": "resource", "item": i, "name": i.get('name') };
              items.pushObject(item);
            });
            linkedResources.forEach(function (i) {
              var item = { "type": "linkedResource", "item": i, "name": i.get('resource.resourceName') };
              items.pushObject(item);
            });
            return items.sortBy('name');
          }),

          folderId: params.folder_id

        });
      } else {
        return Ember.RSVP.hash({
          classLibraryFolders: this.store.findAll('class-library-folder'),
          classResources: this.store.findAll('class-resource'),
          classLinkedResources: this.store.findAll('class-linked-resource'),
          curFolderId: params.folder_id,
          showTeacher: showTeacher,
          isStudent: isStudent,
          teacher: teacher,
          clipboardObj: clipboardObj,

          folderArray: new Array(),
          //  Filter class library folders based on the folder id.
          filteredClassLibraryFolders: Ember.computed('classLibraryFolders.@each.id', 'classLibraryFolders.@each.parentFolder', function () {
            if (parseInt(params.folder_id).toString() == params.folder_id.toString()) {
              if (params.folder_id > 0) {
                return this.classLibraryFolders.filterBy('parentFolder.id', params.folder_id).sortBy('name');
              } else {
                return this.classLibraryFolders.filter(function (item) {
                  if (!item.get('parentFolder.id')) {
                    return true;
                  }
                }).sortBy('name');
              }
            } else {
              return this.classLibraryFolders.filter(function (item) {
                if (!item.get('parentFolder.id') && item.get('teacher.id') == params.folder_id) {
                  return true;
                }
              }).sortBy('name');
            }
          }),

          curFolderName: Ember.computed('classLibraryFolders.@each.id', function () {
            if (parseInt(params.folder_id).toString() == params.folder_id.toString()) {
              if (params.folder_id > 0) {
                return this.classLibraryFolders.findBy('id', params.folder_id).get('name');
              } else {
                return '';
              }
            }
          }),

          showBorder: Ember.computed('curFolderId', function () {
            if (parseInt(params.folder_id).toString() == params.folder_id.toString()) {
              if (this.curFolderId > 0) {
                return 'showBorder';
              }
            }
          }),

          filteredItems: Ember.computed('classResources.@each.id', 'classLinkedResources.@each.id', 'classResources.@each.classLibraryFolder', 'classLinkedResources.@each.classLibraryFolder', function () {
            var classResources;
            var linkedResources;
            var items = new Array();
            if (parseInt(params.folder_id).toString() == params.folder_id.toString()) {
              if (params.folder_id > 0) {
                classResources = this.classResources.filter(function (resources) {
                  if (resources.get('classLibraryFolder')) {
                    return resources.get('classLibraryFolder.id') == params.folder_id;
                  }
                });
                linkedResources = this.classLinkedResources.filter(function (linkedResources) {
                  if (linkedResources.get('classLibraryFolder')) {
                    return linkedResources.get('classLibraryFolder.id') == params.folder_id;
                  }
                });
              } else {
                classResources = this.classResources.filter(function (resources) {
                  if (!resources.get('classLibraryFolder.id')) {
                    return true;
                  }
                });
                linkedResources = this.classLinkedResources.filter(function (linkedResources) {
                  if (!linkedResources.get('classLibraryFolder.id')) {
                    return true;
                  }
                });
              }
            } else {
              classResources = this.classResources.filter(function (resources) {
                if (!resources.get('classLibraryFolder.id') && resources.get('teacher.id') == params.folder_id) {
                  return true;
                }
              });
              linkedResources = this.classLinkedResources.filter(function (linkedResources) {
                if (!linkedResources.get('classLibraryFolder.id') && linkedResources.get('teacher.id') == params.folder_id) {
                  return true;
                }
              });
            }

            classResources.forEach(function (i) {
              var item = { "type": "resource", "item": i, "name": i.get('name') };
              items.pushObject(item);
            });
            linkedResources.forEach(function (i) {
              var item = { "type": "linkedResource", "item": i, "name": i.get('resource.resourceName') };
              items.pushObject(item);
            });
            return items.sortBy('name');
          }),

          folderId: params.folder_id

        });
      }
    },


    afterModel: function afterModel(model) {
      if (parseInt(model.curFolderId).toString() == model.curFolderId.toString()) {
        if (model.curFolderId == '0') {
          this._super(model, 'Class Library', '0', true);
        } else {
          var curFolder = model.classLibraryFolders.findBy('id', model.curFolderId);
          this._super(model, curFolder.get('name'), model.curFolderId);
        }
      } else {
        var teacher = model.teacher;
        this._super(model, teacher.get('teacherDisplayName'), model.curFolderId);
      }
      //This is here to ensure that the breadcrumbs update. Without it the didRender hook does not always fire
      if (model.tick) {
        model.tick++;
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.set('boundOnTouchStart', this.get('closeContext').bind(this));
    },

    activate: function activate() {
      window.addEventListener("touchstart", this.get('boundOnTouchStart'), false);
    },

    deactivate: function deactivate() {
      window.removeEventListener("touchstart", this.get('boundOnTouchStart'), false);
    },

    closeContext: function closeContext(e) {
      if (e.touches[0].target.className != 'context-menu__item__label' && e.touches[0].target.className != 'context-menu__item') {
        this.get('contextMenu').set('isActive', false);
      }
    },

    actions: {

      createNewClassResource: function createNewClassResource() {
        var folderId = this.get('currentModel.folderId');
        this.transitionTo('add-class-resource', folderId);
      },

      createNewClassLibraryFolder: function createNewClassLibraryFolder() {
        var folderId = this.get('currentModel.folderId');
        this.transitionTo('add-class-resource-folder', folderId);
      },
      updateClassResource: function updateClassResource(classResource) {
        var folderId = this.get('currentModel.folderId');
        this.transitionTo('edit-class-resource', folderId, classResource.get('id'));
      },


      drillFolder: function drillFolder(folderId) {
        this.set('currentModel.folderId', folderId);
        this.transitionTo('class-library', folderId);
      },

      drillResource: function drillResource(id) {
        this.transitionTo('view-class-resource', id);
      },

      drillLinkedResource: function drillLinkedResource(id) {
        this.transitionTo('view-class-linked-resource', id);
      },
      updateClasslibraryFolder: function updateClasslibraryFolder() {
        var folderId = this.get('currentModel.folderId');
        this.transitionTo('edit-class-resource-folder', folderId);
      },


      ConfirmRemoveFolder: function ConfirmRemoveFolder() {
        this.set('currentModel.showModal', true);
        var folderId = this.get('currentModel.folderId');
        var folders = this.get('currentModel.classLibraryFolders').filterBy('parentFolder.id', folderId);
        var resources = this.get('currentModel.classResources').filterBy('classLibraryFolder.id', folderId);
        var linkedResources = this.get('currentModel.classLinkedResources').filterBy('classLibraryFolder.id', folderId);
        if (folders.length > 0 || resources.length > 0 || linkedResources.length > 0) {
          this.set('currentModel.confirmMessage', 'Are you sure that you want to delete this folder and everything in it?');
        } else {
          this.set('currentModel.confirmMessage', 'Are you sure you want to delete this folder?');
        }
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
        this.set('currentModel.alertMessage', '');
      },
      removeFolder: function removeFolder() {
        this.set('currentModel.showModal', false);
        var folderId = this.get('currentModel.folderId');
        var folders = this.get('currentModel.classLibraryFolders').filterBy('parentFolder.id', folderId);
        var resources = this.get('currentModel.classResources').filterBy('classLibraryFolder.id', folderId);
        var linkedResources = this.get('currentModel.classLinkedResources').filterBy('classLibraryFolder.id', folderId);
        var parentFolderId;
        var self = this;
        if (folders.length > 0 || resources.length > 0 || linkedResources.length > 0) {
          this.deleteChildFolders(folders);
          this.get('currentModel.folderArray').pushObject(folderId);
          var deletedFolders = this.get('currentModel.folderArray');

          var adapter = this.get('store').adapterFor('application');
          $.ajax({
            url: adapter.get('host') + '/classLibraryFolders/DeleteFoldersAndResources',
            data: JSON.stringify(deletedFolders),
            dataType: "json",
            contentType: 'application/json; charset=utf-8',
            type: 'DELETE',
            cache: false,
            complete: function complete(response) {
              if (response.readyState == 4) {
                self.get('currentModel.folderArray').forEach(function (folderId) {

                  var classResources = self.store.peekAll('class-resource').filterBy('classLibraryFolder.id', folderId);
                  classResources.forEach(function (item) {
                    item.destroyRecord();
                  });

                  var LinkedClassResources = self.store.peekAll('class-linked-resource').filterBy('classLibraryFolder.id', folderId);
                  LinkedClassResources.forEach(function (item) {
                    item.destroyRecord();
                  });

                  var folder = self.store.peekRecord('class-library-folder', folderId);
                  if (folder.get('parentFolder.id')) {
                    parentFolderId = folder.get('parentFolder.id');
                  } else {
                    parentFolderId = 0;
                  }
                  folder.destroyRecord();
                });

                // self.transitionToPreviousRoute();
                self.transitionTo('class-library', parentFolderId);
              } else {
                self.set('currentModel.showAlert', true);
                var folderName = self.get('currentModel.classLibraryFolders').findBy('id', folderId).get('name');
                self.set('currentModel.alertMessage', "You cannot delete this folder (" + folderName + ")");
              }
            }
          });
        } else {
          var libraryfolder = this.get('currentModel.classLibraryFolders').findBy('id', folderId);
          if (libraryfolder.get('parentFolder.id')) {
            parentFolderId = libraryfolder.get('parentFolder.id');
          } else {
            parentFolderId = 0;
          }
          libraryfolder.destroyRecord().then(function () {
            // self.transitionToPreviousRoute();
            self.transitionTo('class-library', parentFolderId);
          }, function () {
            self.set('currentModel.error', true);
          });
        }
      },

      moveItem: function moveItem(objString) {
        var type = objString.split('|')[0];
        var id = objString.split('|')[1];
        var folderId = objString.split('|')[2];
        if (type != 'folder' || id != folderId) {
          var newFolder = this.store.peekRecord('class-library-folder', folderId);
          if (type == 'folder') {
            var item = this.store.peekRecord('class-library-folder', id);
            item.set('parentFolder', newFolder);
            item.save();
          } else if (type == 'resource') {
            var item = this.store.peekRecord('class-resource', id);
            item.set('classLibraryFolder', newFolder);
            item.save();
          } else if (type == 'linkedResource') {
            var item = this.store.peekRecord('class-linked-resource', id);
            item.set('classLibraryFolder', newFolder);
            item.save();
          }
        }
      },

      pasteItem: function pasteItem() {
        var session = this.get('session');
        var clipboardObj = session.get('libraryClipboard');
        var self = this;
        if (clipboardObj) {
          var id = clipboardObj.id;
          var type = clipboardObj.type;
          var action = clipboardObj.action;
          var newFolder = this.store.peekRecord('class-library-folder', this.get('currentModel.folderId'));

          if (type == 'resource' && action == 'copy') {
            var newClassResource;
            var item = this.store.peekRecord('class-resource', id);
            newClassResource = this.store.createRecord('class-resource');
            newClassResource.set('name', item.get('name'));
            newClassResource.set('description', item.get('description'));
            newClassResource.set('fileUrl', item.get('fileUrl'));
            newClassResource.set('webUrl', item.get('webUrl'));
            newClassResource.set('copiedFrom', item.get('id'));
            newClassResource.set('teacher', item.get('teacher'));
            newClassResource.set('classLibraryFolder', newFolder);
            newClassResource.save();
          } else if (type == 'linkedResource' && action == 'copy') {
            var newLinkedResource;
            var item = this.store.peekRecord('class-linked-resource', id);
            newLinkedResource = this.store.createRecord('class-linked-resource');
            newLinkedResource.set('resource', item.get('resource'));
            newLinkedResource.set('description', item.get('description'));
            newLinkedResource.set('teacher', item.get('teacher'));
            newLinkedResource.set('classLibraryFolder', newFolder);
            newLinkedResource.save();
          } else if (type == 'resource' && action == 'cut') {
            var item = this.store.peekRecord('class-resource', id);
            item.set('cutFrom', id);
            item.set('classLibraryFolder', newFolder);
            item.save().then(function () {
              session.set('libraryClipboard', null);
              self.set('currentModel.clipboardObj', null);
            });
            this.set('session.libraryClipboard', '');
          } else if (type == 'linkedResource' && action == 'cut') {
            var item = this.store.peekRecord('class-linked-resource', id);
            item.set('classLibraryFolder', newFolder);
            item.save().then(function () {
              session.set('libraryClipboard', null);
              self.set('currentModel.clipboardObj', null);
            });
            this.set('session.libraryClipboard', '');
          }
        }
      }

    },

    transitionToPreviousRoute: function transitionToPreviousRoute() {
      var self = this;
      var folderId = '0';
      if (self.get('lastVisitedFolder')) {
        folderId = self.get('lastVisitedFolder');
      }
      self.transitionTo('class-library', folderId);
    },
    deleteChildFolders: function deleteChildFolders(folders) {
      var length = folders.length;
      for (var i = 0; i < length; i++) {
        var childFolderId = folders[i].id;
        var subfolders = this.get('currentModel.classLibraryFolders').filterBy('parentFolder.id', childFolderId);
        if (subfolders.length > 0) {
          this.deleteChildFolders(subfolders);
        }
        this.get('currentModel.folderArray').pushObject(childFolderId);
      }
    }
  });
});
define('ohs/components/game-mode-banner-rover', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var GameModeBannerRoverComponent = Ember.Component.extend({});

    GameModeBannerRoverComponent.reopenClass({
        positionalParams: ['position']
    });

    exports.default = GameModeBannerRoverComponent;
});
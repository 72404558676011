define('ohs/models/resource', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    resourceBookmarks: _emberData.default.hasMany('resource-bookmarks'),
    description: _emberData.default.attr('string'),
    webUrl: _emberData.default.attr('string'),
    attachmentUrl: _emberData.default.attr('string'),
    resourceType: _emberData.default.attr('string'),
    resourceSubtype: _emberData.default.attr('string'),
    resourceName: _emberData.default.attr('string'),
    videoLengthMinutes: _emberData.default.attr('number'),
    videoLengthSeconds: _emberData.default.attr('number'),
    lessonPlanMaterialsNeeded: _emberData.default.attr('string'),
    lessonPlanAssessmentType: _emberData.default.attr('string'),
    lessonPlanLength: _emberData.default.attr('string'),
    gradeLevelComments: _emberData.default.attr('string'),
    subject: _emberData.default.belongsTo('subject'),
    costValue: _emberData.default.attr('number'),
    accessLevel: _emberData.default.attr('string'),
    costComments: _emberData.default.attr('string'),
    additionalNotes: _emberData.default.attr('string'),

    audiences: _emberData.default.hasMany('audience'),
    costTypes: _emberData.default.hasMany('cost-type'),
    gradeLevels: _emberData.default.hasMany('grade-level'),
    interestAreas: _emberData.default.hasMany('interest-area'),
    interestLevels: _emberData.default.hasMany('interest-level'),
    appropriateFors: _emberData.default.hasMany('appropriate-for'),
    platforms: _emberData.default.hasMany('platform'),
    standards: _emberData.default.hasMany('standard'),
    resourceUserReviews: _emberData.default.hasMany('resource-user-review'),
    studentViews: _emberData.default.attr('number'),
    classLinkedResources: _emberData.default.hasMany('class-linked-resource'),

    flashRequirement: _emberData.default.attr('string'),
    graphiteRating: _emberData.default.attr('number'),
    teacherRating: _emberData.default.attr('number'),
    reviewURL: _emberData.default.attr('string'),
    sendDate: _emberData.default.attr('date'),
    viewedDate: _emberData.default.attr('date'),
    sendDateFormatted: Ember.computed('sendDate', function () {
      if (this.get('sendDate')) {
        var date = this.get('sendDate').toLocaleDateString();
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (today.toLocaleDateString() == date) return 'Today';else if (yesterday.toLocaleDateString() == date) {
          return 'Yesterday';
        } else {
          return date;
        }
      }
    }),
    viewedDateFormatted: Ember.computed('viewedDate', function () {
      if (this.get('viewedDate')) {
        var date = this.get('viewedDate').toLocaleDateString();
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (today.toLocaleDateString() == date) return 'Today';else if (yesterday.toLocaleDateString() == date) {
          return 'Yesterday';
        } else {
          return date;
        }
      }
    }),
    eligibleContentQueryString: Ember.computed('standards', function () {
      var stan = this.get('standards');
      // if(this.get('passage.firstObject')){
      if (this.get('subjectId') == 2) {
        var cont = stan.filterBy('standardLevelId', 1).sortBy('standardOrderId');
      } else {
        //use filterBy because we can have more than one eligible content so we need this property to be an array
        var cont = stan.filterBy('standardLevelId', 3).sortBy('standardOrderId');
      }
      //We want a comma separated list of standard ids that we can pass to the api
      var returnString = "";
      if (cont) {
        for (var i = 0; i < cont.length; i++) {
          if (i + 1 == cont.length) {
            returnString += cont[i].get('standardCode');
          } else {
            returnString += cont[i].get('standardCode') + ", ";
          }
        }
        if (returnString.length > 0) {
          returnString = returnString.substr(0, returnString.length - 1);
        }
      }
      return returnString;
    }),

    requiresFlash: Ember.computed('flashRequirement', function () {
      if (this.get('flashRequirement') == 'Flash') {
        return true;
      }
    }),

    resourceTypeDisplay: Ember.computed('resourceType', 'resourceSubtype', 'flashRequirement', function () {
      if (this.get('resourceType')) {
        if (this.get('resourceType') == 'Teacher Resources') {
          if (this.get('resourceSubtype') && this.get('resourceSubtype') != "Other") {
            return this.get('resourceSubtype');
          } else {
            return 'Other Teacher Resource';
          }
        } else {
          var returnString = this.get('resourceType');
          if (this.get('resourceSubtype') && this.get('resourceSubtype') != "N/A") {
            returnString += "- " + this.get('resourceSubtype');
          }
          // if (this.get('flashRequirement') == 'Flash') {
          //   returnString += " (Flash Required)";
          // }
          return returnString;
        }
      }
    }),

    viewsText: Ember.computed('studentViews', function () {
      var numViews = this.get('studentViews');
      if (numViews == 1) {
        return '1 Student View';
      } else {
        return numViews.toString() + ' Student Views';
      }
    }),

    level: Ember.computed('interestLevels.@each.description', function () {
      var levels = this.get('interestLevels').toArray();
      if (levels.length > 0) {
        //only a single level
        if (levels.length == 1) {
          return levels[0].get('description');
        }
        //all 3 levels
        else if (levels.length == 3) {
            return "Above, At and Below Grade Level";
          }
          //two levels
          else {
              //if it contains below, it must be at and below
              if (this.get('interestLevels').findBy('id', '1')) {
                return "At and Below Grade Level";
              }
              //otherwise it is above and at
              else {
                  return "Above and At Grade Level";
                }
            }
      }
    }),

    levelIcon: Ember.computed('interestLevels.@each.description', function () {
      var levels = this.get('interestLevels').toArray();
      //only a single level
      if (levels.length == 1) {
        if (this.get('interestLevels').findBy('id', '1')) {
          return 'icon icon-grade-below';
        } else if (this.get('interestLevels').findBy('id', '2')) {
          return 'icon icon-grade-at';
        } else {
          return 'icon icon-grade-above';
        }
      }
      //all 3 levels
      else if (levels.length == 3) {
          return 'icon icon-grade-above-at-below';
        }
        //two levels
        else {
            //if it contains below, it must be at and below
            if (this.get('interestLevels').findBy('id', '1')) {
              return 'icon icon-grade-at-below';
            }
            //otherwise it is above and at
            else {
                return 'icon icon-grade-above-at';
              }
          }
    }),

    ratings: Ember.computed('resourceUserReviews.@each.id', function () {
      return this.get('resourceUserReviews').get('length');
    }),

    ratingsString: Ember.computed('ratings', function () {
      if (this.get('ratings') == 1) {
        return '1 rating';
      } else {
        return this.get('ratings') + ' ratings';
      }
    }),

    averageRating: Ember.computed('resourceUserReviews.@each.ratingScore', function () {
      var total = 0;
      var numRatings = 0;
      this.get('resourceUserReviews').forEach(function (item, index) {
        total += item.get('ratingScore');
        numRatings++;
      });
      var average = total / numRatings;
      return Math.round(average);
    }),

    typeIcon: Ember.computed('resourceType', function () {
      // There are two types of resources (websites and physical desktop applications) We differentiate the icons here
      if (this.get('resourceType') == 'Website') {
        // If it is a website that has a sub resource type (like a website with videos or games or something)
        // we differentiate that here. To add more just add to the if else with your specific case
        if (this.get('resourceSubtype') == 'Video') {
          return "icon icon-nav-video";
        } else if (this.get('resourceSubtype') == 'Game') {
          return "icon icon-nav-games";
        } else if (this.get('resourceSubtype') == 'Skill Practice/Activity') {
          return "icon icon-nav-practice";
        } else {
          return "icon icon-website";
        }
      }
      if (this.get('resourceType') == 'App') {
        return "icon icon-app";
      }
    }),

    costValueFormatted: Ember.computed('costValue', function () {
      if (this.get('costValue') > 0) {
        return this.get('costValue').toFixed(2);
      }
    }),

    interestAreasFiltered: Ember.computed('interestAreas.@each.id', function () {
      return this.get('interestAreas').filter(function (interest, index, array) {
        return interest.get('id') != 15;
      });
    }),

    displayDescription: Ember.computed('description', function () {
      if (this.get('description')) {
        if (this.get('description').length > 1000) {
          return this.get('description').substr(0, 997) + '...';
        } else {
          return this.get('description');
        }
      }
    }),

    commonSenseApproved: Ember.computed('graphiteRating', function () {
      if (this.get('graphiteRating')) {
        if (this.get('graphiteRating') >= 4) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }),

    belowSortValue: Ember.computed('level', function () {
      var level = this.get('level');
      if (level == 'Below Grade Level') {
        return 1;
      }
      if (level == 'At and Below Grade Level') {
        return 2;
      }
      if (level == 'Above, At and Below Grade Level') {
        return 3;
      }
      if (level == 'At Grade Level') {
        return 4;
      }
      if (level == 'Above and At Grade Level') {
        return 5;
      }
      if (level == 'Above Grade Level') {
        return 6;
      }
    }),

    atSortValue: Ember.computed('level', function () {
      var level = this.get('level');
      if (level == 'Below Grade Level') {
        return 5;
      }
      if (level == 'At and Below Grade Level') {
        return 2;
      }
      if (level == 'Above, At and Below Grade Level') {
        return 4;
      }
      if (level == 'At Grade Level') {
        return 1;
      }
      if (level == 'Above and At Grade Level') {
        return 3;
      }
      if (level == 'Above Grade Level') {
        return 6;
      }
    }),

    aboveSortValue: Ember.computed('level', function () {
      var level = this.get('level');
      if (level == 'Below Grade Level') {
        return 6;
      }
      if (level == 'At and Below Grade Level') {
        return 5;
      }
      if (level == 'Above, At and Below Grade Level') {
        return 3;
      }
      if (level == 'At Grade Level') {
        return 4;
      }
      if (level == 'Above and At Grade Level') {
        return 2;
      }
      if (level == 'Above Grade Level') {
        return 1;
      }
    }),

    numStandards: Ember.computed('standards.@each.id', function () {
      return this.get('standards').get('length');
    }),

    numStandardsSortValue: Ember.computed('numStandards', function () {
      if (this.get('numStandards') <= 3) {
        return 0;
      } else {
        return 1;
      }
    }),

    standardCodeDisplay: Ember.computed('numStandards', function () {
      if (this.get('numStandards') > 1) {
        return this.get('numStandards') + ' Standards';
      } else {
        return this.get('standards').get('firstObject').get('standardCode');
      }
    }),

    ratingSortValue: Ember.computed('averageRating', function () {
      if (this.get('averageRating') >= 4) {
        return 0;
      } else {
        return 1;
      }
    }),

    averageRatingSort: Ember.computed('averageRating', function () {
      if (this.get('averageRating')) {
        return 5 - this.get('averageRating');
      } else {
        return 6;
      }
    }),

    resourceTypeSort: Ember.computed('resourceSubtype', function () {
      if (this.get('resourceSubtype') == 'Video' || this.get('resourceSubtype') == 'Game' || this.get('resourceSubtype') == 'Skill Practice/Activity') {
        return 0;
      } else {
        return 1;
      }
    }),

    appropriate: Ember.computed('interestLevels.@each.description', function () {
      var appropriates = this.get('appropriateFors').toArray();
      if (appropriates.length > 0) {
        if (appropriates.length == 1) {
          return appropriates[0].get('description');
        } else {
          var appropriatesReverse = appropriates.sortBy('id').toArray().reverse();
          var selectedAppropriates = '';
          var count = appropriates.length;
          appropriatesReverse.forEach(function (item, index, enumerable) {
            if (selectedAppropriates != '') {
              if (index + 1 == count) {
                selectedAppropriates += ' and ';
              } else {
                selectedAppropriates += ', ';
              }
            }
            selectedAppropriates += item.get('description');
          });
          return selectedAppropriates;
        }
      }
    })

  });
});
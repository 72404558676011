define('ohs/mirage/config', ['exports', 'ohs/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function () {

        this.urlPrefix = 'http://devapi.kandoolu.com'; // make this `http://localhost:8080`, for example, if your API is on a different server
        this.namespace = '/api';
        this.timing = 400; // simulate network delay

        this.get('/galaxies');
        this.get('/studentMissions');
        this.get('/studentMissions/:id');
        this.get('/missions');
        this.get('/missions/:id');
        this.post('/studentMissions', function (db, request) {
            var studentMission = db.studentMissions.create(request.requestBody);
            return studentMission;
        });

        // allows everything else to passthrough
        this.passthrough(_environment.default.apiUrl + '/**');
    };
});
define('ohs/routes/manage-teachers', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Manage Teachers',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          } else if (roles.indexOf("Parent") > 0) {
            this.controllerFor('application').set('parent_many_children', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
          this.controllerFor('application').set('parent_many_children', '');
        }
      }
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var account = this.store.peekAll('account');
      var accountId = account.objectAt(0).id;
      var userId = this.get('session').session.content.authenticated["userId"];

      return Ember.RSVP.hash({
        teachers: this.store.query('teacher', { accountId: accountId }),
        userCreationError: this.store.query('userCreationError', { userRole: 'Teacher' }),
        rosterCreationError: this.store.query('rosterCreationError', { userId: userId }),
        orderedTeachers: Ember.computed('teacher', 'searchBox', function () {
          return this.teachers.filter(function (teach, index, array) {
            if (this.searchBox) {
              return teach.get('fullName').toLowerCase().includes(this.searchBox.toLowerCase()) || teach.get('userName').toLowerCase().includes(this.searchBox.toLowerCase());
            } else {
              return true;
            }
          }, this).sortBy('fullNameReversed');
        }),
        loadFinished: true,
        searchBox: ''
      });
    },


    afterModel: function afterModel(model) {
      this._super(model, 'Manage Teachers', false, true);
    },

    actions: {
      generateTeacherUserTemplate: function generateTeacherUserTemplate() {
        var self = this;
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = 'TeacherUserTemplate.csv';
        var xhr = new XMLHttpRequest();
        xhr.open('GET', baseUrl + '/teachers/DownloadCsvTemplate?fileName=' + encodeURIComponent(fileName), true);
        xhr.responseType = "blob";
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            var blobType = self.getBlobType();
            var blob = new Blob([xhr.response], { type: blobType });
            saveAs(blob, fileName);

            // if(navigator.userAgent.match('CriOS')){
            //     // var blob = new Blob([xhr.response], {type: "text/csv;base64"});
            //     var blob = new Blob([xhr.response], {type: "application/octet-stream"});
            // }else {
            //
            //       //var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
            //       var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
            //       if(isSafari){
            //         var blob = new Blob([xhr.response], {type: "application/octet-stream"});
            //       }else {
            //         var blob = new Blob([xhr.response], {type: "application/csv"});
            //       }
            // }
            // saveAs(blob,fileName);

            // var userAgent = navigator.userAgent || navigator.vendor || window.opera;
            // var isMac = navigator.platform.toUpperCase().indexOf('MAC')>=0;
            //
            //      // Windows Phone must come first because its UA also contains "Android"
            //    if (/windows phone/i.test(userAgent)) {
            //        alert("Windows Phone");
            //    }
            //
            //    if (/android/i.test(userAgent)) {
            //        alert("Android");
            //    }
            //
            //    // iOS detection from: http://stackoverflow.com/a/9039885/177710
            //    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            //        alert("iOS");
            //            if(navigator.userAgent.match('CriOS')){
            //              var blob = new Blob([xhr.response], {type: "text/csv;base64"});
            //            }else{
            //              var blob = new Blob([xhr.response], {type: "application/octet-stream"});
            //            }
            //    }else if(isMac){
            //      var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
            //      if(isSafari){
            //        var blob = new Blob([xhr.response], {type: "application/octet-stream"});
            //      }else {
            //        var blob = new Blob([xhr.response], {type: "text/csv;base64"});
            //      }
            //    }else{
            //         var blob = new Blob([xhr.response], {type: "text/csv;base64"});
            //    }
            //  saveAs(blob,fileName);


            // var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
            // if(isSafari){
            //   var blob = new Blob([xhr.response], {type: "application/octet-stream"});
            // }
            // else{
            //   var blob = new Blob([xhr.response], {type: "application/pdf"});
            // }
            // saveAs(blob,fileName);

            //   saveAs(
            //     new Blob(
            //   [(new XMLSerializer).serializeToString(document)], {type: "text/csv;base64"}), fileName
            // );
          }
        };
        xhr.send(null);
      },
      generateRosterTemplate: function generateRosterTemplate() {
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = 'RosterTemplate.csv';

        var xhr = new XMLHttpRequest();
        xhr.open('GET', baseUrl + '/teachers/DownloadCsvTemplate?fileName=' + encodeURIComponent(fileName), true);
        xhr.responseType = "blob";
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            var blob = new Blob([xhr.response], { type: "text/csv;base64" });
            // var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
            // if(isSafari){
            //   var blob = new Blob([xhr.response], {type: "application/octet-stream"});
            // }
            // else{
            //   var blob = new Blob([xhr.response], {type: "application/pdf"});
            // }
            saveAs(blob, fileName);
          }
        };
        xhr.send(null);
      },
      uploadTeacherUsers: function uploadTeacherUsers() {
        var self = this;
        self.set("currentModel.loadFinished", false);
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = 'TeacherUserTemplate.csv';
        var file = $('[name="uploadTeacherUsersList"]').prop("files")[0];

        if (file) {
          // Csv file validation
          //  var validExts = new Array(".xlsx", ".xls", ".csv");
          var validExts = new Array(".xls", ".csv");
          var fileExt = file.name.substring(file.name.lastIndexOf('.'));
          if (validExts.indexOf(fileExt) < 0) {
            self.set("currentModel.showAlert", true);
            self.set('currentModel.alertMessage', "Please upload a valid file!");
            self.set("currentModel.loadFinished", true);
            return;
          }
          var sesObj = this.get('session');
          var userId = sesObj.session.content.authenticated["userId"];

          var formData = new FormData();
          formData.append(fileName, file);

          var xhr = new XMLHttpRequest();
          xhr.open('POST', baseUrl + '/accounts/UploadTeacherUsers?userId=' + encodeURIComponent(userId), true);

          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
              self.set("currentModel.showConfirm", true);

              var response = xhr.response.split('"').join("");
              if (typeof InstallTrigger !== 'undefined') {
                response = $(response).text();
              }
              self.set("currentModel.confirmMessage", response);
            }
          };

          xhr.send(formData);
        } else {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Please select a file to upload!");
          self.set("currentModel.loadFinished", true);
        }
      },
      uploadRoster: function uploadRoster() {
        var self = this;
        self.set("currentModel.loadFinished", false);
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = 'RosterTemplate.csv';
        var file = $('[name="uploadRosterList"]').prop("files")[0];

        if (file) {
          // Csv file validation
          // var validExts = new Array(".xlsx", ".xls", ".csv");
          var validExts = new Array(".xls", ".csv");
          var fileExt = file.name.substring(file.name.lastIndexOf('.'));
          if (validExts.indexOf(fileExt) < 0) {
            self.set("currentModel.showAlert", true);
            self.set('currentModel.alertMessage', "Please upload a valid file!");
            self.set("currentModel.loadFinished", true);
            return;
          }

          var account = this.store.peekAll('account');
          var accountId = account.objectAt(0).id;
          var userId = this.get('session').session.content.authenticated["userId"];

          var formData = new FormData();
          formData.append(fileName, file);

          var xhr = new XMLHttpRequest();
          xhr.open('POST', baseUrl + '/students/UploadRoster?userId=' + encodeURIComponent(userId) + '&accountId=' + encodeURIComponent(accountId), true);

          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
              self.set("currentModel.showConfirm", true);

              var response = xhr.response.split('"').join("");
              if (typeof InstallTrigger !== 'undefined') {
                response = $(response).text();
              }
              self.set("currentModel.confirmMessage", response);
            }
          };

          xhr.send(formData);
        } else {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Please select a file to upload!");
          self.set("currentModel.loadFinished", true);
        }
      },
      updateErrorUser: function updateErrorUser(errorUser) {
        this.transitionTo('new-user', 'Teacher', errorUser.get('id'));
      },
      updateErrorRoster: function updateErrorRoster(errorRoster) {
        this.transitionTo('roster-error-update', errorRoster.get('id'));
      },
      confirmRemoveErrorUser: function confirmRemoveErrorUser(errorUser) {
        this.set('currentModel.selectedErrorUser', errorUser);
        this.set('currentModel.showErrorUserConfirm', true);
        this.set('currentModel.modalMessage', 'Are you sure you want to delete this teacher from the error list?');
      },
      confirmRemoveErrorRoster: function confirmRemoveErrorRoster(errorRoster) {
        this.set('currentModel.selectedErrorRoster', errorRoster);
        this.set('currentModel.showErrorRosterConfirm', true);
        this.set('currentModel.modalMessage', 'Are you sure you want to delete this roster from the error list?');
      },
      removeErrorUser: function removeErrorUser() {
        var self = this;
        self.set('currentModel.showErrorUserConfirm', false);
        self.set('currentModel.modalMessage', null);
        self.set("currentModel.loadFinished", false);
        var errorUser = this.get('currentModel.selectedErrorUser');

        errorUser.destroyRecord().then(function () {
          self.set("currentModel.showConfirm", true);
          self.set("currentModel.confirmMessage", 'This teacher has been deleted from the error list!');
        }, function () {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "This teacher could not be deleted from the error list. Please check your Internet connection and try again.");
        });
      },
      removeErrorRoster: function removeErrorRoster() {
        var self = this;
        self.set('currentModel.showErrorRosterConfirm', false);
        self.set('currentModel.modalMessage', null);
        self.set("currentModel.loadFinished", false);
        var errorRoster = this.get('currentModel.selectedErrorRoster');

        errorRoster.destroyRecord().then(function () {
          self.set("currentModel.showConfirm", true);
          self.set("currentModel.confirmMessage", 'Roster has been deleted from the error list!');
        }, function () {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Roster could not be deleted from the error list. Please check your Internet connection and try again.");
        });
      },
      drill: function drill(id) {
        this.transitionTo('manage-teacher-profile', id);
      },
      confirmRemoveTeacher: function confirmRemoveTeacher(teacher) {
        this.set('currentModel.selectedTeacher', teacher);
        this.set('currentModel.showModal', true);
        this.set('currentModel.modalMessage', 'Are you sure you want to delete this teacher from this account?');
      },
      removeTeacher: function removeTeacher() {
        this.set('currentModel.showModal', false);
        this.set('currentModel.modalMessage', null);

        var self = this;
        var teacher = this.get('currentModel.selectedTeacher');
        teacher.destroyRecord().then(function () {
          self.set("currentModel.showConfirm", true);
          self.set("currentModel.confirmMessage", 'This teacher has been deleted from your account!');
        }, function () {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Teacher could not be deleted. Please check your Internet connection and try again.");
        });
      },


      hideConfirm: function hideConfirm() {
        var self = this;
        self.refresh();
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
        this.set('currentModel.alertMessage', '');
      },

      createTeacher: function createTeacher() {
        this.transitionTo('new-user', 'Teacher', 0);
      },


      showUploadSection: function showUploadSection() {
        if (this.get('currentModel.showUploadSection')) {
          this.set('currentModel.showUploadSection', false);
        } else {
          this.set('currentModel.showUploadSection', true);
          if (this.get('currentModel.showRosterUploadSection')) {
            this.set('currentModel.showRosterUploadSection', false);
          }
        }
      },

      showRosterUploadSection: function showRosterUploadSection() {
        if (this.get('currentModel.showRosterUploadSection')) {
          this.set('currentModel.showRosterUploadSection', false);
        } else {
          this.set('currentModel.showRosterUploadSection', true);
          if (this.get('currentModel.showUploadSection')) {
            this.set('currentModel.showUploadSection', false);
          }
        }
      }

    },
    getBlobType: function getBlobType() {

      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      var isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        if (navigator.userAgent.match('CriOS')) {
          return "text/csv;base64";
        } else {
          return "application/octet-stream";
        }
      } else if (isMac) {
        var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
        if (isSafari) {
          return "application/octet-stream";
        } else {
          return "text/csv;base64";
        }
      } else {
        return "text/csv;base64";
      }
    }
  });
});
define('ohs/components/teacher-mastery', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Component.extend({
        tagName: 'div',

        store: Ember.inject.service(),

        // beforeDate: Date.now(),
        // afterDate: new Date(-8640000000000000),
        showStudentReport: false,
        isLoading: false,
        // selectedStudents: [],
        selectedTeachers: [],

        teachers: Ember.computed('data.[]', function () {
            var store = this.get('store');

            return this.get('data').map(function (a) {
                return store.findRecord('teacher', a.teacherId);
            }).sortBy('lastName');
        }),

        x_headers: Ember.computed('filteredData.[]', function () {
            var headers = this.get('filteredData').reduce(function (previous, current) {
                var result = previous;
                current.performance.forEach(function (a) {
                    if (!result.has(a.standardOrderId)) {
                        result.set(a.standardOrderId, a.standardCode);
                    }
                });
                return result;
            }, new Map());

            var self = this;
            return Array.from(new Map([].concat(_toConsumableArray(headers.entries())).sort()).values()).map(function (a) {

                //average for each column(standard wise)**START**
                var data = self.get('filteredData');
                var correct = 0;
                var attempted = 0;
                for (var i = 0; i < data.length; i++) {
                    var performance = data.objectAt(i).performance.filterBy('standardCode', a);
                    for (var j = 0; j < performance.length; j++) {
                        correct = correct + performance.objectAt(j).totalCorrect;
                        attempted = attempted + performance.objectAt(j).totalAttempted;
                    }
                }
                var avg = "Average: " + Math.round(correct / attempted * 100) + "%";
                //average for each column(standard wise)**END**

                return { text: a, average: avg };
            });
        }),

        totalAverage: Ember.computed('filteredData.[]', function () {
            // to find total average
            var data = this.get('filteredData');
            var totCorrect = 0;
            var totAttempted = 0;
            for (var i = 0; i < data.length; i++) {
                var performance = data.objectAt(i).performance;
                for (var j = 0; j < performance.length; j++) {
                    totCorrect = totCorrect + performance.objectAt(j).totalCorrect;
                    totAttempted = totAttempted + performance.objectAt(j).totalAttempted;
                }
            }
            var totAvg = "Average for all: " + Math.round(totCorrect / totAttempted * 100) + "%";
            return totAvg;
        }),

        rows: Ember.computed('filteredData.[]', function () {
            var _this = this;

            var rows = this.get('filteredData').map(function (a) {
                var columns = _this.get('x_headers').map(function (b, index) {
                    var propertyIndex = _this.getIndexOfProperty(a.performance, 'standardCode', '' + b.text);
                    var color = '';

                    if (a.performance[propertyIndex] === undefined || !a.performance[propertyIndex].totalAttempted > 0) {
                        return {
                            index: index,
                            totalAttempted: 0,
                            totalCorrect: 0,
                            percentCorrectDisplay: 'N/A',
                            scoreDisplay: 'N/A',
                            standard: b.text,
                            classes: ''
                        };
                    } else if (a.performance[propertyIndex].percentCorrect > 79) {
                        color = 'high';
                    } else if (a.performance[propertyIndex].percentCorrect > 59) {
                        color = 'mid';
                    } else {
                        color = 'low';
                    }

                    return {
                        index: index,
                        totalAttempted: a.performance[propertyIndex].totalAttempted,
                        totalCorrect: a.performance[propertyIndex].totalCorrect,
                        percentCorrectDisplay: a.performance[propertyIndex].percentCorrect + '%',
                        scoreDisplay: a.performance[propertyIndex].scoreDisplay,
                        standard: b.text,
                        classes: color
                    };
                });

                var teacher = _this.get('store').findRecord('teacher', a.teacherId);

                return {
                    //cells
                    columns: columns.sort(function (b, c) {
                        if (b.index < c.index) {
                            return -1;
                        } else if (b.index > c.index) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }),
                    //teacher
                    teacher: teacher
                };
            });
            return rows;
        }),

        filteredData: Ember.computed('data.[]', 'selectedTeachers', function () {
            var _this2 = this;

            return this.get('data').reduce(function (a, b) {
                var previous = a;

                //check if teacher is selected
                if (_this2.get('selectedTeachers').length > 0) {
                    if (!_this2.get('selectedTeachers').some(function (c) {
                        return c.get('id') == b.teacherId;
                    })) {
                        return previous;
                    }
                }

                var current = {
                    teacherId: b.teacherId,
                    performance: b.standardPerformance.map(function (c) {
                        var answers = c.answers;
                        // .filter((answer) => {
                        //     var finished = new Date(answer.dateFinished);
                        //     return finished < this.get('beforeDate')
                        //         && finished > this.get('afterDate');
                        // });
                        var correct = answers.filter(function (answer) {
                            return answer.isCorrect;
                        });

                        return {
                            standardCode: c.standardCode,
                            standardOrderId: c.standardOrderId,
                            totalCorrect: correct.length,
                            totalAttempted: answers.length,
                            percentCorrect: answers.length == 0 ? 0 : +(correct.length / answers.length * 100).toFixed(0),
                            scoreDisplay: correct.length + '/' + answers.length
                        };
                    })
                };

                //check if student has any results
                if (current.performance.reduce(function (b, c) {
                    return b + c.totalAttempted;
                }, 0) > 0) {
                    previous.pushObject(current);
                }

                return previous;
            }, []);
        }),

        actions: {
            back: function back() {
                this.sendAction('return');
            },
            goBack: function goBack() {
                this.set('showStudentReport', false);
                this.set('reportData', null);
                this.set('isAllStudentsSelected', true);
            },
            hideAlert: function hideAlert() {
                this.set('showAlert', false);
                this.set('alertMessage', '');
            },
            StudentStandardCoverage: function StudentStandardCoverage(teacherId) {
                var host = this.get('store').adapterFor('application').get('host');
                var self = this;
                Ember.$.ajax({
                    url: host + '/report/studentStandardMastery?userId=' + teacherId,
                    data: {
                        groupIds: [],
                        studentIds: [],
                        isAllStudentsSelected: true,
                        reportingCategory: self.get('reportingCategory')
                    },
                    type: 'Get'
                }).then(function (response) {
                    self.set('reportData', response);
                    self.set('showStudentReport', true);
                    self.set('isLoading', false);
                }, function (xhr, status, error) {
                    // console.log(xhr.responseText);
                    self.set("showAlert", true);
                    self.set("alertMessage", 'Oops! No results were found under that search, Please try again.');
                });
            }
        },

        getIndexOfProperty: function getIndexOfProperty(array, attribute, value) {
            for (var x = 0; x < array.length; x++) {
                if (array[x][attribute] === value) {
                    return x;
                }
            }

            return -1;
        }
    });
});
define('ohs/components/mission-control/mission-details-actions', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'aside',
        classNames: ['sidebar']
    });
});
define('ohs/routes/student-account-list-add-remove', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _breadcrumbMixin.default, {
    titleToken: function titleToken(model) {
      return model.teacher.get('fullName');
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      //Find the previous route and redirect to it after completing the task
      var previousRoutes = this.router.router.currentHandlerInfos;
      var previousRoute = previousRoutes && previousRoutes.pop();
      if (previousRoute) {
        this.set('lastVisitedRoute', previousRoute.name);
      }
    },

    model: function model(params) {
      var self = this;
      return Ember.RSVP.hash({
        teacherId: params.teacher_id,
        teacher: this.store.findRecord('teacher', params.teacher_id),
        account: this.store.peekRecord('account', params.accountId),
        accountLinkedStudents: this.store.query('student', { accountId: params.accountId }),
        studentSearch: "",
        filteredStudents: Ember.computed('studentSearch', 'accountLinkedStudents.[]', function () {
          return this.accountLinkedStudents.filter(function (stu, index, array) {
            if (this.studentSearch) {
              return stu.get('fullName').toLowerCase().includes(this.studentSearch.toLowerCase()) || stu.get('userName').toLowerCase().includes(this.studentSearch.toLowerCase());
            } else {
              return true;
            }
          }, this).sortBy('lastName');
        })
      });
    },
    afterModel: function afterModel(model) {
      this._super(model, 'Add or Remove Students', model.teacher.get('id'));
    },


    deactivate: function deactivate() {
      $('li:contains("Account Admin")').find('li:contains("Manage Teachers")').children().first().removeClass('active');
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Account Admin")').find('li:contains("Manage Teachers")').children().first().addClass('active');
      }, 100);
    },

    actions: {
      finish: function finish() {
        var teacherId = this.get('currentModel.teacherId');
        if (this.get('lastVisitedRoute')) {
          this.transitionTo(this.get('lastVisitedRoute'), teacherId);
        } else {
          this.transitionTo('manage-teacher-profile', teacherId);
        }
      },
      hideKeyboard: function hideKeyboard() {
        document.activeElement.blur();
      },

      goToQuizzes: function goToQuizzes() {
        this.transitionTo('teacher-quizzes');
      },

      loading: function loading(transition, originRoute) {
        if (originRoute != this) return true;
      },

      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});
define('ohs/routes/change-avatar', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset'], function (exports, _authenticatedRouteMixin, _reset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, {
    titleToken: 'Choose an Avatar',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);

      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          } else if (roles.indexOf("Parent") > 0) {
            this.controllerFor('application').set('parent_many_children', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
          this.controllerFor('application').set('parent_many_children', '');
        }
      }
    },

    beforeModel: function beforeModel() {
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (!roles) {
          this.transitionTo('home');
        }
      }
      this.store.unloadAll('avatar-face');
    },

    model: function model() {

      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      var self = this;

      return this.store.findRecord('user', userId).then(function (userRecord) {
        return Ember.RSVP.hash({
          user: userRecord,
          avatars: self.store.findAll('avatar-face'),
          currentColor: userRecord.get('avatarColor'),
          oldColor: '!color!',
          coloredAvatars: Ember.computed('avatars.@each.filePattern', 'currentColor', function () {
            var self = this;
            var a = this.avatars;
            var re = new RegExp(this.oldColor, "gi");
            a.forEach(function (item) {
              item.set('filePattern', item.get('filePattern').replace(re, self.currentColor));
            });
            return a;
          }),

          availableColors: [{
            id: 'Green',
            text: 'Green',
            background: '#a8cb51'
          }, {
            id: 'Blue',
            text: 'Blue',
            background: '#8fd4d7'
          }, {
            id: 'Orange',
            text: 'Orange',
            background: '#ec653c'
          }, {
            id: 'Pink',
            text: 'Pink',
            background: '#d6438c'
          }, {
            id: 'Yellow',
            text: 'Yellow',
            background: '#f7bd3b'
          }, {
            id: 'Gray',
            text: 'Gray',
            background: '#b2b4b8'
          }]
        });
      });
    },


    actions: {
      pickAvatar: function pickAvatar(avatar) {
        var self = this;
        var user = this.get('currentModel.user');
        user.set('avatar', avatar);
        user.save().then(function () {
          self.set('currentModel.showConfirm', true);
        });
      },

      hideConfirm: function hideConfirm() {
        this.transitionTo('user-profile');
      },

      changeColor: function changeColor(color) {
        this.set('currentModel.oldColor', this.get('currentModel.currentColor'));
        this.set('currentModel.currentColor', color);
      },

      cancelAvatar: function cancelAvatar() {
        this.transitionTo('user-profile');
      }

    }

  });
});
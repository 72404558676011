define('ohs/routes/review-1', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/resource-actions-mixin'], function (exports, _authenticatedRouteMixin, _reset, _noNavigation, _teacherAuthorizeMixin, _resourceActionsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _noNavigation.default, _teacherAuthorizeMixin.default, _resourceActionsMixin.default, {
    titleToken: function titleToken(model) {
      return "Review " + model.quiz.get('quizName');
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      return Ember.RSVP.hash({
        quiz: this.store.find('quiz', params.quiz_id),
        curUser: this.store.findRecord('teacher', userId),
        orderedQuestions: Ember.computed('quiz.testQuestions.@each.id', 'quiz.testQuestions.@each.extraOrder', function () {
          return this.quiz.get('testQuestions').sortBy('questionOrder');
        }),
        approvedResources: this.store.findAll('test-approved-resource'),

        numApprovedResources: Ember.computed('approvedResources.@each.id', function () {
          return this.approvedResources.filterBy('quiz.id', this.quiz.get('id')).get('length');
        }),

        approvedBelowResources: Ember.computed('approvedResources.@each.id', function () {
          var self = this;
          return this.approvedResources.filter(function (item) {
            return item.get('interestLevel.id') == 1 && item.get('quiz.id') == self.quiz.get('id');
          });
        }),

        approvedAtResources: Ember.computed('approvedResources.@each.id', function () {
          var self = this;
          return this.approvedResources.filter(function (item) {
            return item.get('interestLevel.id') == 2 && item.get('quiz.id') == self.quiz.get('id');
          });
        }),

        approvedAboveResources: Ember.computed('approvedResources.@each.id', function () {
          var self = this;
          return this.approvedResources.filter(function (item) {
            return item.get('interestLevel.id') == 3 && item.get('quiz.id') == self.quiz.get('id');
          });
        })
      });
    },
    afterModel: function afterModel(model) {
      var list = model.quiz.get('questions').toArray();
      for (var i = 0; i < list.length; i++) {
        var question = list[i];
        model.quiz.get('questions').removeObject(question);
      }

      $(".question-edit-controls :button").attr("disabled", false);
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      },

      editName: function editName() {
        this.transitionTo('edit-quiz-name', this.get('currentModel.quiz.id'), 0);
      },

      startQuiz: function startQuiz(orderedQuestions) {
        var self = this;
        if (orderedQuestions.length == 0) {
          self.set('currentModel.showAlert', true);
          self.set("currentModel.alertMessage", "Please add at least one question in order to give the quiz.");
        } else {
          this.transitionTo('teacher.start-quiz.pick-type', this.get('currentModel.quiz.id'));
        }
      },

      doneForNow: function doneForNow() {
        this.transitionTo('teacher-quizzes');
      },

      reportResourceIssue: function reportResourceIssue(reportItem) {
        var self = this;
        var adapter = this.get('store').adapterFor('application');

        var url = adapter.get('host') + '/accounts/reportResource?resourceId=' + reportItem.resourceId + '&issue=' + reportItem.issue + '&comment=' + reportItem.comments;
        if (reportItem.comments == '') {
          url = adapter.get('host') + '/accounts/reportResource?resourceId=' + reportItem.resourceId + '&issue=' + reportItem.issue;
        }
        adapter.ajax(url, 'POST').then(function () {
          self.set('currentModel.showIssueConfirm', true);
          self.set('currentModel.issueConfirmationMessage', 'Your report has been received and is currently being reviewed. If we have any follow up questions, someone will be in touch!');
        }, function () {
          self.set('currentModel.showAlert', true);
          self.set("currentModel.alertMessage", "There was an error sending your report. Please check your internet connection and try again.");
        });
      },

      hideIssueConfirm: function hideIssueConfirm() {
        this.set('currentModel.showIssueConfirm', false);
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
      },

      deleteResource: function deleteResource(r) {
        r.destroyRecord();
      },

      showNavAlert: function showNavAlert(url) {
        this.set('currentModel.showNavConfirmModal', true);
        this.set('currentModel.navigateURL', url);
      },
      setShared: function setShared() {
        this.toggleProperty('currentModel.quiz.shared');
        this.get('currentModel.quiz').save();
      }

    }
  });
});
define('ohs/routes/teacher-leaderboard', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Teacher Leaderboard',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },
    model: function model(params) {
      var account = this.store.peekAll('account');
      var accountId = account.objectAt(0).id;
      return Ember.RSVP.hash({
        accountId: accountId,
        teacherLeaderboardDetails: this.store.query('teacher-leaderboard', { accountId: accountId }),
        leaderboardOptions: [{
          text: "Name",
          id: 1
        }, {
          text: "Number of logins",
          id: 2
        }, {
          text: "Number of quizzes created",
          id: 3
        }, {
          text: "Number of quizzes given",
          id: 4
        }],
        curSort: 1,
        fromDate: "",
        toDate: "",
        errorMessage: "",
        dateType: "All",
        dateOrDateRange: "Date",
        curLeaderboards: Ember.computed('teacherLeaderboardDetails.@each.id', 'curSort', function () {
          var results;
          if (this.curSort == 1) {
            results = this.teacherLeaderboardDetails.sortBy('lastName');
          } else if (this.curSort == 2) {
            results = this.teacherLeaderboardDetails.sortBy('numberOfLogins').reverse();
          } else if (this.curSort == 3) {
            results = this.teacherLeaderboardDetails.sortBy('numberOfQuizzesCreated').reverse();
          } else if (this.curSort == 4) {
            results = this.teacherLeaderboardDetails.sortBy('numberOfQuizzesGiven').reverse();
          }
          return results;
        }),
        dateOptions: [{
          text: "All",
          id: 1
        }, {
          text: "Today",
          id: 2
        }, {
          text: "Yesterday",
          id: 3
        }, {
          text: "This week",
          id: 4
        }, {
          text: "Last week",
          id: 5
        }, {
          text: "Last 2 weeks",
          id: 6
        }, {
          text: "This Month",
          id: 7
        }, {
          text: "Last Month",
          id: 8
        }, {
          text: "This year",
          id: 9
        }, {
          text: "Last year",
          id: 10
        }]
      });
    },


    actions: {
      sortBy: function sortBy(selectedValue) {
        this.set('currentModel.curSort', selectedValue);
      },

      filterByDate: function filterByDate(value) {
        this.set('currentModel.dateType', this.get('currentModel.dateOptions')[value - 1].text);
      },
      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
        this.set('currentModel.alertMessage', '');
      },
      searchDetails: function searchDetails() {
        var error = this.validateAndSetValues(this.get('currentModel.fromDate'), this.get('currentModel.toDate'));
        if (!error) {
          var self = this;
          if (self.get('currentModel.fromDate') && self.get('currentModel.toDate')) {
            var filterUrl = '&fromDate=' + encodeURIComponent(self.get('currentModel.fromDate')) + '&toDate=' + encodeURIComponent(self.get('currentModel.toDate'));
          } else {
            var filterUrl = '&filterType=' + encodeURIComponent(self.get('currentModel.dateType'));
          }

          return Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/teacherLeaderboards?accountId=' + self.get('currentModel.accountId') + filterUrl).done(function (response) {
            self.set('currentModel.teacherLeaderboardDetails', response);
          }).fail(function (response) {
            self.set('currentModel.alertMessage', 'The values you entered could not be validated. Please check your internet connection and try again.');
            self.set('currentModel.showAlert', true);
          });
        }
      },

      setDateOrDateRange: function setDateOrDateRange(value) {
        this.set('currentModel.dateOrDateRange', value);
        this.set('currentModel.errorMessage', "");
        this.set('currentModel.fromDate', "");
        this.set('currentModel.toDate', "");
      }
    },

    convertDateFormat: function convertDateFormat(date) {
      var date = new Date(date);
      date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      return date;
    },
    validateAndSetValues: function validateAndSetValues(fromdate, todate) {
      if (this.get('currentModel.dateOrDateRange') == "Daterange") {
        if (fromdate && todate) {
          var fromdate = this.convertDateFormat(fromdate);
          var todate = this.convertDateFormat(todate);

          if (Date.parse(todate) <= Date.parse(fromdate)) {
            this.set('currentModel.errorMessage', "To date must be greater than from date.");
            return true;
          } else {
            this.set('currentModel.fromDate', fromdate);
            this.set('currentModel.toDate', todate);
            this.set('currentModel.errorMessage', "");
            return false;
          }
        } else {
          this.set('currentModel.errorMessage', "Please select from date and to date.");
          return true;
        }
      } else {
        return false;
      }
    }
  });
});
define('ohs/models/user-creation-error', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    creatorUserId: _emberData.default.attr('string'),
    userRole: _emberData.default.attr('string'),
    userName: _emberData.default.attr('string'),
    honorific: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    gradeLevelId: _emberData.default.attr('number'),
    studentId: _emberData.default.attr('string'),
    PasswordEncrypt: _emberData.default.attr('string'),
    errorDate: _emberData.default.attr('date'),
    errorMessage: _emberData.default.attr('string'),

    fullName: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    })

  });
});
define('ohs/controllers/teacher/mission-control/mission/results', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    //// properties
    textLimit: 1000,
    commentLength: 0,
    questionIssue: '',
    questionIssueComments: '',

    //// computeds
    mission: Ember.computed.alias('model.mission'),
    activity: Ember.computed.alias('model.activity'),
    assessmentItems: Ember.computed.alias('model.assessmentItems'),
    student: Ember.computed.alias('model.studentMission.student'),
    questions: Ember.computed.mapBy('assessmentItems', 'question'),

    // feel free to move this into the student-mission model OR even the json response for the student-mission
    numberCorrect: Ember.computed('model.studentMissionAnswers', 'questions.[]', function () {
      var outOf = Ember.get(this, 'questions.length');
      var correct = Ember.get(this, 'model.studentMissionAnswers').filterBy('correct', true).get('length');

      return correct + ' of ' + outOf;
    }),

    // copy and pasted from -> components/editable-question.js
    validIssue: Ember.computed('questionIssue', 'commentLength', 'textLimit', function () {
      if (this.questionIssue) {
        if (this.questionIssue != "Other") {
          if (this.questionIssueComments && this.textLimit) {
            return this.questionIssueComments.length <= this.textLimit;
          }
          return true;
        } else {
          if (this.questionIssueComments && this.textLimit) {
            if (this.questionIssueComments.length > 0 && this.questionIssueComments.length <= this.textLimit) {
              return true;
            } else {
              return false;
            }
          }
          return false;
        }
      }
      return false;
    }),

    // copy and pasted from -> components/editable-question.js
    tellUsMore: Ember.computed('questionIssue', function () {
      return this.questionIssue == "Other" ? "Tell us more." : "Tell us more (optional).";
    }),

    actions: {
      openReportAnIssueModal: function openReportAnIssueModal(question) {
        Ember.set(this, 'showReportIssueModal', true);
        Ember.set(this, 'reportIssueQuestion', question);
      },


      // copy and pasted from -> components/editable-question.js
      validateIssue: function validateIssue() {
        if (!this.get('questionIssue')) {
          this.set('issueMessage', 'Please tell us what is wrong with this question.');
          return false;
        } else if (this.get('questionIssue') == "Other" && !this.get('questionIssueComments').trim()) {
          this.set('issueMessage', 'Please add some additional information about what is wrong with this question.');
          return false;
        } else if (this.get('questionIssueComments.length') > this.get('textLimit')) {
          this.set('issueMessage', 'Comment exceeds the character limit.');
          return false;
        } else {
          return true;
        }
      },

      // copy and pasted from -> components/editable-question.js
      setQuestionIssue: function setQuestionIssue(value) {
        this.set('questionIssue', value);
      },

      // copy and pasted from -> components/editable-question.js
      setQuestionIssueComment: function setQuestionIssueComment(value) {
        this.set('questionIssueComments', value);
      },

      // copy and pasted from -> components/editable-question.js
      reportIssue: function reportIssue() {
        var _this = this;

        this.set('issueMessage', null);
        var adapter = this.get('store').adapterFor('application');
        var host = adapter.get('host');
        var question = Ember.get(this, 'reportIssueQuestion');
        var issue = Ember.get(this, 'questionIssue');
        var comment = Ember.get(this, 'questionIssueComments');
        var url = host + '/accounts/reportQuestion?questionId=' + question.get('id') + '&issue=' + issue;

        if (comment.trim() !== '') {
          url += '&comment=' + comment;
        }

        adapter.ajax(url, 'POST').then(function () {
          _this.set('showConfirm', true);
          _this.set('confirmationMessage', 'Your report has been received and is currently being reviewed. If we have any follow up questions, someone will be in touch!');
        }, function () {
          this.set('showAlert', true);
          this.set("alertMessage", "There was an error sending your report. Please check your internet connection and try again.");
        });

        this.set('questionIssueComments', '');
        this.set('questionIssue', null);
      },

      // copy and pasted from -> components/editable-question.js
      cancel: function cancel() {
        this.set('issueMessage', '');
        this.set('questionIssueComments', '');
        this.set('questionIssue', null);
      },


      // copy and pasted from -> components/editable-question.js
      clear: function clear() {
        this.set('issueMessage', '');
        this.set('questionIssueComments', '');
        this.set('questionIssue', null);
      }
    }
  });
});
define('ohs/routes/teacher/start-quiz/pick-students', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() {
            if (!this.modelFor('teacher.start-quiz.pick-type')) {
                this.transitionTo('teacher.start-quiz.pick-type', this.modelFor('teacher.start-quiz').administration.get('quiz.id'));
            }
        },
        model: function model() {
            var store = this.get('store');
            return Ember.RSVP.hash({
                showAlert: false,
                alertMessage: '',
                administration: this.modelFor('teacher.start-quiz').administration,
                teacher: this.modelFor('teacher').user,
                selectedStudentGroups: [],
                selectedStudents: [],
                isAllSelected: false,
                distinctStudentTestAdministrations: Ember.computed('selectedStudentGroups.[]', 'selectedStudents.[]', 'isAllSelected', function () {
                    //check if all is selected
                    if (this.isAllSelected) {
                        return this.teacher.get('students').map(function (student) {
                            return store.createRecord('student-test-administration', {
                                student: student,
                                invitedThrough: 'All Students',
                                userId: student.get('id')
                            });
                        });
                    }

                    var students = [];
                    var studentTestAdministrations = [];

                    /* Before modifying
                       The reason there are two separate arrays is because we cannot compare types without Id's AND even if the types had Id's they would be different
                       if the student is in multiple groups.
                            1. The students array holds distinct students and we can compare against it since every student has an Id.
                            2. The studentTestAdministrations array holds a 'student-test-administration' that is made from a student object.
                        NOTE: If a student is in two groups we just use the first one we come across.
                    */

                    //distinct the students from the groups
                    this.selectedStudentGroups.forEach(function (group) {
                        group.get('students').forEach(function (student) {
                            if (!students.includes(student)) {
                                students.pushObject(student);
                                studentTestAdministrations.pushObject(store.createRecord('student-test-administration', {
                                    student: student,
                                    invitedThrough: group.get('studentGroupName'),
                                    userId: student.get('id')
                                }));
                            }
                        });
                    });

                    //run through the selected students
                    this.selectedStudents.forEach(function (student) {
                        if (!students.includes(student)) {
                            students.pushObject(student);
                            studentTestAdministrations.pushObject(store.createRecord('student-test-administration', {
                                student: student,
                                userId: student.get('id')
                            }));
                        }
                    });

                    return studentTestAdministrations;
                })
            });
        },


        actions: {
            selectAll: function selectAll() {
                this.toggleProperty('currentModel.isAllSelected');
            },
            selectStudentGroup: function selectStudentGroup(id) {
                var selectedGroup = this.store.peekRecord('student-group', id);
                if (this.get('currentModel.selectedStudentGroups').includes(selectedGroup)) this.get('currentModel.selectedStudentGroups').removeObject(selectedGroup);else this.get('currentModel.selectedStudentGroups').pushObject(selectedGroup);
            },
            reviewTeams: function reviewTeams() {
                if (this.get('currentModel.distinctStudentTestAdministrations.length') > 1) {
                    this.set('currentModel.administration.studentTestAdministrations', this.get('currentModel.distinctStudentTestAdministrations'));
                    this.transitionTo('teacher.start-quiz.pick-teams', this.get('currentModel.administration.quiz.id'));
                } else {
                    Ember.set(this, 'currentModel.alertMessage', 'You have to select at least 2 students!');
                    this.toggleProperty('currentModel.showAlert');
                }
            }
        }
    });
});
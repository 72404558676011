define('ohs/components/notification-navigation', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    //// properties
    classNames: ['notification-navigation-wrapper'],
    classNameBindings: ['isExpanded'],
    isExpanded: false,
    role: 'dialog',

    //// hooks
    didInsertElement: function didInsertElement() {
      this.get('parent').registerChild(this);
    },

    //// computeds
    newNotificationObserver: Ember.observer('model.studentTrophies.[]', 'notifications.[]', function () {
      this.set('model.notificationsViewed', false);
    }),

    totalNumNotifications: Ember.computed('model.newRecommendedCount', 'model.quizInProgress.@each.id', 'model.newResources.@each.id', 'model.openAdministrations.@each.id', 'model.newTrophies.@each.id', 'missionCompletedCount', 'notifications.[]', function () {
      var numNotifications = 0;
      if (this.get('model.newRecommendedCount') && this.get('model.newRecommendedCount') > 0) {
        numNotifications += this.get('model.newRecommendedCount');
      }
      if (this.get('model.quizInProgress.length') > 0) {
        // debugger;
        numNotifications++;
      }
      if (this.get('model.openAdministrations.length') > 0) {
        numNotifications++;
      }
      if (this.get('model.newTrophies')) {
        numNotifications += this.get('model.newTrophies.length');
      }
      if (this.get('model.newResources.length') > 0) {
        numNotifications++;
      }
      if (this.get('model.classResourceStudentNotifies')) {
        numNotifications += this.get('model.classResourceStudentNotifies.length');
      }
      if (this.get('model.classLinkedResourceStudentNotifies')) {
        numNotifications += this.get('model.classLinkedResourceStudentNotifies.length');
      }

      if (Ember.get(this, 'missionCompletedCount')) {
        numNotifications += Ember.get(this, 'missionCompletedCount');
      }

      if (Ember.get(this, 'notifications')) {
        numNotifications += Ember.get(this, 'notifications').rejectBy('notificationType', 'MissionCompleted').get('length');
      }

      return numNotifications;
    }),

    missionCompletedNotifications: Ember.computed('notifications.@each.{id,notificationType}', function () {
      var notifications = Ember.get(this, 'notifications');
      if (!notifications) {
        return [];
      }

      return notifications.filterBy('notificationType', 'MissionCompleted');
    }),

    missionCompletedCount: Ember.computed('missionCompletedNotifications.@each.id', function () {
      var missionCompleted = Ember.get(this, 'missionCompletedNotifications');

      return _lodash.default.uniqBy(missionCompleted.toArray(), function (n) {
        return Ember.get(n, 'actor.id');
      }).length;
    }),

    //// actions
    actions: {
      show: function show() {
        if (this.get('isExpanded')) {
          this.set('model.notificationsViewed', true);
        }
        this.toggleProperty('isExpanded');
        this.attrs.hideOthers(this);
      },

      hide: function hide() {
        if (this.get('isExpanded')) {
          this.set('isExpanded', false);
          this.set('model.notificationsViewed', true);
        }
      },

      hideTouch: function hideTouch() {
        var self = this;
        setTimeout(function () {
          if (self.get('isExpanded')) {
            self.set('isExpanded', false);
            self.set('model.notificationsViewed', true);
          }
        }, 350);
      },

      drillTrophy: function drillTrophy(st) {
        st.set('studentViewed', true);
        st.save();
        this.sendAction('transitionAction', 'student-trophies');
        //this.transitionTo('student-trophies');
      },

      stayOpen: function stayOpen() {},

      dimissCompletedMissions: function dimissCompletedMissions() {
        var missionCompleted = Ember.get(this, 'missionCompletedNotifications');

        missionCompleted.invoke('destroyRecord');
      },
      dismiss: function dismiss(notification) {
        notification.destroyRecord();
      }
    },

    //// methods
    isMobile: function isMobile() {
      return navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i) || navigator.userAgent.match(/Opera Mini/i) || navigator.userAgent.match(/IEMobile/i);
    },

    'aria-hidden': function () {
      return !this.get('isExpanded');
    }.property('isExpanded'),

    hide: function hide() {
      if (this.get('isExpanded')) {
        this.set('isExpanded', false);
        this.set('model.notificationsViewed', true);
      }
    }

  });
});
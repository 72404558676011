define("ohs/helpers/is-equal", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEqual = isEqual;
  function isEqual(params /*, hash*/) {
    if (params[0] && params[1]) {
      var val1 = params[0].toString();
      var val2 = params[1].toString();
      return val1 == val2;
    } else {
      return false;
    }
  }

  exports.default = Ember.Helper.helper(isEqual);
});
define('ohs/routes/parent/resource-search-start', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/parent-authorize-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _parentAuthorizeMixin, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _parentAuthorizeMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Search Resources',
    signalR: Ember.inject.service('signal-r'),

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('parent_many_children', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      this.store.unloadAll('standard');
    },

    model: function model() {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      var currentStudentId = sesObj.get('currentStudent');
      var self = this;

      if (currentStudentId) {
        return this.store.findRecord('student', currentStudentId).then(function (student) {
          var gradeLevel = 3;
          if (student.get('gradeLevelId')) {
            gradeLevel = student.get('gradeLevelId');
          }
          return Ember.RSVP.hash({
            grades: self.store.query('grade', { 'gradeType': 'SearchResource' }),
            // subjects: self.store.findAll('subject'),
            account: self.store.queryRecord('account', { userId: userId }),
            standards: self.store.findAll('standard'),
            selectedGrade: gradeLevel,
            selectedSubject: 1,
            keywordSearch: '',

            standardType: sesObj.get('standardType'),

            selectedReportingCategory: "",
            selectedAssessmentAnchor: "",
            selectedEligibleContent: [],
            eligibleContentQueryString: "",
            fillteredSubjects: Ember.computed('account.subjects', function () {
              var sub = this.account.get('subjects');
              return sub;
            }),
            reportingCategories: Ember.computed('selectedGrade', 'selectedSubject', 'standards.@each.id', function () {
              var stan = this.standards;
              var gradeLevelId = this.selectedGrade;
              var subjectId = this.selectedSubject;
              stan = stan.filterBy('standardLevelId', 1).filterBy('gradeLevelId', gradeLevelId).filterBy('subjectId', subjectId).sortBy('standardOrderId');
              return stan;
            }),
            assessmentAnchors: Ember.computed('selectedReportingCategory', 'standards.@each.id', function () {
              var stan = this.standards;
              var rc = this.selectedReportingCategory;
              stan = stan.filterBy('parentStandardId', rc).sortBy('standardOrderId');
              return stan;
            }),
            eligibleContent: Ember.computed('selectedAssessmentAnchor', 'standards.@each.id', function () {
              var stan = this.standards;
              var anchor = this.selectedAssessmentAnchor;
              stan = stan.filterBy('parentStandardId', anchor).sortBy('standardOrderId');
              return stan;
            })

          });
        });
      } else {
        return Ember.RSVP.hash({
          grades: self.store.query('grade', { 'gradeType': 'SearchResource' }),
          subjects: self.store.findAll('subject'),
          standards: self.store.findAll('standard'),

          selectedGrade: 3,
          selectedSubject: 1,
          keywordSearch: '',

          standardType: sesObj.get('standardType'),

          selectedReportingCategory: "",
          selectedAssessmentAnchor: "",
          selectedEligibleContent: [],
          eligibleContentQueryString: "",
          reportingCategories: Ember.computed('selectedGrade', 'selectedSubject', 'standards.@each.id', function () {
            var stan = this.standards;
            var gradeLevelId = this.selectedGrade;
            var subjectId = this.selectedSubject;
            stan = stan.filterBy('standardLevelId', 1).filterBy('gradeLevelId', gradeLevelId).filterBy('subjectId', subjectId).sortBy('standardOrderId');
            return stan;
          }),
          assessmentAnchors: Ember.computed('selectedReportingCategory', 'standards.@each.id', function () {
            var stan = this.standards;
            var rc = this.selectedReportingCategory;
            stan = stan.filterBy('parentStandardId', rc).sortBy('standardOrderId');
            return stan;
          }),
          eligibleContent: Ember.computed('selectedAssessmentAnchor', 'standards.@each.id', function () {
            var stan = this.standards;
            var anchor = this.selectedAssessmentAnchor;
            stan = stan.filterBy('parentStandardId', anchor).sortBy('standardOrderId');
            return stan;
          })

        });
      }
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Search Resources', false, true);
      //Signal-R event subscription
      this.get('signalR').on('linkParentStudent', this, 'linkParentStudent');
    },

    actions: {
      setGrade: function setGrade(value) {
        this.set('currentModel.selectedGrade', parseInt(value));
      },

      setSubject: function setSubject(value) {
        this.set('currentModel.selectedSubject', parseInt(value));
      },

      search: function search() {
        document.activeElement.blur();
        this.transitionTo('parent.search-results');
      }

    },

    linkParentStudent: function linkParentStudent(userId) {
      var sesObj = this.get('session');
      if (!sesObj.get('currentStudent')) {
        sesObj.set('currentStudent', userId);
      }
      this.refresh();
    }
  });
});
define('ohs/components/quiz-item', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'article',
    classNames: ['quiz-item'],
    //TODO: Use the public routing service instead of the private one.
    router: Ember.inject.service('-routing'),
    distinctGroups: Ember.computed('administration.studentTestAdministrations.@each.invitedThrough', function () {
      if (this.get('administration')) {
        var stas = this.get('administration.studentTestAdministrations').map(function (v) {
          return get(v, 'invitedThrough');
        }).filter(function (v) {
          return v && v.toLowerCase() !== 'invited manually' && v.toLowerCase() !== 'joined using access code';
        });

        return stas.reduce(function (acc, current) {
          return acc.includes(current) ? acc : acc.concat(current);
        }, []);
      }
    }),

    groupDisplay: Ember.computed('distinctGroups.[]', function () {
      if (this.get('distinctGroups') && this.get('distinctGroups.length') > 0) {
        if (this.get('distinctGroups.length') == 1) {
          return this.get('distinctGroups.firstObject');
        } else {
          return this.get('distinctGroups.length') + ' Groups';
        }
      }
    }),

    actions: {
      linkAction: function linkAction() {
        this.sendAction('linkAction', this.get('drillId'));
      },
      showStudents: function showStudents() {
        this.sendAction('showStudentsAction', this.get('administration'));
      },
      showGroups: function showGroups() {
        this.sendAction('showGroupsAction', this.get('distinctGroups'));
      },
      drillStudentResults: function drillStudentResults(id) {
        this.sendAction('drillAction', id);
      },
      deleteSession: function deleteSession() {
        this.sendAction('deleteAction', this.get('administration.id'));
        this.set('checkDelete', false);
      },
      checkDeleteSession: function checkDeleteSession() {
        this.set('checkDelete', true);
      },

      enterQuizRoom: function enterQuizRoom() {
        /*
          the ['administration.id'] syntax is a little quirk of using the injected '-routing' service
        */
        //game mode
        if (get(this, 'administration.testTypeId') == 3) {
          get(this, 'router').transitionTo('teacher.quiz.game-mode.lobby', [get(this, 'administration.id')]);
        }
        //teacher-led ELA
        else if (get(this, 'administration.testTypeId') == 2 && get(this, 'administration.quiz.subject.id') == 2) {
            get(this, 'router').transitionTo('teacher.quiz.teacher-led.lobby', [get(this, 'administration.id')]);
          }
          //everything else (for now)
          else {
              get(this, 'router').transitionTo('teacher-quiz-room', [get(this, 'administration.id')]);
            }
      },
      enterQuizRoomStudent: function enterQuizRoomStudent() {
        //teacher-led ELA
        if (get(this, 'sta.administration.testTypeId') == 2 && get(this, 'sta.administration.quiz.subject.id') == 2) {
          get(this, 'router').transitionTo('student.quiz.teacher-led.lobby', [get(this, 'sta.id')]);
        }
        //everything else (for now)
        else {
            get(this, 'router').transitionTo('student-quiz-room', [get(this, 'sta.id')]);
          }
      }
    }
  });
});
define('ohs/components/page-title-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['page-title-icon-wrapper'],
    actions: {
      print: function print() {
        this.sendAction('printAction');
      },

      showTip: function showTip() {
        this.sendAction('showTip');
      }
    }
  });
});
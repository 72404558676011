define('ohs/models/account', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    //email : DS.attr('string')
    accountTypeId: _emberData.default.attr('number'),
    accountName: _emberData.default.attr('string'),
    numStudentLicenses: _emberData.default.attr('number'),
    stateId: _emberData.default.attr('number'),
    standardTypeId: _emberData.default.attr('number'),
    isAdminAccount: _emberData.default.attr('boolean'),
    isFreeTrial: _emberData.default.attr('boolean'),
    subjects: _emberData.default.hasMany('subject'),
    missionControlEnabled: _emberData.default.attr('boolean')
  });
});
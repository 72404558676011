define('ohs/models/mission-control/galaxy', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr('string'),
        displayId: attr('number'),
        order: attr('number'),

        planets: hasMany('mission-control/planet', { async: true })
    });
});
define('ohs/models/mission-control/question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    text: attr('string'),

    difficulty: belongsTo('mission-control/difficulty'),
    dokLevel: belongsTo('mission-control/dok-level'),

    answerOptions: hasMany('mission-control/answer-option'),
    standards: hasMany('mission-control/standard'),

    answerOptionsSorting: Object.freeze(['order']),
    sortedAnswerOptions: Ember.computed.sort('answerOptions', 'answerOptionsSorting'),

    teaserText: Ember.computed('text', function () {
      var text = Ember.get(this, 'text');

      //count fraction images as a single character and other images as the end of the teaser text
      var charactersToAdd = 0;
      var endIndex = 250;
      var imgSpliterList = text.split('<img');
      if (imgSpliterList.length > 1) {
        for (var i = 1; i < imgSpliterList.length; i++) {
          var img = imgSpliterList[i];
          img = img.substring(0, img.indexOf('>') + 1);
          img = '<img' + img;
          if (text.indexOf(img) > endIndex + charactersToAdd) {
            endIndex = text.substring(0, text.indexOf(img)).lastIndexOf(' ') - charactersToAdd;
          } else {
            if (img.indexOf('Wirisformula') >= 0) {
              charactersToAdd += img.length;
            } else {
              endIndex = text.indexOf(img) - charactersToAdd;
            }
          }
        }
      }

      if (text.length > endIndex + charactersToAdd) {
        return text.substring(0, endIndex + charactersToAdd) + " ...";
      } else {
        return text;
      }
    })
  });
});
define('ohs/components/choose-answer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    currentAnswer: Ember.computed('studentAnswers.@each.answerId', 'testQuestion.id', function () {
      var curAnswer = this.get('studentAnswers').findBy('questionId', parseInt(this.get('testQuestion').get('question').get('id')));
      return curAnswer;
    }),

    orderedAnswers: Ember.computed('testQuestion.question.answerOptions.@each.id', function () {
      return (this.get('testQuestion.question.answerOptions') || []).sortBy('id');
    }),

    actions: {
      selectAnswer: function selectAnswer(selectedAnswerChoice) {
        if (this.get('currentAnswer')) {
          this.sendAction('setPropertyAction', parseInt(selectedAnswerChoice), parseInt(this.get('currentAnswer').get('id')));
        } else {
          this.sendAction('setPropertyAction', parseInt(selectedAnswerChoice));
        }
      }
    }
  });
});
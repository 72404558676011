define('ohs/components/mission-control/galaxy-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['galaxy-group', 'teacher'],

    filteredPlanets: Ember.computed('galaxy.planets.@each.id', 'selectedGrade', 'missionFilter', function () {
      var _this = this;

      return this.get('galaxy.planets').filter(function (planet) {
        var gradeFilter = planet.get('gradeLevel.id') == _this.get('selectedGrade.id');
        var missionFilter = true;

        if (_this.get('missionFilter') == 2) {
          missionFilter = false;
          planet.get('missions').forEach(function (mission) {
            var studentMission = this.get('studentMissions').findBy('mission.id', mission.get('id'));
            if (studentMission) {
              if (studentMission.get('numAnswered') == 0) {
                missionFilter = true;
              }
            } else {
              missionFilter = true;
            }
          }, _this);
        } else if (_this.get('missionFilter') == 3) {
          missionFilter = false;
          planet.get('missions').forEach(function (mission) {
            var studentMission = this.get('studentMissions').findBy('mission.id', mission.get('id'));
            if (studentMission) {
              if (studentMission.get('numAnswered') > 0) {
                missionFilter = true;
              }
            }
          }, _this);
        }

        return gradeFilter && missionFilter;
      });
    }),

    actions: {
      planetInfo: function planetInfo(planet) {
        this.set('showPlanetModal', true);
        this.set('modalPlanet', planet);
      },
      closeModal: function closeModal() {
        this.set('showPlanetModal', false);
      },
      viewResults: function viewResults(studentMissionId) {
        this.sendAction('viewResultsAction', studentMissionId);
      },
      startQuiz: function startQuiz(missionId) {
        this.sendAction('startQuizAction', missionId);
      },
      continueQuiz: function continueQuiz(studentMissionId) {
        this.sendAction('continueQuizAction', studentMissionId);
      }
    }
  });
});
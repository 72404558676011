define('ohs/controllers/teacher/mission-control/leaderboard', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        sortBy: 'rank',
        sortByDirection: 'asc',

        sortedLeaderboards: Ember.computed('sortBy', 'sortByDirection', 'model.leaderboards', function () {
            var leaderboard = Ember.get(this, 'model.leaderboards').toArray();
            var sortBy = Ember.get(this, 'sortBy');
            var sortByDirection = Ember.get(this, 'sortByDirection');

            return leaderboard.sort(function (a, b) {
                var aProp = Ember.get(a, sortBy);
                var bProp = Ember.get(b, sortBy);
                var result = 0;

                if (aProp < bProp) {
                    result = -1;
                } else if (aProp > bProp) {
                    result = 1;
                }

                return sortByDirection === 'desc' ? -result : result;
            });

            //return leaderboard.sortBy(`${sortBy}:${sortByDirection}`);
        }),

        actions: {
            exploreMissions: function exploreMissions() {
                this.transitionToRoute('teacher.mission-control.mission.search');
            },
            sort: function sort(sortBy) {
                var currentSortBy = Ember.get(this, 'sortBy');
                var sortByDirection = Ember.get(this, 'sortByDirection');

                // if the sortBy changed - go to 'asc'
                if (sortBy !== currentSortBy) {
                    Ember.set(this, 'sortBy', sortBy);
                    Ember.set(this, 'sortByDirection', 'asc');
                }
                // if it stayed the same - go to the opposite sortBy
                else if (sortByDirection === 'desc') {
                        Ember.set(this, 'sortByDirection', 'asc');
                    } else {
                        Ember.set(this, 'sortByDirection', 'desc');
                    }
            }
        }
    });
});
define('ohs/controllers/student-profile', ['exports', 'moment', 'lodash'], function (exports, _moment, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Controller.extend({
        ////properties
        selectedSchoolYearId: null,

        ////injections
        session: Ember.inject.service('session'),

        ////single line computeds
        recentSortDef: Object.freeze(['dateCompletedSort:desc']),
        sortedAdministrations: Ember.computed.sort('selectedSchoolYearAdministrations', 'recentSortDef'),

        spaceshipsSorting: Object.freeze(['isActive:desc']),
        sortedSpaceships: Ember.computed.sort('model.spaceships', 'spaceshipsSorting'),

        sortedStudentMissionsAndQuizzes: Ember.computed('selectedSchoolYearStudentMissions.@each.id', 'selectedSchoolYearAdministrations.@each.id', function () {
            var _this = this;

            var studentMissions = Ember.get(this, 'selectedSchoolYearStudentMissions');
            var administrations = Ember.get(this, 'selectedSchoolYearAdministrations');
            // flattens the studentMissions and administrations together
            var both = [].concat(_toConsumableArray(studentMissions), _toConsumableArray(administrations));

            return both.sort(function (modelA, modelB) {
                // there are two model type in here - figure out which one we have.
                var aSortProp = _this.getSortProp(modelA);
                var bSortProp = _this.getSortProp(modelB);
                var aSortValue = Ember.get(modelA, aSortProp);
                var bSortValue = Ember.get(modelB, bSortProp);

                if (aSortValue < bSortValue) {
                    return 1;
                }

                if (aSortValue > bSortValue) {
                    return -1;
                }

                return 0;
            }).map(function (model) {
                return {
                    type: model.constructor.modelName,
                    model: model
                };
            });
        }),

        ////multilined computeds
        filteredStudentMissions: Ember.computed('model.studentMissions.[]', 'model.showOnlyMyQuizzes', function () {
            var studentMissions = Ember.get(this, 'model.studentMissions');
            var completedStudentMission = studentMissions.filterBy('completed');
            var onlyMine = Ember.get(this, 'model.showOnlyMyQuizzes');
            var userId = Ember.get(this, 'session.session.content.authenticated.userId');

            // show only my quizzes - filter on the sent by user
            if (onlyMine) {
                return _lodash.default.filter(completedStudentMission.toArray(), function (sm) {
                    var userSentMissions = Ember.get(sm, 'userSentMissions');
                    return _lodash.default.some(userSentMissions.toArray(), function (usm) {
                        return Ember.get(usm, 'sentBy.id') === userId;
                    });
                });
            }

            return completedStudentMission;
        }),

        filteredAdministrations: Ember.computed('model.completedQuizzes', 'model.showOnlyMyQuizzes', function () {
            var administrations = Ember.get(this, 'model.completedQuizzes');
            var userId = Ember.get(this, 'session.session.content.authenticated.userId');
            return Ember.get(this, 'model.showOnlyMyQuizzes') ? administrations.filterBy('administration.quiz.createUserId', userId) : administrations;
        }),

        selectedSchoolYearAdministrations: Ember.computed('selectedSchoolYearId', 'filteredAdministrations.[]', function () {
            var selectedSchoolYear = Ember.get(this, 'model.schoolYears').findBy('id', Ember.get(this, 'selectedSchoolYearId'));
            var start = (0, _moment.default)(Ember.get(selectedSchoolYear, 'start'));
            var end = (0, _moment.default)(Ember.get(selectedSchoolYear, 'end'));

            return Ember.get(this, 'filteredAdministrations').reduce(function (administrations, administration) {
                var date = (0, _moment.default)(Ember.get(administration, 'dateCompletedSort'));
                return date.isBefore(end) && date.isAfter(start) ? administrations.concat(administration) : administrations;
            }, []);
        }),

        selectedSchoolYearStudentMissions: Ember.computed('selectedSchoolYearId', 'filteredAdministrations.[]', function () {
            var selectedSchoolYear = Ember.get(this, 'model.schoolYears').findBy('id', Ember.get(this, 'selectedSchoolYearId'));
            var start = (0, _moment.default)(Ember.get(selectedSchoolYear, 'start'));
            var end = (0, _moment.default)(Ember.get(selectedSchoolYear, 'end'));
            var studentMissions = Ember.get(this, 'filteredStudentMissions');

            return _lodash.default.filter(studentMissions.toArray(), function (sm) {
                var date = (0, _moment.default)(Ember.get(sm, 'created'));
                return date.isBefore(end) && date.isAfter(start);
            });
        }),

        schoolYearOptions: Ember.computed('filteredAdministrations.[]', 'model.schoolYears.[]', function () {
            var administrations = Ember.get(this, 'filteredAdministrations');
            var missions = Ember.get(this, 'filteredStudentMissions');

            return Ember.get(this, 'model.schoolYears').reduce(function (schoolYears, schoolYear) {
                var start = (0, _moment.default)(Ember.get(schoolYear, 'start'));
                var end = (0, _moment.default)(Ember.get(schoolYear, 'end'));

                var adminsExist = administrations.toArray().some(function (a) {
                    var date = (0, _moment.default)(Ember.get(a, 'dateCompletedSort'));
                    return date.isBefore(end) && date.isAfter(start);
                });

                var missionExist = missions.toArray().some(function (a) {
                    var date = (0, _moment.default)(Ember.get(a, 'completed'));
                    return date.isBefore(end) && date.isAfter(start);
                });

                return adminsExist || missionExist ? schoolYears.concat(schoolYear) : schoolYears;
            }, []);
        }),

        //// methods
        getSortProp: function getSortProp(model) {
            if (model.constructor.modelName === 'student-mission') {
                return 'created';
            } else if (model.constructor.modelName === 'student-test-administration') {
                return 'dateCompletedSort';
            }
        }
    });
});
define('ohs/routes/account-admin/teacher/quiz-detail', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/resource-actions-mixin'], function (exports, _authenticatedRouteMixin, _resourceActionsMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_resourceActionsMixin.default, _authenticatedRouteMixin.default, {
        model: function model(_ref) {
            var quiz_id = _ref.quiz_id;

            var teacher = this.modelFor('account-admin.teacher');
            var teacherId = Ember.get(teacher, 'id');
            var store = Ember.get(this, 'store');
            var userId = Ember.get(this, 'session.session.content.authenticated.userId');

            return Ember.RSVP.hash({
                user: store.peekRecord('teacher', userId),
                teacher: teacher,
                quiz: store.findRecord('quiz', quiz_id),
                administrations: store.query('administration', { testId: quiz_id, userId: teacherId }),
                testQuestions: store.query('test-question', { testId: quiz_id }),
                studentTestAdministraitons: store.query('student-test-administration', { testId: quiz_id }),
                approvedResources: store.query('test-approved-resource', { testId: quiz_id, userId: teacherId })
            });
        },
        setupController: function setupController() {
            this._super.apply(this, arguments);

            this.controllerFor('application').set('showSecondaryNav', true);
            this.controllerFor('application').set('teacher', true);
        },


        actions: {
            // copied and pasted from routes/teacher/quiz-detail
            reportIssue: function reportIssue(reportItem) {
                var self = this;
                var adapter = this.get('store').adapterFor('application');

                var url = adapter.get('host') + '/accounts/reportResource?resourceId=' + reportItem.resourceId + '&issue=' + reportItem.issue + '&comment=' + reportItem.comments;
                if (reportItem.comments == '') {
                    url = adapter.get('host') + '/accounts/reportResource?resourceId=' + reportItem.resourceId + '&issue=' + reportItem.issue;
                }
                adapter.ajax(url, 'POST').then(function () {
                    self.set('currentModel.showIssueConfirm', true);
                    self.set('currentModel.issueConfirmationMessage', 'Your report has been received and is currently being reviewed. If we have any follow up questions, someone will be in touch!');
                }, function () {
                    self.set('currentModel.showAlert', true);
                    self.set("currentModel.alertMessage", "There was an error sending your report. Please check your internet connection and try again.");
                });
                reportItem.comments = '';
                reportItem.issue = null;
            }
        }
    });
});
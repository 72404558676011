define('ohs/components/x-bookmark', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isBookmarked: false,
    classNames: ['bookmark'],
    classNameBindings: ['isBookmarked'],
    actions: {
      toggleBookmark: function toggleBookmark() {
        //this.toggleProperty('isBookmarked');
        if (this.bookmark) {
          this.sendAction('deleteAction');
        } else {
          this.sendAction('createAction');
        }
      }
    }
  });
});
define('ohs/routes/forgot-password', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Reset Password',
    model: function model(params) {

      return Ember.RSVP.hash({
        userName: '',
        email: '',
        userNameRequired: false,
        validationErrors: '',
        successMessages: '',
        isProcessing: false
      });
    },


    actions: {

      emailNext: function emailNext() {
        document.activeElement.blur();
        var self = this;
        self.set("currentModel.validationErrors", '');
        self.set("currentModel.successMessages", '');
        self.set("currentModel.isProcessing", true);

        // if (this.get('currentModel.userNameRequired') && !this.get('currentModel.userName')) {
        //   self.forgotPassword(self.currentModel.email, self.currentModel.email)
        // }
        //
        // else if(self.currentModel.email && self.currentModel.userName){
        //   self.forgotPassword(self.currentModel.email, self.currentModel.userName)
        // }
        if (self.currentModel.userName.trim()) {
          self.forgotPassword(self.currentModel.userName);
        } else {
          self.set("currentModel.validationErrors", 'Username cannot be blank.');
          self.set("currentModel.isProcessing", false);
        }
        //
        // else if(self.currentModel.email){
        // //check email address is associated to multiple accounts
        // Ember.$.getJSON(this.store.adapterFor('application')
        // .get('host')+'/GetAssociatedUsersByEmail?email='+ encodeURIComponent(self.currentModel.email))
        //                       .done(function(response) {
        //                         if(response.Id == 0) {
        //                         self.set('currentModel.userNameRequired',false);
        //                         self.set("currentModel.validationErrors", 'We could not find any accounts associated to this email address.');
        //                         self.set("currentModel.isProcessing", false);
        //                       }
        //                       else if(response.length>1){
        //                         self.set("currentModel.userNameRequired", true);
        //                         self.set('currentModel.validationErrors','The email address you entered is associated with multiple accounts. To reset the password for one of your student accounts, please enter the username for the account you want to reset. To reset the password for your teacher/parent account, leave the username box blank.');
        //                         self.set("currentModel.isProcessing", false);
        //                       }
        //                       else{
        //                         //perform Password reset email
        //                         self.forgotPassword(self.currentModel.email, self.currentModel.userName)
        //                       }
        //                       }).fail(function(response) {
        //                         self.set('currentModel.validationErrors','The email addresses you entered could not be validated. Please check your internet connection and try again.');
        //                         self.set("currentModel.isProcessing", false);
        //                       });
        //                     }
        //                     else {
        //                       self.set("currentModel.validationErrors", 'Please enter your email address');
        //                       self.set("currentModel.isProcessing", false);
        //                     }
      },

      emailBack: function emailBack() {
        this.transitionTo('login');
      },

      hideConfirm: function hideConfirm() {
        this.set('currentModel.showConfirm', false);
        this.transitionTo('login');
      }

    },

    //Methods
    forgotPassword: function forgotPassword(userName) {
      var self = this;
      // if (!userName)
      // {
      //   userName = null
      // }
      self.set("currentModel.processingMsg", 'sending');
      return Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/ForgotPassword?userName=' + encodeURIComponent(userName.trim())).done(function (response) {
        self.set("currentModel.validationErrors", '');
        //self.set("currentModel.successMessages", 'Your verification email has been sent to the registered email');
        self.set('currentModel.showConfirm', true);
        //self.transitionTo('success-route');
      }).fail(function (response) {
        if (response.statusText == "Not Found") {
          self.set("currentModel.validationErrors", response.responseText);
          self.set("currentModel.isProcessing", false);
        } else {
          //If there is no internet connection.
          self.set("currentModel.validationErrors", 'Please check your internet connection and try again.');
          self.set("currentModel.isProcessing", false);
        }
      });
    }
  });
});
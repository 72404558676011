define('ohs/routes/teacher/quiz-search-results', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Search Results',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    deactivate: function deactivate() {
      this.set('currentModel.selectedType', 1);
      this.set('currentModel.selectedLevel', 1);
      this.set('currentModel.currentSort', "1");
      $('li:contains("Quizzes")').find('li:contains("Search")').children().first().removeClass('active');
      $('li:contains("Quizzes")').find('li:contains("Global Quizzes")').children().first().removeClass('active');
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Quizzes")').find('li:contains("Search")').children().first().addClass('active');
        $('li:contains("Quizzes")').find('li:contains("Global Quizzes")').children().first().addClass('active');
      }, 100);
    },

    model: function model() {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      var self = this;

      var searchParams = this.modelFor('teacher.quiz-search-start');

      if (!searchParams) {
        this.transitionTo('teacher.quiz-search-start');
      }

      var r;
      var paramList = {
        gradeLevelId: searchParams.selectedGrade,
        subjectId: searchParams.selectedSubject,
        minQuestions: searchParams.minQuestionCountSearch || 0,
        maxQuestions: searchParams.maxQuestionCountSearch || 0,
        hasResourcesAttached: searchParams.selectedHasResourceAttached
      };

      if (searchParams.eligibleContentQueryString.length > 0) {
        paramList["eligibleContents"] = searchParams.eligibleContentQueryString;
      }

      if (searchParams.selectedAssessmentAnchor.toString().length > 0) {
        paramList["assessmentAnchorId"] = searchParams.selectedAssessmentAnchor;
      }

      if (searchParams.selectedReportingCategory.toString().length > 0) {
        paramList["reportingCategoryId"] = searchParams.selectedReportingCategory;
      }

      var self = this;
      return this.store.query('quiz', paramList).then(function (q) {
        return Ember.RSVP.hash({
          quizzes: q,
          curUser: self.store.findRecord('teacher', userId),
          gradeLevel: self.store.peekRecord('grade', parseInt(searchParams.selectedGrade)),
          subject: self.store.peekRecord('subject', parseInt(searchParams.selectedSubject)),

          minQuestions: searchParams.minQuestionCountSearch,
          maxQuestions: searchParams.maxQuestionCountSearch,
          hasResourcesAttached: searchParams.selectedHasResourceAttached,

          searchBox: searchParams.keywordSearch,
          finalSearch: searchParams.keywordSearch,

          standardCodeDisplay: Ember.computed('searchParams', function () {
            if (searchParams.eligibleContentQueryString.length > 0) {
              var returnString = "";
              var arr = searchParams.eligibleContentQueryString.split(',');
              for (var i = 0; i < arr.length; i++) {
                var stan = self.store.peekRecord('standard', parseInt(arr[i]));
                returnString += stan.get('standardCode') + ", ";
              }
              if (returnString.length > 0) {
                returnString = returnString.substr(0, returnString.length - 2);
              }
              return returnString;
            } else if (searchParams.selectedAssessmentAnchor.toString().length > 0) {
              var stan = self.store.peekRecord('standard', parseInt(searchParams.selectedAssessmentAnchor));
              return stan.get('standardCode');
            } else if (searchParams.selectedReportingCategory.toString().length > 0) {
              var stan = self.store.peekRecord('standard', parseInt(searchParams.selectedReportingCategory));
              return stan.get('standardCode');
            } else {
              return '';
            }
          }),

          perPage: 20,
          numShown: 20,
          hasMoreItems: Ember.computed('quizzes.@each.id', 'finalSearch', 'numShown', 'quizzes.@each.isAlreadySharedQuiz', function () {
            var r = this.quizzes.filter(function (quiz, index, array) {
              // trim extra space from the quiz name
              quiz.set('quizName', quiz.get('quizName').trim());
              var keywordSearch = true;
              if (this.searchBox.length > 0) {
                var splitKeywords = this.searchBox.toLowerCase().split(" ");
                splitKeywords.forEach(function (k) {
                  if (keywordSearch) {
                    if (quiz.get('accountName')) {
                      keywordSearch = quiz.get('quizName').toLowerCase().indexOf(k) >= 0 || quiz.get('user').get('lastName').toLowerCase().indexOf(k) >= 0 || quiz.get('user').get('firstName').toLowerCase().indexOf(k) >= 0 || quiz.get('user').get('userName').toLowerCase().indexOf(k) >= 0 || quiz.get('accountName').toLowerCase().indexOf(k) >= 0;
                    } else {
                      keywordSearch = quiz.get('quizName').toLowerCase().indexOf(k) >= 0 || quiz.get('user').get('lastName').toLowerCase().indexOf(k) >= 0 || quiz.get('user').get('firstName').toLowerCase().indexOf(k) >= 0 || quiz.get('user').get('userName').toLowerCase().indexOf(k) >= 0;
                    }
                  }
                });
              }

              return keywordSearch;
            }, this);
            if (r.get('length') > this.numShown) {
              return true;
            } else {
              return false;
            }
          }),

          filteredQuizzes: Ember.computed('quizzes.@each.id', 'finalSearch', 'numShown', 'quizzes.@each.isAlreadySharedQuiz', function () {

            var results = this.quizzes.filter(function (quiz, index, array) {
              // trim extra space from the quiz name
              quiz.set('quizName', quiz.get('quizName').trim());

              var keywordSearch = true;
              if (this.searchBox.length > 0) {
                var splitKeywords = this.searchBox.toLowerCase().split(" ");
                splitKeywords.forEach(function (k) {
                  if (keywordSearch) {
                    if (quiz.get('accountName')) {
                      keywordSearch = quiz.get('quizName').toLowerCase().indexOf(k) >= 0 || quiz.get('user').get('lastName').toLowerCase().indexOf(k) >= 0 || quiz.get('user').get('firstName').toLowerCase().indexOf(k) >= 0 || quiz.get('user').get('userName').toLowerCase().indexOf(k) >= 0 || quiz.get('accountName').toLowerCase().indexOf(k) >= 0;
                    } else {
                      keywordSearch = quiz.get('quizName').toLowerCase().indexOf(k) >= 0 || quiz.get('user').get('lastName').toLowerCase().indexOf(k) >= 0 || quiz.get('user').get('firstName').toLowerCase().indexOf(k) >= 0 || quiz.get('user').get('userName').toLowerCase().indexOf(k) >= 0;
                    }
                  }
                });
              }
              return keywordSearch;
            }, this);

            return results.slice(0, this.numShown);
          })

        });
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Search Results');
    },

    actions: {

      searchKeyword: function searchKeyword() {
        document.activeElement.blur();
        this.set('currentModel.finalSearch', this.currentModel.searchBox);
      }
    }
  });
});
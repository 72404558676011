define('ohs/components/components/search-standard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    assessmentClass: '',
    eligibleClass: '',
    counter: 250,
    all_checkbox: false,
    more_checkbox_1: false,
    more_checkbox_2: false,
    all_disabled: undefined,
    more_disabled: undefined,

    watchall_checkbox: function () {
      this.set('more_disabled', this.get('all_checkbox'));
    }.observes('all_checkbox'),

    watchmore_checkboxes: function () {
      this.set('all_disabled', this.get('more_checkbox_1') || this.get('more_checkbox_2'));
    }.observes('more_checkbox_1' || 'more_checkbox_2'),

    watchmore_checkbox_1: function () {
      if ('more_checkbox_1') {
        var counter = this.get('counter');
        this.set('counter', counter - 50);
      }
    }.observes('more_checkbox_1'),

    watchmore_checkbox_2: function () {
      if ('more_checkbox_2') {
        var counter = this.get('counter');
        this.set('counter', counter - 50);
      }
    }.observes('more_checkbox_2'),

    peopleViews: [{
      text: "Students",
      id: 1
    }],

    actions: {
      openAnchors: function openAnchors() {
        this.set('assessmentClass', 'active');
        var counter = this.get('counter');
        this.set('counter', counter - 50);
      },

      openEligible: function openEligible() {
        this.set('eligibleClass', 'active');
        var counter = this.get('counter');
        this.set('counter', counter - 50);
      }
    }
  });
});
define('ohs/serializers/rest-serializer', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var RESTSerializer = _emberData.default.RESTSerializer;
    exports.default = RESTSerializer.extend({
        keyForRelationship: function keyForRelationship(key) {
            return key;
        }
    });
});
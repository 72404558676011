define('ohs/components/quiz-step-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['quiz-step-item'],
    classNameBindings: ['enabled:is-enabled'],
    expanded: false,
    editable: false,
    enabled: true,

    didInsertElement: function didInsertElement() {
      this.get('parent').registerChild(this);
    },

    expandedClasses: function () {
      return this.get('expanded') ? 'expanded' : '';
    }.property('expanded'),

    // expandedClasses: Ember.computed('expanded',function() {
    //   return this.get('expanded') ? 'expanded' : '';
    // }).property('expanded'),

    editableClasses: function () {
      return this.get('editable') ? 'editable' : '';
    }.property('editable'),

    showContent: function showContent() {
      this.set('expanded', true);
    },

    hideContent: function hideContent() {
      this.set('expanded', false);
    },

    showEditable: function showEditable() {
      this.set('editable', true);
    },

    hideEditable: function hideEditable() {
      this.set('editable', false);
    },

    actions: {
      showNext: function showNext() {
        this.get('parent').showNextChild(this);
      },
      editContent: function editContent() {
        this.get('parent').collapseNextSteps(this);
      }
    }
  });
});
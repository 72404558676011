define('ohs/components/mission-control/mission-item-group', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'article',
        classNames: 'mission-item-group',

        //// properties
        showPlanetModal: false,

        anchors: Ember.computed('planet.standards', function () {
            return this.planet.get('standards').join(', ');
        })
    });
});
define('ohs/routes/edit-class-resource', ['exports', 'ohs/mixins/authorized-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authorizedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authorizedRouteMixin.default, _breadcrumbMixin.default, {
    authorizedRoles: ['Tutor', 'Teacher'],
    titleToken: 'Class Resources',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },
    model: function model(params) {
      //Find the previous route and redirect to it after completing the task
      var previousRoutes = this.router.router.currentHandlerInfos;
      var previousRoute = previousRoutes && previousRoutes.pop();

      if (previousRoute) {
        this.set('lastVisitedRoute', previousRoute.name);
      }

      var classResourceId = params.class_resource_id;

      return Ember.RSVP.hash({
        classResourceId: params.class_resource_id,
        classResource: this.store.findRecord('class-resource', classResourceId),
        // classLibraryFolder:this.store.findRecord('class-library-folder',params.folder_id),

        // classLibraryFolders: this.store.findAll('class-library-folder'),
        // orderedclassLibraryFolders: Ember.computed('classLibraryFolders.@each.id',function() {
        //   return this.classLibraryFolders.sortBy('name');
        // }),
        //   selectedclassLibraryFolders:[],
        fileName: Ember.computed('classResource.fileUrl', function () {
          return this.classResource.get('fileUrl').replace(/^.*[\\\/]/, '');
        })
      });
    },
    afterModel: function afterModel(model) {
      this._super(model, 'Edit Item', model.classResourceId, false);
    },


    actions: {

      cancel: function cancel() {
        this.transitionTo('view-class-resource', this.get('currentModel.classResourceId'));
      },
      //
      //   setSelectedclassLibraryFolder(selectedValue) {
      //     var classLibraryFolderId = selectedValue;
      //     if (classLibraryFolderId) {
      //       let classLibraryFolder = this.store.peekRecord('class-library-folder',parseInt(classLibraryFolderId));
      //       //If class Library Folder already exist in array remove it otherwise add it
      //       if(this.currentModel.selectedclassLibraryFolders.contains(classLibraryFolder)){
      //         this.currentModel.selectedclassLibraryFolders.removeObject(classLibraryFolder);
      //     }
      //     else {
      //         this.currentModel.selectedclassLibraryFolders.pushObject(classLibraryFolder);
      //     }
      //   }
      // },
      //decide whether or not should the transition occur.
      willTransition: function willTransition(transition) {
        this.get('currentModel.classResource').rollbackAttributes();
      },


      hideAlert: function hideAlert() {
        var self = this;
        self.set('currentModel.alertMessage', '');
        self.set('currentModel.showAlert', false);
      },
      hideConfirm: function hideConfirm() {
        this.transitionTo('view-class-resource', this.get('currentModel.classResourceId'));
      },

      save: function save() {
        var self = this;
        var classResource = self.get('currentModel.classResource');
        //$('[name="uploadClassResource"]').prop("files") can be undefined if no file is uploaded, this causes [0] to throw an error.
        //setting this to null negates that.
        var file = $('[name="uploadClassResource"]').prop("files") ? $('[name="uploadClassResource"]').prop("files")[0] : null;
        var validExts = ['docx', 'xlsx', 'pptx', 'txt', 'doc', 'xls', 'pdf', 'ppt', 'html', 'jpg', 'mp3', 'mp4', 'png', 'psd', 'svg', 'zip'];
        var baseUrl = this.store.adapterFor('application').get('host');

        // validation of file type **START**//
        if (file) {
          var fileExt = file.name.slice(file.name.lastIndexOf('.') + 1);
          if (!validExts.includes(fileExt)) {
            self.set("currentModel.showAlert", true);
            self.set('currentModel.alertMessage', "Please upload a valid file!");
            self.set("currentModel.loadFinished", true);
            return;
          }
          //validate size in bytes. < 10 MB
          else if (file.size > 10485760) {
              self.set('currentModel.showAlert', true);
              self.set('currentModel.alertMessage', 'Please upload a file smaller than 10 MB.');
              self.set('currentModel.loadFinished', true);
              return;
            }
        }
        // validation of file type **END**//

        if (!classResource.get('invalidAttributes')) {
          classResource.set('invalidAttributes', new Array());
        }
        if (classResource.get('invalidAttributes.length') == 0) {
          document.activeElement.blur();

          //increment the tick so that validation is done for each field
          self.set('currentModel.tick', self.get('currentModel.tick') + 1);

          //delay for 1 seconds to allow validations to happen
          setTimeout(function () {
            if (classResource.get('invalidAttributes.length') > 0) {
              //user needs to fix validation errorMessage
              self.set('currentModel.isProcessing', false);
            } else {
              self.set('currentModel.isProcessing', true);
              classResource.save().then(
              //success
              function (uploadedClassResource) {
                self.set("currentModel.loadFinished", false);
                self.updateBreadCrumbs();
                if (file) {
                  var formData = new FormData();
                  var xhr = new XMLHttpRequest();

                  formData.append(file.name, file);
                  xhr.open('POST', baseUrl + '/classResources/UploadClassResource?classResourceId=' + encodeURIComponent(uploadedClassResource.id), true);
                  xhr.onreadystatechange = function () {
                    if (xhr.readyState == 4) {
                      self.showSuccessAlert();
                      if (xhr.response) {
                        self.showFailureAlert();
                      }
                    }
                  };
                  xhr.send(formData);
                } else {
                  self.showSuccessAlert();
                }
                //failure
              }, function (error) {
                self.set('currentModel.isProcessing', false);
                self.set('currentModel.showAlert', true);
                if (error && error.errors && error.errors.length > 0 && error.errors[0].detail) {
                  self.set('currentModel.alertMessage', error.errors[0].detail);
                } else {
                  self.set('currentModel.alertMessage', 'There was a problem creating the user. Please check your internet connection and try again.');
                }
              });
            }
          }, 1000);
        }
      },

      ConfirmRemoveFile: function ConfirmRemoveFile() {
        this.set('currentModel.showModal', true);
      },

      removeFile: function removeFile() {
        this.set('currentModel.showModal', false);
        var item = this.store.peekRecord('class-resource', this.get('currentModel.classResourceId'));
        item.set('fileUrl', '');
        item.save();
      }
    },
    showSuccessAlert: function showSuccessAlert() {
      var self = this;
      self.set('currentModel.confirmMessage', 'Your information has been saved successfully!');
      self.set('currentModel.isProcessing', false);
    },
    showFailureAlert: function showFailureAlert() {
      var self = this;
      self.set('currentModel.confirmMessage', 'Please select a valid file to upload!');
      self.set('currentModel.isProcessing', false);
    },

    //Common Methods
    updateBreadCrumbs: function updateBreadCrumbs() {
      var breadcrumbs = this.get('session').get('breadcrumbs');
      if (breadcrumbs.length > 1) {
        var bc = breadcrumbs[breadcrumbs.length - 2];
        if (bc) {
          this.get('session').get('breadcrumbs').popObject(); //pop 'Edit Item'
          this.get('session').get('breadcrumbs').popObject(); //pop old item name
        }
      }
    }
  });
});
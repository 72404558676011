define('ohs/routes/student-teacher-list', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/student-authorize-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _studentAuthorizeMixin, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _studentAuthorizeMixin.default, _breadcrumbMixin.default, {
    titleToken: 'My Teachers',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
    },

    model: function model() {

      return Ember.RSVP.hash({
        student: this.modelFor('application').user,
        teachers: Ember.computed.filterBy('student.teachers', 'userRole', 'Teacher'),
        studentUserId: Ember.computed.alias('student.id'),
        teacherUserId: '',
        teacherName: '',
        code: '',
        showModal: '',
        successMessages: '',
        validationErrors: '',
        isProcessing: false
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Teachers', false, true);
    },

    actions: {
      joinNow: function joinNow() {
        document.activeElement.blur();
        var self = this;
        self.set("currentModel.validationErrors", '');
        self.set("currentModel.successMessages", '');
        self.set("currentModel.showModal", false);
        self.set("currentModel.isProcessing", true);

        if (self.currentModel.code.trim()) {
          return Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/teachers?code=' + self.currentModel.code.trim()).done(function (response) {
            if (response[0]) {
              self.set("currentModel.showModal", true);
              self.set("currentModel.teacherUserId", response[0].id);
              self.set("currentModel.teacherName", response[0].honorific + " " + response[0].lastName);
              self.set("currentModel.isProcessing", false);
            } else {
              self.set("currentModel.validationErrors", 'The teacher code you entered could not be found.');
              self.set("currentModel.isProcessing", false);
            }
          }).fail(function (response) {
            self.set("currentModel.validationErrors", response.responseText);
            self.set("currentModel.isProcessing", false);
          });
        } else {
          self.set("currentModel.validationErrors", 'Teacher code can\'t be blank.');
          self.set("currentModel.isProcessing", false);
        }
      },

      join: function join(teacherUserId) {
        document.activeElement.blur();
        var self = this;
        self.set("currentModel.validationErrors", '');
        self.set("currentModel.successMessages", '');
        self.set("currentModel.showModal", false);

        return Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/students?teacherUserId=' + teacherUserId + '&studentUserId=' + self.get("currentModel.studentUserId")).done(function (response) {
          if (response == true) {
            self.set("currentModel.successMessages", 'You have successfully joined ' + self.get("currentModel.teacherName") + ' ’s roster.');
            get(self, 'store').findRecord('student', get(self, 'currentModel.studentUserId')).then(function (student) {
              return set(self, 'currentModel.student', student);
            });
          } else {
            self.set("currentModel.validationErrors", 'You have already joined ' + self.get("currentModel.teacherName") + ' ’s roster.');
            self.set("currentModel.isProcessing", false);
          }
        }).fail(function (response) {
          self.set("currentModel.validationErrors", response.responseText);
          self.set("currentModel.isProcessing", false);
        });
      }

    }

  });
});
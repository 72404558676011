define('ohs/components/student-result-self-guided', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    pctCorrect: Ember.computed('sta.studentAnswers.@each.answerId', function () {
      if (this.get('sta').get('numAnswered') == 0) {
        return "-";
      } else {
        return this.get('sta').get('percentCorrectInProgress') + "%";
      }
    }),

    pctComplete: Ember.computed('sta.numAnswered', function () {
      if (this.get('sta').get('isFinished')) {
        return 100;
      } else {
        var increment = 100 / this.get('sta').get('administration').get('quiz').get('testQuestions').get('length');
        return this.get('sta').get('numAnswered') * increment;
      }
    }),

    completeLabel: Ember.computed('sta.isFinished', function () {
      if (this.get('sta').get('isFinished')) {
        return "Complete ";
      } else {
        return "";
      }
    }),

    actions: {
      confirmDeleteStudentTest: function confirmDeleteStudentTest(studentTestAdministration) {
        this.sendAction('ConfirmDeleteStudentAction', studentTestAdministration);
      }
    }
  });
});
define('ohs/models/trophy', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    trophyCategory: _emberData.default.belongsTo('trophy-category'),
    activeClass: _emberData.default.attr('string'),
    inactiveClass: _emberData.default.attr('string'),
    mystery: _emberData.default.attr('boolean'),
    text: _emberData.default.attr('string'),
    studentTrophies: _emberData.default.hasMany('student-trophy'),

    mostRecentAwardedDate: Ember.computed('studentTrophies.@each.dateEarned', function () {
      var ordered = this.get('studentTrophies').sortBy('dateEarned').reverse();
      if (ordered && ordered.get('firstObject')) {
        return ordered.get('firstObject').get('dateEarned');
      }
    })

  });
});
define('ohs/routes/sign-up-success', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Sign-up Success',
    session: Ember.inject.service(),
    model: function model() {
      var createdUser = this.modelFor('sign-up-student');
      if (!createdUser) {
        createdUser = this.modelFor('sign-up-teacher');
      }

      if (!createdUser) {
        createdUser = this.modelFor('sign-up-tutor');
      }

      var schoologyUser = this.modelFor('schoology-sign-in');
      if (createdUser) {
        return createdUser;
      } else if (schoologyUser) {
        return schoologyUser;
      } else {
        this.transitionTo('sign-up');
      }
    },


    actions: {

      signIn: function signIn() {
        this.set('currentModel.isProcessing', true);
        if (this.get('currentModel.isGoogleSignUp')) this.googleSignIn();else if (this.get('currentModel.isSchoologySignUp')) {
          this.schoologySignIn();
        } else {
          this.transitionTo('login');
        }
      }

    },

    googleSignIn: function googleSignIn() {
      document.activeElement.blur();

      var self = this;
      if (gapi.auth2 && gapi.auth2.getAuthInstance()) {
        gapi.auth2.getAuthInstance().signIn().then(function (googleUser) {
          var credentials = { 'idtoken': googleUser.getAuthResponse().id_token };
          self.get('session').authenticate('authenticator:custom', credentials).catch(function (message) {
            if (message) {
              var obj = JSON.parse(message);
            }
            self.transitionTo('login');
          });
        });
      } else {
        self.transitionTo('login');
      }
    },
    schoologySignIn: function schoologySignIn() {
      var self = this;

      //get cookies
      var pairs = document.cookie.split(";");
      var cookies = {};
      for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].trim().split("=");
        cookies[pair[0]] = unescape(pair[1]);
      }

      if (cookies.SRT && cookies.SRTS) {
        var self = this;
        //log in with schoology
        var credentials = { 'schoologyAccessToken': cookies.SRT, 'schoologyAccessSecret': cookies.SRTS };
        self.get('session').authenticate('authenticator:custom', credentials).catch(function (message) {
          if (message) {
            var obj = JSON.parse(message);

            //clear out cookies
            var cookie_date = new Date();
            cookie_date.setTime(cookie_date.getTime() - 1);
            document.cookie = 'SRT=; Path=/; Expires=' + cookie_date.toGMTString();
            document.cookie = 'SRTS=; Path=/; Expires=' + cookie_date.toGMTString();
          }
          self.transitionTo('login');
        });
      } else {
        self.transitionTo('login');
      }
    }
  });
});
define('ohs/routes/parent/resource-detail', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/parent-authorize-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _parentAuthorizeMixin, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _parentAuthorizeMixin.default, _breadcrumbMixin.default, {
    titleToken: function titleToken(model) {
      return model.resource.get('resourceName');
    },

    signalR: Ember.inject.service('signal-r'),
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('parent_many_children', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      var currentStudentId = sesObj.get('currentStudent');
      var self = this;
      var commentLength = 0;
      var textLimit = 0;

      if (currentStudentId) {
        return this.store.findRecord('resource', params.resource_id).then(function (r) {
          return Ember.RSVP.hash({
            resource: r,
            curUser: self.store.findRecord('parent', userId),
            student: self.store.findRecord('student', currentStudentId),

            bookmark: self.store.findAll('resourceBookmark').then(function (b) {
              return b.find(function (item, index, enumerable) {
                return item.get('resource').get('id') == params.resource_id;
              });
            }),

            warningMessage: '',
            confirmationMessage: '',
            resourceIssue: '',
            resourceIssueComments: '',
            studentMessage: '',
            issueMessage: '',
            showNavConfirmModal: false,

            // validIssue: Ember.computed('resourceIssue','resourceIssueComments',function() {
            //   return (this.resourceIssue && this.resourceIssue != "Other" && this.resourceIssueComments.length<=this.textLimit)
            //     || (this.resourceIssue == "Other" && this.resourceIssueComments.trim() && this.resourceIssueComments.length<=this.textLimit);
            // }),
            validIssue: Ember.computed('resourceIssue', 'commentLength', 'textLimit', function () {
              if (this.resourceIssue) {
                if (this.resourceIssue != "Other") {
                  if (this.resourceIssueComments && this.textLimit) {
                    return this.resourceIssueComments.length <= this.textLimit;
                  }
                  return true;
                } else {
                  if (this.resourceIssueComments && this.textLimit) {
                    if (this.resourceIssueComments.length > 0 && this.resourceIssueComments.length <= this.textLimit) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  return false;
                }
              }
              return false;
            }),
            tellUsMore: Ember.computed('resourceIssue', function () {
              if (this.resourceIssue == "Other") {
                return "Tell us more.";
              } else {
                return "Tell us more (optional).";
              }
            })
          });
        }, function (error) {
          console.log(error);
        });
      } else {
        return this.store.findRecord('resource', params.resource_id).then(function (r) {
          return Ember.RSVP.hash({
            resource: r,
            curUser: self.store.findRecord('parent', userId),

            bookmark: self.store.findAll('resourceBookmark').then(function (b) {
              return b.find(function (item, index, enumerable) {
                return item.get('resource').get('id') == params.resource_id;
              });
            }),

            warningMessage: '',
            confirmationMessage: '',
            resourceIssue: '',
            resourceIssueComments: '',
            studentMessage: '',
            issueMessage: '',
            showNavConfirmModal: false,

            // validIssue: Ember.computed('resourceIssue','resourceIssueComments',function() {
            //   return (this.resourceIssue && this.resourceIssue != "Other")
            //     || (this.resourceIssue == "Other" && this.resourceIssueComments.trim());
            // }),

            validIssue: Ember.computed('resourceIssue', 'commentLength', 'textLimit', function () {
              if (this.resourceIssue) {
                if (this.resourceIssue != "Other") {
                  if (this.resourceIssueComments && this.textLimit) {
                    return this.resourceIssueComments.length <= this.textLimit;
                  }
                  return true;
                } else {
                  if (this.resourceIssueComments && this.textLimit) {
                    if (this.resourceIssueComments.length > 0 && this.resourceIssueComments.length <= this.textLimit) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  return false;
                }
              }
              return false;
            }),

            tellUsMore: Ember.computed('resourceIssue', function () {
              if (this.resourceIssue == "Other") {
                return "Tell us more.";
              } else {
                return "Tell us more (optional).";
              }
            })
          });
        }, function (error) {
          console.log(error);
        });
      }
    },

    afterModel: function afterModel(model) {
      this._super(model, model.resource.get('resourceName'), model.resource.get('id'));
      //Signal-R event subscription
      this.get('signalR').on('joinRoster', this, 'joinRoster');
      this.get('signalR').on('linkParentStudent', this, 'linkParentStudent');
    },


    actions: {

      setCharacterLimitAction: function setCharacterLimitAction(commentCharacterLength, characterLimit, comment) {
        this.set('currentModel.textLimit', characterLimit);
        this.set('currentModel.commentLength', commentCharacterLength);
        this.set('currentModel.resourceIssueComments', comment);
      },

      //Hide alert box
      hideAlert: function hideAlert() {
        this.set("currentModel.showAlert", false);
      },

      //Hide alert box
      hideConfirm: function hideConfirm() {
        this.set("currentModel.showConfirm", false);
      },

      createBookmark: function createBookmark() {
        var curModel = this.currentModel;
        var self = this;
        if (!curModel.bookmark) {
          var b = this.store.createRecord('resource-bookmark', {
            resource: curModel.resource,
            user: curModel.curUser,
            bookmarkedDate: new Date()
          });
          b.save().then(function () {
            self.refresh();
          }, function () {
            self.set('currentModel.warningMessage', "This resource could not be bookmarked. Please check your Internet connection and try again.");
            self.set("currentModel.showAlert", true);
          });
        }
      },

      deleteBookmark: function deleteBookmark() {
        var curModel = this.currentModel;
        var self = this;
        if (curModel.bookmark) {
          var b = curModel.bookmark;
          b.destroyRecord().then(function () {
            self.refresh();
          }, function () {
            self.set('currentModel.warningMessage', "This bookmark could not be deleted. Please check your Internet connection and try again.");
            self.set("currentModel.showAlert", true);
          });
        }
      },

      sendResource: function sendResource() {
        var self = this;

        var ra = this.store.createRecord('resource-assigned', {
          resource: this.get('currentModel.resource'),
          student: this.get('currentModel.student'),
          user: this.get('currentModel.curUser') });
        ra.save().then(function () {
          self.set('currentModel.confirmationMessage', 'Resource sent successfully!');
          self.set('currentModel.showConfirm', true);
        }, function (error) {
          self.set("currentModel.showAlert", true);
          self.set("currentModel.warningMessage", "There was an error sending the resource. Please check your internet connection and try again.");
        });
      },

      showFullDescription: function showFullDescription() {
        this.set('currentModel.showFullDescription', true);
      },

      hideFullDescription: function hideFullDescription() {
        this.set('currentModel.showFullDescription', false);
      },

      reportIssue: function reportIssue() {

        this.set('currentModel.issueMessage', null);
        var self = this;
        var adapter = this.get('store').adapterFor('application');

        var url = adapter.get('host') + '/accounts/reportResource?resourceId=' + this.get('currentModel.resource').get('id') + '&issue=' + this.get('currentModel.resourceIssue') + '&comment=' + this.get('currentModel.resourceIssueComments');
        if (this.get('currentModel.resourceIssueComments').trim() == '') {
          url = adapter.get('host') + '/accounts/reportResource?resourceId=' + this.get('currentModel.resource').get('id') + '&issue=' + this.get('currentModel.resourceIssue');
        }
        adapter.ajax(url, 'POST').then(function () {
          self.set('currentModel.showConfirm', true);
          self.set('currentModel.confirmationMessage', 'Your report has been received and is currently being reviewed. If we have any follow up questions, someone will be in touch!');
        }, function () {
          self.set('currentModel.showAlert', true);
          self.set("currentModel.warningMessage", "There was an error sending your report. Please check your internet connection and try again.");
        });

        this.set('currentModel.resourceIssue', null);
        this.set('currentModel.resourceIssueComments', '');
      },

      setResourceIssue: function setResourceIssue(value) {
        this.set('currentModel.resourceIssue', value);
      },

      setResourceIssueComment: function setResourceIssueComment(value) {
        this.set('currentModel.resourceIssueComments', value);
      },

      validateIssue: function validateIssue() {
        if (!this.get('currentModel.resourceIssue')) {
          this.set('currentModel.issueMessage', 'Please tell us what is wrong with this resource.');
          return false;
        } else if (this.get('currentModel.resourceIssue') == "Other" && !this.get('currentModel.resourceIssueComments').trim()) {
          this.set('currentModel.issueMessage', 'Please add some additional information about what is wrong with this resource.');
          return false;
        } else if (this.get('currentModel.resourceIssueComments.length') > this.get('currentModel.textLimit')) {
          this.set('currentModel.issueMessage', 'Comment exceeds the character limit.');
          return false;
        } else {
          return true;
        }
      },

      loading: function loading(transition, originRoute) {
        if (originRoute != this) return true;
      },

      confirmVisitResource: function confirmVisitResource(webUrl) {
        var self = this;
        this.disableVisitResourceButton(true);
        // var user = this.get('store').findRecord('user',this.get('currentModel.curUser.id'),{reload : true}).then((user)=>{
        var user = this.get('currentModel.curUser');
        if (user.get('hideNavigationWarning')) {
          self.disableVisitResourceButton(false);
          window.open(webUrl);
        } else {
          self.set('currentModel.navigateURL', webUrl);
          self.set('currentModel.showNavConfirmModal', true);
        }
        // })
      },
      cancel: function cancel() {
        this.set('currentModel.issueMessage', '');
        this.set('currentModel.resourceIssueComments', '');
        this.set('currentModel.resourceIssue', null);
      },
      clear: function clear() {
        this.set('currentModel.issueMessage', '');
        this.set('currentModel.resourceIssueComments', '');
        this.set('currentModel.resourceIssue', null);
      },


      showFlashWarning: function showFlashWarning() {
        this.set('currentModel.showFlashWarning', true);
      },

      closeFlash: function closeFlash() {
        this.set('currentModel.showFlashWarning', false);
      }

    },

    joinRoster: function joinRoster() {
      this.refresh();
    },
    linkParentStudent: function linkParentStudent(userId) {
      var sesObj = this.get('session');
      if (!sesObj.get('currentStudent')) {
        sesObj.set('currentStudent', userId);
      }
      this.refresh();
    },
    disableVisitResourceButton: function disableVisitResourceButton(isDisable) {
      $('.button-subtle').prop('disabled', isDisable);
    }
  });
});
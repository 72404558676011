define('ohs/models/student-trophy', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    student: _emberData.default.belongsTo('student'),
    dateEarned: _emberData.default.attr('date'),
    studentViewed: _emberData.default.attr('boolean'),
    trophy: _emberData.default.belongsTo('trophy'),

    dateEarnedDisplay: Ember.computed('dateEarned', function () {
      var date;
      if (this.get('dateEarned')) {
        date = this.get('dateEarned').toLocaleDateString();
      } else {
        return 'Not Yet Earned';
      }
      var today = new Date();
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (today.toLocaleDateString() == date) return 'Earned Today';else if (yesterday.toLocaleDateString() == date) {
        return 'Earned Yesterday';
      } else return 'Earned ' + date;
    })
  });
});
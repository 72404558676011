define('ohs/routes/teacher/tug-of-war-lobby', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        renderTemplate: function renderTemplate() {
            this.render();
            Ember.run.scheduleOnce('afterRender', this, function () {
                // Select Styling
                $('select').each(function () {
                    var $this = $(this),
                        numberOfOptions = $(this).children('option').length;

                    $this.addClass('select-hidden');
                    $this.wrap('<div class="select"></div>');
                    $this.after('<div id="select-styled"></div>');

                    var $styledSelect = $this.next('div#select-styled');
                    $styledSelect.text($this.children('option').eq(0).text());

                    var $list = $('<ul />', {
                        'class': 'select-options'
                    }).insertAfter($styledSelect);

                    Array.from(this.children).forEach(function (child) {
                        $('<li />', {
                            text: child.innerText,
                            rel: child.value,
                            class: child.value
                        }).appendTo($list);
                    });

                    var $listItems = $list.children('li');

                    $styledSelect.click(function (e) {
                        var isChoice = $(e.target).is('li');
                        e.stopPropagation();

                        if (!isChoice) {
                            $('div#select-styled.active').not(this).each(function () {
                                $(this).removeClass('active').next('ul.select-options').hide();
                                $(this).closest(".team").find('.team-list').toggleClass('fade');
                            });
                            $(this).toggleClass('active').next('ul.select-options').slideToggle(300);
                            $list.closest(".team").find('.team-list').toggleClass('fade');
                        }
                    });

                    $listItems.click(function (e) {
                        e.stopPropagation();
                        $styledSelect.text($(this).text()).removeClass('active');
                        $styledSelect.attr("class", $(this).attr('rel'));
                        $this.val($(this).attr('rel'));
                        $list.slideUp(300);
                        $list.closest(".team").find('.team-list').toggleClass('fade');
                        this.closest('.team').setAttribute('theme-color', $(this).attr('rel'));
                    });

                    $(document).click(function () {
                        $styledSelect.removeClass('active');
                        $list.hide();
                        if ($('.team-list').hasClass("fade")) {
                            $('.team-list').removeClass('fade');
                        }
                    });
                });
            });
        }
    });
});
define('ohs/components/utility-navigation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['aria-hidden', 'role', 'aria-labeledby'],
    classNames: ['utility-navigation-wrapper'],
    classNameBindings: ['isExpanded'],
    role: 'dialog',
    'aria-labeledby': 'utility-nav-content',
    isExpanded: false,
    session: Ember.inject.service('session'),

    userRole: Ember.computed('session', function () {
      var roles = this.get('curSes').session.content.authenticated["roles"];
      if (roles.includes("Teacher")) {
        return "Teacher";
      } else if (roles.includes("Student")) {
        return "Student";
      } else if (roles.indexOf("Parent") > 0) {
        return "Parent";
      }
    }),

    displayName: Ember.computed('session', function () {
      return this.get('curSes').session.content.authenticated["displayName"];
    }),

    didInsertElement: function didInsertElement() {
      this.get('parent').registerChild(this);
    },

    hide: function hide() {
      this.set('isExpanded', false);
    },

    'aria-hidden': function () {
      return !this.get('isExpanded');
    }.property('isExpanded'),

    actions: {
      openUtility: function openUtility() {
        this.toggleProperty('isExpanded');
        this.attrs.hideOthers(this);
      },

      invalidateSession: function invalidateSession() {
        if (gapi.auth2) {
          var auth2 = gapi.auth2.getAuthInstance();
          auth2.signOut().then(function () {
            console.log('User signed out.');
          });
        }
        this.get('session').invalidate();
      },

      hide: function hide() {
        this.set('isExpanded', false);
      },

      hideTouch: function hideTouch() {
        var self = this;
        setTimeout(function () {
          self.set('isExpanded', false);
        }, 350);
      }
    }
  });
});
define('ohs/components/empty-state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['empty-state-wrapper'],
    tagName: 'div',

    actions: {
      buttonClick: function buttonClick() {
        this.sendAction('buttonAction');
      },

      secondaryButtonClick: function secondaryButtonClick() {
        this.sendAction('secondaryButtonAction');
      }
    }
  });
});
define('ohs/routes/teacher/search-standard', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Search Standard',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    model: function model() {
      var searchParams = this.controllerFor('teacher.resource-search-start');

      if (searchParams) {
        return searchParams;
      } else {
        this.transitionTo('teacher.resource-search-start');
      }
    },


    afterModel: function afterModel(model) {
      this._super(model, 'Search By Standard');
    },

    actions: {
      submit: function submit() {
        document.activeElement.blur();
        this.transitionTo('teacher.search-results');
      },

      setTarget: function setTarget(value) {
        this.set('currentModel.selectedTarget', value);
      }
    }

  });
});
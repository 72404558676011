define('ohs/routes/home', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: 'Home',
    afterModel: function afterModel(model, transition) {
      //In certain instances, the refreshModel action cannot be triggered. In that case, we can ignore the error
      //TODO: REFACTOR
      /*
        This little tidbit of code is preventing us from utilizing our own authenticated-route-mixin, because 
        if the application model is not refreshed, weird things happen. This is due to the way the entire application 
        relys on having roles in the application model hook. This really needs to be done away with. A good fix for this
        would be to properly nest the routes and do away with the role switching in the application model hook.
      */

      try {
        this.controllerFor('application').refreshModel();
      } catch (err) {}

      var _EmberGet = Ember.get(this, 'session.session.content.authenticated'),
          roles = _EmberGet.roles,
          forcePasswordChange = _EmberGet.forcePasswordChange,
          hasRoster = _EmberGet.hasRoster,
          userId = _EmberGet.userId;

      if (forcePasswordChange) return this.transitionTo('force-password-change');

      if (!hasRoster && roles.includes('Student')) return this.transitionTo('force-roster-join');

      //log user login
      var baseUrl = this.store.adapterFor('application').get('host');
      Ember.$.post(baseUrl + '/LogUserLogin?userId=' + userId);

      if (roles.includes('Teacher')) return this.transitionTo('teacher-quizzes');

      if (roles.includes("Student")) return this.transitionTo('student-quizzes');

      if (roles.includes("Tutor")) return this.transitionTo('teacher.mission-control.mission.search');
    }
  });
});
define('ohs/routes/passage-quiz-review', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/passage-modal-mixin'], function (exports, _authenticatedRouteMixin, _reset, _noNavigation, _teacherAuthorizeMixin, _passageModalMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _noNavigation.default, _teacherAuthorizeMixin.default, _passageModalMixin.default, {
    titleToken: function titleToken(model) {
      if (model.quiz) {
        return model.quiz.get('quizName');
      }
    },

    model: function model(params) {
      var store = this.store;
      var self = this;
      var passagequiz = this.modelFor('passage-quiz');
      return Ember.RSVP.hash({
        passagequiz: this.modelFor('passage-quiz'),
        curPassageId: params.passage_id,
        passage: store.peekRecord('passage', params.passage_id),
        CurQuizId: params.quiz_id,
        quiz: this.store.find('quiz', params.quiz_id),
        orderedQuestions: Ember.computed('quiz.testQuestions.@each.id', 'quiz.testQuestions.@each.extraOrder', function () {
          return this.quiz.get('testQuestions').sortBy('questionOrder');
        })

      });
    },
    actions: {
      editQuiz: function editQuiz() {
        this.transitionTo('edit-quiz-name', this.get('currentModel.quiz.id'), 0);
      },
      backToPassages: function backToPassages() {
        this.transitionTo('passage-quiz');
      },
      reviewQuiz: function reviewQuiz() {
        this.transitionTo('passage-quiz-review', this.currentModel.curPassageId, this.get('currentModel.quiz.id'));
      },
      startQuiz: function startQuiz(orderedQuestions) {
        if (orderedQuestions.length == 0) {
          this.set('currentModel.showAlert', true);
          this.set("currentModel.alertMessage", "Please add at least one question in order to give the quiz.");
        } else {
          this.transitionTo('teacher.start-quiz.pick-type', this.get('currentModel.quiz.id'));
        }
      },
      doneForNow: function doneForNow() {
        this.transitionTo('teacher-quizzes');
      },
      setShared: function setShared() {
        this.toggleProperty('currentModel.quiz.shared');
        this.get('currentModel.quiz').save();
      },

      hideIssueConfirm: function hideIssueConfirm() {
        this.set('currentModel.showIssueConfirm', false);
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
      }
    }
  });
});
define('ohs/components/student-group-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'article',
    // classNames: ['selectable-group-item'],
    classNameBindings: ['dynamicClasses'],

    dynamicClasses: Ember.computed('group_url', 'linkAction', 'itemClass', function () {
      if (this.get('group_url') || this.get('linkAction') || this.get('linkToGroup')) {
        return 'selectable-group-item';
      } else {
        return 'selectable-student-item' + this.get('itemClass');
      }
    }),

    itemClass: Ember.computed('group.students.@each.id', function () {
      if (this.get('group').get('students').contains(this.get('student'))) {
        return ' in-group';
      } else {
        return '';
      }
    }),

    acceptDisabled: Ember.computed('group.students.@each.id', function () {
      if (this.get('group').get('students').contains(this.get('student'))) {
        return true;
      } else {
        return false;
      }
    }),

    removeDisabled: Ember.computed('group.students.@each.id', function () {
      if (this.get('group').get('students').contains(this.get('student'))) {
        return false;
      } else {
        return true;
      }
    }),

    actions: {
      toggleStudent: function toggleStudent() {
        var self = this;
        self.isProcessing(true);

        if (self.get('group').get('students').contains(self.get('student'))) {
          self.get('group').get('students').removeObject(self.get('student'));
        } else {
          self.get('group').get('students').pushObject(self.get('student'));
        }
        self.get('group').save().then(function (response) {
          self.isProcessing(false);
        }, function () {
          self.isProcessing(false);
        });
      },

      linkAction: function linkAction() {
        this.sendAction('linkAction', this.get('drillId'));
      }

    },

    //Methods

    //For Enable/Disable all the buttons in the div with 'group-actions' class
    isProcessing: function isProcessing(status) {
      if (status == true) {
        //Placing a dynamic grid 'over' on the top of these button’s div tag
        $(".group-actions").append('<div id="over"  class="disable"></div>');

        //Disable 'Finished' link button while doing Add/Remove student group.
        //But there is no way to disable a link.
        //As a work around, add an onclick fuction and return false
        $(".button-subtle").attr("disabled", "disabled").on("click", function () {
          return false;
        });
      } else {
        //Removes the dynamic grid 'over' from the top of the button’s div tag only after the API interaction is finished
        $(".group-actions").find("#over").remove();

        //re-enable link
        $(".button-subtle").removeAttr("disabled").off("click");
      }
    }
  });
});
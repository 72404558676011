define('ohs/models/resource-recommended', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    resource: _emberData.default.belongsTo('resource'),
    teacher: _emberData.default.belongsTo('teacher'),
    student: _emberData.default.belongsTo('student'),
    recommendedDate: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    resourceStatus: _emberData.default.attr('string'),
    studentTestAdministration: _emberData.default.belongsTo('student-test-administration'),
    standard: _emberData.default.belongsTo('standard')
  });
});
define('ohs/components/stripe-checkout', ['exports', 'ohs/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    stripeCheckout: null,
    store: Ember.inject.service(),
    isLoading: false,

    init: function init() {
      this._super.apply(this, arguments);
      var self = this;

      self.set('stripeCheckout', StripeCheckout.configure({
        key: _environment.default.stripeAPI,
        image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
        locale: 'auto',
        token: function token(_token) {
          //handle token
          var adapter = self.get('store').adapterFor('application');
          var host = adapter.get('host');
          self.set('model.isLoading', true);

          var data = {
            'teacherEmail': self.get('model.email'),
            'token': _token.id,
            'subscriptionIds': self.get('model.selectedSubscription').get('id')
          };

          Ember.$.ajax({
            url: host + '/subscribe',
            data: data,
            dataType: 'JSON',
            type: 'Post'
          }).then(function (response) {
            self.sendAction('successAction', response);
          }, function (xhr, status, error) {
            var response = xhr.responseText;
            self.sendAction('failAction', response);
          });
        }
      }));
    },

    actions: {
      open: function open() {
        var self = this;
        var adapter = self.get('store').adapterFor('application');

        if (!self.get('model.invalidAttributes')) {
          self.set('model.invalidAttributes', new Array());
        }

        if (self.get('model.invalidAttributes').get('length') == 0) {

          self.set('model.tick', self.get('model.tick') + 1);

          setTimeout(function () {
            if (self.get('model.invalidAttributes').length > 0) {
              //invalid form
            } else {
              if (!self.get('model.selectedSubscription')) {
                self.set('model.alertMessage', 'Please select a subscription!');
                self.set('model.showAlert', true);
              } else {
                var data = {
                  'teacherEmail': self.get('model.email'),
                  'subscriptionIds': self.get('model.selectedSubscription').id,
                  'token': null
                };

                Ember.$.ajax({
                  url: adapter.get('host') + '/subscription/validate',
                  data: data,
                  dataType: 'application/x-www-form-urlencoded',
                  type: 'Post',
                  complete: function complete(response) {
                    if (response.responseText == '"Success"') {
                      var description = self.get('model.selectedSubscription').get('text');
                      var cost = self.get('model.selectedSubscription').get('stripeAmount');

                      self.get('stripeCheckout').open({
                        name: 'Kandoolu',
                        description: description,
                        amount: cost
                      });
                    } else if (response.responseText == 'District') {
                      self.sendAction('modal');
                    } else {
                      self.sendAction('alert', response.responseText);
                    }
                  }
                });
              }
            }
          }, 1000);
        }
      }
    }
  });
});
define('ohs/routes/teacher-accounts', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Teacher Accounts',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          } else if (roles.indexOf("Parent") > 0) {
            this.controllerFor('application').set('parent_many_children', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
          this.controllerFor('application').set('parent_many_children', '');
        }
      }
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var account = this.store.peekAll('account');
      var accountId = account.objectAt(0).id;
      var userId = this.get('session').session.content.authenticated["userId"];

      return Ember.RSVP.hash({
        teachers: this.store.query('teacher', { accountId: accountId }),
        loadFinished: true,
        curSort: 1,
        searchBox: '',
        teacherAccountsOptions: [{
          text: "Name",
          id: 1
        }, {
          text: "Email",
          id: 2
        }, {
          text: "Grade Level",
          id: 3
        }, {
          text: "# Of Students",
          id: 4
        }],
        //     curTeacherDetails: Ember.computed('teachers.@each.id','curSort',function() {
        //     var results;
        //     if (this.curSort == 1) {
        //       results= this.teachers.sortBy('fullName');
        //     }
        //     else if (this.curSort == 2) {
        //       results= this.teachers.sortBy('email').reverse();
        //     }
        //     else if (this.curSort == 3) {
        //       results= this.teachers.sortBy('gradeLevelDesc').reverse();
        //     }
        //      else if (this.curSort == 4) {
        //       results= this.teachers.sortBy('noOfLinkedStudents').reverse();
        //     }
        //     return results;
        // }),

        curTeacherDetails: Ember.computed('teachers.@each.id', 'curSort', 'searchBox', function () {
          var results;
          var searchResults = this.teachers.filter(function (teach, index, array) {
            if (this.searchBox) {
              return teach.get('fullName').toLowerCase().includes(this.searchBox.toLowerCase()) || teach.get('userName').toLowerCase().includes(this.searchBox.toLowerCase());
            } else {
              return true;
            }
          }, this).sortBy('fullNameReversed');

          if (this.curSort == 1) {
            results = searchResults.sortBy('fullName');
          } else if (this.curSort == 2) {
            results = searchResults.sortBy('email').reverse();
          } else if (this.curSort == 3) {
            results = searchResults.sortBy('gradeLevelDesc').reverse();
          } else if (this.curSort == 4) {
            results = searchResults.sortBy('noOfLinkedStudents').reverse();
          }
          return results;
        })

      });
    },


    afterModel: function afterModel(model) {
      this._super(model, 'Teacher Accounts', false, true);
    },

    actions: {

      sortBy: function sortBy(selectedValue) {
        this.set('currentModel.curSort', selectedValue);
      },

      drill: function drill(id) {
        this.transitionTo('teacher-account-details', id);
      },
      confirmRemoveTeacher: function confirmRemoveTeacher(teacher) {
        this.set('currentModel.selectedTeacher', teacher);
        this.set('currentModel.showModal', true);
        this.set('currentModel.modalMessage', 'Are you sure you want to delete this teacher account?');
      },
      removeTeacher: function removeTeacher() {
        this.set('currentModel.showModal', false);
        this.set('currentModel.modalMessage', null);

        var self = this;
        var teacher = this.get('currentModel.selectedTeacher');
        teacher.destroyRecord().then(function () {
          self.set("currentModel.showConfirm", true);
          self.set("currentModel.confirmMessage", 'Teacher has been deleted from your account!');
        }, function () {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Teacher could not be deleted. Please check your Internet connection and try again.");
        });
      },


      hideConfirm: function hideConfirm() {
        var self = this;
        self.refresh();
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
        this.set('currentModel.alertMessage', '');
      },

      createTeacher: function createTeacher() {
        this.transitionTo('new-user', 'Teacher', 0);
      }
    }

  });
});
define('ohs/mixins/signalr-student-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        actions: {
            changeTeam: function changeTeam() {
                this.refresh();
            },
            convertToSelfGuidedQuiz: function convertToSelfGuidedQuiz(studentTestId, testQuestionId) {
                var _this = this;

                // since we are transitioning we need to pull updated administrations
                this.store.findAll('administration').then(function () {
                    _this.transitionTo('student-self-guided-question', studentTestId, testQuestionId);
                });
            },
            endQuiz: function endQuiz(administrationId) {
                console.log('End quiz');
            },
            cancelQuiz: function cancelQuiz(administration) {
                console.log('Cancel quiz');
            },
            changeQuestion: function changeQuestion(questionIndex, answersLocked, administrationId) {
                console.log('Change question');
            },
            signalRConnectionAlert: function signalRConnectionAlert(message, messageClass, iconClass) {
                console.log('Connection alert');
            },
            removeStudentFromAdministration: function removeStudentFromAdministration(administrationId) {
                console.log('Removed student from administration');
            },
            notificationChange: function notificationChange(userId) {
                console.log('notification changed');
            }
        }
    });
});
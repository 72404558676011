define('ohs/routes/teacher/start-quiz/pick-teams', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel(transition) {
            //if there is no model for pick-students, send them back to the start
            if (!this.modelFor('teacher.start-quiz.pick-students')) {
                this.transitionTo('teacher.start-quiz.pick-type', this.modelFor('teacher.start-quiz').administration.get('quiz.id'));
            }
        },
        model: function model(params, transition) {
            var pickTeamsModel = this.modelFor('teacher.start-quiz.pick-teams'),
                administration = Ember.get(this.modelFor('teacher.start-quiz.pick-students'), 'administration'),
                teamStudents = this.shuffleTeams(administration.get('studentTestAdministrations'));
            /*TODO: remove this in time
              Currently the saving of the teams and the administration causes the model to refresh.
              This can cause all sorts of bugs on this page if it happens during the saving of the
              student-test-administrations. We need a different serializer so we can package the
              creating of the student-test-administrations with the creation of the administration.
              Until then, this will work, but we all need to be aware that this should be HIGHLY avoided where we can.
            */
            if (pickTeamsModel) {
                return pickTeamsModel;
            } else {
                return Ember.RSVP.hash({
                    quiz: Ember.get(this.modelFor('teacher.start-quiz'), 'quiz'),
                    administration: administration,
                    studentTestAdministrations: administration.get('studentTestAdministrations'),
                    allColors: this.store.peekAll('team-color'),
                    teamA: this.store.createRecord('team', {
                        teamColor: this.store.peekRecord('team-color', 2),
                        studentTestAdministrations: teamStudents[0],
                        name: 'Team Blue',
                        administration: administration
                    }),
                    teamB: this.store.createRecord('team', {
                        teamColor: this.store.peekRecord('team-color', 3),
                        studentTestAdministrations: teamStudents[1],
                        name: 'Team Green',
                        administration: administration
                    }),
                    colors: Ember.computed('allColors.[]', 'teamA.teamColor.id', 'teamB.teamColor.id', function () {
                        var teams = [Ember.get(this, 'teamA'), Ember.get(this, 'teamB')];
                        var allColors = this.allColors.slice();
                        var usedColors = teams.reduce(function (previous, team) {
                            return previous.concat(team.get('teamColor'));
                        }, []);

                        return allColors.reduce(function (previous, color) {
                            if (usedColors.any(function (teamColor) {
                                return teamColor.get('id') == color.get('id');
                            })) {
                                return previous;
                            } else {
                                return previous.concat(color);
                            }
                        }, []);
                    })
                });
            }
        },
        deactivate: function deactivate() {
            Ember.set(this, 'currentModel', null);
        },


        actions: {
            studentChangeTeam: function studentChangeTeam(student, currentTeam) {
                var teams = [Ember.get(this, 'currentModel.teamA'), Ember.get(this, 'currentModel.teamB')];
                var studentTestAdministration = this.get('currentModel.studentTestAdministrations').find(function (sta) {
                    return sta.get('student.id') == student.get('id');
                });
                var newTeam = teams.find(function (team) {
                    return !team.get('studentTestAdministrations').any(function (sta) {
                        return sta.get('student.id') == student.get('id');
                    });
                });
                var teamMembersA = newTeam.get('studentTestAdministrations').toArray();

                studentTestAdministration.set('team', newTeam);
                currentTeam.get('studentTestAdministrations').removeObject(studentTestAdministration);
                teamMembersA.unshift(studentTestAdministration);
                newTeam.set('studentTestAdministrations', teamMembersA);
            },
            shuffleTeams: function shuffleTeams() {
                var teamStudents = this.shuffleTeams(this.get('currentModel.studentTestAdministrations'));
                Ember.set(Ember.get(this, 'currentModel.teamA'), 'studentTestAdministrations', teamStudents.objectAt(0));
                Ember.set(Ember.get(this, 'currentModel.teamB'), 'studentTestAdministrations', teamStudents.objectAt(1));
            },
            startQuiz: function startQuiz() {
                var _this = this;

                //make sure each team has atleast 1 student
                if (!(Ember.get(this, 'currentModel.teamA.studentTestAdministrations.length') > 0 && Ember.get(this, 'currentModel.teamB.studentTestAdministrations.length') > 0)) {
                    this.toggleProperty('currentModel.showAlert');
                    Ember.set(this, 'currentModel.alertMessage', 'Each team must have atleast one student!');
                    return;
                }

                Ember.set(this, 'currentModel.isProcessing', true);
                //get the administration, teams, studentTestAdminstrations
                var administration = Ember.get(this, 'currentModel.administration');
                var studentTestAdministrations = this.get('currentModel.studentTestAdministrations'),
                    teamA = Ember.get(this, 'currentModel.teamA');

                //set administration startTime
                administration.set('startTime', new Date());

                administration.save().then(function (admin) {
                    Ember.RSVP.Promise.all([Ember.get(_this, 'currentModel.teamA').save(), Ember.get(_this, 'currentModel.teamB').save()]).then(function (results) {
                        Ember.RSVP.Promise.all(studentTestAdministrations.reduce(function (promises, sta) {
                            //set the administrationId
                            sta.set('administrationId', administration.get('id'));
                            sta.set('teamId', sta.get('team.id'));
                            //save
                            promises.pushObject(sta.save());
                            return promises;
                        }, [])).then(function () {
                            _this.transitionTo('teacher.quiz.game-mode', administration.get('id'));
                        });
                    });
                });
            }
        },

        shuffleTeams: function shuffleTeams(studentTestAdministrations) {
            var team1 = [];
            var team2 = [];
            var students = studentTestAdministrations.slice();

            while (students.get('length') > 0) {
                var index = Math.abs(Math.ceil(Math.random() * students.get('length') - 1));
                if (team1.length <= team2.length) {
                    students.objectAt(index).set('team', team1);
                    team1.pushObject(students.objectAt(index));
                } else {
                    students.objectAt(index).set('team', team2);
                    team2.pushObject(students.objectAt(index));
                }

                students.removeObject(students.objectAt(index));
            }

            return [team1, team2];
        }
    });
});
define('ohs/models/mission-control/assessment-item-question', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var belongsTo = _emberData.default.belongsTo,
        attr = _emberData.default.attr;
    exports.default = _emberData.default.Model.extend({
        order: attr('number'),

        assessmentItem: belongsTo('mission-control/assessment-item'),
        question: belongsTo('mission-control/question')
    });
});
define('ohs/components/context-menu-item', ['exports', 'ember-context-menu/components/context-menu-item'], function (exports, _contextMenuItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _contextMenuItem.default;
    }
  });
});
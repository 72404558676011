define('ohs/routes/error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Error',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', false);
      //this.controllerFor('application').set('student', true);
    },
    session: Ember.inject.service('session'),

    model: function model() {
      var errorStatus = this.controllerFor('application').get('errorStatusCode');
      var errorDetail = this.controllerFor('application').get('errorDetailMessage');
      var errorHeader = '';

      if (errorStatus == 500) //If server not found
        {
          errorHeader = 'Server not found!';
          errorDetail = 'Your connection to the server has been lost. Please try again.';
        } else if (errorStatus == 0) //If no internet connection
        {
          errorHeader = 'No internet connection!';
          errorDetail = 'Please check your internet connection and try again.';
        } else if (errorStatus == 403) //If don\'t have access to the request
        {
          errorHeader = 'Access is denied!';
        }

      this.controllerFor('application').set('errorStatusCode', '');
      this.controllerFor('application').set('errorDetailMessage', '');

      return Ember.RSVP.hash({
        errorStatusCode: errorStatus,
        errorDetailMessage: errorDetail,
        errorHeaderText: errorHeader
      });
    },


    actions: {
      refresh: function refresh() {
        var breadcrumbs = this.get('session').get('breadcrumbs');
        if (breadcrumbs.get('length') > 0) {
          var mostRecent = breadcrumbs[breadcrumbs.get('length') - 1];
          if (mostRecent.model) {
            this.transitionTo(mostRecent.route, mostRecent.model);
          } else {
            this.transitionTo(mostRecent.route);
          }
        } else {
          this.transitionTo('home');
        }
      }
    }

  });
});
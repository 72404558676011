define('ohs/components/rate-resource', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['rating-count'],
    currentRating: 0,
    allowRating: true,
    showCount: false,
    ratingCount: 0,

    actions: {

      rate: function rate(value) {
        if (this.get('allowRating')) {
          if (value > 0 && value <= 5) {
            this.sendAction('ratingAction', value);
          }
        }
      },

      setAnonymous: function setAnonymous() {
        if (this.get('isAnonymous')) {
          this.set('isAnonymous', false);
        } else {
          this.set('isAnonymous', true);
        }
        this.sendAction('anonymousAction', this.get('isAnonymous'));
      }

    }
  });
});
define('ohs/models/resource-assigned', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    resource: _emberData.default.belongsTo('resource'),
    student: _emberData.default.belongsTo('student'),
    viewed: _emberData.default.attr('boolean', { defaultValue: false }),
    assignedDate: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    user: _emberData.default.belongsTo('user'), //sent by user
    viewedDate: _emberData.default.attr('date'),

    sentDate: Ember.computed('assignedDate', function () {
      var date = this.get('assignedDate').toLocaleDateString();
      var today = new Date();
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (today.toLocaleDateString() == date) return 'Today';else if (yesterday.toLocaleDateString() == date) {
        return 'Yesterday';
      } else return date;
    }),

    viewedDateFormatted: Ember.computed('viewedDate', function () {
      if (this.get('viewedDate')) {
        var date = this.get('viewedDate').toLocaleDateString();
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (today.toLocaleDateString() == date) return 'Today';else if (yesterday.toLocaleDateString() == date) {
          return 'Yesterday';
        } else {
          return date;
        }
      }
    }),

    viewedForSorting: Ember.computed('viewed', function () {
      if (this.get('viewed')) {
        return 0;
      } else {
        return 1;
      }
    })

  });
});
define('ohs/components/checkable-student', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['selectable-student-item'],

        //fields
        student: null
    });
});
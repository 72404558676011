define('ohs/routes/teacher/quiz/game-mode', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return Ember.RSVP.hash({
                colors: this.get('store').findAll('team-color'),
                administration: Ember.get(this.modelFor('teacher.quiz'), 'administration'),
                teamA: Ember.computed('administration.teamA', function () {
                    return Ember.get(this, 'administration.teamA');
                }),

                teamB: Ember.computed('administration.teamB', function () {
                    return Ember.get(this, 'administration.teamB');
                })
            });
        },
        afterModel: function afterModel() {
            var administration = Ember.get(this.modelFor('teacher.quiz'), 'administration');
            if (Ember.get(administration, 'isComplete')) {
                this.transitionTo('teacher.quiz.game-mode.game-results', Ember.get(administration, 'id'));
            } else if (Ember.get(administration, 'isOpen')) {
                this.transitionTo('teacher.quiz.game-mode.in-progress.classroom-view', Ember.get(administration, 'id'));
            } else {
                this.transitionTo('teacher.quiz.game-mode.lobby', Ember.get(administration, 'id'));
            }
        }
    });
});
define('ohs/routes/reset-password', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Reset Password',
    model: function model(params) {
      return Ember.RSVP.hash({
        resetToken: params.resetToken,
        userId: params.userId,
        newPassword: '',
        confirmPassword: '',
        userName: '',
        validationErrors: '',
        successMessages: '',
        tick: 1,
        syncPassword: 1
      });
    },


    actions: {
      resetPassword: function resetPassword() {
        var self = this;
        document.activeElement.blur();

        if (!self.get('currentModel.invalidAttributes')) {
          self.set('currentModel.invalidAttributes', new Array());
        }

        if (self.get('currentModel.invalidAttributes.length') == 0) {
          self.set('currentModel.isProcessing', true);

          //increment the tick so that validation is done for each field
          self.set('currentModel.tick', self.get('currentModel.tick') + 1);

          setTimeout(function () {
            if (self.get('currentModel.invalidAttributes.length') > 0) {
              //user needs to fix validation errorMessage
              self.set('currentModel.isProcessing', false);
            } else {
              Ember.$.getJSON(self.store.adapterFor('application').get('host') + '/ResetPassword/?resetToken=' + encodeURIComponent(self.currentModel.resetToken) + '&userId=' + encodeURIComponent(self.currentModel.userId) + '&newPassword=' + encodeURIComponent(self.currentModel.newPassword)).done(function (response) {
                //self.set("currentModel.successMessages", 'Your password has been reset!');
                self.set("currentModel.userName", response.UserName);
                self.set("currentModel.validationErrors", '');
                self.set('currentModel.showConfirm', true);
              }).fail(function (response) {
                self.set("currentModel.successMessages", '');
                if (response.responseText.includes('Invalid token')) {
                  self.set("currentModel.validationErrors", 'Your password reset email is expired');
                } else {
                  self.set("currentModel.validationErrors", response.responseText);
                }
              });
            }
          }, 250);
        }
      },

      passwordBack: function passwordBack() {
        this.transitionTo('login');
      },

      hideConfirm: function hideConfirm() {
        this.set('currentModel.showConfirm', false);
        this.transitionTo('login');
      }

    }

  });
});
define('ohs/controllers/teacher/quiz-detail-notgiven', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    changeMade: false,

    changeClasses: function () {
      return this.get('changeMade') ? 'made-change' : '';
    }.property('changeMade'),

    actions: {
      showChange: function showChange() {
        this.set('changeMade', true);
      },
      hideChange: function hideChange() {
        this.set('changeMade', false);
      }
    }
  });
});
define('ohs/routes/standard-coverage-report', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
        titleToken: 'Student Standard Mastery',
        setupController: function setupController(controller, model) {
            //call _super for default behavior
            this._super(controller, model);
            //custom setup
            this.controllerFor('application').set('showSecondaryNav', true);
            this.controllerFor('application').set('teacher', true);
        },
        beforeModel: function beforeModel() {
            this._super.apply(this, arguments);
        },
        model: function model() {
            var _this = this;

            var userId = this.get('session').session.content.authenticated["userId"];
            var account = this.store.peekAll('account');
            var accountId = account.objectAt(0).id;
            var host = this.store.adapterFor('application').get('host');
            // return Ember.RSVP.hash({
            //   accountId:accountId,
            //   usageTrackerDetails: this.store.query('ReportingCategory',{accountId: accountId, reload: true}),
            // });
            return Ember.$.getJSON(host + '/ReportingCategories?accountId=' + encodeURIComponent(accountId)).then(function (response) {
                //model
                return Ember.RSVP.hash({
                    accountId: accountId,
                    reportingCategories: response,
                    grades: _this.store.query('grade', { accountId: accountId }),
                    selectedReportingCategory: null,
                    selectedGradeLevel: null,
                    didReportRun: false,
                    isLoading: false,
                    tick: 1,
                    showAlert: false,
                    alertMessage: '',
                    //holds the report data
                    reportData: [],
                    teacherOrGradeOptions: [{
                        text: "Teacher",
                        id: 1
                    }, {
                        text: "Grade Level",
                        id: 2
                    }],
                    curOption: "Teacher",
                    selectedOption: 1,

                    //computed properties
                    noQuizzesGiven: Ember.computed('reportingCategories.[]', function () {
                        if (_this.get('currentModel.reportingCategories').length <= 0) {
                            return true;
                        }
                    }),
                    //filtered lists
                    //grades filtered based on reporting categories given
                    filteredGrades: Ember.computed('grades.[]', 'reportingCategories.[]', function () {
                        return _this.get('currentModel.grades').filter(function (a) {
                            return _this.get('currentModel.reportingCategories').some(function (b) {
                                return b.gradeLevelId == a.id;
                            });
                        });
                    }),

                    //reporting categories filtered by selected grade level
                    filteredCategories: Ember.computed('selectedGradeLevel', function () {
                        return _this.get('currentModel.reportingCategories').reduce(function (a, b) {
                            var previous = a;

                            //this is incase they don't select a grade level, to show all the possible reporting categories
                            if (b.gradeLevelId == _this.get('currentModel.selectedGradeLevel') || !_this.get('currentModel.selectedGradeLevel')) {
                                previous.pushObject({
                                    id: b.id,
                                    orderId: b.standardOrderId,
                                    text: b.standardCode
                                });
                            }

                            return previous;
                        }, []).sort(function (a, b) {
                            if (a.orderId > b.orderId) {
                                return 1;
                            } else if (a.orderId < b.orderId) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });
                    })

                });
            },
            //fail
            function (xhr, status, error) {
                console.log(xhr.responseText);
            });
        },
        afterModel: function afterModel(model) {
            this._super(model, 'Standard Coverage Report', false, true);
        },
        validateFields: function validateFields(successCallback, failCallback) {
            var self = this;

            if (!this.get('currentModel.invalidAttributes')) {
                this.set('currentModel.invalidAttributes', []);
            }

            if (this.get('currentModel.invalidAttributes.length') == 0) {
                document.activeElement.blur();

                this.set('currentModel.tick', this.get('currentModel.tick') + 1);

                setTimeout(function () {
                    if (self.get('currentModel.invalidAttributes.length') > 0) {
                        failCallback();
                    } else {
                        successCallback();
                    }
                }, 1000);
            }
        },

        actions: {
            setTeacherOrGradeLevel: function setTeacherOrGradeLevel(selectedValue) {
                this.set('currentModel.curOption', this.get('currentModel.teacherOrGradeOptions')[selectedValue - 1].text);
                this.set('currentModel.selectedOption', selectedValue);
            },
            setGradeLevel: function setGradeLevel(gradeLevel) {
                this.set('currentModel.selectedGradeLevel', gradeLevel);
                this.set('currentModel.selectedReportingCategory', null);
            },

            setReportingCategory: function setReportingCategory(category) {
                this.set('currentModel.selectedReportingCategory', category);
            },

            hideAlert: function hideAlert() {
                this.set('currentModel.showAlert', false);
            },

            goBack: function goBack() {
                this.set('currentModel.didReportRun', false);
                this.set('currentModel.reportData', null);
            },

            goToQuizzes: function goToQuizzes() {
                this.transitionTo('teacher-quizzes');
            },

            runReport: function runReport() {
                var _this2 = this;

                this.validateFields(function () {
                    _this2.set('currentModel.isLoading', true);
                    var host = _this2.store.adapterFor('application').get('host');
                    if (_this2.get('currentModel.curOption') == 'Teacher') {
                        Ember.$.ajax({
                            url: host + '/teachers',
                            data: {
                                accountId: _this2.get('currentModel.accountId'),
                                standardId: _this2.get('currentModel.selectedReportingCategory')
                            },
                            type: 'Get'
                        }).done(function (response) {
                            _this2.set('currentModel.reportData', response);
                            _this2.set('currentModel.didReportRun', true);
                            _this2.set('currentModel.isLoading', false);
                        }).fail(function (xhr, status, error) {
                            _this2.set('currentModel.showAlert', true);
                            _this2.set('currentModel.isLoading', false);
                            _this2.set('currentModel.alertMessage', 'Oops! No results were found under that search, Please try again.');
                        });
                    } else {
                        Ember.$.ajax({
                            url: host + '/report/studentStandardMastery',
                            data: {
                                accountId: _this2.get('currentModel.accountId'),
                                groupIds: [],
                                studentIds: [],
                                isAllStudentsSelected: true,
                                reportingCategory: _this2.get('currentModel.selectedReportingCategory')
                            },
                            type: 'Get'
                        }).done(function (response) {
                            _this2.set('currentModel.reportData', response);
                            _this2.set('currentModel.didReportRun', true);
                            _this2.set('currentModel.isLoading', false);
                        }).fail(function (xhr, status, error) {
                            _this2.set('currentModel.showAlert', true);
                            _this2.set('currentModel.isLoading', false);
                            _this2.set('currentModel.alertMessage', 'Oops! No results were found under that search, Please try again.');
                        });
                    }
                }, function () {
                    _this2.set('currentModel.isLoading', false);
                });
            }
        }
    });
});
define('ohs/components/student-trophy-case', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        teacher: false,
        parent: false,
        student: false
    });
});
define('ohs/routes/reset-user-password', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset'], function (exports, _authenticatedRouteMixin, _reset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, {
    titleToken: 'Reset Password',
    lastVisitedRoute: null,
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('parent_many_children', true);

      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
        }
      }
    },

    beforeModel: function beforeModel() {
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (!roles) {
          this.transitionTo('home');
        }
      }

      //Find the previous route and redirect to it after completing the task
      var previousRoutes = this.router.router.currentHandlerInfos;
      var previousRoute = previousRoutes && previousRoutes.pop();
      if (previousRoute) {
        this.set('lastVisitedRoute', previousRoute.name);
      }
    },

    model: function model(params) {
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
      }
      return Ember.RSVP.hash({
        userId: params.userId,
        newPassword: '',
        confirmPassword: '',
        tick: 1,
        syncPassword: 1,
        role: roles
      });
    },


    actions: {
      resetPassword: function resetPassword() {
        document.activeElement.blur();

        if (!this.get('currentModel.invalidAttributes')) {
          this.set('currentModel.invalidAttributes', new Array());
        }
        var self = this;

        if (this.get('currentModel.invalidAttributes.length') == 0) {
          this.set('currentModel.isProcessing', true);

          //increment the tick so that validation is done for each field
          this.set('currentModel.tick', this.get('currentModel.tick') + 1);

          setTimeout(function () {
            if (self.get('currentModel.invalidAttributes.length') > 0) {
              //user needs to fix validation errorMessage
              self.set('currentModel.isProcessing', false);
            } else {
              Ember.$.getJSON(self.store.adapterFor('application').get('host') + '/ResetPassword/?userId=' + encodeURIComponent(self.currentModel.userId) + '&newPassword=' + encodeURIComponent(self.currentModel.newPassword)).done(function (response) {
                //While resetting password, the admin will enter a temporary password and we will set the ForcePasswordChange flag to true.
                var teacher = self.store.peekRecord('teacher', self.get('currentModel.userId'));
                if (teacher) {
                  teacher.set('forcePasswordChange', true);
                  teacher.save();
                } else {
                  //Added to reset password of student.
                  var student = self.store.peekRecord('student', self.get('currentModel.userId'));
                  student.set('forcePasswordChange', true);
                  student.save();
                }
                self.set("currentModel.validationErrors", '');
                self.set('currentModel.showConfirm', true);
              }).fail(function (response) {
                self.set("currentModel.validationErrors", response.responseText);
                self.set('currentModel.isProcessing', false);
              });
            }
          }, 250);
        }
      },

      hideConfirm: function hideConfirm() {
        this.set('currentModel.showConfirm', false);
        if (this.get('lastVisitedRoute')) {
          this.transitionTo(this.get('lastVisitedRoute'), this.get('currentModel.userId'));
        } else {
          if (this.get('currentModel.role') == "Teacher") {
            this.transitionTo('manage-teacher-profile', this.get('currentModel.userId'));
          } else if (this.get('currentModel.role') == "Student") {
            this.transitionTo('manage-student-profile', this.get('currentModel.userId'));
          } else {
            this.transitionTo('home');
          }
        }
      },

      cancel: function cancel() {
        if (this.get('lastVisitedRoute')) {
          this.transitionTo(this.get('lastVisitedRoute'), this.get('currentModel.userId'));
        } else {
          if (this.get('currentModel.role').includes("Teacher")) {
            this.transitionTo('manage-teacher-profile', this.get('currentModel.userId'));
          } else if (this.get('currentModel.role').includes("Student")) {
            this.transitionTo('manage-student-profile', this.get('currentModel.userId'));
          } else {
            this.transitionTo('home');
          }
        }
      }
    }
  });
});
define('ohs/mixins/breadcrumb-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    afterModel: function afterModel(model, bcText, routeParam, clearBreadcrumbs) {

      var m = null;
      if (routeParam) {
        m = routeParam;
      }
      var bc = { route: this.get('routeName'), text: bcText, model: m };

      if (clearBreadcrumbs) {
        this.get('session').set('breadcrumbs', new Array());
        this.get('session').get('breadcrumbs').pushObject(bc);
      } else {
        //Check to see if this already exists
        var crumbs = this.get('session.breadcrumbs');
        var index = -1;
        for (var i = 0; i < crumbs.length; i++) {
          if (Object.prototype.toString.call(bc.model) === '[object Array]') {
            if (crumbs[i].route == bc.route && crumbs[i].text == bc.text) {
              index = i;
              break;
            }
          } else {
            if (crumbs[i].route == bc.route && crumbs[i].text == bc.text && crumbs[i].model == bc.model) {
              index = i;
              break;
            }
          }
        }
        if (index >= 0) {
          this.get('session').set('breadcrumbs', crumbs.slice(0, index + 1));
        } else {
          this.get('session').get('breadcrumbs').pushObject(bc);
        }
      }
    }
  });
});
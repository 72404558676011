define('ohs/routes/teacher/teacher-dashboard', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, {
    titleToken: 'Teacher Dashboard',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    signalR: Ember.inject.service('signal-r'),

    model: function model() {
      //initialize signal-R
      this.get('signalR').administrationId = 0;
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      return Ember.RSVP.hash({
        teacher: this.store.findRecord('teacher', userId),

        latestResults: this.store.query('administration', { filter: 'dashboard' }),

        openAdministrations: this.store.query('administration', { isComplete: false }),

        firstTwoOpen: Ember.computed('openAdministrations.@each.startDate', function () {
          return this.openAdministrations.sortBy('startTime').reverse().slice(0, 2);
        }),

        //  openAdministrations: Ember.computed('administrations.@each.isComplete',function() {
        //     return this.administrations.filterBy('isComplete',false);
        //  }),
        //
        //  latestResults: Ember.computed('administrations.@each.isComplete','administrations.@each.endTime',function() {
        //    return this.administrations.filterBy('isComplete',true).sortBy('endTime').reverse().slice(0,2);
        //  }),

        bookmarks: this.store.findAll('resourceBookmark'),

        latestBookmarks: Ember.computed('bookmarks.@each.bookmarkedDate', function () {
          return this.bookmarks.sortBy('bookmarkedDate').reverse().slice(0, 2);
        }),

        classResources: this.store.findAll('class-resource'),

        latestClassResources: Ember.computed('classResources.@each.dateCreated', function () {
          return this.classResources.sortBy('dateCreated').reverse().slice(0, 2);
        })

        //quiz: this.store.findAll('quiz'),

        //  inProgress: this.store.query('administration',{isComplete:false}),
        //
        //  inProgressOrdered: Ember.computed('inProgress.@each.id',function() {
        //    return this.inProgress.sortBy('id').reverse();
        //  }),
        //
        //  latestActivity: this.store.findAll('latest-activity-teacher-quiz'),
        //
        //  latestActivityDisplay: Ember.computed('latestActivity.@each.id',function() {
        //    return this.latestActivity.sortBy('date').reverse().slice(0,3);
        //  }),
        //
        //  latestTeacherResourceActivity: this.store.findAll('latest-activity-teacher-Resource'),
        //
        //  latestTeacherResourceActivityDisplay: Ember.computed('latestTeacherResourceActivity.@each.id',function() {
        //    return this.latestTeacherResourceActivity.sortBy('date').reverse().slice(0,3);
        //  }),
        //
        //  user: this.store.findRecord('user',userId),
        //
        //  recommended: this.store.findAll('resource-recommended'),
        //
        //  newRecommendedCount: Ember.computed('recommended.@each.resourceStatus',function() {
        //    var uniqueStudents = [];
        //    this.recommended.filterBy('resourceStatus','New').forEach(function(item) {
        //      if (!uniqueStudents.isAny('student.id',item.get('student').get('id'))) {
        //        uniqueStudents.pushObject(item);
        //      }
        //    })
        //    return uniqueStudents.length;
        //  }),
        //
        //  showResourceSection: Ember.computed('recommended.@each.resourceStatus','latestTeacherResourceActivity.@each.id','latestActivity.@each.id' ,function() {
        //    return this.recommended.get('length') > 0 || this.latestTeacherResourceActivity.get('length') > 0 || this.latestActivity.get('length') > 0;
        //  }),
        //
        //  trophies: this.store.findAll('trophy'),
        //
        //  latestTrophies: Ember.computed('trophies.@each.mostRecentAwardedDate',function() {
        //    return this.trophies.sortBy('mostRecentAwardedDate').reverse().slice(0,3);
        //  })

      });
    },

    activate: function activate() {
      var self = this;
      window.addEventListener("resize", function (e) {
        self.resizeCanvas();
      }, false);
    },

    resizeCanvas: function resizeCanvas() {
      $('.trophy-img').each(function () {
        $(this).css('height', $(this).width() * $(this).css('min-height').replace("px", ""));
      });
    },

    actions: {
      startCreateNewQuiz: function startCreateNewQuiz() {
        this.transitionTo('create-quiz-1', 'newQuiz');
      },

      searchResources: function searchResources() {
        this.transitionTo('teacher.resource-search-start');
      },

      drillResource: function drillResource(id) {
        this.transitionTo('view-class-resource', id);
      }

    }

  });
});
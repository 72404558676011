define("ohs/components/sign-up", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    showModal: false,
    tick: 1,

    honorificOptions: [{
      text: "Mrs",
      id: "Mrs"
    }, {
      text: "Mr",
      id: "Mr"
    }, {
      text: "Ms",
      id: "Ms"
    }, {
      text: "Miss",
      id: "Miss"
    }, {
      text: "Dr",
      id: "Dr"
    }],

    onEmailValidation: function onEmailValidation() {},

    onSignIn: function onSignIn(googleUser) {
      var profile = googleUser.getBasicProfile();
      console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
      console.log('Name: ' + profile.getName());
      console.log('Image URL: ' + profile.getImageUrl());
      console.log('Email: ' + profile.getEmail());
    },

    didRender: function didRender() {
      if (typeof gapi != "undefined" && !gapi.auth2) {
        gapi.load('auth2', function () {
          gapi.auth2.init({
            client_id: '1063631977766-069kh3pu464pg7dp9snv75j40fikjub2.apps.googleusercontent.com',
            cookiepolicy: 'single_host_origin'
          });
        });
      }

      if (this.get('model.user.userRole') == 'Student') {
        this.set('model.accountType', null);
        this.set('model.signUpReady', true);
      }
    },

    actions: {
      pickTrial: function pickTrial() {
        this.set('model.accountType', 'Trial');
      },
      pickDistrict: function pickDistrict() {
        this.set('model.accountType', 'District');
      },
      setHonorific: function setHonorific(selectedValue) {
        this.get('model.user').set('honorific', selectedValue);
      },

      setGradeLevel: function setGradeLevel(selectedValue) {
        this.get('model.user').set('gradeLevelId', selectedValue);
      },

      setState: function setState(selectedValue) {
        var user = this.get('model.user');
        user.set('stateId', selectedValue);
        if (user.get('userRole') == "Teacher") {
          if (selectedValue == 39) {
            this.set('standardTypeRequired', true);
          } else {
            this.set('standardTypeRequired', false);
            user.set('standardTypeId', 2011);
          }
        } else {
          user.set('standardTypeId', null);
        }
      },

      setStandardType: function setStandardType(selectedValue) {
        this.get('model.user').set('standardTypeId', selectedValue);
      },

      hideConfirm: function hideConfirm() {
        this.set('showConfirm', false);
      },

      hideAlert: function hideAlert() {
        this.set('showAlert', false);
      },

      cancelUser: function cancelUser() {
        var self = this;
        self.set('model.isGoogleSignUp', false);
        self.set('model.isSchoologySignUp', false);
        self.set('model.typeChosen', false);
        self.set('standardTypeRequired', false);

        // Assign user model to user variable.
        var user = self.get('model.user');
        user.set('invalidAttributes', new Array());
        user.set('userRole', '');
        user.set('isFreeTrial', false);
        user.set('googleId', '');
        user.set('email', null);
        self.set('disableGoogleColumns', false);

        //required to reset the path for teachers
        self.set('model.signUpReady', false);
        self.set('model.firstEmail', false);
        self.set('model.accountType', null);

        self.set('isProcessing', null);
        //user.rollbackAttributes();

        self.sendAction('cancelAction');
      },

      purchaseSubscription: function purchaseSubscription() {
        this.sendAction('purchaseSubscription');
      },

      signUp: function signUp() {
        var self = this;
        // Assign user model to user variable.
        var user = self.get('model.user');
        if (!user.get('invalidAttributes')) {
          user.set('invalidAttributes', new Array());
        }
        if (user.get('invalidAttributes.length') == 0) {
          self.set('isProcessing', true);

          //increment the tick so that validation is done for each field
          self.set('tick', self.get('tick') + 1);

          //delay for 2 seconds to allow validations to happen
          setTimeout(function () {
            if (user.get('invalidAttributes.length') > 0) {
              //user needs to fix validation errorMessage
              self.set('isProcessing', false);
            } else {
              self.set('isProcessing', false);
              //save the user
              self.saveUser();
            }
          }, 1000);
        }
      },

      continueRegistration: function continueRegistration() {
        var self = this;
        var email = this.get('model.user.email');
        var adapter = this.get('store').adapterFor('application');

        var user = self.get('model.user');
        if (!user.get('invalidAttributes')) {
          user.set('invalidAttributes', new Array());
        }

        if (user.get('invalidAttributes.length') == 0) {
          self.set('isProcessing', true);

          //increment the tick so that validation is done for each field
          self.set('tick', self.get('tick') + 1);

          //delay for 2 seconds to allow validations to happen
          setTimeout(function () {
            if (user.get('invalidAttributes.length') > 0) {
              //user needs to fix validation errorMessage
              self.set('isProcessing', null);
            } else {
              var isValid = true;

              //Verify the email is correct
              Ember.$.getJSON(adapter.get('host') + "/ValidateTeacherEmail?email=" + email).done(function (response) {
                isValid = true;
              }).fail(function (response) {
                isValid = false;
              }).always(function () {
                if (self.get('model.accountType') == 'Trial') {
                  //logic for free trial sign-ups
                  Ember.$.getJSON(adapter.get('host') + "/subscription?teacherEmail=" + email).done(function (response) {

                    if (response) {
                      //get first subscription
                      var subscription = response.objectAt(0);

                      if (isValid) {
                        if (subscription.isGrantFunded) {
                          self.set('isProcessing', null);
                          self.set('alertMessage', 'Congratulations! Thanks to funding from local foundations your district can enjoy Kandoolu for free through the 2019 - 2020 school year.');
                          self.set('showAlert', true);
                          self.set('model.signUpReady', true);
                          self.set('model.firstEmail', email);
                        } else if (subscription.isFreeTrial) {
                          self.set('isProcessing', null);
                          self.set('alertMessage', 'Congratulations! Your district is piloting Kandoolu.');
                          self.set('showAlert', true);
                          self.set('model.signUpReady', true);
                          self.set('model.firstEmail', email);
                        } else {
                          self.set('isProcessing', null);
                          self.set('alertMessage', 'Congratulations! Your district has already purchased a license for you.');
                          self.set('showAlert', true);
                          self.set('model.signUpReady', true);
                          self.set('model.firstEmail', email);
                        }
                      } else {
                        if (subscription.accountTypeId == 4) {
                          self.set('isProcessing', null);
                          self.set('alertMessage', 'You already have a district funded account.');
                          self.set('showAlert', true);
                        } else {
                          self.set('isProcessing', null);
                          self.set('showModal', true);
                          self.set('isProcessing', null);
                        }
                      }
                    }
                    //indicates no account
                    else {
                        if (isValid) {
                          self.set('isProcessing', null);
                          self.set('model.signUpReady', true);
                          self.set('model.firstEmail', email);
                        } else {
                          self.set('isProcessing', null);
                          self.set('alertMessage', 'This email is already in use!');
                          self.set('showAlert', true);
                        }
                      }
                  }).fail(function (response) {
                    self.set('isProcessing', null);
                    self.set('alertMessage', response.responseText);
                    self.set('showAlert', true);
                  });
                } else {
                  //logic for district purchased
                  Ember.$.getJSON(adapter.get('host') + "/subscription?teacherEmail=" + email).done(function (response) {
                    if (isValid) {
                      if (response) {
                        if (response.objectAt(0).accountTypeId == 4) {
                          self.set('isProcessing', null);
                          self.set('model.signUpReady', true);
                          self.set('model.firstEmail', email);
                        } else {
                          self.set('isProcessing', null);
                          self.set('alertMessage', 'It appears your district does not have an active subscription to Kandoolu.');
                          self.set('showAlert', true);
                        }
                      }
                      //indicates no account
                      else {
                          self.set('isProcessing', null);
                          self.set('alertMessage', 'It appears your district does not have an active subscription to Kandoolu.');
                          self.set('showAlert', true);
                        }
                    } else {
                      self.set('isProcessing', null);
                      self.set('alertMessage', 'It appears you already have a Kandoolu account.');
                      self.set('showAlert', true);
                    }
                  })
                  //indicates no active subscription
                  .fail(function (response) {
                    self.set('isProcessing', null);
                    self.set('alertMessage', 'It appears your district does not have an active subscription to Kandoolu.');
                    self.set('showAlert', true);
                  });
                }
              });
            }
          }, 1000);
        }
      },

      isGoogleIdAlreadyLinked: function isGoogleIdAlreadyLinked() {
        var self = this;
        self.set('isProcessing', true);
        var adapter = self.get('store').adapterFor('application');
        if (gapi.auth2 && gapi.auth2.getAuthInstance()) {
          gapi.auth2.getAuthInstance().signIn().then(function (googleUser) {
            var url = adapter.get('host') + '/isGoogleIdAlreadyLinked?idToken=' + googleUser.getAuthResponse().id_token;
            adapter.ajax(url, 'GET').then(function (response) {
              self.set('disableGoogleColumns', true);
              self.set('model.user.googleId', googleUser.getBasicProfile().getId());
              self.set('model.user.firstName', googleUser.getBasicProfile().getGivenName());
              self.set('model.user.lastName', googleUser.getBasicProfile().getFamilyName());
              self.set('model.user.email', googleUser.getBasicProfile().getEmail());
              self.set('model.user.googleEmail', googleUser.getBasicProfile().getEmail());
              self.set('model.isGoogleSignUp', true);
              self.set('model.typeChosen', true);

              if (self.get('model.user.userRole') == "Student") {
                self.set('model.user.isFreeTrial', false);

                self.set('isProcessing', false);
              } else {
                self.setFreeTrialFlag(self.get('model.user.email'));
              }
              self.set('model.user.invalidAttributes', new Array());
              //increment the tick so that validation is done for each field
              self.set('tick', self.get('tick') + 1);
            }, function (response) {
              self.set('showAlert', true);
              self.set('alertMessage', response.errors[0].detail);
            });
          });
        } else {
          self.set('showAlert', true);
          this.set('alertMessage', 'Something went wrong. Please refresh the page and try again.');
        }
      },

      isSchoologyIdAlreadyLinked: function isSchoologyIdAlreadyLinked() {
        var self = this;
        var adapter = self.get('store').adapterFor('application');

        //get a request token
        Ember.$.getJSON(this.get('store').adapterFor('application').get('host') + '/getSchoologyRequestTokens').done(function (response) {
          if (response.oauth_token) {
            document.cookie = "SRT=" + response.oauth_token;
            document.cookie = "SRTS=" + response.oauth_token_secret;
            window.location = "https://www.schoology.com/oauth/authorize?return_url=" + window.location.href.replace('/sign-up', '/schoology-sign-in/' + self.get('model.user.userRole')) + "&oauth_token=" + response.oauth_token;
          } else {
            self.set('showAlert', true);
            self.set('alertMessage', 'Error occurred. Please try again.');
          }
        }).fail(function (response) {
          self.set('showAlert', true);
          self.set('alertMessage', 'Error occurred. Please try again.');
        });
      }
    },

    saveUser: function saveUser() {
      var self = this;
      var user = self.get('model.user');

      if (user.get('userRole') == 'Teacher') {
        //For teachers users
        //For teachers email address is username
        user.set('userName', user.get('email'));
      }

      //validate model to ensure the linkingCode is correct
      if (!self.get('model.invalidAttributes')) {
        self.set('model.invalidAttributes', new Array());
      }

      if (self.get('model.invalidAttributes').get('length') == 0) {
        self.set('isProcessing', true);
        self.set('tick', self.get('tick') + 1);

        setTimeout(function () {
          if (self.get('model.invalidAttributes.length') > 0) {
            self.set('isProcessing', false);
          } else {
            user.set('avatar', 'Green/aliens_final_green-01.svg');
            user.save().then(function (u) {
              //if user is a student we need to join that student to the roster provided
              if (user.get('userRole') == 'Student') {
                Ember.$.getJSON(self.get('store').adapterFor('application').get('host') + '/students/BackDoorTeacherLink?userName=' + user.get('userName') + '&teacherCode=' + self.get('model.linkingCode')).done(function (response) {
                  self.sendAction('registerSuccess');
                }).fail(function (response) {
                  console.log('UserCreated but not joined to roster');
                  self.sendAction('registerSuccess');
                });
              }
              //if userRole is teacher just send action
              else {
                  self.sendAction('registerSuccess');
                }
            }, function (error) {
              self.set('isProcessing', false);
              self.set('showAlert', true);
              if (error && error.errors && error.errors.length > 0 && error.errors[0].detail) {
                self.set('alertMessage', error.errors[0].detail);
              } else if (error) {
                self.set('alertMessage', error.message);
              } else {
                self.set('alertMessage', 'There was a problem creating the user. Please check your internet connection and try again.');
              }
            });
          }
        }, 1000);
      }
    },
    setFreeTrialFlag: function setFreeTrialFlag(email) {
      if (email) {
        var self = this;
        Ember.$.getJSON(this.get('store').adapterFor('application').get('host') + '/ValidateTeacherEmail?email=' + encodeURIComponent(email)).done(function (response) {
          if (response) {
            if (!self.get('model.user.stateId')) self.set('model.user.stateId', response.stateId);
            if (!self.get('model.user.standardTypeId')) self.set('model.user.standardTypeId', response.standardTypeId);
            self.set('model.user.isFreeTrial', false);

            self.set('isProcessing', false);
          } else {
            self.set('model.user.isFreeTrial', true);
            self.set('isProcessing', false);
          }
        }).fail(function (error) {
          self.set('alertMessage', 'This email address is already in use');
        });
      } else {
        self.set('model.user.isFreeTrial', true);
        self.set('isProcessing', false);
      }
    }
  });
});
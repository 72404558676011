define('ohs/routes/create-quiz-5', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/tip-mixin'], function (exports, _authenticatedRouteMixin, _reset, _noNavigation, _teacherAuthorizeMixin, _tipMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _noNavigation.default, _teacherAuthorizeMixin.default, _tipMixin.default, {
    titleToken: function titleToken(model) {
      return model.get('quizName');
    },

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      return this.store.find('quiz', params.test_id);
    },


    afterModel: function afterModel(model) {
      var tip = this.store.peekRecord('tip', 12);
      if (tip) {
        model.showTip = true;
        model.tipText = tip.get('tipText');
      }
    },

    actions: {
      closeTip: function closeTip() {
        this.destroyTip(12);
        this.set('currentModel.showTip', false);
      },

      showTip: function showTip() {
        var self = this;
        var uid = this.get('session').session.content.authenticated["userId"];
        var userTip = this.store.createRecord('userTip', {
          userId: uid,
          tipId: 12
        });
        userTip.save().then(function () {
          var tip = self.store.peekRecord('tip', 12);
          if (tip) {
            self.set('currentModel.showTip', true);
            self.set('currentModel.tipText', tip.get('tipText'));
          }
        });
      }
    }
  });
});
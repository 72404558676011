define('ohs/routes/teacher/resource-search-start', ['exports', 'ohs/mixins/reset', 'ohs/mixins/authorized-route-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _reset, _authorizedRouteMixin, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authorizedRouteMixin.default, _breadcrumbMixin.default, {
    authorizedRoles: ['Tutor', 'Teacher'],
    titleToken: 'Search Resources',

    model: function model() {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      var store = Ember.get(this, 'store');

      return Ember.RSVP.hash({
        teacher: store.findRecord('user', userId),
        grades: store.query('grade', { gradeType: 'SearchResource' }),
        account: store.queryRecord('account', { userId: userId }),
        standards: store.findAll('standard'),
        standardType: sesObj.get('standardType'),
        peopleViews: [{
          text: "Students",
          id: 1
        }, {
          text: "Educators",
          id: 2
        }]
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Search Resources', false, true);
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      this.controllerFor('application').set('showSecondaryNav', true);

      var teacherGradeLevelId = Ember.get(model, 'teacher.gradeLevelId');

      // if the controller doesn't have a selected grade and the user does
      // use the users grade level id
      if (!Ember.get(controller, 'selectedGrade') && teacherGradeLevelId) {
        Ember.set(controller, 'selectedGrade', teacherGradeLevelId);
      }
      // if the controller doesn't have a selected grade
      // default it to 3
      else if (!Ember.get(controller, 'selectedGrade')) {
          Ember.set(controller, 'selectedGrade', 3);
        }

      // clear out the standard searches 
      Ember.set(controller, 'selectedReportingCategory', "");
      Ember.set(controller, 'selectedAssessmentAnchor', "");
      Ember.set(controller, 'selectedEligibleContent', []);
      Ember.set(controller, 'eligibleContentQueryString', "");
    }
  });
});
define('ohs/routes/parent/student-quiz-results', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/parent-authorize-mixin', 'ember-infinity/mixins/route', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _parentAuthorizeMixin, _route, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _route.default, _parentAuthorizeMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Previous Results',
    perPageParam: "perPage", // instead of "per_page"
    pageParam: "page", // instead of "page"
    totalPagesParam: "meta.totalPages", // instead of "meta.total_pages"

    signalR: Ember.inject.service('signal-r'),

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('parent_many_children', true);

      //Needed for infinity feature
      controller.set('studentTestAdministrations', model.studentTestAdministrations);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      var sesObj = this.get('session');
      var currentStudentId = sesObj.get('currentStudent');
      if (!currentStudentId) {
        this.transitionTo('parent-students');
      }
    },

    model: function model() {
      var sesObj = this.get('session');
      var currentStudentId = sesObj.get('currentStudent');
      //var userId = sesObj.session.content.authenticated["userId"];
      return Ember.RSVP.hash({
        studentTestAdministrations: this.infinityModel("studentTestAdministration", {
          studentUserId: currentStudentId,
          isOpen: false,
          perPage: 10,
          startingPage: 1,
          modelPath: 'controller.studentTestAdministrations' }),
        student: this.store.findRecord('student', currentStudentId)
      });
    },


    afterModel: function afterModel(model) {
      this._super(model, 'Quizzes', false, true);
      //Signal-R event subscription
      this.get('signalR').on('linkParentStudent', this, 'linkParentStudent');
    },

    actions: {
      drill: function drill(id) {
        this.transitionTo('student-results', id, '0');
      }
    },

    linkParentStudent: function linkParentStudent(userId) {
      var sesObj = this.get('session');
      if (!sesObj.get('currentStudent')) {
        sesObj.set('currentStudent', userId);
      }
      this.refresh();
    }
  });
});
define("ohs/snippets", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "button-dark-theme.scss": "@mixin button-dark-theme {\n  @include title-face;\n  transition:\n    background-color 0.4s ease-in-out,\n    color 0.4s ease-in-out,\n    border-color 0.4s ease-in-out;\n  border: 1px solid darken($link-color-subtle, 4%);\n  border-radius: 2px;\n  display: inline-block;\n  padding: 0.75em 1em;\n  text-decoration: none;\n  color: $link-color-alt-hover;\n  background-color: darken($link-color-subtle, 4%);\n  text-align: center;\n\n  &:hover,\n  &:focus {\n    text-decoration: none;\n    background-color: $background-color;\n    border-color: $background-color;\n    color: $link-color-alt-hover;\n  }\n}",
    "button-delete.scss": "@mixin button-delete {\n  @include button;\n  border-color: darken($incorrect-color, 5%);\n  background-color: $background-color;\n  color: darken($incorrect-color, 15%);\n\n  &:hover,\n  &:focus {\n    border-color: darken($incorrect-color, 15%);\n    color: darken($incorrect-color, 25%);\n    background-color: tint($incorrect-color, 90%);\n  }\n}",
    "button-disabled.scss": "@mixin button-disabled {\n  @include button-subtle;\n  color: $inactive-color;\n  &:hover,\n  &:focus {\n    background-color: $background-color;\n    color: $inactive-color;\n  }\n}",
    "button-highlight-dark-theme.scss": "@mixin button-highlight-dark-theme {\n  @include button-dark-theme;\n  border-color: $background-color;\n  background-color: $background-color;\n  color: $link-color-alt;\n\n  &:hover,\n  &:focus {\n    border-color: $link-color-subtle;\n    background-color: $link-color-subtle;\n    color: $link-color-alt-hover;\n  }\n}",
    "button-highlight.scss": "@mixin button-highlight {\n  @include button;\n  border-color: $link-color-alt;\n  background-color: $link-color-alt;\n  color: lighten($link-color-alt, 73%);\n\n  &:hover,\n  &:focus {\n    border-color: $link-color-alt-hover;\n    background-color: $link-color-alt-hover;\n    color: $background-color;\n  }\n\n  &.large {\n    padding-left: 2em;\n    padding-right: 2em;\n\n    @include breakpoint($breakpoint-l){\n      padding-left: 4em;\n      padding-right: 4em;\n    }\n  }\n}",
    "button-link.scss": "@mixin button-link {\n  @include reset-button;\n  @include link;\n}",
    "button-reverse.scss": "@mixin button-reverse {\n  @include button;\n  background-color: $background-color;\n  border-color: $background-color;\n  color: $link-color-alt;\n  &:hover,\n  &:focus {\n    color: $link-color-alt-hover;\n    background-color: lighten($accent-color-alt, 15%);\n    border-color: lighten($accent-color-alt, 15%);\n  }\n}",
    "button-subtle-dark-theme.scss": "@mixin button-subtle-dark-theme {\n  @include button-dark-theme;\n  background-color: lighten($link-color-med, 10%);\n  border-color: lighten($link-color-med, 10%);\n  color: $background-color;\n  &:hover,\n  &:focus {\n    background-color: lighten($link-color-med, 15%);\n    border-color: $link-color-med;\n    color: $background-color;\n  }\n}",
    "button-subtle.scss": "@mixin button-subtle {\n  @include button;\n  background-color: $background-color;\n  border-color: $border-color;\n  &:hover,\n  &:focus {\n    background-color: lighten($accent-color, 25%);\n    border-color: $border-color;\n  }\n}",
    "button.scss": "@mixin button {\n  @include title-face;\n  transition:\n    background-color 0.4s ease-in-out,\n    color 0.4s ease-in-out,\n    border-color 0.4s ease-in-out;\n  border: 1px solid lighten($accent-color-alt, 10%);\n  border-radius: 2px;\n  display: inline-block;\n  padding: 0.75em 1em;\n  text-decoration: none;\n  color: $link-color-alt;\n  background-color: lighten($accent-color-alt, 10%);\n  text-align: center;\n  cursor: pointer;\n\n  &:hover,\n  &:focus {\n    outline: 0;\n    text-decoration: none;\n    background-color: $accent-color-alt;\n    border-color: $accent-color-alt;\n    color: $link-color-alt-hover;\n  }\n}",
    "column-12.scss": ".column-12 {\n  @include column(12);\n}",
    "column-2.scss": ".column-2 {\n  @include column(2);\n  @include last-col6;\n}",
    "column-3.scss": ".column-3 {\n  @include column(3);\n  @include last-col4;\n}",
    "column-4.scss": ".column-4 {\n  @include column(4);\n  @include last-col3;\n}",
    "column-5-7.scss": ".column-5-7 {\n  @include column(5);\n}\n\n.column-7-5 {\n  @include column(7);\n  @include last-col2;\n}",
    "column-6.scss": ".column-6 {\n  @include column(6);\n  @include last-col2;\n}",
    "column-8-4.scss": ".column-8-4 {\n  @include column(8);\n}\n\n.column-4-8 {\n  @include column(4);\n  @include last-col2;\n}",
    "column-9-3.scss": ".column-9-3 {\n  @include column(9);\n}\n\n.column-3-9 {\n  @include column(3);\n  @include last-col2;\n}",
    "divider-none.scss": "@mixin divider-none {\n  padding: 0;\n  border: 0 none;\n  margin: 0;\n}",
    "header-button.scss": "@mixin header-button {\n  border: 1px $link-color-med solid;\n  border-radius: 2px;\n  color: $background-color;\n  background-color: $link-color-alt-hover;\n  &:hover,\n  &:focus {\n    color: $background-color;\n    outline: 0;\n    background-color: darken($link-color-alt, 5%);\n    border-color: $link-color-med;\n  }\n  &:active {\n    border-color: $background-color;\n  }\n}",
    "heading-1.scss": "@mixin heading-1 {\n  @include heading;\n  font-size: 2.5em;\n}",
    "heading-2.scss": "@mixin heading-2 {\n  @include heading;\n  font-size: 1.8em;\n}",
    "heading-3.scss": "@mixin heading-3 {\n  @include heading;\n  font-size: 1.4em;\n}",
    "heading-4.scss": "@mixin heading-4 {\n  @include heading;\n  font-size: 1.2em;\n}",
    "heading-5.scss": "@mixin heading-5 {\n  @include heading;\n  font-size: 1em;\n}",
    "heading-6.scss": "@mixin heading-6 {\n  @include heading;\n  font-size: 0.875em;\n}",
    "heading-link.scss": "@mixin heading-link {\n  color: $heading-color;\n  text-decoration: none;\n\n  &:hover, &:focus {\n    color: darken($heading-color, 15%);\n    text-decoration: underline;\n  }\n}",
    "link-alt.scss": "@mixin link-alt {\n  @include link;\n  color: $link-color-alt;\n\n  &:hover, &:focus {\n    color: $link-color-alt-hover;\n  }\n}",
    "link-dark-theme.scss": "@mixin link-dark-theme {\n  transition: color 0.4s ease-in-out;\n  color: $link-color-subtle;\n\n  &:hover, &:focus {\n    color: $background-color;\n    text-decoration: none;\n  }\n}",
    "link-delete.scss": "@mixin link-delete {\n  transition: color 0.4s ease-in-out;\n  color: darken($incorrect-color,5%);\n  text-decoration: underline;\n\n  &:hover, &:focus {\n    color: darken($incorrect-color,15%);\n    text-decoration: underline;\n  }\n}",
    "link-reverse-subtle.scss": "@mixin link-reverse-subtle {\n  @include link;\n  color: darken($link-color-subtle, 30%);\n  text-decoration: none;\n\n  &:hover, &:focus {\n    color: darken($link-color-subtle, 15%);\n    text-decoration: none;\n  }\n}",
    "link-reverse.scss": "@mixin link-reverse {\n  transition: color 0.4s ease-in-out;\n  color: $background-color-subtle;\n\n  &:hover, &:focus {\n    color: $background-color;\n    text-decoration: none;\n  }\n}",
    "link-subtle.scss": "@mixin link-subtle {\n  @include reset-button;\n  @include link;\n  color: $text-color-subtle;\n\n  &:hover, &:focus {\n    color: $text-color;\n  }\n}",
    "link.scss": "@mixin link {\n  transition: color 0.4s ease-in-out;\n  color: $link-color;\n  text-decoration: underline;\n\n  &:hover, &:focus {\n    color: $link-color-hover;\n    text-decoration: underline;\n  }\n}",
    "reset-button.scss": "@mixin reset-button {\n  border: 0 none;\n  padding: 0;\n  background-color: transparent;\n  &:hover, &:focus {\n    background-color: transparent;\n    border-color: inherit;\n  }\n}",
    "reset-list.scss": "@mixin reset-list {\n  margin-left: 0;\n  margin-top: 0;\n  li {\n    list-style-type: none;\n  }\n}"
  };
});
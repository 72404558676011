define('ohs/routes/pattern-library/forms', ['exports', 'ohs/mixins/reset', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/no-navigation'], function (exports, _reset, _authenticatedRouteMixin, _noNavigation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _noNavigation.default, _authenticatedRouteMixin.default, {
    model: function model() {
      return Ember.Object.create({
        sortingOpts: [{
          id: 1,
          text: "Rating"
        }, {
          id: 2,
          text: "Standard"
        }, {
          id: 3,
          text: "Resource name"
        }],

        honorific: [{
          text: "Ms",
          id: "Ms"
        }, {
          text: "Miss",
          id: "Miss"
        }, {
          text: "Dr",
          id: "Dr"
        }, {
          text: "Mrs",
          id: "Mrs"
        }, {
          text: "Mx",
          id: "Mx"
        }, {
          text: "Mr",
          id: "Mr"
        }]
      });
    }
  });
});
define('ohs/components/approved-resource-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    acceptedResources: [],
    currentIndex: 0,
    issueMessage: '',
    resourceIssue: null,
    resourceIssueComments: '',
    textLimit: 1000,
    store: Ember.inject.service(),
    addDisabled: Ember.computed('acceptedResources.@each.id', 'currentIndex', function () {
      if (this.get('acceptedResources').findBy('id', this.get('currentResource.id'))) {
        return true;
      } else {
        return false;
      }
    }),
    removeDisabled: Ember.computed('acceptedResources.@each.id', 'currentIndex', function () {
      if (this.get('acceptedResources').findBy('id', this.get('currentResource.id'))) {
        return false;
      } else {
        return true;
      }
    }),
    tellUsMore: Ember.computed('resourceIssue', function () {
      if (this.get('resourceIssue') == "Other") {
        return "Tell us more.";
      } else {
        return "Tell us more (optional).";
      }
    }),

    validIssue: Ember.computed('resourceIssue', 'commentLength', 'textLimit', function () {
      if (this.resourceIssue) {
        if (this.resourceIssue != "Other") {
          if (this.resourceIssueComments && this.textLimit) {
            return this.resourceIssueComments.length <= this.textLimit;
          }
          return true;
        } else {
          if (this.resourceIssueComments && this.textLimit) {
            if (this.resourceIssueComments.length > 0 && this.resourceIssueComments.length <= this.textLimit) {
              return true;
            } else {
              return false;
            }
          }
          return false;
        }
      }
      return false;
    }),

    currentResource: Ember.computed('currentIndex', 'resources.@each.id', function () {
      if (!this.get('showStatic')) {
        return this.get('resources').toArray()[this.get('currentIndex')];
      } else {
        return this.get('approvedResource.resource');
      }
    }),
    currentPosition: Ember.computed('currentIndex', function () {
      return this.get('currentIndex') + 1;
    }),
    resourceCount: Ember.computed('resources.@each.id', function () {
      if (!this.get('showStatic')) {
        return this.get('resources').get('length');
      } else {
        return 1;
      }
    }),

    hasNext: Ember.computed('currentIndex', 'resources.@each.id', function () {
      if (!this.get('showStatic')) {
        if (this.get('currentIndex') < this.get('resources').get('length') - 1 && this.get('currentIndex') >= 0) {
          return 'has-next';
        }
      }
    }),

    hasPrevious: Ember.computed('currentIndex', 'resources.@each.id', 'otherApproved.@each.resource.id', function () {
      if (!this.get('showStatic')) {
        if (this.get('currentIndex') > 0) {
          return 'has-previous';
        }
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var self = this;
      if (this.get('approvedResources')) {
        this.get('approvedResources').forEach(function (item) {
          var r = self.get('resources').findBy('id', item.get('resource.id'));
          if (r) self.get('acceptedResources').pushObject(r);
        });
      }
    },

    currentUserRating: Ember.computed('currentResource.id', 'user.id', 'currentResource.resourceUserReviews@each.ratingScore', 'currentResource.resourceUserReviews.[]', function () {
      return Ember.get(this, 'currentResource.resourceUserReviews').findBy('user.id', Ember.get(this, 'user.id'));
    }),

    currentUserBookmark: Ember.computed('currentResource.id', 'user.id', 'currentResource.resourceBookmarks.[]', function () {
      return Ember.get(this, 'store').peekAll('resourceBookmark').findBy('resource.id', Ember.get(this, 'currentResource.id'));
    }),

    actions: {
      next: function next() {
        var done = false;
        this.set('currentIndex', this.get('currentIndex') + 1);
      },

      previous: function previous() {
        var done = false;
        this.set('currentIndex', this.get('currentIndex') - 1);
      },

      acceptResource: function acceptResource() {
        this.get('acceptedResources').pushObject(this.get('currentResource'));
      },
      removeResource: function removeResource() {
        this.get('acceptedResources').removeObject(this.get('currentResource'));
      },
      closeModal: function closeModal() {
        this.set('showDescription', false);
      },

      showModal: function showModal() {
        this.set('showDescription', true);
      },

      delete: function _delete() {
        this.set('showConfirmMessage', false);
        this.sendAction('deleteAction', this.get('approvedResource'));
      },

      confirmVisitResource: function confirmVisitResource(webUrl) {
        var user = this.get('user');
        if (user.get('hideNavigationWarning')) {
          window.open(webUrl);
        } else {
          this.sendAction('navAlertAction', webUrl);
        }
      },


      setResourceIssue: function setResourceIssue(value) {
        this.set('resourceIssue', value);
      },

      setCharacterLimitAction: function setCharacterLimitAction(commentCharacterLength, characterLimit, comment) {
        this.set('textLimit', characterLimit);
        this.set('commentLength', commentCharacterLength);
        this.set('resourceIssueComments', comment);
      },

      reportIssue: function reportIssue() {
        this.set('issueMessage', null);
        var reportItem = { 'resourceId': this.get('currentResource.id'), 'issue': this.get('resourceIssue'), 'comments': this.get('resourceIssueComments') };
        this.sendAction('issueReportAction', reportItem);
        this.set('resourceIssueComments', '');
        this.set('resourceIssue', null);
      },

      validateIssue: function validateIssue() {
        if (!this.get('resourceIssue')) {
          this.set('issueMessage', 'Please tell us what is wrong with this resource.');
          return false;
        } else if (this.get('resourceIssue') == "Other" && !this.get('resourceIssueComments').trim()) {
          this.set('issueMessage', 'Please add some additional information about what is wrong with this resource.');
          return false;
        } else if (this.get('resourceIssueComments.length') > this.get('textLimit')) {
          this.set('issueMessage', 'Comment exceeds the character limit.');
          return false;
        } else {
          return true;
        }
      },

      cancel: function cancel() {
        this.set('issueMessage', '');
        this.set('resourceIssueComments', '');
        this.set('resourceIssue', null);
      },
      clear: function clear() {
        this.set('issueMessage', '');
        this.set('resourceIssueComments', '');
        this.set('resourceIssue', null);
      },
      showFlashWarning: function showFlashWarning() {
        this.set('showFlashWarning', true);
      },

      closeFlash: function closeFlash() {
        this.set('showFlashWarning', false);
      },

      showConfirmMessage: function showConfirmMessage() {
        this.set('showConfirmMessage', true);
      }

    }

  });
});
define('ohs/routes/add-resource-classlibrary', ['exports', 'ohs/mixins/authorized-route-mixin', 'ohs/mixins/reset'], function (exports, _authorizedRouteMixin, _reset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authorizedRouteMixin.default, {
    authorizedRoles: ['Tutor', 'Teacher'],

    titleToken: function titleToken(model) {
      return 'Add Resource to Class Library';
    },


    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      var _get = this.get('token'),
          userId = _get.userId;

      return Ember.RSVP.hash({
        teacher: this.store.findRecord('user', userId),
        classLinkedResource: this.store.createRecord('class-linked-resource'),
        // classLibraryFolders: this.store.findAll('class-library-folder'),
        // orderedclassLibraryFolders: Ember.computed('classLibraryFolders.@each.id',function() {
        //   return this.classLibraryFolders.sortBy('name');
        // }),
        // selectedclassLibraryFolders:[],
        resource: this.store.findRecord('resource', params.resource_id)
      });
    },


    actions: {

      // setSelectedclassLibraryFolder(selectedValue) {
      //     var classLibraryFolderId = selectedValue;
      //     if (classLibraryFolderId) {
      //       let classLibraryFolder = this.store.peekRecord('class-library-folder',parseInt(classLibraryFolderId));
      //       //If class Library Folder already exist in array remove it otherwise add it
      //       if(this.currentModel.selectedclassLibraryFolders.contains(classLibraryFolder)){
      //         this.currentModel.selectedclassLibraryFolders.removeObject(classLibraryFolder);
      //     }
      //     else {
      //         this.currentModel.selectedclassLibraryFolders.pushObject(classLibraryFolder);
      //     }
      //   }
      // },

      cancel: function cancel() {
        this.get('currentModel.classLinkedResource').rollbackAttributes();
        this.transitionTo('teacher.resource-detail', this.get('currentModel.resource.id'));
      },

      //decide whether or not should the transition occur.
      willTransition: function willTransition(transition) {
        this.get('currentModel.classLinkedResource').rollbackAttributes();
      },


      hideAlert: function hideAlert() {
        var self = this;
        self.set('currentModel.alertMessage', '');
        self.set('currentModel.showAlert', false);
      },

      hideConfirm: function hideConfirm() {
        // var self = this;
        // var folderId = self.get('currentModel.folderId');
        // if(self.get('lastVisitedRoute')){
        //   self.transitionTo(self.get('lastVisitedRoute'),folderId);
        // }else {
        //   self.transitionTo('class-library',folderId);
        // }
        this.transitionTo('teacher.resource-detail', this.get('currentModel.resource.id'));
      },

      save: function save() {
        var self = this;
        var classLinkedResource = self.get('currentModel.classLinkedResource');
        if (!classLinkedResource.get('invalidAttributes')) {
          classLinkedResource.set('invalidAttributes', new Array());
        }
        if (classLinkedResource.get('invalidAttributes.length') == 0) {
          self.set('currentModel.isProcessing', true);
          document.activeElement.blur();

          //increment the tick so that validation is done for each field
          self.set('currentModel.tick', self.get('currentModel.tick') + 1);

          //delay for 2 seconds to allow validations to happen
          setTimeout(function () {
            if (classLinkedResource.get('invalidAttributes.length') > 0) {
              //user needs to fix validation errorMessage
              self.set('currentModel.isProcessing', false);
            } else {
              //var self = this;
              // classLinkedResource.set('classLibraryFolders', self.get('currentModel.selectedclassLibraryFolders'));
              // //save the user
              // classLinkedResource.set('classLibraryFolder', self.get('currentModel.classLibraryFolder'));
              classLinkedResource.set('teacher', self.get('currentModel.teacher'));
              classLinkedResource.set('resource', self.get('currentModel.resource'));
              classLinkedResource.set('dateCreated', new Date());
              classLinkedResource.save().then(function (u) {
                //self.uploadClassResource(u.id);
                self.set('currentModel.confirmMessage', 'Your information has been saved successfully!');
                self.set('currentModel.isProcessing', false);
              }, function (error) {
                self.set('currentModel.isProcessing', false);
                self.set('currentModel.showAlert', true);
                if (error) {
                  self.set('currentModel.alertMessage', error.message);
                } else {
                  self.set('currentModel.alertMessage', 'There was a problem creating the user. Please check your internet connection and try again.');
                }
              });
            }
          }, 1000);
        }
      }
    }

  });
});
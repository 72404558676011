define('ohs/components/labeled-input-radio', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    type: 'radio',
    classNames: ['x-input x-input-radio'],
    attributeBindings: ['type', 'htmlChecked:checked', 'value', 'name', 'disabled'],

    change: function change() {
      var selectedValue = this.get('value');
      this.sendAction('setPropertyAction', selectedValue);
    }
  });
});
define('ohs/controllers/tutor/resources/search', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        //// properties
        selectedGrade: null,
        selectedSubject: null,

        //// computeds
        filteredSubjects: Ember.computed.alias('model.account.subjects'),

        filteredGradesSort: Object.freeze(['gradeLevelOrder']),
        filteredGrades: Ember.computed.sort('selectedSubject.gradeLevels', 'filteredGradesSort'),

        actions: {
            setSubject: function setSubject(id) {
                var store = Ember.get(this, 'store');
                var subject = store.peekRecord('subject', id);
                Ember.set(this, 'selectedSubject', subject);

                this.setDefaultTarget();
                this.setDefaultGradeLevel();
            },
            setGrade: function setGrade(id) {
                var store = Ember.get(this, 'store');
                var grade = store.peekRecord('grade', id);
                Ember.set(this, 'selectedGrade', grade);
            },
            search: function search() {
                document.activeElement.blur();
                return this.transitionToRoute("tutor.resources.search-results");
            }
        },

        //// methods
        setDefaultTarget: function setDefaultTarget() {
            var subject = Ember.get(this, 'selectedSubject');

            // if the subject.id is 2. Default the target to 2.
            if (Ember.get(subject, 'id') === "2") {
                Ember.set(this, 'selectedTarget', 2);
            } else {
                Ember.set(this, 'selectedTarget', 1);
            }
        },
        setDefaultGradeLevel: function setDefaultGradeLevel() {
            var subject = Ember.get(this, 'selectedSubject');
            var grade = Ember.get(this, 'selectedGrade');
            var grades = Ember.get(subject, 'gradeLevels');

            // if the selected grade is not in the subject 
            // default it to the first one
            if (!grades.includes(grade)) {
                Ember.set(this, 'selectedGrade', grades.objectAt(0));
            }
        }
    });
});
define('ohs/models/student-group', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    studentGroupName: _emberData.default.attr('string'),
    studentGroupDesc: _emberData.default.attr('string'),
    isPending: _emberData.default.attr('boolean'),
    dateApproved: _emberData.default.attr('date'),
    edInsightCourseId: _emberData.default.attr('string'),
    schoologyCourseId: _emberData.default.attr('string'),
    googleCourseId: _emberData.default.attr('string'),
    createdOn: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),

    lastUsed: _emberData.default.attr('date'),

    teacher: _emberData.default.belongsTo('teacher'),
    students: _emberData.default.hasMany('student'),

    numStudents: _emberData.default.attr('number'),

    studentCount: Ember.computed('students.[]', function () {
      return this.get('students').get('length');
    }),

    //This computed property is used for display date in the student Group list based on the created Date (createdOn).
    createdDate: Ember.computed('createdOn', function () {
      var date = this.get('createdOn').toLocaleDateString();
      var today = new Date();
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (today.toLocaleDateString() == date) return 'Created Today';else if (yesterday.toLocaleDateString() == date) {
        return 'Created Yesterday';
      } else return 'Created ' + date;
    }),

    shortDescription: Ember.computed('studentGroupDesc', function () {
      var text = this.get('studentGroupDesc');
      if (text && text.length > 50) {
        return text.substring(0, 47) + " ...";
      } else {
        return text;
      }
    }),

    countDisplay: Ember.computed('studentCount', function () {
      if (this.get('studentCount') == 1) {
        return '1 student';
      } else {
        return this.get('studentCount') + ' students';
      }
    }),

    numStudentsDisplay: Ember.computed('numStudents', function () {
      if (this.get('numStudents') == 1) {
        return '1 student';
      } else {
        return this.get('numStudents') + ' students';
      }
    }),

    text: Ember.computed('studentGroupName', function () {
      return this.get('studentGroupName');
    })

  });
});
define('ohs/controllers/passage-quiz', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      transitionToQuestions: function transitionToQuestions() {
        this.transitionToRoute('passage-quiz-questions');
      }
    }
  });
});
define('ohs/routes/manage-student-profile', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: function titleToken(model) {
      return model.student.get('fullName');
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      var account = this.store.peekAll('account');
      var accountId = account.objectAt(0).id;
      var studentId = params.student_id;
      var sesObj = this.get('session');

      return Ember.RSVP.hash({
        student: this.store.findRecord('student', studentId),
        orderedUserInterests: Ember.computed('student.interestAreas.@each.id', function () {
          return this.student.get('interestAreas').sortBy('description');
        }),
        grades: this.store.query('grade', { 'gradeType': 'StudentProfle' }),
        gradeLevel: Ember.computed('student.gradeLevelId', 'grades.@each.id', function () {
          var gradeId = this.student.get('gradeLevelId');
          if (gradeId) {
            var selectedGrade = this.grades.findBy('id', gradeId.toString());
            if (selectedGrade) {
              return selectedGrade.get('text');
            }
          } else {
            return "Not Provided";
          }
        }),
        completedQuizzes: this.store.query('student-test-administration', { accountId: accountId, userId: studentId }),
        recentResults: Ember.computed('completedQuizzes', function () {
          return this.completedQuizzes.sortBy('dateCompletedSort').reverse();
        }),
        resources: this.store.query('resource-assigned', { userId: studentId }),
        orderedResources: Ember.computed('resources.@each.id', function () {
          return this.resources.filterBy('student.id', studentId).sortBy('assignedDate').reverse();
        }),
        standardType: sesObj.get('standardType')
      });
    },


    afterModel: function afterModel(model) {
      this._super(model, model.student.get('fullName'), model.student.get('id'));
    },

    deactivate: function deactivate() {
      $('li:contains("Account Admin")').find('li:contains("Manage Students")').children().first().removeClass('active');
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Account Admin")').find('li:contains("Manage Students")').children().first().addClass('active');
      }, 100);
    },

    actions: {

      drillToResult: function drillToResult(id) {
        this.transitionTo('teacher-results-2', id);
      },

      drillToResource: function drillToResource(id) {
        this.transitionTo('teacher.resource-detail', id);
      },

      generateCsv: function generateCsv() {
        var csvContent = "";
        var link = document.createElement("a");
        var fileName = this.get('currentModel.student.fullName') + '_QuizzesResults.csv';
        this.set("currentModel.isProcessing", true);

        //IE 10+
        if (navigator != null && navigator.msSaveBlob) {
          csvContent = this.generateCsvContent();
          var blob = new Blob([csvContent], {
            "type": "text/csv;charset=utf8;"
          });
          navigator.msSaveBlob(blob, fileName);

          document.body.appendChild(link);
          link.click();
          this.set("currentModel.isProcessing", false);
        }
        //In other browsers check for download attribute support
        else if (link.download !== undefined) {
            csvContent = "data:text/csv;charset=utf-8," + this.generateCsvContent();
            var encoded = encodeURI(csvContent);
            link.setAttribute("href", encoded);
            link.setAttribute("download", fileName);

            document.body.appendChild(link);
            link.click();
            this.set("currentModel.isProcessing", false);
          } else {
            //needs to implement server side export
            //var administrationId = this.get('currentModel.administration.id');
            var baseUrl = this.store.adapterFor('application').get('host');

            var sesObj = this.get('session');
            var userId = sesObj.session.content.authenticated["userId"];
            var studentUserId = this.get('currentModel.student.id');
            var self = this;
            self.set('currentModel.isPrinting', true);

            return Ember.$.getJSON(baseUrl + '/administrations/GenerateQuizResultCsv?teacherUserId=' + encodeURIComponent(userId) + '&studentUserId=' + encodeURIComponent(studentUserId)).done(function (response) {
              var xhr = new XMLHttpRequest();
              xhr.open('GET', baseUrl + '/quiz/DownloadQuizQuestions?fileName=' + encodeURIComponent(response), true);
              xhr.responseType = "blob";
              xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                  var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
                  if (isSafari) {
                    var blob = new Blob([xhr.response], { type: "application/octet-stream" });
                  } else {
                    var blob = new Blob([xhr.response], { type: "application/pdf" });
                  }
                  saveAs(blob, response);
                }
              };
              xhr.send(null);

              self.set("currentModel.isProcessing", false);
              self.set('currentModel.isPrinting', false);
            }).fail(function (response) {
              self.set("currentModel.isProcessing", false);
              self.set('currentModel.isPrinting', false);
            });
          }
      },
      resetPassword: function resetPassword() {
        //this.transitionTo('change-password');
        var userId = this.get('currentModel.student.id');
        this.transitionTo('reset-user-password', userId);
        // this.transitionTo('change-password',userId,'1');
      },


      updateBreadCrumbs: function updateBreadCrumbs() {
        var breadcrumbs = this.get('session').get('breadcrumbs');
        var bc = breadcrumbs[breadcrumbs.length - 1];
        if (bc) {
          Ember.set(bc, 'text', this.currentModel.student.get('fullName'));
        }
      }
    },

    //Common Methods
    generateCsvContent: function generateCsvContent() {
      var results = this.get('currentModel.recentResults');
      var csvContent = "Quiz Name,Standard Code,Number Correct,Points Possible,Percent Correct,";
      csvContent += "\n";
      results.forEach(function (item) {
        var quizName = item.get('administration').get('quiz').get('quizName');
        var standardCode = item.get('administration').get('quiz').get('standardCodeDisplay');
        var numQuestions = item.get('administration').get('quiz').get('numQuestions');
        var quizDetails = '"' + quizName + '"' + ',' + standardCode + ',' + item.get('numCorrect') + ',' + numQuestions + ',' + item.get('percentCorrect') + ',';
        csvContent += quizDetails;
        csvContent += "\n";
      });
      return csvContent;
    }
  });
});
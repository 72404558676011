define('ohs/components/editable-question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['question'],
    classNameBindings: ['dynamicClasses'],
    showModal: null,
    showAlert: null,
    alertMessage: null,
    store: Ember.inject.service(),

    questionIssue: '',
    questionIssueComments: '',
    textLimit: 1000,
    commentLength: 0,

    // validIssue: Ember.computed('questionIssue','questionIssueComments',function() {
    //   return (this.questionIssue && this.questionIssue != "Other" && this.questionIssueComments.length<=this.textLimit)
    //     || (this.questionIssue == "Other" && this.questionIssueComments!=null && this.questionIssueComments.trim() && this.questionIssueComments.length<=this.textLimit);
    // }),
    validIssue: Ember.computed('questionIssue', 'commentLength', 'textLimit', function () {
      if (this.questionIssue) {
        if (this.questionIssue != "Other") {
          if (this.questionIssueComments && this.textLimit) {
            return this.questionIssueComments.length <= this.textLimit;
          }
          return true;
        } else {
          if (this.questionIssueComments && this.textLimit) {
            if (this.questionIssueComments.length > 0 && this.questionIssueComments.length <= this.textLimit) {
              return true;
            } else {
              return false;
            }
          }
          return false;
        }
      }
      return false;
    }),

    tellUsMore: Ember.computed('questionIssue', function () {
      if (this.questionIssue == "Other") {
        return "Tell us more.";
      } else {
        return "Tell us more (optional).";
      }
    }),

    questionCount: Ember.computed('length', function () {
      return this.get('length');
    }),

    orderedAnswers: Ember.computed('question.question.answerOptions.@each.id', function () {
      if (this.get('question.question.answerOptions')) {
        return this.get('question.question.answerOptions').sortBy('id');
      }
    }),
    orderedSecondPartAnswers: Ember.computed('question.question.secondPartQuestion.secondPartAnswerOptions.@each.id', function () {
      if (this.get('question.question.secondPartQuestion.secondPartAnswerOptions')) {
        return this.get('question.question.secondPartQuestion.secondPartAnswerOptions').sortBy('id');
      }
    }),
    dynamicClasses: Ember.computed('length', 'question.questionOrder', function () {
      var names = [];
      var length = this.get('length');

      if (this.get('question.questionOrder') == 1) {
        names.push('is-first');
      }

      if (this.get('question.questionOrder') == this.get('length')) {
        names.push('is-last');
      }

      return names.join(' ');
    }),

    updateElementClasses: function updateElementClasses(self) {
      var children = self.parent().children();
      children.removeClass('slide-up slide-down');
      //self.addClass(this.get('justAcceptedStatus'));

      // children.first().addClass('is-first');
      // children.last().addClass('is-last');
    },

    didRender: function didRender() {
      //alert('question ' + this.get('question.questionOrder') + ' rendered');
    },
    willDestroyElement: function willDestroyElement() {
      //alert('question ' + this.get('question.questionOrder') + ' destroying');
    },
    didUpdate: function didUpdate() {
      //alert('question ' + this.get('question.questionOrder') + ' updated');
      //this.rerender();
    },


    resetQuestionOrders: function resetQuestionOrders() {
      var order = 1;
      this.get('testQuestions').forEach(function (item) {
        item.set('questionOrder', order);
        order++;
      });
    },

    actions: {
      reorderUp: function reorderUp() {
        var self = this;

        self.isProcessing(true);

        var tq1 = this.get('question');
        var tq2 = this.get('testQuestions').findBy('questionOrder', tq1.get('questionOrder') - 1);
        tq1.set('questionOrder', tq1.get('questionOrder') - 1);
        tq2.set('questionOrder', tq2.get('questionOrder') + 1);
        tq1.save().then(function () {
          tq2.save().then(function () {
            var item = self.$();
            var prev = self.$().closest('li').prev();
            item.addClass('slide-up');
            prev.addClass('slide-down');
            setTimeout(function () {
              // var row = self.$().closest('li');
              // row.insertBefore(row.prev());
              self.updateElementClasses(self.$());
              tq1.set('extraOrder', tq1.get('questionOrder'));
              tq2.set('extraOrder', tq2.get('questionOrder'));
            }, 500);
            self.isProcessing(false);
          }, function () {
            tq2.rollbackAttributes();
            tq1.set('questionOrder', tq1.get('questionOrder') + 1);
            tq1.save();
            self.isProcessing(false);
          });
        }, function () {
          tq1.rollbackAttributes();
          tq2.rollbackAttributes();
          self.isProcessing(false);
          self.set('showAlert', true);
          self.set('alertMessage', "This question could not be re-ordered. Please check your internet connection and try again.");
        });
      },

      reorderDown: function reorderDown() {
        var self = this;

        self.isProcessing(true);

        var tq1 = this.get('question');
        var tq2 = this.get('testQuestions').findBy('questionOrder', tq1.get('questionOrder') + 1);
        tq1.set('questionOrder', tq1.get('questionOrder') + 1);
        tq2.set('questionOrder', tq2.get('questionOrder') - 1);
        tq1.save().then(function () {
          tq2.save().then(function () {

            var li = self.$();
            var ul = li.parent();
            var item = li;
            var next = li.closest('li').next();
            item.addClass('slide-down');
            next.addClass('slide-up');
            setTimeout(function () {
              // var row = li.closest('li');
              // row.insertAfter(row.next());
              self.updateElementClasses(li);
              tq1.set('extraOrder', tq1.get('questionOrder'));
              tq2.set('extraOrder', tq2.get('questionOrder'));
            }, 500);
            self.isProcessing(false);
          }, function () {
            tq2.rollbackAttributes();
            tq1.set('questionOrder', tq1.get('questionOrder') + 1);
            tq1.save();
            self.isProcessing(false);
          });
        }, function () {
          tq1.rollbackAttributes();
          tq2.rollbackAttributes();
          self.isProcessing(false);
          self.set('showAlert', true);
          self.set('alertMessage', "This question could not be re-ordered. Please check your internet connection and try again.");
        });
      },

      showConfirmMessage: function showConfirmMessage() {
        this.set('showModal', true);
      },

      removeQuestion: function removeQuestion() {
        this.set('showModal', false);

        var tq = this.get('question');
        var self = this;

        self.isProcessing(true);

        var li = self.$();
        var ul = li.parent();
        var next = self.$().closest('li').next();
        li.addClass('delete');
        tq.destroyRecord().then(function () {
          //li.remove();
          self.get('testQuestions').removeObject(tq);
          self.resetQuestionOrders();
          //var row = ul.children().first();
          //self.updateElementClasses(row);
          //self.sendAction('refreshAction');
          // setTimeout(function() {
          //   li.remove();
          //   var row = ul.children().first();
          //   self.updateElementClasses(row);
          // },500);
          $(".question-edit-controls :button").attr("disabled", false);
        }, function () {
          tq.rollbackAttributes();
          self.isProcessing(false);
          self.set('showAlert', true);
          self.set('alertMessage', "This question could not be deleted. Please check your internet connection and try again.");
        });
      },

      //Hide alert box
      hideAlert: function hideAlert() {
        this.set("showAlert", false);
        this.set("alertMessage", '');
      },

      setCharacterLimitAction: function setCharacterLimitAction(commentCharacterLength, characterLimit, comment) {
        this.set('textLimit', characterLimit);
        this.set('commentLength', commentCharacterLength);
        this.set('questionIssueComments', comment);
      },
      reportIssue: function reportIssue() {

        this.set('issueMessage', null);
        var self = this;
        var adapter = this.get('store').adapterFor('application');

        var url = adapter.get('host') + '/accounts/reportQuestion?questionId=' + this.get('question').get('question').get('id') + '&issue=' + this.get('questionIssue') + '&comment=' + this.get('questionIssueComments');
        if (this.get('questionIssueComments').trim() == '') {
          url = adapter.get('host') + '/accounts/reportQuestion?questionId=' + this.get('question').get('question').get('id') + '&issue=' + this.get('questionIssue');
        }
        adapter.ajax(url, 'POST').then(function () {
          self.set('showConfirm', true);
          self.set('confirmationMessage', 'Your report has been received and is currently being reviewed. If we have any follow up questions, someone will be in touch!');
        }, function () {
          self.set('showAlert', true);
          self.set("alertMessage", "There was an error sending your report. Please check your internet connection and try again.");
        });

        this.set('questionIssueComments', '');
        this.set('questionIssue', null);
      },

      //Hide alert box
      hideConfirm: function hideConfirm() {
        this.set("showConfirm", false);
      },

      setQuestionIssue: function setQuestionIssue(value) {
        this.set('questionIssue', value);
      },

      setQuestionIssueComment: function setQuestionIssueComment(value) {
        this.set('questionIssueComments', value);
      },

      validateIssue: function validateIssue() {
        if (!this.get('questionIssue')) {
          this.set('issueMessage', 'Please tell us what is wrong with this question.');
          return false;
        } else if (this.get('questionIssue') == "Other" && !this.get('questionIssueComments').trim()) {
          this.set('issueMessage', 'Please add some additional information about what is wrong with this question.');
          return false;
        } else if (this.get('questionIssueComments.length') > this.get('textLimit')) {
          this.set('issueMessage', 'Comment exceeds the character limit.');
          return false;
        } else {
          return true;
        }
      },

      cancel: function cancel() {
        this.set('issueMessage', '');
        this.set('questionIssueComments', '');
        this.set('questionIssue', null);
      },
      clear: function clear() {
        this.set('issueMessage', '');
        this.set('questionIssueComments', '');
        this.set('questionIssue', null);
      }
    },

    //Methods

    // Enable or disable buttons based on the status of a process.
    isProcessing: function isProcessing(status) {
      //Enable/Disable all the buttons in the div with 'question-edit-controls :button' class
      $(".question-edit-controls :button").attr("disabled", status);

      //  Remove the background-color by set it as transparent.
      $(".question-edit-controls :button").css('background-color', 'transparent');

      //  Remove the border by set the border as 'none'.
      $(".question-edit-controls :button").css('border', 'none');

      //  Remove the padding by set the padding as '0'.
      $(".question-edit-controls :button").css('padding', '0');
    }
  });
});
define('ohs/routes/parent/resource-bookmarks', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Bookmarks',
    signalR: Ember.inject.service('signal-r'),
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('parent_many_children', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];

      return Ember.RSVP.hash({
        bookmarks: this.store.findAll('resourceBookmark'),
        perPage: 10,
        numShown: 10,
        hasMoreItems: Ember.computed('bookmarks.@each.id', 'numShown', function () {
          if (this.bookmarks.get('length') > this.numShown) {
            return true;
          } else {
            return false;
          }
        }),

        curBookmarks: Ember.computed('bookmarks.@each.id', 'numShown', 'curSort', function () {
          if (this.curSort == 1) {
            return this.bookmarks.sortBy('bookmarkedDate').reverse().slice(0, this.numShown);
          } else if (this.curSort == 3) {
            return this.bookmarks.sortBy('resource.resourceName').slice(0, this.numShown);
          } else {
            return this.bookmarks.slice(0, this.numShown);
          }
        }),
        curSort: 1,
        curUser: this.store.findRecord('parent', userId),

        sortingOpts: [{
          id: 1,
          text: "Rating"
        }, {
          id: 3,
          text: "Resource name"
        }],
        isInfinityGridVisible: true
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Resource Bookmarks', false, true);
      //Signal-R event subscription
      this.get('signalR').on('linkParentStudent', this, 'linkParentStudent');
    },

    actions: {
      createBookmark: function createBookmark(resourceId) {
        var curModel = this.currentModel;
        var self = this;
        var curResource = curModel.resources.findBy('id', resourceId);
        var bookmarkForCurResource = curModel.bookmarks.find(function (item, index, enumerable) {
          return item.get('resource').get('id') == resourceId;
        });
        if (curResource && !bookmarkForCurResource) {
          var b = this.store.createRecord('resource-bookmark', {
            resource: curResource,
            user: curModel.curUser,
            bookmarkedDate: new Date()
          });
          b.save().then(function () {
            //self.refresh();
          }, function () {
            self.set('currentModel.warningMessage', "This resource could not be bookmarked. Please check your Internet connection and try again.");
            self.set("currentModel.showAlert", true);
          });
        }
      },

      deleteBookmark: function deleteBookmark(resourceId) {
        var curModel = this.currentModel;
        var self = this;
        var bookmarks = this.get('currentModel.bookmarks');
        var bookmarkForCurResource = curModel.bookmarks.find(function (item, index, enumerable) {
          return item.get('resource').get('id') == resourceId;
        });
        if (bookmarkForCurResource) {
          bookmarkForCurResource.destroyRecord().then(function () {
            self.refresh();
            //bookmarks.popObject(bookmarkForCurResource);
          }, function () {
            self.set('currentModel.warningMessage', "This bookmark could not be deleted. Please check your Internet connection and try again.");
            self.set("currentModel.showAlert", true);
          });
        }
      },

      sortBookmarks: function sortBookmarks(selectedValue) {
        this.set('currentModel.curSort', selectedValue);
      },

      searchResources: function searchResources() {
        this.transitionTo('parent.resource-search-start');
      },

      drill: function drill(id) {
        this.transitionTo('parent.resource-detail', id);
      }

    },

    linkParentStudent: function linkParentStudent(userId) {
      var sesObj = this.get('session');
      if (!sesObj.get('currentStudent')) {
        sesObj.set('currentStudent', userId);
      }
      this.refresh();
    }
  });
});
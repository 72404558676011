define('ohs/helpers/is-contains', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isContains = isContains;
  function isContains(params /*, hash*/) {
    var checked = false;
    if (params[0] && params[1]) {
      var val1 = params[0].toString();
      var val2 = params[1];
      var valueToBeChecked = params[2];

      // if  params[1] is true or false
      if (val2 === true || val2 === false) {
        return val2;
      }

      // if  params[1] is an array
      val2.forEach(function (item) {

        if (valueToBeChecked) {
          if (item.get(valueToBeChecked) == val1) {
            checked = true;
          }
        } else {
          if (item.get('id') == val1) {
            checked = true;
          }
        }
      });
    }
    return checked;
  }

  exports.default = Ember.Helper.helper(isContains);
});
define('ohs/routes/account-admin', ['exports', 'ohs/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        model: function model() {
            var userId = Ember.get(this, 'session.session.content.authenticated.userId');
            var store = Ember.get(this, 'store');

            return Ember.RSVP.hash({
                account: store.queryRecord('account', { userId: userId })
            });
        },
        afterModel: function afterModel(model) {
            var isAccountAdmin = Ember.get(model, 'account.isAdminAccount');

            if (!isAccountAdmin) {
                this.get('session').invalidate();
            }
        }
    });
});
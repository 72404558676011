define('ohs/routes/teacher-account-details', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: function titleToken(model) {
      return model.user.get('fullName');
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          } else if (roles.indexOf("Parent") > 0) {
            this.controllerFor('application').set('parent_many_children', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
          this.controllerFor('application').set('parent_many_children', '');
        }
      }
    },
    model: function model(params) {
      var account = this.store.peekAll('account');
      var accountId = account.objectAt(0).id;

      return Ember.RSVP.hash({
        user: this.store.findRecord('user', params.user_id),
        students: this.store.query('student', { teacherUserId: params.user_id }),
        orderedStudents: Ember.computed('students.@each.id', function () {
          return this.students.sortBy('lastName');
        }),
        accountId: accountId,
        isEmailRequired: true,
        removeStudents: false,
        removeSchoology: false,
        isEdit: false,
        isNew: false,
        grades: this.store.query('grade', { 'gradeType': 'TeacherProfle' }),
        stateOptions: this.store.findAll('state'),
        standardTypeOptions: this.store.findAll('standard-type'),
        state: Ember.computed('user.stateId', 'stateOptions.@each.id', function () {
          return this.stateOptions.findBy('id', this.user.get('stateId').toString());
        }),
        standardType: Ember.computed('user.standardTypeId', 'standardTypeOptions.@each.id', function () {
          return this.standardTypeOptions.findBy('id', this.user.get('standardTypeId').toString());
        }),
        gradeLevel: Ember.computed('user.gradeLevelId', 'grades.@each.id', function () {
          var gradeId = this.user.get('gradeLevelId');
          if (gradeId) {
            var selectedGrade = this.grades.findBy('id', gradeId.toString());
            if (selectedGrade) {
              return selectedGrade.get('text');
            }
          } else {
            return "Not Provided";
          }
        }),
        honorificOptions: [{
          text: "Mrs",
          id: "Mrs"
        }, {
          text: "Mr",
          id: "Mr"
        }, {
          text: "Ms",
          id: "Ms"
        }, {
          text: "Miss",
          id: "Miss"
        }, {
          text: "Dr",
          id: "Dr"
        }]
      });
    },
    afterModel: function afterModel(model) {
      this._super(model, model.user.get('fullName'), model.user.get('id'));

      model.user.set('firstNameEdit', model.user.get('firstName'));
      model.user.set('lastNameEdit', model.user.get('lastName'));
      model.user.set('emailEdit', model.user.get('email'));
      var username = model.user.get('userName');
      if (username.indexOf('.') > 0) model.user.set('currentSuffix', username.substring(username.indexOf('.')));

      if (model.user.get('userRole') == "Teacher" || model.user.get('userRole') == "Parent") {
        if (model.user.get('stateId') == 39) {
          this.set('model.standardTypeSelected', true);
        } else {
          this.set('model.standardTypeSelected', false);
          this.set('model.standardTypeId', 2011);
        }
      } else {
        this.set('model.standardTypeId', null);
      }
      this.set('model.isEditGeneral', true);
    },

    deactivate: function deactivate() {
      $('li:contains("Account Admin")').find('li:contains("Teacher Accounts")').children().first().removeClass('active');
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Account Admin")').find('li:contains("Teacher Accounts")').children().first().addClass('active');
      }, 100);
    },

    actions: {
      resetPassword: function resetPassword() {
        var userId = this.get('currentModel.user.id');
        this.transitionTo('reset-user-password', userId);
      },

      drill: function drill(id) {
        this.transitionTo('student-profile', id);
      },
      updateBreadCrumbs: function updateBreadCrumbs() {
        var breadcrumbs = this.get('session').get('breadcrumbs');
        var bc = breadcrumbs[breadcrumbs.length - 1];
        if (bc) {
          Ember.set(bc, 'text', this.currentModel.user.get('fullName'));
        }
      },

      setHonorific: function setHonorific(selectedValue) {
        this.set('currentModel.user.honorific', selectedValue);
      },

      setGradeLevel: function setGradeLevel(selectedValue) {
        this.set('currentModel.user.gradeLevelId', selectedValue);
      },

      setState: function setState(selectedValue) {
        this.set('currentModel.user.stateId', selectedValue);
        if (this.get('currentModel.user').get('userRole') == "Teacher" || this.get('currentModel.user').get('userRole') == "Parent") {
          if (selectedValue == 39) {
            this.set('currentModel.standardTypeSelected', true);
          } else {
            this.set('currentModel.standardTypeSelected', false);
            this.get('currentModel.user').set('standardTypeId', 2011);
          }
        } else {
          this.get('currentModel.user').set('standardTypeId', null);
        }
      },

      setStandardType: function setStandardType(selectedValue) {
        this.set('currentModel.user.standardTypeId', selectedValue);
      },

      hideConfirm: function hideConfirm() {
        this.set('currentModel.showConfirm', false);
        this.set('currentModel.isEditGeneral', false);
        this.set('currentModel.isEditLearning', false);
        this.set('currentModel.isEditInterests', false);
        var breadcrumbs = this.get('session.breadcrumbs');
        if (breadcrumbs.length >= 2) {
          var lastBC = breadcrumbs[breadcrumbs.length - 2];
          if (lastBC.model) {
            this.transitionTo(lastBC.route, lastBC.model);
          } else {
            this.transitionTo(lastBC.route);
          }
        } else {
          this.transitionTo('students');
        }
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
      },
      addStudents: function addStudents() {
        this.transitionTo('student-account-list-add-remove', this.get('currentModel.user.id'), this.get('currentModel.accountId'));
      }
    }

  });
});
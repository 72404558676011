define('ohs/models/educator', ['exports', 'ember-data', 'ohs/models/user'], function (exports, _emberData, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = _user.default.extend({
    code: attr('string'),
    students: hasMany('student'),
    studentGroups: hasMany('student-group'),
    resourceBookmarks: hasMany('resource-bookmark'),
    recommendedResources: hasMany('resource-recommended'),

    orderedStudents: Ember.computed('students.@each.id', function () {
      return this.get('students').sortBy('fullNameReversed');
    }),

    orderedStudentGroups: Ember.computed('studentGroups.@each.id', function () {
      return this.get('studentGroups').sortBy('studentGroupName');
    })
  });
});
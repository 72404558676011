define('ohs/routes/student-results', ['exports', 'ohs/mixins/reset', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/breadcrumb-mixin', 'ohs/mixins/passage-modal-mixin'], function (exports, _reset, _authenticatedRouteMixin, _breadcrumbMixin, _passageModalMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, _passageModalMixin.default, {
        signalR: Ember.inject.service('signal-r'),

        titleToken: function titleToken(model) {
            return model.studentTestAdministration.get('administration.quiz.quizName');
        },

        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            // Implement your custom setup after
            this.controllerFor('application').set('showSecondaryNav', true);
            var sesObj = this.get('session');
            if (sesObj) {
                var roles = sesObj.session.content.authenticated["roles"];
                if (roles) {
                    if (roles.includes("Student")) {
                        this.controllerFor('application').set('student', true);
                    } else if (roles.indexOf("Parent") > 0) {
                        this.controllerFor('application').set('parent_many_children', true);
                    }
                }
            }
        },

        model: function model(params) {
            var store = this.store;
            return store.queryRecord('quiz', { studentTestAdministrationId: params.student_test_id }).then(function (quiz) {
                return Ember.RSVP.hash({
                    showNextStep: params.show_next_step,
                    studentTestAdministration: store.findRecord('studentTestAdministration', params.student_test_id),
                    quiz: quiz,
                    teacher: store.find('user', get(quiz, 'createUserId')),
                    testQuestions: store.query('test-question', { testId: get(quiz, 'id') }),
                    passage: Ember.computed.alias('quiz.passages.firstObject'),

                    orderedQuestions: Ember.computed('testQuestions.[]', function () {
                        return get(this, 'testQuestions').sortBy('questionOrder');
                    })
                });
            });
        },

        activate: function activate() {
            setTimeout(function () {
                if ($('li:contains("Quizzes")').find('li:contains("Results")').length > 0) {
                    $('li:contains("Quizzes")').find('li:contains("Results")').children().first().addClass('active');
                } else {
                    $('li:contains("Quizzes")').children().first().addClass('active');
                }
            }, 100);
        },

        afterModel: function afterModel(model) {
            this._super(model, model.studentTestAdministration.get('administration.quiz.quizName'), [{ 'param': model.studentTestAdministration.get('id') }, { 'param': model.showNextStep }]);
            var sta = model.studentTestAdministration;
            if (!sta.get('isFinished')) {
                sta.set('isFinished', 1);
                sta.set('currentlyInQuiz', 0);
                sta.save();
            }
        },

        deactivate: function deactivate() {
            var adminId = this.get('currentModel.studentTestAdministration').get('administration').get('id');
            this.get('signalR').leaveAdministration(adminId);
            $('li:contains("Quizzes")').find('li:contains("Results")').children().first().removeClass('active');
        },

        actions: {
            removeStudentFromAdministration: function removeStudentFromAdministration(administrationId) {
                //Leave Administration
                this.get('signalR').leaveAdministration(administrationId);
                this.set("currentModel.showCancelAlert", true);
                this.set("currentModel.alertMessage", "Your teacher has removed you from the quiz!");
            },


            //Hide alert box and transition To student-quizzes route
            gotoQuizzes: function gotoQuizzes() {
                this.transitionTo('student-quizzes');
            }
        }

    });
});
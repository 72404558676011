define('ohs/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    userName: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    confirmPassword: _emberData.default.attr('string'),
    honorific: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    forcePasswordChange: _emberData.default.attr('boolean'),

    stateId: _emberData.default.attr('number'),
    standardTypeId: _emberData.default.attr('number'),

    userRole: _emberData.default.attr('string'),
    lastLogin: _emberData.default.attr('date'),
    gradeLevelId: _emberData.default.attr('number'),
    studentId: _emberData.default.attr('string'),
    avatar: _emberData.default.attr('string'),

    firstNameEdit: _emberData.default.attr('string'),
    lastNameEdit: _emberData.default.attr('string'),
    emailEdit: _emberData.default.attr('string'),
    userNameEdit: _emberData.default.attr('string'),
    studentIdEdit: _emberData.default.attr('string'),
    hideNavigationWarning: _emberData.default.attr('boolean'),
    isThroughAccountAdmin: _emberData.default.attr('boolean'),
    accountId: _emberData.default.attr('number'),
    accountDisabled: _emberData.default.attr('boolean'),
    disabledReason: _emberData.default.attr('string'),
    errorId: _emberData.default.attr('number'),
    googleId: _emberData.default.attr('string'),
    googleEmail: _emberData.default.attr('string'),
    hasTeacherStudentLink: _emberData.default.attr('boolean'),
    schoologyId: _emberData.default.attr('string'),
    schoologyAccess: _emberData.default.attr('string'),
    schoologyUsername: _emberData.default.attr('string'),
    edinsightProviderId: _emberData.default.attr('number'),
    edinsightId: _emberData.default.attr('number'),
    hasClassLibrary: _emberData.default.attr('boolean'),
    gradeLevelDesc: _emberData.default.attr('string'),
    noOfLinkedStudents: _emberData.default.attr('number'),
    primarySubjectId: _emberData.default.attr('number'),

    //supports the survey answers for marketing
    surveyReferralTypeId: _emberData.default.attr('number'),

    initials: Ember.computed('firstName', 'lastName', function () {
      if (this.get('firstName') && this.get('lastName')) return this.get('firstName').substring(0, 1) + this.get('lastName').substring(0, 1);
    }),

    fullNameReversed: Ember.computed('firstName', 'lastName', function () {
      if (this.get('firstName') && this.get('lastName')) {
        return this.get('lastName') + ", " + this.get('firstName');
      } else if (this.get('firstName')) {
        return this.get('firstName');
      } else if (this.get('lastName')) {
        return this.get('lastName');
      }
    }),

    studentDisplayName: Ember.computed('firstName', 'lastName', function () {
      if (this.get('firstName') && this.get('lastName')) {
        return this.get('firstName') + " " + this.get('lastName').substring(0, 1) + ".";
      } else if (this.get('firstName')) {
        return this.get('firstName');
      } else if (this.get('lastName')) {
        return this.get('lastName');
      }
    }),

    teacherDisplayName: Ember.computed('honorific', 'lastName', function () {
      if (this.get('lastName')) {
        return (this.get('honorific') + " " + this.get('lastName')).trim();
      }
    }),

    parentDisplayName: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + " " + this.get('lastName');
    }),

    fullName: Ember.computed('firstName', 'lastName', function () {
      return (this.get('firstName') + ' ' + this.get('lastName')).trim();
    }),

    lastLoginFormatted: Ember.computed('lastLogin', function () {
      if (this.get('lastLogin')) {
        var date = this.get('lastLogin').toLocaleDateString();
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (today.toLocaleDateString() == date) return 'Today';else if (yesterday.toLocaleDateString() == date) {
          return 'Yesterday';
        } else {
          return date;
        }
      }
    }),

    avatarColor: Ember.computed('avatar', function () {
      if (this.get('avatar') && this.get('avatar').indexOf('/') > 0) {
        return this.get('avatar').substr(0, this.get('avatar').indexOf('/'));
      } else {
        return 'Green';
      }
    })

  });
});
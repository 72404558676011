define('ohs/models/student-mission-answer', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var belongsTo = _emberData.default.belongsTo,
        attr = _emberData.default.attr;
    exports.default = _emberData.default.Model.extend({
        created: attr('date'),
        correct: attr('boolean'),

        question: belongsTo('mission-control/question'),
        answerOption: belongsTo('mission-control/answer-option'),
        studentMission: belongsTo('student-mission'),

        questionId: Ember.computed.alias('question.id'),
        answerOptionId: Ember.computed.alias('answerOption.id'),
        studentMissionId: Ember.computed.alias('studentMission.id')
    });
});
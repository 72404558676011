define('ohs/components/result-answer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    studentAnswers: Ember.computed('studentTestAdministrations.@each.numAnswered', 'studentTestAdministrations.@each.numCorrect', function () {
      if (this.get('qc')) {
        var answerId = this.get('qc').get('id');
        var numStudents = 0;
        var returnList = [];
        this.get('studentTestAdministrations').forEach(function (item, index) {
          var studentAnswer = item.get('studentAnswers').findBy('answerId', parseInt(answerId));
          if (studentAnswer)
            //numStudents++;
            returnList.pushObject(studentAnswer);
        });
        //return numStudents;
        return returnList;
      }
    }),
    studentSecondPartAnswers: Ember.computed('studentTestAdministrations.@each.numSecondPartAnswered', 'studentTestAdministrations.@each.numSecondPartCorrect', function () {
      if (this.get('sqc')) {
        var answerId = this.get('sqc').get('id');
        var numStudents = 0;
        var returnList = [];
        this.get('studentTestAdministrations').forEach(function (item, index) {
          var studentAnswer = item.get('studentSecondPartAnswers').findBy('secondPartAnswerId', parseInt(answerId));
          if (studentAnswer)
            //numStudents++;
            returnList.pushObject(studentAnswer);
        });
        //return numStudents;
        return returnList;
      }
    }),

    numStudentAnswers: Ember.computed('studentAnswers.@each.id', function () {
      if (this.get('studentAnswers')) {
        return this.get('studentAnswers').get('length');
      }
    }),

    studentText: Ember.computed('numStudentAnswers', function () {
      if (this.get('numStudentAnswers') == 1) {
        return "  student";
      } else {
        return " students";
      }
    }),

    numStudentSecPartAnswers: Ember.computed('studentSecondPartAnswers.@each.id', function () {
      if (this.get('studentSecondPartAnswers')) {
        return this.get('studentSecondPartAnswers').get('length');
      }
    }),

    secPartStudentText: Ember.computed('numStudentSecPartAnswers', function () {
      if (this.get('numStudentSecPartAnswers') == 1) {
        return "  student";
      } else {
        return " students";
      }
    }),

    studentChosenSecAnswer: Ember.computed('studentSecondPartAnswers', function () {
      if (this.get('sta') && this.get('sqc')) {
        var studentSecAnswer = this.get('sta').get('studentSecondPartAnswers').findBy('secondPartAnswerId', parseInt(this.get('sqc').get('id')));
        if (studentSecAnswer) {
          return true;
        } else {
          return false;
        }
      }
    }),
    studentChoseAnswer: Ember.computed('studentAnswers', function () {
      if (this.get('sta') && this.get('qc')) {
        var studentAnswer = this.get('sta').get('studentAnswers').findBy('answerId', parseInt(this.get('qc').get('id')));
        if (studentAnswer) {
          return true;
        } else {
          return false;
        }
      }
    }),

    actions: {
      showStudents: function showStudents() {
        this.sendAction('showStudentAction', this.get('studentAnswers'));
      },
      showSecPartStudents: function showSecPartStudents() {
        this.sendAction('showStudentAction', this.get('studentSecondPartAnswers'));
      }
    }
  });
});
define('ohs/routes/linked-student-list', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Student List',
    google: Ember.inject.service('google-integration'),
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    model: function model(params) {
      return Ember.RSVP.hash({
        teacherId: params.teacher_id,
        students: this.store.query('student', { teacherUserId: params.teacher_id }),
        studentSortOptions: [{
          text: "Name",
          id: 1
        }, {
          text: "Last Login",
          id: 2
        }],
        curSort: 1,
        curStudents: Ember.computed('students.@each.id', 'curSort', function () {
          var results;
          if (this.curSort == 1) {
            results = this.students.sortBy('fullName');
          } else if (this.curSort == 2) {
            results = this.students.sortBy('lastLogin');
          }
          return results;
        })
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Student List', model.teacherId);
    },

    actions: {
      sortBy: function sortBy(selectedValue) {
        this.set('currentModel.curSort', selectedValue);
      }
    }

  });
});
define('ohs/components/resource-student-rating', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['aria-hidden', 'role', 'aria-labeledby'],
    role: 'dialog',
    enabled: false,
    isValid: true,
    'aria-labeledby': 'modal-content',
    cancelText: 'Cancel',
    input_value: null,
    names: [],
    validationMessage: '',
    store: Ember.inject.service(),

    'aria-hidden': function () {
      return !this.get('enabled') + '';
    }.property('enabled'),

    enabledClasses: function () {
      return this.get('enabled') ? 'is-open' : 'is-hidden';
    }.property('enabled'),

    showNames: function () {
      console.log('firing computed value updater');
      return this.get('names').length > 0;
    }.property('names.length'),

    hasAnyRatings: Ember.computed('rating.{wasBoring,wasHelpful,wasFun,wasEasy,wasHard,wasQuick,wasTooLong,wasBroken,wasConfusing}', function () {
      if (this.get('rating')) {
        var rating = this.get('rating');
        if (rating.get('wasBoring') || rating.get('wasHelpful') || rating.get('wasFun') || rating.get('wasEasy') || rating.get('wasHard') || rating.get('wasQuick') || rating.get('wasTooLong') || rating.get('wasBroken') || rating.get('wasConfusing')) {
          return true;
        } else {
          return false;
        }
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('assignedObject').get('viewed') && !this.get('rating')) {
        var r = this.get('store').createRecord('resource-student-rating', {
          resource: this.get('assignedObject').get('resource'),
          student: this.get('assignedObject').get('student'),
          ratingDate: new Date(),
          wasHelpful: false,
          wasFun: false,
          wasBoring: false,
          wasTooLong: false,
          wasQuick: false,
          wasConfusing: false,
          wasEasy: false,
          wasHard: false,
          wasBroken: false
        });
        this.set('rating', r);
        r.save();
      }
    },

    actions: {
      toggleModal: function toggleModal() {
        this.set('validationMessage', null);
        this.toggleProperty('enabled');
      },

      save: function save() {
        this.get('rating').set('ratingDate', new Date());
        this.get('rating').save();
        this.set('validationMessage', null);
        this.toggleProperty('enabled');
        this.set("showConfirm", true);
        this.set("confirmationMessage", 'Thanks!');
      },

      cancel: function cancel() {
        this.get('rating').rollbackAttributes();
        this.set('validationMessage', null);
        this.toggleProperty('enabled');
      },

      //Hide alert box
      hideConfirm: function hideConfirm() {
        this.set("showConfirm", false);
        this.set("confirmationMessage", '');
      }

    }
  });
});
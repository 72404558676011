define('ohs/models/latest-activity-teacher-quiz', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    action: _emberData.default.attr('string'),
    date: _emberData.default.attr('date'),

    actionString: Ember.computed('action', 'date', function () {
      if (this.get('date')) {
        return this.get('action') + ' ' + this.get('date').toLocaleDateString();
      } else {
        return this.get('action');
      }
    }),

    linkTo: Ember.computed('action', function () {
      if (this.get('action') == 'Given') {
        return 'teacher-results-1';
      } else if (this.get('action') == 'Created') {
        return 'teacher.quiz-detail';
      }
    }),

    itemId: Ember.computed(function () {
      return this.get('id').replace('Created', '').replace('Given', '');
    })

  });
});
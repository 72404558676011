define('ohs/mirage/fixtures/student-missions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    id: 1,
    numQuestions: 3,
    numAnswered: 3,
    numCorrect: 2,
    sentBy: 'Miss Lons',
    pointsEarned: 200,
    mission: {
      id: 1,
      name: 'Mission to Angles Canyon',
      points: 300,
      order: 1,
      numQuestions: 3,
      testQuestions: [{
        id: 1,
        questionOrder: 1,
        question: {
          id: 1,
          questionText: 'Answer this question',
          answerOptions: [{
            id: 1,
            answerText: 'Answer A',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'A',
            order: 1
          }, {
            id: 2,
            answerText: 'Answer B',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'B',
            order: 2
          }, {
            id: 3,
            answerText: 'Answer C',
            isCorrect: true,
            answerLetter: 'C',
            order: 3
          }, {
            id: 4,
            answerText: 'Answer D',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'D',
            order: 4
          }]
        }
      }, {
        id: 2,
        questionOrder: 2,
        question: {
          id: 2,
          questionText: 'Answer this question',
          answerOptions: [{
            id: 5,
            answerText: 'Answer A',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'A',
            order: 1
          }, {
            id: 6,
            answerText: 'Answer B',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'B',
            order: 2
          }, {
            id: 7,
            answerText: 'Answer C',
            isCorrect: true,
            answerLetter: 'C',
            order: 3
          }, {
            id: 8,
            answerText: 'Answer D',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'D',
            order: 4
          }]
        }
      }, {
        id: 3,
        questionOrder: 3,
        question: {
          id: 3,
          questionText: 'Answer this question',
          answerOptions: [{
            id: '09',
            answerText: 'Answer A',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'A',
            order: 1
          }, {
            id: '10',
            answerText: 'Answer B',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'B',
            order: 2
          }, {
            id: '11',
            answerText: 'Answer C',
            isCorrect: true,
            answerLetter: 'C',
            order: 3
          }, {
            id: '12',
            answerText: 'Answer D',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'D',
            order: 4
          }]
        }
      }]
    },
    studentAnswers: [{
      id: 1,
      questionId: 1,
      answerId: 3,
      isCorrect: true
    }, {
      id: 2,
      questionId: 2,
      answerId: 7,
      isCorrect: true
    }, {
      id: 3,
      questionId: 3,
      answerId: 12,
      isCorrect: false
    }]
  }, {
    id: 2,
    numQuestions: 12,
    numAnswered: 0,
    numCorrect: 0,
    sentBy: 'Mrs Didier',
    mission: {
      id: 2,
      name: 'Obtuse, Acute, Right Mission'
    }
  }, {
    id: 3,
    numQuestions: 12,
    numAnswered: 8,
    numCorrect: 5,
    mission: {
      id: 3,
      name: 'All the Degrees Mission'
    }
  }, {
    id: 99,
    numQuestions: 3,
    numAnswered: 0,
    numCorrect: 0,
    pointsEarned: 0,
    mission: {
      id: 99,
      name: 'Mission to the Cardello Building',
      description: 'On this mission, you will make a few guesses and see how well you can guess the correct answer.',
      points: 300,
      order: 1,
      numQuestions: 3,
      planet: {
        id: 1,
        name: 'Planet Angolix',
        galaxy: {
          id: 1,
          name: 'Geomeda Galaxy'
        }
      },
      testQuestions: [{
        id: 101,
        questionOrder: 1,
        question: {
          id: 101,
          questionText: 'Answer this first question',
          answerOptions: [{
            id: 1101,
            answerText: 'Answer A',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'A',
            order: 1
          }, {
            id: 1102,
            answerText: 'Answer B',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'B',
            order: 2
          }, {
            id: 1103,
            answerText: 'Answer C',
            isCorrect: true,
            answerLetter: 'C',
            order: 3
          }, {
            id: 1104,
            answerText: 'Answer D',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'D',
            order: 4
          }]
        }
      }, {
        id: 102,
        questionOrder: 2,
        question: {
          id: 102,
          questionText: 'Answer this second question',
          answerOptions: [{
            id: 1201,
            answerText: 'Answer A',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'A',
            order: 1
          }, {
            id: 1202,
            answerText: 'Answer B',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'B',
            order: 2
          }, {
            id: 1203,
            answerText: 'Answer C',
            isCorrect: true,
            answerLetter: 'C',
            order: 3
          }, {
            id: 1204,
            answerText: 'Answer D',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'D',
            order: 4
          }]
        }
      }, {
        id: 103,
        questionOrder: 3,
        question: {
          id: 103,
          questionText: 'Answer this third question',
          answerOptions: [{
            id: 1301,
            answerText: 'Answer A',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'A',
            order: 1
          }, {
            id: 1302,
            answerText: 'Answer B',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'B',
            order: 2
          }, {
            id: 1303,
            answerText: 'Answer C',
            isCorrect: true,
            answerLetter: 'C',
            order: 3
          }, {
            id: 1304,
            answerText: 'Answer D',
            isCorrect: false,
            cognitiveMistakeDesc: 'The student made the wrong choice',
            answerLetter: 'D',
            order: 4
          }]
        }
      }]
    }
  }];
});
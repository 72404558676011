define('ohs/routes/student-group-add-remove', ['exports', 'ohs/mixins/authorized-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authorizedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authorizedRouteMixin.default, _breadcrumbMixin.default, {
    authorizedRoles: ['Tutor', 'Teacher'],

    titleToken: function titleToken(model) {
      return model.studentGroup.get('studentGroupName');
    },
    signalR: Ember.inject.service('signal-r'),

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      //var self = this;
      return Ember.RSVP.hash({
        students: this.store.query('student', { groupId: 0 }, { reload: true }),
        //students: this.store.findAll('student',{reload:true}),
        studentGroup: this.store.findRecord('studentGroup', params.group_id, { reload: true }),
        studentSearch: "",
        filteredStudents: Ember.computed('studentSearch', 'students.[]', function () {
          return this.students.filter(function (stu, index, array) {
            if (this.studentSearch) {
              return stu.get('fullName').toLowerCase().includes(this.studentSearch.toLowerCase()) || stu.get('userName').toLowerCase().includes(this.studentSearch.toLowerCase());
            } else {
              return true;
            }
          }, this).sortBy('lastName');
        })
      });
    },
    afterModel: function afterModel(model) {
      this._super(model, 'Add or Remove Students', model.studentGroup.get('id'));
      //Signal-R event subscription
      this.get('signalR').on('joinRoster', this, 'joinRoster');
    },


    deactivate: function deactivate() {
      $('li:contains("Students")').find('li:contains("My Groups")').children().first().removeClass('active');
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Students")').find('li:contains("My Groups")').children().first().addClass('active');
      }, 100);
    },

    actions: {
      hideKeyboard: function hideKeyboard() {
        document.activeElement.blur();
      },

      goToQuizzes: function goToQuizzes() {
        this.transitionTo('teacher-quizzes');
      },

      loading: function loading(transition, originRoute) {
        if (originRoute != this) return true;
      }
    },

    joinRoster: function joinRoster() {
      this.refresh();
    }

    // Commented out to fix a bug that occurs when we delete a user from roster and  redirect to this route will show the deleted user.
    // currently deleted user records are not deleted from the store
    //afterModel: function(curModel) {
    //Ember.set(curModel,'students', this.store.peekAll('student').sortBy('lastName'));
    //}

  });
});
define('ohs/routes/sign-up-student', ['exports', 'ohs/mixins/reset', 'ohs/mixins/no-navigation'], function (exports, _reset, _noNavigation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _noNavigation.default, {
    titleToken: 'Create A New Account',

    model: function model(params) {
      var store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        //fields
        user: store.createRecord('student', { userRole: 'Student' }),
        linkingCode: '',
        //options
        grades: this.store.query('grade', { 'gradeType': 'StudentProfle' })
      });
    },

    actions: {
      //transition to Purchase route
      purchaseAction: function purchaseAction() {
        this.transitionTo('purchase');
      },

      //registers a student
      studentRegister: function studentRegister() {
        var self = this;
        var host = self.get('store').adapterFor('application').get('host');

        //setup user for save
        var user = self.get('currentModel.user');
        user.set('avatar', 'Green/aliens_final_green-01.svg');

        //saves the user
        this.send('register', function () {
          //join the student to the roster after save
          Ember.$.getJSON(host + '/students/BackDoorTeacherLink?userName=' + user.get('userName') + '&teacherCode=' + self.get('currentModel.linkingCode')).done(function (response) {
            self.transitionTo('sign-up-success');
          }).fail(function (response) {
            console.log('User created but not joined to roster');
            self.transitionTo('sign-up-success');
          });
        });
      },

      //saves the user
      register: function register(callback) {
        var user = this.get('currentModel.user');

        user.save().then(function (u) {
          callback();
        });
      },

      cancel: function cancel() {
        this.transitionTo('login');
      },

      //centeralized method for validating fields on the screen
      //invalidAttributes *MUST* be part of currentModel
      //allows for a callback on success and failure, must provide caller
      validateFields: function validateFields(successCallback, failCallback, caller) {
        var self = this;

        if (!this.get('currentModel.invalidAttributes')) {
          this.set('currentModel.invalidAttributes', []);
        }

        if (this.get('currentModel.invalidAttributes.length') == 0) {
          caller.set('isProcessing', true);
          document.activeElement.blur();

          caller.set('tick', caller.get('tick') + 1);

          setTimeout(function () {
            if (self.get('currentModel.invalidAttributes.length') > 0) {
              failCallback();
            } else {
              successCallback();
            }
          }, 1000);
        }
      },

      setSubject: function setSubject(subject) {
        this.get('currentModel.user').set('primarySubjectId', subject);
      },

      setGradeLevel: function setGradeLevel(gradeLevelId) {
        this.get('currentModel.user').set('gradeLevelId', gradeLevelId);
      },

      resetUser: function resetUser() {

        var user = this.get('currentModel.user');
        user.set('firstName', null);
        user.set('lastName', null);
        user.set('email', null);
        user.set('username', null);
        user.set('password', null);
        user.set('confirmPassword', null);
        user.set('subject', null);
        user.set('gradeLevelId', null);
        user.set('stateId', null);
        user.set('standardTypeId', null);
        user.set('honorific', null);
        user.set('studentId', null);
        user.set('surveyReferralTypeId', null);
      }

    }

  });
});
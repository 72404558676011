define('ohs/components/x-question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['qb-question'],
    accepted: false,
    current: false,

    classNameBindings: ['dynamicClasses'],

    dynamicClasses: Ember.computed('accepted', 'current', function () {
      var classes = '';

      if (this.get('accepted')) {
        classes += 'accepted';
      }

      if (this.get('current')) {
        classes += ' current';
      }

      return classes;
    }),

    didInsertElement: function didInsertElement() {
      this.get('parent').registerChild(this);
    },

    markAccepted: function markAccepted(state) {
      this.set('accepted', state);
    },

    markCurrent: function markCurrent(state) {
      this.set('current', state);
    },

    orderedAnswers: Ember.computed('question.answerOptions.@each.id', function () {
      return this.get('question.answerOptions').sortBy('id');
    }),
    orderedSecondPartAnswers: Ember.computed('question.secondPartQuestion.secondPartAnswerOptions.@each.id', function () {
      return this.get('question.secondPartQuestion.secondPartAnswerOptions').sortBy('id');
    }),

    actions: {
      showIssue: function showIssue() {
        this.sendAction('showIssueAction');
      },

      showPreviousUsed: function showPreviousUsed() {
        this.sendAction('showPreviousUsed');
        //this.set('showPrevousQuizzes',true);
      },

      closeModal: function closeModal() {
        this.set('showPrevousQuizzes', false);
      },

      //Hide alert box
      hideAlert: function hideAlert() {
        this.set("showAlert", false);
        this.set("alertMessage", '');
      }

    }
  });
});
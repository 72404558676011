define('ohs/routes/teacher/quiz/teacher-led', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        afterModel: function afterModel(model) {
            var administration = Ember.get(model, 'administration');
            if (Ember.get(administration, 'isComplete')) {
                this.transitionTo('teacher-results-1', Ember.get(administration, 'id'), '1');
            } else if (Ember.get(administration, 'isOpen') && Ember.get(administration, 'quiz.subject.id') == 2) {
                this.transitionTo('teacher.quiz.teacher-led.ela.classroom-view', Ember.get(administration, 'id'));
            } else if (Ember.get(model, 'quiz.subject.id') == 2) {
                this.transitionTo('teacher.quiz.teacher-led.lobby', Ember.get(administration, 'id'));
            }
        },


        actions: {
            willTransition: function willTransition(transition) {
                switch (Ember.get(transition, 'targetName')) {
                    case 'teacher-results-1':
                    case 'teacher-quizzes':
                    case 'teacher-self-guided-results':
                    case 'error':
                    case 'teacher.quiz.teacher-led.lobby':
                    case 'teacher.quiz.teacher-led.ela.classroom-view':
                    case 'teacher.quiz.teacher-led.ela.teacher-view':
                        return true;
                    default:
                        if (Ember.get(this, 'currentModel.isTransitionApproved')) {
                            Ember.set(this, 'currentModel.isTransititonApproved', false);
                            return true;
                        }

                        Ember.set(this, 'currentModel.transition', transition);
                        Ember.set(this, 'currentModel.confirmTransition', true);
                        transition.abort();
                        break;
                }
            },
            nextQuestion: function nextQuestion() {
                var _this = this;

                this.toggleProperty('currentModel.isProcessing');
                this.incrementProperty('currentModel.administration.currentQuestionIndex');
                Ember.get(this, 'currentModel.administration').save().then(function () {
                    _this.toggleProperty('currentModel.isProcessing');
                }, function () {
                    Ember.get(_this, 'currentModel.administration').rollbackAttributes();
                    Ember.set(_this.modelFor('teacher.quiz'), 'showAlert', true);
                    Ember.set(_this.modelFor('teacher.quiz'), 'currentModel.alertMessage', "Could not navigate to next question. Please check your internet connection and try again.");
                    _this.toggleProperty('currentModel.isProcessing');
                });
            },
            previousQuestion: function previousQuestion() {
                var _this2 = this;

                this.decrementProperty('currentModel.administration.currentQuestionIndex');
                this.toggleProperty('currentModel.isProcessing');
                Ember.get(this, 'currentModel.administration').save().then(function () {
                    _this2.toggleProperty('currentModel.isProcessing');
                }, function () {
                    _this2.toggleProperty('currentModel.isProcessing');
                    Ember.get(_this2, 'currentModel.administration').rollbackAttributes();
                    Ember.set(_this2, 'currentModel.showAlert', true);
                    Ember.set(_this2, 'currentModel.alertMessage', "Could not navigate to previous question. Please check your internet connection and try again.");
                });
            },
            lockAnswers: function lockAnswers() {
                var _this3 = this;

                Ember.set(this, 'currentModel.isProcessing', true);
                var admin = Ember.get(this, 'currentModel.administration');
                this.toggleProperty('currentModel.administration.answersLocked');

                admin.save().then(function () {
                    Ember.set(_this3, 'currentModel.isProcessing', false);
                }, function (error) {
                    admin.rollbackAttributes();
                    Ember.set(_this3, 'currentModel.isProcessing', false);
                    Ember.set(_this3.modelFor('teacher.quiz'), "showAlert", true);
                    var message = Ember.get(admin, 'answersLocked') ? "Failed to unlock the answers. Please check your internet connection and try again." : "Failed to lock the answers. Please check your internet connection and try again.";
                    Ember.set(_this3.modelFor('teacher.quiz'), "alertMessage", message);
                });
            }
        }
    });
});
define('ohs/controllers/student-teacher-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        isTeachersEmpty: Ember.computed.empty('model.student.teachers'),
        isTutorsEmpty: Ember.computed.empty('model.student.tutors'),
        isMissingRoser: Ember.computed.and('isTeachersEmpty', 'isTutorsEmpty')
    });
});
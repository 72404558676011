define('ohs/models/standard-type', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    standardTypeName: _emberData.default.attr('string'),
    level1Label: _emberData.default.attr('string'),
    level2Label: _emberData.default.attr('string'),
    level3Label: _emberData.default.attr('string'),
    level4Label: _emberData.default.attr('string'),

    text: Ember.computed('standardTypeName', function () {
      return this.get('standardTypeName');
    }),

    level1LabelAltText: Ember.computed('level1Label', function () {
      if (this.get('id') == 1023) {
        return "Standard Area";
      } else {
        return this.get('level1Label');
      }
    }),

    level2LabelAltText: Ember.computed('level2Label', function () {
      if (this.get('id') == 1023) {
        return "Strand";
      } else {
        return this.get('level2Label');
      }
    }),

    level3LabelAltText: Ember.computed('level3Label', function () {
      if (this.get('id') == 1023) {
        return "Standard";
      } else {
        return this.get('level3Label');
      }
    })
  });
});
define('ohs/components/x-tip-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['aria-hidden', 'role', 'aria-labeledby'],
    classNames: ['modal'],
    classNameBindings: ['isOpen', 'isHidden'],
    role: 'dialog',
    'aria-labeledby': 'modal-content',
    isOpen: true,
    isHidden: false,
    doNotShowAgain: false,

    'aria-hidden': function () {
      return !this.get('isOpen') + '';
    }.property('isOpen'),

    actions: {
      openModal: function openModal() {
        this.set('isOpen', true);
        this.set('isHidden', false);
      },

      closeModal: function closeModal() {
        this.set('isOpen', false);
        this.set('isHidden', true);
      },

      proceed: function proceed() {
        this.sendAction('proceed', this.get('doNotShowAgain'));
      },

      noThanks: function noThanks() {
        this.sendAction('noThanks', this.get('doNotShowAgain'));
      },

      checkChange: function checkChange() {
        if (this.get('doNotShowAgain')) {
          this.set('doNotShowAgain', false);
        } else {
          this.set('doNotShowAgain', true);
        }
      }
    }
  });
});
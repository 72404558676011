define('ohs/routes/edit-class-resource-folder', ['exports', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/authenticated-route-mixin'], function (exports, _reset, _breadcrumbMixin, _teacherAuthorizeMixin, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _breadcrumbMixin.default, {

    titleToken: function titleToken(model) {
      return 'Edit class resource folder';
    },

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    model: function model(params) {
      //Find the previous route and redirect to it after completing the task
      var previousRoutes = this.router.router.currentHandlerInfos;
      var previousRoute = previousRoutes && previousRoutes.pop();
      if (previousRoute) {
        this.set('lastVisitedRoute', previousRoute.name);
      }

      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];

      return Ember.RSVP.hash({
        teacher: this.store.findRecord('teacher', userId),
        folderId: params.folder_id,
        classLibraryFolder: this.store.findRecord('class-library-folder', params.folder_id),

        // classLibraryFolders:this.store.peekAll('class-library-folder').findBy('parentFolder.id',params.folder_id),
        // classResources:this.store.peekAll('class-resource').findBy('classLibraryFolder.id',params.folder_id),
        // classLinkedResources:this.store.peekAll('class-linked-resource').findBy('classLibraryFolder.id',params.folder_id),

        tick: 1
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Edit Folder', model.folderId, false);
    },

    actions: {

      cancel: function cancel() {
        var folderId = this.get('currentModel.folderId');
        this.get('currentModel.classLibraryFolder').rollbackAttributes();
        if (this.get('lastVisitedRoute')) {
          this.transitionTo(this.get('lastVisitedRoute'), folderId);
        } else {
          this.transitionTo('class-library', folderId);
        }
      },
      //decide whether or not should the transition occur.
      willTransition: function willTransition(transition) {
        this.get('currentModel.classLibraryFolder').rollbackAttributes();
      },


      save: function save() {
        var self = this;
        var classLibraryFolder = self.get('currentModel.classLibraryFolder');
        //make sure we have a validation array on the user object
        if (!classLibraryFolder.get('invalidAttributes')) {
          classLibraryFolder.set('invalidAttributes', new Array());
        }
        if (classLibraryFolder.get('invalidAttributes.length') == 0) {
          self.set('currentModel.isProcessing', true);
          document.activeElement.blur();

          //increment the tick so that validation is done for each field
          self.set('currentModel.tick', self.get('currentModel.tick') + 1);

          //delay for 2 seconds to allow validations to happen
          setTimeout(function () {
            if (classLibraryFolder.get('invalidAttributes.length') > 0) {
              //user needs to fix validation errorMessage
              self.set('currentModel.isProcessing', false);
            } else {
              //save the user
              self.createClassLibraryFolders();
            }
          }, 1000);
        }
      },

      hideAlert: function hideAlert() {
        var self = this;
        self.set('currentModel.alertMessage', '');
        self.set('currentModel.showAlert', false);
      },

      hideConfirm: function hideConfirm() {
        var self = this;
        var folderId = self.get('currentModel.folderId');
        if (self.get('lastVisitedRoute')) {
          self.transitionTo(self.get('lastVisitedRoute'), folderId);
        } else {
          self.transitionTo('class-library', folderId);
        }
      }

    },

    createClassLibraryFolders: function createClassLibraryFolders() {
      var self = this;
      var folderId = self.get('currentModel.folderId');
      var classLibraryFolder = self.get('currentModel.classLibraryFolder');
      classLibraryFolder.set('teacher', self.get('currentModel.teacher'));
      classLibraryFolder.save().then(function (u) {
        self.updateBreadCrumbs();
        self.set('currentModel.confirmMessage', 'Your information has been saved successfully!');
        self.set('currentModel.isProcessing', false);
      }, function (error) {
        self.set('currentModel.isProcessing', false);
        self.set('currentModel.showAlert', true);
        if (error && error.errors && error.errors.length > 0 && error.errors[0].detail) {
          self.set('currentModel.alertMessage', error.errors[0].detail);
        } else {
          self.set('currentModel.alertMessage', 'There was a problem creating the user. Please check your internet connection and try again.');
        }
      });
    },

    //Common Methods
    updateBreadCrumbs: function updateBreadCrumbs() {
      var breadcrumbs = this.get('session').get('breadcrumbs');
      if (breadcrumbs.length > 1) {
        var bc = breadcrumbs[breadcrumbs.length - 2];
        if (bc) {
          this.get('session').get('breadcrumbs').popObject(); //pop 'Edit Item'
          this.get('session').get('breadcrumbs').popObject(); //pop old item name
        }
      }
    }
  });
});
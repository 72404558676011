define('ohs/models/mission-control/mission', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    name: attr('string'),
    description: attr('string'),
    points: attr('number'),
    order: attr('number'),

    planet: belongsTo('mission-control/planet'),
    standards: hasMany('mission-control/standard'),
    activity: belongsTo('mission-control/activity'),
    userSentMissions: hasMany('user-sent-missions'),
    studentMissions: hasMany('student-missions'),

    numQuestions: Ember.computed.alias('activity.activityItems.length'),

    pointsPerQuestion: Ember.computed('activity.activityItems.[]', function () {
      var points = Ember.get(this, 'points');
      var questionCount = Ember.get(this, 'activity.activityItems.length');

      return points / questionCount;
    }),

    /* NOTE:
     * in order for the below computeds to work
     * you must ensure the planet and the planet.missions are loaded
     */
    next: Ember.computed('planet.missions.@each.order', function () {
      var missions = this.get('planet.sortedMissions');
      var index = this.get('planet.sortedMissions').indexOf(this);

      if (index + 1 !== missions.get('length')) {
        return missions.objectAt(index + 1);
      }

      return null;
    }),

    previous: Ember.computed('planet.missions.@each.order', function () {
      var missions = this.get('planet.sortedMissions');
      var index = this.get('planet.sortedMissions').indexOf(this);

      if (index !== 0) {
        return missions.objectAt(index - 1);
      }

      return null;
    })
  });
});
define('ohs/models/user-tracker', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    date: _emberData.default.attr('date'),
    user: _emberData.default.belongsTo('user'),
    loginDateFormatted: Ember.computed('date', function () {
      if (this.get('date')) {
        var date = this.get('date').toLocaleDateString();
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (today.toLocaleDateString() == date) return 'Today';else if (yesterday.toLocaleDateString() == date) {
          return 'Yesterday';
        } else {
          return date;
        }
      }
    }),

    loginTimeFormatted: Ember.computed('date', function () {
      if (this.get('date')) {
        var date = this.get('date');
        var newDate = new Date(date);
        var hours = newDate.getHours();
        var minutes = newDate.getMinutes();
        var sec = newDate.getSeconds();
        // return hours+':'+minutes+':'+sec;
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      }
    })
  });
});
define('ohs/components/resource-search-start', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      setGrade: function setGrade(value) {
        this.sendAction('setGradeAction', value);
      },

      setSubject: function setSubject(value) {
        this.sendAction('setSubjectAction', value);
      },

      setTarget: function setTarget(value) {
        this.sendAction('setTargetAction', value);
      }

    }
  });
});
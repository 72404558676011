define('ohs/routes/student-list', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/breadcrumb-mixin', 'ohs/mixins/tip-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _breadcrumbMixin, _tipMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _breadcrumbMixin.default, _tipMixin.default, {
    titleToken: 'All Students',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },
    google: Ember.inject.service('google-integration'),
    model: function model(params) {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];

      return Ember.RSVP.hash({
        teacher: this.store.findRecord('teacher', userId),
        students: this.store.query('student', { groupId: params.group_id }),
        searchBox: '',
        showUsernamesPasswords: false,
        filteredStudents: Ember.computed('students.@each.id', 'searchBox', function () {
          return this.students.filter(function (stu, index, array) {
            if (this.searchBox) {
              return stu.get('fullName').toLowerCase().includes(this.searchBox.toLowerCase()) || stu.get('userName').toLowerCase().includes(this.searchBox.toLowerCase());
            } else {
              return true;
            }
          }, this).sortBy('lastName');
        })
      });
    },


    afterModel: function afterModel(model) {
      this._super(model, 'All Students', false, true);

      var tip = this.store.peekRecord('tip', 9);
      if (tip) {
        model.showTip = true;
        model.tipText = tip.get('tipText');
      }
    },

    actions: {
      hideKeyboard: function hideKeyboard() {
        document.activeElement.blur();
      },

      drill: function drill(id) {
        this.transitionTo('student-profile', id);
      },

      goToQuizzes: function goToQuizzes() {
        this.transitionTo('teacher-quizzes');
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
        this.set('currentModel.alertMessage', '');
      },

      setShowUsernames: function setShowUsernames() {
        var currentlyShown = this.get('currentModel.showUsernamesPasswords');
        this.set('currentModel.showUsernamesPasswords', !currentlyShown);
      },

      printStudents: function printStudents() {
        if (this.get('currentModel.students') && this.get('currentModel.students.length') > 0) {
          var self = this;
          self.set('currentModel.isPrinting', true);
          var baseUrl = this.store.adapterFor('application').get('host');

          var sesObj = this.get('session');
          var userId = sesObj.session.content.authenticated["userId"];
          var displayName = sesObj.session.content.authenticated["displayName"];
          //remove last dot from display name
          displayName = displayName.slice(0, -1);

          return Ember.$.getJSON(baseUrl + '/students/GenerateTeacherLinkedStudentsPdf/' + encodeURIComponent(userId) + '/' + encodeURIComponent(displayName)).done(function (response) {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', baseUrl + '/quiz/DownloadQuizQuestions?fileName=' + encodeURIComponent(response), true);
            xhr.responseType = "blob";
            xhr.onreadystatechange = function () {
              if (xhr.readyState == 4) {
                var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
                if (isSafari) {
                  var blob = new Blob([xhr.response], { type: "application/octet-stream" });
                } else {
                  var blob = new Blob([xhr.response], { type: "application/pdf" });
                }
                saveAs(blob, response);
              }
            };
            xhr.send(null);

            self.set('currentModel.isPrinting', false);
          }).fail(function (response) {
            self.set("currentModel.error", true);
            self.set('currentModel.isPrinting', false);
          });
        } else {
          this.set('currentModel.showAlert', true);
          this.set('currentModel.alertMessage', 'There is no student on your roster to print!');
        }
      },


      closeTip: function closeTip() {
        this.destroyTip(9);
        this.set('currentModel.showTip', false);
      },

      showTip: function showTip() {
        var self = this;
        var uid = this.get('currentModel.teacher.id');
        var userTip = this.store.createRecord('userTip', {
          userId: uid,
          tipId: 9
        });
        userTip.save().then(function () {
          var tip = self.store.peekRecord('tip', 9);
          if (tip) {
            self.set('currentModel.showTip', true);
            self.set('currentModel.tipText', tip.get('tipText'));
          }
        });
      },
      createStudent: function createStudent() {
        this.transitionTo('new-user', 'Student', 0);
      },


      pullFromClassroom: function pullFromClassroom() {
        var _this = this;

        this.toggleProperty('currentModel.syncing');
        var userId = this.get('session').session.content.authenticated["userId"];
        this.get('google').pullClassroom(userId, false).then(function () {
          _this.toggleProperty('currentModel.showConfirm');
          _this.set('currentModel.confirmMessage', 'Success! Your students and courses have been pulled in from Classroom!');
          _this.toggleProperty('currentModel.syncing');
        }, function (error) {
          _this.toggleProperty('currentModel.showAlert');
          _this.set('currentModel.alertMessage', error);
          _this.toggleProperty('currentModel.syncing');
        });
      },

      hideConfirm: function hideConfirm() {
        this.set('currentModel.showConfirm', false);
        this.refresh();
      }

      // hideAlert: function() {
      //   this.set('currentModel.showAlert',false);
      //   this.set('currentModel.alertMessage','');
      // },

    },

    joinRoster: function joinRoster() {
      this.refresh();
    }
  });
});
define('ohs/models/usage-tracker', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = _emberData.default.Model.extend((_DS$Model$extend = {
    honorific: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    avatar: _emberData.default.attr('string'),
    numberOfLogins: _emberData.default.attr('number'),
    numberOfQuizzesCreated: _emberData.default.attr('number'),
    numberOfQuizzesGiven: _emberData.default.attr('number')
  }, _defineProperty(_DS$Model$extend, 'numberOfQuizzesGiven', _emberData.default.attr('number')), _defineProperty(_DS$Model$extend, 'numberOfResourcesSent', _emberData.default.attr('number')), _defineProperty(_DS$Model$extend, 'numberOfQuizzesTaken', _emberData.default.attr('number')), _defineProperty(_DS$Model$extend, 'numberOfResourcesViewed', _emberData.default.attr('number')), _defineProperty(_DS$Model$extend, 'numberOfTrophiesEarned', _emberData.default.attr('number')), _DS$Model$extend));
});
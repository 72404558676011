define('ohs/components/account-selectable-student', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['selectable-student-item'],
    classNameBindings: ['itemClass'],
    store: Ember.inject.service(),

    actionButtonText: Ember.computed('teacher.students.@each.id', function () {
      if (this.get('teacher.students').includes(this.get('student'))) {
        return "Remove";
      } else {
        return "+ Add";
      }
    }),

    itemClass: Ember.computed('teacher.students.@each.id', function () {
      if (this.get('teacher.students').includes(this.get('student'))) {
        return ' in-group';
      } else {
        return '';
      }
    }),

    acceptDisabled: Ember.computed('teacher.students.@each.id', function () {
      if (this.get('teacher.students').includes(this.get('student'))) {
        return true;
      } else {
        return false;
      }
    }),

    removeDisabled: Ember.computed('teacher.students.@each.id', function () {
      if (this.get('teacher.students').includes(this.get('student'))) {
        return false;
      } else {
        return true;
      }
    }),

    actions: {
      toggleStudent: function toggleStudent() {
        var self = this;
        self.isProcessing(true);
        if (this.get('teacher.students').includes(this.get('student'))) {
          this.get('teacher.students').removeObject(this.get('student'));
        } else {
          this.get('teacher.students').pushObject(this.get('student'));
        }
        var teacherId = this.get('teacherId');
        var studentId = this.get('student.id');
        Ember.$.getJSON(this.get('store').adapterFor('application').get('host') + '/students/AddOrRemoveTeacherLinkedStudents/' + encodeURIComponent(teacherId) + '/' + encodeURIComponent(studentId)).done(function (response) {
          self.isProcessing(false);
        }).fail(function (response) {
          self.isProcessing(false);
        });
      },

      linkAction: function linkAction() {
        this.sendAction('linkAction', this.get('student').get('id'));
      }
    },

    //Methods

    //For Enable/Disable all the buttons in the div with 'group-actions' class
    isProcessing: function isProcessing(status) {
      if (status == true) {
        //Placing a dynamic grid 'over' on the top of these button’s div tag
        $(".group-actions").append('<div id="over" class="disable"></div>');

        //Disable 'Finished' link button while doing Add/Remove student group.
        //But there is no way to disable a link.
        //As a work around, add an onclick fuction and return false
        $(".button-subtle").attr("disabled", "disabled").on("click", function () {
          return false;
        });
      } else {
        //Removes the dynamic grid 'over' from the top of the button’s div tag only after the API interaction is finished
        $(".group-actions").find("#over").remove();

        //re-enable link
        $(".button-subtle").removeAttr("disabled").off("click");
      }
    }
  });
});
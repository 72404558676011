define('ohs/routes/parent/parent-dashboard', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/parent-authorize-mixin'], function (exports, _authenticatedRouteMixin, _reset, _parentAuthorizeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _parentAuthorizeMixin.default, {
    titleToken: 'Parent Dashboard',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('parent_many_children', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      this.store.unloadAll('student-trophy');
    },

    signalR: Ember.inject.service('signal-r'),

    model: function model() {
      this.get('signalR').administrationId = 0;
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      var currentStudentId = sesObj.get('currentStudent');
      var currentUser = this.store.findRecord('parent', userId);

      if (currentStudentId) {
        return Ember.RSVP.hash({
          userId: userId,
          student: this.store.findRecord('user', currentStudentId),
          user: currentUser,

          allAdmins: this.store.query('student-test-administration', { userId: encodeURIComponent(currentStudentId) }),
          // openAdministrations: this.store.query('student-test-administration', {isOpen:true}),

          // openAdministrationsOrdered: Ember.computed('openAdministrations.@each.id',function() {
          //   return this.openAdministrations.sortBy('id').reverse();
          // }),

          recentResults: Ember.computed('allAdmins.@each.isFinished', function () {
            return this.allAdmins.filter(function (sta, index, array) {
              return sta.get('isFinished');
            }).sortBy('dateCompletedSort').reverse().slice(0, 3);
          }),

          latestParentResourceActivity: this.store.query('latest-activity-parent-resource', { parentUserId: encodeURIComponent(userId), studentUserId: encodeURIComponent(currentStudentId) }),

          latestParentResourceActivityDisplay: Ember.computed('latestParentResourceActivity.@each.id', function () {
            return this.latestParentResourceActivity.sortBy('date').reverse().slice(0, 3);
          }),

          resources: this.store.query('resource-assigned', { userId: encodeURIComponent(currentStudentId) }),

          newResources: Ember.computed('resources.@each.viewed', function () {
            return this.resources.filterBy('viewed', false).sortBy('assignedDate').reverse();
          }),

          studentTrophies: this.store.query('student-trophy', { userId: encodeURIComponent(currentStudentId) }),

          latestTrophies: Ember.computed('studentTrophies.@each.dateEarned', function () {
            return this.studentTrophies.sortBy('dateEarned').reverse().slice(0, 3);
          })

        });
      } else {
        var self = this;
        return Ember.RSVP.hash({
          userId: userId,
          user: currentUser,
          noStudentsLinked: "You don’t have any students linked to your account yet! To add a student, give them the following code and have them enter it on their Parents/Guardians page: "
        });
      }
    },

    activate: function activate() {
      var self = this;
      window.addEventListener("resize", function (e) {
        self.resizeCanvas();
      }, false);
    },

    resizeCanvas: function resizeCanvas() {
      $('.trophy-img').each(function () {
        $(this).css('height', $(this).width() * $(this).css('min-height').replace("px", ""));
      });
    }

  });
});
define('ohs/mixins/passage-modal-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    // afterModel(model,bcText,routeParam,clearBreadcrumbs) {
    // },

    actions: {
      showPassage: function showPassage() {
        this.set('currentModel.showPassageModal', true);
        $('body').addClass('modal-active');
      },
      hidePassage: function hidePassage() {
        this.set('currentModel.showPassageModal', false);
        $('body').removeClass('modal-active');
      }
    }
  });
});
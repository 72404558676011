define('ohs/routes/quiz-approved-resources', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/breadcrumb-mixin', 'ohs/mixins/resource-actions-mixin', 'ohs/mixins/tip-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _breadcrumbMixin, _resourceActionsMixin, _tipMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _breadcrumbMixin.default, _resourceActionsMixin.default, _tipMixin.default, {
    titletoken: function titletoken(model) {
      return 'Approve Resources for ' + model.quiz.get('quizName');
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', false);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      this.store.unloadAll('test-approved-resource');
    },

    model: function model(params) {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      return Ember.RSVP.hash({
        token: 0,
        returnToDetail: params.return_detail,
        quiz: this.store.find('quiz', params.test_id),
        resources: this.store.query('resource', { testId: params.test_id }),
        curUser: this.store.findRecord('teacher', userId),
        belowResources: Ember.computed('resources.@each.id', function () {
          return this.resources.sortBy('resourceTypeSort', 'belowSortValue', 'numStandardsSortValue', 'ratingSortValue', 'numStandards', 'averageRatingSort');
        }),

        atResources: Ember.computed('resources.@each.id', function () {
          return this.resources.sortBy('resourceTypeSort', 'atSortValue', 'numStandardsSortValue', 'ratingSortValue', 'numStandards', 'averageRatingSort');
        }),

        aboveResources: Ember.computed('resources.@each.id', function () {
          return this.resources.sortBy('resourceTypeSort', 'aboveSortValue', 'numStandardsSortValue', 'ratingSortValue', 'numStandards', 'averageRatingSort');
        }),

        approvedResources: this.store.findAll('test-approved-resource'),

        approvedBelowResources: Ember.computed('approvedResources.@each.id', function () {
          var self = this;
          return this.approvedResources.filter(function (item) {
            return item.get('interestLevel.id') == 1 && item.get('quiz.id') == self.quiz.get('id');
          });
        }),

        approvedAtResources: Ember.computed('approvedResources.@each.id', function () {
          var self = this;
          return this.approvedResources.filter(function (item) {
            return item.get('interestLevel.id') == 2 && item.get('quiz.id') == self.quiz.get('id');
          });
        }),

        approvedAboveResources: Ember.computed('approvedResources.@each.id', function () {
          var self = this;
          return this.approvedResources.filter(function (item) {
            return item.get('interestLevel.id') == 3 && item.get('quiz.id') == self.quiz.get('id');
          });
        }),

        acceptedBelow: [],
        acceptedAt: [],
        acceptedAbove: []

      });
    },

    afterModel: function afterModel(model) {
      if (model.returnToDetail == '1') {
        this._super(model, 'Choose Resources', [{ 'param': model.quiz.get('id') }, { 'param': model.returnToDetail }]);
      }

      // var tip = this.store.peekRecord('tip',10);
      // if (tip) {
      //   model.showTip = true;
      //   model.tipText = tip.get('tipText');
      // }

      // //pre-populate some recommendations
      // var approvedForThisTest = model.approvedResources.filterBy('quiz.id',model.quiz.get('id'));
      // if (approvedForThisTest.length == 0) {
      //   //add a below level resource
      //   var self = this;
      //   var firstBelowResource = model.resources.sortBy('resourceTypeSort','belowSortValue','numStandardsSortValue','ratingSortValue','numStandards','averageRatingSort').toArray()[0];
      //   var below = this.store.peekRecord('interestLevel',1);
      //   var belowResource = this.store.createRecord('test-approved-resource', {
      //     quiz: model.quiz,
      //     interestLevel: below,
      //     resource: firstBelowResource
      //   });
      //
      //   //add an at level resource
      //   var firstAtResource = model.resources.sortBy('resourceTypeSort','atSortValue','numStandardsSortValue','ratingSortValue','numStandards','averageRatingSort').toArray()[0];
      //   var at = this.store.peekRecord('interestLevel',2);
      //   var atResource = this.store.createRecord('test-approved-resource', {
      //     quiz: model.quiz,
      //     interestLevel: at,
      //     resource: firstAtResource
      //   });
      //
      //   //add an above level resource
      //   var firstAboveResource = model.resources.sortBy('resourceTypeSort','aboveSortValue','numStandardsSortValue','ratingSortValue','numStandards','averageRatingSort').toArray()[0];
      //   var above = this.store.peekRecord('interestLevel',3);
      //   var aboveResource = this.store.createRecord('test-approved-resource', {
      //     quiz: model.quiz,
      //     interestLevel: above,
      //     resource: firstAboveResource
      //   });
      // }
    },

    deactivate: function deactivate() {
      $('li:contains("Quizzes")').children().first().removeClass('active');
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Quizzes")').children().first().addClass('active');
      }, 100);
    },

    actions: {
      closeTip: function closeTip() {
        this.destroyTip(10);
        this.set('currentModel.showTip', false);
      },

      save: function save() {
        var self = this;

        //THIS NEEDS TO BE RE-DONE AS ATTEMPTING TO SAVE WITHOUT CHANGES CAUSES ERRORS.
        //WE NEED TO COMPARE THE OLD EXISTING RESOURCES WITH THE CURRENTLY ACCEPTED RESOURCES AND MAKE A DECISTION TO DELETE, CREATE, OR LEAVE ALONE AS NECESSARY

        if (this.get('currentModel.acceptedBelow.length') > 0 || this.get('currentModel.acceptedAt.length') > 0 || this.get('currentModel.acceptedAbove.length') > 0) {

          self.set('currentModel.token', 0);
          //Handle the below resources

          //delete unselected resources
          var approvedBelow = this.get('currentModel.approvedBelowResources');
          var acceptedBelow = this.get('currentModel.acceptedBelow');
          approvedBelow.forEach(function (item) {
            var isAccepted = acceptedBelow.find(function (a) {
              return a.get('id') == item.get('resource.id');
            });
            if (!isAccepted) {
              self.set('currentModel.token', self.get('currentModel.token') + 1);
              item.destroyRecord().then(function () {
                self.set('currentModel.token', self.get('currentModel.token') - 1);
              }, function () {
                self.set('currentModel.token', self.get('currentModel.token') - 1);
              });
            }
          });

          //insert newly selected resources
          if (acceptedBelow.get('length') > 0) {
            acceptedBelow.forEach(function (item) {
              var alreadyApproved = approvedBelow.find(function (a) {
                return a.get('resource.id') == item.get('id');
              });
              if (!alreadyApproved) {
                self.set('currentModel.token', self.get('currentModel.token') + 1);
                var below = self.store.peekRecord('interestLevel', 1);
                var newResource = self.store.createRecord('test-approved-resource', {
                  quiz: self.get('currentModel.quiz'),
                  interestLevel: below,
                  resource: item
                });
                newResource.save().then(function () {
                  self.set('currentModel.token', self.get('currentModel.token') - 1);
                }, function () {
                  self.set('currentModel.token', self.get('currentModel.token') - 1);
                });
              }
            });
          }

          //Handle the at resources

          //delete unselected resources
          var approvedAt = this.get('currentModel.approvedAtResources');
          var acceptedAt = this.get('currentModel.acceptedAt');
          approvedAt.forEach(function (item) {
            var isAccepted = acceptedAt.find(function (a) {
              return a.get('id') == item.get('resource.id');
            });
            if (!isAccepted) {
              self.set('currentModel.token', self.get('currentModel.token') + 1);
              item.destroyRecord().then(function () {
                self.set('currentModel.token', self.get('currentModel.token') - 1);
              }, function () {
                self.set('currentModel.token', self.get('currentModel.token') - 1);
              });
            }
          });

          //insert newly selected resources
          if (acceptedAt.get('length') > 0) {
            acceptedAt.forEach(function (item) {
              var alreadyApproved = approvedAt.find(function (a) {
                return a.get('resource.id') == item.get('id');
              });
              if (!alreadyApproved) {
                self.set('currentModel.token', self.get('currentModel.token') + 1);
                var at = self.store.peekRecord('interestLevel', 2);
                var newResource = self.store.createRecord('test-approved-resource', {
                  quiz: self.get('currentModel.quiz'),
                  interestLevel: at,
                  resource: item
                });
                newResource.save().then(function () {
                  self.set('currentModel.token', self.get('currentModel.token') - 1);
                }, function () {
                  self.set('currentModel.token', self.get('currentModel.token') - 1);
                });
              }
            });
          }

          //Handle the above resources

          //delete unselected resources
          var approvedAbove = this.get('currentModel.approvedAboveResources');
          var acceptedAbove = this.get('currentModel.acceptedAbove');
          approvedAbove.forEach(function (item) {
            var isAccepted = acceptedAbove.find(function (a) {
              return a.get('id') == item.get('resource.id');
            });
            if (!isAccepted) {
              self.set('currentModel.token', self.get('currentModel.token') + 1);
              item.destroyRecord().then(function () {
                self.set('currentModel.token', self.get('currentModel.token') - 1);
              }, function () {
                self.set('currentModel.token', self.get('currentModel.token') - 1);
              });
            }
          });

          //insert newly selected resources
          if (acceptedAbove.get('length') > 0) {
            acceptedAbove.forEach(function (item) {
              var alreadyApproved = approvedAbove.find(function (a) {
                return a.get('resource.id') == item.get('id');
              });
              if (!alreadyApproved) {
                self.set('currentModel.token', self.get('currentModel.token') + 1);
                var above = self.store.peekRecord('interestLevel', 3);
                var newResource = self.store.createRecord('test-approved-resource', {
                  quiz: self.get('currentModel.quiz'),
                  interestLevel: above,
                  resource: item
                });
                newResource.save().then(function () {
                  self.set('currentModel.token', self.get('currentModel.token') - 1);
                }, function () {
                  self.set('currentModel.token', self.get('currentModel.token') - 1);
                });
              }
            });
          }

          self.waitForDone(self);

          // if (this.get('currentModel.returnToDetail') == '1') {
          //   this.transitionTo('teacher.quiz-detail',this.get('currentModel.quiz.id'));
          // }
          // else {
          //   this.transitionTo('review-1',this.get('currentModel.quiz.id'));
          // }
        } else {
          this.set('currentModel.showAlert', true);
          this.set('currentModel.alertMessage', 'You need to choose at least one resource to send!');
        }
      },

      checkClear: function checkClear() {
        this.set('currentModel.showClearAlert', true);
      },

      backToReview: function backToReview() {
        this.transitionTo('review-1', this.get('currentModel.quiz.id'));
      },

      clear: function clear() {
        var self = this;
        this.set('currentModel.token', 0);
        var approved = this.get('currentModel.approvedResources').filterBy('quiz.id', this.get('currentModel.quiz.id'));
        approved.forEach(function (item, index, enumerable) {
          self.set('currentModel.token', self.get('currentModel.token') + 1);
          item.destroyRecord().then(function () {
            self.set('currentModel.token', self.get('currentModel.token') - 1);
          }, function () {
            self.set('currentModel.token', self.get('currentModel.token') - 1);
          });
        });
        this.waitForDone(this);
        // if (this.get('currentModel.returnToDetail') == '1') {
        //   this.transitionTo('teacher.quiz-detail',this.get('currentModel.quiz.id'));
        // }
        // else {
        //   this.transitionTo('review-1',this.get('currentModel.quiz.id'));
        // }
      },

      hideConfirm: function hideConfirm() {
        if (this.get('currentModel.returnToDetail') == '1') {
          this.transitionTo('teacher.quiz-detail', this.get('currentModel.quiz.id'));
        } else {
          this.transitionTo('review-1', this.get('currentModel.quiz.id'));
        }
      },

      hideIssueConfirm: function hideIssueConfirm() {
        this.set('currentModel.showIssueConfirm', false);
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
      },

      delete: function _delete(approvedResource) {
        approvedResource.destroyRecord();
      },

      showNavAlert: function showNavAlert(url) {
        this.set('currentModel.showNavConfirmModal', true);
        this.set('currentModel.navigateURL', url);
      },

      reportIssue: function reportIssue(reportItem) {
        var self = this;
        var adapter = this.get('store').adapterFor('application');

        var url = adapter.get('host') + '/accounts/reportResource?resourceId=' + reportItem.resourceId + '&issue=' + reportItem.issue + '&comment=' + reportItem.comments;
        if (reportItem.comments == '') {
          url = adapter.get('host') + '/accounts/reportResource?resourceId=' + reportItem.resourceId + '&issue=' + reportItem.issue;
        }
        adapter.ajax(url, 'POST').then(function () {
          self.set('currentModel.showIssueConfirm', true);
          self.set('currentModel.issueConfirmationMessage', 'Your report has been received and is currently being reviewed. If we have any follow up questions, someone will be in touch!');
        }, function () {
          self.set('currentModel.showAlert', true);
          self.set("currentModel.alertMessage", "There was an error sending your report. Please check your internet connection and try again.");
        });
        reportItem.comments = '';
        reportItem.issue = null;
      }

    },

    waitForDone: function waitForDone(self) {
      if (!self.isDone()) {
        setTimeout(self.waitForDone, 1000, self);
      } else {
        if (self.get('currentModel.returnToDetail') == '1') {
          self.transitionTo('teacher.quiz-detail', self.get('currentModel.quiz.id'));
        } else {
          self.transitionTo('review-1', self.get('currentModel.quiz.id'));
        }
      }
    },

    isDone: function isDone() {
      return this.get('currentModel.token') == 0;
    }

  });
});
define('ohs/components/x-table', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        tagName: 'table',
        classNameBindings: ['table_classes'],

        store: Ember.inject.service(),

        headers: Ember.computed('x_headers.[]', 'headerOffset', function () {
            var x_headers = this.get('x_headers');
            var headers = [];
            for (var y = 0; y < this.get('headerOffset'); y++) {
                headers.push({ text: '', classes: '', average: '' });
            }

            return headers.concat(x_headers);
        })
    });
});
define('ohs/routes/student/quiz/game-mode', ['exports', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/authenticated-route-mixin'], function (exports, _reset, _noNavigation, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reset.default, _noNavigation.default, _authenticatedRouteMixin.default, {
        afterModel: function afterModel() {
            var studentTestAdministration = get(this.modelFor('student.quiz'), 'studentTestAdministration');
            //if the administration is open go to the in-progress route 
            //else go to the waiting area for that type
            if (get(studentTestAdministration, 'administration.isOpen')) {
                this.transitionTo('student.quiz.game-mode.in-progress', get(studentTestAdministration, 'id'));
            } else if (get(studentTestAdministration, 'administration.isComplete')) {
                this.transitionTo('student-results', get(studentTestAdministration, 'id'), '1');
            } else {
                this.transitionTo('student.quiz.game-mode.lobby', get(studentTestAdministration, 'id'));
            }
        }
    });
});
define('ohs/routes/passage-quiz-detail', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/teacher-authorize-mixin'], function (exports, _authenticatedRouteMixin, _reset, _noNavigation, _teacherAuthorizeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _noNavigation.default, _teacherAuthorizeMixin.default, {
    titleToken: function titleToken(model) {
      return model.passagequiz.createQuiz1.quiz.get('quizName');
    },

    model: function model(params) {
      var store = this.store;
      var passagequiz = this.modelFor('passage-quiz');
      return Ember.RSVP.hash({
        passagequiz: this.modelFor('passage-quiz'),
        curPassageId: params.passage_id,
        passage: this.store.peekRecord('passage', params.passage_id),
        isThroughPassage: true,
        editingQuizName: false
      });
    },
    actions: {
      editQuiz: function editQuiz() {
        this.transitionTo('create-quiz-1', 'existingQuiz');
      },
      backToPassages: function backToPassages() {
        this.transitionTo('passage-quiz');
      },
      confirmPassage: function confirmPassage() {
        this.transitionTo('passage-quiz-questions', this.currentModel.curPassageId);
      }
    }
  });
});
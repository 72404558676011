define('ohs/routes/teacher/resource-ratings', ['exports', 'ohs/mixins/authorized-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authorizedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authorizedRouteMixin.default, _breadcrumbMixin.default, {
    authorizedRoles: ['Tutor', 'Teacher'],
    titleToken: 'Your Ratings',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var sesObj = this.get('session');
      var uId = sesObj.session.content.authenticated["userId"];

      return Ember.RSVP.hash({
        ratings: this.store.findAll('resource-user-review'),
        perPage: 10,
        numShown: 10,
        subjectOptions: Ember.computed('ratings.@each.resource', function () {
          var subjects = [];
          var subjectIds = [];
          this.ratings.forEach(function (r) {
            if (subjectIds.indexOf(r.get('resource.subject.id')) < 0) {
              subjects.pushObject(r.get('resource.subject'));
              subjectIds.pushObject(r.get('resource.subject.id'));
            }
          });
          return subjects.sortBy('id');
        }),
        subjectFilter: '',
        hasMoreItems: Ember.computed('ratings.@each.id', 'numShown', function () {
          var filtered = this.ratings.filter(function (r, index, array) {
            var inSubject = true;
            if (this.subjectFilter) {
              inSubject = r.get('resource').get('subject.id') == this.subjectFilter;
            }
            return inSubject;
          }, this);

          if (this.ratings.filterBy('user.id', uId).get('length') > this.numShown) {
            return true;
          } else {
            return false;
          }
        }),

        myRatings: Ember.computed('ratings.@each.user', function () {
          return this.ratings.filterBy('user.id', uId);
        }),

        curRatings: Ember.computed('ratings.@each.id', 'numShown', 'curSort', 'subjectFilter', function () {
          var results = this.ratings.filter(function (r, index, array) {

            var inSubject = true;
            if (this.subjectFilter) {
              inSubject = r.get('resource').get('subject.id') == this.subjectFilter;
            }
            return inSubject;
          }, this);

          if (this.curSort == 1) {
            results = results.filterBy('user.id', uId).sortBy('ratingScore').reverse().slice(0, this.numShown);
          } else if (this.curSort == 2) {
            results = results.filterBy('user.id', uId).sortBy('ratingDate').reverse().slice(0, this.numShown);
          } else if (this.curSort == 3) {
            results = results.filterBy('user.id', uId).sortBy('resource.resourceName').slice(0, this.numShown);
          } else {
            results = results.filterBy('user.id', uId).slice(0, this.numShown);
          }
          return results.slice(0, this.numShown);
        }),
        curSort: 1,
        selectedRating: null,

        sortingOpts: [{
          id: 1,
          text: "Rating"
        }, {
          id: 2,
          text: "Rating date"
        }, {
          id: 3,
          text: "Resource name"
        }]

      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Resource Ratings', false, true);
    },

    actions: {
      sortRatings: function sortRatings(selectedValue) {
        this.set('currentModel.curSort', selectedValue);
      },

      searchResources: function searchResources() {
        this.transitionTo('teacher.resource-search-start');
      },

      drill: function drill(id) {
        this.transitionTo('teacher.resource-detail', id);
      },

      confirmRemoveRating: function confirmRemoveRating(rating) {
        this.set('currentModel.selectedRating', rating);
        this.set('currentModel.showModal', true);
        this.set('currentModel.modalMessage', 'Are you sure you want to delete this rating?');
      },


      removeRating: function removeRating() {
        this.set('currentModel.showModal', false);
        this.set('currentModel.modalMessage', null);

        var self = this;
        var rating = this.get('currentModel.selectedRating');
        rating.destroyRecord().then(function () {}, function () {
          self.set('currentModel.warningMessage', "Your rating could not be deleted. Please check your Internet connection and try again.");
          self.set("currentModel.showAlert", true);
        });
      },

      filtersubject: function filtersubject(selectedValue) {
        this.set('currentModel.subjectFilter', selectedValue);
      }

    }

  });
});
define('ohs/components/labeled-input-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['x-input x-input-select'],
    isMultiLineSelect: true,

    revalidate: Ember.observer('tickNumber', 'value', function () {
      this.validate();
    }),

    validate: function validate() {
      if (this.get('validationType')) {
        //set invalid properties on the model
        if (!this.get('model.invalidAttributes')) {
          this.set('model.invalidAttributes', new Array());
        }
        if (this.get('validationType') == "notBlank") {
          if (this.get('value')) {
            this.set('errorMessage', null);
            if (this.get('model.invalidAttributes').includes(this.get('displayName'))) {
              var index = this.get('model.invalidAttributes').indexOf(this.get('displayName'));
              this.get('model.invalidAttributes').splice(index, 1);
            }
          } else {
            var errorMessage;
            switch (this.get('displayName')[0].toUpperCase()) {
              case 'O':
              case 'A':
              case 'I':
              case 'E':
                errorMessage = 'You must select an ' + this.get('displayName');
                break;
              default:
                errorMessage = 'You must select a ' + this.get('displayName');
                break;
            }
            if (this.get('displayName')[0]) this.set('errorMessage', errorMessage);
            if (!this.get('model.invalidAttributes').includes(this.get('displayName'))) {
              this.get('model.invalidAttributes').push(this.get('displayName'));
            }
          }
        }
      }
    },


    didRender: function didRender() {
      //convert select control with style selectric-box into jquery-selectric plug-in control
      if (this.isMultiLineSelect) this.$().find('.selectric-box').selectric();
    },

    actions: {
      setProperty: function setProperty() {
        var selectedEl = this.$('select')[0];
        var selectedIndex = selectedEl.selectedIndex;
        var selectedValue = selectedEl[selectedIndex].value;
        this.sendAction('setPropertyAction', selectedValue);
      }
    }

  });
});
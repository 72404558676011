define('ohs/routes/student-quizzes', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/student-authorize-mixin', 'ohs/mixins/breadcrumb-mixin', 'ohs/mixins/tip-mixin'], function (exports, _authenticatedRouteMixin, _reset, _studentAuthorizeMixin, _breadcrumbMixin, _tipMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _studentAuthorizeMixin.default, _breadcrumbMixin.default, _tipMixin.default, {
    titleToken: 'My Current Quizzes',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('student', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      return Ember.RSVP.hash({
        subjects: Ember.get(this, 'store').peekAll('subject'),
        usedSubjects: Ember.computed('subjects.[]', 'studentTestAdmiinstrations.[]', function () {
          return Ember.get(this, 'studentTestAdministrations').reduce(function (current, sta) {
            return !current.some(function (sub) {
              return Ember.get(sub, 'id') == Ember.get(sta, 'administration.quiz.subject.id');
            }) ? current.concat(Ember.get(sta, 'administration.quiz.subject')) : current;
          }, []);
        }),
        // administrations: get(this, 'store').findAll('administration'),
        // quizzes: get(this, 'store').findAll('quiz'),
        studentTestAdministrations: this.store.query('student-test-administration', { isOpen: true }),
        filteredSTAs: Ember.computed('studentTestAdministrations.[]', 'selectedSubjectId', function () {
          var stas = Ember.get(this, 'studentTestAdministrations');
          if (Ember.get(this, 'selectedSubjectId')) {
            stas = stas.filterBy('administration.quiz.subject.id', Ember.get(this, 'selectedSubjectId'));
          }
          return stas.sortBy('administration.id').reverse();
        }),
        quizCode: "",
        errorMessage: "",
        administrationId: "",
        buttonsDisabled: false
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Quizzes', false, true);

      var tip = this.store.peekRecord('tip', 7);
      if (tip) {
        model.showTip = true;
      }
    },

    actions: {
      joinNow: function joinNow() {
        document.activeElement.blur();

        var self = this;
        self.set('currentModel.buttonsDisabled', true);

        if (this.get("currentModel.quizCode").trim()) {
          self.set("currentModel.errorMessage", '');
          var sesObj = this.get('session');
          var userId = sesObj.session.content.authenticated["userId"];
          return Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/administrations/getAdministrationId?testCode=' + encodeURIComponent(this.get("currentModel.quizCode").trim()) + '&userId=' + encodeURIComponent(userId)).done(function (response) {
            //If already joined the quiz (student test administration). Move the student into the student-quiz-room route for that quiz.
            if (response.id) {
              self.transitionTo('student-quiz-room', response.id);
            }
            //If not joined already, join the quiz and move the student into the student-quiz-room route for that quiz.
            else if (response.administrationId) {
                var studentTestAdministration = self.store.createRecord('student-test-administration', {
                  administrationId: response.administrationId,
                  userId: userId,
                  isFinished: false,
                  invitedThrough: 'Joined using access code'
                });

                studentTestAdministration.save().then(function (data) {

                  self.transitionTo('student-quiz-room', data.id);
                });
              }
          }).fail(function (response) {
            if (response.status == 0) {
              self.set("currentModel.errorMessage", 'Please check your internet connection and try again.');
            } else {
              self.set("currentModel.errorMessage", response.responseText);
            }
            self.set('currentModel.buttonsDisabled', false);
          });
        } else {
          self.set("currentModel.errorMessage", 'Access code  can\'t be blank');
          self.set('currentModel.buttonsDisabled', false);
        }
      },

      transitionToQuiz: function transitionToQuiz(studentTestId) {
        this.transitionTo('student-quiz-room', studentTestId);
      },

      drill: function drill(id) {
        this.transitionTo('student-quiz-room', id);
      },

      closeTip: function closeTip() {
        this.destroyTip(7);
        this.set('currentModel.showTip', false);
      },

      goToProfile: function goToProfile() {
        this.destroyTip(7);
        this.transitionTo('user-profile');
      }

    }
  });
});
define('ohs/components/game-mode-student-banner', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        teamColor: Ember.computed('team.teamColor.id', function () {
            return 'team-' + Ember.get(this, 'team.teamColor.class');
        })
    });
});
define('ohs/components/teacher-quiz-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'section',
        classNames: ['quiz-item-group'],
        numberShown: 10

    }).reopenClass({
        positionalParams: ['quizzes']
    });
});
define('ohs/routes/edit-quiz-questions', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _breadcrumbMixin.default, {
    titleToken: function titleToken(model) {
      return model.quiz.get('quizName');
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', false);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      var store = this.store;
      return this.store.find('quiz', params.quiz_id).then(function (q) {
        return Ember.RSVP.hash({
          quiz: q,
          returnToDetail: params.return_detail,
          questions: store.query('question', { 'standardId': q.get('eligibleContentQueryString') })
        });
      });
    },


    afterModel: function afterModel(model) {
      if (model.returnToDetail == '1') this._super(model, 'Edit Questions', [{ 'param': model.quiz.get('id') }, { 'param': model.returnToDetail }]);
    },

    actions: {
      letsGo: function letsGo(quizId) {
        if (this.currentModel.returnToDetail == '1') {
          this.transitionTo('teacher.quiz-detail', quizId);
        } else {
          this.transitionTo('review-1', quizId);
        }
      }
    }

  });
});
define('ohs/routes/teacher/quiz/teacher-led/ela', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        actions: {
            switchView: function switchView() {
                this.toggleProperty('currentModel.isTeacherView');
                if (get(this, 'currentModel.isTeacherView')) {
                    this.transitionTo('teacher.quiz.teacher-led.ela.teacher-view', get(this, 'currentModel.administration.id'));
                } else {
                    this.transitionTo('teacher.quiz.teacher-led.ela.classroom-view', get(this, 'currentModel.administration.id'));
                }
            }
        }
    });
});
define('ohs/models/mission-control/answer-option', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany,
        belongsTo = _emberData.default.belongsTo;
    exports.default = _emberData.default.Model.extend({
        rationale: attr('string'),
        correct: attr('boolean'),
        order: attr('number'),
        text: attr('string'),

        question: belongsTo('mission-control/question'),
        studentMissionAnswer: hasMany('student-mission-answer'),

        studentMissionAnswerCount: Ember.computed.alias('studentMissionAnswer.length')
    });
});
define('ohs/routes/student-accounts', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Student Accounts',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          } else if (roles.indexOf("Parent") > 0) {
            this.controllerFor('application').set('parent_many_children', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
          this.controllerFor('application').set('parent_many_children', '');
        }
      }
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },
    model: function model() {
      var account = this.store.peekAll('account');
      var accountId = account.objectAt(0).id;
      var userId = this.get('session').session.content.authenticated["userId"];

      return Ember.RSVP.hash({

        students: this.store.query('student', { accountId: accountId }),
        loadFinished: true,
        curSort: 0,
        searchBox: '',
        studentAccountsOptions: [{
          text: "First Name",
          id: 0
        }, {
          text: "Last Name",
          id: 1
        }, {
          text: "Username",
          id: 2
        }, {
          text: "Email",
          id: 3
        }, {
          text: "Password",
          id: 4
        }, {
          text: "Grade Level",
          id: 5
        }],

        curStudentDetails: Ember.computed('students.@each.id', 'curSort', 'searchBox', function () {
          var results;
          var searchResults = this.students.filter(function (stud, index, array) {
            if (this.searchBox) {
              return stud.get('fullName').toLowerCase().includes(this.searchBox.toLowerCase()) || stud.get('userName').toLowerCase().includes(this.searchBox.toLowerCase());
            } else {
              return true;
            }
          }, this).sortBy('fullNameReversed');

          if (this.curSort == 0) {
            results = searchResults.sortBy('fullName');
          } else if (this.curSort == 1) {
            results = searchResults.sortBy('lastName');
          } else if (this.curSort == 2) {
            results = searchResults.sortBy('userName'); //.reverse();
          } else if (this.curSort == 3) {
            results = searchResults.sortBy('email').reverse();
          } else if (this.curSort == 4) {
            results = searchResults.sortBy('passwordEncrypt').reverse();
          } else if (this.curSort == 5) {
            results = searchResults.sortBy('gradeLevelId'); //.reverse();
          }
          return results;
        })

      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Student Accounts', false, true);
    },

    actions: {

      sortBy: function sortBy(selectedValue) {
        this.set('currentModel.curSort', selectedValue);
      },

      drill: function drill(id) {
        this.transitionTo('student-account-details', id);
      },
      confirmRemoveStudent: function confirmRemoveStudent(student) {
        this.set('currentModel.selectedStudent', student);
        this.set('currentModel.showModal', true);
        this.set('currentModel.modalMessage', 'Are you sure you want to delete this student account?');
      },
      removeStudent: function removeStudent() {
        this.set('currentModel.showModal', false);
        this.set('currentModel.modalMessage', null);

        var self = this;
        var student = this.get('currentModel.selectedStudent');
        student.destroyRecord().then(function () {
          //self.refresh();
          self.set("currentModel.showConfirm", true);
          self.set("currentModel.confirmMessage", 'Student has been deleted from your account!');
        }, function () {
          self.set("currentModel.showAlert", true);
          self.set('currentModel.alertMessage', "Student could not be deleted. Please check your Internet connection and try again.");
        });
      },


      hideConfirm: function hideConfirm() {
        var self = this;
        self.refresh();
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
        this.set('currentModel.alertMessage', '');
      },

      createStudent: function createStudent() {
        this.transitionTo('new-user', 'Student', 0);
      }
    }
  });
});
define('ohs/models/team', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        teamColor: _emberData.default.belongsTo('teamColor'),
        studentTestAdministrations: _emberData.default.hasMany('student-test-administration'),
        administration: _emberData.default.belongsTo('administration'),

        changeName: Ember.observer('teamColor.name', function () {
            this.set('name', 'Team ' + this.get('teamColor.name'));
        }),

        score: Ember.computed('studentTestAdministrations.@each.numPointsEarned', function () {
            var totalEarned = Ember.get(this, 'studentTestAdministrations').reduce(function (totalEarned, sta) {
                return totalEarned + Ember.get(sta, 'numPointsEarned');
            }, 0);
            var totalAvailable = Ember.get(this, 'studentTestAdministrations').reduce(function (totalAvailable, sta) {
                return totalAvailable + Ember.get(sta, 'numPointsAvailable');
            }, 0);

            return Math.round(totalEarned / totalAvailable * 100) || 0;
        })
    });
});
define('ohs/routes/teacher-results-2', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: function titleToken(model) {
      return model.studentTestAdministration.get('administration').get('quiz.quizName');
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    model: function model(params) {
      return Ember.RSVP.hash({
        studentTestAdministration: this.store.findRecord('studentTestAdministration', params.student_test_id),
        quiz: this.store.queryRecord('quiz', { studentTestAdministrationId: params.student_test_id }),
        testQuestions: this.store.query('test-question', { studentTestId: params.student_test_id }),
        orderedQuestions: Ember.computed('studentTestAdministration.administration.quiz.testQuestions.@each.id', function () {
          return this.studentTestAdministration.get('administration').get('quiz').get('testQuestions').sortBy('questionOrder');
        })
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, model.studentTestAdministration.get('student.fullName'), model.studentTestAdministration.get('id'));
      model.loadFinished = true;
    },

    deactivate: function deactivate() {
      $('li:contains("Quizzes")').find('li:contains("Results")').children().first().removeClass('active');
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Quizzes")').find('li:contains("Results")').children().first().addClass('active');
      }, 100);
    },

    actions: {
      refresh: function refresh() {
        this.refresh();
      },

      drill: function drill() {
        this.transitionTo('student-profile', this.get('currentModel.studentTestAdministration.student.id'));
      }
    }
  });
});
define('ohs/components/shared-quiz-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'article',
    classNames: ['quiz-item'],
    classNameBindings: ['itemClass'],
    store: Ember.inject.service(),

    createdBy: Ember.computed('quiz.user.id', function () {
      if (Ember.get(this, 'currentUserId') == Ember.get(this, 'quiz.user.id')) return 'You';else return Ember.get(this, 'quiz.user.fullName');
    }),

    actions: {
      addToMyQuizzes: function addToMyQuizzes() {
        var self = this;
        var testId = this.get('quiz.id');
        Ember.$.getJSON(this.get('store').adapterFor('application').get('host') + '/quizzes/AddToMyQuizzes/' + encodeURIComponent(testId)).done(function (response) {
          self.set("showConfirm", true);
          self.set("confirmMessage", 'Successfully added !');
        }).fail(function (response) {
          self.set("showAlert", true);
          self.set('alertMessage', "Quiz could not be shared. Please check your Internet connection and try again.");
        });
      },

      removeFromMyQuizzes: function removeFromMyQuizzes() {
        var self = this;
        var testId = this.get('quiz.id');
        Ember.$.getJSON(this.get('store').adapterFor('application').get('host') + '/quizzes/RemoveFromMyQuizzes/' + encodeURIComponent(testId)).done(function (response) {
          self.set("showConfirm", true);
          self.set("confirmMessage", 'Successfully removed !');
        }).fail(function (response) {
          self.set("showAlert", true);
          self.set('alertMessage', "Quiz could not be shared. Please check your Internet connection and try again.");
        });
      },
      hideAlert: function hideAlert() {
        this.set('showAlert', false);
        this.set('alertMessage', '');
      },
      hideConfirm: function hideConfirm() {
        this.set('showConfirm', false);
        this.set('confirmMessage', '');
      }

    }
  });
});
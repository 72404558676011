define('ohs/routes/student-profile', ['exports', 'ohs/mixins/authorized-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authorizedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authorizedRouteMixin.default, _breadcrumbMixin.default, {
    authorizedRoles: ['Tutor', 'Teacher'],

    titleToken: function titleToken(model) {
      return model.student.get('fullName');
    },

    ////life cycle hooks
    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Students")').find('li:contains("All Students")').children().first().addClass('active');
      }, 100);
      var self = this;
      window.addEventListener("resize", function (e) {
        self.resizeCanvas();
      }, false);
    },

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super.apply(this, arguments);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);

      Ember.set(controller, 'selectedSchoolYearId', Ember.get(controller, 'schoolYearOptions.lastObject.id'));
    },

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      this.store.unloadAll('student-trophy');

      var self = this;
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      var studentId = transition.handlerInfos[1].params.student_id;
      var hostAddress = self.get('store').adapterFor('application').get('host');

      Ember.$.getJSON(hostAddress + '/students/updateLastViewed?teacherUserId=' + userId + '&studentUserId=' + studentId).done(function (response) {
        if (response)
          // Updated "LastViewed" column  in the "TeacherStudentLink" table.
          return;
      }).fail(function (error) {
        self.controllerFor('application').set('errorStatusCode', error.status);
        self.transitionTo('error');
      });
    },

    model: function model(params) {
      var _EmberGet = Ember.get(this, 'token'),
          userId = _EmberGet.userId,
          roles = _EmberGet.roles;

      var store = Ember.get(this, 'store');
      var user = null;

      if (roles.includes('Tutor')) {
        user = store.findRecord('tutor', userId);
      } else if (roles.includes('Teacher')) {
        user = store.findRecord('teacher', userId);
      }

      return Ember.RSVP.hash({
        ////properties
        showOnlyMyQuizzes: false,
        standardType: Ember.get(this, 'session.standardType'),

        ////store calls
        student: store.findRecord('student', params.student_id, { reload: true }),
        teacher: user,
        teacherGroups: store.findAll('studentGroup'),
        completedQuizzes: store.query('student-test-administration', { isOpen: false, userId: params.student_id }),
        administrations: store.query('administration', { studentUserId: params.student_id }),
        quizzes: store.query('quiz', { studentUserId: params.student_id }),
        resources: store.query('resource-assigned', { userId: params.student_id }),
        categories: store.query('trophy-category', { userId: params.student_id }),
        user: store.findRecord('student', userId),
        grades: store.query('grade', { 'gradeType': 'StudentProfle' }),
        schoolYears: store.findAll('school-year'),
        spaceships: store.query('mission-control/student-spaceship', { studentId: params.student_id }),
        studentMissions: store.query('student-mission', { studentId: params.student_id, onlyPointEligible: true, include: ['UserSentMissions'] }),

        ////mutlilined computeds
        gradeLevel: Ember.computed('user.gradeLevelId', 'grades.@each.id', function () {
          var gradeId = this.user.get('gradeLevelId');
          if (gradeId) {
            var selectedGrade = this.grades.findBy('id', gradeId.toString());
            if (selectedGrade) {
              return selectedGrade.get('text');
            }
          } else {
            return "Not Provided";
          }
        }),

        orderedUserInterests: Ember.computed('student.interestAreas.@each.id', function () {
          return this.student.get('interestAreas').sortBy('description').mapBy('description').join(', ');
        }),

        orderedLearningStyles: Ember.computed('student.learningStyles.@each.id', function () {
          return this.student.get('learningStyles').sortBy('description').mapBy('description').join(', ');
        }),

        orderedResources: Ember.computed('resources.@each.id', function () {
          return this.resources.filterBy('user.id', userId).sortBy('assignedDate').reverse();
        })
      });
    },


    afterModel: function afterModel(model) {
      this._super(model, model.student.get('fullName'), model.student.get('id'));
    },

    deactivate: function deactivate() {
      $('li:contains("Students")').find('li:contains("All Students")').children().first().removeClass('active');
    },

    ////methods
    resizeCanvas: function resizeCanvas() {
      $('.trophy-img').each(function () {
        $(this).css('height', $(this).width() * $(this).css('min-height').replace("px", ""));
      });
    },

    generateCsvContent: function generateCsvContent() {
      var results = this.controllerFor('student-profile').get('sortedAdministrations');
      var csvContent = "Quiz Name,Standard Code,Number Correct,Points Possible,Percent Correct,";
      csvContent += "\n";
      results.forEach(function (item) {
        var quizName = item.get('administration').get('quiz').get('quizName');
        var standardCode = item.get('administration').get('quiz').get('standardCodeDisplay');
        var quizDetails = '"' + quizName + '"' + ',' + standardCode + ',' + item.get('numCorrect') + ',' + item.get('numberPointsAvailable') + ',' + item.get('percentCorrect') + ',';
        csvContent += quizDetails;
        csvContent += "\n";
      });
      return csvContent;
    },


    ////actions
    actions: {
      generateCsv: function generateCsv() {
        var csvContent = "";
        var link = document.createElement("a");
        var fileName = this.get('currentModel.student.fullName') + '_QuizzesResults.csv';
        this.set("currentModel.isProcessing", true);

        //IE 10+
        if (navigator != null && navigator.msSaveBlob) {
          csvContent = this.generateCsvContent();
          var blob = new Blob([csvContent], {
            "type": "text/csv;charset=utf8;"
          });
          navigator.msSaveBlob(blob, fileName);

          document.body.appendChild(link);
          link.click();
          this.set("currentModel.isProcessing", false);
        }
        //In other browsers check for download attribute support
        else if (link.download !== undefined) {
            csvContent = "data:text/csv;charset=utf-8," + this.generateCsvContent();
            var encoded = encodeURI(csvContent);
            link.setAttribute("href", encoded);
            link.setAttribute("download", fileName);

            document.body.appendChild(link);
            link.click();
            this.set("currentModel.isProcessing", false);
          } else {
            //needs to implement server side export
            var administrationId = this.get('currentModel.administration.id');
            var baseUrl = this.store.adapterFor('application').get('host');

            var sesObj = this.get('session');
            var userId = sesObj.session.content.authenticated["userId"];
            var studentUserId = this.get('currentModel.student.id');
            var self = this;
            self.set('currentModel.isPrinting', true);

            return Ember.$.getJSON(baseUrl + '/administrations/GenerateQuizResultCsv?teacherUserId=' + encodeURIComponent(userId) + '&studentUserId=' + encodeURIComponent(studentUserId)).done(function (response) {
              var xhr = new XMLHttpRequest();
              xhr.open('GET', baseUrl + '/quiz/DownloadQuizQuestions?fileName=' + encodeURIComponent(response), true);
              xhr.responseType = "blob";
              xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                  var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
                  if (isSafari) {
                    var blob = new Blob([xhr.response], { type: "application/octet-stream" });
                  } else {
                    var blob = new Blob([xhr.response], { type: "application/pdf" });
                  }
                  saveAs(blob, response);
                }
              };
              xhr.send(null);

              self.set("currentModel.isProcessing", false);
              self.set('currentModel.isPrinting', false);
            }).fail(function (response) {
              self.set("currentModel.isProcessing", false);
              self.set('currentModel.isPrinting', false);
            });
          }
      },


      checkDeleteStudent: function checkDeleteStudent() {
        this.set('currentModel.showModal', true);
      },

      deleteStudent: function deleteStudent() {
        this.set('currentModel.showModal', false);
        var self = this;
        var adapter = this.get('store').adapterFor('application');

        adapter.ajax(adapter.get('host') + '/teachers/removeStudent/' + this.get('currentModel.student').get('id'), 'DELETE').then(function () {
          self.set('currentModel.showConfirm', true);
          self.get('currentModel.teacher.students').removeObject(self.get('currentModel.student'));
          self.get('currentModel.student.studentGroups').forEach(function (item, index) {
            item.get('students').removeObject(self.get('currentModel.student'));
          });
        }, function () {
          self.set('currentModel.showAlert', true);
        });
      },

      updateBreadCrumbs: function updateBreadCrumbs() {
        var breadcrumbs = this.get('session').get('breadcrumbs');
        var bc = breadcrumbs[breadcrumbs.length - 1];
        if (bc) {
          Ember.set(bc, 'text', this.currentModel.student.get('fullName'));
        }
      },

      hideAlert: function hideAlert() {
        this.set('currentModel.showAlert', false);
      },

      hideConfirm: function hideConfirm() {
        this.set('currentModel.showConfirm', false);
        var breadcrumbs = this.get('session.breadcrumbs');
        if (breadcrumbs.length >= 2) {
          var lastBC = breadcrumbs[breadcrumbs.length - 2];
          if (lastBC.model) {
            this.transitionTo(lastBC.route, lastBC.model);
          } else {
            this.transitionTo(lastBC.route);
          }
        } else {
          this.transitionTo('students');
        }
      },

      drillToResult: function drillToResult(id) {
        this.transitionTo('teacher-results-2', id);
      },

      drillToGroup: function drillToGroup(id) {
        this.transitionTo('student-group', id);
      },

      drillToResource: function drillToResource(id) {
        this.transitionTo('teacher.resource-detail', id);
      },

      createGroup: function createGroup() {
        this.transitionTo('student-group-create');
      },

      editGroups: function editGroups() {
        this.transitionTo('student-groups-student', this.get('currentModel.student.id'));
      },

      giveQuiz: function giveQuiz() {
        this.transitionTo('teacher-quizzes');
      },

      searchResources: function searchResources() {
        this.transitionTo('teacher.resource-search-start');
      },

      setShowOnlyMyQuizzes: function setShowOnlyMyQuizzes() {
        this.toggleProperty('currentModel.showOnlyMyQuizzes');
      },

      resetPassword: function resetPassword() {
        var userId = this.get('currentModel.student.id');
        this.transitionTo('reset-user-password', userId);
      },


      editProfile: function editProfile() {
        this.set('currentModel.editProfile', true);
      }

    }
  });
});
define('ohs/models/quiz', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    quizName: attr('string'),
    subjectId: attr('number'),
    gradeLevelId: attr('number'),
    isPublished: attr('boolean', { defaultValue: true }),
    standardCodeDisplay: attr('string'),
    createUserId: attr('string'),
    passageId: attr('number'),
    accountName: attr('string'),
    shared: attr('boolean', { defaultValue: false }),
    isAlreadySharedQuiz: _emberData.default.attr('boolean', { defaultValue: false }),
    copiedFromTestId: attr('number'),
    createdOn: attr('date'),
    hasApprovedResources: attr('boolean', { defaultValue: false }),
    questionsCount: attr('number'),
    timesGiven: attr('number'),
    hasIncompleteAdministrations: attr('boolean'),
    studentsTakenCount: attr('number'),
    lastGiven: attr('date'),
    averageScore: attr('number'),

    subject: belongsTo('subject'),
    user: belongsTo('user'),

    standards: hasMany('standard'),
    testQuestions: hasMany('testQuestion'),
    questions: hasMany('question'),
    administrations: hasMany('administration'),
    passages: hasMany('passage'),

    hasAdministration: Ember.computed.gt('timesGiven', 0),

    //TODO: REFACTOR. The templates need refactored to no longer use these properties. These were created to support
    //backward compatibility with the rest of the application.
    numStudentsTaken: Ember.computed.alias('studentsTakenCount'),
    numQuestions: Ember.computed.alias('questionsCount'),
    hasInCompleteAdministration: Ember.computed.alias('hasIncompleteAdministrations'),
    avgScore: Ember.computed.alias('averageScore'),

    //TODO: REFACTOR. Use Moment.js
    createdDate: Ember.computed('createdOn', function () {
      var date = this.get('createdOn').toLocaleDateString();
      var today = new Date();
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (today.toLocaleDateString() == date) return 'Created Today';else if (yesterday.toLocaleDateString() == date) {
        return 'Created Yesterday';
      } else return 'Created ' + date;
    }),

    //TODO: REFACTOR. Use Moment.js
    lastGivenDisplay: Ember.computed('lastGiven', function () {
      if (this.get('lastGiven')) {
        var date = this.get('lastGiven').toLocaleDateString();
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (today.toLocaleDateString() == date) return 'Last given today';else if (yesterday.toLocaleDateString() == date) {
          return 'Last given yesterday';
        } else {
          return 'Last given ' + date;
        }
      }
    }),

    avgScoreRounded: Ember.computed('avgScore', function () {
      if (this.get('avgScore')) {
        return Math.round(this.get('avgScore'));
      } else {
        return 0;
      }
    }),

    reportingCategory: Ember.computed('standards.[]', function () {
      var stan = this.get('standards');
      //use findBy because we can only have one top level standard added to the standards array
      var rCat = stan.findBy('standardLevelId', 1);
      if (rCat) {
        return parseInt(rCat.get('id'));
      } else {
        return null;
      }
    }),

    assessmentAnchors: Ember.computed('standards', function () {
      var stan = this.get('standards');
      //use filterBy because we can have more than one eligible content so we need this property to be an array
      return stan.filterBy('standardLevelId', 2).sortBy('standardOrderId') || null;
    }),

    eligibleContent: Ember.computed('standards', function () {
      var stan = this.get('standards');
      if (this.get('subjectId') == 2) {
        var cont = stan.filterBy('standardLevelId', 1).sortBy('standardOrderId');
      } else {
        //use filterBy because we can have more than one eligible content so we need this property to be an array
        var cont = stan.filterBy('standardLevelId', 3).sortBy('standardOrderId');
      }
      if (cont) {
        return cont;
      }
    }),

    //TODO: REFACTOR. Why are we forming query requests in the model?!?!?
    eligibleContentQueryString: Ember.computed('standards', function () {
      var stan = this.get('standards');
      // if(this.get('passage.firstObject')){
      if (this.get('subjectId') == 2) {
        var cont = stan.filterBy('standardLevelId', 1).sortBy('standardOrderId');
      } else {
        //use filterBy because we can have more than one eligible content so we need this property to be an array
        var cont = stan.filterBy('standardLevelId', 3).sortBy('standardOrderId');
      }
      //We want a comma separated list of standard ids that we can pass to the api
      var returnString = "";
      if (cont) {
        for (var i = 0; i < cont.length; i++) {
          returnString += cont[i].get('id') + ",";
        }
        if (returnString.length > 0) {
          returnString = returnString.substr(0, returnString.length - 1);
        }
      }
      return returnString;
    }),

    //TODO: REFACTOR. Use Moment.js
    date: Ember.computed('createdOn', function () {
      var date = this.get('createdOn').toLocaleDateString();
      var today = new Date();
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (today.toLocaleDateString() == date) return 'Today';else if (yesterday.toLocaleDateString() == date) {
        return 'Yesterday';
      } else return date;
    })
  });
});
define('ohs/services/google-integration', ['exports', 'ohs/config/environment', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _environment, _applicationRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend(_applicationRouteMixin.default, {
        clientId: '',
        scopes: '',
        store: Ember.inject.service('store'),

        init: function init() {
            var _this = this;

            this.set('clientId', _environment.default.googleClientId);
            this.set('scopes', _environment.default.googleScopes);

            if (typeof gapi != "undefined" && !gapi.auth2) {
                gapi.load('auth2', function () {
                    gapi.auth2.init({
                        client_id: _this.get('clientId'),
                        cookiepolicy: 'single_host_origin'
                    });
                });
            }
        },

        signIn: function signIn() {
            var options = new gapi.auth2.SigninOptionsBuilder({ scope: this.get('scopes').join(' ') });

            return new Ember.RSVP.Promise(function (resolve, reject) {
                gapi.auth2.getAuthInstance().signIn(options).then(function (googleUser) {
                    resolve(googleUser);
                }, function (response) {
                    reject(response);
                });
            });
        },

        linkToGoogle: function linkToGoogle(userId) {
            var _this2 = this;

            return new Ember.RSVP.Promise(function (resolve, reject) {
                _this2.signIn().then(function (googleUser) {
                    var baseUrl = _this2.get('store').adapterFor('application').get('host');
                    Ember.$.post(baseUrl + '/linkToGoogle?userId=' + userId + '&idToken=' + googleUser.getAuthResponse().id_token).then(function () {
                        resolve(googleUser);
                    }, function (response) {
                        reject(response);
                    });
                }, function (response) {
                    reject(response);
                });
            });
        },

        unlinkGoogle: function unlinkGoogle(userId) {
            var baseUrl = this.get('store').adapterFor('application').get('host');
            return new Ember.RSVP.Promise(function (resolve, reject) {
                Ember.$.post(baseUrl + '/unlinkGoogle?userId=' + userId).then(function () {
                    resolve();
                }, function (response) {
                    reject(response);
                });
            });
        },

        pullClassroom: function pullClassroom(userId, ownedOnly) {
            var host = this.get('store').adapterFor('application').get('host');

            return new Ember.RSVP.Promise(function (resolve, reject) {
                gapi.auth2.getAuthInstance().signIn().then(function (x) {
                    var googleUser = x.getAuthResponse(true);
                    var data = {
                        userId: userId,
                        accessToken: googleUser.access_token,
                        expiresIn: googleUser.expires_in,
                        idToken: googleUser.id_token,
                        firstIssued: googleUser.first_issued_at,
                        scope: googleUser.scope,
                        tokenType: googleUser.token_type,
                        googleId: x.El,
                        ownedOnly: ownedOnly
                    };

                    Ember.$.ajax({
                        url: host + '/pullFromGoogle',
                        data: data,
                        dataType: 'application/x-www-form-urlencoded',
                        type: 'POST'
                    }).then(function () {
                        resolve();
                    }, function (response) {
                        reject(response.responseText);
                    });
                }, function () {
                    return;
                });
            });
        }

    });
});
define('ohs/components/teacher-quiz-room', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    distinctGroupings: Ember.computed('joinedStudents', function () {
      var groupings = [];
      var order = 1;

      this.get('joinedStudents').forEach(function (item, index) {
        var sta = item;
        var groupName = sta.get('invitedThrough');
        var existingObj = groupings.findBy('name', groupName);
        if (existingObj) {
          existingObj.students.pushObject(sta);
        } else {
          var thisOrder;
          if (groupName == 'Invited manually') {
            thisOrder = 99;
          } else if (groupName == 'Joined using access code') {
            thisOrder = 100;
          } else {
            thisOrder = order;
            order++;
          }
          var groupObj = { 'name': groupName, 'students': [sta], 'order': thisOrder };
          groupings.pushObject(groupObj);
        }
      });

      return groupings.sortBy('order');
    })

  });
});
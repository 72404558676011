define('ohs/components/color-picker', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        tagName: 'div',
        classNames: ['input select'],
        isOpen: false,

        actions: {
            close: function close() {
                Ember.set(this, 'isOpen', false);
            },
            pickColor: function pickColor(color) {
                this.toggleProperty('isOpen');
                this.sendAction('pickColor', color);
            }
        }
    });
});
define('ohs/mixins/authenticated-route-mixin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
        session: Ember.inject.service('session'),

        token: Ember.computed.alias('session.session.content.authenticated'),

        beforeModel: function beforeModel() {
            this._super.apply(this, arguments);

            if (Ember.get(this, 'session.isAuthenticated')) {
                var _EmberGet = Ember.get(this, 'session.session.content.authenticated'),
                    roles = _EmberGet.roles,
                    forcePasswordChange = _EmberGet.forcePasswordChange,
                    hasRoster = _EmberGet.hasRoster;

                if (!hasRoster && roles.includes('Student')) return this.transitionTo('force-roster-join');

                if (forcePasswordChange) return this.transitionTo('force-password-change');
            }
        }
    });
});
define('ohs/routes/student/quiz/teacher-led', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        actions: {
            willTransition: function willTransition(transition) {
                switch (Ember.get(transition, 'targetName')) {
                    case 'student-passage-quiz':
                    case 'student-quizzes':
                    case 'student-results':
                    case 'student.quiz.teacher-led.ela.in-progress':
                    case 'error':
                        return true;
                    default:
                        if (Ember.get(this, 'currentModel.isTransitionApproved')) {
                            Ember.set(this, 'currentModel.isTransititonApproved', false);
                            return true;
                        }

                        Ember.set(this, 'currentModel.transition', transition);
                        Ember.set(this, 'currentModel.confirmTransition', true);
                        transition.abort();
                        break;
                }
            },


            //shared method for saving a first-part/only-part answer
            //can be overridden by placing this method in child route
            selectAnswer: function selectAnswer(answerOptionId) {
                var studentAnswerId = Ember.get(this, 'currentModel.selectedFirstPartAnswer.id');
                //if there is a studentAnswerId that means the studentAnswer needs updated
                //else we need to create a new one
                var chosenAnswer = Ember.get(this, 'store').peekRecord('answer-option', answerOptionId.toString());
                if (!studentAnswerId) {
                    Ember.get(this, 'store').createRecord('studentAnswer', {
                        answerId: answerOptionId,
                        questionId: parseInt(Ember.get(this, 'currentModel.administration.testQuestion.question.id')),
                        studentTestId: parseInt(Ember.get(this, 'currentModel.studentTestAdministration.id')),
                        isCorrect: Ember.get(chosenAnswer, 'isCorrect'),
                        studentTestAdministration: Ember.get(this, 'currentModel.studentTestAdministration')
                    }).save();
                } else {
                    var answer = Ember.get(this, 'store').peekRecord('studentAnswer', studentAnswerId);
                    Ember.set(answer, 'answerId', answerOptionId);
                    Ember.set(answer, 'isCorrect', Ember.get(chosenAnswer, 'isCorrect'));
                    answer.save();
                }
            },


            //shared method for saving a radio-button second part answer
            //can be overridden by placing this method in child route
            selectSecondAnswer: function selectSecondAnswer(answerId) {
                var chosenAnswer = Ember.get(this, 'store').peekRecord('second-part-answer-option', answerId.toString());
                var currentAnswers = Ember.get(this, 'currentModel.selectedSecondPartAnswers');

                //since this is a radio button we need to maintain one answer
                //this means we need to update the answer in the array
                if (Ember.get(currentAnswers, 'length') > 0) {
                    var currentAnswer = currentAnswers.objectAt(0);
                    Ember.set(currentAnswer, 'secondPartAnswerId', answerId);
                    Ember.set(currentAnswer, 'isCorrect', Ember.get(chosenAnswer, 'isCorrect'));
                }
                //need to create an answer
                else {
                        Ember.get(this, 'store').createRecord('student-second-part-answer', {
                            studentTestId: parseInt(Ember.get(this, 'currentModel.studentTestAdministration.id')),
                            questionId: parseInt(Ember.get(this, 'currentModel.administration.testQuestion.question.id')),
                            secondPartQuestionId: parseInt(Ember.get(this, 'currentModel.administration.testQuestion.question.secondPartQuestion.id')),
                            secondPartAnswerId: answerId,
                            isCorrect: Ember.get(chosenAnswer, 'isCorrect'),
                            studentTestAdministration: Ember.get(this, 'currentModel.studentTestAdministration')
                        }).save();
                    }
            },


            //shared method for saving a checkbox second part answer
            //can be overridden by placing this method in child route
            selectSecondAnswerCheckbox: function selectSecondAnswerCheckbox(answerId) {
                var chosenAnswer = Ember.get(this, 'store').peekRecord('second-part-answer-option', answerId.toString());
                var currentAnswer = Ember.get(this, 'currentModel.selectedSecondPartAnswers').findBy('secondPartAnswerId', parseInt(answerId));

                //since this is a checkbox we need to allow adding multiple answers 
                //this means we need to remove the current answer;
                if (currentAnswer) {
                    currentAnswer.destroyRecord();
                }
                //need to create an answer
                else {
                        Ember.get(this, 'store').createRecord('student-second-part-answer', {
                            studentTestId: parseInt(Ember.get(this, 'currentModel.studentTestAdministration.id')),
                            questionId: parseInt(Ember.get(this, 'currentModel.administration.testQuestion.question.id')),
                            secondPartQuestionId: parseInt(Ember.get(this, 'currentModel.administration.testQuestion.question.secondPartQuestion.id')),
                            secondPartAnswerId: answerId,
                            isCorrect: Ember.get(chosenAnswer, 'isCorrect'),
                            studentTestAdministration: Ember.get(this, 'currentModel.studentTestAdministration')
                        }).save();
                    }
            }
        }
    });
});
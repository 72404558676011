define('ohs/routes/start-quiz-0', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, {
    titleToken: function titleToken(model) {
      return model.quiz.get('quizName');
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model(params) {
      var _this = this;

      return Ember.RSVP.hash({
        quiz: this.store.peekRecord('quiz', params.quiz_id),
        administrations: Ember.computed('quiz.administrations.[]', function () {
          return _this.get('currentModel.quiz.administrations');
        }),

        orderedAdministrations: Ember.computed('administrations.@each.startDate', function () {
          //group linked administrations into a single (open) administration
          var admins = _this.get('currentModel.administrations');
          admins.forEach(function (a) {
            a.set('hideAdmin', false);
            a.set('totalStudentCount', a.get('studentTestAdministrations').get('length'));
          });

          admins.forEach(function (a) {
            if (a.get('parentAdministrationId') != null) {
              var parent = admins.findBy('id', a.get('parentAdministrationId').toString());
              a.set('totalStudentCount', parent.get('studentTestAdministrations').get('length') + a.get('studentTestAdministrations').get('length'));
              a.set('startTime', parent.get('startTime'));
              parent.set('hideAdmin', true);
            }
          });

          return _this.get('currentModel.administrations').filter(function (item) {
            return !item.get('hideAdmin');
          }).sortBy('startTime').reverse();
        })
      });
    },

    actions: {
      startQuiz: function startQuiz() {
        this.transitionTo('teacher.start-quiz.pick-type', this.currentModel.quiz.get('id'));
      },

      deleteSession: function deleteSession(administrationId) {
        var self = this;
        var admin = this.get('currentModel.administrations').findBy('id', administrationId);
        admin.destroyRecord(admin).then(function () {
          self.set('currentModel.showSessionConfirm', true);
          self.set('currentModel.confirmMessage', "This session has been deleted!");
        }, function () {
          self.set('currentModel.showAlert', true);
          self.set('currentModel.alertMessage', "This quiz could not be deleted. Please check your internet connection and try again.");
        });
      },

      hideSessionConfirm: function hideSessionConfirm() {
        this.set('currentModel.showSessionConfirm', false);
      }

    }
  });
});
define('ohs/routes/parent/student-resources', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Resources for Student',
    signalR: Ember.inject.service('signal-r'),

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('parent_many_children', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      var currentStudentId = sesObj.get('currentStudent');
      return Ember.RSVP.hash({
        resources: this.store.query('resource-assigned', { userId: currentStudentId }),
        perPage: 10,
        numShown: 10,
        hasMoreItems: Ember.computed('resources.@each.id', 'numShown', function () {
          if (this.resources.get('length') > this.numShown) {
            return true;
          } else {
            return false;
          }
        }),

        orderedResources: Ember.computed('resources.@each.id', 'numShown', function () {
          return this.resources.sortBy('assignedDate').reverse().slice(0, this.numShown);
        }),

        standardType: sesObj.get('standardType'),
        curUser: this.store.findRecord('parent', userId)
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Resources', false, true);
      //Signal-R event subscription
      this.get('signalR').on('linkParentStudent', this, 'linkParentStudent');
    },

    actions: {
      createBookmark: function createBookmark(resourceId) {
        var curModel = this.currentModel;
        var self = this;
        var curResource = curModel.resources.findBy('resource.id', resourceId);
        var bookmarkForCurResource = curModel.bookmarks.find(function (item, index, enumerable) {
          return item.get('resource').get('id') == resourceId;
        });
        if (curResource && !bookmarkForCurResource) {
          var b = this.store.createRecord('resource-bookmark', {
            resource: curResource.get('resource'),
            user: curModel.curUser,
            bookmarkedDate: new Date()
          });
          b.save().then(function () {
            //self.refresh();
          }, function () {
            self.set('currentModel.warningMessage', "This resource could not be bookmarked. Please check your Internet connection and try again.");
            self.set("currentModel.showAlert", true);
          });
        }
      },

      deleteBookmark: function deleteBookmark(resourceId) {
        var curModel = this.currentModel;
        var self = this;
        var bookmarkForCurResource = curModel.bookmarks.find(function (item, index, enumerable) {
          return item.get('resource').get('id') == resourceId;
        });
        if (bookmarkForCurResource) {
          bookmarkForCurResource.destroyRecord().then(function () {
            //self.refresh();
          }, function () {
            self.set('currentModel.warningMessage', "This bookmark could not be deleted. Please check your Internet connection and try again.");
            self.set("currentModel.showAlert", true);
          });
        }
      }
    },

    linkParentStudent: function linkParentStudent(userId) {
      var sesObj = this.get('session');
      if (!sesObj.get('currentStudent')) {
        sesObj.set('currentStudent', userId);
      }
      this.refresh();
    }
  });
});
define('ohs/routes/teacher/search-results', ['exports', 'ohs/mixins/authorized-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authorizedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authorizedRouteMixin.default, _breadcrumbMixin.default, {
    authorizedRoles: ['Tutor', 'Teacher'],
    titleToken: 'Search Results',

    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
    },

    deactivate: function deactivate() {
      this.set('currentModel.selectedType', 1);
      this.set('currentModel.selectedLevel', 1);
      this.set('currentModel.currentSort', "1");
      $('li:contains("Resources")').find('li:contains("Search")').children().first().removeClass('active');
    },

    activate: function activate() {
      setTimeout(function () {
        $('li:contains("Resources")').find('li:contains("Search")').children().first().addClass('active');
      }, 100);
    },

    // beforeModel: function() {
    //   this.store.unloadAll('resource');
    // },

    model: function model() {
      var curUser = this.modelFor('teacher');
      var self = this;

      var searchParams = this.controllerFor('teacher.resource-search-start');

      if (!searchParams) {
        this.transitionTo('teacher.resource-search-start');
      }

      var r;
      var paramList = {};

      if (searchParams.eligibleContentQueryString.length > 0) {
        paramList = { gradeLevelId: searchParams.selectedGrade, subjectId: searchParams.selectedSubject, targetId: searchParams.selectedTarget, reportingCategoryId: searchParams.selectedReportingCategory, assessmentAnchorId: searchParams.selectedAssessmentAnchor, eligibleContents: searchParams.eligibleContentQueryString };
      } else if (searchParams.selectedAssessmentAnchor.toString().length > 0) {
        paramList = { gradeLevelId: searchParams.selectedGrade, subjectId: searchParams.selectedSubject, targetId: searchParams.selectedTarget, reportingCategoryId: searchParams.selectedReportingCategory, assessmentAnchorId: searchParams.selectedAssessmentAnchor };
      } else if (searchParams.selectedReportingCategory.toString().length > 0) {
        paramList = { gradeLevelId: searchParams.selectedGrade, subjectId: searchParams.selectedSubject, targetId: searchParams.selectedTarget, reportingCategoryId: searchParams.selectedReportingCategory };
      } else {
        paramList = { gradeLevelId: searchParams.selectedGrade, subjectId: searchParams.selectedSubject, targetId: searchParams.selectedTarget };
      }

      var self = this;
      return this.store.query("resource", paramList).then(function (r) {
        return Ember.RSVP.hash({
          curUser: curUser,
          resources: r,
          bookmarks: self.store.findAll('resourceBookmark'),
          gradeLevel: self.store.peekRecord('grade', parseInt(searchParams.selectedGrade)),
          subject: self.store.peekRecord('subject', parseInt(searchParams.selectedSubject)),

          selectedTarget: searchParams.selectedTarget,
          searchBox: searchParams.keywordSearch,
          finalSearch: searchParams.keywordSearch,
          selectedType: 1,
          selectedLevel: 1,
          onlyBookmarks: false,
          noFlash: false,
          commonSenseOnly: false,
          currentSort: "1",

          standardCodeDisplay: Ember.computed('searchParams', function () {
            if (searchParams.eligibleContentQueryString.length > 0) {
              var returnString = "";
              var arr = searchParams.eligibleContentQueryString.split(',');
              for (var i = 0; i < arr.length; i++) {
                var stan = self.store.peekRecord('standard', parseInt(arr[i]));
                returnString += stan.get('standardCode') + ", ";
              }
              if (returnString.length > 0) {
                returnString = returnString.substr(0, returnString.length - 2);
              }
              return returnString;
            } else if (searchParams.selectedAssessmentAnchor.toString().length > 0) {
              var stan = self.store.peekRecord('standard', parseInt(searchParams.selectedAssessmentAnchor));
              return stan.get('standardCode');
            } else if (searchParams.selectedReportingCategory.toString().length > 0) {
              var stan = self.store.peekRecord('standard', parseInt(searchParams.selectedReportingCategory));
              return stan.get('standardCode');
            } else {
              return '';
            }
          }),

          perPage: 20,
          numShown: 20,
          hasMoreItems: Ember.computed('resources.@each.id', 'numShown', function () {
            var r = this.resources.filter(function (resource, index, array) {
              // trim extra space from the resource name
              resource.set('resourceName', resource.get('resourceName').trim());
              var typeFilter = true;
              switch (this.selectedType) {
                case '2':
                  typeFilter = resource.get('resourceType') == "Website" && resource.get('resourceSubtype') == "Skill Practice/Activity";
                  break;
                case '3':
                  typeFilter = resource.get('resourceType') == "App";
                  break;
                case '4':
                  typeFilter = resource.get('resourceType') == "Website" && resource.get('resourceSubtype') == "Video";
                  break;
                case '5':
                  typeFilter = resource.get('resourceType') == "Community Event/Activity";
                  break;
                case '6':
                  typeFilter = resource.get('resourceType') == "Other";
                  break;
                case '7':
                  typeFilter = resource.get('resourceType') == "Website" && resource.get('resourceSubtype') == "Game";
                  break;
                case '9':
                  typeFilter = resource.get('resourceType') == "Website" && resource.get('resourceSubtype') == "N/A";
                  break;
                case '10':
                  typeFilter = resource.get('resourceType') == "Teacher Resources" && resource.get('resourceSubtype') == "Lesson Plan";
                  break;
                case '11':
                  typeFilter = resource.get('resourceType') == "Teacher Resources" && resource.get('resourceSubtype') == "Task";
                  break;
                case '12':
                  typeFilter = resource.get('resourceType') == "Teacher Resources" && resource.get('resourceSubtype') == "Short Answer";
                  break;
                case '13':
                  typeFilter = resource.get('resourceType') == "Teacher Resources" && resource.get('resourceSubtype') == 'TDA';
                  break;
                case '14':
                  typeFilter = resource.get('resourceType') == "Teacher Resources" && resource.get('resourceSubtype') == 'Constructed Response';
                default:
                  typeFilter = true;
              }

              var levelFilter = true;
              switch (this.selectedLevel) {
                case '2':
                  levelFilter = resource.get('interestLevels').findBy('id', '3');
                  break;
                case '3':
                  levelFilter = resource.get('interestLevels').findBy('id', '2');
                  break;
                case '4':
                  levelFilter = resource.get('interestLevels').findBy('id', '1');
                  break;
                default:
                  levelFilter = true;
              }

              var keywordSearch = true;
              if (this.searchBox.length > 0) {
                var splitKeywords = this.searchBox.toLowerCase().split(" ");
                splitKeywords.forEach(function (k) {
                  if (keywordSearch) {
                    keywordSearch = resource.get('resourceName').toLowerCase().indexOf(k) >= 0 || resource.get('description').toLowerCase().indexOf(k) >= 0;
                  }
                });
                // keywordSearch = resource.get('resourceName').toLowerCase().indexOf(this.searchBox.toLowerCase()) >= 0 ||
                //   resource.get('description').toLowerCase().indexOf(this.searchBox.toLowerCase()) >= 0
              }

              return typeFilter && levelFilter && keywordSearch && (!this.onlyBookmarks || this.bookmarks.find(function (item, index, enumerable) {
                return item.get('resource').get('id') == resource.get('id');
              })) && (!this.noFlash || !resource.get('requiresFlash')) && (!this.commonSenseOnly || resource.get('commonSenseApproved'));
            }, this);
            if (r.get('length') > this.numShown) {
              return true;
            } else {
              return false;
            }
          }),

          filteredResources: Ember.computed('resources.@each.id', 'bookmarks.@each.id', 'onlyBookmarks', 'noFlash', 'commonSenseOnly', 'selectedType', 'selectedLevel', 'finalSearch', 'currentSort', 'numShown', function () {

            var results = this.resources.filter(function (resource, index, array) {
              // trim extra space from the resource name
              resource.set('resourceName', resource.get('resourceName').trim());
              var typeFilter = true;
              switch (this.selectedType) {
                case '2':
                  typeFilter = resource.get('resourceType') == "Website" && resource.get('resourceSubtype') == "Skill Practice/Activity";
                  break;
                case '3':
                  typeFilter = resource.get('resourceType') == "App";
                  break;
                case '4':
                  typeFilter = resource.get('resourceType') == "Website" && resource.get('resourceSubtype') == "Video";
                  break;
                case '5':
                  typeFilter = resource.get('resourceType') == "Community Event/Activity";
                  break;
                case '6':
                  typeFilter = resource.get('resourceType') == "Other";
                  break;
                case '7':
                  typeFilter = resource.get('resourceType') == "Website" && resource.get('resourceSubtype') == "Game";
                  break;
                case '9':
                  typeFilter = resource.get('resourceType') == "Website" && resource.get('resourceSubtype') == "N/A";
                  break;
                case '10':
                  typeFilter = resource.get('resourceType') == "Teacher Resources" && resource.get('resourceSubtype') == "Lesson Plan";
                  break;
                case '11':
                  typeFilter = resource.get('resourceType') == "Teacher Resources" && resource.get('resourceSubtype') == "Task";
                  break;
                case '12':
                  typeFilter = resource.get('resourceType') == "Teacher Resources" && resource.get('resourceSubtype') == "Short Answer";
                  break;
                case '13':
                  typeFilter = resource.get('resourceType') == "Teacher Resources" && resource.get('resourceSubtype') == 'TDA';
                  break;
                case '14':
                  typeFilter = resource.get('resourceType') == "Teacher Resources" && resource.get('resourceSubtype') == 'Constructed Response';
                  break;
                default:
                  typeFilter = true;
              }

              var levelFilter = true;
              switch (this.selectedLevel) {
                case '2':
                  levelFilter = resource.get('interestLevels').findBy('id', '3');
                  break;
                case '3':
                  levelFilter = resource.get('interestLevels').findBy('id', '2');
                  break;
                case '4':
                  levelFilter = resource.get('interestLevels').findBy('id', '1');
                  break;
                default:
                  levelFilter = true;
              }

              var keywordSearch = true;
              if (this.searchBox.length > 0) {
                var splitKeywords = this.searchBox.toLowerCase().split(" ");
                splitKeywords.forEach(function (k) {
                  if (keywordSearch) {
                    keywordSearch = resource.get('resourceName').toLowerCase().indexOf(k) >= 0 || resource.get('description').toLowerCase().indexOf(k) >= 0;
                  }
                });
                // keywordSearch = resource.get('resourceName').toLowerCase().indexOf(this.searchBox.toLowerCase()) >= 0 ||
                //   resource.get('description').toLowerCase().indexOf(this.searchBox.toLowerCase()) >= 0
              }

              return typeFilter && levelFilter && keywordSearch && (!this.onlyBookmarks || this.bookmarks.find(function (item, index, enumerable) {
                return item.get('resource').get('id') == resource.get('id');
              })) && (!this.noFlash || !resource.get('requiresFlash')) && (!this.commonSenseOnly || resource.get('commonSenseApproved'));
            }, this);

            if (this.currentSort == "1") {
              results = results.sortBy('averageRating').reverse();
            } else if (this.currentSort == "3") {
              results = results.sortBy('resourceName');
            }
            var tSize = results.length;
            results.slice(0, this.numShown);
            results.tSize = tSize;
            return results;
          }),

          typeFilterOpts: [{
            id: 1,
            text: "All types"
          },
          //{
          //  id: 3,
          //  text: "App"
          //},
          //{
          //  id: 5,
          //  text: "Community Event/Activity"
          //},
          {
            id: 7,
            text: "Online Game"
          }, {
            id: 9,
            text: "Other Website"
          }, {
            id: 2,
            text: "Skill Practice/Activity"
          }, {
            id: 4,
            text: "Video"
          }],
          teacherTypeFilterOpts: [{
            id: 1,
            text: "All types"
          }, {
            id: 10,
            text: "Lesson Plan"
          }, {
            id: 11,
            text: "Task"
          }, {
            id: 12,
            text: 'Short Answer'
          }, {
            id: 13,
            text: 'TDA'
          }, {
            id: 14,
            text: 'Constructed Response'
          }],
          levelFilterOpts: [{
            id: 1,
            text: "All levels"
          }, {
            id: 2,
            text: "Above grade level"
          }, {
            id: 3,
            text: "At grade level"
          }, {
            id: 4,
            text: "Below grade level"
          }],
          sortingOpts: [{
            id: 1,
            text: "Rating"
          },
          // {
          //   id: 2,
          //   text: "Standard"
          // },
          {
            id: 3,
            text: "Resource name"
          }]

        });
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Search Results');
    },

    actions: {

      createBookmark: function createBookmark(resourceId) {
        var curModel = this.currentModel;
        var self = this;
        var curResource = curModel.resources.findBy('id', resourceId);
        var bookmarkForCurResource = curModel.bookmarks.find(function (item, index, enumerable) {
          return item.get('resource').get('id') == resourceId;
        });
        if (curResource && !bookmarkForCurResource) {
          var b = this.store.createRecord('resource-bookmark', {
            resource: curResource,
            user: curModel.curUser,
            bookmarkedDate: new Date()
          });
          b.save().then(function () {
            //self.refresh();
          }, function () {
            self.set('currentModel.warningMessage', "This resource could not be bookmarked. Please check your Internet connection and try again.");
            self.set("currentModel.showAlert", true);
          });
        }
      },

      deleteBookmark: function deleteBookmark(resourceId) {
        var curModel = this.currentModel;
        var self = this;
        var bookmarkForCurResource = curModel.bookmarks.find(function (item, index, enumerable) {
          return item.get('resource').get('id') == resourceId;
        });
        if (bookmarkForCurResource) {
          bookmarkForCurResource.destroyRecord().then(function () {
            //self.refresh();
          }, function () {
            self.set('currentModel.warningMessage', "This bookmark could not be deleted. Please check your Internet connection and try again.");
            self.set("currentModel.showAlert", true);
          });
        }
      },

      setBookmarksOnly: function setBookmarksOnly() {
        if (this.currentModel.onlyBookmarks) {
          this.set('currentModel.onlyBookmarks', false);
        } else {
          this.set('currentModel.onlyBookmarks', true);
        }
      },

      setNoFlash: function setNoFlash() {
        if (this.currentModel.noFlash) {
          this.set('currentModel.noFlash', false);
        } else {
          this.set('currentModel.noFlash', true);
        }
      },

      setCommonSense: function setCommonSense() {
        if (this.currentModel.commonSenseOnly) {
          this.set('currentModel.commonSenseOnly', false);
        } else {
          this.set('currentModel.commonSenseOnly', true);
        }
      },

      setType: function setType(value) {
        this.set('currentModel.selectedType', value);
      },

      setLevel: function setLevel(value) {
        this.set('currentModel.selectedLevel', value);
      },

      searchKeyword: function searchKeyword() {
        document.activeElement.blur();
        this.set('currentModel.finalSearch', this.currentModel.searchBox);
      },

      sortResults: function sortResults(value) {
        this.set('currentModel.currentSort', value);
      }

    }
  });
});
define('ohs/components/secondary-navigation', ['exports', 'ohs/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['secondary-nav-wrapper', 'is-closed'],
    childPickerIsOpen: false,
    activeIndex: 0,
    session: Ember.inject.service('session'),
    environment: _environment.default.environment,

    //// computeds
    currentRoles: Ember.computed.alias('session.session.content.authenticated.roles'),
    currentRole: Ember.computed('currentRoles.[]', function () {
      var currentRoles = Ember.get(this, 'currentRoles');

      if (currentRoles.includes('Teacher')) {
        return 'Teacher';
      }

      if (currentRoles.includes('Tutor')) {
        return 'Tutor';
      }

      if (currentRoles.includes('Student')) {
        return 'Student';
      }
    }),

    //// hooks
    didInsertElement: function didInsertElement() {
      this.attrs.registerNavigation(this);
    },

    actions: {
      close: function close() {
        var self = this;
        //We need to delay the closing of the menu because there is a 300ms delay in registering a touch event on the menu links. Without the delay,
        //the menu closes before the link touch registers so the touch ends up hitting the page below the menu
        setTimeout(function () {
          // if the nav has the open class, remove it and add is-closed
          if (self.$()) {
            if (self.$().hasClass('is-open')) {
              self.$().toggleClass('is-open is-closed');
            };
          }
          // if the body has the scroll-disabled class, remove it
          if ($('body')) {
            if ($('body').hasClass('scroll-disabled')) {
              $('body').removeClass('scroll-disabled');
            };
          }
          if (!self.isDestroyed) {
            self.set('menuOpen', false);
          }
        }, 350);
      },

      stayOpen: function stayOpen() {},

      click: function click(event) {
        this.set('activeIndex', event.target);
      },

      changeStudent: function changeStudent() {
        var selectedEl = this.$('select')[0];
        var selectedIndex = selectedEl.selectedIndex;
        var selectedValue = selectedEl[selectedIndex].value;

        var sesObj = this.get('session');
        sesObj.set('currentStudent', selectedValue);
        this.sendAction('refreshAction');
      }
    }
  });
});
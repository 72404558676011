define('ohs/routes/teacher/continue-quiz/pick-students', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        afterModel: function afterModel(model) {
            var testTypeId = Ember.get(model, 'administration.testTypeId');
            if (!testTypeId) {
                this.transitionTo('teacher.continue-quiz.pick-type');
            }
        }
    });
});
define('ohs/routes/create-quiz-4', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/teacher-authorize-mixin'], function (exports, _authenticatedRouteMixin, _reset, _noNavigation, _teacherAuthorizeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _noNavigation.default, _teacherAuthorizeMixin.default, {
    titleToken: function titleToken(model) {
      return model.createQuiz1.quiz.get('quizName');
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var createQuiz1 = this.modelFor('create-quiz-1');
      var store = this.store;
      createQuiz1.quiz.set('passageBased', true);

      if (createQuiz1 && createQuiz1.quiz && createQuiz1.quiz.get('eligibleContentQueryString')) {

        return Ember.RSVP.hash({
          createQuiz1: this.modelFor('create-quiz-1'),
          standards: this.store.peekAll('standard'),
          questions: this.store.query('question', { 'standardId': createQuiz1.quiz.get('eligibleContentQueryString') }),
          tip: this.store.peekRecord('tip', 5),

          gradeLevelDesc: Ember.computed('createQuiz1.quiz.gradeLevelId', function () {
            var grade = store.peekRecord('grade-level', this.createQuiz1.quiz.get('gradeLevelId'));
            return grade.get('description');
          }),

          subjectName: Ember.computed('createQuiz1.quiz.subjectId', function () {
            var subject = store.peekRecord('subject', this.createQuiz1.quiz.get('subjectId'));
            return subject.get('text');
          }),

          initial: Ember.computed(function () {
            return this.createQuiz1.quiz.get('questions').get('length') == 0 && this.tip != null;
          })
        });
      } else if (createQuiz1 && createQuiz1.quiz && createQuiz1.quiz.get('passageBased')) {
        return Ember.RSVP.hash({
          createQuiz1: createQuiz1,

          gradeLevelDesc: Ember.computed('createQuiz1.quiz.gradeLevelId', function () {
            var grade = store.peekRecord('grade', this.createQuiz1.quiz.get('gradeLevelId'));
            return grade.get('text');
          }),

          subjectName: Ember.computed('createQuiz1.quiz.subjectId', function () {
            var subject = store.peekRecord('subject', this.createQuiz1.quiz.get('subjectId'));
            return subject.get('text');
          }),

          initial: Ember.computed(function () {
            return this.createQuiz1.quiz.get('questions').get('length') == 0 && this.tip != null;
          })

        });
      } else {
        this.transitionTo('create-quiz-1', 'New');
      }
    },

    actions: {
      letsGo: function letsGo(quizId) {
        this.transitionTo('create-quiz-5', quizId);
      },

      goBack: function goBack() {
        this.transitionTo('create-quiz-1', 'existingQuiz');
      }
    }

  });
});
define('ohs/routes/download-account-creation-template', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authenticatedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _breadcrumbMixin.default, {
    titleToken: 'Download Account Creation Templates',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Student")) {
            this.controllerFor('application').set('student', true);
          } else if (roles.includes("Teacher")) {
            this.controllerFor('application').set('teacher', true);
          } else if (roles.indexOf("Parent") > 0) {
            this.controllerFor('application').set('parent_many_children', true);
          }
        } else {
          this.controllerFor('application').set('student', '');
          this.controllerFor('application').set('teacher', '');
          this.controllerFor('application').set('parent_many_children', '');
        }
      }
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },
    model: function model() {},

    afterModel: function afterModel(model) {
      this._super(model, 'Download Account Creation Templates', false, true);
    },
    actions: {
      generateTeacherUserTemplate: function generateTeacherUserTemplate() {
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = 'TeacherUserTemplate.csv';

        var xhr = new XMLHttpRequest();
        xhr.open('GET', baseUrl + '/teachers/DownloadCsvTemplate?fileName=' + encodeURIComponent(fileName), true);
        xhr.responseType = "blob";
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            var blob = new Blob([xhr.response], { type: "text/csv;base64" });
            saveAs(blob, fileName);
          }
        };
        xhr.send(null);
      },
      generateStudentUserTemplate: function generateStudentUserTemplate() {
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = 'StudentUserTemplate.csv';

        var xhr = new XMLHttpRequest();
        xhr.open('GET', baseUrl + '/students/DownloadStudentUserTemplate?fileName=' + encodeURIComponent(fileName), true);
        xhr.responseType = "blob";
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            var blob = new Blob([xhr.response], { type: "text/csv;base64" });
            saveAs(blob, fileName);
          }
        };
        xhr.send(null);
      },
      generateRosterTemplate: function generateRosterTemplate() {
        var baseUrl = this.store.adapterFor('application').get('host');
        var fileName = 'RosterTemplate.csv';

        var xhr = new XMLHttpRequest();
        xhr.open('GET', baseUrl + '/teachers/DownloadCsvTemplate?fileName=' + encodeURIComponent(fileName), true);
        xhr.responseType = "blob";
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            var blob = new Blob([xhr.response], { type: "text/csv;base64" });
            saveAs(blob, fileName);
          }
        };
        xhr.send(null);
      }
    }
  });
});
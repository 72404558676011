define('ohs/routes/join-quiz-without-login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        userName: '',
        quizCode: '',
        teacherCode: '',
        teacherCodeRequired: false,
        showModal: '',
        validationErrors: '',
        successMessages: '',
        buttonsDisabled: false
      });
    },


    actions: {

      next: function next() {
        var self = this;
        self.set("currentModel.validationErrors", '');
        self.set("currentModel.successMessages", '');
        self.set("currentModel.showModal", false);
        self.set('currentModel.buttonsDisabled', true);

        if (self.currentModel.userName.trim() && self.currentModel.quizCode.trim()) {
          // Make sure the username and access code is supplied
          if (self.currentModel.teacherCodeRequired == true) {
            // Check whether teacher code is required or not
            if (self.currentModel.teacherCode.trim()) {
              // Make sure the teacher code is supplied only if the code is required.
              // Check whether the teacher code is valid or not. if valid, link the user to the quiz’s teacher and join Quiz.
              return Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/students/ValidateTeacherCodeAndLink?userName=' + self.currentModel.userName.trim() + '&quizCode=' + encodeURIComponent(self.currentModel.quizCode.trim()) + '&teacherCode=' + self.currentModel.teacherCode.trim()).done(function (response) {
                if (response.id) {
                  self.joinQuiz(response.id); //join Quiz
                } else {
                  self.set("currentModel.validationErrors", 'Failed to link the teacher. Please check your internet connection and try again.');
                  self.set('currentModel.buttonsDisabled', false);
                }
              }).fail(function (response) {
                self.set("currentModel.validationErrors", response.responseText);
                self.set('currentModel.buttonsDisabled', false);
              });
            } else {
              // Teacher code can't be blank only when the teacher code is required.
              self.set("currentModel.validationErrors", 'The teacher code can\'t be blank');
              self.set('currentModel.buttonsDisabled', false);
            }
          } else {
            // If the username and access code is valid. Check the user is linked or not to the quiz’s teacher.
            return Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/students/ValidateUserNameAndQuizCode?userName=' + self.currentModel.userName.trim() + '&quizCode=' + encodeURIComponent(self.currentModel.quizCode.trim())).done(function (response) {
              if (response.id) {
                self.joinQuiz(response.id); //join Quiz
              } else {
                self.set("currentModel.showModal", true);
                self.set('currentModel.buttonsDisabled', false);
              }
            }).fail(function (response) {
              self.set("currentModel.validationErrors", response.responseText);
              self.set('currentModel.buttonsDisabled', false);
            });
          }
        } else {
          // username and access code can't be blank.
          self.set("currentModel.validationErrors", 'The username and access code can\'t be blank');
          self.set('currentModel.buttonsDisabled', false);
        }
      },

      proceed: function proceed() {
        var self = this;
        self.set("currentModel.validationErrors", '');
        self.set("currentModel.successMessages", '');
        self.set("currentModel.showModal", false);

        self.set("currentModel.teacherCodeRequired", true);
      },

      back: function back() {
        this.transitionTo('forgot-password');
      }

    },

    joinQuiz: function joinQuiz(student_test_id) {
      var self = this;
      self.transitionTo('student-quiz-room', student_test_id);
    }

  });
});
define('ohs/services/edinsight-service', ['exports', 'ohs/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        store: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);
        },


        //linking
        link: function link(providerId, user) {
            user.set('edinsightProviderId', providerId);
            return user.save();
        },
        unlink: function unlink(user) {
            user.set('edinsightProviderId', null);
            return user.save();
        },


        //pulls

        //gets the roster from EdInsight and loads it into the users roster
        pullRoster: function pullRoster(userToken, providerId) {
            var _this = this;

            return new Ember.RSVP.Promise(function (resolve, reject) {
                var baseUrl = _this.get('store').adapterFor('application').get('host');

                return Ember.$.ajax({
                    url: baseUrl + '/pullFromEdinsight?userToken=' + encodeURIComponent(userToken) + '&providerId=' + encodeURIComponent(providerId),
                    type: 'Post'
                }).then(function (response) {
                    resolve(response);
                }, function (response) {
                    reject(response);
                });
            });
        },


        //gets 
        getDistrictByProviderId: function getDistrictByProviderId(providerId) {
            var _this2 = this;

            return new Ember.RSVP.Promise(function (resolve, reject) {
                _this2.getDistricts().then(function (districts) {
                    var district = districts.find(function (district) {
                        return district.providerId == providerId;
                    });
                    if (district) {
                        resolve(district);
                    } else {
                        //providerId does not exists 
                        reject('That district provider Id could not be found.');
                    }
                }, function () {
                    //connection issue
                    reject('There was an issue finding the district');
                });
            });
        },
        getDistrictByURL: function getDistrictByURL(url) {
            var _this3 = this;

            return new Ember.RSVP.Promise(function (resolve, reject) {
                // urls(from the user) used for comparing
                var urls = _this3.getComparisonUrls(url);

                _this3.getDistricts().then(function (districts) {
                    var matches = districts.filter(function (d) {
                        var districtUrls = _this3.getComparisonUrls(d.url);

                        // if any of the user urls exist in the districtUrls return
                        return urls.some(function (uUrl) {
                            return districtUrls.some(function (dUrl) {
                                return dUrl.toLowerCase().trim().includes(uUrl.toLowerCase().trim());
                            });
                        });
                    });

                    if (matches.length === 1) {
                        resolve(matches[0]);
                    } else {
                        reject();
                    }
                }).catch(reject);
            });
        },
        getUserToken: function getUserToken(district) {
            var redirectUrl = '' + window.location.origin + window.location.pathname;
            var params = ['isRosterSync=true', 'providerId=' + district.providerId];

            redirectUrl = encodeURIComponent(redirectUrl + '?' + params.join('&'));
            window.location = district.url + '?redir=' + redirectUrl;
        },


        //returns the users roster in Edinsight
        getRoster: function getRoster(userToken, providerId) {
            var baseUrl = this.get('store').adapterFor('application').get('host');
            return Ember.$.getJSON(baseUrl + '/getEdinsightRoster?userToken=' + encodeURIComponent(userToken) + '&providerId=' + encodeURIComponent(providerId));
        },
        getDistricts: function getDistricts() {
            var baseUrl = this.get('store').adapterFor('application').get('host');
            return Ember.$.getJSON(baseUrl + '/getEdinsightDistricts');
        },
        getComparisonUrls: function getComparisonUrls(fullUrl) {
            // finds all words
            var matches = fullUrl.match(/[\w-]+/g);

            //ex. http://something.myedinsight.com/EdInsight/login.aspx -> ["http", "something", "myedinsight", "com", "EdInsight", "login", "aspx"]
            // NOTE: Stupid logic time
            // EdInsight urls are not normalized (go figure 🙄) 
            // this is because there are some urls like 
            // - https://edinsight.iu28.org/blairsville/login.aspx 
            // - https://edinsight.iu28.org/HomerCenter/Login.aspx 
            // these districts should be able to enter -> edinsight.iu28.org/HomerCenter and get a match
            // other districts should be able to just enter penntrafford.myedinsight and get a match

            var url = matches.reduce(function (parts, match, index) {
                // remove hyphens from only the first match
                // handles penntrafford-pa -> penntrafford
                if (index === 0 && match.includes('-')) {
                    match = match.slice(0, match.indexOf('-'));
                }

                return parts.concat(match);
            }, []).join('.');

            // give them both the original and the fullurl they entered
            return [url, fullUrl];
        }
    });
});
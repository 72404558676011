define('ohs/routes/student/quiz/game-mode/in-progress', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        signalR: Ember.inject.service('signal-r'),

        model: function model() {
            var quizModel = this.modelFor('student.quiz');
            return Ember.RSVP.hash({
                studentTestAdministration: Ember.get(quizModel, 'studentTestAdministration'),
                teacher: Ember.get(quizModel, 'teacher'),
                administration: Ember.get(quizModel, 'studentTestAdministration.administration'),

                isUnAnswered: Ember.computed('studentTestAdministration.studentAnswers.@each.id', 'administration.testQuestion.question.id', function () {
                    return Ember.get(Ember.get(this, 'studentTestAdministration.studentAnswers').filterBy('questionId', parseInt(Ember.get(this, 'administration.testQuestion.question.id'))), 'length') <= 0;
                }),

                selectedAnswer: null,
                currentQuestionNumber: Ember.computed('administration.currentQuestionIndex', function () {
                    return Ember.get(this, 'administration.currentQuestionIndex') + 1;
                }),

                currentProgress: Ember.computed('administration.currentQuestionIndex', 'quiz.testQuestions.[]', function () {
                    return 'Question ' + (Ember.get(this, 'administration.currentQuestionIndex') + 1) + ' of ' + Ember.get(this, 'administration.quiz.testQuestions.length');
                })
            });
        },


        actions: {
            willTransition: function willTransition(transition) {
                //allowed routes to transition to
                switch (Ember.get(transition, 'targetName')) {
                    case 'student-results':
                    case 'student-self-guided-question':
                    case 'student-quizzes':
                        Ember.get(this, 'signalR').leaveAdministration(Ember.get(this, 'currentModel.studentTestAdministration.administration.id'));
                        return true;
                    case 'student.quiz.game-mode.in-progress':
                        return true;
                    default:
                        //we can also transition if it's approved or if the quiz is cancelled
                        if (Ember.get(this, 'currentModel.studentTestAdministration.cancelled') || Ember.get(this, 'currentModel.isTransitionApproved')) return true;

                        //anything else will need approved
                        Ember.set(this, 'currentModel.transition', transition);
                        Ember.set(this, 'currentModel.confirmTransition', true);
                        transition.abort();
                        break;
                }
            },
            retryTransition: function retryTransition() {
                Ember.set(this, 'currentModel.confirmTransition', false);
                Ember.set(this, 'currentModel.isTransitionApproved', true);
                Ember.get(this, 'currentModel.transition').retry();
            },
            selectAnswer: function selectAnswer(answerOptionId, studentAnswerId) {
                //if there is a studentAnswerId that means the studentAnswer needs updated
                //else we need to create a new one
                var chosenAnswer = Ember.get(this, 'store').peekRecord('answer-option', answerOptionId.toString());
                if (!studentAnswerId) {
                    Ember.set(this, 'currentModel.selectedAnswer', Ember.get(this, 'store').createRecord('studentAnswer', {
                        answerId: answerOptionId,
                        questionId: parseInt(Ember.get(this, 'currentModel.administration.testQuestion.question.id')),
                        studentTestId: parseInt(Ember.get(this, 'currentModel.studentTestAdministration.id')),
                        isCorrect: Ember.get(chosenAnswer, 'isCorrect')
                        //studentTestAdministration: get(this, 'currentModel.studentTestAdministration')
                    }));
                } else {
                    var answer = Ember.get(this, 'store').peekRecord('studentAnswer', studentAnswerId);
                    Ember.set(answer, 'answerId', answerOptionId);
                    Ember.set(answer, 'isCorrect', Ember.get(chosenAnswer, 'isCorrect'));
                    Ember.set(this, 'currentModel.selectedAnswer', answer);
                }
            },
            submitAnswer: function submitAnswer() {
                var _this = this;

                //if answer is null or undefined then there is nothing to save or update
                var answer = Ember.get(this, 'currentModel.selectedAnswer');
                if (answer) {
                    answer.save().then(function () {
                        Ember.set(answer, 'studentTestAdministration', Ember.get(_this, 'currentModel.studentTestAdministration'));
                    }, function (response) {
                        answer.rollbackAttributes();
                        Ember.set(_this.modelFor('student.quiz'), 'showAlert', true);
                        if (response && Ember.get(response, 'errors') && Ember.get(response, 'errors.length') > 0) {
                            Ember.set(_this.modelFor('student.quiz'), 'alertMessage', Ember.get(Ember.get(response, 'errors')[0], 'detail'));
                        } else {
                            Ember.set(_this.modelFor('student.quiz'), 'alertMessage', 'Your answer could not be saved. Please check your internet connection and try again.');
                        }
                    });
                }
            }
        }
    });
});
define('ohs/routes/sign-up', ['exports', 'ohs/mixins/reset', 'ohs/mixins/no-navigation'], function (exports, _reset, _noNavigation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _noNavigation.default, {
    titleToken: 'Create A New Account',

    actions: {
      teacherSignUp: function teacherSignUp() {
        this.transitionTo('sign-up-teacher');
      },
      studentSignUp: function studentSignUp() {
        this.transitionTo('sign-up-student');
      },
      login: function login() {
        this.transitionTo('login');
      },
      submitEmail: function submitEmail() {}
    }

  });
});
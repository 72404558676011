define('ohs/components/menu-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    //tagName: 'button',
    //classNames: 'secondary-nav-trigger',
    navMenu: 'secondary-nav-wrapper',
    $navMenu: null,

    // click: function() {
    //   this.$navMenu = Ember.$('.' + this.get('navMenu'));
    //   this.$navMenu.toggleClass('is-closed is-open');
    //   $('body').toggleClass('scroll-disabled');
    //   this.attrs.hideOthers(this);
    // }

    actions: {
      openMenu: function openMenu() {
        this.$navMenu = Ember.$('.' + this.get('navMenu'));
        this.$navMenu.toggleClass('is-closed is-open');
        $('body').toggleClass('scroll-disabled');
        this.attrs.hideOthers(this);
        this.set('menuOpen', true);
      }
    }
  });
});
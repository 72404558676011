define('ohs/mixins/resource-actions-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        afterModel: function afterModel() {
            this._super.apply(this, arguments);
            //we need to ensure that the bookmarks are loaded for the bookmarking to work properly
            Ember.get(this, 'store').findAll('resource-bookmark');
        },


        actions: {
            deleteBookmark: function deleteBookmark(bookmark) {
                var _this = this;

                bookmark.deleteRecord();
                bookmark.save().then(function () {
                    Ember.get(_this, 'store').unloadRecord(bookmark);
                });
            },
            createBookmark: function createBookmark(resource, user) {
                Ember.get(this, 'store').createRecord('resource-bookmark', {
                    resource: resource,
                    user: user,
                    bookmarkedDate: new Date()
                }).save();
            },
            setRating: function setRating(resource, currentRating, user, newRating) {
                //if there is a current rating, update it.
                if (!!currentRating) {
                    Ember.set(currentRating, 'ratingScore', newRating);
                    Ember.set(currentRating, 'ratingDate', new Date());
                    currentRating.save();
                }
                //need to create a new rating.
                else {
                        var rating = Ember.get(this, 'store').createRecord('resource-user-review', {
                            resource: resource,
                            user: user,
                            ratingScore: newRating,
                            ratingDate: new Date()
                        });
                        rating.save();
                    }
            }
        }
    });
});
define('ohs/helpers/possesive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.possesive = possesive;
  function possesive(params /*, hash*/) {
    var name = params[0];

    if (name.substr(name.length - 1) == 's') {
      return name + "'";
    } else {
      return name + "'s";
    }
  }

  exports.default = Ember.Helper.helper(possesive);
});
define('ohs/models/second-part-answer-option', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    answerText: _emberData.default.attr('string'),
    templateText: _emberData.default.attr('string'),
    isCorrect: _emberData.default.attr('boolean'),
    cognitiveMistakeDesc: _emberData.default.attr('string'),
    secondPartQuestion: _emberData.default.belongsTo('secondPartQuestion'),
    answerLetter: _emberData.default.attr('string')
  });
});
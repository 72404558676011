define('ohs/routes/teacher/quiz/game-mode/in-progress/teacher-view', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return this.modelFor('teacher.quiz.game-mode.in-progress');
        }
    });
});
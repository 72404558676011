define('ohs/models/class-library-folder', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    teacher: _emberData.default.belongsTo('user'),
    parentFolder: _emberData.default.belongsTo('classLibraryFolder', { inverse: null }),
    childFolders: _emberData.default.hasMany('classLibraryFolder', { inverse: null }),
    classResources: _emberData.default.hasMany('classResource'),
    classLinkedResources: _emberData.default.hasMany('classLinkedResource'),

    text: Ember.computed('name', function () {
      return this.get('name');
    }),

    itemIcon: Ember.computed(function () {
      return 'icon-class-library-folder';
    })

  });
});
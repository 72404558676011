define('ohs/routes/student-dashboard', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/student-authorize-mixin'], function (exports, _authenticatedRouteMixin, _reset, _studentAuthorizeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _studentAuthorizeMixin.default, {
    titleToken: 'Student Dashboard',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('student', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    signalR: Ember.inject.service('signal-r'),

    model: function model() {

      //initialize signal-R
      this.get('signalR').administrationId = 0;
      var store = this.store;
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];

      return Ember.RSVP.hash({
        userId: userId,
        allAdmins: this.store.findAll('student-test-administration'),
        //  openAdministrations: Ember.computed('allAdmins.@each.isFinished', function() {
        //    return this.allAdmins.filter(function(sta,index,array) {
        //      return (!sta.get('administration').get('isComplete') && sta.get('isFinished') == false);
        //    });
        //  }),
        openAdministrations: this.store.query('student-test-administration', { isOpen: true }),

        openAdministrationsOrdered: Ember.computed('openAdministrations.@each.id', function () {
          return this.openAdministrations.sortBy('administration.startedDateForOrdering').reverse();
        }),

        recentResults: Ember.computed('allAdmins.@each.isFinished', function () {
          return this.allAdmins.filter(function (sta, index, array) {
            return sta.get('isFinished');
          }).sortBy('dateCompletedSort').reverse().slice(0, 3);
        }),
        completedQuizCount: Ember.computed('allAdmins', function () {
          return this.allAdmins.filter(function (sta, index, array) {
            return sta.get('isFinished');
          }).length;
        }),
        latestStudentResourceActivity: store.findAll('latest-activity-student-resource'),

        latestStudentResourceActivityDisplay: Ember.computed('latestStudentResourceActivity.@each.id', function () {
          return this.latestStudentResourceActivity.sortBy('date').reverse().slice(0, 3);
        }),

        resources: store.findAll('resource-assigned'),

        newResources: Ember.computed('resources.@each.viewed', function () {
          return this.resources.filterBy('viewed', false).sortBy('assignedDate').reverse();
        }),

        studentTrophies: this.store.findAll('student-trophy'),

        latestTrophies: Ember.computed('studentTrophies.@each.dateEarned', function () {
          return this.studentTrophies.sortBy('dateEarned').reverse().slice(0, 3);
        }),

        user: store.findRecord('user', userId),
        quizCode: "",
        errorMessage: "",
        administrationId: "",
        buttonsDisabled: false
      });
    },


    activate: function activate() {
      var self = this;
      window.addEventListener("resize", function (e) {
        self.resizeCanvas();
      }, false);
    },

    resizeCanvas: function resizeCanvas() {
      $('.trophy-img').each(function () {
        $(this).css('height', $(this).width() * $(this).css('min-height').replace("px", ""));
      });
    },

    actions: {
      joinNow: function joinNow() {
        document.activeElement.blur();

        var self = this;
        self.set('currentModel.buttonsDisabled', true);

        if (this.get("currentModel.quizCode").trim()) {
          self.set("currentModel.errorMessage", '');
          var sesObj = this.get('session');
          var userId = sesObj.session.content.authenticated["userId"];
          return Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/administrations/getAdministrationId?testCode=' + encodeURIComponent(this.get("currentModel.quizCode").trim()) + '&userId=' + userId).done(function (response) {
            //If already joined the quiz (student test administration). Move the student into the student-quiz-room route for that quiz.
            if (response.id) {
              self.transitionTo('student-quiz-room', response.id);
            }
            //If not joined already, join the quiz and move the student into the student-quiz-room route for that quiz.
            else if (response.administrationId) {
                var studentTestAdministration = self.store.createRecord('student-test-administration', {
                  administrationId: response.administrationId,
                  userId: userId,
                  isFinished: false,
                  invitedThrough: 'Joined using access code'
                });

                studentTestAdministration.save().then(function (data) {

                  self.transitionTo('student-quiz-room', data.id);
                });
              }
          }).fail(function (response) {
            self.set("currentModel.errorMessage", response.responseText);
            self.set('currentModel.buttonsDisabled', false);
          });
        } else {
          self.set("currentModel.errorMessage", 'Access code  can\'t be blank');
          self.set('currentModel.buttonsDisabled', false);
        }
        // administration: this.store.findRecord('administration', this.get("currentModel.quizCode")).then(function(data) {
        //   console.log(data);
        //   }).catch((error)=>{
        //     this.set("currentModel.errorMessage", error.errors[0]);
        //     });
        // //this.transitionTo('student-question');
      },

      transitionToQuiz: function transitionToQuiz(studentTestId) {
        this.transitionTo('student-quiz-room', studentTestId);
      }

    }

  });
});
define('ohs/routes/forgot-user-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Recover Username',
    model: function model(params) {
      return Ember.RSVP.hash({
        userName: '',
        email: '',
        userNameRequired: false,
        validationErrors: '',
        successMessages: '',
        isProcessing: false
      });
    },


    actions: {

      hideConfirm: function hideConfirm() {
        this.set('currentModel.showConfirm', false);
        this.transitionTo('login');
      },

      emailNext: function emailNext() {
        document.activeElement.blur();
        var self = this;
        self.set("currentModel.validationErrors", '');
        self.set("currentModel.successMessages", '');
        self.set("currentModel.isProcessing", true);

        if (self.currentModel.email) {
          if (self.get('currentModel.email').match(/.*@.*\..*/)) {
            Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/SendAssociatedUserDetailsEmail/?email=' + encodeURIComponent(self.currentModel.email)).done(function (response) {
              if (response.id != 0) {
                //self.set("currentModel.successMessages", 'All your associated user account details has been sent to the registered email');
                self.set('currentModel.showConfirm', true);
                self.set("currentModel.validationErrors", '');
                self.set("currentModel.isProcessing", false);
              } else {
                self.set("currentModel.validationErrors", 'The email address you entered could not be found');
                self.set("currentModel.isProcessing", false);
              }
            }).fail(function (response) {
              self.set("currentModel.isProcessing", false);
              if (response.responseText) self.set("currentModel.validationErrors", response.responseText);else //If there is no internet connection.
                self.set("currentModel.validationErrors", 'Please check your internet connection and try again.');
            });
          } else {
            self.set("currentModel.validationErrors", 'You must enter a valid email address');
            self.set("currentModel.isProcessing", false);
          }
        } else {
          self.set("currentModel.validationErrors", 'The email can\'t be blank');
          self.set("currentModel.isProcessing", false);
        }
      },

      emailBack: function emailBack() {
        this.transitionTo('login');
      }

    }

  });
});
define('ohs/models/mission-control/activity', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var hasMany = _emberData.default.hasMany;
    exports.default = _emberData.default.Model.extend({
        activityItems: hasMany('mission-control/activity-item'),

        activityItemsSorting: Object.freeze(['sort']),
        sortedActivityItems: Ember.computed.sort('activityItems', 'activityItemsSorting')
    });
});
define('ohs/components/toggleable-result-question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    percentCorrect: Ember.computed('studentTestAdministrations.@each.percentCorrect', function () {
      var questionId = this.get('question').get('question').get('id');
      var numCorrect = 0;
      var numPoints = 1;
      var self = this;
      if (this.get('question.question.secondPartQuestion') && this.get('question.question.secondPartQuestion.numCorrectAnswers') > 0) {
        numPoints += this.get('question.question.secondPartQuestion.numCorrectAnswers');
      }
      var totalStudents;
      if (this.get('in-progress')) {
        var answers = this.get('studentTestAdministrations').filter(function (sta, index, array) {
          return sta.get('studentAnswers').findBy('questionId', parseInt(questionId));
        });
        totalStudents = answers.get('length');
      } else {
        totalStudents = this.get('studentTestAdministrations').get('length');
      }
      if (totalStudents > 0) {

        this.get('studentTestAdministrations').forEach(function (item, index) {
          var studentAnswer = item.get('studentAnswers').findBy('questionId', parseInt(questionId));

          if (studentAnswer && item.get('studentSecondPartAnswers') && item.get('studentSecondPartAnswers.length') > 0) {
            if (studentAnswer.get('isCorrect')) {
              numCorrect++;
            }
            var studentSecondPartAnswers = item.get('studentSecondPartAnswers').filterBy('questionId', parseInt(questionId));
            if (studentSecondPartAnswers && studentSecondPartAnswers.length <= self.get('question.question.secondPartQuestion.numCorrectAnswers') && studentAnswer.get('isCorrect')) {
              for (var i = 0; i < studentSecondPartAnswers.length; i++) {
                if (studentSecondPartAnswers.objectAt(i).get('isCorrect')) numCorrect++;
              }
            }
          } else {
            if (studentAnswer && studentAnswer.get('isCorrect')) numCorrect++;
          }
        });
        return Math.round(numCorrect / (totalStudents * numPoints) * 100);
      } else {
        return 0;
      }
    }),

    studentAnswerCorrect: Ember.computed('sta.studentAnswers.@each.answerId', function () {
      if (this.get('sta')) {
        var studentAnswer = this.get('sta').get('studentAnswers').findBy('questionId', parseInt(this.get('question').get('question').get('id')));
        if (this.get('sta').get('studentSecondPartAnswers') && this.get('sta').get('studentSecondPartAnswers').get('length') > 0) {
          var studentSecondPartAnswers = this.get('sta').get('studentSecondPartAnswers').filterBy('questionId', parseInt(this.get('question').get('question').get('id')));
          var isCorrectLength = studentSecondPartAnswers.filterBy('isCorrect', true).get('length');
          if (studentSecondPartAnswers && studentSecondPartAnswers.get('length') == isCorrectLength && studentAnswer && studentAnswer.get('isCorrect')) {
            return true;
          }
        } else {
          if (studentAnswer) {
            return studentAnswer.get('isCorrect');
          } else {
            return false;
          }
        }
      }
    }),

    studentNumPoints: Ember.computed('sta.studentAnswers.@each.answerId', function () {
      var numCorrect = 0;
      var studentAnswer = this.get('sta.studentAnswers').findBy('questionId', parseInt(this.get('question.question.id')));
      if (!studentAnswer || !studentAnswer.get('isCorrect')) {
        return 0;
      } else {
        numCorrect = 1;
      }

      if (this.get('sta.studentSecondPartAnswers') && this.get('sta.studentSecondPartAnswers.length') > 0) {
        var questionId = this.get('question.question.id');
        var studentSecondPartAnswers = this.get('sta.studentSecondPartAnswers').filterBy('questionId', parseInt(this.get('question.question.id')));

        var secCorrectAnswerlength = studentSecondPartAnswers.filterBy('questionId', parseInt(questionId)).filterBy('isCorrect', true).get('length');
        var studentSecAnswerLength = studentSecondPartAnswers.filterBy('questionId', parseInt(questionId)).get('length');
        var secAnswerlength = this.get('sta.administration.quiz.testQuestions').findBy('question.id', questionId.toString()).get('question.secondPartQuestion.numCorrectAnswers');
        if (secAnswerlength && secAnswerlength > 0) {
          if (studentSecAnswerLength <= secAnswerlength) {
            numCorrect += secCorrectAnswerlength;
          }
        }
      }
      return numCorrect;
    }),

    orderedAnswers: Ember.computed('question.question.answerOptions.@each.id', function () {
      return this.get('question.question.answerOptions').sortBy('id');
    }),
    orderedSecondPartAnswers: Ember.computed('question.question.secondPartQuestion.secondPartAnswerOptions.@each.id', function () {
      return this.get('question.question.secondPartQuestion.secondPartAnswerOptions').sortBy('id');
    }),
    actions: {
      showIssue: function showIssue() {
        this.sendAction('showIssueAction', this.get('question').get('question').get('id'));
      },

      showStudents: function showStudents(students) {
        this.set('studentsToShow', students);
        this.set('showStudentList', true);
      },

      closeModal: function closeModal() {
        this.set('showStudentList', false);
      }
    }
  });
});
define('ohs/routes/teacher/standard-coverage', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/teacher-authorize-mixin'], function (exports, _authenticatedRouteMixin, _teacherAuthorizeMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, {

        titleToken: 'Student Standard Mastery',

        setupController: function setupController(controller, model) {
            //call _super for default behavior
            this._super(controller, model);

            //custom setup
            this.controllerFor('application').set('showSecondaryNav', true);
            this.controllerFor('application').set('teacher', true);
        },

        beforeModel: function beforeModel() {
            this._super.apply(this, arguments);
        },

        model: function model() {
            var _this = this;

            var userId = this.get('session').session.content.authenticated["userId"];
            var host = this.store.adapterFor('application').get('host');

            return Ember.$.getJSON(host + '/ReportingCategories').then(function (response) {
                //model
                return Ember.RSVP.hash({
                    //store calls
                    teacher: _this.store.queryRecord('teacher', { userId: userId }),
                    students: _this.store.findAll('student'),
                    grades: _this.store.query('grade', { 'gradeType': 'CreateQuiz' }),

                    //non-computed
                    reportingCategories: response,
                    isAllStudentsSelected: false,
                    selectedReportingCategory: null,
                    selectedGradeLevel: null,
                    selectedStudents: [],
                    selectedStudentGroups: [],
                    didReportRun: false,
                    isLoading: false,
                    tick: 1,
                    showAlert: false,
                    alertMessage: '',

                    //holds the report data
                    reportData: [],

                    //computed properties
                    noQuizzesGiven: Ember.computed('reportingCategories.[]', function () {
                        if (_this.get('currentModel.reportingCategories').length <= 0) {
                            return true;
                        }
                    }),

                    selectedStudentIds: Ember.computed('selectedStudents.[]', function () {
                        return _this.get('currentModel.students').filter(function (a) {
                            return _this.get('currentModel.selectedStudents').map(function (b) {
                                return b.id;
                            }).indexOf(a.id) != -1;
                        }).map(function (a) {
                            return a.id;
                        });
                    }),

                    allStudentIds: Ember.computed('selectedStudents.[]', 'selectedStudentGroups.[]', 'isAllStudentsSelected', function () {
                        //if all students are selected no need to continue
                        if (_this.get('currentModel.isAllStudentsSelected')) {
                            return _this.get('currentModel.students').map(function (a) {
                                return a.id;
                            });
                        }

                        var selectedStudents = _this.get('currentModel.selectedStudents').map(function (a) {
                            return a.id;
                        });

                        var groupStudents = _this.get('currentModel.selectedStudentGroups').reduce(function (a, b) {
                            var group = _this.store.peekRecord('student-group', b);
                            var students = group.get('students');

                            return a.concat(b.map(function (a) {
                                return a.id;
                            }));
                        }, []);

                        return Array.from(new Set([].concat(_toConsumableArray(groupStudents), _toConsumableArray(selectedStudents))));
                    }),

                    //filtered lists
                    //grades filtered based on reporting categories given
                    filteredGrades: Ember.computed('grades.[]', 'reportingCategories.[]', function () {
                        return _this.get('currentModel.grades').filter(function (a) {
                            return _this.get('currentModel.reportingCategories').some(function (b) {
                                return b.gradeLevelId == a.id;
                            });
                        });
                    }),

                    //reporting categories filtered by selected grade level
                    filteredCategories: Ember.computed('selectedGradeLevel', function () {
                        return _this.get('currentModel.reportingCategories').reduce(function (a, b) {
                            var previous = a;

                            //this is incase they don't select a grade level, to show all the possible reporting categories
                            if (b.gradeLevelId == _this.get('currentModel.selectedGradeLevel') || !_this.get('currentModel.selectedGradeLevel')) {
                                previous.pushObject({
                                    id: b.id,
                                    orderId: b.standardOrderId,
                                    text: b.standardCode
                                });
                            }

                            return previous;
                        }, []).sort(function (a, b) {
                            if (a.orderId > b.orderId) {
                                return 1;
                            } else if (a.orderId < b.orderId) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });
                    }),

                    filteredStudents: Ember.computed('students.[]', function () {
                        return this.students.sortBy('fullNameReversed');
                    }),

                    filteredStudentGroups: Ember.computed('teacher.studentGroups@each.id', function () {
                        return _this.get('currentModel.teacher.studentGroups').sortBy('studentGroupName');
                    })
                });
            },
            //fail
            function (xhr, status, error) {
                console.log(xhr.responseText);
            });
        },

        validateFields: function validateFields(successCallback, failCallback) {
            var self = this;

            if (!this.get('currentModel.invalidAttributes')) {
                this.set('currentModel.invalidAttributes', []);
            }

            if (this.get('currentModel.invalidAttributes.length') == 0) {
                document.activeElement.blur();

                this.set('currentModel.tick', this.get('currentModel.tick') + 1);

                setTimeout(function () {
                    if (self.get('currentModel.invalidAttributes.length') > 0) {
                        failCallback();
                    } else {
                        successCallback();
                    }
                }, 1000);
            }
        },


        actions: {
            setGradeLevel: function setGradeLevel(gradeLevel) {
                this.set('currentModel.selectedGradeLevel', gradeLevel);
            },

            setReportingCategory: function setReportingCategory(category) {
                this.set('currentModel.selectedReportingCategory', category);
            },

            setAllStudentSelected: function setAllStudentSelected() {
                this.set('currentModel.isAllStudentsSelected', !this.get('currentModel.isAllStudentsSelected'));
            },

            hideAlert: function hideAlert() {
                this.set('currentModel.showAlert', false);
            },

            setStudentGroup: function setStudentGroup(group) {
                this.get('currentModel.selectedStudentGroups').indexOf(group) == -1 ? this.get('currentModel.selectedStudentGroups').pushObject(group) : this.get('currentModel.selectedStudentGroups').removeObject(group);
            },

            goBack: function goBack() {
                this.set('currentModel.didReportRun', false);
                this.set('currentModel.reportData', null);
                this.set('currentModel.isAllStudentsSelected', false);
                this.set('currentModel.selectedStudentGroups', []);
                this.set('currentModel.selectedStudents', []);
            },

            goToQuizzes: function goToQuizzes() {
                this.transitionTo('teacher-quizzes');
            },

            runReport: function runReport() {
                var _this2 = this;

                this.validateFields(function () {
                    //make sure they selected atleast one student
                    if (_this2.get('currentModel.selectedStudentGroups').length <= 0 && _this2.get('currentModel.selectedStudentIds').length <= 0 && !_this2.get('currentModel.isAllStudentsSelected')) {

                        _this2.set('currentModel.showAlert', true);
                        _this2.set('currentModel.alertMessage', 'Please select at least one student or student group.');
                    } else {
                        _this2.set('currentModel.isLoading', true);
                        var host = _this2.store.adapterFor('application').get('host');
                        Ember.$.ajax({
                            url: host + '/report/studentStandardMastery',
                            data: {
                                groupIds: _this2.get('currentModel.selectedStudentGroups'),
                                studentIds: _this2.get('currentModel.selectedStudentIds'),
                                isAllStudentsSelected: _this2.get('currentModel.isAllStudentsSelected'),
                                reportingCategory: _this2.get('currentModel.selectedReportingCategory')
                            },
                            type: 'Get'
                        }).done(function (response) {
                            _this2.set('currentModel.reportData', response);
                            _this2.set('currentModel.didReportRun', true);
                            _this2.set('currentModel.isLoading', false);
                        }).fail(function (xhr, status, error) {
                            _this2.set('currentModel.showAlert', true);
                            _this2.set('currentModel.isLoading', false);
                            _this2.set('currentModel.isAllStudentsSelected', false);
                            _this2.set('currentModel.selectedStudentGroups', []);
                            _this2.set('currentModel.alertMessage', 'Oops! No results were found under that search, Please try again.');
                        });
                    }
                }, function () {
                    _this2.set('currentModel.isLoading', false);
                });
            }
        }

    });
});
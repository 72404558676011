define('ohs/routes/student-review-quiz', ['exports', 'ohs/mixins/reset', 'ohs/mixins/no-navigation', 'ohs/mixins/authenticated-route-mixin'], function (exports, _reset, _noNavigation, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _noNavigation.default, _authenticatedRouteMixin.default, {
    titleToken: function titleToken(model) {
      return 'Review ' + model.studentTestAdministration.get('administration').get('quiz').get('quizName');
    },
    signalR: Ember.inject.service('signal-r'),

    model: function model(params) {
      var self = this;
      var store = this.store;

      // var cur = store.findRecord('studentTestAdministration',params.student_test_id);
      // var quizId = cur.get('administration').get('quiz.id');
      // var studentAnswers=  store.findAll('studentAnswer');

      return this.store.findRecord('studentTestAdministration', params.student_test_id, { reload: true }).then(function (cur) {
        return Ember.RSVP.hash({
          studentTestAdministration: cur,
          testQuestions: store.query('testQuestion', { testId: cur.get('administration').get('quiz.id') }),
          teacher: store.findRecord('user', cur.get('administration').get('quiz').get('createUserId')),
          studentTestId: params.student_test_id,

          completeBy: Ember.computed('studentTestAdministration.administration.futureEndDate', function () {
            if (this.studentTestAdministration.get('administration.futureEndDate')) {
              return 'Complete by ' + this.studentTestAdministration.get('administration.futureEndDate');
            }
          }),

          message: "",
          messageClass: "",
          iconClass: "",
          allowRedirect: null
        });
      });
    },
    afterModel: function afterModel(model) {

      if (model.studentTestAdministration.get('cancelled')) {
        model.showCancelAlert = true;
        model.alertMessage = "Your teacher has cancelled this quiz!";
      } else if (model.studentTestAdministration.get('quizEnded')) {
        model.showResultAlert = true;
        model.alertMessage = "This quiz has ended!";
      } else {
        this.get('signalR').administrationId = model.studentTestAdministration.get('administration').get('id');
        this.get('signalR').joinAdministration(model.studentTestAdministration.get('administration').get('id'));

        //Signal-R event subscription
        this.get('signalR').on('cancelQuiz', this, 'cancelQuiz');
        this.get('signalR').on('endQuiz', this, 'endQuiz');
        this.get('signalR').on('signalRConnectionAlert', this, 'signalRConnectionAlert');
        this.get('signalR').on('refreshModel', this, 'refreshModel');
        this.get('signalR').on('removeStudentFromAdministration', this, 'removeStudentFromAdministration');

        //flag the student test administration as in the review stage
        model.studentTestAdministration.set('inReview', true);
      }
    },
    cancelQuiz: function cancelQuiz(administrationId) {
      this.set('currentModel.studentTestAdministration.cancelled', true);
    },
    endQuiz: function endQuiz(administrationId, scheduledEnd) {
      this.set('currentModel.studentTestAdministration.quizEnded', true);
    },
    signalRConnectionAlert: function signalRConnectionAlert(message, messageClass, iconClass) {
      Ember.set(this.get('currentModel'), 'message', message);
      Ember.set(this.get('currentModel'), 'messageClass', messageClass);
      Ember.set(this.get('currentModel'), 'iconClass', iconClass);
    },
    refreshModel: function refreshModel() {
      this.refresh();
    },
    removeStudentFromAdministration: function removeStudentFromAdministration(administrationId) {
      //Leave Administration
      this.get('signalR').leaveAdministration(administrationId);
      this.set("currentModel.showCancelAlert", true);
      this.set("currentModel.alertMessage", "Your teacher has removed you from the quiz!");
      this.set("currentModel.allowRedirect", true);
    },


    actions: {

      loading: function loading(transition, originRoute) {
        if (originRoute != this) return true;
      },

      endQuiz: function endQuiz(student_test_id) {

        var numQuestions = this.currentModel.testQuestions.get('length');

        //finish the quiz
        var sta = this.currentModel.studentTestAdministration;
        //if there are still unanswered questions
        if (sta.get('numAnswered') < numQuestions) {
          //show modal popup
          this.set('currentModel.showModal', true);
          this.set('currentModel.modalMessage', 'You haven\'t answered all of the questions! Are you sure you want to end the quiz?');
        } else {
          sta.set('isFinished', true);
          sta.set('currentlyInQuiz', 0);
          var self = this;
          sta.save().then(function (a) {
            //unload from store
            a.unloadRecord();
            //Leave administration
            self.get('signalR').leaveAdministration(self.get('currentModel.studentTestAdministration.administration.id'));
            self.transitionTo('student-results', a.id, '1');
          }, function () {
            sta.rollbackAttributes();
            self.set("currentModel.showAlert", true);
            self.set("currentModel.alertMessage", "Your quiz could not be completed. Please check your internet connection and try again.");
          });
        }
      },

      finishQuiz: function finishQuiz() {

        var sta = this.currentModel.studentTestAdministration;
        //if (confirm("You haven't answered all of the questions! Are you sure you want to end the quiz?")) {
        sta.set('isFinished', true);
        sta.set('currentlyInQuiz', 0);
        var self = this;
        sta.save().then(function (a) {
          //Leave administration
          self.get('signalR').leaveAdministration(self.get('currentModel.studentTestAdministration.administration.id'));
          self.transitionTo('student-results', a.id, '1');
        }, function () {
          sta.rollbackAttributes();
          self.set("currentModel.showAlert", true);
          self.set("currentModel.alertMessage", "Your quiz could not be completed. Please check your internet connection and try again.");
        });
        //}
      },

      //Hide alert box
      hideAlert: function hideAlert() {
        this.set("currentModel.showAlert", false);
        this.set("currentModel.alertMessage", "");
      },

      //Hide alert box and transition To student-results route
      gotoResult: function gotoResult() {
        this.set("currentModel.showResultAlert", false);
        this.set("currentModel.alertMessage", "");
        //Leave administration
        this.get('signalR').leaveAdministration(this.get('currentModel.studentTestAdministration.administration.id'));
        this.transitionTo('student-results', this.get('currentModel').studentTestAdministration.get('id'), 1);
      },

      gotoQuizzes: function gotoQuizzes() {
        //Leave administration
        this.get('signalR').leaveAdministration(this.get('currentModel.studentTestAdministration.administration.id'));
        this.transitionTo('student-quizzes');
      },

      //decide whether or not the transition should occur.
      willTransition: function willTransition(transition) {
        var subjectId = this.get('currentModel.studentTestAdministration').get('administration').get('quiz').get('subject.id');
        if (subjectId == 2) {
          if (transition.targetName == "student-review-quiz" || transition.targetName == "student-passage-quiz" || transition.targetName == "student-results" || this.get('currentModel.studentTestAdministration.cancelled') || this.get('currentModel.allowRedirect')) {
            return true;
          }
        } else {
          if (transition.targetName == "student-review-quiz" || transition.targetName == "student-self-guided-question" || transition.targetName == "student-results" || this.get('currentModel.studentTestAdministration.cancelled') || this.get('currentModel.allowRedirect')) {
            return true;
          }
        }

        //redirect to error page when an error occur in this page like network failure
        if (transition.targetName == "error") {
          return true;
        }

        //redirectUrl is used to hold the current url to check whether its called from redirect action method or not
        var redirectUrl = this.get('currentModel.redirectUrl');
        if (redirectUrl && redirectUrl.targetName == transition.targetName) {
          return true;
        } else {
          this.set('currentModel.transitionUrl', transition);
          this.set('currentModel.confirmTransition', true);
          transition.abort();
        }
      },
      redirect: function redirect() {
        //Leave administration
        this.get('signalR').leaveAdministration(this.get('currentModel.studentTestAdministration.administration.id'));
        this.set('currentModel.confirmTransition', false);
        this.set('currentModel.redirectUrl', this.get('currentModel.transitionUrl'));
        this.transitionTo(this.get('currentModel.transitionUrl.targetName'));
      }
    }

  });
});
define('ohs/components/eligible-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['eligible-item'],
    classNameBindings: ['expanded'],
    expanded: false,

    didInsertElement: function didInsertElement() {
      this.get('parent').registerChild(this);
    },

    hide: function hide() {
      this.set('expanded', false);
    },

    actions: {
      show: function show() {
        this.toggleProperty('expanded');
        this.attrs.hideOthers(this);
      }
    }
  });
});
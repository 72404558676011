define('ohs/routes/teacher/start-quiz/pick-type', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return Ember.RSVP.hash({
                testTypes: this.store.peekAll('test-type').filterBy('isEnabled', true),
                quiz: Ember.get(this.modelFor('teacher.start-quiz'), 'administration.quiz'),
                quizId: Ember.computed('quiz.id', function () {
                    return Ember.get(this, 'quiz.id');
                }),
                filteredTestTypes: Ember.computed('testTypes.@each.id', 'testTypes.[]', 'quiz.subject.id', function () {
                    //test-types 1=self guided, 2=teacher-led, 3=game-mode
                    //we need to disable game-mode for ELA
                    var testTypes = Ember.get(this, 'testTypes');
                    if (Ember.get(this, 'quiz.subject.id') == 2) {
                        return testTypes.filter(function (tt) {
                            return Ember.get(tt, 'id') != 3;
                        });
                    }

                    return testTypes;
                })
            });
        },


        actions: {
            pickType: function pickType(testTypeId) {
                Ember.set(this.modelFor('teacher.start-quiz'), 'administration.testTypeId', parseInt(testTypeId));
                switch (parseInt(testTypeId)) {
                    case 1:
                    case 2:
                        this.transitionTo('start-quiz-2', this.get('currentModel.quizId'), testTypeId);
                        break;
                    case 3:
                        this.transitionTo('teacher.start-quiz.pick-students', this.get('currentModel.quizId'));
                        break;
                }
            }
        }
    });
});
define('ohs/components/chart-student-answer-choices', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var chartStudentAnswerChoices = Ember.Component.extend({
    studentTestAdministrations: [],
    height: 250,
    correctColor: '#a8cd4d',
    incorrectColor: '#ed633b',

    chartOptions: {
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          },
          scaleLabel: {
            display: true,
            labelString: 'Answer Options'
          }
        }],
        yAxes: [{
          ticks: {
            max: 100,
            min: 0,
            stepSize: 25
          },
          scaleLabel: {
            display: true,
            labelString: '% of Students'
          }
        }]
      },
      tooltips: {
        //Makes the colored block not display
        displayColors: false,
        callbacks: {
          //Choice C: 3 Students 
          title: function title(tooltipItem, data) {
            return 'Choice ' + tooltipItem[0].xLabel + ': ' + tooltipItem[0].yLabel + '%';
          },
          //return nothing for the label
          label: function label(tooltipItem, data) {
            return '';
          }
        }
      }
    }
  });

  chartStudentAnswerChoices.reopenClass({
    positionalParams: ['administration', 'studentTestAdministrations', 'height']
  });

  exports.default = chartStudentAnswerChoices;
});
define('ohs/routes/teacher/start-quiz', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        session: Ember.inject.service('session'),

        titleToken: function titleToken(model) {
            return "Start " + model.administration.get('quiz.quizName');
        },

        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            // Implement your custom setup after
            this.controllerFor('application').set('showSecondaryNav', true);
            this.controllerFor('application').set('teacher', true);
        },

        model: function model(params) {
            var _this = this;

            return this.store.findRecord('quiz', params.quiz_id).then(function (q) {
                return Ember.RSVP.hash({
                    quiz: q,
                    teamColors: _this.store.findAll('team-color'),
                    administration: _this.store.createRecord('administration', {
                        quiz: q,
                        isComplete: false,
                        currentQuestionIndex: 0,
                        testTypeId: -1,
                        isOpen: false
                    }),
                    testTypes: _this.store.findAll('test-type'),
                    displayTestType: Ember.computed('administration.testTypeId', function () {
                        var testType = this.testTypes.filterBy('id', this.administration.get('testTypeId').toString())[0];
                        if (testType) {
                            return testType.get('name');
                        } else {
                            return null;
                        }
                    })
                });
            });
        },
        deactivate: function deactivate() {
            if (!get(this, 'currentModel.administration.id')) get(this, 'currentModel.administration').unloadRecord();
        },


        actions: {
            printQuestions: function printQuestions() {
                var self = this;

                //  Added by Jibin for feature 3098	(Kandoolu - Remove 'Print Quiz' option from users on a Free Trial) on 02/23/2017
                var account = self.get('store').peekAll('account');
                var isFreeTrial = account.objectAt(0).get('isFreeTrial');
                if (isFreeTrial && isFreeTrial == true) {
                    self.set('currentModel.showAlert', true);
                    self.set('currentModel.alertMessage', "Printing quizzes is disabled during your free trial. To enable this feature, please upgrade to a full subscription!");
                    return;
                }
                //  feature 3098 end

                self.set("currentModel.isProcessing", true);
                self.set('currentModel.isPrinting', true);

                var quizId = this.get('currentModel.quiz.id');
                var baseUrl = this.store.adapterFor('application').get('host');

                var sesObj = this.get('session');
                var userId = sesObj.session.content.authenticated["userId"];
                return Ember.$.getJSON(baseUrl + '/quiz/GenerateQuizQuestionsPdf?quizId=' + quizId + '&userId=' + encodeURIComponent(userId)).done(function (response) {
                    //self.refresh();
                    self.set("currentModel.isProcessing", false);

                    var xhr = new XMLHttpRequest();
                    xhr.open('GET', baseUrl + '/quiz/DownloadQuizQuestions?fileName=' + encodeURIComponent(response), true);
                    xhr.responseType = "blob";
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState == 4) {
                            var blob = new Blob([xhr.response], { type: "application/pdf" });
                            //
                            // var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
                            // if(isSafari){
                            //   var blob = new Blob([xhr.response], {type: "application/pdf"});
                            // }
                            // else{
                            //   var blob = new Blob([xhr.response], {type: "application/pdf"});
                            // }
                            saveAs(blob, response);
                        }
                    };
                    xhr.send(null);

                    self.set('currentModel.isPrinting', false);
                }).fail(function (response) {
                    self.set("currentModel.isProcessing", false);
                    self.set("currentModel.error", true);
                    self.set('currentModel.isPrinting', false);
                });
            }
        }
    });
});
define('ohs/models/question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    questionText: _emberData.default.attr('string'),
    dokLevelId: _emberData.default.attr('number'),
    hasTemplate: _emberData.default.attr('boolean'),
    subjectId: _emberData.default.attr('number'),
    difficultyIndexId: _emberData.default.attr('number'),
    standards: _emberData.default.hasMany('standard', { async: true, embedded: 'always' }),
    answerOptions: _emberData.default.hasMany('answerOption', { async: true, embedded: 'always' }),
    previouslyUsed: _emberData.default.attr(),
    minimumAnswerWidth: _emberData.default.attr('number'),
    audioUrl: _emberData.default.attr('string'),
    questionType: _emberData.default.attr('string'),
    secondPartQuestion: _emberData.default.belongsTo('secondPartQuestion'),

    eligibleContentCode: Ember.computed('eligibleContent.id', function () {
      if (!Ember.get(this, 'eligibleContent')) return;

      return Ember.get(this, 'eligibleContent.standardCode');
    }),

    eligibleContent: Ember.computed('standards.@each.id', 'standards.[]', function () {
      if (this.get('standards') && this.get('standards').get('length') > 0) return this.get('standards.firstObject');

      return null;
    }),

    difficultyIndexDesc: Ember.computed('difficultyIndexId', function () {
      if (this.get('difficultyIndexId')) {
        var id = this.get('difficultyIndexId');
        if (id == 1) {
          return "Easy";
        } else if (id == 2) {
          return "Moderate";
        } else if (id == 3) {
          return "Difficult";
        }
      }
    }),

    teaserText: Ember.computed('questionText', function () {
      var text = this.get('questionText');

      //count fraction images as a single character and other images as the end of the teaser text
      var charactersToAdd = 0;
      var endIndex = 250;
      var imgSpliterList;
      var imgSpliterList = text.split('<img');
      if (imgSpliterList.length > 1) {
        for (var i = 1; i < imgSpliterList.length; i++) {
          var img = imgSpliterList[i];
          img = img.substring(0, img.indexOf('>') + 1);
          img = '<img' + img;
          if (text.indexOf(img) > endIndex + charactersToAdd) {
            endIndex = text.substring(0, text.indexOf(img)).lastIndexOf(' ') - charactersToAdd;
          } else {
            if (img.indexOf('Wirisformula') >= 0) {
              charactersToAdd += img.length;
            } else {
              endIndex = text.indexOf(img) - charactersToAdd;
            }
          }
        }
      }

      if (text.length > endIndex + charactersToAdd) {
        return text.substring(0, endIndex + charactersToAdd) + " ...";
      } else {
        return text;
      }
    }),

    dokDisplay: Ember.computed('dokLevelId', function () {
      if (this.get('subjectId') == 1) {
        return this.get('dokLevelId');
      }
    }),

    numPoints: Ember.computed('secondPartQuestion.numCorrectAnswers', function () {
      return (Ember.get(this, 'secondPartQuestion.numCorrectAnswers') || 0) + 1;
    }),

    orderedAnswers: Ember.computed('answerOptions.@each.id', 'answerOptions.[]', function () {
      return Ember.get(this, 'answerOptions').sortBy('id');
    })
  });
});
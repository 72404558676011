define('ohs/components/resource-student-rating-property', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    boringActive: Ember.computed('rating.{wasBoring,wasHelpful,wasFun,wasEasy,wasHard,wasQuick,wasTooLong,wasBroken,wasConfusing}', function () {
      if (this.get('rating').get(this.get('property'))) {
        return 'selected';
      }
    }),

    propertyString: Ember.computed('property', function () {
      return 'rating.' + this.get('property');
    }),

    actions: {
      toggleProperty: function toggleProperty() {
        var rating = this.get('rating');
        var property = this.get('property');
        if (rating.get(property)) {
          rating.set(property, false);
        } else {
          rating.set(property, true);
        }
      }

    }
  });
});
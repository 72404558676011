define('ohs/components/student-passage-quiz', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['student-passage-quiz'],
    store: Ember.inject.service(),

    answerSelected: Ember.computed('currentQuestionIndex', function () {
      var index = this.get('currentQuestionIndex');
      var answer = this.get('questions').objectAt(index).get('answer');
      return answer != null && answer != undefined;
    }),

    questions: [],
    secondAnswersArray: [],
    passage: {},
    viewMode: 'passage',
    viewModeString: Ember.computed('viewMode', function () {
      return this.get('otherViewMode').capitalize();
    }),

    currentQuestionCount: Ember.computed('currentQuestionIndex', function () {
      return this.get('currentQuestionIndex') + 1;
    }),

    secondQuestionEnabled: Ember.computed('currentQuestionIndex', 'questions.@each.[]', function () {
      var index = this.get('currentQuestionIndex');
      var firstAnswer = this.get("questions").objectAt(index).get('answer');

      return !(firstAnswer == null || firstAnswer == undefined);
    }),

    otherViewMode: Ember.computed('viewMode', function () {
      return this.get("viewMode") == 'passage' ? 'questions' : 'passage';
    }),

    enableSecondQuestion: Ember.computed('currentQuestionIndex', 'answerSelected', function () {
      return this.get('answerSelected') ? 'is-enabled' : 'is-disabled';
    }),

    showNextQuestion: Ember.computed('currentQuestionIndex', function () {
      return this.get('currentQuestionIndex') < this.get('questions.length') - 1;
    }),

    showPrevQuestion: Ember.computed('currentQuestionIndex', function () {
      return this.get('currentQuestionIndex') > 0;
    }),

    currentAnswer: Ember.computed('currentQuestionIndex', function () {
      var qId = this.get('questions').objectAt(this.get('currentQuestionIndex')).get('question').get('id');
      var ans = '';
      this.get('studentTestAdministration').get('studentAnswers').forEach(function (item) {
        if (item.get('questionId') == qId) {
          ans = item.get('id');
        }
      });
      return ans;
    }),

    currentAnswerId: Ember.computed('currentQuestionIndex', function () {
      var qId = this.get('questions').objectAt(this.get('currentQuestionIndex')).get('question').get('id');
      var ans = '';
      this.get('studentTestAdministration').get('studentAnswers').forEach(function (item) {
        if (item.get('questionId') == qId) {
          ans = item.get('answerId');
        }
      });
      return parseInt(ans);
    }),

    currentSecondSelectedPartAnswers: Ember.computed('currentQuestionIndex', function () {
      var testQuestion = this.get('questions').objectAt(this.get('currentQuestionIndex'));
      var qId = testQuestion.get('question').get('id');
      var secAnsArray = new Array();
      var AnswerArray = new Array();

      var s = this.get('studentTestAdministration').get('studentSecondPartAnswers');
      this.get('studentTestAdministration').get('studentSecondPartAnswers').forEach(function (item) {
        if (item.get('questionId') == qId) {
          secAnsArray.pushObject(item.get('secondPartAnswerId'));
        }
      });
      if (testQuestion.get('question').get('secondPartQuestion').get('secondPartAnswerOptions')) {
        testQuestion.get('question').get('secondPartQuestion').get('secondPartAnswerOptions').forEach(function (item) {
          var answerId = item.get('id');
          if (secAnsArray.includes(parseInt(answerId))) {
            AnswerArray.pushObject(item);
          }
        });
        return AnswerArray;
      }
    }),

    currentSecondAnswers: Ember.computed('currentQuestionIndex', function () {
      var secondAnswersUpdateArray = new Array();
      var qId = this.get('questions').objectAt(this.get('currentQuestionIndex')).get('question').get('id');
      this.get('studentTestAdministration').get('studentSecondPartAnswers').forEach(function (item) {
        if (item.get('questionId') == qId) {
          secondAnswersUpdateArray.pushObject(item.get('id'));
        }
      });

      return secondAnswersUpdateArray;
    }),

    hasUnansweredQuestions: Ember.computed('answerSelected', 'currentQuestionIndex', function () {
      var unanswered = this.get('questions').filter(function (q, i) {
        var firstPartUnanswered = q.answer == undefined || q.answer == null;

        if (!firstPartUnanswered && q.secondQuestionText) {
          return q.secondAnswer == null || q.secondAnswer == undefined;
        } else {
          return firstPartUnanswered;
        }
      });

      return unanswered.length > 0;
    }),

    didRender: function didRender() {

      Ember.run.later(function () {
        function backToTopHandler() {
          var startTop = $('#back-to-top').offset().top;

          $(window).on('scroll', function (e) {
            var newTop = $(window).scrollTop();

            if (newTop > startTop) {
              $('#back-to-top').addClass('is-visible');
            } else {
              $('#back-to-top').removeClass('is-visible');
            }
          });
        }

        function questionMobileNavFixedHandler() {
          var startTop = $('.view-mode-selection').offset().top + 150;
          $(window).on('scroll', function (e) {
            var newTop = $(window).scrollTop();
            if (newTop > startTop) {
              $('.view-mode-selection').addClass('is-fixed');
            } else {
              $('.view-mode-selection').removeClass('is-fixed');
            }
          });
        }

        backToTopHandler();
        questionMobileNavFixedHandler();
      }, 400);
    },

    actions: {
      toSummary: function toSummary() {
        var _this = this;

        Ember.set(this, 'isProcessing', true);
        this.saveTestAnswers().then(function () {
          _this.sendAction('toSummary');
        });
      },

      nextQuestion: function nextQuestion() {
        var _this2 = this;

        var index = this.get('currentQuestionIndex');
        var testAnswers = this.saveTestAnswers().then(function () {
          if (index != _this2.get('questions.length') - 1) {
            _this2.set('currentQuestionIndex', _this2.get('currentQuestionIndex') + 1);
            _this2.set('validationErrors', '');
            _this2.attrs.nextAction();
          }
        });
      },

      prevQuestion: function prevQuestion() {
        var _this3 = this;

        var index = this.get('currentQuestionIndex');
        this.saveTestAnswers().then(function () {
          if (index != 0) {
            _this3.set('currentQuestionIndex', _this3.get('currentQuestionIndex') - 1);
            _this3.set('validationErrors', '');
            _this3.attrs.prevAction();
          }
        });
      },

      selectAnswer: function selectAnswer(answer) {
        var index = this.get('currentQuestionIndex');
        this.get('questions').objectAt(index).set('answer', answer);
        this.notifyPropertyChange('answerSelected');
      },

      selectSecondAnswer: function selectSecondAnswer(secpartAnswer) {
        var index = this.get('currentQuestionIndex');

        var isexist = false;
        this.get('secondAnswersArray').forEach(function (item, index) {
          if (item == secpartAnswer) {
            isexist = true;
          }
        });
        if (isexist == true) {
          this.get('secondAnswersArray').removeObject(secpartAnswer);
        } else {
          this.get('secondAnswersArray').pushObject(secpartAnswer);
        }
        this.get('questions').objectAt(index).set('secondAnswers', this.get('secondAnswersArray'));
        this.get('questions').objectAt(index).set('secondAnswerlength', this.get('secondAnswersArray.length'));
        this.notifyPropertyChange('answerSelected');
      },

      hideAlert: function hideAlert() {
        this.set('showAlert', false);
        this.set('alertMessage', '');
      },

      toggleViewMode: function toggleViewMode() {
        var mode = this.get("viewMode");

        if (mode == 'passage') {
          this.set('previousPosition', this.$(window).scrollTop());
        } else {
          this.$('html, body').animate({ scrollTop: this.get('previousPosition') });
        }

        this.set('viewMode', this.get('otherViewMode'));
      },

      saveLastAnswers: function saveLastAnswers() {
        var testAnswers = this.saveTestAnswers();
        if (testAnswers != false) {
          this.set('validationErrors', '');
        }
      },

      acceptWarning: function acceptWarning() {
        this.attrs.submitAction();
      },

      reviewAnswers: function reviewAnswers() {
        this.sendAction('setPropertyAction', parseInt(this.get('studentTestId')));
      },

      backToTop: function backToTop() {
        $('html, body').animate({
          scrollTop: 0
        });
      }
    },

    saveTestAnswers: function saveTestAnswers() {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var studentAnswerId = _this4.get('currentAnswerId');
        var secpartAnswers = _this4.get('currentSecondAnswers');
        var chosenAnswer = '';
        var answerId = '';
        var index = _this4.get('currentQuestionIndex');
        var studentTestQuestionAnwers = _this4.get('questions').objectAt(index);
        var qestionId = studentTestQuestionAnwers.get('question').get('id');
        var secAnswerLength = _this4.get('questions').objectAt(index).get('secondAnswerlength');
        var secondPartQestionId = studentTestQuestionAnwers.get('question').get('secondPartQuestion.id');
        var secAnswers = studentTestQuestionAnwers.get('secondAnswers');
        var secondAnswerAllowedCount = _this4.get('questions').objectAt(index).get('question').get('secondPartQuestion').get('secondAnswerAllowedCount');
        var self = _this4;

        //I believe this checks to see if any data needs saved
        if (!studentTestQuestionAnwers.get('answer') && !studentTestQuestionAnwers.get('secondAnswers') && !studentAnswerId) {
          _this4.toggleProperty('isProcessing');
          return resolve();
        }

        if (studentTestQuestionAnwers.get('secondAnswers') && !studentTestQuestionAnwers.get('answer')) {
          if (!studentAnswerId) {
            _this4.set('validationErrors', "You must answer first part question.");
            _this4.toggleProperty('isProcessing');
            return reject();
          }
        }

        if (studentTestQuestionAnwers.get('answer')) {
          answerId = parseInt(studentTestQuestionAnwers.get('answer'));
          chosenAnswer = studentTestQuestionAnwers.get('question').get('answerOptions').findBy('id', studentTestQuestionAnwers.get('answer').toString());
        } else {
          answerId = parseInt(studentAnswerId);
          chosenAnswer = studentTestQuestionAnwers.get('question').get('answerOptions').findBy('id', studentAnswerId.toString());
        }

        if (!studentAnswerId) {
          var answer = _this4.get('store').createRecord('studentAnswer', {
            studentTestId: parseInt(_this4.get('studentTestId')),
            questionId: qestionId,
            answerId: answerId,
            isCorrect: chosenAnswer.get('isCorrect'),
            studentTestAdministration: _this4.get('studentTestAdministration')
          });

          answer.save().then(function (ans) {
            self.get('studentTestAdministration').get('studentAnswers').pushObject(ans);
            if (secAnswers) {
              for (var i = 0; i < secAnswers.length; i++) {
                var secondPartAnswer = self.get('store').createRecord('studentSecondPartAnswer', {
                  studentTestId: parseInt(self.get('studentTestId')),
                  questionId: qestionId,
                  secondPartQuestionId: secondPartQestionId,
                  secondPartAnswerId: parseInt(secAnswers.objectAt(i)),
                  isCorrect: studentTestQuestionAnwers.get('question').get('secondPartQuestion').get('secondPartAnswerOptions').findBy('id', secAnswers[i].toString()).get('isCorrect')
                });
                secondPartAnswer.save().then(function (secAns) {
                  self.get('studentTestAdministration').get('studentSecondPartAnswers').pushObject(secAns);
                  studentTestQuestionAnwers.set('secondAnswers.length', 0);
                  self.set('secondAnswersArray.length', 0);
                }, function () {
                  secondPartAnswer.rollbackAttributes();
                });
              }
            }
            //all the new answers have been saved.
            _this4.toggleProperty('isProcessing');
            resolve();
          }, function () {
            answer.rollbackAttributes();
            self.set("showAlert", true);
            self.set("alertMessage", "Your answer could not be saved. Please check your internet connection and try again.");
            self.set('answersDisabled', false);
            _this4.toggleProperty('isProcessing');
            reject();
          });
        } else {
          var _answer = self.get('store').peekRecord('studentAnswer', _this4.get('currentAnswer'));
          _answer.set('answerId', answerId);
          _answer.set('isCorrect', chosenAnswer.get('isCorrect'));

          _answer.save().then(function () {
            for (var j = 0; j < secpartAnswers.length; j++) {
              var spa = self.get('store').peekRecord('studentSecondPartAnswer', secpartAnswers.objectAt(j));
              var spaId = spa.get('secondPartAnswerId');

              if (self.get('secondAnswersArray').includes(spaId.toString())) {
                self.get('secondAnswersArray').removeObject(spaId.toString());
                spa.destroyRecord();
              }
            }

            for (var k = 0; k < self.get('secondAnswersArray.length'); k++) {
              var secondPartAnswer = self.get('store').createRecord('studentSecondPartAnswer', {
                studentTestId: parseInt(self.get('studentTestId')),
                questionId: qestionId,
                secondPartQuestionId: secondPartQestionId,
                secondPartAnswerId: parseInt(secAnswers.objectAt(k)),
                isCorrect: studentTestQuestionAnwers.get('question').get('secondPartQuestion').get('secondPartAnswerOptions').findBy('id', secAnswers[k].toString()).get('isCorrect')
              });
              secondPartAnswer.save().then(function (secAns) {
                self.get('studentTestAdministration').get('studentSecondPartAnswers').pushObject(secAns);
                // studentTestQuestionAnwers.get('secondAnswers').removeAt(0,secAnswers.length);
                studentTestQuestionAnwers.set('secondAnswers.length', 0);
                self.set('secondAnswersArray.length', 0);
              }, function () {
                secondPartAnswer.rollbackAttributes();
              });
            }
            //save all the updates
            _this4.toggleProperty('isProcessing');
            resolve();
          }, function () {
            _answer.rollbackAttributes();
            self.set("showAlert", true);
            self.set("alertMessage", "Your answer could not be saved. Please check your internet connection and try again.");
            _this4.toggleProperty('isProcessing');
            reject();
          });
        }
      });
    }
  });
});
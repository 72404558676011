define('ohs/components/quiz-steps', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    children: [],
    store: Ember.inject.service(),
    expandedChildIndex: 0,
    useDefaultName: true,
    session: Ember.inject.service('session'),

    step1NextEnabled: Ember.computed('model.quiz.subjectId', 'model.quiz.gradeLevelId', 'model.quiz.reportingCategory', function () {
      if (this.get('model.quiz.subjectId') == 2) {
        return true;
      } else {
        return this.get('model.quiz.reportingCategory');
      }
    }),

    registerChild: function registerChild(child) {
      this.get('children').pushObject(child);
      if (child.get('number_item') <= this.get('expandedChildIndex')) {
        child.showEditable();
      }
    },

    didRender: function didRender() {
      if (this.get('scrollTo')) {
        Ember.set(this, 'scrollTo', false);
        var child = this.get('children').get('lastObject').$();
        Ember.run.later(function () {
          $('html, body').animate({
            scrollTop: child.offset().top
          }, 800);
        }, 400);
      }
    },

    showNextChild: function showNextChild(child) {
      var children = this.get('children');
      var thischildindex = children.indexOf(child);
      var nextchildindex = thischildindex + 1;

      var nextChild = children.objectAt(nextchildindex);

      while (!nextChild.$().hasClass('is-enabled')) {
        nextchildindex += 1;
        nextChild = children.objectAt(nextchildindex);
      }

      this.set('expandedChildIndex', nextchildindex);

      child.showEditable();
      nextChild.showContent();
      child.hideContent();

      if (nextchildindex == 3) {
        $('#btnNameQuiz').prop('disabled', false);
      } else {
        $('#btnNameQuiz').prop('disabled', true);
      }

      Ember.run.later(function () {
        $('html, body').animate({
          scrollTop: nextChild.$().offset().top
        }, 800);
      }, 400);
    },

    collapseNextSteps: function collapseNextSteps(child) {
      var thischildindex = this.get('children').indexOf(child);

      this.set('expandedChildIndex', thischildindex);

      var others = this.get('children').filter(function (item, index, self) {
        if (index > thischildindex) {
          return true;
        }
      });

      child.showContent();
      child.hideEditable();
      others.forEach(function (item) {
        item.hideContent();
        item.hideEditable();
      });
    },

    willDestroyElement: function willDestroyElement() {
      var children = this.get('children');
      children.length = 0;
    },

    level1LabelText: Ember.computed('model.standardType.level1Label', 'model.quiz.gradeLevelId', function () {
      if (this.get('model.quiz.gradeLevelId') == 1 || this.get('model.quiz.gradeLevelId') == 2 || this.get('model.quiz.gradeLevelId') == 23) {
        return this.get('model.standardType.level1LabelAltText');
      } else {
        return this.get('model.standardType.level1Label');
      }
    }),

    actions: {
      setGradeLevel: function setGradeLevel(selectedValue) {
        this.get('model').quiz.set('gradeLevelId', parseInt(selectedValue));
        this.send('setReportingCategory', '');
        this.get('model').quiz.set('questions', []);

        var rcs = this.get('model.filteredCategories');
        if (rcs.get('length') == 1) {
          this.send('setReportingCategory', rcs.get('firstObject'));
        }
      },

      //not going to lie, this method needs some work. ( But it functions )
      setReportingCategory: function setReportingCategory(value) {
        var quiz = this.get('model').quiz;

        if (Number(value)) {
          value = this.get('store').peekRecord('standard', value);
        }

        if (value) {
          quiz.set('standards', []);
          quiz.get('standards').pushObject(value);
        } else if (value == "") {
          this.get('model').quiz.set('standards', []);
        }
      },

      setSubject: function setSubject(selectedValue) {
        if (selectedValue > 0) {
          this.get('model').quiz.set('subjectId', parseInt(selectedValue));
          this.send('setReportingCategory', '');
          this.get('model').quiz.set('questions', []);
          this.get('model').quiz.set('subject', this.get('store').peekRecord('subject', parseInt(selectedValue)));

          var rcs = this.get('model.filteredCategories');
          if (rcs.get('length') == 1) {
            this.send('setReportingCategory', rcs.get('firstObject'));
          }
        }

        // toggle enabled focus / skills steps for grammar quizzes
        var stepsEnabled = this.get('model').quiz.get('subject.id') != 2;
        this.get('children').objectAt('1').set('enabled', stepsEnabled);
        this.get('children').objectAt('2').set('enabled', stepsEnabled);
      },

      setAnchor: function setAnchor(selectedValue) {
        var anchorId = selectedValue;
        if (anchorId) {
          var quiz = this.get('model').quiz;
          var standards = quiz.get('standards'),
              list = standards.toArray();
          standards.removeObjects(list);
          var removeStandard;
          var ecToDelete = [];

          list.forEach(function (standard) {
            if (standard.get('standardLevelId') == 2 && standard.get('id') == anchorId) {
              removeStandard = standard;
            }
            if (standard.get('parentStandardId') == anchorId) {
              ecToDelete.pushObject(standard.get('id'));
            }
          });

          if (removeStandard) {
            if (ecToDelete) {
              var self = this;
              ecToDelete.forEach(function (id) {
                list.removeObject(self.get('store').peekRecord('standard', parseInt(id)));
              });
            }
            list.removeObject(removeStandard);
          } else {
            var selectedStandard = this.get('store').peekRecord('standard', parseInt(anchorId));
            list.pushObject(selectedStandard);
          }
          standards.pushObjects(list);
          this.get('model').quiz.set('questions', []);
        }
      },
      setEligibleContent: function setEligibleContent(selectedValue) {
        var eligibleContentId = selectedValue;
        if (eligibleContentId) {
          var quiz = this.get('model').quiz;
          var standards = quiz.get('standards'),
              list = standards.toArray();
          standards.removeObjects(list);
          var removeStandard;

          list.forEach(function (standard) {
            if (standard.get('standardLevelId') >= 3 && standard.get('id') == eligibleContentId) {
              removeStandard = standard;
            }
          });

          if (removeStandard) {
            list.removeObject(removeStandard);
          } else {
            var selectedStandard = this.get('store').peekRecord('standard', parseInt(eligibleContentId));
            list.pushObject(selectedStandard);
          }
          standards.pushObjects(list);

          //set the quiz name
          if (this.get('useDefaultName')) {
            var eligiblecontent = this.get('model').quiz.get('eligibleContentQueryString');
            var name = "";
            if (eligiblecontent.length > 0) {
              if (eligiblecontent.indexOf(",") > 0) {
                var assessmentAnchors = this.get('model').quiz.get('assessmentAnchors');
                if (assessmentAnchors.length > 1) {
                  var standard = this.get('model.quiz.standards').findBy('standardLevelId', 1);
                  name = standard.get('standardCode');
                } else {
                  var standard = assessmentAnchors.get('firstObject');
                  name = standard.get('standardCode');
                }
              } else {
                var standard = this.get('model').quiz.get('standards').findBy('id', eligiblecontent);
                name = standard.get('standardCode');
              }
              this.get('model').quiz.set('standardCodeDisplay', name);
              this.get('model').quiz.set('quizName', "Quiz " + name);
            }
          }
          this.get('model').quiz.set('questions', []);
        }
      },


      nameQuiz: function nameQuiz() {
        document.activeElement.blur();
        var quiz = this.get('model').quiz;
        //trim quiz name
        quiz.set('quizName', quiz.get('quizName').trim());
        var quizName = quiz.get('quizName');
        //check whether quiz name is empty or not
        if (Ember.isBlank(quizName)) {
          this.set("model.validationErrors", "The quiz name can\'t be blank.");
          return false;
        }

        var quizId = quiz.get('id');
        var self = this;
        if (!quizId) {
          quizId = 0;
        }
        var sesObj = this.get('session');
        var userId = sesObj.session.content.authenticated["userId"];

        var duplicateCheckUrl = this.get('store').adapterFor('application').get('host') + '/quiz/hasDuplicateQuizName?quizId=' + quizId;
        duplicateCheckUrl += '&quizName=' + encodeURIComponent(quizName) + '&userId=' + encodeURIComponent(userId);

        Ember.$.getJSON(duplicateCheckUrl).done(function (response) {
          self.set("model.validationErrors", '');
          // TODO: fix validation errors when creating quizzes without focus/ skills selected
          // invalid quizzes are actually passage-based quizzes
          if (quiz.get('subject.id') == 2) {
            self.set('model.quiz.passageBased', true);
            self.sendAction('passageTransition');
          } else {
            self.sendAction('transitionAction');
          }
        }).fail(function (response) {
          self.set("model.validationErrors", response.responseText);
        });
      }
    }

  });
});
define('ohs/helpers/choose-article', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.chooseArticle = chooseArticle;
  function chooseArticle(params /*, hash*/) {
    if (params[0]) {
      var noun = params[0].toLowerCase();

      //String.prototype.startsWith method is not supported by IE. So polyfill String.prototype.startsWith() with the following snippet to fix the issue
      if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (noun, position) {
          position = position || 0;
          return this.substr(position, noun.length) === noun;
        };
      }

      if (noun.startsWith('a') || noun.startsWith('e') || noun.startsWith('i') || noun.startsWith('o') || noun.startsWith('u')) {
        return "an";
      } else {
        return "a";
      }
    }
  }

  exports.default = Ember.Helper.helper(chooseArticle);
});
define('ohs/components/game-mode-banner', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        quizName: null,
        accessCode: null
        // didInsertElement() {
        //     this.set('rLeft', this.$().find("#rover-left"));
        //     this.set('rRight', this.$().find("#rover-right"));
        //     this.set('rTracksLeft', this.$().find(".rover-left-tracks"));
        //     this.set('rTracksRight', this.$().find(".rover-right-tracks"));
        //     this.set('rWheelsRight', this.$().find("#rover-right .wheel"));
        //     this.set('rWheelsLeft', this.$().find("#rover-left .wheel"));
        //     this.set('rEyeLeft', this.$().find("#rover-left .eye"));
        //     this.set('rPupilLeft', this.$().find("#rover-left .pupil"));
        //     this.set('rEyeRight', this.$().find("#rover-right .eye"));
        //     this.set('rPupilRight', this.$().find("#rover-right .pupil"));
        //     this.set('rBody', this.$().find(".rover-body"));
        //     this.set('rHead', this.$().find(".head"));
        //     this.set('rHeadLeft', this.$().find("#rover-left .head"));
        //     this.set('rHeadRight', this.$().find("#rover-right .head"));
        //     this.set('rBodyBar', this.$().find(".body-bar"));
        //     this.set('star', this.$().find(".star"));
        //     this.set('shootingStarRight', this.$().find(".shooting-star-right"));
        //     this.set('shootingStarLeft', this.$().find(".shooting-star-left"));
        //     this.set('tlRoverEnter', new TimelineLite())
        //     this.set('tlRoverHead', new TimelineMax({delay: 5, repeat: -1, repeatDelay: 7}))
        //     this.set('tlStars', new TimelineLite())
        //     this.set('tlShootingStars', new TimelineMax({repeat: -1, repeatDelay: 4}));
        //     this.set('buttonRestart', this.$().find("#run-animation"));
        //
        //     this.get('tlRoverEnter')
        //             .to(this.get('rLeft'), 1.5, {css: {left:"60%"}, ease: Sine.easeInOut}, "roverStart")
        //             .to(this.get('rTracksLeft'), 1.5, {css: {width:"65%"}, ease: Sine.easeInOut}, "roverStart")
        //             .to(this.get('rTracksRight'), 1.5, {css: {width:"65%"}, ease: Sine.easeInOut}, "roverStart")
        //             .to(this.get('rRight'), 1.5, {css: {right:"60%"}, ease: Sine.easeInOut}, "roverStart")
        //             .to(this.get('rWheelsRight'), 1.5, {rotation:-360, transformOrigin:"50% 50%", ease: Sine.easeInOut}, "roverStart")
        //             .to(this.get('rWheelsLeft'), 1.5, {rotation:360, transformOrigin:"50% 50%", ease: Sine.easeInOut}, "roverStart")
        //             .to(this.get('rBody'), 0.2, {y:-3, yoyo: true, ease: Bounce.easeIn, repeat: 4}, "roverStart+=0.1")
        //             .to(this.get('rHead'), 0.2, {y:-3, yoyo: true, ease: Bounce.easeIn, repeat: 4}, "roverStart+=0.1")
        //             .to(this.get('rBodyBar'), 0.2, {y:-3, yoyo: true, ease: Bounce.easeIn, repeat: 4}, "roverStart+=0.1")
        //             .to(this.get('rBody'), 0.1, {y:0, ease: Bounce.easeIn}, "roverStart+=1.3")
        //             .to(this.get('rHead'), 0.1, {y:0, ease: Bounce.easeIn}, "roverStart+=1.3")
        //             .to(this.get('rBodyBar'), 0.1, {y:0, ease: Bounce.easeIn}, "roverStart+=1.3")
        //
        //     this.get('tlRoverHead')
        //             .add("headMovements")
        //             .to(this.get('rHeadRight'), 1, {rotation: 12, transformOrigin:"100% 0%"})
        //             .to(this.get('rHeadRight'), 0.5, {rotation: 0, delay: 3, transformOrigin:"100% 0%"})
        //             .to(this.get('rEyeRight'), 0.5, {x: 15}, "+=3")
        //             .to(this.get('rHeadRight'), 0.5, {x: 20}, "-=0.5")
        //             .to(this.get('rHeadRight'), 0.5, {rotation: -5, transformOrigin:"20% 100%"}, "-=0.25")
        //             .to(this.get('rEyeRight'), 0.5, {x: 0},  "+=3")
        //             .to(this.get('rHeadRight'), 1, {rotation: 0, x: 0, transformOrigin:"20% 100%"},"-=0.5")
        //             .to(this.get('rEyeRight'), 0.75, {x: 15}, "+=7")
        //             .to(this.get('rHeadRight'), 0.75, {x: 25}, "-=0.65")
        //             .to(this.get('rHeadRight'), 0.75, {rotation: -1, transformOrigin:"20% 100%"}, "-=0.5")
        //             .to(this.get('rEyeRight'), 1, {x: 0, y:-1}, "+=4")
        //             .to(this.get('rHeadRight'), 1, {x: -4, y:-3}, "-=0.9")
        //             .to(this.get('rHeadRight'), 1, {rotation: 3, transformOrigin:"20% 100%"}, "-=0.7")
        //             .to(this.get('rEyeRight'), 0.5, {x: 0, y:0},  "+=3")
        //             .to(this.get('rHeadRight'), 1, {rotation: 0, x: 0, y:2, transformOrigin:"20% 100%"},"-=0.5")
        //
        //             .to(this.get('rEyeLeft'), 0.5, {x:-15}, "headMovements+=3")
        //             .to(this.get('rHeadLeft'), 1, {x:-30, y:3}, "headMovements+=3.15")
        //             .to(this.get('rHeadLeft'), 1, {rotation:-12, transformOrigin:"100% 0%"}, "headMovements+=3.25")
        //             .to(this.get('rEyeLeft'), 0.5, {x:-7, y:-2}, "headMovements+=8.9")
        //             .to(this.get('rHeadLeft'), 1, {x:-10, y:-5, rotation: 0}, "headMovements+=9")
        //             .to(this.get('rEyeLeft'), 0.5, {x:0, y:-2}, "headMovements+=13.9")
        //             .to(this.get('rHeadLeft'), 1, {x:-5, y:-5, rotation: -5, transformOrigin:"0% 50%"}, "headMovements+=14")
        //             .to(this.get('rEyeLeft'), 0.5, {x:0, y:0}, "headMovements+=18")
        //             .to(this.get('rHeadLeft'), 1, {x:0, y:2, rotation: 0, transformOrigin:"50% 50%"}, "headMovements+=18")
        //             .to(this.get('rEyeLeft'), 0.5, {x:-18, y:0}, "headMovements+=24")
        //             .to(this.get('rHeadLeft'), 1, {x:-20, y:0, rotation: 10, transformOrigin:"100% 100%"}, "headMovements+=24")
        //             .to(this.get('rEyeLeft'), 0.5, {x:0, y:0}, "headMovements+=29.9")
        //             .to(this.get('rHeadLeft'), 1, {x:0, y:2, rotation: 0}, "headMovements+=30")
        //
        //     this.get('tlStars').staggerFromTo(this.get('star'), 0.3,
        //                                     {scale: 0, autoAlpha: 0},
        //                                     {scale:1, autoAlpha: 1, transformOrigin:"50% 50%", ease:Back.easeInOut, delay: 1},
        //                                     0.05
        //                                     )
        //         .staggerFromTo(this.get('star'), 2,
        //                                     {opacity: 1, scale:1},
        //                                     {opacity: 0.25, scale:1.4, repeat: -1, transformOrigin:"50% 50%", yoyo: true, ease: Back.easeInOut},
        //                                     0.5
        //                                     );
        //
        //     this.get('tlShootingStars')
        //         .set(this.get('shootingStarRight'), {x:0, y:400}, "shootingStarStart")
        //         .set(this.get('shootingStarLeft'), {x:-100, y:400}, "shootingStarStart")
        //             .to(this.get('shootingStarRight'), 1.25, {x:600, y:-200, delay:2})
        //             .to(this.get('shootingStarLeft'), 1.25, {x:500, y:-200, delay:4})
        //         .set(this.get('shootingStarLeft'), {x:-400, y:400})
        //             .to(this.get('shootingStarLeft'), 1.25, {x:200, y:-200, delay:1})
        //         .set(this.get('shootingStarRight'), {x:-200, y:400})
        //             .to(this.get('shootingStarRight'), 1.25, {x:400, y:-200, delay:4})
        // }

    });
});
define('ohs/routes/teacher-class-view', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/teacher-authorize-mixin', 'ohs/mixins/signalr-teacher-route', 'ohs/mixins/tip-mixin'], function (exports, _authenticatedRouteMixin, _reset, _teacherAuthorizeMixin, _signalrTeacherRoute, _tipMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, _teacherAuthorizeMixin.default, _signalrTeacherRoute.default, _tipMixin.default, {

        signalR: Ember.inject.service('signal-r'),

        titleToken: function titleToken(model) {
            return model.administration.get('quiz.quizName');
        },

        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            // Implement your custom setup after
            this.controllerFor('application').set('showSecondaryNav', false);
            this.controllerFor('application').set('teacher', true);

            this.get('signalR').administrationId = "t" + model.administration.get('id');
            this.get('signalR').joinAdministration("t" + model.administration.get('id').toString());
        },

        model: function model(params) {
            var store = this.store;
            return Ember.RSVP.hash({
                message: "",
                messageClass: "",
                iconClass: "",
                hasNoStudentAnswers: "",
                transitionUrl: "",
                redirectUrl: "",
                questionIssue: '',
                questionIssueComments: '',
                currentColor: 'red',
                currentTool: 'draw',
                backgroundColor: 'transparent',

                administration: store.findRecord('administration', params.administration_id),
                studentTestAdministrations: store.query('student-test-administration', { administrationId: params.administration_id }),
                testQuestions: store.query('test-question', { administrationId: params.administration_id }),

                quiz: Ember.computed.alias('administration.quiz'),
                testQuestion: Ember.computed.alias('administration.testQuestion'),

                orderedAnswers: Ember.computed('testQuestion.question.answerOptions.@each.id', function () {
                    return Ember.get(this, 'testQuestion.question.answerOptions').sortBy('id');
                }),

                joinedStudents: Ember.computed('administration.studentTestAdministrations.@each.currentlyInQuiz', function () {
                    return this.administration.get('studentTestAdministrations').filterBy('currentlyInQuiz', true).sortBy('student.fullName');
                }),

                percentInitial: Ember.computed('testQuestion.questionOrder', 'administration.quiz.testQuestions.length', function () {
                    if (Ember.get(this, 'testQuestions')) {
                        var increment = 100 / Ember.get(this, 'testQuestions.length');
                        return Ember.get(this, 'testQuestion.questionOrder') * increment - increment;
                    }
                }),

                percentCurrent: Ember.computed('testQuestion.questionOrder', 'administration.quiz.testQuestions.length', function () {
                    if (Ember.get(this, 'testQuestions')) {
                        var increment = 100 / Ember.get(this, 'testQuestions.length');
                        return Ember.get(this, 'testQuestion.questionOrder') * increment;
                    }
                }),

                buttonText: Ember.computed('administration.currentQuestionIndex', function () {
                    if (this.administration.get('currentQuestionIndex') == this.administration.get('quiz').get('testQuestions').get('length') - 1) {
                        return "End Quiz";
                    } else {
                        return "Next Question";
                    }
                }),

                validIssue: Ember.computed('questionIssue', 'questionIssueComments', function () {
                    return this.questionIssue && this.questionIssue != "Other" || this.questionIssue == "Other" && this.questionIssueComments.trim();
                }),

                tellUsMore: Ember.computed('questionIssue', function () {
                    if (this.questionIssue == "Other") {
                        return "Tell us more.";
                    } else {
                        return "Tell us more (optional).";
                    }
                })
            });
        },


        afterModel: function afterModel(model) {
            if (model.administration.get('isComplete')) {
                this.transitionTo('teacher-results-1', model.administration.get('id'), '1');
            }

            var tip = this.store.peekRecord('tip', 3);
            if (tip) {
                model.showTip = true;
                model.tipText = tip.get('tipText');
            }

            model.loadFinished = true;
        },

        actions: {
            //signalr actions
            changeQuestion: function changeQuestion(questionIndex, answersLocked) {
                if (this.get('currentModel.administration.currentQuestionIndex') != questionIndex) {
                    var canvas = $('#myCanvas')[0];
                    if (canvas) {
                        this.set('currentModel.testQuestion.scratchpadContent', canvas.toDataURL());
                    }
                    this.set('currentModel.showScratchpad', false);
                    this.set('currentModel.loadFinished', false);
                    this.get('currentModel').administration.set('currentQuestionIndex', questionIndex);

                    this.refresh();
                } else {
                    this.set('currentModel.administration.answersLocked', answersLocked);
                }
            },
            signalRConnectionAlert: function signalRConnectionAlert(message, messageClass, iconClass) {
                Ember.set(this.get('currentModel'), 'message', message);
                Ember.set(this.get('currentModel'), 'messageClass', messageClass);
                Ember.set(this.get('currentModel'), 'iconClass', iconClass);
            },

            //end signalr actions

            loading: function loading(transition, originRoute) {
                if (originRoute != this) return true;
            },

            nextQuestion: function nextQuestion() {
                this.set('currentModel.loadFinished', false);
                var admin = this.currentModel.administration;
                this.set('currentModel.isProcessing', true);
                var self = this;

                if (admin.get('currentQuestionIndex') == admin.get('quiz').get('testQuestions').get('length') - 1) {
                    var sesObj = this.get('session');
                    var userId = sesObj.session.content.authenticated["userId"];
                    Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/administrations/hasAnyStudentAnswers?administrationId=' + admin.id + '&userId=' + encodeURIComponent(userId)).done(function (hasAnyStudentAnswers) {
                        if (hasAnyStudentAnswers) {
                            //end the quiz
                            admin.set('isOpen', false);
                            admin.set('isComplete', true);
                            admin.set('endTime', new Date());

                            admin.save().then(function (a) {
                                //Leave administration
                                self.get('signalR').leaveAdministration("t" + a.id);

                                if (admin.get('parentAdministrationId')) {
                                    self.transitionTo('teacher-results-1', admin.get('parentAdministrationId'), '1');
                                } else {
                                    self.transitionTo('teacher-results-1', a.id, '1');
                                }
                            }, function () {
                                admin.rollbackAttributes();
                                //alert("Could end the quiz. Please check your internet connection and try again.");
                                self.set("currentModel.showAlert", true);
                                self.set('currentModel.isProcessing', false);
                                self.set('currentModel.loadFinished', true);
                                self.set("currentModel.alertMessage", "Could not end the quiz. Please check your internet connection and try again.");
                            });
                        }
                        //No students joined the quiz
                        else {
                                self.set('currentModel.hasNoStudentAnswers', true);
                                self.set('currentModel.isProcessing', false);
                                self.set('currentModel.loadFinished', true);
                            }
                    }).fail(function (response) {
                        self.set('currentModel.isProcessing', false);
                        self.set('currentModel.loadFinished', true);
                    });
                } else {
                    var self = this;
                    this.set('currentModel.isProcessing', true);

                    admin.set('currentQuestionIndex', admin.get('currentQuestionIndex') + 1);
                    //admin.set('answersLocked',false);
                    admin.save().then(function () {
                        self.refresh();
                    }, function () {
                        admin.rollbackAttributes();
                        //alert("Could not navigate to next question. Please check your internet connection and try again.");
                        self.set("currentModel.showAlert", true);
                        self.set('currentModel.isProcessing', false);
                        self.set('currentModel.loadFinished', true);
                        self.set("currentModel.alertMessage", "Could not navigate to next question. Please check your internet connection and try again.");
                    });
                }
            },

            prevQuestion: function prevQuestion() {
                this.set('currentModel.loadFinished', false);
                this.set('currentModel.isProcessing', true);
                var admin = this.currentModel.administration;
                var self = this;
                admin.set('currentQuestionIndex', admin.get('currentQuestionIndex') - 1);
                //admin.set('answersLocked',false);
                admin.save().then(function () {
                    self.refresh();
                }, function () {
                    admin.rollbackAttributes();
                    //alert("Could not navigate to previous question. Please check your internet connection and try again.");
                    self.set("currentModel.showAlert", true);
                    self.set('currentModel.isProcessing', false);
                    self.set('currentModel.loadFinished', true);
                    self.set("currentModel.alertMessage", "Could not navigate to previous question. Please check your internet connection and try again.");
                });
            },

            deleteQuiz: function deleteQuiz(administrationId) {
                //hidden modal popup
                this.set('currentModel.hasNoStudentAnswers', false);
                //Delete administration
                var admin = this.currentModel.administration;
                var self = this;
                admin.destroyRecord().then(function (a) {
                    //Leave Administration
                    self.get('signalR').leaveAdministration(administrationId);
                    //go to teacher dashboard
                    self.transitionTo('teacher-quizzes');
                }, function () {
                    admin.rollbackAttributes();
                    //alert("Failed to end the quiz. Please check your internet connection and try again.")
                    self.set("currentModel.showAlert", true);
                    self.set("currentModel.alertMessage", "Failed to end the quiz. Please check your internet connection and try again.");
                });
            },

            //Hide alert box
            hideAlert: function hideAlert() {
                this.set("currentModel.showAlert", false);
                this.set("currentModel.alertMessage", "");
                this.set('currentModel.loadFinished', true);
            },

            reportIssue: function reportIssue() {

                this.set('currentModel.issueMessage', null);
                var self = this;
                var adapter = this.get('store').adapterFor('application');

                var url = adapter.get('host') + '/accounts/reportQuestion?questionId=' + this.get('currentModel.testQuestion').get('question').get('id') + '&issue=' + this.get('currentModel.questionIssue') + '&comment=' + this.get('currentModel.questionIssueComments');
                if (this.get('currentModel.questionIssueComments').trim() == '') {
                    url = adapter.get('host') + '/accounts/reportQuestion?questionId=' + this.get('currentModel.testQuestion').get('question').get('id') + '&issue=' + this.get('currentModel.questionIssue');
                }
                adapter.ajax(url, 'POST').then(function () {
                    self.set('currentModel.showConfirm', true);
                    self.set('currentModel.confirmationMessage', 'Your report has been received and is currently being reviewed. If we have any follow up questions, someone will be in touch!');
                }, function () {
                    self.set('currentModel.showAlert', true);
                    self.set("currentModel.alertMessage", "There was an error sending your report. Please check your internet connection and try again.");
                });
                self.set('currentModel.questionIssue', null);
                self.set('currentModel.questionIssueComments', '');
            },

            //Hide alert box
            hideConfirm: function hideConfirm() {
                this.set("currentModel.showConfirm", false);
            },

            setQuestionIssue: function setQuestionIssue(value) {
                this.set('currentModel.questionIssue', value);
            },

            setQuestionIssueComment: function setQuestionIssueComment(value) {
                this.set('currentModel.questionIssueComments', value);
            },

            validateIssue: function validateIssue() {
                if (!this.get('currentModel.questionIssue')) {
                    this.set('issueMessage', 'Please tell us what is wrong with this resource.');
                    return false;
                } else if (this.get('currentModel.questionIssue') == "Other" && !this.get('currentModel.questionIssueComments').trim()) {
                    this.set('currentModel.issueMessage', 'Please add some additional information about what is wrong with this resource.');
                    return false;
                } else {
                    return true;
                }
            },

            lockAnswers: function lockAnswers() {
                var admin = this.get('currentModel.administration');
                var self = this;
                this.set('currentModel.isProcessing', true);
                if (admin.get('answersLocked')) {
                    admin.set('answersLocked', false);
                    //admin.save();
                    admin.save().then(function () {
                        self.set('currentModel.isProcessing', false);
                    }, function (error) {
                        admin.rollbackAttributes();
                        self.set('currentModel.isProcessing', false);
                        self.set("currentModel.showAlert", true);
                        self.set("currentModel.alertMessage", "Failed to unlock the answers. Please check your internet connection and try again.");
                    });
                } else {
                    admin.set('answersLocked', true);
                    //admin.save();
                    admin.save().then(function () {
                        self.set('currentModel.isProcessing', false);
                    }, function (error) {
                        admin.rollbackAttributes();
                        self.set('currentModel.isProcessing', false);
                        self.set("currentModel.showAlert", true);
                        self.set("currentModel.alertMessage", "Failed to lock the answers. Please check your internet connection and try again.");
                    });
                }
            },

            //decide whether or not the transition should occur.
            willTransition: function willTransition(transition) {
                if (transition.targetName == "teacher-quiz-question" || transition.targetName == "teacher-class-view" || transition.targetName == "teacher-results-1" || transition.targetName == "teacher-quizzes" || transition.targetName == "teacher-self-guided-results") {
                    return true;
                }

                //redirect to error page when an error occur in this page like network failure
                if (transition.targetName == "error") {
                    return true;
                }

                //redirectUrl is used to hold the current url to check whether its called from redirect action method or not
                var redirectUrl = this.get('currentModel.redirectUrl');
                if (redirectUrl && redirectUrl.targetName == transition.targetName) {
                    return true;
                } else {
                    this.set('currentModel.transitionUrl', transition);
                    this.set('currentModel.confirmTransition', true);
                    transition.abort();
                }
            },
            redirect: function redirect() {
                this.set('currentModel.confirmTransition', false);
                this.set('currentModel.redirectUrl', this.get('currentModel.transitionUrl'));
                this.transitionTo(this.get('currentModel.transitionUrl.targetName'));
            },
            confirmChangeInTestType: function confirmChangeInTestType() {
                this.set('currentModel.showChangeInTestTypeDialog', true);
            },
            convertToSelfGuidedQuiz: function convertToSelfGuidedQuiz() {
                var admin = this.get('currentModel.administration');
                var self = this;
                this.set('currentModel.showChangeInTestTypeDialog', false);
                this.set('currentModel.isProcessing', true);

                //convert to self guided quiz
                admin.set('testTypeId', 1);
                admin.set('answersLocked', false);
                admin.save().then(function (result) {
                    self.set('currentModel.isProcessing', false);
                    self.transitionTo('teacher-self-guided-results', admin.id);
                }, function (error) {
                    admin.rollbackAttributes();
                    self.set('currentModel.isProcessing', false);
                    self.set("currentModel.showAlert", true);
                    self.set("currentModel.alertMessage", "Failed to convert to self guided quiz. Please check your internet connection and try again.");
                });
            },


            closeTip: function closeTip() {
                this.destroyTip(3);
                this.set('currentModel.showTip', false);
            },

            showScratchpad: function showScratchpad() {
                this.set('currentModel.showScratchpad', true);
            },

            closeScratchpad: function closeScratchpad(dataUrl) {
                this.set('currentModel.testQuestion.scratchpadContent', dataUrl);
                this.set('currentModel.showScratchpad', false);
            }

        }

    });
});
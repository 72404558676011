define('ohs/mixins/parent-authorize-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    beforeModel: function beforeModel() {
      var sesObj = this.get('session');
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.indexOf("Parent") > 0) {
            return true;
          } else {
            this.controllerFor('application').set('errorStatusCode', 403);
            this.controllerFor('application').set('errorDetailMessage', 'You don\'t have access to this request');
            this.transitionTo('error');
            return false;
          }
        } else {
          this.controllerFor('application').set('errorStatusCode', 403);
          this.controllerFor('application').set('errorDetailMessage', 'You don\'t have access to this request');
          this.transitionTo('error');
          return false;
        }
      }
    }

  });
});
define('ohs/routes/new-user', ['exports', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/reset'], function (exports, _authenticatedRouteMixin, _reset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authenticatedRouteMixin.default, {
    titleToken: 'New User',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      if (transition.handlerInfos[1].params.error_id > 0) {
        var errorUser = this.store.peekRecord('user-creation-error', transition.handlerInfos[1].params.error_id);
        if (!errorUser) {
          if (transition.handlerInfos[1].params.role == "Student") {
            this.transitionTo('manage-students');
          } else if (transition.handlerInfos[1].params.role == "Teacher") {
            this.transitionTo('manage-teachers');
          }
        }
      }
    },

    model: function model(params) {
      //Find the previous route and redirect to it after completing the task
      var previousRoutes = this.router.router.currentHandlerInfos;
      var previousRoute = previousRoutes && previousRoutes.pop();
      var lastVisitedRoute = previousRoute ? previousRoute.name : '';

      var sesObj = this.get('session');
      var currentUser = '';
      if (sesObj) {
        var roles = sesObj.session.content.authenticated["roles"];
        if (roles) {
          if (roles.includes("Teacher")) {
            currentUser = 'Teacher';
          }
        }
      }

      if (params) {
        if (params.role == "Student") {
          return Ember.RSVP.hash({
            user: this.store.createRecord('student', { userRole: params.role, stateId: 0 }),
            grades: this.store.query('grade', { 'gradeType': 'StudentProfle' }),
            errorUser: this.store.peekRecord('user-creation-error', params.error_id),
            tick: 1,
            account: this.store.peekAll('account'),
            stateOptions: this.store.findAll('state'),
            isNew: true,
            emailRequired: false,
            forcePasswordChange: false,
            lastVisitedRoute: lastVisitedRoute,
            currentUser: currentUser
          });
        } else {
          return Ember.RSVP.hash({
            user: this.store.createRecord('teacher', { userRole: params.role, stateId: 0 }),
            grades: this.store.query('grade', { 'gradeType': 'TeacherProfle' }),
            stateOptions: this.store.findAll('state'),
            standardTypeOptions: this.store.findAll('standard-type'),
            errorUser: this.store.peekRecord('user-creation-error', params.error_id),
            tick: 1,
            isTeacher: true,
            emailRequired: true,
            account: this.store.peekAll('account'),
            honorificOptions: [{
              text: "Mrs",
              id: "Mrs"
            }, {
              text: "Mr",
              id: "Mr"
            }, {
              text: "Ms",
              id: "Ms"
            }, {
              text: "Miss",
              id: "Miss"
            }, {
              text: "Dr",
              id: "Dr"
            }],
            isNew: true,
            forcePasswordChange: true,
            lastVisitedRoute: lastVisitedRoute
          });
        }
      }
    },


    afterModel: function afterModel(model) {
      model.user.set('stateId', model.account.get('firstObject').get('stateId'));
      if (model.account.get('firstObject').get('stateId') == 39 && model.isTeacher) {
        model.standardTypeSelected = true;
      }
      model.user.set('standardTypeId', model.account.get('firstObject').get('standardTypeId'));
      if (model.errorUser) {
        model.user.set('honorific', model.errorUser.get('honorific'));
        model.user.set('firstNameEdit', model.errorUser.get('firstName'));
        model.user.set('lastNameEdit', model.errorUser.get('lastName'));
        model.user.set('userNameEdit', model.errorUser.get('userName'));
        model.user.set('emailEdit', model.errorUser.get('email'));
        model.user.set('studentIdEdit', model.errorUser.get('studentId'));
        model.user.set('gradeLevelId', model.errorUser.get('gradeLevelId'));
        model.user.set('password', model.errorUser.get('PasswordEncrypt'));
        model.user.set('confirmPassword', model.errorUser.get('PasswordEncrypt'));
        var self = this;
        setTimeout(function () {
          self.set('currentModel.tick', self.get('currentModel.tick') + 1);
        }, 250);
      }
    },

    actions: {

      // saveUser(){
      //   if(this.get('currentModel.user.userRole')=='Student'){
      //       this.transitionTo('manage-students');
      //   }
      //   else if(this.get('currentModel.user.userRole')=='Teacher'){
      //     this.transitionTo('manage-teachers');
      //   }
      // },
      //
      // cancelUser(){
      //   if(this.get('currentModel.user.userRole')=='Student'){
      //       this.transitionTo('manage-students');
      //   }
      //   else if(this.get('currentModel.user.userRole')=='Teacher'){
      //     this.transitionTo('manage-teachers');
      //   }
      // }

      transitionAction: function transitionAction(route) {
        this.transitionTo(route);
      }
    }
  });
});
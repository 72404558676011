define('ohs/routes/link-teacher-without-login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Link Teacher',
    model: function model() {
      return Ember.RSVP.hash({
        userName: '',
        teacherCode: '',
        validationErrors: '',
        successMessages: '',
        buttonsDisabled: false
      });
    },


    actions: {

      next: function next() {
        document.activeElement.blur();
        var self = this;
        self.set("currentModel.validationErrors", '');
        self.set("currentModel.successMessages", '');
        self.set('currentModel.buttonsDisabled', true);
        if (self.currentModel.userName.trim() && self.currentModel.teacherCode.trim()) {
          // Make sure the username and teacher code is supplied

          return Ember.$.getJSON(this.store.adapterFor('application').get('host') + '/students/BackDoorTeacherLink?userName=' + self.currentModel.userName.trim() + '&teacherCode=' + self.currentModel.teacherCode.trim()).done(function (response) {
            if (response) {
              self.set("currentModel.successMessages", response);
              self.set('currentModel.buttonsDisabled', false);
            } else {
              self.set("currentModel.validationErrors", 'Failed to link the teacher. Please check your internet connection and try again.');
              self.set('currentModel.buttonsDisabled', false);
            }
          }).fail(function (response) {
            self.set("currentModel.validationErrors", response.responseText);
            self.set('currentModel.buttonsDisabled', false);
          });
        } else {
          // username and teacher code can't be blank.
          self.set("currentModel.validationErrors", 'The username and teacher code can\'t be blank');
          self.set('currentModel.buttonsDisabled', false);
        }
      },

      back: function back() {
        this.transitionTo('sign-up');
      }

    }

  });
});
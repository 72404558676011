define('ohs/models/resource-student-rating', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    resource: _emberData.default.belongsTo('resource'),
    student: _emberData.default.belongsTo('student'),
    ratingDate: _emberData.default.attr('date'),
    wasHelpful: _emberData.default.attr('boolean'),
    wasFun: _emberData.default.attr('boolean'),
    wasBoring: _emberData.default.attr('boolean'),
    wasTooLong: _emberData.default.attr('boolean'),
    wasQuick: _emberData.default.attr('boolean'),
    wasConfusing: _emberData.default.attr('boolean'),
    wasEasy: _emberData.default.attr('boolean'),
    wasHard: _emberData.default.attr('boolean'),
    wasBroken: _emberData.default.attr('boolean')
  });
});
define('ohs/routes/welcome', ['exports', 'ohs/mixins/validate-form-mixin'], function (exports, _validateFormMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_validateFormMixin.default, {

        model: function model() {
            return Ember.RSVP.hash({
                surveyAnswer: this.store.createRecord('survey-answer', {
                    surveyRole: null,
                    surveyReferral: null,
                    state: null
                }),
                surveyRoles: this.store.findAll('survey-role'),
                states: this.store.findAll('state'),
                surveyReferrals: this.store.findAll('survey-referral'),
                //this is a terrible hacky workaround for the email validation
                userRole: "Student",
                stepNumber: 1,
                tick: 1,
                emailHeaderText: Ember.computed('stepNumber', function () {
                    switch (this.stepNumber) {
                        case 1:
                            return "Find out more";
                        default:
                            return "Thank you! We will be in touch!";
                    }
                }),

                emailInfoText: Ember.computed('stepNumber', function () {
                    switch (this.stepNumber) {
                        case 1:
                            return "Looking for more info? Put your email in below and we will reach out.";
                        case 2:
                            return "Tell us a bit more about yourself so we can find the best information.";
                        default:
                            return "";
                    }
                })
            });
        },

        actions: {
            nextStep: function nextStep() {
                var _this = this;

                this.validate(this.get('currentModel')).then(function () {
                    _this.get('currentModel.surveyAnswer').save().then(function () {
                        _this.incrementProperty('currentModel.stepNumber');
                    });
                });
            },

            setRole: function setRole(roleId) {
                this.set("currentModel.surveyAnswer.surveyRole", this.store.peekRecord('survey-role', roleId));
            },

            setState: function setState(stateId) {
                this.set('currentModel.surveyAnswer.state', this.store.peekRecord('state', stateId));
            },

            setReferralType: function setReferralType(referralId) {
                this.set('currentModel.surveyAnswer.surveyReferral', this.store.peekRecord('survey-referral', referralId));
            }
        }
    });
});
define('ohs/routes/teacher/resource-bookmarks', ['exports', 'ohs/mixins/authorized-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authorizedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authorizedRouteMixin.default, _breadcrumbMixin.default, {
    authorizedRoles: ['Tutor', 'Teacher'],
    titleToken: 'Bookmarks',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      return Ember.RSVP.hash({
        curUser: this.modelFor('teacher').user,
        bookmarks: this.store.findAll('resourceBookmark'),
        perPage: 10,
        numShown: 10,
        subjectOptions: Ember.computed('bookmarks.@each.resource', function () {
          var subjects = [];
          var subjectIds = [];
          this.bookmarks.forEach(function (r) {
            if (subjectIds.indexOf(r.get('resource.subject.id')) < 0) {
              subjects.pushObject(r.get('resource.subject'));
              subjectIds.pushObject(r.get('resource.subject.id'));
            }
          });
          return subjects.sortBy('id');
        }),
        subjectFilter: '',
        hasMoreItems: Ember.computed('bookmarks.@each.id', 'numShown', function () {
          var filtered = this.bookmarks.filter(function (r, index, array) {
            var inSubject = true;
            if (this.subjectFilter) {
              inSubject = r.get('resource').get('subject.id') == this.subjectFilter;
            }
            return inSubject;
          }, this);

          if (this.bookmarks.get('length') > this.numShown) {
            return true;
          } else {
            return false;
          }
        }),

        curBookmarks: Ember.computed('bookmarks.@each.id', 'numShown', 'curSort', 'subjectFilter', function () {
          var results = this.bookmarks.filter(function (r, index, array) {

            var inSubject = true;
            if (this.subjectFilter) {
              inSubject = r.get('resource').get('subject.id') == this.subjectFilter;
            }
            return inSubject;
          }, this);

          if (this.curSort == 1) {
            results = results.sortBy('resource.averageRating').reverse().slice(0, this.numShown);
          } else if (this.curSort == 2) {
            results = results.sortBy('resource.resourceName').slice(0, this.numShown);
          } else if (this.curSort == 3) {
            results = results.sortBy('bookmarkedDate').reverse().slice(0, this.numShown);
          } else {
            results = results.slice(0, this.numShown);
          }

          return results.slice(0, this.numShown);
        }),

        curSort: 1,

        sortingOpts: [{
          id: 1,
          text: "Rating"
        }, {
          id: 2,
          text: "Resource name"
        }, {
          id: 3,
          text: "Bookmarked date"
        }]
      });
    },

    afterModel: function afterModel(model) {
      this._super(model, 'Resource Bookmarks', false, true);
    },

    actions: {
      createBookmark: function createBookmark(resourceId) {
        var curModel = this.currentModel;
        var self = this;
        var curResource = curModel.resources.findBy('id', resourceId);
        var bookmarkForCurResource = curModel.bookmarks.find(function (item, index, enumerable) {
          return item.get('resource').get('id') == resourceId;
        });
        if (curResource && !bookmarkForCurResource) {
          var b = this.store.createRecord('resource-bookmark', {
            resource: curResource,
            user: curModel.curUser,
            bookmarkedDate: new Date()
          });
          b.save().then(function () {
            //self.refresh();
          }, function () {
            self.set('currentModel.warningMessage', "This resource could not be bookmarked. Please check your Internet connection and try again.");
            self.set("currentModel.showAlert", true);
          });
        }
      },

      deleteBookmark: function deleteBookmark(resourceId) {
        var curModel = this.currentModel;
        var self = this;
        var bookmarks = this.get('currentModel.bookmarks');
        var bookmarkForCurResource = curModel.bookmarks.find(function (item, index, enumerable) {
          return item.get('resource').get('id') == resourceId;
        });
        if (bookmarkForCurResource) {
          bookmarkForCurResource.destroyRecord().then(function () {
            self.refresh();
            //bookmarks.popObject(bookmarkForCurResource);
          }, function () {
            self.set('currentModel.warningMessage', "This bookmark could not be deleted. Please check your Internet connection and try again.");
            self.set("currentModel.showAlert", true);
          });
        }
      },

      sortBookmarks: function sortBookmarks(selectedValue) {
        this.set('currentModel.curSort', selectedValue);
      },

      searchResources: function searchResources() {
        this.transitionTo('teacher.resource-search-start');
      },

      drill: function drill(id) {
        this.transitionTo('teacher.resource-detail', id);
      },

      filtersubject: function filtersubject(selectedValue) {
        this.set('currentModel.subjectFilter', selectedValue);
      }
    }

  });
});
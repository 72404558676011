define('ohs/components/labeled-input-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      observer = Ember.observer,
      defineProperty = Ember.defineProperty,
      run = Ember.run;
  exports.default = Ember.Component.extend({
    classNames: ['x-input'],
    tagName: 'div',
    classNameBindings: ['showErrorClass:has-error', 'isValid:has-success'],
    model: null,
    value: null,
    rawInputValue: null,
    type: 'text',
    valuePath: '',
    placeholder: '',
    attributeValidation: null,
    isTyping: false,
    warnOnBlanks: false,

    store: Ember.inject.service(),

    didValidate: computed.oneWay('targetObject.didValidate'),

    // showErrorClass: computed('isTyping', 'showMessage', 'hasContent', 'attributeValidation', function() {
    //   return this.get('attributeValidation') && !this.get('isTyping') && this.get('showMessage') && this.get('hasContent');
    // }),

    hasContent: computed.notEmpty('rawInputValue'),

    isValid: computed.and('hasContent', 'attributeValidation.isValid'),

    isInvalid: computed.oneWay('attributeValidation.isInvalid'),

    inputValueChange: observer('rawInputValue', function () {
      this.set('isTyping', true);
      run.debounce(this, this.setValue, 500, false);
    }),

    // showMessage: computed('attributeValidation.isDirty', 'isInvalid', 'didValidate', function() {
    //   return (this.get('attributeValidation.isDirty') || this.get('didValidate')) && this.get('isInvalid');
    // }),

    setValue: function setValue() {
      this.set('value', this.get('rawInputValue'));
      this.set('isTyping', false);
    },
    init: function init() {
      this._super.apply(this, arguments);
      var valuePath = this.get('valuePath');
      defineProperty(this, 'attributeValidation', computed.oneWay('model.validations.attrs.' + valuePath));
      this.set('rawInputValue', this.get('model.' + valuePath));
      defineProperty(this, 'value', computed.alias('model.' + valuePath));
    },


    revalidate: Ember.observer('tickNumber', function () {
      this.set('warnOnBlanks', true);
      this.validate();
    }),

    confirmSync: Ember.observer('syncNumber', function () {
      this.validate();
    }),

    validate: function validate() {
      if (this.get('validationType')) {
        var self = this;
        //set invalid properties on the model
        if (!this.get('model.invalidAttributes')) {
          this.set('model.invalidAttributes', new Array());
        }

        //Generic required box
        if (this.get('validationType') == 'notBlank') {
          if (!this.get('value') || this.get('value').trim() == "") {
            this.set('errorMessage', this.get('label_text') + " cannot be blank");
            if (!this.get('model.invalidAttributes').includes(this.get('label_text'))) {
              this.get('model.invalidAttributes').push(this.get('label_text'));
            }
          } else {
            this.set('errorMessage', null);
            if (this.get('model.invalidAttributes').includes(this.get('label_text'))) {
              var index = this.get('model.invalidAttributes').indexOf(this.get('label_text'));
              this.get('model.invalidAttributes').splice(index, 1);
            }
          }
        }
        //number
        else if (this.get('validationType') == 'number') {
            if (this.get('value') && !this.get('value').match(/^\d+$/)) {
              this.set('errorMessage', "Please enter a number");
              if (!this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                this.get('model.invalidAttributes').push(this.get('label_text'));
              }
            } else {
              this.set('errorMessage', null);
              if (this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                var index = this.get('model.invalidAttributes').indexOf(this.get('label_text'));
                this.get('model.invalidAttributes').splice(index, 1);
              }
            }
          }
          //Phone number box
          else if (this.get('validationType') == 'phoneNumber') {
              if (this.get('value') && (this.get('value.length') < 10 || !this.get('value').match(/^[0-9]*$/))) {
                this.set('errorMessage', "Please enter a 10 digit phone number or leave the field blank");
                if (!this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                  this.get('model.invalidAttributes').push(this.get('label_text'));
                }
              } else {
                this.set('errorMessage', null);
                if (this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                  var index = this.get('model.invalidAttributes').indexOf(this.get('label_text'));
                  this.get('model.invalidAttributes').splice(index, 1);
                }
              }
            }

            //Username box
            else if (this.get('validationType') == 'username') {
                if (!this.get('value') || this.get('value').trim() == "") {
                  this.set('errorMessage', "The username cannot be blank");
                  if (!this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                    this.get('model.invalidAttributes').push(this.get('label_text'));
                  }
                } else if (this.get('value.length') > 25) {
                  this.set('errorMessage', "The username cannot exceed 25 characters");
                  if (!this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                    this.get('model.invalidAttributes').push(this.get('label_text'));
                  }
                } else if (this.get('value').includes(' ')) {
                  this.set('errorMessage', "The username cannot contain spaces");
                  if (!this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                    this.get('model.invalidAttributes').push(this.get('label_text'));
                  }
                } else if (this.get('value').match(/.*@.*\..*/)) {
                  this.set('errorMessage', "The username cannot be an email address");
                  if (!this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                    this.get('model.invalidAttributes').push(this.get('label_text'));
                  }
                } else {
                  var self = this;
                  Ember.$.getJSON(this.get('store').adapterFor('application').get('host') + '/UniqueUserName?userName=' + encodeURIComponent(this.get('value')) + '&&userId=' + this.get('model.id')).done(function (response) {
                    if (response.id == 0) {
                      self.set('errorMessage', null);
                      if (self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                        var index = self.get('model.invalidAttributes').indexOf(self.get('label_text'));
                        self.get('model.invalidAttributes').splice(index, 1);
                      }
                    } else {
                      self.set('errorMessage', "This username is already in use");

                      if (!self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                        self.get('model.invalidAttributes').push(self.get('label_text'));
                      }
                    }
                  });
                }
              }
              //Email box
              else if (this.get('validationType') == 'email') {
                  if (this.get('value') && this.get('value').match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    if (this.get('model.userRole') == "Student") {
                      this.set('errorMessage', null);
                      if (this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                        var index = this.get('model.invalidAttributes').indexOf(this.get('label_text'));
                        this.get('model.invalidAttributes').splice(index, 1);
                      }
                    } else if (this.get('model.userRole') == "Tutor") {
                      var self = this;
                      Ember.$.getJSON(this.get('store').adapterFor('application').get('host') + '/ValidateTutorEmail?email=' + encodeURIComponent(this.get('value')) + '&&userId=' + this.get('model.id')).done(function (response) {
                        self.set('errorMessage', null);
                        if (self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                          var index = self.get('model.invalidAttributes').indexOf(self.get('label_text'));
                          self.get('model.invalidAttributes').splice(index, 1);
                        }

                        if (response) {
                          if (!self.get('model.stateId')) self.set('model.stateId', response.stateId);
                          if (!self.get('model.standardTypeId')) self.set('model.standardTypeId', response.standardTypeId);
                          self.set('model.isFreeTrial', false);
                        } else {
                          self.set('model.isFreeTrial', true);
                        }
                      }).fail(function (error) {
                        self.set('errorMessage', error.responseText);
                        if (!self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                          self.get('model.invalidAttributes').push(self.get('label_text'));
                        }
                      });
                    } else {
                      var self = this;
                      Ember.$.getJSON(this.get('store').adapterFor('application').get('host') + '/ValidateTeacherEmail?email=' + encodeURIComponent(this.get('value')) + '&&userId=' + this.get('model.id')).done(function (response) {
                        self.set('errorMessage', null);
                        if (self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                          var index = self.get('model.invalidAttributes').indexOf(self.get('label_text'));
                          self.get('model.invalidAttributes').splice(index, 1);
                        }

                        if (response) {
                          if (!self.get('model.stateId')) self.set('model.stateId', response.stateId);
                          if (!self.get('model.standardTypeId')) self.set('model.standardTypeId', response.standardTypeId);
                          self.set('model.isFreeTrial', false);
                        } else {
                          self.set('model.isFreeTrial', true);
                        }
                      }).fail(function (error) {
                        self.set('errorMessage', error.responseText);
                        if (!self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                          self.get('model.invalidAttributes').push(self.get('label_text'));
                        }
                      });
                    }
                  } else {
                    if (this.get('value')) {
                      this.set('errorMessage', 'Email address is not valid');
                      if (!this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                        this.get('model.invalidAttributes').push(this.get('label_text'));
                      }
                    } else if (this.get('isEmailRequired')) {
                      this.set('errorMessage', 'You must enter an email address');
                      if (!this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                        this.get('model.invalidAttributes').push(this.get('label_text'));
                      }
                    } else {
                      this.set('errorMessage', null);
                      if (this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                        var index = this.get('model.invalidAttributes').indexOf(this.get('label_text'));
                        this.get('model.invalidAttributes').splice(index, 1);
                      }
                    }
                  }
                }
                //basicEmail just required, valid email, valid district
                else if (this.get('validationType') == 'basicEmail') {
                    var store = this.get('store').adapterFor('application');
                    var host = store.get('host');
                    var self = this;

                    if (this.get('value') && this.get('value').match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                      Ember.$.getJSON(host + '/ValidateDistrictEmail?email=' + encodeURIComponent(this.get('value'))).then(function (response) {
                        self.set('errorMessage', null);
                        if (self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                          var index = self.get('model.invalidAttributes').indexOf(self.get('label_text'));
                          self.get('model.invalidAttributes').splice(index, 1);
                        }
                      }).fail(function (response) {
                        self.set('errorMessage', response.responseText);
                        if (!self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                          self.get('model.invalidAttributes').push(self.get('label_text'));
                        }
                      });
                    } else {
                      if (!this.get('value')) {
                        this.set('errorMessage', 'Email cannot be blank.');
                      } else {
                        this.set('errorMessage', 'Email address is not valid');
                      }

                      if (!this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                        this.get('model.invalidAttributes').push(this.get('label_text'));
                      }
                    }
                  }

                  //webUrl
                  else if (this.get('validationType') == 'nonCompleteWebUrl') {
                      if (this.get('value') && this.get('value').match(/((?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/i) || this.get('value').trim() == "") {
                        this.set('errorMessage', null);
                        if (this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                          var index = this.get('model.invalidAttributes').indexOf(this.get('label_text'));
                          this.get('model.invalidAttributes').splice(index, 1);
                        }
                      } else {
                        if (this.get('value').trim() != "") {
                          this.set('errorMessage', 'Invalid Url');
                          if (!this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                            this.get('model.invalidAttributes').push(this.get('label_text'));
                          }
                        }
                      }
                    }
                    //webUrl
                    else if (this.get('validationType') == 'webUrl') {
                        if (this.get('value') && this.get('value').match(/((?:https?\:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/i) || this.get('value').trim() == "") {
                          this.set('errorMessage', null);
                          if (this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                            var index = this.get('model.invalidAttributes').indexOf(this.get('label_text'));
                            this.get('model.invalidAttributes').splice(index, 1);
                          }
                        } else {
                          if (this.get('value').trim() != "") {
                            this.set('errorMessage', 'Invalid Url');
                            if (!this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                              this.get('model.invalidAttributes').push(this.get('label_text'));
                            }
                          }
                        }
                      }
                      //Password box
                      else if (this.get('validationType') == 'password') {
                          if (this.get('value') && this.get('value').match(/^(?=.*[A-Za-z])(?=.*\d).{6,}$/)) {
                            this.set('errorMessage', null);
                            if (this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                              var index = this.get('model.invalidAttributes').indexOf(this.get('label_text'));
                              this.get('model.invalidAttributes').splice(index, 1);
                            }
                          } else {
                            this.set('errorMessage', 'Your password must be a minimum of 6 characters and contain at least one letter and one number');
                            if (!this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                              this.get('model.invalidAttributes').push(this.get('label_text'));
                            }
                          }
                        }

                        //Password confirmation box
                        else if (this.get('validationType') == 'passwordConfirm' && (this.get('warnOnBlanks') || this.get('value'))) {
                            if (this.get('value') == this.get('matchValue')) {
                              this.set('errorMessage', null);
                              if (this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                                var index = this.get('model.invalidAttributes').indexOf(this.get('label_text'));
                                this.get('model.invalidAttributes').splice(index, 1);
                              }
                            } else {
                              this.set('errorMessage', 'Your passwords do not match');
                              if (!this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                                this.get('model.invalidAttributes').push(this.get('label_text'));
                              }
                            }
                          }

                          //Email confirmation box
                          else if (this.get('validationType') == 'confirmEmail' && (this.get('warnOnBlanks') || this.get('value'))) {
                              if (this.get('value') == this.get('matchValue')) {
                                this.set('errorMessage', null);
                                if (this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                                  var index = this.get('model.invalidAttributes').indexOf(this.get('label_text'));
                                  this.get('model.invalidAttributes').splice(index, 1);
                                }
                              } else {
                                this.set('errorMessage', 'The email addresses do not match');
                                if (!this.get('model.invalidAttributes').includes(this.get('label_text'))) {
                                  this.get('model.invalidAttributes').push(this.get('label_text'));
                                }
                              }
                            }

                            //Linking code
                            else if (this.get('validationType') == 'linkingCode') {
                                if (self.get('value')) {
                                  if (this.get('value').length == 9) {
                                    Ember.$.getJSON(self.get('store').adapterFor('application').get('host') + '/teachers?code=' + self.get('value').trim()).done(function (response) {
                                      if (response[0]) {
                                        //This is a valid linking code
                                        self.set('errorMessage', null);
                                        if (self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                                          self.get('model.invalidAttributes').splice(self.get('model.invalidAttributes').indexOf(self.get('label_text')), 1);
                                        }
                                      } else {
                                        //linking code not found
                                        self.set('errorMessage', 'Linking code does not exist');
                                        if (!self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                                          self.get('model.invalidAttributes').push(self.get('label_text'));
                                        }
                                      }
                                    }).fail(function (response) {
                                      self.set('errorMessage', response.responseText);
                                      if (!self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                                        self.get('model.invalidAttributes').push(self.get('label_text'));
                                      }
                                    });
                                  } else if (this.get('value').length < 9) {
                                    self.set('errorMessage', 'linking code is not long enough.');
                                    if (!self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                                      self.get('model.invalidAttributes').push(self.get('label_text'));
                                    }
                                  } else if (this.get('value').length > 9) {
                                    self.set('errorMessage', 'Linking code is to long.');
                                    if (!self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                                      self.get('model.invalidAttributes').push(self.get('label_text'));
                                    }
                                  }
                                } else {
                                  self.set('errorMessage', 'Linking code cannot be blank.');
                                  if (!self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                                    self.get('model.invalidAttributes').push(self.get('label_text'));
                                  }
                                }
                              }
                              //validates if an email is linked to a teacher account
                              else if (this.get('validationType') == 'teacherEmail') {
                                  if (self.get('value')) {
                                    if (self.get('value').match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                                      var host = self.get('store').adapterFor('application').get('host');

                                      Ember.$.getJSON(host + '/teachers?email=' + self.get('value')).done(function (response) {
                                        if (response) {
                                          self.set('errorMessage', null);
                                          if (self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                                            self.get('model.invalidAttributes').splice(self.get('model.invalidAttributes').indexOf(self.get('label_text')), 1);
                                          }
                                        } else {
                                          self.set('errorMessage', 'Email is not associated with an account.');
                                          if (!self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                                            self.get('model.invalidAttributes').push(self.get('label_text'));
                                          }
                                        }
                                      }).fail(function (error) {
                                        self.set('errorMessage', error.responseText);
                                        if (!self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                                          self.get('model.invalidAttributes').push(self.get('label_text'));
                                        }
                                      });
                                    } else {
                                      //this is for email that dont match the pattern
                                      self.set('errorMessage', 'Email must be valid.');
                                      if (!self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                                        self.get('model.invalidAttributes').push(self.get('label_text'));
                                      }
                                    }
                                  } else {
                                    self.set('errorMessage', 'Email cannot be blank.');
                                    if (!self.get('model.invalidAttributes').includes(self.get('label_text'))) {
                                      self.get('model.invalidAttributes').push(self.get('label_text'));
                                    }
                                  }
                                }
      }
    },


    actions: {
      doValidation: function doValidation() {
        if (this.get('validationType')) {
          if (this.get('syncNumber')) {
            this.set('syncNumber', this.get('syncNumber') + 1);
          } else {
            this.validate();
          }
        }
      }
    }
  });
});
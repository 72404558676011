define('ohs/controllers/teacher/resource-search-start', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        //// properties
        selectedReportingCategory: "",
        selectedAssessmentAnchor: "",
        selectedEligibleContent: [],
        eligibleContentQueryString: "",

        selectedGrade: null,
        selectedSubject: 1,
        keywordSearch: '',
        selectedTarget: 1,

        //// computeds
        fillteredSubjects: Ember.computed.alias('model.account.subjects'),
        peopleViews: Ember.computed.alias('model.peopleViews'),
        standards: Ember.computed.alias('model.standards'),

        filGrades: Ember.computed('selectedSubject', function () {
            var curSubject = this.store.peekRecord('subject', this.selectedSubject);
            var grades = curSubject.get('gradeLevels');

            grades.forEach(function (item) {
                item.set('text', item.get('description'));
            });

            return grades.sortBy('gradeLevelOrder');
        }),

        actions: {
            setGrade: function setGrade(value) {
                Ember.set(this, 'selectedGrade', parseInt(value));
            },
            setSubject: function setSubject(value) {
                Ember.set(this, 'selectedSubject', parseInt(value));

                if (value === "2") {
                    Ember.set(this, 'selectedTarget', 2);
                } else {
                    Ember.set(this, 'selectedTarget', 1);
                }
            },
            setTarget: function setTarget(value) {
                Ember.set(this, 'selectedTarget', parseInt(value));
            },
            search: function search() {
                document.activeElement.blur();
                this.transitionToRoute('teacher.search-results');
            }
        }
    });
});
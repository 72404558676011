define('ohs/routes/account-admin/teacher', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model(_ref) {
            var user_id = _ref.user_id;

            var store = Ember.get(this, 'store');
            return store.findRecord('teacher', user_id);
        }
    });
});
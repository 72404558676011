define('ohs/routes/pattern-library/trophies', ['exports', 'ohs/mixins/reset', 'ohs/mixins/authenticated-route-mixin', 'ohs/mixins/no-navigation'], function (exports, _reset, _authenticatedRouteMixin, _noNavigation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _noNavigation.default, _authenticatedRouteMixin.default, {
    model: function model() {
      return [{
        "category": "Earned Trophies",
        "description": "Trophies that have been earned appear in full color.",
        "trophies": [{
          "id": 1,
          "title": "comet.svg",
          "img": "/earned/comet.svg",
          "inactive": false,
          "mystery": false,
          "text": "comet.svg"
        }, {
          "id": 2,
          "title": "data.svg",
          "img": "/earned/data.svg",
          "inactive": false,
          "mystery": false,
          "text": "data.svg"
        }, {
          "id": 3,
          "title": "frequent-flyer.svg",
          "img": "/earned/frequent-flyer.svg",
          "inactive": false,
          "mystery": false,
          "text": "frequent-flyer.svg"
        }, {
          "id": 4,
          "title": "geometry.svg",
          "img": "/earned/geometry.svg",
          "inactive": false,
          "mystery": false,
          "text": "geometry.svg"
        }, {
          "id": 5,
          "title": "guided-tour.svg",
          "img": "/earned/guided-tour.svg",
          "inactive": false,
          "mystery": false,
          "text": "guided-tour.svg"
        }, {
          "id": 6,
          "title": "hot-streak.svg",
          "img": "/earned/hot-streak.svg",
          "inactive": false,
          "mystery": false,
          "text": "hot-streak.svg"
        }, {
          "id": 7,
          "title": "jupiter.svg",
          "img": "/earned/jupiter.svg",
          "inactive": false,
          "mystery": false,
          "text": "jupiter.svg"
        }, {
          "id": 8,
          "title": "mars.svg",
          "img": "/earned/mars.svg",
          "inactive": false,
          "mystery": false,
          "text": "mars.svg"
        }, {
          "id": 9,
          "title": "perfect-week.svg",
          "img": "/earned/perfect-week.svg",
          "inactive": false,
          "mystery": false,
          "text": "perfect-week.svg"
        }, {
          "id": 10,
          "title": "pluto.svg",
          "img": "/earned/pluto.svg",
          "inactive": false,
          "mystery": false,
          "text": "pluto.svg"
        }, {
          "id": 11,
          "title": "rover.svg",
          "img": "/earned/rover.svg",
          "inactive": false,
          "mystery": false,
          "text": "rover.svg"
        }, {
          "id": 12,
          "title": "satellite.svg",
          "img": "/earned/satellite.svg",
          "inactive": false,
          "mystery": false,
          "text": "satellite.svg"
        }, {
          "id": 13,
          "title": "saturn.svg",
          "img": "/earned/saturn.svg",
          "inactive": false,
          "mystery": false,
          "text": "saturn.svg"
        }, {
          "id": 14,
          "title": "second-look.svg",
          "img": "/earned/second-look.svg",
          "inactive": false,
          "mystery": false,
          "text": "second-look.svg"
        }, {
          "id": 15,
          "title": "shooting-star.svg",
          "img": "/earned/shooting-star.svg",
          "inactive": false,
          "mystery": false,
          "text": "shooting-star.svg"
        }, {
          "id": 16,
          "title": "solo-flight.svg",
          "img": "/earned/solo-flight.svg",
          "inactive": false,
          "mystery": false,
          "text": "solo-flight.svg"
        }, {
          "id": 17,
          "title": "space-walk.svg",
          "img": "/earned/space-walk.svg",
          "inactive": false,
          "mystery": false,
          "text": "space-walk.svg"
        }, {
          "id": 18,
          "title": "stargazer.svg",
          "img": "/earned/stargazer.svg",
          "inactive": false,
          "mystery": false,
          "text": "stargazer.svg"
        }, {
          "id": 19,
          "title": "voyager.svg",
          "img": "/earned/voyager.svg",
          "inactive": false,
          "mystery": false,
          "text": "voyager.svg"
        }, {
          "id": 20,
          "title": "warp-drive.svg",
          "img": "/earned/warp-drive.svg",
          "inactive": false,
          "mystery": false,
          "text": "warp-drive.svg"
        }]
      }, {
        "category": "Unearned Trophies",
        "description": "Trophies that have not yet been earned appear in grayscale.",
        "trophies": [{
          "id": 1,
          "title": "comet-inactive.svg",
          "img": "/unearned/comet-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "comet-inactive.svg"
        }, {
          "id": 2,
          "title": "data-inactive.svg",
          "img": "/unearned/data-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "data-inactive.svg"
        }, {
          "id": 3,
          "title": "frequent-flyer-inactive.svg",
          "img": "/unearned/frequent-flyer-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "frequent-flyer-inactive.svg"
        }, {
          "id": 4,
          "title": "geometry-inactive.svg",
          "img": "/unearned/geometry-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "geometry-inactive.svg"
        }, {
          "id": 5,
          "title": "guided-tour-inactive.svg",
          "img": "/unearned/guided-tour-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "guided-tour-inactive.svg"
        }, {
          "id": 6,
          "title": "hot-streak-inactive.svg",
          "img": "/unearned/hot-streak-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "hot-streak-inactive.svg"
        }, {
          "id": 7,
          "title": "jupiter-inactive.svg",
          "img": "/unearned/jupiter-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "jupiter-inactive.svg"
        }, {
          "id": 8,
          "title": "mars-inactive.svg",
          "img": "/unearned/mars-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "mars-inactive.svg"
        }, {
          "id": 9,
          "title": "perfect-week-inactive.svg",
          "img": "/unearned/perfect-week-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "perfect-week-inactive.svg"
        }, {
          "id": 10,
          "title": "pluto-inactive.svg",
          "img": "/unearned/pluto-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "pluto-inactive.svg"
        }, {
          "id": 11,
          "title": "rover-inactive.svg",
          "img": "/unearned/rover-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "rover-inactive.svg"
        }, {
          "id": 12,
          "title": "satellite-inactive.svg",
          "img": "/unearned/satellite-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "satellite-inactive.svg"
        }, {
          "id": 13,
          "title": "saturn-inactive.svg",
          "img": "/unearned/saturn-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "saturn-inactive.svg"
        }, {
          "id": 14,
          "title": "second-look-inactive.svg",
          "img": "/unearned/second-look-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "second-look-inactive.svg"
        }, {
          "id": 15,
          "title": "shooting-star-inactive.svg",
          "img": "/unearned/shooting-star-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "shooting-star-inactive.svg"
        }, {
          "id": 16,
          "title": "solo-flight-inactive.svg",
          "img": "/unearned/solo-flight-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "solo-flight-inactive.svg"
        }, {
          "id": 17,
          "title": "space-walk-inactive.svg",
          "img": "/unearned/space-walk-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "space-walk-inactive.svg"
        }, {
          "id": 18,
          "title": "stargazer-inactive.svg",
          "img": "/unearned/stargazer-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "stargazer-inactive.svg"
        }, {
          "id": 19,
          "title": "voyager-inactive.svg",
          "img": "/unearned/voyager-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "voyager-inactive.svg"
        }, {
          "id": 20,
          "title": "warp-drive-inactive.svg",
          "img": "/unearned/warp-drive-inactive.svg",
          "inactive": false,
          "mystery": false,
          "text": "warp-drive-inactive.svg"
        }]
      }, {
        "category": "Mystery Trophies",
        "description": "Mystery trophies are a special type of unearned trophy. In order to increase excitement and motivation, users can’t see what these trophies look like until they earn them — instead, they only see an outlined shape. This treatment is most appropriate for advanced trophies within each category.",
        "trophies": [{
          "id": 1,
          "title": "mystery-blast-off.svg",
          "img": "/mystery/mystery-blast-off.svg",
          "inactive": false,
          "mystery": false,
          "text": "mystery-blast-off.svg"
        }, {
          "id": 2,
          "title": "mystery-deep-space.svg",
          "img": "/mystery/mystery-deep-space.svg",
          "inactive": false,
          "mystery": false,
          "text": "mystery-deep-space.svg"
        }, {
          "id": 3,
          "title": "mystery-galaxy-explorer.svg",
          "img": "/mystery/mystery-galaxy-explorer.svg",
          "inactive": false,
          "mystery": false,
          "text": "mystery-galaxy-explorer.svg"
        }, {
          "id": 4,
          "title": "mystery-mission-control.svg",
          "img": "/mystery/mystery-mission-control.svg",
          "inactive": false,
          "mystery": false,
          "text": "mystery-mission-control.svg"
        }, {
          "id": 5,
          "title": "mystery-wormhole.svg",
          "img": "/mystery/mystery-wormhole.svg",
          "inactive": false,
          "mystery": false,
          "text": "mystery-wormhole.svg"
        }]
      }];
    }
  });
});
define('ohs/routes/teacher-sent-resources', ['exports', 'ohs/mixins/authorized-route-mixin', 'ohs/mixins/reset', 'ohs/mixins/breadcrumb-mixin'], function (exports, _authorizedRouteMixin, _reset, _breadcrumbMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_reset.default, _authorizedRouteMixin.default, _breadcrumbMixin.default, {
    authorizedRoles: ['Tutor', 'Teacher'],
    titleToken: 'Sent Resources',
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.controllerFor('application').set('showSecondaryNav', true);
      this.controllerFor('application').set('teacher', true);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },

    model: function model() {
      var sesObj = this.get('session');
      var userId = sesObj.session.content.authenticated["userId"];
      return Ember.RSVP.hash({
        studentGroups: this.store.findAll('student-group'),
        // subjects: this.store.findAll('subject'),
        account: this.store.queryRecord('account', { userId: userId }),
        sentSortOption: '1',
        levelFilterOptionSent: '1',
        sentKeywordBox: '',
        sentKeywordSearchPhrase: '',
        groupFilterSent: '',
        showModal: false,
        filterExpanded: false,
        selectedSortOption: 'Date Sent',
        selectedStudentGroupId: 0,
        subjectFilterSent: '',

        standardType: sesObj.get('standardType'),

        sentResources: this.store.findAll('resource-assigned'),

        fillteredSubjects: Ember.computed('account.subjects', function () {
          var sub = this.account.get('subjects');
          return sub;
        }),

        isFilterApplied: Ember.computed('sentKeywordBox', 'selectedStudentGroupId', function () {
          if (this.sentKeywordBox && this.sentKeywordBox.length > 0 && this.selectedStudentGroupId > 0) {
            return true;
          } else {
            return false;
          }
        }),

        isResourceNotEmpty: Ember.computed('sentResources', 'isFilterApplied', 'sentKeywordBox', 'selectedStudentGroupId', function () {
          if (this.sentResources.get('length') > 0) {
            return true;
          } else {
            if (this.isFilterApplied) {
              return false;
            } else {
              return true;
            }
          }
        }),

        sentResourcesDistinctStudents: Ember.computed('sentResources.@each.assignedDate', 'sentResources.@each.viewed', 'sentKeywordBox', 'groupFilterSent', 'levelFilterOptionSent', 'subjectFilterSent', function () {
          var students = new Array();

          var filtered = this.sentResources.filter(function (r, index, array) {
            var hasLevel = true;
            switch (this.levelFilterOptionSent) {
              case '1':
                hasLevel = true;
                break;
              case '2':
                hasLevel = r.get('resource').get('interestLevels').findBy('id', '3');
                break;
              case '3':
                hasLevel = r.get('resource').get('interestLevels').findBy('id', '2');
                break;
              case '4':
                hasLevel = r.get('resource').get('interestLevels').findBy('id', '1');
                break;
              default:
                hasLevel = true;
                break;
            }

            var keywordSearch = true;
            if (this.sentKeywordBox.length > 0) {
              var splitKeywords = this.sentKeywordBox.toLowerCase().split(" ");
              splitKeywords.forEach(function (k) {
                if (keywordSearch) {
                  keywordSearch = r.get('resource').get('resourceName').toLowerCase().indexOf(k) >= 0 || r.get('resource').get('description').toLowerCase().indexOf(k) >= 0 || r.get('student').get('fullName').toLowerCase().indexOf(k) >= 0;
                }
              });
            }

            var inGroup = true;
            if (this.groupFilterSent.length > 0) {
              inGroup = r.get('student').get('studentGroups').findBy('id', this.groupFilterSent);
            }

            var inSubject = true;
            if (this.subjectFilterSent.length > 0) {
              inSubject = r.get('resource').get('subject.id') == this.subjectFilterSent;
            }

            return hasLevel && keywordSearch && inGroup && inSubject;
          }, this);

          filtered.sortBy('student.fullNameReversed').forEach(function (item) {
            if (students.length == 0 || students[students.length - 1].get('id') != item.get('student.id')) {
              students.pushObject(item.get('student'));
            }
          });
          return students;
        }),

        sentResourcesOrdered: Ember.computed('sentResources.@each.assignedDate', 'sentResources.@each.viewed', 'sentKeywordBox', 'groupFilterSent', 'levelFilterOptionSent', 'subjectFilterSent', function () {
          var filtered = this.sentResources.filter(function (r, index, array) {
            var hasLevel = true;
            switch (this.levelFilterOptionSent) {
              case '1':
                hasLevel = true;
                break;
              case '2':
                hasLevel = r.get('resource').get('interestLevels').findBy('id', '3');
                break;
              case '3':
                hasLevel = r.get('resource').get('interestLevels').findBy('id', '2');
                break;
              case '4':
                hasLevel = r.get('resource').get('interestLevels').findBy('id', '1');
                break;
              default:
                hasLevel = true;
                break;
            }

            var keywordSearch = true;
            if (this.sentKeywordBox.length > 0) {
              var splitKeywords = this.sentKeywordBox.toLowerCase().split(" ");
              splitKeywords.forEach(function (k) {
                if (keywordSearch) {
                  keywordSearch = r.get('resource').get('resourceName').toLowerCase().indexOf(k) >= 0 || r.get('resource').get('description').toLowerCase().indexOf(k) >= 0 || r.get('student').get('fullName').toLowerCase().indexOf(k) >= 0;
                }
              });
            }

            var inGroup = true;
            if (this.groupFilterSent.length > 0) {
              inGroup = r.get('student').get('studentGroups').findBy('id', this.groupFilterSent);
            }

            var inSubject = true;
            if (this.subjectFilterSent.length > 0) {
              inSubject = r.get('resource').get('subject.id') == this.subjectFilterSent;
            }

            return hasLevel && keywordSearch && inGroup && inSubject;
          }, this);

          return filtered.sortBy('assignedDate').reverse();
        }),

        isProcessing: false,

        levelFilterOpts: [{
          id: 1,
          text: "All levels"
        }, {
          id: 2,
          text: "Above grade level"
        }, {
          id: 3,
          text: "At grade level"
        }, {
          id: 4,
          text: "Below grade level"
        }]
      });
    },


    afterModel: function afterModel(model) {
      var crumbs = this.get('session.breadcrumbs');
      if (crumbs.length > 0 && crumbs[crumbs.length - 1].route == 'teacher-results-1') {
        this._super(model, 'Sent Resources', false, false);
      } else {
        this._super(model, 'Sent Resources', false, true);
      }
    },

    actions: {
      hideKeyboard: function hideKeyboard() {
        document.activeElement.blur();
      },

      resend: function resend(object) {
        object.set('wasSent', 'sent-button');
        setTimeout(function () {
          object.set('updateText', true);
          setTimeout(function () {
            object.set('assignedDate', new Date());
            object.set('viewed', false);
            object.set('viewedDate', null);
            object.save();
          }, 1000);
        }, 500);
      },

      filterSent: function filterSent(option) {
        this.set('currentModel.levelFilterOptionSent', option);
      },

      sentKeywordSearch: function sentKeywordSearch() {
        var searchPhrase = this.get('currentModel.sentKeywordBox');
        this.set('currentModel.sentKeywordSearchPhrase', searchPhrase);
      },

      filterStudentGroupSent: function filterStudentGroupSent(option) {
        this.set('currentModel.groupFilterSent', option);
      },

      goToQuizzes: function goToQuizzes() {
        this.transitionTo('teacher-quizzes');
      },

      searchResources: function searchResources() {
        this.transitionTo('teacher.resource-search-start');
      },

      toggleFilter: function toggleFilter() {
        if (this.get('currentModel.filterExpanded')) {
          this.set('currentModel.filterExpanded', false);
        } else {
          this.set('currentModel.filterExpanded', true);
        }
      },
      filterSubjectSent: function filterSubjectSent(option) {
        this.set('currentModel.subjectFilterSent', option);
      }

    }

  });
});
define('ohs/components/roster-group', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'section',
        classNames: ['roster-group'],

        //fields
        groups: null,

        actions: {
            approveGroup: function approveGroup(groupId) {
                this.sendAction('approveGroup', groupId);
            },

            disapproveGroup: function disapproveGroup(groupId) {
                this.sendAction('disapproveGroup', groupId);
            },

            toggleStudentApproval: function toggleStudentApproval(groupId, student) {
                this.sendAction('toggleStudentApproval', groupId, student);
            }
        }
    });
});
define('ohs/components/student-mastery', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Component.extend({
        tagName: 'div',

        store: Ember.inject.service(),

        beforeDate: Date.now(),
        afterDate: new Date(-8640000000000000),
        selectedStudents: [],
        selectedTeachers: [],

        students: Ember.computed('data.[]', function () {
            var store = this.get('store');
            return this.get('data').map(function (a) {
                return store.findRecord('student', a.studentId);
            }).sortBy('lastName');
        }),

        // get all teacherIds
        teacherIds: Ember.computed.mapBy('data', 'teacherId'),
        // get only unique teacherIds
        uniqueteacherIds: Ember.computed.uniq('teacherIds'),
        // get teacher details
        teachers: Ember.computed('uniqueteacherIds.[]', function () {
            var store = this.get('store');
            return this.get('uniqueteacherIds').map(function (teacherId) {
                return store.findRecord('teacher', teacherId);
            }).sortBy('lastName');
        }),

        x_headers: Ember.computed('filteredData.[]', function () {
            var headers = this.get('filteredData').reduce(function (previous, current) {
                var result = previous;
                current.performance.forEach(function (a) {
                    if (!result.has(a.standardOrderId)) {
                        result.set(a.standardOrderId, a.standardCode);
                    }
                });
                return result;
            }, new Map());

            var self = this;
            return Array.from(new Map([].concat(_toConsumableArray(headers.entries())).sort()).values()).map(function (a) {
                //average for each column(standard wise)**START**
                var data = self.get('filteredData');
                var correct = 0;
                var attempted = 0;
                for (var i = 0; i < data.length; i++) {
                    var performance = data.objectAt(i).performance.filterBy('standardCode', a);
                    for (var j = 0; j < performance.length; j++) {
                        correct = correct + performance.objectAt(j).totalCorrect;
                        attempted = attempted + performance.objectAt(j).totalAttempted;
                    }
                }
                var avg = "Average: " + Math.round(correct / attempted * 100) + "%";
                //average for each column(standard wise)**END**

                return { text: a, average: avg };
            });
        }),

        totalAverage: Ember.computed('filteredData.[]', function () {
            // to find total average
            var data = this.get('filteredData');
            var totCorrect = 0;
            var totAttempted = 0;
            for (var i = 0; i < data.length; i++) {
                var performance = data.objectAt(i).performance;
                for (var j = 0; j < performance.length; j++) {
                    totCorrect = totCorrect + performance.objectAt(j).totalCorrect;
                    totAttempted = totAttempted + performance.objectAt(j).totalAttempted;
                }
            }
            var totAvg = "Average for all: " + Math.round(totCorrect / totAttempted * 100) + "%";
            return totAvg;
        }),
        rows: Ember.computed('filteredData.[]', function () {
            var _this = this;

            var rows = this.get('filteredData').map(function (a) {
                var columns = _this.get('x_headers').map(function (b, index) {
                    var propertyIndex = _this.getIndexOfProperty(a.performance, 'standardCode', '' + b.text);
                    var color = '';

                    if (a.performance[propertyIndex] === undefined || !a.performance[propertyIndex].totalAttempted > 0) {
                        return {
                            index: index,
                            totalAttempted: 0,
                            totalCorrect: 0,
                            percentCorrectDisplay: 'N/A',
                            scoreDisplay: 'N/A',
                            standard: b.text,
                            classes: ''
                        };
                    } else if (a.performance[propertyIndex].percentCorrect > 79) {
                        color = 'high';
                    } else if (a.performance[propertyIndex].percentCorrect > 59) {
                        color = 'mid';
                    } else {
                        color = 'low';
                    }

                    return {
                        index: index,
                        totalAttempted: a.performance[propertyIndex].totalAttempted,
                        totalCorrect: a.performance[propertyIndex].totalCorrect,
                        percentCorrectDisplay: a.performance[propertyIndex].percentCorrect + '%',
                        scoreDisplay: a.performance[propertyIndex].scoreDisplay,
                        standard: b.text,
                        classes: color
                    };
                });

                var student = _this.get('store').findRecord('student', a.studentId);

                return {
                    //cells
                    columns: columns.sort(function (b, c) {
                        if (b.index < c.index) {
                            return -1;
                        } else if (b.index > c.index) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }),
                    //student
                    student: student
                };
            });
            return rows;
        }),

        filteredData: Ember.computed('data.[]', 'afterDate', 'beforeDate', 'selectedStudents', 'selectedTeachers', function () {
            var _this2 = this;

            return this.get('data').reduce(function (a, b) {
                var previous = a;

                //check if student is selected
                if (_this2.get('selectedStudents').length > 0) {
                    if (!_this2.get('selectedStudents').some(function (c) {
                        return c.get('id') == b.studentId;
                    })) {
                        return previous;
                    }
                }
                //check if teacher is selected
                if (_this2.get('selectedTeachers').length > 0) {
                    if (!_this2.get('selectedTeachers').some(function (c) {
                        return c.get('id') == b.teacherId;
                    })) {
                        return previous;
                    }
                }

                var current = {
                    studentId: b.studentId,
                    performance: b.standardPerformance.map(function (c) {
                        var answers = c.answers;
                        // .filter((answer) => {
                        //     var finished = new Date(answer.dateFinished);
                        //     return finished < this.get('beforeDate')
                        //         && finished > this.get('afterDate');
                        // });
                        var correct = answers.filter(function (answer) {
                            return answer.isCorrect;
                        });

                        return {
                            standardCode: c.standardCode,
                            standardOrderId: c.standardOrderId,
                            totalCorrect: correct.length,
                            totalAttempted: answers.length,
                            percentCorrect: answers.length == 0 ? 0 : +(correct.length / answers.length * 100).toFixed(0),
                            scoreDisplay: correct.length + '/' + answers.length
                        };
                    })
                };

                //check if student has any results
                if (current.performance.reduce(function (b, c) {
                    return b + c.totalAttempted;
                }, 0) > 0) {
                    previous.pushObject(current);
                }

                return previous;
            }, []);
        }),

        actions: {
            back: function back() {
                this.sendAction('return');
            },

            exportToCSV: function exportToCSV() {
                var encloseField = function encloseField(field) {
                    return '"' + field + '"';
                };
                var createLine = function createLine(columns) {
                    return '' + columns.join(',');
                };
                var createCSV = function createCSV(rows) {
                    return rows.join('\n');
                };
                var headers = createLine(['Student Name', 'Standard Code', 'Attempted', 'Correct', 'Percentage Correct']);
                var rows = this.get('rows');
                var data = rows.reduce(function (lines, row) {
                    var newLines = row.columns.reduce(function (studentLines, column) {
                        return studentLines.concat(createLine([row.student.get('fullName'), column.standard, column.totalAttempted, column.totalCorrect, column.percentCorrectDisplay].map(encloseField)));
                    }, []);

                    return lines.concat.apply(lines, _toConsumableArray(newLines));
                }, []);

                var blob = new Blob([createCSV([headers].concat(_toConsumableArray(data)))], { type: 'text/csv' });
                saveAs(blob, 'Standard Coverage Report.csv');
            }
        },

        getIndexOfProperty: function getIndexOfProperty(array, attribute, value) {
            for (var x = 0; x < array.length; x++) {
                if (array[x][attribute] === value) {
                    return x;
                }
            }

            return -1;
        }
    });
});